import React from 'react';

interface IProps {
  isMSBActivated?: boolean;
  isMSBPotentiallyStuck?: boolean;
}

export const IconPlaneteOuiMSB = (props: IProps) => {
  const fillColor = props.isMSBActivated
    ? '#12b24b'
    : props.isMSBPotentiallyStuck
      ? '#ffc107'
      : '#afafb7';
  return (
    <svg viewBox="0 0 70 70" height="30" width="30">
      <circle cx="35" cy="35" r="27.79" fill={fillColor} />
      <path
        d="M31.93,37.41c0,3.48,2.77,5.75,6.2,5.75s6.21-2.27,6.21-5.75v-7.7h-4.8V37.2c0,1.08-.54,1.66-1.41,1.66s-1.4-.58-1.4-1.66V29.71h-4.8ZM22.66,29.5c-4.06,0-7.29,2.78-7.29,6.83s3.23,6.83,7.29,6.83,7.28-2.77,7.28-6.83S26.71,29.5,22.66,29.5Zm0,9.36a2.53,2.53,0,1,1,2.48-2.53A2.46,2.46,0,0,1,22.66,38.86Zm29,4.09h-4.8V29.71h4.8Z"
        fill="#fff"
      />
      <polyline
        points="49.76 21.23 49.76 25.37 48.77 25.37 48.77 21.23 49.76 21.23"
        fill="#fff"
      />
      <path
        d="M50.59,22.17v1.15a2.24,2.24,0,1,1-2.65,0V22.17a3.24,3.24,0,1,0,2.65,0Z"
        fill="#fff"
      />
    </svg>
  );
};

export default IconPlaneteOuiMSB;
