import * as React from 'react';
import { Query, QueryResult } from 'react-apollo';
import DiagnosticDetail from './DiagnosticDetail';
import { RouteComponentProps, withRouter } from 'react-router';
import { GET_DIAGNOSTICS_FOR_CUSTOMER } from './DiagnosticSummaryQuery';
import { DiagnosticSet } from '../../../../graphql/graphql.schema';

interface IMatchParams {
  customerId: string;
  testSetId: string;
}

interface IProps extends RouteComponentProps<IMatchParams> {
  customerId: string;
  testGroup: string;
}

interface IResponse {
  diagnosticSetByCustomerId: DiagnosticSet[];
}

const DiagnosticSummaryQuery = ({
  match: {
    params: { customerId, testSetId }
  }
}: IProps) => {
  return (
    <Query
      query={GET_DIAGNOSTICS_FOR_CUSTOMER}
      variables={{ customerId, testSetId }}
    >
      {({ loading, data, error }: QueryResult<IResponse>) => {
        const diago =
          data &&
          data.diagnosticSetByCustomerId &&
          data.diagnosticSetByCustomerId.find(d => d.id === testSetId);
        return (
          <DiagnosticDetail
            diagnostics={(diago && diago.diagnostics) || []}
            loading={false}
            error={error ? error.message : undefined}
          />
        );
      }}
    </Query>
  );
};
export default withRouter(DiagnosticSummaryQuery);
