import { default as React, useEffect, useState } from 'react';

import { translate } from 'react-i18next';
import { Alert, Button, Col, Collapse, Container, Form, Row } from 'reactstrap';
import { MasterTypeInternetConnection } from '../../../../constants/MasterTypeInternetConnection';
import Spinner from '../../../../ui/Spinner/Spinner';
import { TranslationFunction } from 'i18next';
import { IUpdateInstallation } from './EditInstallationQuery';
import { InputNumber } from '../../../../ui/InputNumber/InputNumber';
import LoadingButton from '../../../../ui/Button/LoadingButton/LoadingButton';
import { EditPVInstallationStore } from '../../../../stores/installations/editPVInstallationStore';
import { EditElectricalInstallationStore } from '../../../../stores/installations/editElectricalInstallationStore';
import { FormInput } from '../../../../ui/FormInput/FormInput';
import SelectInput, { ISelectValue } from '../../../../ui/Select/Select';
import Label from 'reactstrap/lib/Label';
import { TimeRange } from '../../../../stores/ranges/timeRange';
import {
  POWER_CONTRACT_TYPE_OPTIONS,
  POWER_CONTRACT_TYPES
} from '../../../../stores/devices_inscription/contants';
import Toggle from '../../../../ui/Button/Toggle/Toggle';
import IsolatedComponent from '../../../../ui/IsolatedComponent/IsolatedComponent';
import InternetProviderSelector from '../../../devices_inscription/installation/InternetProviderSelector';
import PanelManufacturerSelector from '../../../devices_inscription/installation/PanelManufacturerSelector';
import InternetBoxModelSelector from '../../../devices_inscription/installation/InternetBoxModelSelector';
import RackingPowerSelector from '../../../devices_inscription/installation/RackingPowerSelector';
import ElectricityProviderSelector from '../../../devices_inscription/installation/ElectricityProviderSelector';
import { Customer } from '../../../../graphql/graphql.schema';
import MasterConnectionTypeSelector from '../../../devices_inscription/installation/MasterConnectionTypeSelector';
import { GridType } from '@mylight/data-model';
import { CustomForm } from '../../../../ui/FormInput/CustomForm';
import ShowAlert from '../../../../ui/ShowAlert/ShowAlert';
import MissingFormFieldsAlert from '../../../../ui/MissingFormFieldsAlert/MissingFormFieldsAlert';
import { safeMobxInject } from '../../../../stores/storeInjectionHelpers';
import { CompositeDevice } from '../../../../stores/inscription/compositeDevice';
import CustomModal from '../../../../ui/CustomModal/CustomModal';
import { MSB_FINISHED, NO_MSB } from '../../../../constants/BATTERY_STATUS';
import SelectRouterReference from '../../../devices_inscription/master/SelectRouterReference';
import InputSerialNumber from '../../../devices_inscription/master/InputSerialNumber';

interface IStoresProps {
  editElectricalInstallation: EditElectricalInstallationStore;
  editPVInstallation: EditPVInstallationStore;
  isMSBAdded: boolean;
  customerIsFromCH: boolean;
  compositeDevicesDone: CompositeDevice[];
}

interface IProps {
  customer?: Customer;
  t: TranslationFunction;
  loading: boolean;
  done: boolean;
  saveLoading: boolean;
  save: (installation: IUpdateInstallation) => void;
}

let EditInstallationForm: React.FunctionComponent<IProps>;
EditInstallationForm = safeMobxInject<IStoresProps, IProps>(
  stores => ({
    editElectricalInstallation: stores.editElectricalInstallation,
    editPVInstallation: stores.editPVInstallation,
    isMSBAdded: stores.battery.isMSBAdded,
    customerIsFromCH: stores.inscription.customerIsFromCH,
    compositeDevicesDone: stores.inscription.compositeDevicesDone
  }),
  ({
    customer,
    loading,
    saveLoading,
    save,
    done,
    t,
    isMSBAdded,
    customerIsFromCH,
    compositeDevicesDone,
    editElectricalInstallation,
    editPVInstallation
  }: IProps & IStoresProps) => {
    const customerHasMSB =
      isMSBAdded || (customer && customer.msbStatus !== NO_MSB);
    const customerCannotEditPDL =
      customerHasMSB && (customer && customer.msbStatus === MSB_FINISHED);

    const [gridTypeChanged, setGridTypeChanged] = useState(false);
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);

    useEffect(
      () => {
        if (customerHasMSB) {
          editElectricalInstallation.setSellingPower(false);
          editElectricalInstallation.setSellingEnergyPrice(0);
        }
      },
      [customerHasMSB]
    );
    useEffect(
      () => {
        const mandatoryPDLForCustomersWithMSB = Boolean(
          !customerIsFromCH && customerHasMSB
        );
        editElectricalInstallation.updatePdlMandatory(
          mandatoryPDLForCustomersWithMSB
        );
      },
      [customerIsFromCH, customerHasMSB]
    );

    const formInvalid = Boolean(
      editPVInstallation.customerEditFormIsInvalid ||
        editElectricalInstallation.customerEditFormIsInvalid
    );

    const hasCompositeDeviceWithFunctionDefined =
      compositeDevicesDone.length > 0;

    const hasChangedGridTypeToSinglePhase =
      gridTypeChanged &&
      editElectricalInstallation.gridType === GridType.SINGLE_PHASE;

    const {
      masterTypeInternetConnection,
      setMasterTypeInternetConnection,
      simRouterReference,
      setSimRouterReference,
      isSimRouterReferenceValid,
      simSerialNumber,
      simSerialNumberError,
      setSimSerialNumber
    } = editElectricalInstallation;

    return (
      <IsolatedComponent
        title={t('InstallationEdit:title')}
        sm={10}
        md={9}
        lg={7}
        xl={6}
      >
        <Spinner loading={loading}>
          <Container fluid={true}>
            <Form
              className="pt-5 pb-5"
              style={{
                width: '100%'
              }}
              onSubmit={event => {
                event.preventDefault();
              }}
            >
              <SelectInput
                required
                onChange={value => {
                  setGridTypeChanged(true);
                  editElectricalInstallation.setGridType(value.value);
                }}
                id={`editInstallationForm-gridType`}
                name={t('gridElectricalPhaseType')}
                options={[
                  {
                    value: GridType.SINGLE_PHASE,
                    label: t(`Installation:${GridType.SINGLE_PHASE}`)
                  },
                  {
                    value: GridType.THREE_PHASE,
                    label: t(`Installation:${GridType.THREE_PHASE}`)
                  }
                ]}
                value={{
                  value: editElectricalInstallation.gridType || '',
                  label: editElectricalInstallation.gridType
                    ? t(`Installation:${editElectricalInstallation.gridType}`)
                    : ''
                }}
                error={
                  editElectricalInstallation.gridType &&
                  editElectricalInstallation.errorGridType
                }
              />
              {hasCompositeDeviceWithFunctionDefined &&
                hasChangedGridTypeToSinglePhase && (
                  <Alert className="email-error" color="warning">
                    {t('Installation:warningResetFunctionCompositeDevice')}
                  </Alert>
                )}
              {customerIsFromCH ? (
                <FormInput
                  required
                  onChange={editElectricalInstallation.setRackingPower}
                  id={`editInstallationForm-rackingPower`}
                  type="number"
                  round={1}
                  name={t('Customer:rackingPower')}
                  value={editElectricalInstallation.rackingPower}
                  unit={'kVa'}
                  error={
                    editElectricalInstallation.rackingPower
                      ? editElectricalInstallation.errorRackingPower
                      : undefined
                  }
                />
              ) : (
                <RackingPowerSelector
                  onChange={editElectricalInstallation.setRackingPower}
                  value={{
                    label: editElectricalInstallation.rackingPower
                      ? String(editElectricalInstallation.rackingPower)
                      : '',
                    value: editElectricalInstallation.rackingPower
                      ? String(editElectricalInstallation.rackingPower)
                      : ''
                  }}
                  error={
                    editElectricalInstallation.rackingPower
                      ? editElectricalInstallation.errorSelectRackingPower
                      : undefined
                  }
                />
              )}
              {!customerIsFromCH && (
                <FormInput
                  required={editElectricalInstallation.mandatoryPdlNumber}
                  onChange={editElectricalInstallation.setPdlNumber}
                  id={`editInstallationForm-pdl`}
                  type="text"
                  name={t('Installation:pdl')}
                  value={editElectricalInstallation.pdlNumber || ''}
                  disabled={customerCannotEditPDL}
                  helperText={
                    customerCannotEditPDL
                      ? t('Installation:pdlWithMsbNotEditable')
                      : customerHasMSB && !editElectricalInstallation.pdlNumber
                        ? t('Installation:pdlWithMsbInfo')
                        : undefined
                  }
                  error={
                    Boolean(
                      editElectricalInstallation.pdlNumber &&
                        !editElectricalInstallation.isValidPdlNumber
                    )
                      ? editElectricalInstallation.errorPdlNumber
                      : undefined
                  }
                />
              )}
              <ElectricityProviderSelector
                value={editElectricalInstallation.powerProvider || ''}
                onChange={(v: string) => {
                  editElectricalInstallation.setPowerProvider(v);
                }}
              />
              <SelectInput
                required
                onChange={v => {
                  editElectricalInstallation.setPowerContractType(v.value as
                    | 'predefined'
                    | 'flat'
                    | 'custom');
                  if (v.value === 'custom') {
                    editElectricalInstallation.addRange();
                  }
                }}
                name={t('powerContractType')}
                id={`editInstallationForm-powerContractType`}
                value={
                  (editElectricalInstallation.powerContractType && {
                    value: editElectricalInstallation.powerContractType || '',
                    label: t(
                      `PowerContractType:${
                        editElectricalInstallation.powerContractType
                      }`
                    )
                  }) ||
                  undefined
                }
                options={POWER_CONTRACT_TYPES.map(e => ({
                  value: e,
                  label: t(`PowerContractType:${e}`)
                }))}
                error={
                  editElectricalInstallation.powerContractType &&
                  editElectricalInstallation.errorPowerContractType
                }
              />

              <Collapse
                isOpen={
                  editElectricalInstallation.powerContractType === 'predefined'
                }
              >
                <SelectInput
                  required
                  onChange={v => {
                    editElectricalInstallation.setPowerContractOption(v.value);
                  }}
                  name={t('powerContractOption')}
                  id={`editInstallationForm-PowerContractOption`}
                  value={
                    (editElectricalInstallation.powerContractOption && {
                      value: editElectricalInstallation.powerContractOption,
                      label: t(
                        `PowerContractOption:${
                          editElectricalInstallation.powerContractOption
                        }`
                      )
                    }) || {
                      value: '',
                      label: ''
                    }
                  }
                  options={POWER_CONTRACT_TYPE_OPTIONS.map(e => ({
                    value: e,
                    label: t(`PowerContractOption:${e}`)
                  }))}
                  error={
                    editElectricalInstallation.powerContractOption &&
                    editElectricalInstallation.errorPowerContractOption
                  }
                />
              </Collapse>

              <Collapse
                isOpen={
                  editElectricalInstallation.powerContractType === 'custom'
                }
                className={'mt-3 mb-4'}
              >
                <div className="input-field">
                  <Label className="input-title">{t('timeSlots')}</Label>
                  <Button
                    className="mr-2 ml-2"
                    onClick={(e: any) => {
                      e.preventDefault();
                      editElectricalInstallation.removeRange();
                    }}
                  >
                    -
                  </Button>
                  <Button
                    className="mr-2"
                    onClick={(e: any) => {
                      e.preventDefault();
                      editElectricalInstallation.addRange();
                    }}
                  >
                    +
                  </Button>
                </div>
                {editElectricalInstallation.hcRanges.map(
                  (ts: TimeRange, index) => (
                    <Col key={index}>
                      <Row style={{ margin: '0.75rem 0' }}>
                        <Col sm={12} md={6}>
                          <Row style={{ flexWrap: 'nowrap' }}>
                            <Col
                              style={{ display: 'flex', minWidth: '4rem' }}
                              xs={2}
                            >
                              <Label className="mt-auto mb-auto">
                                {t('Installation:start')}
                              </Label>
                            </Col>
                            <Col xs={5}>
                              <div className="input-group">
                                <InputNumber
                                  value={ts.startH}
                                  onChange={ts.setStartH}
                                  min={0}
                                  unit="H"
                                  max={23}
                                  step={1}
                                  style={{ padding: '0 0 0 0.5rem' }}
                                />
                              </div>
                            </Col>
                            <Col xs={5}>
                              <div className="input-group">
                                <InputNumber
                                  value={ts.startM}
                                  onChange={ts.setStartM}
                                  min={0}
                                  unit="M"
                                  max={59}
                                  step={1}
                                  style={{ padding: '0 0 0 0.5rem' }}
                                />
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        <Col sm={12} md={6}>
                          <Row style={{ flexWrap: 'nowrap' }}>
                            <Col
                              style={{ display: 'flex', minWidth: '4rem' }}
                              xs={2}
                            >
                              <Label className="mt-auto mb-auto">
                                {t('Installation:end')}
                              </Label>
                            </Col>
                            <Col xs={5}>
                              <div className="input-group">
                                <InputNumber
                                  value={ts.endH}
                                  onChange={ts.setEndH}
                                  min={0}
                                  unit="H"
                                  max={23}
                                  step={1}
                                  style={{ padding: '0 0 0 0.5rem' }}
                                />
                              </div>
                            </Col>
                            <Col xs={5}>
                              <div className="input-group">
                                <InputNumber
                                  value={ts.endM}
                                  onChange={ts.setEndM}
                                  min={0}
                                  max={59}
                                  unit="M"
                                  step={1}
                                  style={{ padding: '0 0 0 0.5rem' }}
                                />
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  )
                )}
                {editElectricalInstallation.hcRangeConflict && (
                  <Row>
                    <Col>
                      <Alert color="danger">
                        {t('Installation:hcRangeOverlapWarning')}
                      </Alert>
                    </Col>
                  </Row>
                )}
              </Collapse>
              <CustomForm name={t('Installation:sellingPower')}>
                <Toggle
                  className="centered"
                  labelTrue={t('Common:yes')}
                  labelFalse={t('Common:no')}
                  onCheckChange={editElectricalInstallation.setSellingPower}
                  checked={
                    customerHasMSB
                      ? false
                      : editElectricalInstallation.sellingPower
                  }
                  disabled={customerHasMSB}
                />
                {customerHasMSB && (
                  <div className="pt-4">
                    <Alert color="warning">
                      {t('Installation:unableToSellPowerWithMSB')}
                    </Alert>
                  </div>
                )}
              </CustomForm>
              <Collapse isOpen={editElectricalInstallation.sellingPower}>
                <FormInput
                  required
                  onChange={editElectricalInstallation.setSellingEnergyPrice}
                  id={`editInstallationForm-sellingEnergyPrice`}
                  unit={
                    editPVInstallation.currency === 'EUR'
                      ? 'c€'
                      : `c${editPVInstallation.currency}`
                  }
                  type="number"
                  name={t('sellingEnergyPrice')}
                  value={editElectricalInstallation.sellingEnergyPrice || 0}
                  min={0}
                  step={0.01}
                  round={0.01}
                  error={
                    Boolean(
                      editElectricalInstallation.sellingEnergyPrice !== null &&
                        editElectricalInstallation.errorSellingEnergyPrice
                    )
                      ? t('Installation:errorSellingPower')
                      : undefined
                  }
                />
              </Collapse>
              <FormInput
                onChange={editPVInstallation.setPanelCount}
                id={`editInstallationForm-moduleCount`}
                type="number"
                name={t('panelCount')}
                value={editPVInstallation.panelCount}
                disabled={editPVInstallation.hasMultipleProductionCounter}
                min={0}
                step={1}
                round={1}
                error={
                  editPVInstallation.errorPanelCount &&
                  t('Installation:errorPanelCount')
                }
              />
              <FormInput
                onChange={editPVInstallation.setPanelWattPeak}
                id={`editInstallationForm-panelWattPeak`}
                type="number"
                name={t('wattPeakByPanel')}
                unit="Wc"
                disabled={editPVInstallation.hasMultipleProductionCounter}
                value={editPVInstallation.panelWattPeak}
                min={0}
                step={1}
                round={1}
                max={1000}
                error={
                  editPVInstallation.errorPanelWattPeak &&
                  t('Installation:errorPanelWattPeak')
                }
              />

              <PanelManufacturerSelector
                value={editPVInstallation.panelManufacturer || ''}
                onChange={(pmf: any) => {
                  editPVInstallation.setPanelManufacturer(pmf);
                }}
              />

              <InternetProviderSelector
                value={editElectricalInstallation.internetServiceProvider || ''}
                onChange={(provider: any) => {
                  editElectricalInstallation.setInternetServiceProvider(
                    provider
                  );
                }}
              />
              <InternetBoxModelSelector
                value={editElectricalInstallation.internetBoxModel || ''}
                onChange={(model: any) => {
                  editElectricalInstallation.setInternetBoxModel(model);
                }}
              />
              <MasterConnectionTypeSelector
                value={masterTypeInternetConnection}
                onChange={(internetConnectionType: ISelectValue) =>
                  setMasterTypeInternetConnection(internetConnectionType.value)
                }
              />
              {masterTypeInternetConnection ===
                MasterTypeInternetConnection.GSM && (
                <>
                  <SelectRouterReference
                    isValid={isSimRouterReferenceValid}
                    simRouterReference={simRouterReference}
                    setSimRouterReference={setSimRouterReference}
                  />
                  <InputSerialNumber
                    onSimSerialNumberChange={(serialNumber: string) =>
                      setSimSerialNumber(serialNumber)
                    }
                    simSerialNumber={simSerialNumber}
                    simSerialNumberError={
                      simSerialNumber &&
                      simSerialNumberError &&
                      t('Errors:numberFormatError')
                    }
                  />
                </>
              )}
              {formInvalid && (
                <ShowAlert message={t('StepTodo:installation')} />
              )}
              <MissingFormFieldsAlert formType={'editInstallation'} />
              <div className={'mt-3 mb-3 text-right'}>
                <LoadingButton
                  onClick={event => {
                    event.preventDefault();
                    if (
                      hasCompositeDeviceWithFunctionDefined &&
                      hasChangedGridTypeToSinglePhase
                    ) {
                      setIsConfirmOpen(true);
                    } else {
                      save({
                        pVInstallation: editPVInstallation.editedPVInstallation,
                        electricalInstallation:
                          editElectricalInstallation.editedElectricalInstallation
                      });
                    }
                  }}
                  disabled={formInvalid}
                  loading={saveLoading}
                  done={done}
                >
                  {t('InstallationEdit:saveEditButton')}
                </LoadingButton>
              </div>
              <CustomModal
                isOpened={isConfirmOpen}
                title={t('Common:warning')}
                handleClose={() => {
                  setIsConfirmOpen(false);
                }}
                handleConfirm={() => {
                  save({
                    pVInstallation: editPVInstallation.editedPVInstallation,
                    electricalInstallation:
                      editElectricalInstallation.editedElectricalInstallation
                  });
                  setIsConfirmOpen(false);
                }}
                confirmLabel={t('Common:agree')}
              >
                {t('Installation:areYouSureToChangedGridType')}
              </CustomModal>
            </Form>
          </Container>
        </Spinner>
      </IsolatedComponent>
    );
  }
);

export default translate([
  'Customer',
  'Installation',
  'InstallationEdit',
  'Common',
  'PowerContractType',
  'Errors'
])(EditInstallationForm);
