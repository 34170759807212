import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import CustomerList from '../../../ui/CustomerList/CustomerList';
import { Store } from '../../../stores';
import { translate, TranslationFunction } from 'react-i18next';
import Spinner from '../../../ui/Spinner/Spinner';
import MediaQuery from 'react-responsive';
import {
  BREAK_POINT_SAFE_MIN_WIDTH,
  BREAK_POINT_WIDTH
} from '../../../constants/BREAK_POINT_WIDTH';
import MobileCustomerList from '../../../ui/CustomerList/MobileCustomerList';
import { CustomerListItem } from '../../../graphql/graphql.schema';

interface IProps extends RouteComponentProps<IMatchParams> {
  customers: CustomerListItem[];
  t: TranslationFunction;
  Store?: Store;
  loading: boolean;
  canFetchMore: boolean;
  fetchMore: () => void;
}

interface IMatchParams {
  current: string;
}

const CustomersListView = ({
  customers,
  loading,
  fetchMore,
  canFetchMore
}: IProps) => {
  return (
    <Spinner loading={loading} style={{ height: 'calc(100% - 60px)' }}>
      <MediaQuery
        className="mobile-customer-list"
        query={`(max-width: ${BREAK_POINT_WIDTH})`}
      >
        <MobileCustomerList
          customers={customers}
          canFetchMore={canFetchMore}
          fetchMore={fetchMore}
        />
      </MediaQuery>
      <MediaQuery query={`(min-width: ${BREAK_POINT_SAFE_MIN_WIDTH})`}>
        <CustomerList
          customers={customers}
          canFetchMore={canFetchMore}
          fetchMore={fetchMore}
        />
      </MediaQuery>
    </Spinner>
  );
};

export default translate('Customer')(withRouter(CustomersListView));
