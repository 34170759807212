import * as React from 'react';
import { translate, TranslationFunction } from 'react-i18next';
import { MasterTypeInternetConnection } from '../../../constants/MasterTypeInternetConnection';
import SelectInput, { ISelectValue } from '../../../ui/Select/Select';

interface IProps {
  t: TranslationFunction;
  value?: string;
  onChange: (masterTypeInternetConnection: ISelectValue) => void;
  disabled?: boolean;
}

const MasterConnectionTypeSelector = ({
  t,
  value,
  onChange,
  disabled = false
}: IProps) => {
  return (
    <SelectInput
      required
      id={`editInstallationForm-master-connection`}
      name={t('Installation:masterTypeInternetConnection')}
      options={Object.values(MasterTypeInternetConnection).map(type => ({
        value: type,
        label: t(`Installation:${type}ConnectionType`)
      }))}
      onChange={onChange}
      disabled={disabled}
      value={
        value
          ? {
              value,
              label: t(`Installation:${value}ConnectionType`)
            }
          : {
              value: '',
              label: ''
            }
      }
    />
  );
};

export default translate(['Installation', 'Errors'])(
  MasterConnectionTypeSelector
);
