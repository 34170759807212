import { action, computed, observable } from 'mobx';
import { SignalQuality } from '../../constants/SignalQuality';
import {
  EPPDevice,
  EPPDevicePreConfig,
  PPDevice
} from '../../graphql/graphql.schema';
import {
  DEVICE_TYPE,
  ETH,
  FUNCTION_TYPE,
  GMD,
  GMD_G3,
  MST,
  MST_G3,
  SW,
  SW_G3,
  TPH
} from '../devices_inscription/contants';
import { t } from 'i18next';
import { basicStringValidator } from '../../utils/validators';
import { ISelectValue } from '../../ui/Select/Select';
import {
  getDeviceFunctionOptions,
  sortSelectOptionsAlphabetically
} from '../../utils/tools';

export const {
  ALARM_CLOCK,
  ASOKA_ELECTRIC_COUNTER,
  ASOKA_RED_PLUG,
  ASPIRATOR,
  BATTERY,
  BBQ_HOT_STONE,
  COFFEE_MACHINE,
  COMPUTER,
  DEEP_FRYER,
  DISHWASHER,
  DRYER,
  DVD_PLAYER,
  ELECTRIC_CAR,
  FOOD_PROCESSOR,
  FREEZER,
  GAME_CONSOLE,
  HAIR_DRYER,
  HEATER,
  HOME_CINEMA_SYSTEM,
  IRON,
  KETTLE,
  LAMP,
  MICROWAVE_OVEN,
  OTHER_DEVICE_TYPE,
  OVEN,
  PHONE,
  PRINTER,
  PRODUCTION_COUNTER,
  PRODUCTION_COUNTER_IGNORED,
  PUMP,
  RADIO,
  RAZOR,
  REFRIGERATOR,
  REFRIGERATOR_FREEZER,
  SPEAKER,
  STEREO_SYSTEM,
  TOASTER,
  TV,
  VENTILATOR,
  VIDEO_PROJECTOR,
  WASHING_DRYING_MACHINE,
  WASHING_MACHINE,
  WATER_HEATER,
  WINE_CELLAR
} = FUNCTION_TYPE;

export const GREENPLAY_DEVICES: any = [
  DISHWASHER,
  DRYER,
  ELECTRIC_CAR,
  WASHING_MACHINE,
  WATER_HEATER
];

const mstFunctionOptions = [ASOKA_RED_PLUG];
const swFunctionOptions = [
  ALARM_CLOCK,
  ASPIRATOR,
  BATTERY,
  BBQ_HOT_STONE,
  COFFEE_MACHINE,
  COMPUTER,
  DEEP_FRYER,
  DISHWASHER,
  DRYER,
  DVD_PLAYER,
  ELECTRIC_CAR,
  FOOD_PROCESSOR,
  FREEZER,
  GAME_CONSOLE,
  HAIR_DRYER,
  HEATER,
  HOME_CINEMA_SYSTEM,
  IRON,
  KETTLE,
  LAMP,
  MICROWAVE_OVEN,
  OTHER_DEVICE_TYPE,
  OVEN,
  PHONE,
  PRINTER,
  PUMP,
  RADIO,
  RAZOR,
  REFRIGERATOR,
  REFRIGERATOR_FREEZER,
  SPEAKER,
  STEREO_SYSTEM,
  TOASTER,
  TV,
  VENTILATOR,
  VIDEO_PROJECTOR,
  WASHING_DRYING_MACHINE,
  WASHING_MACHINE,
  WINE_CELLAR
];
const swG3FunctionOptions = [
  ALARM_CLOCK,
  ASPIRATOR,
  BATTERY,
  BBQ_HOT_STONE,
  COFFEE_MACHINE,
  COMPUTER,
  DEEP_FRYER,
  DISHWASHER,
  DRYER,
  DVD_PLAYER,
  ELECTRIC_CAR,
  FOOD_PROCESSOR,
  FREEZER,
  GAME_CONSOLE,
  HAIR_DRYER,
  HEATER,
  HOME_CINEMA_SYSTEM,
  IRON,
  KETTLE,
  LAMP,
  MICROWAVE_OVEN,
  OTHER_DEVICE_TYPE,
  OVEN,
  PHONE,
  PRINTER,
  PUMP,
  RADIO,
  RAZOR,
  REFRIGERATOR,
  REFRIGERATOR_FREEZER,
  SPEAKER,
  STEREO_SYSTEM,
  TOASTER,
  TV,
  VENTILATOR,
  VIDEO_PROJECTOR,
  WASHING_DRYING_MACHINE,
  WASHING_MACHINE,
  WATER_HEATER,
  WINE_CELLAR
];

const ethFunctionOptions = [
  ALARM_CLOCK,
  ASPIRATOR,
  BATTERY,
  BBQ_HOT_STONE,
  COFFEE_MACHINE,
  COMPUTER,
  DEEP_FRYER,
  DISHWASHER,
  DRYER,
  DVD_PLAYER,
  ELECTRIC_CAR,
  FOOD_PROCESSOR,
  FREEZER,
  GAME_CONSOLE,
  HAIR_DRYER,
  HEATER,
  HOME_CINEMA_SYSTEM,
  IRON,
  KETTLE,
  LAMP,
  MICROWAVE_OVEN,
  OTHER_DEVICE_TYPE,
  OVEN,
  PHONE,
  PRINTER,
  PUMP,
  RADIO,
  RAZOR,
  REFRIGERATOR,
  REFRIGERATOR_FREEZER,
  SPEAKER,
  STEREO_SYSTEM,
  TOASTER,
  TV,
  VENTILATOR,
  VIDEO_PROJECTOR,
  WASHING_DRYING_MACHINE,
  WASHING_MACHINE,
  WINE_CELLAR
];
const devicesWithoutDescription = [TPH, SW_G3, GMD_G3];
const devicesWithoutRoomEdition = [MST, MST_G3, SW_G3, GMD_G3];
const devicesWithExtendedDescription = [MST_G3];

export class Device {
  @observable public id?: string = undefined;
  @observable public firmware?: number = undefined;
  @observable public status?: string = undefined;
  @observable public plcMac?: string = undefined;
  @observable public ethMac?: string = undefined;
  @observable public power?: number[] = undefined;
  @observable public state?: boolean = undefined;
  @observable public activationCode?: string = undefined;
  @observable public masterMac?: string = undefined;
  @observable public deviceTypeOverride?: string = undefined;
  @observable public controlledDeviceType?: string = undefined;
  @observable public controlledDeviceName?: string = undefined;
  @observable public roomType?: string = undefined;
  @observable public roomName?: string = undefined;
  @observable public isDeleted?: boolean = undefined;
  @observable public compositeId?: string = undefined;
  @observable public modbusReference?: string = undefined;
  @observable public greenPlayEnabled?: boolean = undefined;

  @observable public editedRoomType?: string = undefined;
  @observable public editedRoomName?: string = undefined;

  @observable public editedGreenPlayEnabled?: boolean = undefined;
  @observable public editedControlledDeviceName?: string = undefined;
  @observable public editedControlledDeviceType?: string = undefined;
  @observable public isEmulatedFromG3?: boolean = undefined;
  @observable public deviceType?: string = undefined;
  @observable public isWirelessModbus?: boolean = false;

  @observable public simNb?: string = undefined;
  @observable public rssi?: number = undefined;
  @observable public rscp?: number = undefined;
  @observable public gsmSignalQuality?: string = undefined;
  @observable public isGSMConnected?: boolean = false;

  @observable public tx?: number = undefined;
  @observable public rx?: number = undefined;
  @observable public plcSignalQuality?: string = undefined;

  constructor(device: PPDevice) {
    this.id = device.id;
    this.firmware = device.firmware;
    this.status = device.status;
    this.deviceType = device.deviceType;
    this.plcMac = device.plcMac;
    this.ethMac = device.ethMac || undefined;
    this.power = device.power;
    this.state = device.state;
    this.activationCode = device.activationCode;
    this.masterMac = device.masterMac;
    this.deviceTypeOverride = device.deviceTypeOverride;
    this.controlledDeviceType = device.preConfig.controlledDeviceType;
    this.controlledDeviceName = device.preConfig.controlledDeviceName;
    this.roomType = device.preConfig.roomType;
    this.roomName = device.preConfig.roomName;
    this.isDeleted = device.preConfig.isDeleted;
    this.compositeId = device.preConfig.compositeId;
    this.greenPlayEnabled = device.preConfig.greenPlayEnabled;
    this.isWirelessModbus =
      device.deviceType === DEVICE_TYPE.GMD &&
      device.preConfig.isWirelessModbus;
    // init edition observable
    this.editedRoomType = device.preConfig.roomType;
    this.editedRoomName = device.preConfig.roomName;
    this.editedControlledDeviceName = device.preConfig.controlledDeviceName;
    this.editedControlledDeviceType = device.preConfig.controlledDeviceType;
    this.editedGreenPlayEnabled = device.preConfig.greenPlayEnabled;
    this.isEmulatedFromG3 = device.emulatedFromG3;
    this.simNb = device.simNb;
    this.rssi = device.rssi;
    this.rscp = device.rscp;
    this.gsmSignalQuality = device.gsmSignalQuality;
    this.isGSMConnected = device.isGSMConnected;
    this.tx = device.tx;
    this.rx = device.rx;
    this.plcSignalQuality = device.plcSignalQuality;
  }

  @computed
  get toMutationPayload(): EPPDevice[] {
    return [{ ...this.payLoadData, preConfig: this.preConfig }];
  }

  @computed
  get getCanMerge(): boolean {
    return false;
  }

  @computed
  get getCanSplit(): boolean {
    return false;
  }

  @computed
  get getKey(): string {
    return `${this.ethMac}|${this.plcMac}`;
  }

  @computed
  get isSplitable(): boolean {
    return false;
  }

  @computed
  get getIsModbus() {
    return false;
  }

  @computed
  get getCanHaveGreenPlay() {
    return true;
  }

  @computed
  get preConfig(): EPPDevicePreConfig {
    return {
      controlledDeviceType: this.editedControlledDeviceType,
      controlledDeviceName: this.editedControlledDeviceName,
      roomType: this.editedRoomType,
      roomName: this.editedRoomName,
      isDeleted: this.isDeleted,
      compositeId: this.compositeId,
      greenPlayEnabled: this.editedGreenPlayEnabled
    };
  }

  @computed
  get payLoadData(): Partial<EPPDevice> {
    return {
      id: this.id,
      deviceType: this.deviceType,
      plcMac: this.plcMac,
      ethMac: this.ethMac,
      activationCode: this.activationCode,
      masterMac: this.masterMac,
      deviceTypeOverride: this.deviceTypeOverride
    };
  }

  @computed
  get isConnectToGsmDevice(): boolean {
    return this.deviceType === MST && (!!this.simNb || !!this.rssi);
  }

  @action.bound
  public setId(value: string) {
    this.id = value;
  }

  @action.bound
  public setFirmware(value: number) {
    this.firmware = value;
  }

  @action.bound
  public setStatus(value: string) {
    this.status = value;
  }

  @action.bound
  public setDeviceType(value: string) {
    this.deviceType = value;
  }

  @action.bound
  public setPlcMac(value: string) {
    this.plcMac = value;
  }

  @action.bound
  public setEthMac(value: string) {
    this.ethMac = value;
  }

  @action.bound
  public setPower(value: number[]) {
    this.power = value;
  }

  @action.bound
  public setState(value: boolean) {
    this.state = value;
  }

  @action.bound
  public setActivationCode(value: string) {
    this.activationCode = value;
  }

  @action.bound
  public setMasterMac(value: string) {
    this.masterMac = value;
  }

  @action.bound
  public setDeviceTypeOverride(value: string) {
    this.deviceTypeOverride = value;
  }

  @action.bound
  public setControlledDeviceType(value: string) {
    this.controlledDeviceType = value;
  }

  @action.bound
  public setControlledDeviceName(value: string) {
    this.controlledDeviceName = value;
  }

  @action.bound
  public setCompositeId(value: string) {
    this.compositeId = value;
  }

  @action.bound
  public setGreenPlayEnabled(value: boolean) {
    this.greenPlayEnabled = value;
  }

  @action.bound
  public setEditedRoomType(value: string) {
    this.editedRoomType = value;
    if (this.hasDefaultRoomSelected) {
      this.editedRoomName = '';
    }
  }

  @action.bound
  public setEditedRoomName(value: string) {
    this.editedRoomName = value;
  }

  @action.bound
  public setEditedControlledDeviceName(value: string) {
    this.editedControlledDeviceName = value;
  }

  @action.bound
  public setEditedControlledDeviceType(value: string) {
    this.editedControlledDeviceType = value;

    // update wmodbus device name for production and consumption functions
    if (this.isWirelessModbus) {
      switch (value) {
        case FUNCTION_TYPE.PRODUCTION_COUNTER:
        case FUNCTION_TYPE.ASOKA_ELECTRIC_COUNTER:
          this.setEditedControlledDeviceName(
            `${t(`DevicesFunctions:${value}`)} - RF`
          );
          break;

        default:
          this.setEditedControlledDeviceName(`${t('Devices:counter')} RF`);
          break;
      }
    }
  }

  @action.bound
  public setEditedGreenPlayEnabled(value: boolean) {
    this.editedGreenPlayEnabled = value;
  }

  @computed
  get isTodo() {
    return !this.controlledDeviceType;
  }

  @computed
  get isDone() {
    return !this.isTodo;
  }

  @computed
  get controlledDeviceTypeChanged(): boolean {
    return this.controlledDeviceType !== this.editedControlledDeviceType;
  }

  @computed
  get controlledDeviceNameChanged(): boolean {
    return this.controlledDeviceName !== this.editedControlledDeviceName;
  }

  @computed
  get roomNameChanged(): boolean {
    return this.roomName !== this.editedRoomName;
  }

  @computed
  get roomTypeChanged(): boolean {
    return this.roomType !== this.editedRoomType;
  }

  @computed
  get greenPlayChanged(): boolean {
    return this.greenPlayEnabled !== this.editedGreenPlayEnabled;
  }

  @computed
  get editedControlledDeviceNameError(): string | undefined {
    return basicStringValidator(this.editedControlledDeviceName);
  }

  @computed
  get editedRoomNameError(): string | undefined {
    return basicStringValidator(this.editedRoomName);
  }

  @computed
  get canSaveFunction(): boolean {
    return (
      !this.editedControlledDeviceNameError &&
      (this.greenPlayChanged ||
        this.controlledDeviceTypeChanged ||
        this.controlledDeviceNameChanged)
    );
  }

  @computed
  get canSaveRoom(): boolean {
    return (
      (!this.editedRoomNameError || this.hasDefaultRoomSelected) &&
      (this.roomNameChanged || this.roomTypeChanged)
    );
  }

  @computed
  get isMst(): boolean {
    return this.deviceType === MST;
  }

  @computed
  get canBeDeleted(): boolean {
    return !this.isMst && !this.isEmulatedFromG3;
  }

  @computed
  get isCounter(): boolean {
    return (
      this.controlledDeviceType === ASOKA_ELECTRIC_COUNTER ||
      this.controlledDeviceType === PRODUCTION_COUNTER
    );
  }

  @computed
  get isDeviceConnected(): boolean {
    return this.status === 'online' || this.status === 'unpaired';
  }

  @computed
  get isModbusMC3D01RM(): boolean {
    return (
      this.getIsModbus &&
      !!this.modbusReference &&
      this.modbusReference === 'MC3D01RM'
    );
  }

  @computed
  get isModbusMC3D01RMConfiguredAsProductionCounter(): boolean {
    return this.isModbusMC3D01RM && this.isProductionCounter;
  }

  @computed
  get getDevicePower(): number {
    let totalPower = 0;
    if (this.power) {
      this.power.forEach(p => (totalPower += p));
    }
    if (this.isModbusMC3D01RMConfiguredAsProductionCounter) {
      totalPower = Math.abs(totalPower);
    }
    return totalPower;
  }

  @computed
  get getFunctionOptions(): ISelectValue[] {
    let functionOptions: string[] = [];

    switch (this.getDeviceType) {
      case SW:
        functionOptions = swFunctionOptions;
        break;
      case SW_G3:
        functionOptions = swG3FunctionOptions;
        break;
      case ETH:
        functionOptions = ethFunctionOptions;
        break;
      case MST:
        functionOptions = mstFunctionOptions;
        break;
    }
    return sortSelectOptionsAlphabetically(
      getDeviceFunctionOptions(functionOptions)
    );
  }

  @computed
  get isNegativePower(): boolean {
    return Boolean(
      this.isDone &&
        (this.controlledDeviceType === WATER_HEATER ||
          this.controlledDeviceType === PRODUCTION_COUNTER) &&
        this.getDevicePower < 0
    );
  }

  @computed
  get getDeviceType(): string {
    if (this.deviceType === MST && this.isEmulatedFromG3) {
      return MST_G3;
    }
    if (this.deviceType === GMD && this.isEmulatedFromG3) {
      return GMD_G3;
    }
    if (this.deviceType === SW && this.isEmulatedFromG3) {
      return SW_G3;
    }
    return this.deviceType || 'unknown_device';
  }

  @computed
  get getDeviceImageType(): string {
    return this.getDeviceType.toLowerCase();
  }

  @computed
  get getDeviceFullName(): string {
    return t(`DeviceType:${this.getDeviceType}`);
  }

  @computed
  public get getDeviceFunctionType(): string {
    return t(`DevicesFunctions:${this.controlledDeviceType}`);
  }

  @computed
  get getDeviceShortFunctionName(): string {
    if (this.controlledDeviceType) {
      return this.controlledDeviceName
        ? `${this.getDeviceFunctionNamePrefix} ${this.controlledDeviceName}`
        : '';
    }
    return `${this.controlledDeviceName}`;
  }

  @computed
  get getDeviceFullFunctionName(): string {
    if (this.controlledDeviceType) {
      return `${this.getDeviceShortFunctionName} - ${t(
        `DevicesFunctions:${this.controlledDeviceType}`
      )}`;
    }
    return `${this.controlledDeviceName}`;
  }

  @computed
  get getDeviceFullRoomName(): string {
    if (this.roomType) {
      if (this.roomName) {
        return `${t(`RoomName:${this.roomType}`)} - ${this.roomName}`;
      }
      return t(`RoomName:${this.roomType}`);
    }
    return t('Devices:roomToDefine');
  }

  @computed
  get isDeviceHasRoomEdition(): boolean {
    return !devicesWithoutRoomEdition.includes(this.getDeviceType);
  }

  @computed
  get isDeviceHasDescription(): boolean {
    return !devicesWithoutDescription.includes(this.getDeviceType);
  }

  @computed
  get isDeviceHasExtendedDescription(): boolean {
    return devicesWithExtendedDescription.includes(this.getDeviceType);
  }

  @computed
  get isGlobalConsumption() {
    return this.controlledDeviceType === ASOKA_ELECTRIC_COUNTER;
  }

  @computed
  get isProductionCounter() {
    return this.controlledDeviceType === PRODUCTION_COUNTER;
  }

  @computed
  get hasSelectedGlobalConsumption() {
    return this.editedControlledDeviceType === ASOKA_ELECTRIC_COUNTER;
  }

  @computed
  get hasDefaultRoomSelected(): boolean {
    return this.editedRoomType === 'alaska_home';
  }

  @action.bound
  public cancelChanges() {
    this.editedControlledDeviceType = this.controlledDeviceType;
    this.editedControlledDeviceName = this.controlledDeviceName;
    this.editedRoomName = this.roomName;
    this.editedRoomType = this.roomType;
    this.editedGreenPlayEnabled = this.greenPlayEnabled;
  }

  @computed
  get isForbidden(): boolean {
    return false;
  }

  @computed
  get isDeviceForbiddenToMergeOnInstallationSinglePhase(): boolean {
    return false;
  }

  @computed
  public get getDeviceFunctionNamePrefix(): string {
    const devicesWithControlOfPrefix = [SW_G3];

    if (devicesWithControlOfPrefix.includes(this.getDeviceType)) {
      return t('Devices:controlOfPrefix');
    }

    return '';
  }

  @computed
  public get signalQualityIsBad(): boolean {
    return (
      this.plcSignalQuality === SignalQuality.BAD ||
      this.plcSignalQuality === null
    );
  }
}
