import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getRandomCharactersString } from '../../utils/tools';
import CustomTooltip from '../Tooltip/CustomTooltip';
import { Alert } from '../../graphql/graphql.schema';
import { TranslationFunction } from 'i18next';

import './AlertTranslation.scss';
import { translate } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

interface IProps {
  t: TranslationFunction;
  icon: string;
  alert: Alert;
  rightMargin?: number;
  Store?: any;
}

const AlertNotificationIcon = inject('Store')(
  observer(
    ({
      t,
      icon,
      alert,
      rightMargin,
      Store: {
        profile: { tenant }
      }
    }: IProps) => {
      const [selector, setSelector] = useState(getRandomCharactersString());
      useEffect(() => {
        setSelector(getRandomCharactersString());
      }, []);

      const convertAlertTranslation = (translation: string) => {
        const replaced = translation
          .replace('%tenant%', t(`Tenant:${tenant}`))
          .replace('%long_tenant%', t(`Tenant:${tenant}FullName`));

        return replaced.split('%br%').map((item, key) => {
          if (item.indexOf('%FAQ%') !== -1) {
            const parts = item.split('%FAQ%');
            return (
              <span key={key}>
                <span>
                  {parts[0]}
                  <a
                    href={'https://www.mylight-systems.com/espace-pro/'}
                    target={'_blank'}
                  >
                    FAQ
                  </a>
                  {parts[1]}
                </span>
                <br />
              </span>
            );
          }

          return (
            <span key={key}>
              {item}
              <br />
            </span>
          );
        });
      };

      return (
        <span>
          <span
            className={'notification-icon-container'}
            id={selector}
            onClick={e => e.stopPropagation()}
          >
            <FontAwesomeIcon
              icon={'exclamation-circle'}
              color={'red'}
              style={{
                marginRight: rightMargin || 0
              }}
            />
          </span>
          <CustomTooltip
            errorMsg={{ text: alert.label, weight: 900 }}
            selector={selector}
            complementMsg={
              alert.description || alert.extendedDescription ? (
                <div className={'alert-translated-content'}>
                  {alert.description
                    ? convertAlertTranslation(alert.description)
                    : null}
                  <span className={'alert-translated-tip'}>{t('tip')}</span>
                  {alert.extendedDescription
                    ? convertAlertTranslation(alert.extendedDescription)
                    : null}
                </div>
              ) : null
            }
          />
        </span>
      );
    }
  )
);

export default translate(['Common', 'Tenant'])(AlertNotificationIcon);
