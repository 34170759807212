import React from 'react';
import { IDeviceIcon } from '../DeviceIcons';

const HomeCinemaSystem = (props: IDeviceIcon) => (
  <svg
    width="3rem"
    height="3rem"
    viewBox="0 0 20000 20000"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    color="black"
    {...props}
  >
    <g id="prefix__layer">
      <g id="prefix___1405087084080">
        <path
          style={{ fill: `${props.color}` }}
          d="M5263 9963H2629c-116 0-194-58-194-194V4888c0-116 58-194 194-194h2634c116 0 194 58 194 194v4881c0 77-58 194-194 194zm-2440-368h2324V5024H2823v4571z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M3946 7348c-504 0-930-445-930-930 0-504 445-949 930-949 504 0 949 445 949 949 0 484-445 930-949 930zm0-1511c-310 0-562 252-562 562s252 562 562 562 562-252 562-562-232-562-562-562zM3946 9092c-368 0-697-310-697-697 0-368 310-697 697-697 368 0 697 310 697 697s-310 697-697 697zm0-1123c-194 0-368 194-368 368 0 194 194 368 368 368 194 0 368-194 368-368 19-174-174-368-368-368z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M4198 6399c0 136-116 252-252 252s-252-116-252-252 116-252 252-252c155 0 252 116 252 252zM4082 8336c0 77-58 116-136 116-77 0-116-58-116-116s58-116 116-116c77 0 136 58 136 116zM17485 9963h-2634c-116 0-194-58-194-194V4888c0-116 58-194 194-194h2634c116 0 194 58 194 194v4881c0 77-58 194-194 194zm-2499-368h2324V5024h-2324v4571z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M16168 7348c-504 0-930-445-930-930 0-504 445-949 930-949 504 0 949 445 949 949-77 484-445 930-949 930zm0-1511c-310 0-562 252-562 562s252 562 562 562 562-252 562-562-252-562-562-562zM16168 9092c-368 0-697-310-697-697 0-368 310-697 697-697 368 0 697 310 697 697s-310 697-697 697zm0-1123c-194 0-368 194-368 368 0 194 194 368 368 368 194 0 368-194 368-368 19-174-174-368-368-368z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M16419 6399c0 136-116 252-252 252s-252-116-252-252 116-252 252-252 252 116 252 252zM16303 8336c0 77-58 116-116 116s-116-58-116-116 58-116 116-116 116 58 116 116zM5960 13798h3254c116 0 252-116 252-252v-872c0-136-136-252-252-252H5960c-116 0-252 116-252 252v872c0 116 116 252 252 252zm194-891h2828v445H6154v-445zM10899 13798h3254c116 0 252-116 252-252v-872c0-136-116-252-252-252h-3254c-116 0-252 116-252 252v872c77 116 136 252 252 252zm252-891h2828v445h-2828v-445z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M14850 11590H5321c-252 0-445 194-445 445v3002c0 116 116 252 252 252 116 0 252-116 252-252v-426h9529v445c0 116 136 252 252 252s252-116 252-252v-3022c-174-252-368-445-562-445zm0 2634H5321v-2189h9529v2189zM7026 10970h6082c252 0 445-194 445-445V7523c0-252-194-445-445-445H7026c-252 0-445 194-445 445v3002c0 252 194 445 445 445zm0-3506h6082v3002H7026V7464z"
        />
      </g>
    </g>
  </svg>
);

export default HomeCinemaSystem;
