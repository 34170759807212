import React from 'react';

import classnames from 'classnames';
import './stepper.scss';
import { IStepperMenuItem } from '../../components/devices_inscription/DevicesInscription';
import { safeMobxInject } from '../../stores/storeInjectionHelpers';
import { Progress } from 'reactstrap';

interface IProps {
  current: string;
  title?: string;
  activeKey?: string;
}

interface IStoresProps {
  menuItems: IStepperMenuItem[];
}

export type StepStatus = 'done' | 'pending' | 'todo';

const Stepper = safeMobxInject<IStoresProps, IProps>(
  stores => ({
    menuItems: stores.inscriptionSteps.visibleMenuItems
  }),
  ({ menuItems }: IProps & IStoresProps) => {
    const registrationStepsDone = menuItems.filter(i => i.isDone).length;
    const registrationStepsCount = menuItems.length - 1;
    const registrationProgressPercentage =
      (registrationStepsDone / registrationStepsCount) * 100;
    return (
      <div className={classnames('stepper')}>
        <div className={'stepper-progress'}>
          <Progress value={registrationProgressPercentage} />
        </div>
      </div>
    );
  }
);

export default Stepper;
