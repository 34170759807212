import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Col,
  Collapse,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from 'reactstrap';
import { FormInput } from '../../../ui/FormInput/FormInput';
import { translate, TranslationFunction } from 'react-i18next';
import { CustomForm } from '../../../ui/FormInput/CustomForm';
import LoadingButton from '../../../ui/Button/LoadingButton/LoadingButton';
import Toggle from '../../../ui/Button/Toggle/Toggle';
import { ListTitle } from '../../../ui/DataDisplay/List/ListHeader';
import List from '../../../ui/DataDisplay/List/ListWrapper';
import ListItem from '../../../ui/DataDisplay/List/ListItem';
import { UCG } from '../../../stores/inscription/inscriptionStore';
import { RouteComponentProps, withRouter } from 'react-router';
import { ScanCodeType } from '../../../stores/barcode';
import ScanBarcodeButton from '../barcode/ScanBarcodeButton';
import imgPositionUCG from '../../../assets/images/ucg/position-ucg.jpg';
import './UCGView.scss';

import { useScrollToInscriptionStep } from '../../../utils/hooks';
import { safeMobxInject } from '../../../stores/storeInjectionHelpers';
import { InscriptionStep } from '../../../stores/inscription/inscriptionStepsStore';
import UcgPreconfigurationWizardFeatureInfo from './UcgPreconfigurationWizardFeatureInfo';
import CustomModal from '../../../ui/CustomModal/CustomModal';

interface IProps extends RouteComponentProps {
  saveLoading: boolean;
  done: boolean;
  error: any;
  save: (ucg: UCG | undefined) => void;
  t: TranslationFunction;
}

interface IStoresProps {
  setUcg: (ucg: UCG) => void;
  ucg?: UCG;
  isEditedUCGInvalid: boolean;
  isEditedUCGEmpty: boolean;
  setType: (type: ScanCodeType | undefined) => void;
}

const UCGView = safeMobxInject<IStoresProps, IProps>(
  stores => ({
    setUcg: stores.inscription.setUcgEdited,
    ucg: stores.inscription.ucgEdited,
    isEditedUCGInvalid: stores.inscription.isEditedUCGInvalid,
    isEditedUCGEmpty: stores.inscription.isEditedUCGEmpty,
    setType: stores.barcode.setType
  }),
  ({
    save,
    t,
    saveLoading,
    done,
    error,
    ucg,
    setUcg,
    setType,
    isEditedUCGInvalid,
    isEditedUCGEmpty
  }: IProps & IStoresProps) => {
    useScrollToInscriptionStep(InscriptionStep.UCG);

    useEffect(() => {
      return () => {
        setType(undefined);
      };
    }, []);

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const invalidUCGSerialNumber =
      isEditedUCGInvalid ||
      (error &&
        error.graphQLErrors[0] &&
        error.graphQLErrors[0].message &&
        error.graphQLErrors[0].message.errorCode === 'ucgSerialNumberInvalid');
    const canSaveUcg = !isEditedUCGEmpty && !isEditedUCGInvalid;
    return (
      <Col md={12} xl={6} className={'mobile-helper'}>
        <List fluid>
          <ListTitle
            text={t('RegistrationMenu:ucg')}
            children={<ScanBarcodeButton codeType={'UCG'} />}
          />
          <ListItem>
            <Form
              className="form-wrapper"
              onSubmit={e => {
                e.preventDefault();
              }}
            >
              <CustomForm id="ucgToggle" name={t('UCG:iHaveUCG')}>
                <Toggle
                  className="centered"
                  onCheckChange={checked => {
                    setUcg(checked ? '' : null);
                  }}
                  labelFalse={t('no')}
                  labelTrue={t('yes')}
                  checked={ucg !== null}
                />
              </CustomForm>
              <Collapse isOpen={ucg !== null} className="mt-3">
                <UcgPreconfigurationWizardFeatureInfo />
                <FormInput
                  id="ucg"
                  name={t('UCG:ucg')}
                  onChange={(value: string) => setUcg(value)}
                  addon={
                    <Button
                      className={'ucg-hint-button'}
                      size="sm"
                      onClick={() => setIsModalOpen(true)}
                    >
                      {t('UCG:whereIsUCG')}
                    </Button>
                  }
                  error={invalidUCGSerialNumber && t('UCG:wrongUCG')}
                  value={ucg ? ucg : ''}
                  disabled={ucg === null}
                />
                {isEditedUCGEmpty && (
                  <Alert color="warning">{t('UCG:writeUCG')}</Alert>
                )}
              </Collapse>
              <div className="text-right mt-3">
                <LoadingButton
                  loading={saveLoading}
                  done={done}
                  disabled={!canSaveUcg}
                  onClick={() => {
                    save(ucg);
                  }}
                >
                  {t('save')}
                </LoadingButton>
              </div>
            </Form>
          </ListItem>
        </List>
        <CustomModal
          isOpened={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
          title={t('UCG:whereIsUCG')}
        >
          <Col className={'ucg-image-modal'}>
            <Row> {t('UCG:positionUCG')}</Row>
            <Row className="pt-4">
              <img src={imgPositionUCG} />
            </Row>
          </Col>
        </CustomModal>
      </Col>
    );
  }
);

export default withRouter(translate(['Common', 'UCG'])(UCGView));
