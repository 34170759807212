import React from 'react';
import { IDeviceIcon } from '../DeviceIcons';

const Speaker = (props: IDeviceIcon) => (
  <svg
    width="3rem"
    height="3rem"
    viewBox="0 0 20000 20000"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    color="black"
    {...props}
  >
    <g id="prefix__Layer_x0020_1">
      <g id="prefix__speaker">
        <path
          style={{ fill: `${props.color}` }}
          d="M11033 13935c878 72 1710-840 1561-1751-84 93-138-165-160-18-124 862-724 1568-1400 1614-146 10 120 74-2 155zm-215-676c584-96 894-619 1070-1381 307 839-39 1442-1070 1381zm-811-2500c734-42 975 1135 95 1204-879 69-829-1162-95-1204zm-1965 221c167-892 562-1155 1176-1304 97 182-495 381-663 623-128 184-183 459-337 634-64 73-114-41-175 47zm1060-2009l-7 209c-1012 382-1117 776-1574 1655l-187 40c77-917 799-1754 1768-1904zm-104 42l300-96-300 96zm694-1003c-4671 468-3787 7293 647 6809s4024-7276-647-6809z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M14271 16494H5815c-382 0-549-285-549-593V3818c-7-455 412-697 740-705h8068c309 28 568 259 601 556v12109c7 352-212 710-568 715h165zm-8465-421h8301c129-15 201-88 209-223V3675c-10-119-99-193-206-205-2682 0-5364-3-8046-3-186-3-445 167-446 368v12064c26 139 16 184 188 174z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M9938 6915c-580 0-1044-464-1044-1044s464-1034 1044-1034 1034 464 1034 1034c10 580-454 1044-1034 1044zm0-1614c-309 0-541 271-541 541 0 309 271 541 541 541 309 0 541-271 541-541 0-271-232-541-541-541zM13496 4187c99 0 198 99 198 198s-99 198-198 198-198-99-198-198c0-107 66-198 198-198zM6554 4187c99 0 198 99 198 198s-99 198-198 198-198-99-198-198c0-107 66-198 198-198zM13496 14683c99 0 198 99 198 198s-99 198-198 198-198-99-198-198c0-107 66-198 198-198zM6554 14683c99 0 198 99 198 198s-99 198-198 198-198-99-198-198c0-107 66-198 198-198z"
        />
      </g>
    </g>
  </svg>
);

export default Speaker;
