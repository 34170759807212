import React from 'react';
import { translate, TranslationFunction } from 'react-i18next';
import { StrasbourgElectricityAdministrativeProcedureStatus } from '../constants';
import './StrasbourgElectricityAdministrativeProcedureStatusText.scss';

interface IProps {
  date: string;
  status: StrasbourgElectricityAdministrativeProcedureStatus;
  t: TranslationFunction;
}

const StrasbourgElectricityAdministrativeProcedureStatusText = ({
  t,
  date,
  status
}: IProps) => {
  const getText = `${t(
    `strasbourgElectricityAdministrativeProcedure.${status}`
  )} ${date}`;
  return (
    <span
      className={`strasbourg-electricity-administrative-procedure-text strasbourg-electricity-administrative-procedure-text--${status}`}
    >
      {getText}
    </span>
  );
};
export default translate(['MySmartBattery'])(
  StrasbourgElectricityAdministrativeProcedureStatusText
);
