import React, { ReactNode } from 'react';
import './list.scss';
import { Row } from 'reactstrap';
import classnames from 'classnames';

interface IProps {
  children: ReactNode;
  className?: string;
  options?: any;
}

const ListItem = ({ children, className, options }: IProps) => (
  <div
    className={classnames(
      'table-row',
      { clickable: options && !!options.onClick },
      className
    )}
    {...options}
  >
    {children}
  </div>
);

export default ListItem;
