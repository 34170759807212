import ListItem from '../../../ui/DataDisplay/List/ListItem';
import classnames from 'classnames';
import { Col, Row } from 'reactstrap';
import DeviceIcon from '../../../assets/images/devices/DeviceIcons';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { TranslationFunction } from 'i18next';
import { translate } from 'react-i18next';
import { Device } from '../../../stores/inscription/device';
import { DeviceCT } from '../../../stores/inscription/deviceCT';
import { CompositeDevice } from '../../../stores/inscription/compositeDevice';
import AlertNotificationIcon from '../../../ui/Notifications/AlertNotificationIcon';
import { IInscriptionNavigationRouterParamProps } from '../InscriptionNavigation';
import { GMD } from '../../../stores/devices_inscription/contants';

interface IProps
  extends RouteComponentProps<IInscriptionNavigationRouterParamProps> {
  device: Device;
  parent?: CompositeDevice;
  t: TranslationFunction;
  isUnused?: boolean;
  withoutActions?: boolean;
  withoutPower?: boolean;
  useCTPrefixInFunctionName?: boolean;
}

const DeviceFunctionItem = ({
  t,
  isUnused,
  withoutActions,
  withoutPower,
  useCTPrefixInFunctionName,
  history,
  match: {
    params: { customerId, pvInstallationId }
  },
  device,
  parent
}: IProps) => {
  const {
    getDeviceFullFunctionName,
    getDeviceShortFunctionName,
    getDeviceFunctionType,
    getDeviceFullName,
    getDevicePower,
    controlledDeviceType,
    deviceType,
    isDone,
    getDeviceFullRoomName,
    greenPlayEnabled,
    ethMac,
    plcMac,
    isNegativePower,
    getKey,
    isMst
  } = device;
  const isComposite = Boolean(
    device instanceof CompositeDevice && device.areCTSplit
  );
  const phase = parent && device instanceof DeviceCT && device.phase;
  const mac = ethMac || plcMac;
  const path = `/devices_inscription/${customerId}/functions/${pvInstallationId}/${plcMac}/${ethMac ||
    'none'}`;

  const modbusDeviceIsDisconnected =
    device.getIsModbus && !device.isDeviceConnected;

  const hasNegativePower =
    isUnused && !isMst && !modbusDeviceIsDisconnected && isNegativePower;

  const warningNegativePower =
    (deviceType === GMD || (parent && parent.deviceType === GMD)) &&
    isNegativePower;

  const generateDeviceFunctionName = () => {
    let deviceFunctionName;
    if (isUnused) {
      if (controlledDeviceType === 'production_counter_ignored') {
        deviceFunctionName = getDeviceFullFunctionName;
      } else {
        deviceFunctionName = getDeviceShortFunctionName;
      }
    } else {
      if (parent) {
        return `${t(`DeviceType:${parent.getDeviceType}`)} - CT${phase}`;
      }
      deviceFunctionName = getDeviceFullName;
    }
    if (useCTPrefixInFunctionName) {
      deviceFunctionName = `${deviceFunctionName} - CT${phase}`;
    }
    return deviceFunctionName;
  };

  return (
    <ListItem
      className={classnames(
        'registration-function-item',
        isComposite && 'for-composite'
      )}
      key={getKey}
      options={
        !withoutActions && {
          onClick: async () => {
            await history.push(path);
          }
        }
      }
    >
      <Row className={'function-container'}>
        <Col className={'device-info'}>
          {!isUnused && (
            <Row>
              <Col className={'device-function'}>
                {getDeviceFullFunctionName}
              </Col>
            </Row>
          )}
          <Row>
            <Col className={'device-icon'}>
              <DeviceIcon
                type={controlledDeviceType || ''}
                height={'60px'}
                width={'60px'}
              />
            </Col>
            <Col className={'device-details'}>
              <div className="device-type">{generateDeviceFunctionName()}</div>
              {greenPlayEnabled &&
                !hasNegativePower && (
                  <span className="green-play">
                    {t('Devices:greenPlayLabel')}
                  </span>
                )}
              <div className="device-mac">
                {isUnused
                  ? controlledDeviceType
                    ? getDeviceFunctionType
                    : getDeviceFullName
                  : mac}
              </div>
              {(!isUnused || !isDone) && (
                <div className="device-room-name">{getDeviceFullRoomName}</div>
              )}
            </Col>
          </Row>
        </Col>
        {device.isForbidden && (
          <span className={'notification-icon modbus-disconnected'}>
            <AlertNotificationIcon
              alert={{
                label: t(`Devices:forbiddenDeviceForGridType`)
              }}
              icon={'exclamation-circle'}
            />
          </span>
        )}
        {/* When device isn't split and have function defined*/}
        {!withoutPower &&
          !isComposite &&
          controlledDeviceType && (
            <Col className={'device-power'}>
              {warningNegativePower && (
                <span className={'notification-icon'}>
                  <AlertNotificationIcon
                    alert={{ label: t('Devices:negativePower') }}
                    icon={'exclamation-circle'}
                  />
                </span>
              )}
              {!modbusDeviceIsDisconnected && (
                <span
                  className={classnames(
                    'badge',
                    'badge-pill',
                    warningNegativePower ? 'badge-warning' : 'badge-success'
                  )}
                >
                  {getDevicePower ? getDevicePower.toFixed(1) : 0} W
                </span>
              )}
            </Col>
          )}
        {path &&
          !withoutActions && (
            <Col className={'device-button'}>
              <span>
                <i className="icon-full-arrow-right" />
              </span>
            </Col>
          )}
      </Row>
      {/* When a composite device doesn't have function selected - use case now impossible ? */}
      {isUnused &&
        isComposite && (
          <Row>
            <Col className={'composite-device'}>
              {device instanceof CompositeDevice &&
                device.cts.filter(c => c.isTodo).map((e: DeviceCT) => (
                  <Row
                    key={`${e.getKey}`}
                    className={'composite-device-children'}
                  >
                    <Col className={'children-phase'}>- CT{e.phase}</Col>
                    {!withoutPower && (
                      <Col className={'children-power'}>
                        {e.phase && e.ctPower !== undefined ? (
                          <span
                            className={classnames(
                              'badge',
                              'badge-pill',
                              deviceType === GMD && e.isNegativePower
                                ? 'badge-warning'
                                : 'badge-success'
                            )}
                          >
                            {e.ctPower.toFixed(1)} W
                          </span>
                        ) : (
                          ''
                        )}
                      </Col>
                    )}
                  </Row>
                ))}
            </Col>
          </Row>
        )}
    </ListItem>
  );
};

export default withRouter(
  translate(['Common', 'Devices', 'RoomName', 'DevicesFunctions'])(
    DeviceFunctionItem
  )
);
