import { default as React, ReactNode, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import ResponsiveSubMenu from '../../../ui/SubMenu/ResponsiveSubMenu';
import { TranslationFunction } from 'i18next';
import { translate } from 'react-i18next';
import { Query, QueryResult } from 'react-apollo';
import CustomerHeader from '../../../ui/CustomerHeader/CustomerHeader';
import FixedHeader from '../../../ui/CustomerHeader/FixedHeader/FixedHeader';
import { inject, observer } from 'mobx-react';
import { Store } from '../../../stores';
import { Customer } from '../../../graphql/graphql.schema';
import { extractCustomer } from '../customersList/customerExtractor';
import { GET_CUSTOMER } from '../identity/IdentityQuery';
import Spinner from '../../../ui/Spinner/Spinner';
import { BREAK_POINT_SAFE_MIN_WIDTH } from '../../../constants/BREAK_POINT_WIDTH';
import MediaQuery from 'react-responsive';

export interface IMenuItem {
  key: string;
  name: string;
  path: string;
  hidden?: boolean;
}

interface IProps extends RouteComponentProps<IMatchParams> {
  children?: ReactNode;
  baseRoute?: string;
  previousRoute: string;
  Store?: Store;
  t: TranslationFunction;
}

interface IMatchParams {
  id: string;
  current: string;
}

interface IResponse {
  customer: Customer;
}
const CustomerMenu = inject('Store')(
  observer(
    ({
      Store: store,
      children,
      match: {
        params: { current, id }
      },
      location,
      t
    }: IProps) => {
      const customerMenuItems: IMenuItem[] = [
        {
          key: 'identity',
          name: t('identity'),
          path: 'identity'
        },
        {
          key: 'proContact',
          name: t('contactPro'),
          path: 'pro_contact'
        },
        {
          key: 'instalation',
          name: t('installation'),
          path: 'installation'
        },
        {
          key: 'mylight_systems',
          name: t('system'),
          path: 'systems'
        },
        {
          key: 'tools_and_support',
          name: t('support'),
          path: 'support'
        }
      ];
      const { setTitle } = store!.mainMenu!;
      return (
        <Query query={GET_CUSTOMER} variables={{ id }} fetchPolicy={'no-cache'}>
          {({ loading, data, error }: QueryResult<IResponse>) => {
            const customer =
              data && data.customer
                ? extractCustomer(data.customer)
                : undefined;
            setTitle(
              customer
                ? `${
                    customer.lastName ? customer.lastName.toUpperCase() : ''
                  } ${customer.firstName}`
                : undefined
            );
            return (
              <div>
                <MediaQuery
                  query={`(min-width: ${BREAK_POINT_SAFE_MIN_WIDTH})`}
                >
                  <Spinner
                    loading={loading}
                    error={error ? error.message : undefined}
                    style={loading ? { height: '90px' } : {}}
                  >
                    <CustomerHeader
                      customer={customer}
                      loading={loading}
                      error={error ? error.message : undefined}
                    />
                  </Spinner>
                </MediaQuery>
                <ResponsiveSubMenu
                  menuItems={customerMenuItems}
                  baseRoute={`/customers/${id}`}
                  previousRoute="/customers/list"
                  currentItem={customerMenuItems.find(m => m.path === current)}
                  fixedHeaderContent={
                    <FixedHeader
                      customer={customer}
                      loading={loading}
                      error={error}
                    />
                  }
                >
                  {children}
                </ResponsiveSubMenu>
              </div>
            );
          }}
        </Query>
      );
    }
  )
);
export default translate(['SubMenu', 'RegistrationStatus', 'Customer'])(
  withRouter(CustomerMenu)
);
