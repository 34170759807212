import React, { ReactNode } from 'react';
import { inject, observer } from 'mobx-react';
import { IAllStores } from '../../../stores/allStores.model';
import { translate } from 'react-i18next';
import { Device } from '../../../stores/inscription/device';
import { IDeviceDetailsProps } from './DeviceDetail';
import { withRouter } from 'react-router';
import { CompositeDevice } from '../../../stores/inscription/compositeDevice';
import MSTImage from '../../../assets/images/devices/devicetype/master.png';
import MSTG3Image from '../../../assets/images/devices/devicetype/mst_g3.png';
import GMDImage from '../../../assets/images/devices/devicetype/gmd.png';
import SWImage from '../../../assets/images/devices/devicetype/spc.png';
import TPHImage from '../../../assets/images/devices/devicetype/tph.png';
import ETHImage from '../../../assets/images/devices/devicetype/spe.png';
import RS485MC1 from '../../../assets/images/devices/devicetype/rs485_MC1.png';
import RS485MC3 from '../../../assets/images/devices/devicetype/rs485_MC3.png';
import classnames from 'classnames';

const DeviceImage = inject(({ inscription }: IAllStores) => {
  return {
    getEditedDevice: inscription.getEditedDevice
  };
})(
  observer(
    ({
      t,
      match: {
        params: {
          ethMac: ethMacFromRouter,
          plcMac: plcMacFromRouter,
          pvInstallationId,
          customerId
        }
      },
      history,
      getEditedDevice
    }: IDeviceDetailsProps) => {
      const editedDevice: Device | undefined = getEditedDevice!({
        ethMac: ethMacFromRouter,
        plcMac: plcMacFromRouter
      });
      const Image: { [key: string]: string } = {
        mst: MSTImage,
        mst_g3: MSTG3Image,
        gmd: GMDImage,
        gmd_g3: MSTG3Image,
        sw: SWImage,
        sw_g3: MSTG3Image,
        tph: TPHImage,
        eth: ETHImage,
        rs485_mc1: RS485MC1,
        rs485_mc3: RS485MC3
      };
      if (editedDevice) {
        const { getDeviceType } = editedDevice;
        let imageType = getDeviceType;

        if (editedDevice instanceof CompositeDevice) {
          const { isModbus, getModbusReferenceId } = editedDevice;
          if (isModbus && getModbusReferenceId) {
            imageType = getModbusReferenceId;
          }
        }

        return (
          <img
            src={Image[imageType]}
            className={classnames('device-image', imageType)}
          />
        );
      }
      return <div />;
    }
  )
);

export default withRouter(translate(['Devices'])(DeviceImage));
