import * as React from 'react';
import { LoginBox } from '../../ui/LoginBox/LoginBox';

import { Alert, Button, Col, Form, FormGroup } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { FormInput } from '../../ui/FormInput/FormInput';
import { ApolloError } from 'apollo-client';
import LoadingButton from '../../ui/Button/LoadingButton/LoadingButton';
import { TranslationFunction } from 'i18next';
import { translate } from 'react-i18next';
import LoginActionWrapper from '../../ui/Wrapper/LoginActionWrapper';
import LinksContainer from '../../ui/LoginBox/LinksContainer';
import eye from '../../assets/images/eye.svg';
import eyeIn from '../../assets/images/eye-in.svg';
import { Link } from 'react-router-dom';
import { IAllStores } from '../../stores/allStores.model';
import { Store } from '../../stores';

interface IPropsType {
  t: TranslationFunction;
  sending?: boolean;
  onSubmit: (a: { email: string; password: string }) => void;
  mobix?: Store;
  accountCreationUrl?: string;
  error?: ApolloError;
}

const LoginForm = inject((allStores: IAllStores) => {
  return {
    mobix: allStores.Store,
    accountCreationUrl: allStores.environment.accountCreationUrl
  };
})(
  observer(
    ({
      t,
      onSubmit,
      sending,
      error,
      mobix,
      accountCreationUrl
    }: IPropsType) => {
      const {
        loginForm: {
          emailField,
          passwordField,
          updateEmail,
          updatePassword,
          showPassword,
          setShowPassword
        }
      } = mobix!;
      return (
        <LoginBox>
          <Form
            onSubmit={event => {
              event.preventDefault();
              if (!sending) {
                onSubmit({
                  email: emailField,
                  password: passwordField
                });
              }
            }}
          >
            <Col mx="2" xs="12">
              <FormGroup>
                <FormInput
                  id="loginEmail"
                  type="email"
                  name={t('email')}
                  value={emailField}
                  onChange={updateEmail}
                />
              </FormGroup>
              <div className="password-field">
                <FormGroup>
                  <FormInput
                    id="loginPassword"
                    type={showPassword ? 'text' : 'password'}
                    name={t('password')}
                    value={passwordField}
                    onChange={updatePassword}
                  />
                </FormGroup>
                <Button
                  color="secondary"
                  type={'button'}
                  onMouseDown={() => {
                    setShowPassword(true);
                  }}
                  onTouchStart={() => {
                    setShowPassword(true);
                  }}
                  onMouseUp={() => {
                    setShowPassword(false);
                  }}
                  onTouchEnd={() => {
                    setShowPassword(false);
                  }}
                >
                  <img
                    src={showPassword ? eyeIn : eye}
                    height={'20px'}
                    alt="show"
                  />
                </Button>
              </div>
            </Col>
            {error && (
              <Col mx="2" xs="12">
                <Alert className="login-error" color="danger">
                  {t('error')}
                </Alert>
              </Col>
            )}
            <LoginActionWrapper>
              <LoadingButton
                loading={sending}
                onClick={() => {
                  onSubmit({
                    email: emailField,
                    password: passwordField
                  });
                }}
              >
                {t('connection')}
              </LoadingButton>
            </LoginActionWrapper>
            <LinksContainer>
              <Link to="/forgotPassword">
                {t('ForgotPassword:forgotPasswordLink')}
              </Link>
              <a
                href={ accountCreationUrl }
              >
                {t('Login:accountCreationLink')}
              </a>
            </LinksContainer>
          </Form>
        </LoginBox>
      );
    }
  )
);

export default translate(['Login', 'ForgotPassword'])(LoginForm);
