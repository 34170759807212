import React, { useEffect } from 'react';
import IsolatedComponent from '../../../../../ui/IsolatedComponent/IsolatedComponent';
import { translate, TranslationFunction } from 'react-i18next';
import { Button, Col, Collapse, Form, Row } from 'reactstrap';
import { RouteComponentProps, withRouter } from 'react-router';
import SelectInput from '../../../../../ui/Select/Select';
import Spinner from '../../../../../ui/Spinner/Spinner';
import AddModbusDeviceButton from './AddModbusDeviceButton';

import '../addDevice.scss';
import { IInscriptionNavigationRouterParamProps } from '../../../InscriptionNavigation';
import { ModBusReference } from '../../../../../graphql/graphql.schema';
import { safeMobxInject } from '../../../../../stores/storeInjectionHelpers';

interface IProps
  extends RouteComponentProps<IInscriptionNavigationRouterParamProps> {
  t: TranslationFunction;
  loading?: boolean;
}

interface IStoresProps {
  chosenReference?: ModBusReference;
  chosenAddress?: string;
  availableReferences: string[];
  availableAddresses: string[];
  setChosenReference: (reference: string) => void;
  setChosenAddress: (address: string) => void;
  canAddRS485Device: boolean;
  resetAddDeviceStore: () => void;
}

const AddModbusDevice = safeMobxInject<IStoresProps, IProps>(
  allStores => ({
    availableReferences: allStores.addDevice.availableReferences,
    availableAddresses: allStores.addDevice.availableAddresses,
    setChosenReference: allStores.addDevice.setChosenReference,
    setChosenAddress: allStores.addDevice.setChosenAddress,
    canAddRS485Device: allStores.addDevice.canAddRS485Device,
    resetAddDeviceStore: allStores.addDevice.resetAddDeviceStore,
    chosenReference: allStores.addDevice.chosenReference,
    chosenAddress: allStores.addDevice.chosenAddress
  }),
  ({
    t,
    history,
    match: {
      params: { customerId, pvInstallationId }
    },
    loading,
    availableReferences,
    availableAddresses,
    setChosenReference,
    setChosenAddress,
    canAddRS485Device,
    chosenAddress,
    chosenReference,
    resetAddDeviceStore
  }: IStoresProps & IProps) => {
    useEffect(() => {
      return () => {
        resetAddDeviceStore();
      };
    }, []);

    const goBackToDeviceConnectionTypesList = () =>
      history.push(
        `/devices_inscription/${customerId}/devices/${pvInstallationId}/add`
      );
    return (
      <IsolatedComponent
        title={t('rs485Type')}
        subtitle={t('addDeviceTitleRS485')}
        sm={10}
        md={9}
        lg={6}
        xl={5}
        onGoBack={goBackToDeviceConnectionTypesList}
      >
        <Spinner loading={loading}>
          <Col className={'add-device rs485'}>
            <Form className={'form-wrapper'}>
              <Row>
                <Col>
                  <SelectInput
                    options={availableReferences.map(r => ({
                      label: r,
                      value: r
                    }))}
                    name={t('Devices:modbusReference')}
                    id={'reference'}
                    onChange={e => setChosenReference(e.value)}
                    placeholder={t('Devices:chooseReference')}
                    value={
                      chosenReference
                        ? {
                            label: chosenReference.reference,
                            value: chosenReference.reference
                          }
                        : null
                    }
                  />
                </Col>
              </Row>
              <Collapse isOpen={!!chosenReference}>
                <Row>
                  <Col>
                    <SelectInput
                      options={availableAddresses.map(r => ({
                        label: r,
                        value: r
                      }))}
                      name={t('Devices:modbusAddress')}
                      id={'modbus'}
                      onChange={e => setChosenAddress(e.value)}
                      placeholder={t('Devices:chooseAddress')}
                      value={
                        chosenAddress
                          ? {
                              label: chosenAddress,
                              value: chosenAddress
                            }
                          : null
                      }
                    />
                  </Col>
                </Row>
              </Collapse>
              <Row>
                <Col className={'add-device-button rs485'}>
                  <Button
                    color={'secondary'}
                    onClick={goBackToDeviceConnectionTypesList}
                  >
                    {t('Common:cancel')}
                  </Button>
                  <AddModbusDeviceButton disabled={!canAddRS485Device} />
                </Col>
              </Row>
            </Form>
          </Col>
        </Spinner>
      </IsolatedComponent>
    );
  }
);

export default withRouter(
  translate(['Devices', 'Master', 'Common'])(AddModbusDevice)
);
