import React from 'react';

export const convertTranslation = (translation: string) => {
  return translation.split('\n').map((item, key) => (
    <span key={key}>
      {item}
      <br />
    </span>
  ));
};
