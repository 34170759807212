import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { translate, TranslationFunction } from 'react-i18next';
import { safeMobxInject } from '../../../../../../stores/storeInjectionHelpers';
import { withRouter } from 'react-router-dom';
import { ListTitle } from '../../../../../../ui/DataDisplay/List/ListHeader';
import moment from 'moment';
import { MySmartBattery } from '../../../../../../stores/my_smart_battery/mySmartBattery';
import { ActivationMySmartBatteryEnedisGridInjection } from '../../../../../../stores/my_smart_battery/activationMySmartBatteryEnedisGridInjection';
import {
  consuelDeclaredPowerValidator,
  connectionPowerValidator,
  consuelNumberValidator,
  enedisConnectNumberValidator
} from '../../../../../../utils/validators';
import { withApollo, WithApolloClient } from 'react-apollo';
import EnedisGridInjectionStatusText from './EnedisGridInjectionStatusText';
import { EnedisGridInjectionStatus } from '../constants';
import MsbActivationUploadFileConsuel from '../MsbActivationUploadFileConsuel';
import { MsbInputText } from '../MsbInputText';
import { MsbInlineForm } from '../MsbInlineForm';
import { MsbInputNumber } from '../MsbInputNumber';

import { CustomField } from './../../../../../../ui/DataDisplay/FieldList/FieldList';
import MsbDeletionModalOpenButton from './../../deletion/MsbDeletionModalOpenButton';
import { Profile } from './../../../../../../stores/profile';

interface IStoresProps {
  mySmartBattery: MySmartBattery;
  activationMsbEnedisGridInjection: ActivationMySmartBatteryEnedisGridInjection;
  profile: Profile;
}

interface IProps extends RouteComponentProps<IMatchParams> {
  t: TranslationFunction;
}

interface IMatchParams {
  id: string;
}

interface IHelperTexts {
  enedisConnectNumberText: string;
  consuelNumberText: string;
  consuelDeclaredPowerText: string;
  connectionPowerText: string;
}

const EnedisGridInjectionRequested = safeMobxInject<IStoresProps, IProps>(
  stores => ({
    mySmartBattery: stores.mySmartBattery,
    activationMsbEnedisGridInjection:
      stores.activationMySmartBatteryEnedisGridInjection,
    profile: stores.Store.profile
  }),

  ({
    t,
    mySmartBattery,
    activationMsbEnedisGridInjection,
    profile,
    client,
    match: {
      params: { id: customerId }
    }
  }: WithApolloClient<IProps & IStoresProps>) => {
    useEffect(
      () => {
        setEnedisConnectNumber(
          activationMsbEnedisGridInjection.enedisConnectNumber
        );
        setConsuelDeclaredPower(
          activationMsbEnedisGridInjection.consuelDeclaredPower
        );
        setConnectionPower(
          activationMsbEnedisGridInjection.connectionPower
        );
        setConsuelNumber(activationMsbEnedisGridInjection.consuelNumber);
      },
      [
        activationMsbEnedisGridInjection.enedisConnectNumber,
        activationMsbEnedisGridInjection.consuelDeclaredPower,
        activationMsbEnedisGridInjection.connectionPower,
        activationMsbEnedisGridInjection.consuelNumberSaved
      ]
    );

    useEffect(() => {
      setHelperTexts({
        enedisConnectNumberText: setEnedisConnectNumberInfoHelperText(),
        consuelNumberText: setConsuelNumberInfoHelperText(),
        consuelDeclaredPowerText: setConsuelDeclaredPowerInfoHelperText(),
        connectionPowerText: setConnectionPowerInfoHelperText(),
      });
    },
    [
      activationMsbEnedisGridInjection.enedisConnectNumberSaved,
      activationMsbEnedisGridInjection.consuelDeclaredPowerSaved,
      activationMsbEnedisGridInjection.connectionPowerSaved,
      activationMsbEnedisGridInjection.consuelNumberSaved
    ]);

    const DATE_FORMAT = t('MySmartBattery:enteredOnFormatDate');

    const defaultInfoHelperTexts = {
      ENEDIS_CONNECT_NUMBER_DEFAULT_TEXT: t('MySmartBattery:enedisGridInjectionFormEnedisConnectFieldDescription'),
      CONSUEL_NUMBER_DEFAULT_TEXT: t('MySmartBattery:enedisGridInjectionFormConsuelNumberFieldDescription'),
      CONSUEL_DECLARED_POWER_DEFAULT_TEXT: t('MySmartBattery:enedisGridInjectionFormDeclaredInstalledPowerFieldDescription'),
      CONNECTION_POWER_DEFAULT_TEXT: t('MySmartBattery:enedisGridInjectionFormConnectionPowerFieldDescription'),
    };

    const [enedisConnectNumber, setEnedisConnectNumber] = useState(
      activationMsbEnedisGridInjection.enedisConnectNumber
    );
    const [
      isEnedisConnectNumberLoading,
      setIsEnedisConnectNumberLoading
    ] = useState(false);

    const [
      enedisConnectNumberTextError,
      setEnedisConnectNumberTextError
    ] = useState<string | undefined>(undefined);

    const [consuelNumber, setConsuelNumber] = useState(
      activationMsbEnedisGridInjection.consuelNumber
    );
    const [isConsuelNumberLoading, setIsConsuelNumberLoading] = useState(false);
    const [consuelNumberTextError, setConsuelNumberTextError] = useState<
      string | undefined
    >(undefined);

    const [consuelDeclaredPower, setConsuelDeclaredPower] = useState(
      activationMsbEnedisGridInjection.consuelDeclaredPower
    );

    const [connectionPower, setConnectionPower] = useState(
      activationMsbEnedisGridInjection.connectionPower
    );

    const [
      isConsuelDeclaredPowerLoading,
      setIsConsuelDeclaredPowerLoading
    ] = useState(false);
    const [
      consuelDeclaredPowerTextError,
      setConsuelDeclaredPowerTextError
    ] = useState<string | undefined>(undefined);

    const [
      isConnectionPowerLoading,
      setIsConnectionPowerLoading
    ] = useState(false);

    const [
      connectionPowerTextError,
      setConnectionPowerTextError
    ] = useState<string | undefined>(undefined);

    const getDateEnedisGridInjectionStatus = () => {
      if (mySmartBattery.isMSBEnedisGridInjectionRequested) {
        const validateAt =
          mySmartBattery.msbEnedisGridInjectionRequestedStatus &&
          mySmartBattery.msbEnedisGridInjectionRequestedStatus.validateAt;
        if (validateAt) {
          return moment(validateAt).format(DATE_FORMAT);
        }
      }
      return '';
    };

    const getTextEnedisGridInjectionStatus = () => {
      if (mySmartBattery.isMSBEnedisGridInjectionRequested) {
        return EnedisGridInjectionStatus.REQUESTED;
      }

      if (
        !activationMsbEnedisGridInjection.consuelNumberSaved &&
        !activationMsbEnedisGridInjection.consuelDeclaredPowerSaved &&
        !activationMsbEnedisGridInjection.connectionPowerSaved &&
        !activationMsbEnedisGridInjection.enedisConnectNumberSaved &&
        !activationMsbEnedisGridInjection.sendingCONSUELDate
      ) {
        return EnedisGridInjectionStatus.FILL;
      }

      if (
        !activationMsbEnedisGridInjection.consuelNumberSaved ||
        !activationMsbEnedisGridInjection.consuelDeclaredPowerSaved ||
        !activationMsbEnedisGridInjection.connectionPowerSaved ||
        !activationMsbEnedisGridInjection.enedisConnectNumberSaved ||
        !activationMsbEnedisGridInjection.sendingCONSUELDate
      ) {
        return EnedisGridInjectionStatus.IN_PROGRESS;
      }

      return EnedisGridInjectionStatus.TO_VALIDATE;
    };

    const getTextEnedisConnectNumberModifiedAt = () => {
      return activationMsbEnedisGridInjection.enedisConnectNumberModifiedAt ===
        null
        ? ''
        : `${t('enteredOn')} ${moment(
            activationMsbEnedisGridInjection.enedisConnectNumberModifiedAt
          ).format(DATE_FORMAT)}`;
    };

    const onChangeEnedisConnectNumberField = (value: string) => {
      setEnedisConnectNumber(value);
      activationMsbEnedisGridInjection.enedisConnectNumberInputState = undefined;

      setHelperTexts({
        ...helperTexts,
        enedisConnectNumberText: defaultInfoHelperTexts.ENEDIS_CONNECT_NUMBER_DEFAULT_TEXT,
      });
    };

    const onBlurEnedisConnectNumberField = () => {
      if (isEnedisConnectNumberChanged()) {
        const { isValid, error } = checkEnedisConnectNumberFormat();
        if (isValid) {
          updateEnedisConnectNumber();
        } else {
          activationMsbEnedisGridInjection.enedisConnectNumberInputState =
            'error';
          setEnedisConnectNumberTextError(error);
        }
      }

      setHelperTexts({
        ...helperTexts,
        enedisConnectNumberText: setEnedisConnectNumberInfoHelperText(),
      });
    };

    const isEnedisConnectNumberChanged = () => {
      return (
        enedisConnectNumber !==
        activationMsbEnedisGridInjection.enedisConnectNumberSaved
      );
    };

    const checkEnedisConnectNumberFormat = () => {
      const error: string | undefined = enedisConnectNumberValidator(
        enedisConnectNumber
      );
      const isValid = !error;
      return {
        isValid,
        error
      };
    };

    const updateEnedisConnectNumber = async () => {
      setIsEnedisConnectNumberLoading(true);
      try {
        await activationMsbEnedisGridInjection.updateEnedisConnectNumber(
          client,
          customerId,
          enedisConnectNumber
        );
      } catch (e) {
        setEnedisConnectNumberTextError(e);
      }
      setIsEnedisConnectNumberLoading(false);
    };

    const getTextConsuelNumberModifiedAt = () => {
      return activationMsbEnedisGridInjection.consuelNumberModifiedAt === null
        ? ''
        : `${t('enteredOn')} ${moment(
            activationMsbEnedisGridInjection.consuelNumberModifiedAt
          ).format(DATE_FORMAT)}`;
    };

    const onChangeConsuelNumberField = (value: string) => {
      setConsuelNumber(value);
      activationMsbEnedisGridInjection.consuelNumberInputState = undefined;

      setHelperTexts({
        ...helperTexts,
        consuelNumberText: defaultInfoHelperTexts.CONSUEL_NUMBER_DEFAULT_TEXT,
      });
    };

    const onBlurConsuelNumberField = () => {
      if (isConsuelNumberChanged()) {
        const { isValid, error } = checkConsuelNumberFormat();
        if (isValid) {
          updateConsuelNumber();
        } else {
          activationMsbEnedisGridInjection.consuelNumberInputState = 'error';
          setConsuelNumberTextError(error);
        }
      }

      setHelperTexts({
        ...helperTexts,
        consuelNumberText: setConsuelNumberInfoHelperText(),
      });
    };

    const isConsuelNumberChanged = () => {
      return (
        consuelNumber !== activationMsbEnedisGridInjection.consuelNumberSaved
      );
    };

    const checkConsuelNumberFormat = () => {
      const error: string | undefined = consuelNumberValidator(consuelNumber);
      const isValid = !error;
      return {
        isValid,
        error
      };
    };

    const updateConsuelNumber = async () => {
      setIsConsuelNumberLoading(true);
      try {
        await activationMsbEnedisGridInjection.updateConsuelNumber(
          client,
          customerId,
          consuelNumber
        );
      } catch (e) {
        setConsuelNumberTextError(e);
      }
      setIsConsuelNumberLoading(false);
    };

    const getTextConsuelDeclaredPowerModifiedAt = () => {
      return activationMsbEnedisGridInjection.consuelDeclaredPowerModifiedAt ===
        null
        ? ''
        : `${t('enteredOn')} ${moment(
            activationMsbEnedisGridInjection.consuelDeclaredPowerModifiedAt
          ).format(DATE_FORMAT)}`;
    };

    const onChangeConsuelDeclaredPowerField = (value: number) => {
      setConsuelDeclaredPower(value);
      activationMsbEnedisGridInjection.consuelDeclaredPowerInputState = undefined;

      setHelperTexts({
        ...helperTexts,
        consuelDeclaredPowerText: defaultInfoHelperTexts.CONSUEL_DECLARED_POWER_DEFAULT_TEXT,
      });
    };

    const onBlurConsuelDeclaredPowerField = () => {
      if (isConsuelDeclaredPowerChanged()) {
        const { isValid, error } = checkConsuelDeclaredPowerFormat();
        if (isValid) {
          updateConsuelDeclaredPower();
        } else {
          activationMsbEnedisGridInjection.consuelDeclaredPowerInputState =
            'error';
          setConsuelDeclaredPowerTextError(error);
        }
      }

      setHelperTexts({
        ...helperTexts,
        consuelDeclaredPowerText: setConsuelDeclaredPowerInfoHelperText(),
      });
    };

    const isConsuelDeclaredPowerChanged = () => {
      return (
        consuelDeclaredPower !==
        activationMsbEnedisGridInjection.consuelDeclaredPowerSaved
      );
    };

    const checkConsuelDeclaredPowerFormat = () => {
      const error: string | undefined = consuelDeclaredPowerValidator(
        consuelDeclaredPower
      );
      const isValid = !error;
      return {
        isValid,
        error
      };
    };

    const updateConsuelDeclaredPower = async () => {
      setIsConsuelDeclaredPowerLoading(true);
      try {
        await activationMsbEnedisGridInjection.updateConsuelDeclaredPower(
          client,
          customerId,
          consuelDeclaredPower as number
        );
      } catch (e) {
        setConsuelDeclaredPowerTextError(e);
      }
      setIsConsuelDeclaredPowerLoading(false);
    };

    const getTextConnectionPowerModifiedAt = () => {
      return activationMsbEnedisGridInjection.connectionPowerModifiedAt ===
        null
        ? ''
        : `${t('enteredOn')} ${moment(
            activationMsbEnedisGridInjection.connectionPowerModifiedAt
          ).format(DATE_FORMAT)}`;
    };

    const onChangeConnectionPowerField = (value: number) => {
      setConnectionPower(value);
      activationMsbEnedisGridInjection.connectionPowerInputState = undefined;

      setHelperTexts({
        ...helperTexts,
        connectionPowerText: defaultInfoHelperTexts.CONNECTION_POWER_DEFAULT_TEXT,
      });
    };

    const onBlurConnectionPowerField = () => {
      if (isConnectionPowerChanged()) {
        const { isValid, error } = checkConnectionPowerFormat();
        if (isValid) {
          updateConnectionPower();
        } else {
          activationMsbEnedisGridInjection.connectionPowerInputState =
            'error';
          setConnectionPowerTextError(error);
        }
      }

      setHelperTexts({
        ...helperTexts,
        connectionPowerText: setConnectionPowerInfoHelperText(),
      });
    };

    const isConnectionPowerChanged = () => {
      return (
        connectionPower !==
        activationMsbEnedisGridInjection.connectionPowerSaved
      );
    };

    const checkConnectionPowerFormat = () => {
      const error: string | undefined = connectionPowerValidator(
        connectionPower
      );
      const isValid = !error;
      return {
        isValid,
        error
      };
    };

    const updateConnectionPower = async () => {
      setIsConnectionPowerLoading(true);
      try {
        await activationMsbEnedisGridInjection.updateConnectionPower(
          client,
          customerId,
          connectionPower as number
        );
      } catch (e) {
        setConnectionPowerTextError(e);
      }
      setIsConnectionPowerLoading(false);
    };
    
    const setEnedisConnectNumberInfoHelperText = (): string => {
      return activationMsbEnedisGridInjection.enedisConnectNumber 
        ? getTextEnedisConnectNumberModifiedAt()
        : defaultInfoHelperTexts.ENEDIS_CONNECT_NUMBER_DEFAULT_TEXT;
    };

    const setConsuelNumberInfoHelperText = (): string => {
      return activationMsbEnedisGridInjection.consuelNumber 
        ? getTextConsuelNumberModifiedAt()
        : defaultInfoHelperTexts.CONSUEL_NUMBER_DEFAULT_TEXT;
    };

    const setConsuelDeclaredPowerInfoHelperText = (): string => {
      return activationMsbEnedisGridInjection.consuelDeclaredPower 
        ? getTextConsuelDeclaredPowerModifiedAt()
        : defaultInfoHelperTexts.CONSUEL_DECLARED_POWER_DEFAULT_TEXT;
    };

    const setConnectionPowerInfoHelperText = (): string => {
      return activationMsbEnedisGridInjection.connectionPower 
        ? getTextConnectionPowerModifiedAt()
        : defaultInfoHelperTexts.CONNECTION_POWER_DEFAULT_TEXT;
    };

    const [helperTexts, setHelperTexts] = useState<IHelperTexts>({
      enedisConnectNumberText: setEnedisConnectNumberInfoHelperText(),
      consuelNumberText: setConsuelNumberInfoHelperText(),
      consuelDeclaredPowerText: setConsuelDeclaredPowerInfoHelperText(),
      connectionPowerText: setConnectionPowerInfoHelperText(),
    });

    const canMsbBeDeleted = mySmartBattery.isMSBActivated && profile.isAdmin;

    return (
      <>
        {!mySmartBattery.isMSBActivated && 
          <ListTitle
            text={t('networkInjection')}
            children={
              !mySmartBattery.isMSBActivated && (
                <EnedisGridInjectionStatusText
                  date={getDateEnedisGridInjectionStatus()}
                  status={getTextEnedisGridInjectionStatus()}
                />
              )
            }
          />
        }

        {!activationMsbEnedisGridInjection.sendingCONSUELDate && (
          <p className="enedis-grid-injection-description">
            { t('MySmartBattery:enedisGridInjectionFormDescription') }
          </p>
        )}

        <MsbInlineForm
          htmlFor="enedisConnectNumber"
          label={t('enedisConnectNumber')}
        >
          <MsbInputText
            disabled={
              mySmartBattery.isMSBActivated ||
              mySmartBattery.isMSBEnedisGridInjectionRequested
            }
            id="enedisConnectNumber"
            loading={isEnedisConnectNumberLoading}
            inputState={
              activationMsbEnedisGridInjection.enedisConnectNumberInputState
            }
            name="enedisConnectNumber"
            value={enedisConnectNumber}
            onChange={value => onChangeEnedisConnectNumberField(value)}
            onBlur={() => onBlurEnedisConnectNumberField()}
            helperText={{
              info: helperTexts.enedisConnectNumberText,
              error: enedisConnectNumberTextError
            }}
          />
        </MsbInlineForm>
        <MsbInlineForm htmlFor="consuelNumber" label={t('consuelNumber')}>
          <MsbInputText
            disabled={
              mySmartBattery.isMSBActivated ||
              mySmartBattery.isMSBEnedisGridInjectionRequested
            }
            id="consuelNumber"
            loading={isConsuelNumberLoading}
            inputState={
              activationMsbEnedisGridInjection.consuelNumberInputState
            }
            name="consuelNumber"
            value={consuelNumber}
            onChange={value => onChangeConsuelNumberField(value)}
            onBlur={() => onBlurConsuelNumberField()}
            helperText={{
              info: helperTexts.consuelNumberText,
              error: consuelNumberTextError
            }}
          />
        </MsbInlineForm>
        <MsbInlineForm
          htmlFor="consuelDeclaredPower"
          label={t('consuelDeclaredPower')}
        >
          <MsbInputNumber
            disabled={
              mySmartBattery.isMSBActivated ||
              mySmartBattery.isMSBEnedisGridInjectionRequested
            }
            id="consuelDeclaredPower"
            loading={isConsuelDeclaredPowerLoading}
            inputState={
              activationMsbEnedisGridInjection.consuelDeclaredPowerInputState
            }
            name="consuelDeclaredPower"
            value={consuelDeclaredPower}
            min={0.01}
            step={0.01}
            onChange={value => onChangeConsuelDeclaredPowerField(value)}
            onBlur={() => onBlurConsuelDeclaredPowerField()}
            helperText={{
              info: helperTexts.consuelDeclaredPowerText,
              error: consuelDeclaredPowerTextError
            }}
            addonRight={'kVa'}
          />
        </MsbInlineForm>

        <MsbInlineForm
          htmlFor="connectionPower"
          label={t('connectionPower')}
        >
          <MsbInputNumber
            disabled={
              mySmartBattery.isMSBActivated ||
              mySmartBattery.isMSBEnedisGridInjectionRequested
            }
            id="connectionPower"
            loading={isConnectionPowerLoading}
            inputState={
              activationMsbEnedisGridInjection.connectionPowerInputState
            }
            name="connectionPower"
            value={connectionPower}
            min={0.01}
            step={0.01}
            onChange={value => onChangeConnectionPowerField(value)}
            onBlur={() => onBlurConnectionPowerField()}
            helperText={{
              info: helperTexts.connectionPowerText,
              error: connectionPowerTextError
            }}
            addonRight={'kVa'}
          />
        </MsbInlineForm>

        <MsbActivationUploadFileConsuel />

        { canMsbBeDeleted && 
          <CustomField className='mt-4'
            children={ 
              <MsbDeletionModalOpenButton
                client={ client } 
                customerId={ customerId }
                classList={ 'ml-auto' }
              /> 
            }
          />
        }
      </>
    );
  }
);

export default withRouter(
  translate(['MySmartBattery', 'Installation', 'Common'])(
    withApollo(EnedisGridInjectionRequested)
  )
);
