import { SignalQuality } from '../../../constants/SignalQuality';
import { safeMobxInject } from '../../../stores/storeInjectionHelpers';
import classnames from 'classnames';
import { translate } from 'react-i18next';
import { TranslationFunction } from 'i18next';
import './Master.scss';
import React from 'react';

interface IStoresProps {}

interface IProps {
  t: TranslationFunction;
  rssi?: number;
  rscp?: number;
  gsmSignalQuality?: string;
}

const GsmQualitySignal = safeMobxInject<IStoresProps, IProps>(
  stores => ({}),
  ({ t, rssi, rscp, gsmSignalQuality }: IProps & IStoresProps) => {
    const rssiDbmFormat = (rssi || 0) * 2 - 113;

    return (
      <span
        className={classnames(
          'badge',
          'badge-pill',
          'badge-signalComOne',
          gsmSignalQuality === SignalQuality.CORRECT
            ? 'badge-success'
            : 'badge-warning'
        )}
      >
        <span>{t('Devices:rssi')}:</span>
        <b>{` ${rssiDbmFormat}`} dbm</b>
        {rscp !== undefined && (
          <>
            {` / `}
            <span>{t('Devices:rscp')}:</span>
            <b>{` ${rscp}`}</b>
          </>
        )}
      </span>
    );
  }
);

export default translate()(GsmQualitySignal);
