import React, { ReactNode } from 'react';

import classnames from 'classnames';
import './smoothList.scss';
import { Col, Row } from 'reactstrap';
import { AutoSizer, List } from 'react-virtualized';
import EmptyCollectionPlaceHolder from '../DataDisplay/List/ListPlaceHolder';
import { translate, TranslationFunction } from 'react-i18next';

export interface IColumn {
  title?: string;
  dataKey?: string;
  render: (item: any, index?: number) => ReactNode | string;
  columnKey: string;
  align?: string;
  bootstrapOption?: any;
  isHidden?: boolean;
}

interface IProps {
  columns: IColumn[];
  dataSource: any[];
  placeHolderText?: string;
  rowHeight: number;
  onRowClick?: (item: any, index?: number) => void;
  canFetchMore?: boolean;
  fetchMore?: () => void;
  t: TranslationFunction;
}

class MobileSmoothList extends React.Component<IProps> {
  public render() {
    const {
      placeHolderText,
      columns,
      dataSource,
      onRowClick,
      rowHeight,
      fetchMore,
      canFetchMore,
      t
    } = this.props;
    const visibleColumns = columns.filter(column => !column.isHidden);
    return (
      <div className={classnames('smooth-list')}>
        <AutoSizer className="items">
          {({ height, width }) => (
            <List
              className="list"
              noRowsRenderer={() => {
                return <EmptyCollectionPlaceHolder text={placeHolderText} />;
              }}
              rowCount={dataSource.length + 1}
              rowHeight={rowHeight}
              rowRenderer={({ key, index, style }) => {
                if (index !== dataSource.length) {
                  return (
                    <Row
                      onClick={() => {
                        if (onRowClick) {
                          onRowClick(dataSource[index], index);
                        }
                      }}
                      className="item-row"
                      style={style}
                      key={key}
                    >
                      {visibleColumns.map(column => {
                        const columnRender = column.render
                          ? column.render(dataSource[index], index)
                          : '';
                        const isEmptyColumnRender = Boolean(
                          typeof columnRender !== 'string' &&
                            columnRender === null
                        );
                        if (isEmptyColumnRender) {
                          return null;
                        }
                        return (
                          <Col
                            style={{ textAlign: column.align }}
                            className="item-col"
                            {...column.bootstrapOption}
                            key={`${column.columnKey}-${key}`}
                          >
                            {column.dataKey
                              ? dataSource[index][column.dataKey]
                              : columnRender}
                          </Col>
                        );
                      })}
                    </Row>
                  );
                }
                if (index > 1) {
                  return (
                    <Row
                      className="item-row"
                      style={{
                        ...style,
                        justifyContent: 'center',
                        backgroundColor: canFetchMore ? 'aliceblue' : 'white',
                        borderBottom: 'none',
                        cursor: canFetchMore ? 'pointer' : 'unset'
                      }}
                      onClick={canFetchMore ? fetchMore : undefined}
                      key={key}
                    >
                      <div>
                        {canFetchMore
                          ? t('Common:fetchMore')
                          : t('Common:noMoreResults')}
                      </div>
                    </Row>
                  );
                }
              }}
              height={height}
              width={width}
            />
          )}
        </AutoSizer>
      </div>
    );
  }
}

export default translate(['Common'])(MobileSmoothList);
