import {
  Customer,
  MSBListItem,
  ProUser,
  PVInstallation
} from '../../../graphql/graphql.schema';
import { ICustomer } from '@mylight/data-model';
import { sortBy } from 'lodash';

//
// const fixture = {
//   data: {
//     proUser: {
//       id: '1981284f-04f4-4966-bb27-5f71d37a1f97',
//       firstName: 'Dev',
//       lastName: 'Team',
//       company: {
//         id: '568bd3dc-056b-42db-814a-5e01fccf9321',
//         companyName: 'Mylight dev team',
//         pvInstallations: [
//           {
//             id: '74f751c9-dc29-494d-b4dc-d1d01d71e90d',
//             panelCount: null,
//             panelWattPeak: null,
//             srsRegistrationDate: 1534111200000,
//             srsRegistrationStatus: null,
//             customers: [
//               {
//                 id: 'a12a2eff-e526-4f80-a1a6-7952cf8a52b3',
//                 lastName: 'Bousquet',
//                 email: 'support@fabien-bousquet.com',
//                 firstName: 'Fabien',
//                 address: '56 rue de la république',
//                 city: 'Lyon',
//                 zipCode: 69002,
//                 country: 'FR',
//                 creationDate: 1539263629796
//               }
//             ]
//           },
//           {
//             id: '399839b7-2c14-4a95-9580-288cd3235934',
//             panelCount: null,
//             panelWattPeak: null,
//             srsRegistrationDate: 1534370400000,
//             srsRegistrationStatus: 'ok',
//             customers: [
//               {
//                 id: 'e67d83de-301f-49db-bb5e-e47bda8fdd32',
//                 lastName: 'test',
//                 email: 'test@devteambtob1.fr',
//                 firstName: 'test',
//                 address: '12 rue de la republique',
//                 city: 'Lyon',
//                 zipCode: 69002,
//                 country: 'FR',
//                 creationDate: 1539351558957
//               }
//             ]
//           },
//           {
//             id: '5ea5121f-9d8e-4aa9-929b-ca97af43dd0d',
//             panelCount: null,
//             panelWattPeak: null,
//             srsRegistrationDate: 0,
//             srsRegistrationStatus: 'setup_selected_production_device',
//             customers: [
//               {
//                 id: 'b80a2bbf-f69b-438c-83dd-737ec7cc0860',
//                 lastName: 'Smith',
//                 email: 'test@srs.com',
//                 firstName: 'John',
//                 address: 'Zana 11',
//                 city: 'Paris',
//                 zipCode: 12345,
//                 country: 'FR',
//                 creationDate: 1539351655022
//               }
//             ]
//           },
//           {
//             id: 'f74d9ea9-f968-4157-b951-0a5cd1b9b9b8',
//             panelCount: null,
//             panelWattPeak: null,
//             srsRegistrationDate: 1441490400000,
//             srsRegistrationStatus: 'ok',
//             customers: [
//               {
//                 id: '50951511-65d5-424d-9376-f1a202671b1a',
//                 lastName: 'Suavet',
//                 email: 'o.suavet@mylight-systems.com',
//                 firstName: 'Ondine',
//                 address: '290 Rue Ferdinand Perrier',
//                 city: 'Saint-Priest',
//                 zipCode: 69800,
//                 country: 'FR',
//                 creationDate: 1539351629647
//               }
//             ]
//           },
//           {
//             id: '946c20ae-31f2-49f3-aeae-614a7a2b2d34',
//             panelCount: null,
//             panelWattPeak: null,
//             srsRegistrationDate: 1530655200000,
//             srsRegistrationStatus: 'ok',
//             customers: [
//               {
//                 id: 'afc67aa0-a02a-4a1a-8bfb-365be651ff5b',
//                 lastName: 'User1',
//                 email: 'devuser1@dev.com',
//                 firstName: 'Dev1',
//                 address: '1 rue de la republique',
//                 city: 'Lyon',
//                 zipCode: 69000,
//                 country: 'FR',
//                 creationDate: 1539351577789
//               }
//             ]
//           },
//           {
//             id: 'f315ffc6-cc30-416e-b02f-4f6cf773c3ac',
//             panelCount: 8,
//             panelWattPeak: 300,
//             srsRegistrationDate: 1534111200000,
//             srsRegistrationStatus: 'setup_ok',
//             customers: [
//               {
//                 id: '553793fd-78d2-4de3-a4ba-d7f0730580f8',
//                 lastName: 'test',
//                 email: 'test@dev.fr',
//                 firstName: 'dev',
//                 address: '12 Rue de la République',
//                 city: 'Lyon',
//                 zipCode: 69001,
//                 country: 'FR',
//                 creationDate: 1539351563322
//               }
//             ]
//           },
//           {
//             id: '9967859e-20ce-4860-a0c8-68cb7728ee83',
//             panelCount: 50,
//             panelWattPeak: 300,
//             srsRegistrationDate: 1536789600000,
//             srsRegistrationStatus: 'ok',
//             customers: [
//               {
//                 id: 'f60ffec0-def6-4b5c-8e3b-4144ba806216',
//                 lastName: 'Customer 1',
//                 email: 'customerdev1@devteam.dev',
//                 firstName: 'DevTeam',
//                 address: '12 Rue de la République',
//                 city: 'Lyon',
//                 zipCode: 69001,
//                 country: 'FR',
//                 creationDate: 1539351545611
//               }
//             ]
//           },
//           {
//             id: '71b25066-a8f0-49f8-bf10-6694d8bfdcd5',
//             panelCount: 10,
//             panelWattPeak: 300,
//             srsRegistrationDate: 1537999200000,
//             srsRegistrationStatus: 'ok',
//             customers: [
//               {
//                 id: 'd143943e-c13c-414e-8ffc-4276fcfd0f0f',
//                 lastName: 'Dassin',
//                 email: 'customer2@devteam.com',
//                 firstName: 'Yves',
//                 address: '24 rue de la république',
//                 city: 'Montpellier',
//                 zipCode: 34000,
//                 country: 'FR',
//                 creationDate: 1539351527553
//               }
//             ]
//           },
//           {
//             id: '641e5929-6b48-4109-b818-c80c44e5d85c',
//             panelCount: null,
//             panelWattPeak: null,
//             srsRegistrationDate: 1473717600000,
//             srsRegistrationStatus: 'ok',
//             customers: [
//               {
//                 id: '9e65fb33-8bd4-444a-80d2-af515c4c01b3',
//                 lastName: 'Jung',
//                 email: 'karol.jung@vestiacom.com',
//                 firstName: 'Karol',
//                 address: 'Asda',
//                 city: 'Lublin',
//                 zipCode: 12345,
//                 country: 'FR',
//                 creationDate: 1539351624476
//               }
//             ]
//           }
//         ]
//       }
//     }
//   }
// };

export const extractCustomer = (customer: Customer): any => {
  return {
    id: customer.id,
    status: (
      customer.pvInstallations![0].srsRegistrationStatus! || 'undefined'
    ).toLowerCase(),
    registrationDate: customer.pvInstallations![0].srsRegistrationDate,
    creationDate: customer.creationDate!,
    totalPower: 0,
    tenant: customer.pvInstallations![0].tenant,
    loginAsAllowed: customer.loginAsByMaintainerEnabled,
    email: customer.email!,
    lastName: customer.lastName!,
    firstName: customer.firstName!,
    address: customer.address!,
    addressSup: customer.addressSup!,
    zipCode: customer.zipCode,
    city: customer.city!,
    country: customer.country!,
    smsAlertsEnabled: customer.smsAlertsEnabled!,
    emailAlertsEnabled: customer.emailAlertsEnabled!,
    phoneNumber: customer.phoneNumber!,
    birthDate: customer.birthDate!,
    mobileNumber: customer.mobileNumber!,
    latitude: customer.latitude!,
    longitude: customer.longitude!,
    licenceStatus: customer.pvInstallations![0].srsRegistrationStatus,
    lastConnection: customer.lastConnection,
    canWrite: customer.canWrite,
    gridType:
      customer.pvInstallations &&
      customer.pvInstallations[0] &&
      customer.pvInstallations![0].electricInstallations &&
      customer.pvInstallations![0].electricInstallations![0]
        ? customer.pvInstallations![0].electricInstallations![0].gridType
        : undefined,
    pvInstallationId: customer.pvInstallations![0].id
  };
};

export const extractExtCustomer = (customer: Customer): any => {
  return {
    id: customer.id,
    status: customer.pvInstallations![0].srsRegistrationStatus!.toLowerCase(),
    registrationDate: customer.pvInstallations![0].srsRegistrationDate,
    pvInstallationId: customer.pvInstallations![0].id,
    creationDate: customer.creationDate!,
    totalPower: 0,
    tenant: customer.pvInstallations![0].tenant,
    loginAsAllowed: true,
    email: customer.email!,
    lastName: customer.lastName!,
    firstName: customer.firstName!,
    maintainer: customer.pvInstallations
      ? {
          address: customer.pvInstallations
            ? customer.pvInstallations![0].companies![0].address!
            : '',
          city: customer.pvInstallations![0].companies![0].city!,
          country: customer.pvInstallations![0].companies![0].country!,
          email: customer.pvInstallations![0].companies![0].proUsers![0].email!,
          firstName: customer.pvInstallations![0].companies![0].proUsers![0]
            .firstName!,
          lastName: customer.pvInstallations![0].companies![0].proUsers![0]
            .lastName!,
          phoneNumber: customer.pvInstallations![0].companies![0].proUsers![0]
            .phoneNumber!,
          mobileNumber: customer.pvInstallations![0].companies![0].proUsers![0]
            .mobileNumber!,
          companyName: customer.pvInstallations![0].companies![0].companyName!
        }
      : undefined
  };
};

export const extractCustomersFromProUser = (proUser: ProUser) => {
  const customers: any[] = [];

  if (proUser.company) {
    if (proUser.company.pvInstallations) {
      proUser.company.pvInstallations.forEach(pvI => {
        customers.push({
          totalPower:
            pvI.panelCount && pvI.panelWattPeak
              ? pvI.panelCount * pvI.panelWattPeak
              : 0,
          status: pvI.srsRegistrationStatus
            ? pvI.srsRegistrationStatus.toLowerCase()
            : undefined,
          registrationDate: pvI.srsRegistrationDate,
          tenant: pvI.tenant,
          pvInstallationId: pvI.id,
          ...(pvI.customers ? pvI.customers[0] : {})
        });
      });
    }
  }

  return customers;
};
export const extractCustomersFromPV = (pvInstallations: [PVInstallation]) => {
  const customers: any[] = [];

  if (pvInstallations) {
    pvInstallations
      .filter(pv => !!pv.customers && pv.customers.length > 0)
      .forEach(pvI => {
        customers.push({
          totalPower:
            pvI.panelCount && pvI.panelWattPeak
              ? pvI.panelCount * pvI.panelWattPeak
              : 0,
          status: pvI.srsRegistrationStatus
            ? pvI.srsRegistrationStatus.toLowerCase()
            : undefined,
          registrationDate: pvI.srsRegistrationDate,
          tenant: pvI.tenant,
          pvInstallationId: pvI.id,
          ...(pvI.customers ? pvI.customers[0] : {})
        });
      });
  }

  return customers;
};

export const extractCustomersFromFeedCustomers = (customers: Customer[]) => {
  return customers.map((customer: Customer) => {
    return {
      ...customer,
      tenant: customer.pvInstallations![0].tenant,
      status: (
        customer.pvInstallations![0].srsRegistrationStatus || 'undefined'
      ).toLowerCase(),
      registrationDate: customer.pvInstallations![0].srsRegistrationDate,
      pvInstallationId: customer.pvInstallations![0].id,
      msbStatus: customer.msbStatus
    };
  });
};

export const getFilteredAndSortedCustomers = (
  customers: ICustomer[],
  searchQuery: string
): ICustomer[] => {
  return sortBy(
    customers.filter(customer => {
      return (
        `${customer.firstName} ${customer.lastName}`
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
          .includes(
            searchQuery
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
          ) ||
        `${customer.lastName} ${customer.firstName}`
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
          .includes(
            searchQuery
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
          ) ||
        `${customer.address}`
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
          .includes(
            searchQuery
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
          ) ||
        (customer.city
          ? customer.city.toLowerCase().includes(searchQuery.toLowerCase())
          : false) ||
        (customer.zipCode
          ? customer.zipCode
              .toString()
              .toLowerCase()
              .includes(searchQuery.toLowerCase())
          : false) ||
        (customer.email &&
          customer.email.toLowerCase().includes(searchQuery.toLowerCase()))
      );
    }),
    (customer: ICustomer) =>
      customer.lastName ? customer.lastName.toLowerCase() : ''
  );
};

export const getFilteredAndSortedMSB = (
  mySmartBatteryList: MSBListItem[],
  searchQuery: string
): MSBListItem[] => {
  return sortBy(
    mySmartBatteryList.filter(msb => {
      return (
        `${msb.customerFirstName} ${msb.customerLastName}`
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
          .includes(
            searchQuery
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
          ) ||
        `${msb.customerLastName} ${msb.customerFirstName}`
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
          .includes(
            searchQuery
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
          ) ||
        (msb.customerEmail &&
          msb.customerEmail.toLowerCase().includes(searchQuery.toLowerCase()))
      );
    }),
    (msb: MSBListItem) =>
      msb.customerLastName ? msb.customerLastName.toLowerCase() : ''
  );
};
