// import { default as SelectInput } from '../../ui/Select/Select';
import * as _ from 'lodash';
import * as React from 'react';
import { translate } from 'react-i18next';
import { TranslationFunction } from 'i18next';
import { Col, Row } from 'reactstrap';
import Select from './Select';
import SelectInput from '../../ui/Select/Select';

const monthNames = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december'
];

const daysInMonth = (month: number, year: number) => {
  return new Date(year, month + 1, 0).getDate();
};
const getMonth = (date: Date) => {
  return date.getMonth() || 0;
};
const getDay = (date: Date) => {
  return date.getDate() || 0;
};

const getYear = (date: Date) => {
  return date.getFullYear() || 2000;
};
const BirthDatePicker = ({
  birthDate,
  onChange,
  t
}: {
  birthDate: Date;
  onChange: (date: Date) => void;
  t: TranslationFunction;
}) => (
  <Row noGutters>
    <Col className={'pr-2'}>
      <SelectInput
        id={'day'}
        name={t('Common:day')}
        value={{
          value: String(getDay(birthDate)),
          label: String(getDay(birthDate))
        }}
        options={_.range(
          1,
          daysInMonth(getMonth(birthDate), getYear(birthDate)) + 1
        ).map(e => ({ value: String(e), label: String(e) }))}
        onChange={value => {
          const newDate = new Date(birthDate);
          newDate.setDate(Number(value.value));
          onChange(newDate);
        }}
      />
    </Col>
    <Col className="pr-2">
      <SelectInput
        id={'month'}
        name={t('Common:month')}
        value={{
          value: String(getMonth(birthDate)),
          label: t(monthNames[getMonth(birthDate)])
        }}
        options={monthNames.map((e, i) => ({ value: String(i), label: t(e) }))}
        onChange={value => {
          const newDate = new Date(birthDate);
          newDate.setDate(1);
          newDate.setMonth(Number(value.value));
          newDate.setDate(
            Math.min(
              daysInMonth(getMonth(newDate), getYear(birthDate)),
              getDay(birthDate)
            )
          );
          onChange(newDate);
        }}
      />
    </Col>
    <Col className="pr-2">
      <SelectInput
        id={'year'}
        name={t('Common:year')}
        value={{
          value: String(getYear(birthDate)),
          label: String(getYear(birthDate))
        }}
        options={_.range(getYear(new Date()), getYear(new Date()) - 150).map(
          e => ({ value: String(e), label: String(e) })
        )}
        onChange={value => {
          const newDate = new Date(birthDate);
          newDate.setFullYear(Number(value.value));
          onChange(newDate);
        }}
      />
    </Col>
  </Row>
);
export default translate(['MonthNames', 'Common'])(BirthDatePicker);
