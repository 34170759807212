import { translate } from 'react-i18next';
import Alert from 'reactstrap/lib/Alert';
import { Link } from 'react-router-dom';
import React from 'react';
import { TranslationFunction } from 'i18next';
import { getEditDeviceFunctionPath } from '../routingHelper';
import { Device } from '../../../stores/inscription/device';

interface IProps {
  currentDevice?: Device;
  deviceWithGC?: Device;
  t: TranslationFunction;
  pvInstallationId?: any;
  customerId?: any;
}

const PossibleAlert = ({
  currentDevice,
  deviceWithGC,
  t,
  pvInstallationId,
  customerId
}: IProps) => {
  let deviceName = '';
  let urlDevice = '';
  if (deviceWithGC) {
    deviceName = deviceWithGC.getDeviceFullName;
    urlDevice = getEditDeviceFunctionPath({
      pvInstallationId,
      customerId,
      plcMac: deviceWithGC.plcMac,
      ethMac: deviceWithGC.ethMac
    });
  }

  return (
    <Alert color="danger" style={{ marginTop: '10px' }}>
      {t('notPossibleToDefineTwoGlobalConsumptionCounter')}
      {deviceWithGC && (
        <>
          &nbsp;{t('functionAlreadyAffectedAtAnOtherDevice')}{' '}
          <Link to={urlDevice} onClick={() => currentDevice!.cancelChanges()}>
            {deviceName}
          </Link>
        </>
      )}
    </Alert>
  );
};
export default translate('DevicesInscription')(PossibleAlert);
