import React, { Component, ReactNode } from 'react';

import { inject, observer } from 'mobx-react';
import { Store } from '../../../stores';
import { UserTypes } from '../../../constants/UserTypes';

export interface IProps {
  children: ReactNode;
  featureKey: FeatureKEY;
  Store?: Store;
}

type FeatureKEY = 'SEND_T48' | 'SEE_DIAGNOSTICS';

export const rightMatrix = {
  SEE_DIAGNOSTICS: {
    ADMINISTRATOR: true,
    MAINTAINER: false,
    INSTALLER: false,
    MSB_SUPPORT_AGENT: false
  },
  SEND_T48: {
    ADMINISTRATOR: true,
    MAINTAINER: false,
    INSTALLER: false,
    MSB_SUPPORT_AGENT: false
  }
};

function hasRight({
  role,
  featureKey
}: {
  role: UserTypes;
  featureKey: FeatureKEY;
}): boolean {
  return !!rightMatrix[featureKey][role];
}
@inject('Store')
@observer
class RightHandler extends Component<IProps> {
  public render(): React.ReactNode {
    const { children, featureKey } = this.props;
    const role = this.props.Store!.profile.userType as UserTypes;
    return hasRight({ featureKey, role }) ? children : '';
  }
}

export default RightHandler;
