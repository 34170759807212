export const GMD = 'gmd';
export const CT = 'ct';
export const TPH = 'tph';
export const ETH = 'eth';
export const SW = 'sw';
export const VRT = 'vrt';
export const PLC_HUB = 'tplink';
export const CMP = 'cmp';
export const MST = 'mst';
export const MST_G3 = 'mst_g3';
export const SW_G3 = 'sw_g3';
export const GMD_G3 = 'gmd_g3';
export const RS485 = 'rs485';
export const COM1 = 'MLSH-COM-1_PIB001_SLD001';
export const ENP = 'enp';
export const BAT = 'bat';

/* ???? */
/* export const GMD_3PHASE = 'GMD 3Phase'; */
/* ???? */

export const DEVICE_TYPE = {
  GMD,
  TPH,
  ETH,
  CT,
  SW,
  CMP,
  MST,
  SW_G3,
  GMD_G3,
  COM1
  /* ???? */
  /* GMD_3PHASE, */
  /* ???? */
};

export const FUNCTION_TYPE = {
  ALARM_CLOCK: 'alarm_clock',
  ASOKA_ELECTRIC_COUNTER: 'asoka_electric_counter',
  ASOKA_RED_PLUG: 'asoka_red_plug',
  ASPIRATOR: 'aspirator',
  BATTERY: 'battery',
  BBQ_HOT_STONE: 'bbq_hot_stone',
  COFFEE_MACHINE: 'coffee_machine',
  COMPOSITE_DEVICE: 'composite_device',
  COMPUTER: 'computer',
  DEEP_FRYER: 'deep_fryer',
  DISHWASHER: 'dishwasher',
  DRYER: 'dryer',
  DVD_PLAYER: 'dvd_player',
  ELECTRIC_CAR: 'electric_car',
  FOOD_PROCESSOR: 'food_processor',
  FREEZER: 'freezer',
  GAME_CONSOLE: 'game_console',
  HAIR_DRYER: 'hair_dryer',
  HEATER: 'heater',
  HOME_CINEMA_SYSTEM: 'home_cinema_system',
  IRON: 'iron',
  KETTLE: 'kettle',
  LAMP: 'lamp',
  MICROWAVE_OVEN: 'microwave_oven',
  OTHER_DEVICE_TYPE: 'other_device_type',
  OVEN: 'oven',
  PHONE: 'phone',
  PRINTER: 'printer',
  PRODUCTION_COUNTER: 'production_counter',
  PRODUCTION_COUNTER_IGNORED: 'production_counter_ignored',
  PUMP: 'pump',
  RADIO: 'radio',
  RAZOR: 'razor',
  REFRIGERATOR: 'refrigerator',
  REFRIGERATOR_FREEZER: 'refrigerator_freezer',
  SPEAKER: 'speaker',
  STEREO_SYSTEM: 'stereo_system',
  TOASTER: 'toaster',
  TV: 'tv',
  VENTILATOR: 'ventilator',
  VIDEO_PROJECTOR: 'video_projector',
  VIRTUAL: 'virtual',
  WASHING_DRYING_MACHINE: 'washing_drying_machine',
  WASHING_MACHINE: 'washing_machine',
  WATER_HEATER: 'water_heater',
  WINE_CELLAR: 'wine_cellar'
};

export const SPLITABLE_DEVICES = [GMD, TPH];
export const HANDLED_DEVICES_TYPES = [
  GMD,
  TPH,
  ETH,
  CT,
  SW,
  CMP,
  MST,
  PLC_HUB,
  COM1
];

export const DEVICES_VISIBLE_IN_CUSTOMER_SYSTEM_TAB = [
  MST,
  MST_G3,
  SW,
  SW_G3,
  GMD,
  GMD_G3,
  TPH,
  ETH,
  CT,
  CMP,
  RS485,
  COM1,
  ENP,
  BAT
];

export const POWER_CONTRACT_TYPES = ['flat', 'custom', 'predefined'];
export const POWER_CONTRACT_TYPE_OPTIONS = ['ejp', 'tempo', 'other'];

export type GMD = 'gmd';
export type TPH = 'tph';
export type RS485 = 'rs485';

export const VALID_SIM_ROUTER_REFERENCE = 'Routeur Mylight MLSH-COM-2';
