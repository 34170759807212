import React, { useEffect, useState } from 'react';
import {
  Button,
  CustomInput,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap';
import { translate, TranslationFunction } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import BatteryDocumentUploadButton from './BatteryDocumentUploadButton';
import { DocumentType } from '../../../../../graphql/graphql.schema';
// @ts-ignore
import { Document, Page, pdfjs } from 'react-pdf';
import ShowAlert from '../../../../../ui/ShowAlert/ShowAlert';
import UploadConsuelQuery from './UploadConsuelQuery';
import UploadArpeQuery from './UploadArpeQuery';

interface IProps extends RouteComponentProps {
  t: TranslationFunction;
  isModalOpen: boolean;
  target: string | HTMLElement;
  toggle: any;
  title: string;
  documentType: DocumentType;
  onComplete?: (date: Date) => void;
}
export interface IUploadedFile {
  name?: string;
  type?: string;
  size?: string;
}

const BatteryDocumentUploadModal = ({
  isModalOpen,
  target,
  toggle,
  t,
  title,
  documentType,
  onComplete
}: IProps) => {
  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${
      pdfjs.version
    }/pdf.worker.js`;
  }, []);

  const [file, updateFile] = useState<IUploadedFile>();
  const [showAlert, toggleAlert] = useState<boolean>(false);

  const onChange = ({ target: { files } }: any) => {
    if (!files.length) {
      return;
    }
    const { name, type } = files[0];
    if (type === 'application/pdf' && name.toLowerCase().includes('.pdf')) {
      toggleAlert(false);
      return updateFile(files[0]);
    }
    toggleAlert(true);
    return updateFile(undefined);
  };

  const onCompletedUpload = (uploadDocument: any) => {
    toggle();
    updateFile(undefined);
    if (onComplete) {
      onComplete(uploadDocument);
    }
  };

  const modalSize = file ? 'lg' : 'md';
  return (
    <Modal
      centered
      isOpen={isModalOpen}
      target={target}
      className={'battery-document-modal'}
      size={modalSize}
    >
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>
        <CustomInput
          required
          type={'file'}
          id={'choose_file'}
          name={'choose-file'}
          label={(file && file.name) || t('Common:chooseFile')}
          onChange={e => onChange(e)}
        />
        {file ? (
          <div className={'pdf-container'}>
            {documentType === DocumentType.CONSUEL && (
              <ShowAlert
                type={'info'}
                message={
                  <ul>
                    {t(`pdfUploadInfo_${documentType}`)
                      .split('\n')
                      .map((item: string, key: number) => {
                        return <li key={key}>{item}</li>;
                      })}
                  </ul>
                }
              />
            )}
            <div style={{ marginTop: '1rem' }}>
              <Document file={file} className={'pdf-inner-container'}>
                <Page
                  className={'pdf-page'}
                  pageNumber={1}
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                />
              </Document>
            </div>
          </div>
        ) : showAlert ? (
          <ShowAlert type={'danger'} message={t('choosePdf')} margin={0} />
        ) : null}
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={() => {
            toggle();
            updateFile(undefined);
          }}
          color="secondary"
          className={'mr-2'}
        >
          {t('Common:cancel')}
        </Button>
        {documentType === DocumentType.CONSUEL ? (
          <UploadConsuelQuery
            disabled={!file}
            signedDocument={file}
            documentType={documentType}
            onCompleted={uploadCONSUELDocument =>
              onCompletedUpload(uploadCONSUELDocument)
            }
          />
        ) : (
          <UploadArpeQuery
            disabled={!file}
            signedDocument={file}
            documentType={documentType}
            onCompleted={uploadARPEDocument =>
              onCompletedUpload(uploadARPEDocument)
            }
          />
        )}
      </ModalFooter>
    </Modal>
  );
};

export default withRouter(
  translate(['MySmartBattery'])(BatteryDocumentUploadModal)
);
