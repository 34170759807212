import React from 'react';
import { IDeviceIcon } from '../DeviceIcons';

const Pump = (props: IDeviceIcon) => (
  <svg
    width="3rem"
    height="3rem"
    viewBox="0 0 20000 20000"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    color="black"
    {...props}
  >
    <g id="prefix__Layer_x0020_1">
      <g id="prefix__pump">
        <path
          style={{ fill: `${props.color}` }}
          d="M9727 14212c-107-2120 961-2968 2281-3969 2162-1640 3115 945 1659 2884-1225 853-3111 1875-3940 1086zm-434 21c201 1390 4087 219 4895-1263 978-1297 824-4782-2259-3106-1242 675-2943 2248-2636 4369z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M13789 8245c38 781-1771 704-2511 413-1004-314-1057-411-2208-60-1428-1405-459-3512 1540-3143 1390 228 3375 1111 3179 2791zm-4736 889c1200-1200 4219 1418 5152-691 242-2058-1766-3092-3430-3470-860-196-2052 125-2541 908-770 1235-115 2373 818 3253z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M6795 7659c1607 568 2004 3085 2294 4040 459 1516-2298 2356-3195 270-922-1185-417-4777 902-4310zm1764 1460c-332-1013-1568-2698-2712-1571-1921 1893-425 7227 2539 5952 2678-1129 504-3368 173-4381z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M10169 10808c-482 0-919-390-919-919 0-482 390-919 919-919 482 0 919 390 919 919 0 482-436 919-919 919zm0-1493c-287 0-574 241-574 574 0 287 241 574 574 574 287 0 574-241 574-574 11-287-287-574-574-574z"
        />
      </g>
    </g>
  </svg>
);

export default Pump;
