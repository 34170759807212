import React, { ReactNode } from 'react';

import { translate, TranslationFunction } from 'react-i18next';
import classnames from 'classnames';
import './spinner.scss';

interface IProps {
  children?: ReactNode;
  loading?: boolean;
  error?: string;
  disabled?: boolean;
  onClick?: () => void;
  style?: React.CSSProperties;
  t: TranslationFunction;
  transparent?: boolean;
}

class Spinner extends React.Component<IProps> {
  public render() {
    const { children, loading, error, style, t, transparent } = this.props;
    return (
      <div
        className={classnames(
          'spinner',
          { loading },
          { error },
          { transparent }
        )}
        style={style}
      >
        {children}
        {error && (
          <label>{error !== 'error' ? error : t('Common:loadError')}</label>
        )}
      </div>
    );
  }
}

export default translate('Common')(Spinner);
