import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import '../partnerships.scss';
import { Button, Col, Row } from 'reactstrap';
import Spinner from '../../../../ui/Spinner/Spinner';
import ListItem from '../../../../ui/DataDisplay/List/ListItem';
import List from '../../../../ui/DataDisplay/List/ListWrapper';
import { FormInput } from '../../../../ui/FormInput/FormInput';
import SelectInput from '../../../../ui/Select/Select';
import FormGroup from 'reactstrap/lib/FormGroup';
import { TranslationFunction } from 'i18next';
import { translate } from 'react-i18next';
import DeviceIcon from '../../../../assets/images/devices/DeviceIcons';
import { RouteComponentProps, withRouter } from 'react-router';
import history from '../../../../history';
import TadoSaveDevicesButton from './TadoSaveDevicesButton';
import TadoDisconnectButton from './TadoDisconnectButton';
import TadoRemoveDeviceButton from './TadoRemoveDeviceButton';
import { IAllStores } from '../../../../stores/allStores.model';
import { TadoStore } from '../../../../stores/partnerships/tadoStore';
import { getPartnerPath } from '../../routingHelper';
import { IconSave } from '../../../../ui/Icons/IconSave';

interface IProps extends RouteComponentProps<IMatchParams> {
  tadoStore?: TadoStore;
  t: TranslationFunction;
  roomTypes: any;
  loading: boolean;
}

interface IMatchParams {
  customerId: string;
  pvInstallationId: string;
}

@inject(({ tadoStore }: IAllStores) => {
  return {
    tadoStore
  };
})
@observer
class TadoDevicesView extends Component<IProps> {
  public render() {
    const {
      tadoStore,
      t,
      roomTypes,
      loading,
      match: {
        params: { customerId, pvInstallationId }
      }
    } = this.props;
    const {
      toggleDeviceEditing,
      editedDevice,
      updateDeviceInfo,
      storedTadoDevices
    } = tadoStore!;

    const noDetectedDevices = storedTadoDevices.length === 0;

    const savedTadoDevices = tadoStore!.savedTadoDevices.map(device => (
      <ListItem className="custom-list-item" key={device.id}>
        <Col className="device-container saved">
          <Col>
            <Row className="device-info">
              <DeviceIcon
                type={device.controlledDeviceType}
                height={'60px'}
                width={'60px'}
              />
              <Col className="device-title">
                <div className="device-name-container">
                  <span className="device-name">{device.deviceName}</span>
                </div>
                <span className="device-type">
                  {t(`DeviceType:${device.controlledDeviceType}`)}
                </span>
              </Col>
            </Row>
            <Row className="room-info">
              <Col>
                <Row className="room-type">
                  <span className="room-info-title">{t(`Devices:room`)}:</span>
                  <span className="room-info-content">
                    {t(`RoomName:${device.roomType}`)}
                  </span>
                </Row>
                <Row className="room-name">
                  <span className="room-info-title">
                    {t(`Devices:roomName`)}:
                  </span>
                  <span className="room-info-content">{device.roomName}</span>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col className="device-buttons">
            <TadoRemoveDeviceButton
              deviceId={device.id}
              target={`delete_${device.id}`}
            />
          </Col>
        </Col>
      </ListItem>
    ));

    const unsavedTadoDevices = tadoStore!.unsavedTadoDevices.map(device => (
      <ListItem key={device.id}>
        <Col className="device-container">
          <Row
            className={
              tadoStore!.ignoredTadoDevices.includes(device.id)
                ? 'device-info ignored'
                : 'device-info'
            }
          >
            <DeviceIcon
              type={device.controlledDeviceType}
              color={'#9b9b9b'}
              height={'60px'}
              width={'60px'}
            />
            <Col className="device-title">
              <div className="device-name-container">
                {editedDevice === device.id ? (
                  <FormInput
                    id="deviceName"
                    name=""
                    value={device.deviceName}
                    onChange={e => updateDeviceInfo(device.id, 'deviceName', e)}
                  />
                ) : (
                  <span className="device-name">{device.deviceName}</span>
                )}
                {!tadoStore!.ignoredTadoDevices.includes(device.id) && (
                  <Button
                    className="btn-ico device-edit"
                    onClick={() => toggleDeviceEditing(device.id)}
                  >
                    {editedDevice === device.id ? (
                      <IconSave />
                    ) : (
                      <i className="icon-profile" />
                    )}
                  </Button>
                )}
              </div>
              {editedDevice === device.id ? null : (
                <span className="device-type">
                  {t(`DeviceType:${device.controlledDeviceType}`)}
                </span>
              )}
            </Col>
            {tadoStore!.ignoredTadoDevices.includes(device.id) && (
              <span className={'ignored-device-label'}>
                {t(`Customer:ignored`)}
              </span>
            )}
            <i
              className={
                tadoStore!.ignoredTadoDevices.includes(device.id)
                  ? 'icon-add'
                  : 'icon-cross'
              }
              onClick={() => {
                tadoStore!.ignoreTadoDevice(device);
              }}
            />
          </Row>
          {!tadoStore!.ignoredTadoDevices.includes(device.id) && (
            <Row className="room-info">
              <FormGroup className="room-control">
                <SelectInput
                  id="roomType"
                  name={t(`Devices:room`)}
                  placeholder={t(`Devices:roomType`)}
                  options={roomTypes.map((roomType: any) => {
                    return {
                      value: roomType,
                      label: t(`RoomName:${roomType}`)
                    };
                  })}
                  value={
                    device.roomType
                      ? {
                          value: device.roomType,
                          label: t(`RoomName:${device.roomType}`)
                        }
                      : undefined
                  }
                  onChange={e =>
                    updateDeviceInfo(device.id, 'roomType', e.value)
                  }
                />
                <FormInput
                  id="roomName"
                  type="text"
                  name={t(`Devices:roomName`)}
                  value={device.roomName}
                  onChange={e => updateDeviceInfo(device.id, 'roomName', e)}
                />
              </FormGroup>
            </Row>
          )}
        </Col>
      </ListItem>
    ));
    return (
      <Spinner loading={loading}>
        <List fluid={true}>
          <ListItem className="tado-container">
            <Col className="tado-content">
              <Row>
                <span className="tado-title">
                  {noDetectedDevices
                    ? t('TadoPartnership:noDetectedDevices')
                    : t('TadoPartnership:detectedDevices')}
                </span>
              </Row>
              <List fluid={true}>
                {savedTadoDevices}
                {unsavedTadoDevices}
                <div className="tado-button validate-devices">
                  {tadoStore!.tadoDevicesToSave.length > 0 ? (
                    <TadoSaveDevicesButton />
                  ) : tadoStore!.savedTadoDevices.length > 0 ? (
                    <>
                      <Button
                        color="secondary"
                        onClick={() => {
                          history.push(
                            getPartnerPath({ customerId, pvInstallationId })
                          );
                        }}
                      >
                        {t('DevicesInscription:continueInscription')}
                      </Button>
                      <TadoDisconnectButton />
                    </>
                  ) : (
                    <Button
                      color="primary outline"
                      onClick={() => {
                        history.push(
                          `/devices_inscription/${customerId}/partnership/${pvInstallationId}/tado/home`
                        );
                      }}
                    >
                      {t('Partnerships:cancel')}
                    </Button>
                  )}
                </div>
              </List>
            </Col>
          </ListItem>
        </List>
      </Spinner>
    );
  }
}

export default translate([
  'DeviceType, Partnerships, TadoPartnership, Customer, DevicesInscription'
])(withRouter(TadoDevicesView));
