import { default as React } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import { get } from 'lodash';

import { translate } from 'react-i18next';
import { TranslationFunction } from 'i18next';
import { HelpButton } from '../../../ui/HelpButton/HelpButton';
import Spinner from '../../../ui/Spinner/Spinner';
import {
  CustomField,
  DateField,
  Field,
  FieldAddress,
  FieldEmail,
  FieldList,
  FieldPhone,
  Title
} from '../../../ui/DataDisplay/FieldList/FieldList';
import BadgeStatus from '../../../ui/BadgeStatus/BadgeStatus';
import { dateFormatter } from '../../../utils/tools';

interface IProps {
  customer?: any;
  loading: boolean;
  error?: string;
  pvInstallationId?: string;
  t: TranslationFunction;
  goToEdit: () => void;
}

const IdentityView = ({ customer, loading, error, t, goToEdit }: IProps) => {
  return (
    <Spinner loading={loading} error={error}>
      <Container fluid>
        <Col className="mobile-helper">
          <Row>
            <Col md={12} lg={6}>
              <FieldList>
                <Title
                  text={t('customer')}
                  addon={
                    <Button
                      disabled={!(customer && customer.canWrite)}
                      onClick={() => goToEdit()}
                      className="btn-ico"
                    >
                      <i className="icon-profile" />
                    </Button>
                  }
                />
                {customer && (
                  <FieldAddress name={t('address')} contact={customer} />
                )}
                {customer &&
                  customer.addressSup && (
                    <Field
                      name={t('Common:additionalAddress')}
                      text={customer.addressSup}
                    />
                  )}
                <Field
                  name={t('country')}
                  text={customer && customer.country}
                />
                <DateField
                  name={t('birthDate')}
                  value={get(customer, 'birthDate')}
                />
                <FieldEmail
                  name={t('email')}
                  email={customer && customer.email}
                />
                <FieldPhone
                  name={t('phone')}
                  phone={customer && customer.phoneNumber}
                />
                <FieldPhone
                  name={t('mobile')}
                  phone={customer && customer.mobileNumber}
                />
              </FieldList>
            </Col>
            <Col md={12} lg={6}>
              <FieldList>
                <Title text={t('account')} />
                <DateField
                  name={t('registrationDate')}
                  value={get(customer, 'registrationDate')}
                />
                <CustomField name={t('registrationStatus')}>
                  <BadgeStatus status={get(customer, 'status')} />

                  <HelpButton
                    message={t(
                      `RegistrationStatusToolTips:${get(customer, 'status')}`,
                      {
                        link: `/customers/${get(customer, 'id')}/systems`
                      }
                    )}
                    uniqueKey="customer-identity-status-tooltip"
                  />
                </CustomField>
                <Field
                  name={t('gridElectricalPhaseType')}
                  text={customer && customer.gridType && t(customer.gridType)}
                />
                <CustomField name={t('lastConnections')}>
                  {customer && customer.lastConnections
                    ? customer.lastConnections.map(
                        (connection: number, i: number) => (
                          <div
                            style={{
                              paddingRight: 0,
                              paddingLeft: 0,
                              marginTop: i > 0 ? '10px' : '0px'
                            }}
                            key={i}
                          >
                            <span className="field text">
                              {dateFormatter(connection, 'DD/MM/YYYY HH:mm')}
                            </span>
                          </div>
                        )
                      )
                    : '-'}
                </CustomField>
              </FieldList>
            </Col>
          </Row>
        </Col>
      </Container>
    </Spinner>
  );
};

export default translate(['Customer', 'RegistrationStatus', 'Common'])(
  IdentityView
);
