import BatteryGetInfoQuery from '../../customers/installation/battery/info/BatteryGetInfoQuery';
import { Alert, Col, Row } from 'reactstrap';
import List from '../../../ui/DataDisplay/List/ListWrapper';
import { ListTitle } from '../../../ui/DataDisplay/List/ListHeader';
import PossibleSummaryWarnings from './PossibleSummaryWarnings';
import ListItem from '../../../ui/DataDisplay/List/ListItem';
import MediaQuery from 'react-responsive';
import {
  BREAK_POINT_LARGE__WIDTH,
  BREAK_POINT_LARGE_SAFE_MIN_WIDTH
} from '../../../constants/BREAK_POINT_WIDTH';
import SummaryDevicesAndFunctions from './SummaryDevicesAndFunctions';
import SummaryBattery from './SummaryBattery';
import SummaryPartnerships from './SummaryPartnerships';
import ShowAlert from '../../../ui/ShowAlert/ShowAlert';
import LoadingButton from '../../../ui/Button/LoadingButton/LoadingButton';
import CustomModal from '../../../ui/CustomModal/CustomModal';
import SpinnerFinalisationInscription from './SpinnerFinalisationInscription';
import React, { useEffect, useState } from 'react';
import { safeMobxInject } from '../../../stores/storeInjectionHelpers';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IInscriptionNavigationRouterParamProps } from '../InscriptionNavigation';
import { translate, TranslationFunction } from 'react-i18next';
import { ISnackBarParams } from '../../../stores/userFeedback/snackbarStore';
import i18n from '../../../translations/i18n';
import SummaryInstallationQuery from './SummaryInstallationQuery';
import SendInscriptionSummaryByEmailModal from './send_inscription_summary_by_email/SendInscriptionSummaryByEmailModal';
import SendInscriptionSummaryByEmailFeature from './send_inscription_summary_by_email/SendInscriptionSummaryByEmailFeature';
import { useInView } from 'react-hook-inview';
import mySmartBattery, {
  MySmartBattery
} from '../../../stores/my_smart_battery/mySmartBattery';
import creationMySmartBattery, {
  CreationMySmartBattery
} from '../../../stores/my_smart_battery/creationMySmartBattery';
import { withApollo, WithApolloClient } from 'react-apollo';

export interface IProps
  extends RouteComponentProps<IInscriptionNavigationRouterParamProps> {
  t: TranslationFunction;
  loading: boolean;
  finalizeInstallation: any;
  data: any;
  stopPolling: any;
}

interface IStoresProps {
  isInscriptionDone: boolean;
  isDevicesHasNotAssignFunction: boolean;
  showSnackBar: (params: ISnackBarParams) => void;
  setFinishStepDone: (isFinishStepDone: boolean) => void;
  mySmartBattery: MySmartBattery;
  creationMySmartBattery: CreationMySmartBattery;
}

const FinishInstallationView = safeMobxInject<IStoresProps, IProps>(
  stores => ({
    showSnackBar: stores.snackBar.showSnackBar,
    isDevicesHasNotAssignFunction:
      stores.inscription.isDevicesHasNotAssignFunction,
    isInscriptionDone: stores.inscriptionSteps.isInscriptionDone,
    setFinishStepDone: stores.inscriptionSteps.setFinishStepDone,
    mySmartBattery: stores.mySmartBattery,
    creationMySmartBattery: stores.creationMySmartBattery,
  }),
  ({
    t,
    client,
    finalizeInstallation,
    loading,
    data,
    match: {
      params: { pvInstallationId, customerId }
    },
    isDevicesHasNotAssignFunction,
    isInscriptionDone,
    stopPolling,
    setFinishStepDone
  }: WithApolloClient<IProps & IStoresProps>) => {
    const [finishButtonRef, isFinishButtonVisible] = useInView();
    const [canDisplayMsb, setCanDisplayMsb] = useState(false);

    useEffect(
      () => {
        setCanDisplayMsb(
          mySmartBattery.haveRightToAccessMSB && mySmartBattery.isMSBAdded
        );
      },
      [mySmartBattery.haveRightToAccessMSB, mySmartBattery.isMSBAdded]
    );

    useEffect(
      () => {
        mySmartBattery.loadMsb(client, customerId).then(msb => {
          if (msb) {
            mySmartBattery.setActivationMsb(msb);
            creationMySmartBattery.setCreationMsbMylight(msb);
          }
        });
      },
      [pvInstallationId, customerId]
    );

    useEffect(
      () => {
        setFinishStepDone(isFinishButtonVisible);
      },
      [isFinishButtonVisible]
    );
    useEffect(() => {
      return () => setFinishStepDone(false);
    }, []);

    const [confirmOpened, displayConfirm] = useState(false);

    const [
      isSendInscriptionSummaryModalOpened,
      openSendInscriptionSummaryModal
    ] = useState(false);

    const errorText =
      data &&
      data.completeRegistrationOfDevices &&
      data.completeRegistrationOfDevices.error;
    return (
      <BatteryGetInfoQuery>
        <Col md={12} className={'finish-installation mobile-helper'}>
          <List fluid>
            <ListTitle text={t('RegistrationMenu:summary')} />
            <PossibleSummaryWarnings />
            {isInscriptionDone && (
              <ShowAlert
                type={'info'}
                message={t(`DevicesInscription:pleaseFinishInscription`)}
              />
            )}
            <ListItem className={'grouped-list'}>
              <MediaQuery query={`(max-width: ${BREAK_POINT_LARGE__WIDTH})`}>
                <Col lg={6} md={12}>
                  <SummaryInstallationQuery />
                </Col>
                <Col lg={6} md={12}>
                  <SummaryDevicesAndFunctions />
                </Col>
                {canDisplayMsb && (
                  <Col lg={6} md={12}>
                    <SummaryBattery />
                  </Col>
                )}
                <Col lg={6} md={12}>
                  <SummaryPartnerships />
                </Col>
              </MediaQuery>
              <MediaQuery
                query={`(min-width: ${BREAK_POINT_LARGE_SAFE_MIN_WIDTH})`}
              >
                <Col lg={6}>
                  <SummaryInstallationQuery />
                  {canDisplayMsb && <SummaryBattery />}
                  <SummaryPartnerships />
                </Col>
                <Col lg={6}>
                  <SummaryDevicesAndFunctions />
                </Col>
              </MediaQuery>
            </ListItem>
            {errorText && (
              <Alert color="danger">
                {t(`StepError:${errorText.step || 'unknown'}`)}
              </Alert>
            )}
            <ListItem className={'finish-installation-button'}>
              {!isInscriptionDone && (
                <ShowAlert message={t('StepTodo:summary')} />
              )}
              <div className={'save-button'}>
                {isInscriptionDone &&
                  !loading && (
                    <SendInscriptionSummaryByEmailFeature
                      isSendInscriptionSummaryModalOpened={
                        isSendInscriptionSummaryModalOpened
                      }
                      openSendInscriptionSummaryModal={
                        openSendInscriptionSummaryModal
                      }
                    />
                  )}
                <div ref={finishButtonRef}>
                  <LoadingButton
                    loading={loading}
                    disabled={!isInscriptionDone || loading}
                    onClick={() => {
                      if (isDevicesHasNotAssignFunction) {
                        displayConfirm(true);
                        return;
                      }
                      stopPolling();
                      finalizeInstallation({
                        variables: {
                          pvInstallationId,
                          lang: i18n.language
                        }
                      });
                    }}
                  >
                    {t('DevicesInscription:finalizeInstallation')}
                  </LoadingButton>
                </div>
                <CustomModal
                  isOpened={confirmOpened}
                  title={t('DevicesInscription:deviceNotAssigned')}
                  children={t(
                    'DevicesInscription:warningYouDontHaveAssignedSomeDevice'
                  )}
                  handleConfirm={() => {
                    stopPolling();
                    finalizeInstallation({
                      variables: {
                        pvInstallationId,
                        lang: i18n.language
                      }
                    });
                    displayConfirm(false);
                  }}
                  handleClose={() => {
                    displayConfirm(false);
                  }}
                />
                {loading && <SpinnerFinalisationInscription />}
              </div>
            </ListItem>
          </List>
        </Col>
        <SendInscriptionSummaryByEmailModal
          isModalOpened={isSendInscriptionSummaryModalOpened}
          toggle={() => openSendInscriptionSummaryModal(false)}
        />
      </BatteryGetInfoQuery>
    );
  }
);
export default translate()(withRouter(withApollo(FinishInstallationView)));
