import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { translate } from 'react-i18next';
import { TranslationFunction } from 'i18next';
import { get } from 'lodash';

import RegistrationStatus from '../../../constants/REGISTRATION_STATUS';
import {
  CustomField,
  DateField,
  Field,
  FieldList,
  Title,
  FieldWithError
} from '../../../ui/DataDisplay/FieldList/FieldList';
import { energyValueFormatter } from '../../../utils/tools';

import ResendActivationEmailButton from './ResendActivationEmailButton';
import BadgeStatus from '../../../ui/BadgeStatus/BadgeStatus';
import { convertTranslation } from '../../../utils/convertTranslations';
import {
  Customer,
  Device,
  PVInstallation
} from '../../../graphql/graphql.schema';
import SystemDevicesView from './SystemDevices/SystemDevicesView';
import ServiceFeatureView from '../service/ServiceFeatureView';

interface IProps {
  customer: any;
  system?: any;
  canAccessData?: boolean;
  isSystemOnline: boolean;
  t: TranslationFunction;
  isThreePhaseInstallation: boolean;
  devices: Device[];
  pvInstallation?: PVInstallation;
}

function isSendActivationEmailAvailable(status: string): boolean {
  return (
    status === RegistrationStatus.SETUP_OK ||
    status === RegistrationStatus.USER_SETTINGS_SET ||
    status === RegistrationStatus.USER_PASSWORD_SET ||
    status === RegistrationStatus.USER_ACCEPTED_LICENCE
  );
}

const SystemView = ({
  customer,
  system,
  t,
  canAccessData,
  isSystemOnline,
  isThreePhaseInstallation,
  devices,
  pvInstallation
}: IProps) => {
  if (canAccessData === false) {
    return (
      <div className="content-wrapper">
        <div className="text-center pt-3 mb-0 pb-3">
          <p>{t('Errors:dataAccessBlocked')}</p>
        </div>
      </div>
    );
  }

  const status = get(system, 'status');
  const missingMeasurementsMsg = (
    <>
      {convertTranslation(t('Messages:deviceReadingsIncomplete'))}
      <span className={'font-weight-bold'}>
        {t('Support:franceCustomerSupportNumber')}
      </span>
      <br />
      <br />
      {convertTranslation(t('Support:workingHours'))}
    </>
  );
  const isSystemDataOk =
    system && !system.localBufferRecovery && isSystemOnline;

  const ucgSerialNumber = pvInstallation && pvInstallation.ucgSerialNumber;
  const isUcgSerialNumberExists = !!ucgSerialNumber && ucgSerialNumber !== '';

  return (
    <React.Fragment>
      <Container fluid>
        <Col className="mobile-helper">
          <Row>
            <Col xs={12} lg={6}>
              <FieldList>
                <Title text={t('system')} />
                {isUcgSerialNumberExists && (
                  <Field name={t('UCG:ucg')} text={ucgSerialNumber} />
                )}
                <DateField
                  name={t('registrationDate')}
                  value={get(system, 'registrationDate')}
                />
                <CustomField name={t('registrationStatus')}>
                  <BadgeStatus status={get(system, 'status')} />
                </CustomField>
                {isSendActivationEmailAvailable(status) && (
                  <CustomField name={t('resendActivationEmailLabel')}>
                    <ResendActivationEmailButton
                      customerEmail={customer.email || ''}
                    />
                  </CustomField>
                )}
                <Field
                  name={t('internetProvider')}
                  text={get(system, 'internetProvider', '')}
                />
                <DateField
                  name={t('lastConnection')}
                  value={get(system, 'lastConnection')}
                  format="DD/MM/YYYY HH:mm"
                />
              </FieldList>
              <ServiceFeatureView customer={customer} />
            </Col>
            <Col xs={12} lg={6}>
              <FieldList>
                <Title text={t('productionAndConsumption')} />
                <Row>
                  <FieldWithError
                    name={t('globalConsumption')}
                    text={energyValueFormatter(system, 'totalNetwork.energy')}
                    isError={!isSystemDataOk || !system.consumptionUpToDate}
                    errorMsg={missingMeasurementsMsg}
                  />
                  <FieldWithError
                    name={t('production')}
                    text={energyValueFormatter(system, 'solarPanel.energy')}
                    isError={!isSystemDataOk || !system.productionUpToDate}
                    errorMsg={missingMeasurementsMsg}
                  />
                </Row>
              </FieldList>
            </Col>
          </Row>
          <Row>
            <Col xs={12} xl={6}>
              <SystemDevicesView
                devices={devices}
                isThreePhaseInstallation={isThreePhaseInstallation}
              />
            </Col>
          </Row>
        </Col>
      </Container>
    </React.Fragment>
  );
};

export default translate([
  'Customer',
  'RegistrationStatus',
  'Messages',
  'Support',
  'UCG'
])(SystemView);
