import React, { useEffect } from 'react';

import { TranslationFunction } from 'i18next';
import { translate } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import { Field, FieldList } from '../../../ui/DataDisplay/FieldList/FieldList';
import { Button, Container } from 'reactstrap';
import { FormInput } from '../../../ui/FormInput/FormInput';
import SelectInput, { ISelectValue } from '../../../ui/Select/Select';
import { CustomForm } from '../../../ui/FormInput/CustomForm';
import ShowAlert from '../../../ui/ShowAlert/ShowAlert';
import {
  FUNCTION_TYPE,
  SW_G3,
  TPH
} from '../../../stores/devices_inscription/contants';
import Toggle from '../../../ui/Button/Toggle/Toggle';
import IsolatedComponent from '../../../ui/IsolatedComponent/IsolatedComponent';
import './functionsItem.scss';
import SplitDeviceEditing from './SplitDeviceEditing';
import history from '../../../history';
import { Device, GREENPLAY_DEVICES } from '../../../stores/inscription/device';
import SaveInscriptionQueryButton from '../SaveInscriptionButton/SaveInscriptionQueryButton';
import { CompositeDevice } from '../../../stores/inscription/compositeDevice';
import { getFunctionPath } from '../routingHelper';
import inscription, {
  IDeviceId,
  UCG
} from '../../../stores/inscription/inscriptionStore';
import PossibleAlert from './PossibleAlert';
import { ISnackBarParams } from '../../../stores/userFeedback/snackbarStore';
import { safeMobxInject } from '../../../stores/storeInjectionHelpers';
import { Barcode } from '../../../stores/barcode';
import { GridType } from '@mylight/data-model';

interface IMatchParams {
  customerId: string;
  pvInstallationId: string;
  ethMac: string;
  plcMac: string;
}

interface IProps extends RouteComponentProps<IMatchParams> {
  t: TranslationFunction;
  deviceEdited?: Device;
}

interface IStoresProps {
  getPossibleOtherDeviceWithGlobalConsumption?: (
    device: Device
  ) => Device | undefined;
  getEditedDevice?: (id: IDeviceId) => Device | undefined;
  hasTooManyGlobalConsumptionSelected?: boolean;
  setUcg: (ucg: UCG) => void;
  ucg?: UCG;
  barcode: Barcode;
  showSnackBar?: (param: ISnackBarParams) => void;
  gridType?: string;
}

const EditDeviceFunction = safeMobxInject<IStoresProps, IProps>(
  stores => ({
    getPossibleOtherDeviceWithGlobalConsumption:
      stores.inscription.getPossibleOtherDeviceWithGlobalConsumption,
    getEditedDevice: stores.inscription.getEditedDevice,
    hasTooManyGlobalConsumptionSelected:
      stores.inscription.hasTooManyGlobalConsumptionSelected,
    setUcg: stores.inscription.setUcgEdited,
    ucg: stores.inscription.ucgEdited,
    barcode: stores.barcode,
    showSnackBar: stores.snackBar.showSnackBar,
    gridType: stores.inscription.gridType
  }),
  ({
    t,
    match: {
      params: {
        ethMac: ethMacFromRouter,
        plcMac: plcMacFromRouter,
        pvInstallationId,
        customerId
      }
    },
    getPossibleOtherDeviceWithGlobalConsumption,
    getEditedDevice,
    hasTooManyGlobalConsumptionSelected,
    showSnackBar,
    gridType
  }: IProps & IStoresProps) => {
    const device = getEditedDevice!({
      ethMac: ethMacFromRouter,
      plcMac: plcMacFromRouter
    });

    if (!device) {
      history.push(getFunctionPath({ pvInstallationId, customerId }));
      return <div>{t('Common:redirecting')}</div>;
    }
    const {
      roomType,
      activationCode,
      getFunctionOptions,
      getDeviceType: deviceType,
      editedControlledDeviceType,
      editedControlledDeviceName,
      editedGreenPlayEnabled,
      setEditedControlledDeviceName,
      setEditedControlledDeviceType,
      setEditedGreenPlayEnabled,
      controlledDeviceName,
      ethMac,
      plcMac,
      getDeviceFullRoomName,
      isSplitable,
      isTodo,
      isWirelessModbus
    }: Device = device;

    useEffect(() => {
      if (
        (isTodo || (!isTodo && device.deviceType !== TPH)) &&
        gridType === GridType.SINGLE_PHASE &&
        device instanceof CompositeDevice &&
        isSplitable
      ) {
        device.split();
      }
    }, []);

    const noGridTypeSelected = isSplitable
      ? !device.getCanMerge && !device.getCanSplit
      : false;

    const possibleOtherDeviceWithGlobalConsumption = getPossibleOtherDeviceWithGlobalConsumption!(
      device
    );

    const onGoBack = () => {
      device.cancelChanges();
      history.goBack();
    };

    const getFunctionNameInputLabel =
      device.getDeviceType === SW_G3
        ? t('Devices:swG3FunctionName')
        : t('Devices:functionName');

    const getFunctionInputLabel =
      device.getDeviceType === SW_G3
        ? t('Devices:swG3Function')
        : t('Devices:function');

    return (
      <IsolatedComponent
        title={controlledDeviceName}
        subtitle={`${t(`DeviceType:${deviceType}`)}`}
        onGoBack={() => onGoBack()}
      >
        <Container fluid className={'device-function-edit'}>
          <FieldList>
            <Field name={t('Devices:mac')} text={ethMac || plcMac} />
            <Field name={t('Devices:activationCode')} text={activationCode} />
            {roomType && (
              <Field name={t('Devices:room')} text={getDeviceFullRoomName} />
            )}
          </FieldList>
          <FieldList>
            {device.isDeviceForbiddenToMergeOnInstallationSinglePhase && (
              <ShowAlert message={t('forbiddenDeviceForGridType')} />
            )}
          </FieldList>
          {device instanceof CompositeDevice &&
            device.isSplitable && (
              <>
                <div className="splitable-device-divider">
                  <Button
                    color={
                      editedControlledDeviceType ===
                      FUNCTION_TYPE.COMPOSITE_DEVICE
                        ? 'secondary'
                        : 'primary'
                    }
                    disabled={!device.getCanMerge}
                    onClick={() => device.merge()}
                  >
                    {t('merge')}
                  </Button>
                  <Button
                    color={
                      editedControlledDeviceType ===
                      FUNCTION_TYPE.COMPOSITE_DEVICE
                        ? 'primary'
                        : 'secondary'
                    }
                    onClick={() => device.split()}
                    disabled={!device.getCanSplit}
                  >
                    {t('split')}
                  </Button>
                </div>
                {noGridTypeSelected && (
                  <ShowAlert
                    type={'danger'}
                    message={t('Devices:setGridType')}
                  />
                )}
              </>
            )}
          {editedControlledDeviceType !== FUNCTION_TYPE.COMPOSITE_DEVICE && (
            <div className="device-function-edit">
              <FormInput
                id={`${plcMac}`}
                name={getFunctionNameInputLabel}
                value={editedControlledDeviceName}
                onChange={v => {
                  setEditedControlledDeviceName(v);
                }}
                disabled={noGridTypeSelected || isWirelessModbus}
                error={
                  editedControlledDeviceName &&
                  device.editedControlledDeviceNameError
                }
              />
              {!device.isMst &&
                !noGridTypeSelected && (
                  <SelectInput
                    options={getFunctionOptions}
                    value={
                      editedControlledDeviceType
                        ? {
                            label:
                              t(
                                `DevicesFunctions:${editedControlledDeviceType}`
                              ) || '',
                            value: editedControlledDeviceType || ''
                          }
                        : undefined
                    }
                    placeholder={t('Devices:defineFunction')}
                    id="function"
                    key="function"
                    name={getFunctionInputLabel}
                    onChange={item => {
                      setEditedControlledDeviceType(item.value);
                      setEditedGreenPlayEnabled(
                        device.getCanHaveGreenPlay &&
                          item.value === FUNCTION_TYPE.WATER_HEATER
                      );
                    }}
                  />
                )}
              {device.getDeviceType === SW_G3 &&
                device.editedControlledDeviceType ===
                  FUNCTION_TYPE.WATER_HEATER && (
                  <ShowAlert
                    message={t('Devices:swG3WaterHeaterFunctionSelected')}
                  />
                )}
              {device.getIsModbus &&
                device.editedControlledDeviceType ===
                  FUNCTION_TYPE.WATER_HEATER && (
                  <ShowAlert
                    message={t('Devices:rs485WaterHeaterFunctionSelected')}
                  />
                )}
              {device.hasSelectedGlobalConsumption &&
                possibleOtherDeviceWithGlobalConsumption && (
                  <PossibleAlert
                    currentDevice={device}
                    deviceWithGC={possibleOtherDeviceWithGlobalConsumption}
                    pvInstallationId={pvInstallationId}
                    customerId={customerId}
                  />
                )}
              {device.getCanHaveGreenPlay &&
                GREENPLAY_DEVICES.includes(editedControlledDeviceType) && (
                  <div className={'greenplay'}>
                    <CustomForm id={`greenplay`} name={t('greenplay')}>
                      <Toggle
                        onCheckChange={checked => {
                          setEditedGreenPlayEnabled(checked);
                        }}
                        labelTrue={t(`Common:yes`)}
                        labelFalse={t(`Common:no`)}
                        checked={editedGreenPlayEnabled}
                      />
                    </CustomForm>
                  </div>
                )}
            </div>
          )}
          {device instanceof CompositeDevice &&
            device.editedAreCTSplit && (
              <SplitDeviceEditing
                device={device}
                possibleOtherDeviceWithGlobalConsumption={
                  possibleOtherDeviceWithGlobalConsumption
                }
              />
            )}
          <Button
            color="secondary"
            className={'cancel-button'}
            onClick={() => {
              device.cancelChanges!();
              history.push(getFunctionPath({ pvInstallationId, customerId }));
            }}
          >
            {t('Common:cancel')}
          </Button>
          <SaveInscriptionQueryButton
            disabled={
              !device.canSaveFunction! || hasTooManyGlobalConsumptionSelected
            }
            onCompleted={() => {
              showSnackBar!({
                text: t('Success:updateDevice'),
                type: 'success'
              });
              history.push(getFunctionPath({ pvInstallationId, customerId }));
            }}
          />
        </Container>
      </IsolatedComponent>
    );
  }
);

export default translate([
  'Devices',
  'DeviceName',
  'DeviceFunctions',
  'Common'
])(withRouter(EditDeviceFunction));
