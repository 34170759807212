import React from 'react';
import RegistrationStepperHeader from './RegistrationStepperHeader';
import RegistrationStepperContent from './RegistrationStepperContent';
import { Container } from 'reactstrap';

import './RegistrationStepper.scss';
import { IStepperMenuItem } from '../../components/devices_inscription/DevicesInscription';
import { safeMobxInject } from '../../stores/storeInjectionHelpers';

interface IProps {
  current: string;
  children?: any;
}

interface IStoresProps {
  menuItems: IStepperMenuItem[];
}
const RegistrationStepper = safeMobxInject<IStoresProps, IProps>(
  stores => ({
    menuItems: stores.inscriptionSteps.visibleMenuItems
  }),
  ({ children, menuItems, current }: IProps & IStoresProps) => {
    const currentItem = menuItems!.find(m => m.path === current);
    return (
      <Container fluid className={'registration-stepper'}>
        <RegistrationStepperHeader />
        <RegistrationStepperContent
          menuItems={menuItems!}
          currentItem={currentItem}
        >
          {children}
        </RegistrationStepperContent>
      </Container>
    );
  }
);

export default RegistrationStepper;
