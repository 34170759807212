import { action, computed, observable } from 'mobx';
import {
  EnergyDistributor,
  MSB,
  MsbBoxTypes,
  MSBStatus
} from '../../graphql/graphql.schema';
import inscription from '../inscription/inscriptionStore';
import { BatteryStatusType, MYLIGHT_MSB_STATUSES } from '../battery.constants';
import { orderBy } from 'lodash';
import gql from 'graphql-tag';
import { WithApolloClient } from 'react-apollo';
import snackbarStore from '../userFeedback/snackbarStore';
import { ApolloQueryResult } from 'apollo-client';
import { t } from 'i18next';
import { EnergyDistributorName } from '../../components/customers/installation/battery/activation/constants';

const LOAD_MSB_BY_CUSTOMER_ID = gql`
  query getMSB($customerId: String!) {
    getMSB(customerId: $customerId) {
      id
      status {
        status
        validateAt
        isValidate
      }
      pdlNumber
      pdlNumberModifiedAt
      energyDistributor {
        id
        name
      }
      caeNumber
      caeNumberModifiedAt
      enedisConnectNumber
      enedisConnectNumberModifiedAt
      consuelNumber
      consuelNumberModifiedAt
      consuelDeclaredPower
      consuelDeclaredPowerModifiedAt
      connectionPower
      connectionPowerModifiedAt
      strasbourgInjectionPdlNumber
      strasbourgInjectionPdlNumberModifiedAt
      serialNumberModifiedAt
      boxesSerialNumber
      volume
      customerId
      sendingCAEDate
      sendingARPEDate
      sendingCONSUELDate
      type
    }
  }
`;

export class MySmartBattery {
  @observable public isLoadingLoadMsb: boolean = false;
  @observable public isErrorLoadMsb: boolean = false;

  @observable public type: MsbBoxTypes | null = null;
  @observable public status: MSBStatus[] = [];
  @observable public energyDistributor: EnergyDistributor | null = null;

  // TODO Delete when all MSB PO remove
  @computed
  public get isMsbMylight(): boolean {
    return this.type === MsbBoxTypes.MYLIGHT;
  }

  @computed
  public get isMsbEnedis(): boolean {
    if (this.energyDistributor) {
      return this.energyDistributor.name === EnergyDistributorName.ENEDIS;
    }
    return false;
  }

  @computed
  public get isMsbStrasbourgElectricity(): boolean {
    if (this.energyDistributor) {
      return (
        this.energyDistributor.name ===
        EnergyDistributorName.ELECTRICITE_STRASBOURG
      );
    }
    return false;
  }

  @computed
  public get msbOkStatus(): MSBStatus | undefined {
    return this.status.find(s => s.status === BatteryStatusType.OK);
  }

  @computed
  public get msbEnedisGridInjectionRequestedStatus(): MSBStatus | undefined {
    return this.status.find(
      s => s.status === BatteryStatusType.ENEDIS_GRID_INJECTION_REQUESTED
    );
  }

  @computed
  public get msbStrasbourgElectricityAdministrativeProcedureRequestedStatus():
    | MSBStatus
    | undefined {
    return this.status.find(
      s =>
        s.status ===
        BatteryStatusType.STRASBOURG_ELECTRICITY_ADMINISTRATIVE_PROCEDURE_REQUESTED
    );
  }

  @computed
  public get msbSnFilledStatus(): MSBStatus | undefined {
    return this.status.find(s => s.status === BatteryStatusType.SN_FILLED);
  }

  @computed
  public get getMSBStatuses(): MSBStatus[] {
    let msbStatus = this.isMsbMylight
      ? this.status.filter(
          status =>
            status.status && MYLIGHT_MSB_STATUSES.includes(status.status)
        )
      : [...this.status];

    if (!this.msbOkStatus) {
      return orderBy(msbStatus, ['validateAt'], ['desc']);
    }

    msbStatus.splice(msbStatus.indexOf(this.msbOkStatus), 1);
    msbStatus = orderBy(msbStatus, ['validateAt'], ['desc']);
    if (this.msbOkStatus.isValidate) {
      msbStatus.unshift(this.msbOkStatus);
    }
    return msbStatus;
  }

  @computed
  public get isMSBAdded(): boolean {
    return !!(this.getMSBStatuses && this.getMSBStatuses.length > 0);
  }

  @computed
  public get isMSBActivated(): boolean {
    return !!(this.msbOkStatus && this.msbOkStatus.isValidate);
  }

  @computed
  public get isMSBEnedisGridInjectionRequested(): boolean {
    return !!(
      this.msbEnedisGridInjectionRequestedStatus &&
      this.msbEnedisGridInjectionRequestedStatus.isValidate
    );
  }

  @computed
  public get isMSBStrasbourgElectricityAdministrativeProcedureRequested(): boolean {
    return !!(
      this.msbStrasbourgElectricityAdministrativeProcedureRequestedStatus &&
      this.msbStrasbourgElectricityAdministrativeProcedureRequestedStatus
        .isValidate
    );
  }

  @computed
  public get canAddMSB(): boolean {
    return !this.isMSBAdded;
  }

  @computed
  public get canEditMSB(): boolean {
    return this.isMSBAdded && !this.isMSBActivated;
  }

  // TODO to move on inscription store
  @computed
  public get haveRightToAccessMSB(): boolean {
    return !inscription.customerIsFromCH;
  }

  @action.bound
  public setActivationMsb(msb: MSB): void {
    this.clearActivationMsb();
    if (msb.energyDistributor) {
      this.energyDistributor = msb.energyDistributor;
    }
    if (msb.type) {
      this.type = msb.type;
    }
    if (msb.status) {
      this.status = msb.status;
    }
  }

  @action.bound
  public clearActivationMsb(): void {
    this.type = null;
    this.status = [];
    this.energyDistributor = null;
  }

  @action.bound
  public async loadMsb(
    client: WithApolloClient<any>,
    customerId: string
  ): Promise<any> {
    this.isLoadingLoadMsb = true;
    this.isErrorLoadMsb = false;

    this.clearActivationMsb();
    try {
      const { data, errors }: ApolloQueryResult<any> = await client.query({
        fetchPolicy: 'no-cache',
        query: LOAD_MSB_BY_CUSTOMER_ID,
        variables: { customerId }
      });

      this.isLoadingLoadMsb = false;

      if (errors) {
        this.isErrorLoadMsb = true;
        return;
      }

      return data.getMSB;
    } catch (error) {
      this.isLoadingLoadMsb = false;
      if (
        error.graphQLErrors[0] &&
        error.graphQLErrors[0].message &&
        error.graphQLErrors[0].message.error &&
        error.graphQLErrors[0].message.error.type ===
          'MySmartBatteryNotFoundException'
      ) {
        return;
      }
      snackbarStore.showErrorSnackBar({
        text: t('MySmartBattery:errorLoadingMsbActivation')
      });
    }
  }
}

const mySmartBattery: MySmartBattery = new MySmartBattery();
export default mySmartBattery;
