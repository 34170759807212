import * as React from 'react';
import { MutationResult } from 'react-apollo';

import gql from 'graphql-tag';
import { RouteComponentProps, withRouter } from 'react-router';
import Mutation from 'react-apollo/Mutation';
import { observer, inject } from 'mobx-react';
import { ECustomer } from '../../../../graphql/graphql.schema';
import EnphaseEmailView from './EnphaseEmailView';
import { translate, TranslationFunction } from 'react-i18next';
import { IAllStores } from '../../../../stores/allStores.model';
import { EnphaseStore } from '../../../../stores/partnerships/enphaseStore';
import { ISnackBarParams } from '../../../../stores/userFeedback/snackbarStore';

export const EDIT_CUSTOMER = gql`
  mutation updateCustomer($input: ECustomer!) {
    updateCustomer(customer: $input) {
      id
      email
    }
  }
`;

export interface IProps extends RouteComponentProps<IMatchParams> {
  enphaseStore?: EnphaseStore;
  t: TranslationFunction;
  createEnphaseAccount: () => void;
  showSnackBar?: (params: ISnackBarParams) => void;
}

interface IMatchParams {
  customerId: string;
}

interface IMutation {
  customer: ECustomer;
}

const EnphaseEmailQuery = inject((allStores: IAllStores) => {
  return {
    enphaseStore: allStores.enphaseStore,
    showSnackBar: allStores.snackBar.showSnackBar
  };
})(
  observer(
    ({
      enphaseStore,
      t,
      createEnphaseAccount,
      match: {
        params: { customerId }
      },
      showSnackBar
    }: IProps) => {
      return (
        <Mutation
          mutation={EDIT_CUSTOMER}
          onCompleted={() => {
            enphaseStore!.updateEmailChoice(true);
            showSnackBar!({
              text: t('Success:updateCustomer'),
              type: 'success'
            });
          }}
        >
          {(
            updateCustomer,
            { error, loading, data }: MutationResult<IMutation>
          ) => {
            const { addEmailError, clearEmailErrors } = enphaseStore!;

            clearEmailErrors();
            if (error) {
              error.graphQLErrors.map(e => {
                if (
                  e.message.error === 'Unauthorized' ||
                  e.message.errorCode === 'customerMailAlreadyExist'
                ) {
                  addEmailError(`${t('emailExists')}`);
                  showSnackBar!({
                    text: t('emailExists'),
                    type: 'error'
                  });
                } else {
                  addEmailError(`${t('emailUpdate')}`);
                  showSnackBar!({
                    text: t('emailUpdate'),
                    type: 'error'
                  });
                }
              });
            }

            return (
              <EnphaseEmailView
                data={data}
                loading={loading}
                updateCustomerEmail={async (newEmail: string) => {
                  await updateCustomer({
                    variables: {
                      input: { id: customerId, email: newEmail }
                    }
                  });
                }}
                createEnphaseAccount={createEnphaseAccount}
              />
            );
          }}
        </Mutation>
      );
    }
  )
);

export default withRouter(translate(['EnphaseErrors'])(EnphaseEmailQuery));
