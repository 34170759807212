import React, { ReactNode, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Query, QueryResult } from 'react-apollo';
import gql from 'graphql-tag';
import {
  MSBStatus,
  PPDeviceConfigFile,
  RegistrationSteps,
  SrsCustomer
} from '../../graphql/graphql.schema';
import Spinner from '../../ui/Spinner/Spinner';
import { ScanCodeType } from '../../stores/barcode';
import { ApolloError } from 'apollo-client';
import { IInscriptionNavigationRouterParamProps } from './InscriptionNavigation';
import { safeMobxInject } from '../../stores/storeInjectionHelpers';
import { pendingRegistration } from '../../utils/tools';
import i18n from '../../translations/i18n';

interface IProps
  extends RouteComponentProps<IInscriptionNavigationRouterParamProps> {
  children: ReactNode;
}
interface IStoresProps {
  resetInscriptionStore: () => void;
  initErrorsFromGQLError: (e: ApolloError) => void;
  barcodeType?: ScanCodeType;
  refreshDeviceList: (
    data: ICustomerAndDevicesResponse,
    customerId: string,
    pvInstallationId: string,
    current: string
  ) => void;
}
export interface IStepperMenuItem {
  id: number;
  key: string;
  name: string;
  path: string;
  isDone: boolean;
  hidden?: boolean;
  disabled?: boolean;
}

export interface ICustomerAndDevicesResponse {
  getOneToOneCustomerData?: SrsCustomer;
  devicesConfig?: PPDeviceConfigFile;
  getMSBStatus?: MSBStatus[];
  getRegistrationSteps?: RegistrationSteps;
}

/*
    ###################    APOLLO CACHE MANAGEMENT    ##########################
    Share this with mutation so the apollo cache is updated , and this query is re-executed
    This is not ideal but it's a fix, maybe we can look into graphqlFragment to make it more cleanly
    https://www.apollographql.com/docs/react/advanced/fragments/
 */
export const DEVICE_INSCRIPTION_FIELD_QUERY = ` 
      id
      ucg {
        ucgSerialNumber
        noUcg
      }
      isDevicesConfigurationModeSelected
      devices {
        id
        activationCode
        ethMac
        firmware
        plcMac
        power
        deviceTypeOverride
        preConfig {
          compositeId
          controlledDeviceName
          controlledDeviceType
          greenPlayEnabled
          isDeleted
          phase
          roomName
          roomType
          isWirelessModbus
        }
        state
        status
        deviceType
        emulatedFromG3
        simNb
        rssi
        rscp
        gsmSignalQuality
        isGSMConnected
        tx
        rx
        plcSignalQuality
      }
      alertsCode
      mstError
      error {
        mac
        message
        step
      }
      mst {
        ethMac
        activationCode
        generation
      }
      `;

export const GET_CUSTOMER_AND_DEVICE_CONFIG = gql`
    query getOneToOneCustomerData(
        $customerId: String!
        $pvInstallationId: String!
        $lang: String!
    ) {
        devicesConfig(id: $pvInstallationId, lang:$lang) {
            ${DEVICE_INSCRIPTION_FIELD_QUERY}
        }
        getOneToOneCustomerData(customerId: $customerId) {
            id
            customer {
                id
                firstName
                lastName
                address
                country
                email
                city
                zipCode
                msbStatus
            }
            pVInstallation {
                id
                canWrite
                panelCount
                panelWattPeak
                panelManufacturer
                srsRegistrationDate
                srsRegistrationStatus
                currency
                srsLicenceStatus
                tenant {
                    id
                    name
                }
            }
            electricInstallation {
                id
                sellingPower
                sellingEnergyPrice
                powerProvider
                powerContractType
                powerContractOption
                rackingPower
                internetBoxModel
                gridType
                internetServiceProvider
                masterTypeInternetConnection
                simSerialNumber
                simRouterReference
                timeSlots
                pdlNumber
            }
        }
        getMSBStatus(customerId: $customerId) {
            status
            validateAt
            isValidate
        }
        getRegistrationSteps(pvInstallationId: $pvInstallationId){
            id
            msb
            partnership
        }
    }
`;

export const InscriptionQueryContext = React.createContext({
  refetchInscriptionQuery: (variables?: { hideLoading: boolean }) => {},
  inscriptionQueryLoading: false
});

const DevicesInscription = safeMobxInject<IStoresProps, IProps>(
  stores => ({
    barcodeType: stores.barcode.type,
    initErrorsFromGQLError: stores.inscriptionError.initErrorsFromApolloError,
    resetInscriptionStore: stores.inscription.resetInscriptionStore,
    refreshDeviceList: stores.inscription.refreshDeviceList
  }),
  ({
    children,
    match: {
      params: { customerId, pvInstallationId, current }
    },
    initErrorsFromGQLError,
    barcodeType,
    history,
    resetInscriptionStore,
    refreshDeviceList
  }: IProps & IStoresProps) => {
    useEffect(() => {
      return () => resetInscriptionStore();
    }, []);
    return (
      <Query
        query={GET_CUSTOMER_AND_DEVICE_CONFIG}
        variables={{ customerId, pvInstallationId, lang: i18n.language }}
        fetchPolicy="cache-and-network"
        errorPolicy="ignore"
        skip={!!barcodeType}
        onCompleted={async (data: ICustomerAndDevicesResponse) => {
          if (data.getOneToOneCustomerData) {
            const status =
              data.getOneToOneCustomerData.pVInstallation.srsRegistrationStatus;
            const id = data.getOneToOneCustomerData.customer.id;

            if (!pendingRegistration(status || '')) {
              setTimeout(() => history.push(`/customers/${id}/identity`), 500);
            }
          }
          refreshDeviceList(data, customerId, pvInstallationId, current);
        }}
        onError={e => {
          initErrorsFromGQLError(e);
        }}
      >
        {({
          loading,
          refetch,
          variables
        }: QueryResult<ICustomerAndDevicesResponse>) => {
          const isLoading = loading && !variables.hideLoading;
          return (
            <InscriptionQueryContext.Provider
              value={{
                refetchInscriptionQuery: refetch,
                inscriptionQueryLoading: loading
              }}
            >
              <Spinner loading={isLoading}>{children}</Spinner>
            </InscriptionQueryContext.Provider>
          );
        }}
      </Query>
    );
  }
);

export default withRouter(DevicesInscription);
