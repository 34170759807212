import * as React from 'react';
import { Col, Container, Row } from 'reactstrap';

import PendingCustomersListQuery from './PendingCustomersListQuery';
import LastRegisteredCustomersListQuery from './LastRegisteredCustomersListQuery';
import PendingCustomersActivationListQuery from './PendingCustomersActivationListQuery';
import ProsizerAdvert from './ProsizerAdvert/ProsizerAdvert';
import './dashboard.scss';

const Dashboard = () => {
  return (
    <div className="dashboard">
      <Container fluid>
        <Row>
          <ProsizerAdvert />
          <Col md={12} lg={6} className="mobile-helper">
            <PendingCustomersListQuery />
          </Col>
          <Col md={12} lg={6} className="mobile-helper">
            <PendingCustomersActivationListQuery />
          </Col>
          <Col md={12} lg={12} className="mobile-helper">
            <LastRegisteredCustomersListQuery />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Dashboard;
