import { default as React, ReactNode } from 'react';
import './MainMenu.scss';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { Nav, NavItem } from 'reactstrap';

import myProPortal from '../../../assets/images/my_pro_portal.png';
import { IconLogout } from '../../../ui/Icons/IconLogout';
import tools from '../../../utils/tools';
import ProsizerIcon from '../../../ui/Icons/ProsizerIcon';
import { safeMobxInject } from '../../../stores/storeInjectionHelpers';
import IconMSB from '../../svg/IconMSB';

interface IMainMenuItem {
  key: string;
  name: string;
  path: string;
  icon: string;
}

interface IStoresProps {
  backVersion: string;
}

interface IProps {
  mainMenuItems: IMainMenuItem[];
  children?: ReactNode;
  currentItem?: IMainMenuItem;
  closeMenu: () => void;
  openMenu: () => void;
  menuIsVisible: boolean;
  logo: string;
  title?: string;
  prosizerUrl?: string;
}

const getMenuItems = ({
  mainMenuItems,
  currentItem,
  closeMenu,
  prosizerUrl
}: {
  mainMenuItems: IMainMenuItem[];
  currentItem?: IMainMenuItem;
  closeMenu: () => void;
  prosizerUrl?: string;
}) => {
  const getMenuItemIcon = (menuItem: IMainMenuItem): ReactNode => {
    if (menuItem.key === 'proSizer') {
      return (
        <div className={'prosizer-icon'}>
          <ProsizerIcon />
        </div>
      );
    }
    if (menuItem.key === 'disconnect') {
      return <IconLogout />;
    }
    if (menuItem.key === 'mySmartBattery') {
      return <IconMSB isActive={itemIsActive(menuItem, currentItem)} />;
    }
    return (
      <i
        className={classnames(
          itemIsActive(menuItem, currentItem)
            ? `icon-${menuItem.icon}-active`
            : `icon-${menuItem.icon}`,
          {
            active: itemIsActive(menuItem, currentItem)
          }
        )}
      />
    );
  };

  return mainMenuItems.map(menuItem => (
    <NavItem
      className={'main-menu-item'}
      key={menuItem.key}
      onClick={() => {
        closeMenu();
      }}
    >
      <div
        className={classnames('main-menu-notifier', {
          active: currentItem ? currentItem.key === menuItem.key : false
        })}
      />
      <Link
        className={classnames('main-menu-link', {
          active: itemIsActive(menuItem, currentItem)
        })}
        to={`/${menuItem.path}`}
        onClick={() => {
          if (menuItem.key === 'disconnect') {
            localStorage.removeItem('Auth-Token');
          }
          if (menuItem.key === 'proSizer') {
            tools.openNewTabfForURI(
              `${prosizerUrl}?token=${localStorage.getItem('Auth-Token')}`
            );
          }
        }}
      >
        <div className={'main-menu-icon'}>{getMenuItemIcon(menuItem)}</div>
        <span>{menuItem.name}</span>
      </Link>
    </NavItem>
  ));
};
export const MainMenu = safeMobxInject<IStoresProps, IProps>(
  allStores => ({
    backVersion: allStores.Store.mainMenu.backVersion
  }),
  ({
    backVersion,
    children,
    currentItem,
    menuIsVisible,
    mainMenuItems,
    title,
    closeMenu,
    openMenu,
    logo,
    prosizerUrl
  }: IProps & IStoresProps) => {
    return (
      <section className="main-menu">
        <div
          className={classnames('menu-overlay', { visible: menuIsVisible })}
          onClick={() => {
            closeMenu();
          }}
        >
          <div
            className="menu-items"
            onClick={event => {
              event.stopPropagation();
            }}
          >
            <div className="main-menu-header">
              <i
                className="icon-cross"
                color="primary"
                onClick={() => {
                  closeMenu();
                }}
              />
              <Link to="/dashboard">
                <img className={'main-menu-logo'} src={logo} />
              </Link>
              <i className="header-notification-button icon-notification" />
            </div>
            <Nav vertical>
              {getMenuItems({
                mainMenuItems,
                currentItem,
                closeMenu,
                prosizerUrl
              })}
            </Nav>
            <div className="main-menu-footer">
              <div className="main-menu-items-footer">
                <img style={{ width: '150px' }} src={myProPortal} />
              </div>
              <div className="main-menu-items-version">
                {`${process.env.REACT_APP_BUILD_NUMBER ||
                  'dev'} - ${backVersion}`}
              </div>
            </div>
          </div>
        </div>
        <div className="main-menu-content-header">
          <i
            className="icon-menu"
            color="link"
            onClick={() => {
              openMenu();
            }}
          />
          <label className="page-name">
            {title || (currentItem ? currentItem.name : '')}
          </label>
        </div>
        <div className="main-menu-content" id={'main-menu-scroll-container'}>
          {children}
        </div>
      </section>
    );
  }
);

const itemIsActive = (menuItem: IMainMenuItem, currentItem?: IMainMenuItem) =>
  currentItem && currentItem.key === menuItem.key;
export default MainMenu;
