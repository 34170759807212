import * as React from 'react';
import { TranslationFunction } from 'i18next';
import { translate } from 'react-i18next';

import './isOfflineIndicator.scss';

interface IProps {
  t: TranslationFunction;
}

const IsOfflineIndicator = (props: IProps) => {
  return (
    <div className={'is-system-offline-indicator'}>
      {props.t('systemOffline')}
    </div>
  );
};

export default translate(['Installation'])(IsOfflineIndicator);
