import { Button } from 'reactstrap';
import * as React from 'react';
import { TranslationFunction } from 'i18next';
import { inject, observer } from 'mobx-react';
import { Store } from '../../stores';
import { translate } from 'react-i18next';

import './createUserButton.scss';
import { RouteComponentProps, withRouter } from 'react-router';

interface IProps extends RouteComponentProps {
  t: TranslationFunction;
  Store?: Store;
}

const CreateUserButton = inject('Store')(
  observer(({ Store: mobix, t, history }: IProps) => {
    return (
      <Button
        className="add create-customer-button"
        color="primary"
        onClick={() => {
          history.push(`/createCustomer`);
        }}
      >
        {t('newSubscription')}
      </Button>
    );
  })
);

export default translate(['Customer', 'Tenant'])(withRouter(CreateUserButton));
