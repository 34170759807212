import React from 'react';

import { translate } from 'react-i18next';
import { TranslationFunction } from 'i18next';
import classnames from 'classnames';

import {
  CustomField,
  FieldList,
  Title
} from '../../../ui/DataDisplay/FieldList/FieldList';
import { ITenant } from '@mylight/data-model';
import { get } from 'lodash';
import { Customer } from '../../../graphql/graphql.schema';

interface IProps {
  customer: any;
  t: TranslationFunction;
}

function getStatus(statusOK: boolean, t: TranslationFunction): string {
  if (statusOK) return t('enabled');
  return t('disabled');
}

function isDomuneo(tenant?: ITenant): boolean {
  return get(tenant, 'name') === 'domuneo';
}

const ServiceFeatureView = ({ customer, t }: IProps) => {
  return (
    <FieldList>
      <Title text={t('services')} />
      {customer &&
        customer.licenceStatus !== undefined &&
        isDomuneo(customer.tenant) && (
          <CustomField className="licence-field" name={t('licence')}>
            {customer && (
              <span
                className={classnames('badge-status', {
                  success: customer.licenceStatus
                })}
              >
                {getStatus(customer.licenceStatus, t)}
              </span>
            )}
          </CustomField>
        )}
      <CustomField name={t('smsAlert')}>
        {customer && (
          <span
            className={classnames('badge-status', {
              success: customer.smsAlertsEnabled
            })}
          >
            {getStatus(customer.smsAlertsEnabled, t)}
          </span>
        )}
      </CustomField>
      <CustomField name={t('emailAlert')}>
        {customer && (
          <span
            className={classnames('badge-status', {
              success: customer.emailAlertsEnabled
            })}
          >
            {getStatus(customer.emailAlertsEnabled, t)}
          </span>
        )}
      </CustomField>
    </FieldList>
  );
};

export default translate('Customer')(ServiceFeatureView);
