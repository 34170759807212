import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TranslationFunction } from 'i18next';
import React from 'react';
import { translate } from 'react-i18next';
import { Button } from 'reactstrap';
import { IModbusDevice } from '../../../../../stores/inscription/addDeviceStore';
import { safeMobxInject } from '../../../../../stores/storeInjectionHelpers';
import '../addDevice.scss';

interface IProps {
  t: TranslationFunction;
  device: IModbusDevice;
}

interface IStoresProps {
  removeReferenceFromModbusDevicesList: (device: IModbusDevice) => void;
}

const WModbusDeviceItem = safeMobxInject<IStoresProps, IProps>(
  allStores => ({
    removeReferenceFromModbusDevicesList:
      allStores.addDevice.removeReferenceFromModbusDevicesList
  }),
  ({ t, device, removeReferenceFromModbusDevicesList }) => {
    const handleRemoveModbusDevice = () => {
      removeReferenceFromModbusDevicesList(device);
    };

    return (
      <li className={'wmodbus-device-item'}>
        <span>
          {device.reference} - {device.address}
        </span>

        <Button
          color={'danger'}
          title={t('Common:remove')}
          onClick={handleRemoveModbusDevice}
        >
          <FontAwesomeIcon icon={'trash'} />
        </Button>
      </li>
    );
  }
);

export default translate()(WModbusDeviceItem);
