import { Button, Col, Row } from 'reactstrap';
import { FieldList, Title } from '../../ui/DataDisplay/FieldList/FieldList';
import * as React from 'react';
import { translate, TranslationFunction } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface IProps extends RouteComponentProps<any> {
  t: TranslationFunction;
}

const ToolsTestPairingLink = ({ t, history }: IProps) => {
  const moveToPairingToolkit = () => {
    history.push('/toolTestPairing');
  };
  return (
    <Col className="technical-support mobile-helper" xs={12}>
      <FieldList>
        <Title text={t('ToolsPairing:verifPairing')} />
        <Row>
          <Col sm={12} lg={6} xl={3}>
            <Button block onClick={moveToPairingToolkit}>
              {t('Support:access')}
            </Button>
          </Col>
        </Row>
      </FieldList>
    </Col>
  );
};

export default translate()(withRouter(ToolsTestPairingLink));
