import * as React from 'react';
import { translate } from 'react-i18next';
import { TranslationFunction } from 'i18next';
import { Col, Row } from 'reactstrap';

import { get, sortBy } from 'lodash';

import Spinner from '../../ui/Spinner/Spinner';
import ListHeader, { ListTitle } from '../../ui/DataDisplay/List/ListHeader';
import ListItem from '../../ui/DataDisplay/List/ListItem';
import ListPlaceHolder from '../../ui/DataDisplay/List/ListPlaceHolder';
import CreateUserButton from '../redirect/CreateUserButton';
import ContinueSubscriptionButton from '../redirect/ContinueSubscriptionButton';
import TenantLogo from '../../assets/images/tenant/TenantLogo';
import List from '../../ui/DataDisplay/List/ListWrapper';
import history from '../../history';
import { inject, observer } from 'mobx-react';
import { Store } from '../../stores';
import { Customer } from '../../graphql/graphql.schema';

interface IProps {
  t: TranslationFunction;
  customers?: any[];
  error?: string;
  Store?: Store;
  loading?: boolean;
}

const PendingCustomersListView = inject('Store')(
  observer(({ t, customers, loading, error, Store: mobixStore }: IProps) => {
    const userType = mobixStore!.profile.userType;
    const orderedCustomers = (customers || []).slice(0, 10);

    return (
      <Spinner loading={loading} error={error}>
        <List fluid>
          <ListTitle
            text={t('pendingCustomers')}
            children={<CreateUserButton />}
          />
          <ListHeader>
            <Col xs={2} className="d-none d-sm-none d-md-block">
              {`${t('tenant')}`}
            </Col>

            <Col xs={5} className="d-none d-sm-none d-md-block">
              {`${t('name')}`}
            </Col>
            <Col
              xs={5}
              className="d-none d-sm-none d-md-block text-right"
              style={{ minWidth: '9rem' }}
            >
              {`${t('registrationStatus')}`}
            </Col>
          </ListHeader>
          {orderedCustomers.length === 0 && (
            <ListPlaceHolder text={t('pendingCustomerEmptyList')} />
          )}
          {orderedCustomers.map((customer: any) => (
            <ListItem
              key={customer.id}
              options={{
                onClick: () =>
                  history.push(`/customers/${customer.id}/identity`)
              }}
            >
              <Col xs={2}>
                <TenantLogo tenant={get(customer, 'tenant')} />
              </Col>
              <Col className="font-weight-bold text-left" xs={5}>{`${
                customer.lastName ? customer.lastName.toUpperCase() : ''
              } ${customer.firstName}`}</Col>
              <Col xs={5} className="text-right">
                <ContinueSubscriptionButton
                  customerId={customer.id}
                  pvInstallationId={customer.pvInstallationId}
                  srsRegistrationStatus={customer.status}
                />
              </Col>
            </ListItem>
          ))}
        </List>
      </Spinner>
    );
  })
);

export default translate('Customer')(PendingCustomersListView);
