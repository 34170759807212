import { translate, TranslationFunction } from 'react-i18next';
import { Customer } from '../../../graphql/graphql.schema';
import * as React from 'react';
import { Col, Row } from 'reactstrap';
import { Field, FieldList } from '../../../ui/DataDisplay/FieldList/FieldList';
import { ListTitle } from '../../../ui/DataDisplay/List/ListHeader';
import { safeMobxInject } from '../../../stores/storeInjectionHelpers';
import GsmQualitySignal from '../master/GsmQualitySignal';
import { Device } from '../../../stores/inscription/device';
import MasterConnectionTypePlcMylightAdditionalInfo from '../master/MasterConnectionTypePlcMylightAdditionalInfo';

interface IStoresProps {
  adapterCplMylight?: Device;
  masterDevice?: Device;
  isPlcSignalInfoDisplayed: boolean;
  isMasterConnectedViaGSM: boolean;
  isGsmSignalInfoDisplayed: boolean;
}

interface IProps {
  t: TranslationFunction;
  panelCount?: number;
  pdlNumber?: string;
  internetServiceProvider?: string;
  panelWattPeak?: number;
  panelManufacturer?: string;
  rackingPower: number;
  gridType?: string;
  masterTypeInternetConnection?: string;
  simSerialNumber?: string;
  simRouterReference?: string;
}

const SummaryInstallation = safeMobxInject<IStoresProps, IProps>(
  stores => ({
    adapterCplMylight: stores.inscription.adapterCplMylight,
    masterDevice: stores.inscription.masterDevice,
    isPlcSignalInfoDisplayed: stores.inscription.isPlcSignalInfoDisplayed,
    isMasterConnectedViaGSM: stores.inscription.isMasterConnectedViaGSM,
    isGsmSignalInfoDisplayed: stores.inscription.isGsmSignalInfoDisplayed
  }),
  ({
    t,
    panelCount,
    pdlNumber,
    internetServiceProvider,
    panelWattPeak,
    panelManufacturer,
    rackingPower,
    gridType,
    masterTypeInternetConnection,
    simSerialNumber,
    simRouterReference,
    masterDevice,
    adapterCplMylight,
    isPlcSignalInfoDisplayed,
    isMasterConnectedViaGSM,
    isGsmSignalInfoDisplayed
  }: IStoresProps & IProps) => {
    return (
      <Row>
        <Col md={12}>
          <FieldList>
            <ListTitle text={t('RegistrationMenu:pv_installation')} />
            <Field name={t('Customer:panelCount')} text={panelCount} />
            <Field name={t('Installation:pdl')} text={pdlNumber} />
            <Field
              name={t('Customer:internetServiceProvider')}
              text={internetServiceProvider}
            />
            <Field name={t('Customer:wattPeakByPanel')} text={panelWattPeak} />
            <Field
              name={t('Customer:panelManufacturer')}
              text={panelManufacturer}
            />
            <Field
              name={t('Customer:rackingPower')}
              text={rackingPower && `${rackingPower} kVa`}
            />
            <Field
              name={t('Customer:gridElectricalPhaseType')}
              text={gridType && t(`Customer:${gridType}`)}
            />
            <Field
              name={t('Installation:masterTypeInternetConnection')}
              text={
                masterTypeInternetConnection &&
                t(`Installation:${masterTypeInternetConnection}ConnectionType`)
              }
            />
            {isPlcSignalInfoDisplayed &&
              adapterCplMylight && (
                <Field
                  name={t('Devices:pclRateBetweenMasterAndComOne')}
                  text={
                    <MasterConnectionTypePlcMylightAdditionalInfo
                      tx={adapterCplMylight.tx}
                      rx={adapterCplMylight.rx}
                      plcSignalQuality={adapterCplMylight.plcSignalQuality}
                    />
                  }
                />
              )}
            {isMasterConnectedViaGSM && (
              <>
                <Field
                  name={t('Installation:simRouterReference')}
                  text={simRouterReference && simRouterReference}
                />
                <Field
                  name={t('Installation:simSerialNumber')}
                  text={simSerialNumber && simSerialNumber}
                />
                {isGsmSignalInfoDisplayed && (
                  <Field
                    name={t('Devices:signalReceptionGSM')}
                    text={
                      <GsmQualitySignal
                        rssi={masterDevice && masterDevice.rssi}
                        rscp={masterDevice && masterDevice.rscp}
                        gsmSignalQuality={
                          masterDevice && masterDevice.gsmSignalQuality
                        }
                      />
                    }
                  />
                )}
              </>
            )}
          </FieldList>
        </Col>
      </Row>
    );
  }
);

export default translate(['Customer', 'Installation', 'RegistrationMenu'])(
  SummaryInstallation
);
