import React from 'react';
import { IDeviceIcon } from '../DeviceIcons';

const AsokaRedPlug = (props: IDeviceIcon) => (
  <svg
    width="3rem"
    height="3rem"
    viewBox="0 0 20000 20000"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    color="black"
    {...props}
  >
    <defs>
      <style>{'.prefix__fil0{fill:#2b2a29}'}</style>
    </defs>
    <g id="prefix__layer">
      <g id="prefix__asoka_red_plug">
        <path
          style={{ fill: `${props.color}` }}
          d="M9641 8669c-206 0-325-137-325-325 0-206 137-343 325-343 206 0 343 137 343 343-17 188-137 325-343 325zM10699 8669c-206 0-343-137-343-325 0-206 137-343 343-343s325 137 325 343c17 188-120 325-325 325z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M8921 14241c-743 0-1236-456-1236-1429V6799c0-895 697-1236 1324-1236h2211c952-16 1413 351 1413 1083v6182c0 821-397 1421-1244 1413H8921zm65-8158c-335 8-767 192-767 780v5985c-4 367 98 822 593 875h2585c544-16 710-480 718-855V6855c-28-355-61-804-862-780l-2267 8z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M10180 10028c-981 0-1769-803-1769-1784s803-1769 1769-1769c981 0 1769 803 1769 1769 15 981-788 1784-1769 1784zm3-3017c-312 0-642 147-871 352-271 247-348 583-348 895 0 610 607 1216 1216 1216 610 0 1232-607 1232-1216 0-624-526-1235-1224-1247l-5-1z"
        />
      </g>
    </g>
  </svg>
);

export default AsokaRedPlug;
