import React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { translate, TranslationFunction } from 'react-i18next';
import { Row } from 'reactstrap';
import classnames from 'classnames';
import { IStepperMenuItem } from '../../components/devices_inscription/DevicesInscription';
import doneIcon from '../../assets/images/check-circle-outline.svg';
import todoIcon from '../../assets/images/check-circle-outline-blank.svg';

interface IMatchParams {
  customerId: string;
  pvInstallationId: string;
}

interface IProps extends RouteComponentProps<IMatchParams> {
  Store?: any;
  t: TranslationFunction;
  menuItems: IStepperMenuItem[];
  currentItem: any;
  children?: any;
}

class RegistrationStepperContent extends React.Component<IProps> {
  public render() {
    const {
      menuItems,
      currentItem,
      match: {
        params: { customerId, pvInstallationId }
      },
      children
    } = this.props;
    return (
      <Row className={'registration-stepper-container'}>
        <div className={'registration-stepper-menu'}>
          {menuItems.map((item, idx) => {
            return item.disabled ? (
              <div key={item.key} className={'menu-item disabled'}>
                <img src={todoIcon} className="item-step" alt="status" />
                <div className={'item-name'}>
                  <span>{idx + 1}.</span>
                  <span>{item.name}</span>
                </div>
              </div>
            ) : (
              <Link
                key={item.key}
                className={classnames('menu-item', {
                  active: currentItem && currentItem.key === item.key
                })}
                to={`/devices_inscription/${customerId}/${
                  item.path
                }/${pvInstallationId}`}
              >
                <img
                  src={item.isDone ? doneIcon : todoIcon}
                  className="item-step"
                  alt="status"
                />
                <div className={'item-name'}>
                  <span>{idx + 1}.</span>
                  <span>{item.name}</span>
                </div>
              </Link>
            );
          })}
        </div>
        <div
          className={'registration-stepper-content'}
          id={'registration-stepper-content'}
        >
          {children}
        </div>
      </Row>
    );
  }
}

export default translate('DevicesInscription')(
  withRouter(RegistrationStepperContent)
);
