import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import ModbusBoxImage from '../../../../../assets/images/devices/devicetype/modbus-box.jpg';
import ModbusPartImage from '../../../../../assets/images/devices/devicetype/modbus-part.png';
import RS485Image from '../../../../../assets/images/devices/devicetype/rs485_MC1.png';
import { ListTitle } from '../../../../../ui/DataDisplay/List/ListHeader';
import ListItem from '../../../../../ui/DataDisplay/List/ListItem';
import List from '../../../../../ui/DataDisplay/List/ListWrapper';
import { HelpModal } from '../../../../../ui/HelpModal/HelpModal';
import IsolatedComponent from '../../../../../ui/IsolatedComponent/IsolatedComponent';
import { translate, TranslationFunction } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { RouteComponentProps, withRouter } from 'react-router';

import '../addDevice.scss';
import { IInscriptionNavigationRouterParamProps } from '../../../InscriptionNavigation';

interface IProps
  extends RouteComponentProps<IInscriptionNavigationRouterParamProps> {
  t: TranslationFunction;
}

const SelectWModbusDevice = ({
  t,
  history,
  match: {
    params: { customerId, pvInstallationId }
  }
}: IProps) => {
  const goBackToDeviceConnectionTypesList = () =>
    history.push(
      `/devices_inscription/${customerId}/devices/${pvInstallationId}/add`
    );

  return (
    <IsolatedComponent
      title={t('Devices:addDeviceTitle')}
      subtitle={t('Devices:addDeviceWMODBUS')}
      md={12}
      xl={12}
      onGoBack={goBackToDeviceConnectionTypesList}
    >
      <Col md={6} sm={12} className={'new-device-list'}>
        <List fluid>
          <ListTitle text={t('Devices:selectWModbusDeviceType')} />
          <ListItem
            className={'device-type'}
            options={{
              onClick: () => {
                history.push(
                  `/devices_inscription/${customerId}/devices/${pvInstallationId}/add/wmodbus/box`
                );
              }
            }}
          >
            <Col className={'device-type-name'}>
              <span>{t('DeviceType:modbus_box')}</span>
            </Col>

            <Col className={'device-type-helper'}>
              <HelpModal title={t('DeviceType:modbus_box')}>
                <div className="device-type-image-container">
                  <img
                    className={'device-type-image'}
                    src={ModbusBoxImage}
                    alt=""
                  />
                </div>
              </HelpModal>
            </Col>

            <Col className="arrow-icon">
              <i className={'icon-full-arrow-right'} />
            </Col>
          </ListItem>

          <ListItem
            className={'device-type'}
            options={{
              onClick: () => {
                history.push(
                  `/devices_inscription/${customerId}/devices/${pvInstallationId}/add/wmodbus/part`
                );
              }
            }}
          >
            <Col className={'device-type-name'}>
              <span>{t('DeviceType:modbus_part')}</span>
            </Col>

            <Col className={'device-type-helper'}>
              <HelpModal title={t('DeviceType:modbus_part')}>
                <Row style={{ alignItems: 'center' }}>
                  <Col style={{ flex: 4 }}>
                    <div className="device-type-image-container">
                      <img
                        className={'device-type-image'}
                        src={ModbusPartImage}
                        alt=""
                      />
                    </div>
                  </Col>
                  <Col style={{ flex: 1 }} className={'text-center p-0'}>
                    <FontAwesomeIcon
                      icon={'plus'}
                      size={'2x'}
                      className={'text-primary'}
                    />
                  </Col>
                  <Col style={{ flex: 2 }}>
                    <div className="device-type-image-container">
                      <img
                        className={'device-type-image'}
                        src={RS485Image}
                        alt=""
                      />
                    </div>
                  </Col>
                </Row>
              </HelpModal>
            </Col>

            <Col className="arrow-icon">
              <i className={'icon-full-arrow-right'} />
            </Col>
          </ListItem>
        </List>
      </Col>
    </IsolatedComponent>
  );
};

export default withRouter(translate()(SelectWModbusDevice));
