import * as React from 'react';
import { Button, Form, FormGroup, Col, Alert } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { FormInput } from '../../ui/FormInput/FormInput';
import LoginActionWrapper from '../../ui/Wrapper/LoginActionWrapper';
import { TranslationFunction } from 'i18next';
import { translate } from 'react-i18next';
import LinksContainer from '../../ui/LoginBox/LinksContainer';

import './forgotPassword.scss';
import { Link } from 'react-router-dom';

interface IProps {
  t: TranslationFunction;
  loading?: boolean;
  onSubmit: (email: string) => void;
  Store?: any;
  called: boolean;
  success?: boolean;
}

const ForgotPasswordForm = inject('Store')(
  observer(
    ({
      t,
      Store: {
        forgotPasswordForm: {
          emailField,
          emailError,
          forgotPasswordFormIsInvalid,
          updateEmail
        }
      },
      onSubmit,
      loading,
      called,
      success
    }: IProps) => {
      return (
        <Form
          className={'forgot-password'}
          onSubmit={event => {
            event.preventDefault();
            if (!loading) {
              onSubmit(emailField);
            }
          }}
        >
          <Col mx="2" xs="12">
            <p className={'forgot-password-introduction'}>
              {t('introductionMsg')}
            </p>
          </Col>
          <Col mx="2" xs="12">
            <FormGroup>
              <FormInput
                value={emailField}
                type="email"
                name={t('Common:email')}
                id="email-input"
                onChange={updateEmail}
                error={emailField && emailError}
              />
            </FormGroup>
          </Col>
          {!loading &&
            called &&
            !success && (
              <Col mx="2" xs="12">
                <Alert className="login-error" color="danger">
                  {t('error')}
                </Alert>
              </Col>
            )}

          {!loading &&
            called &&
            success && (
              <Col mx="2" xs="12">
                <Alert className="login-success" color="success">
                  {t('emailSendMsg')}
                </Alert>
              </Col>
            )}

          <LoginActionWrapper>
            <Button
              type="submit"
              color="primary"
              disabled={forgotPasswordFormIsInvalid}
            >
              {t('resetButton')}
            </Button>
          </LoginActionWrapper>

          <LinksContainer>
            <Link to="/login">{t('Common:goBackLink')}</Link>
          </LinksContainer>
        </Form>
      );
    }
  )
);

export default translate(['ForgotPassword', 'Common'])(ForgotPasswordForm);
