import React from 'react';
import { IDeviceIcon } from '../DeviceIcons';

const BBQHotStone = (props: IDeviceIcon) => (
  <svg
    xmlSpace="preserve"
    width="1rem"
    height="1rem"
    viewBox="0 0 20000 20000"
    id="prefix__svg4325"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    color="black"
    {...props}
  >
    <defs id="prefix__defs4327">
      <linearGradient id="prefix__linearGradient4356">
        <stop
          offset={0}
          id="prefix__stop4358"
          stopColor={props.color}
          stopOpacity={1}
        />
      </linearGradient>
      <linearGradient id="prefix__linearGradient4350">
        <stop
          offset={0}
          id="prefix__stop4352"
          stopColor={props.color}
          stopOpacity={1}
        />
      </linearGradient>
      <linearGradient id="prefix__linearGradient4344">
        <stop
          offset={0}
          id="prefix__stop4346"
          stopColor={props.color}
          stopOpacity={1}
        />
      </linearGradient>
      <style type="text/css" id="style4329">
        {`.prefix__fil0{fill:${props.color}}`}
      </style>
      <linearGradient
        xlinkHref="#prefix__linearGradient4356"
        id="prefix__linearGradient4360"
        x1={6950}
        y1={10686.5}
        x2={13138}
        y2={10686.5}
        gradientUnits="userSpaceOnUse"
      />
    </defs>
    <g id="prefix__layer" fillOpacity={1} fillRule="nonzero" fill={props.color}>
      <g
        id="prefix__bbq_hot_stone"
        fillOpacity={1}
        fillRule="nonzero"
        fill={props.color}
      >
        <path
          d="M8353 10460h3681l433-1776H7920z"
          id="prefix__polygon4334"
          fill={props.color}
          fillOpacity={1}
          fillRule="nonzero"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M11089 5286s-188-180-519-396l251-259 96 93 321-332-371-359-321 332 96 93-289 298c-280-163-631-329-1039-446-1085-312-2135 9-2135 9l-229 2686 974 1547 3164-3265z"
          id="prefix__path4336"
          fillOpacity={1}
          fillRule="nonzero"
          fill={props.color}
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M13127 11615l-174-278 185-110-293-492-196 116-112-178H7777v942h389v4791h-124v-457h-451v1391h451v-543h4510v543h451v-1391h-451v457h-124v-4791h697zm-1088 4791H8558v-2373h3481v2373zm0-2763H8558v-2028h3481v2028z"
          id="prefix__path4338"
          fillOpacity={1}
          fillRule="nonzero"
          fill={props.color}
        />
      </g>
    </g>
  </svg>
);

export default BBQHotStone;
