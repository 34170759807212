import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { translate, TranslationFunction } from 'react-i18next';

interface IConfirmProps {
  t: TranslationFunction;
  handleConfirm?: () => void;
  handleClose?: () => void;
  confirmLabel?: string;
  isOpened: boolean;
  title?: string;
  children?: any;
  className?: string;
  size?: 'sm' | 'md' | 'lg';
}

const CustomModal = ({
  t,
  handleConfirm,
  handleClose,
  isOpened,
  title,
  children,
  className,
  confirmLabel = t('Common:save'),
  size
}: IConfirmProps) => {
  const [isModalOpened, setIsModalOpened] = useState(isOpened);

  useEffect(
    () => {
      setIsModalOpened(isOpened);
    },
    [isOpened]
  );

  const isHeaderVisible =
    typeof title !== 'undefined' && title !== null && title !== '';
  const isBodyVisible = typeof children !== 'undefined' && children !== null;
  const isFooterVisible = typeof handleConfirm !== 'undefined';
  const isCancelButtonVisible = typeof handleClose !== 'undefined';

  return (
    <Modal
      isOpen={isModalOpened}
      toggle={handleClose}
      className={className}
      centered={true}
      size={size}
    >
      {isHeaderVisible && (
        <ModalHeader toggle={handleClose}>{title}</ModalHeader>
      )}
      {isBodyVisible && <ModalBody>{children}</ModalBody>}
      {isFooterVisible && (
        <ModalFooter>
          <Button color="primary" onClick={handleConfirm}>
            {confirmLabel}
          </Button>
          {isCancelButtonVisible && (
            <Button color="secondary" onClick={handleClose}>
              {t('Common:cancel')}
            </Button>
          )}
        </ModalFooter>
      )}
    </Modal>
  );
};

export default translate('Common')(CustomModal);
