import { ApolloError } from 'apollo-client';
import gql from 'graphql-tag';
import { TranslationFunction } from 'i18next';
import React from 'react';
import { Mutation, MutationResult } from 'react-apollo';
import { translate } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import history from '../../../../../history';
import { IModbusDevice } from '../../../../../stores/inscription/addDeviceStore';
import { safeMobxInject } from '../../../../../stores/storeInjectionHelpers';
import { SnackbarStore } from '../../../../../stores/userFeedback/snackbarStore';
import i18n from '../../../../../translations/i18n';
import LoadingButton from '../../../../../ui/Button/LoadingButton/LoadingButton';
import { DEVICE_INSCRIPTION_FIELD_QUERY } from '../../../DevicesInscription';
import { IInscriptionNavigationRouterParamProps } from '../../../InscriptionNavigation';
import { getDevicePath } from '../../../routingHelper';

export const ADD_WMODBUS_DEVICES_TO_MST = gql`
  mutation addWModbusDevicesToMst(
    $pvInstallationId: String!
    $modbusPartSerial: String!
    $devices: [ModbusDeviceInput]!
    $lang: String!
  ) {
    addWModbusDevicesToMst(
      pvInstallationId: $pvInstallationId
      modbusPartOrUpmSerial: $modbusPartSerial
      devices: $devices
      lang:$lang
    ) {
      ${DEVICE_INSCRIPTION_FIELD_QUERY}
    }
  }
`;

interface IProps
  extends RouteComponentProps<IInscriptionNavigationRouterParamProps> {
  t: TranslationFunction;
  disabled: boolean;
  modbusPartSerial: string;
  modbusDevices: IModbusDevice[];
}

interface IStoresProps {
  snackBar: SnackbarStore;
  initErrorsFromGQLError: (e: ApolloError) => void;
}

interface IMutationResponse {
  addModbusDeviceToMst: any;
}

const AddWModbusPartDeviceButton = safeMobxInject<IStoresProps, IProps>(
  allStores => ({
    snackBar: allStores.snackBar,
    initErrorsFromGQLError: allStores.inscriptionError.initErrorsFromApolloError
  }),
  ({
    match: {
      params: { customerId, pvInstallationId }
    },
    t,
    disabled,
    modbusPartSerial,
    modbusDevices,
    snackBar,
    initErrorsFromGQLError
  }) => (
    <Mutation
      mutation={ADD_WMODBUS_DEVICES_TO_MST}
      onCompleted={data => {
        const error = data.addWModbusDevicesToMst.error;

        if (error) {
          if (error.message === 'invalid_modbus_part_or_upm_serial') {
            return snackBar.showSnackBar({
              text: t('Errors:wModbusPartSerialError'),
              type: 'error'
            });
          }

          if (error.message === 'save_modbus_part_or_upm_serial_error') {
            return snackBar.showSnackBar({
              text: t('Errors:wModbusPartDeviceUnavailable'),
              type: 'error'
            });
          }

          if (error.message === 'no_modbus_device_to_add') {
            return snackBar.showSnackBar({
              text: t('Errors:wModbusPartDeviceMissingDevices'),
              type: 'error'
            });
          }

          return initErrorsFromGQLError(error);
        }

        history.push(getDevicePath({ customerId, pvInstallationId }));
      }}
      onError={e => initErrorsFromGQLError(e)}
    >
      {(
        addWModbusDevicesToMst,
        { loading }: MutationResult<IMutationResponse>
      ) => (
        <LoadingButton
          className="update"
          onClick={event => {
            event.preventDefault();
            return addWModbusDevicesToMst({
              variables: {
                pvInstallationId,
                modbusPartSerial,
                devices: modbusDevices,
                lang: i18n.language
              }
            });
          }}
          disabled={disabled}
          loading={loading}
        >
          {t('Common:save')}
        </LoadingButton>
      )}
    </Mutation>
  )
);

export default withRouter(translate()(AddWModbusPartDeviceButton));
