import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { translate, TranslationFunction } from 'react-i18next';
import { MySmartBattery } from '../../../stores/my_smart_battery/mySmartBattery';
import { CreationMySmartBattery } from '../../../stores/my_smart_battery/creationMySmartBattery';
import { safeMobxInject } from '../../../stores/storeInjectionHelpers';
import { withApollo, WithApolloClient } from 'react-apollo';
import { ListTitle } from '../../../ui/DataDisplay/List/ListHeader';
import { Button, Col } from 'reactstrap';
import Spinner from '../../../ui/Spinner/Spinner';
import CardDescriptionInscriptionMySmartBattery from '../../customers/installation/battery/activation/CardDescriptionInscriptionMySmartBattery';
import List from '../../../ui/DataDisplay/List/ListWrapper';
import { InscriptionStepsStore } from '../../../stores/inscription/inscriptionStepsStore';
import { getPartnerPath } from '../routingHelper';
import { PAGE_COME_FROM } from '../../customers/installation/battery/creation/MsbCreationWindow';
import MsbCreation from '../../customers/installation/battery/creation/MsbCreation';

interface IProps extends RouteComponentProps<IMatchParams> {
  t: TranslationFunction;
  isolated?: boolean;
}

interface IStoresProps {
  mySmartBatteryStore: MySmartBattery;
  inscriptionSteps: InscriptionStepsStore;
  creationMySmartBatteryStore: CreationMySmartBattery;
}

interface IMatchParams {
  customerId: string;
  pvInstallationId: string;
}

const DeviceInscriptionMySmartBatteryForm = safeMobxInject<
  IStoresProps,
  IProps
>(
  stores => ({
    mySmartBatteryStore: stores.mySmartBattery,
    inscriptionSteps: stores.inscriptionSteps,
    creationMySmartBatteryStore: stores.creationMySmartBattery
  }),
  ({
    t,
    history,
    client,
    mySmartBatteryStore,
    inscriptionSteps,
    creationMySmartBatteryStore,
    match: {
      params: { customerId, pvInstallationId }
    }
  }: WithApolloClient<IProps & IStoresProps>) => {

    useEffect(
      () => {
        creationMySmartBatteryStore.clearCreationMsbMylight();
        mySmartBatteryStore.loadMsb(client, customerId).then(msb => {
          if (msb) {
            creationMySmartBatteryStore.setCreationMsbMylight(msb);
            mySmartBatteryStore.setActivationMsb(msb);
            if (
              !inscriptionSteps.getBatteryDone &&
              (mySmartBatteryStore.isMSBActivated ||
                mySmartBatteryStore.isMSBEnedisGridInjectionRequested ||
                mySmartBatteryStore.isMSBStrasbourgElectricityAdministrativeProcedureRequested)
            ) {
              inscriptionSteps.updateMSBRegistrationStatus(
                client,
                pvInstallationId,
                'done'
              );
            }
          }
        });
      },
      [customerId]
    );

    const skipStep = async () => {
      inscriptionSteps.setBatteryStepStatus('done');
      await inscriptionSteps.updateMSBRegistrationStatus(
        client,
        pvInstallationId,
        'done'
      );

      history.push(
        getPartnerPath({
          pvInstallationId,
          customerId
        })
      );
    };

    const addMsb = () => {
      history.push(`/customers/${customerId}/battery`, {
        pvInstallationId,
        from: PAGE_COME_FROM.DEVICE_INSCRIPTION
      });
    };

    const onUpdate = async () => {
      await creationMySmartBatteryStore.updateMsbMylight(client, customerId);
      inscriptionSteps.setBatteryStepStatus('done');
      await inscriptionSteps.updateMSBRegistrationStatus(
        client,
        pvInstallationId,
        'done'
      );
      history.push(
        getPartnerPath({
          customerId,
          pvInstallationId
        })
      );
    };

    const onNext = async () => {
      history.push(
        getPartnerPath({
          customerId,
          pvInstallationId
        })
      );
    };

    return (
      <Spinner loading={mySmartBatteryStore.isLoadingLoadMsb}>
        {creationMySmartBatteryStore.hasMsb ? (
          <MsbCreation editMode={true} onUpdate={onUpdate} onNext={onNext} />
        ) : (
          <Col md={12} lg={6} className={'mobile-helper'}>
            <List fluid>
              <ListTitle text={t('battery')} />
              <div style={{ marginTop: '1rem' }}>
                <CardDescriptionInscriptionMySmartBattery />
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  paddingTop: '1rem'
                }}
              >
                <Button
                  color={'secondary'}
                  style={{
                    marginRight: '1.5rem',
                    paddingLeft: '1.5rem',
                    paddingRight: '1.5rem'
                  }}
                  onClick={() => skipStep()}
                >
                  {t('DevicesInscription:skipRegistrationStep')}
                </Button>
                <Button
                  color={'primary'}
                  style={{
                    paddingLeft: '1.5rem',
                    paddingRight: '1.5rem'
                  }}
                  onClick={() => addMsb()}
                >
                  {t('Common:add')}
                </Button>
              </div>
            </List>
          </Col>
        )}
      </Spinner>
    );
  }
);

export default withRouter(
  translate(['MySmartBattery', 'Common', 'DevicesInscription'])(
    withApollo(DeviceInscriptionMySmartBatteryForm)
  )
);
