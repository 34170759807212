import React from 'react';
import { IDeviceIcon } from '../DeviceIcons';

const Battery = (props: IDeviceIcon) => (
  <svg
    width="3rem"
    height="3rem"
    viewBox="0 0 20000 20000"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    color="black"
    {...props}
  >
    <a>
      <path
        d="M15271 8661v-800h-1580v780H6352.765v-780h-1580l-10.34 790.34c378.674.16 2281.91-.34-1.425-.34-790 0-1400 610-1400 1400v4480c0 770 630 1400 1400 1400h10250c390 0 750-150 1020-420 260-260 390-610 380-980v-4490c0-700-480-1260-1140-1380zm-1160-380h660v360h-660zM3851 10041c0-530 370-900 900-900h10260c530 0 900 370 900 900v4480c0 530-370 900-900 900H4751c-530 0-900-370-900-900zm1341.765-1760h660v360h-660z"
        fill={props.color}
        transform="translate(36.765 -1896)"
      />
    </a>
    <path
      d="M13505.122 9263.31v952.99h949.352v429.209h-949.352v952.99h-432.846v-952.99h-949.352v-429.21h949.352v-952.99h432.846z"
      fontWeight={700}
      fontSize={3724.661}
      fontFamily="sans-serif"
      letterSpacing={0}
      wordSpacing={0}
      fill={props.color}
    />
    <text
      style={{
        lineHeight: '125%'
      }}
      x={10441.176}
      y={14044.117}
      fontWeight={400}
      fontSize={1128.889}
      fontFamily="sans-serif"
      letterSpacing={0}
      wordSpacing={0}
      fill={props.color}
    />
    <path
      d="M8019.844 10216.3v429.209H5688.277v-429.21c2107.738-.596 671.372 0 2331.567 0z"
      fontWeight={700}
      fontSize={3724.661}
      fontFamily="sans-serif"
      letterSpacing={0}
      wordSpacing={0}
      fill={props.color}
    />
  </svg>
);

export default Battery;
