import { ApolloError } from 'apollo-client';
import gql from 'graphql-tag';
import { TranslationFunction } from 'i18next';
import React from 'react';
import { Mutation, MutationResult } from 'react-apollo';
import { translate } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import history from '../../../../../history';
import { safeMobxInject } from '../../../../../stores/storeInjectionHelpers';
import { SnackbarStore } from '../../../../../stores/userFeedback/snackbarStore';
import i18n from '../../../../../translations/i18n';
import LoadingButton from '../../../../../ui/Button/LoadingButton/LoadingButton';
import { getErrorsMessageGraphQl } from '../../../../../utils/graphQl';
import { DEVICE_INSCRIPTION_FIELD_QUERY } from '../../../DevicesInscription';
import { IInscriptionNavigationRouterParamProps } from '../../../InscriptionNavigation';
import { getDevicePath } from '../../../routingHelper';

const ADD_MODBUS_DEVICE_TO_MST_FROM_UPM = gql`
    mutation addWModbusDevicesToMst(
        $pvInstallationId: String!
        $upmSerial: String!
        $lang: String!
    ) {
      addWModbusDevicesToMst(
            pvInstallationId: $pvInstallationId
            modbusPartOrUpmSerial: $upmSerial
            lang:$lang
        ) {
            ${DEVICE_INSCRIPTION_FIELD_QUERY}
        }
    }
`;

interface IProps
  extends RouteComponentProps<IInscriptionNavigationRouterParamProps> {
  t: TranslationFunction;
  upmSerial: string;
  disabled: boolean;
}

interface IStoresProps {
  snackBar: SnackbarStore;
  initErrorsFromGQLError: (e: ApolloError) => void;
}

interface IMutationResponse {
  addModbusDeviceToMstFromUPM: any;
}

const AddWModbusBoxDeviceButton = safeMobxInject<IStoresProps, IProps>(
  ({ snackBar, inscriptionError }) => ({
    snackBar,
    initErrorsFromGQLError: inscriptionError.initErrorsFromApolloError
  }),
  ({
    match: {
      params: { customerId, pvInstallationId }
    },
    t,
    upmSerial,
    disabled,
    snackBar,
    initErrorsFromGQLError
  }: IStoresProps & IProps) => {
    return (
      <Mutation
        mutation={ADD_MODBUS_DEVICE_TO_MST_FROM_UPM}
        onCompleted={data => {
          const error = data.addWModbusDevicesToMst.error;

          if (error) {
            if (error.message === 'invalid_modbus_part_or_upm_serial') {
              return snackBar.showSnackBar({
                text: t('Errors:wModbusBoxDeviceNotFound'),
                type: 'error'
              });
            }

            if (error.message === 'save_modbus_part_or_upm_serial_error') {
              return snackBar.showSnackBar({
                text: t('Errors:wModbusBoxDeviceUnavailable'),
                type: 'error'
              });
            }

            return initErrorsFromGQLError(error);
          }

          history.push(getDevicePath({ customerId, pvInstallationId }));
        }}
        onError={e => initErrorsFromGQLError(e)}
      >
        {(
          addModbusDeviceToMstFromUPM,
          { loading }: MutationResult<IMutationResponse>
        ) => (
          <LoadingButton
            className="update"
            onClick={event => {
              event.preventDefault();
              return addModbusDeviceToMstFromUPM({
                variables: {
                  pvInstallationId,
                  upmSerial,
                  lang: i18n.language
                }
              });
            }}
            disabled={disabled}
            loading={loading}
          >
            {t('Common:add')}
          </LoadingButton>
        )}
      </Mutation>
    );
  }
);

export default withRouter(translate()(AddWModbusBoxDeviceButton));
