import React from 'react';
import { IDeviceIcon } from '../DeviceIcons';

const VideoProjector = (props: IDeviceIcon) => (
  <svg
    width="3rem"
    height="3rem"
    viewBox="0 0 20000 20000"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    color="black"
    {...props}
  >
    <path
      d="M14492 12570h-348c-237 0-427-142-506-348H6446c-79 205-285 348-506 348h-348c-253 0-458-174-522-395-411-142-711-522-711-980V8729c0-569 458-1027 1027-1027h9310c569 0 1027 458 1027 1027v2466c0 458-285 838-711 980-63 221-285 395-522 395zm-8219-759h7524c111 0 205 95 205 205 0 79 63 126 126 126h348c79 0 126-63 126-126v-16c0-95 79-190 174-205 300-47 506-300 506-601V8728c0-332-269-616-616-616H5356c-332 0-616 269-616 616v2466c0 300 221 553 506 601 95 16 174 111 174 205v16c0 79 63 126 126 126h348c79 0 126-63 126-126 47-111 142-205 253-205zm6844-269c-869 0-1581-711-1581-1581 0-869 711-1581 1581-1581 869 0 1581 711 1581 1581 0 869-711 1581-1581 1581zm0-2734c-648 0-1154 522-1154 1154s522 1154 1154 1154c648 0 1154-522 1154-1154 16-632-506-1154-1154-1154zm-6291 2703c-111 0-205-95-205-205V8619c0-111 95-205 205-205 111 0 205 95 205 205v2671c16 126-79 221-205 221zm-711 0c-111 0-205-95-205-205V8619c0-111 95-205 205-205 111 0 205 95 205 205v2671c0 126-95 221-205 221zm-648 0c-111 0-205-95-205-205V8619c0-111 95-205 205-205 111 0 205 95 205 205v2671c0 126-95 221-205 221zm7650-648c-490 0-885-395-885-885s395-885 885-885 885 395 885 885c16 474-395 885-885 885zm0-1375c-269 0-474 221-474 474 0 269 221 474 474 474 269 0 474-221 474-474s-205-474-474-474z"
      fill={props.color}
    />
  </svg>
);

export default VideoProjector;
