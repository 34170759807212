import React from 'react';
import { IDeviceIcon } from '../DeviceIcons';

const Computer = (props: IDeviceIcon) => (
  <svg
    width="3rem"
    height="3rem"
    viewBox="0 0 20000 20000"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    color="black"
    {...props}
  >
    <g id="prefix__layer">
      <g id="prefix___2098637302864">
        <path
          style={{ fill: `${props.color}` }}
          d="M13992 10903H6052c-117 0-216-117-216-216V6060c0-117 117-216 216-216h7923c117 0 216 117 216 216v4627c17 100-100 216-200 216zm-7657-433h7490V6275H6335v4195z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M11062 12451H9081c-117 0-216-117-216-216v-1598c0-117 117-216 216-216h1981c117 0 216 117 216 216v1598c-50 150-100 216-216 216zm-1814-383h1598v-1149H9248v1149zM10346 9688c0 183-150 333-333 333s-333-150-333-333 150-333 333-333c183 17 333 150 333 333z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M11778 12451H8316c-117 0-216-117-216-216 0-117 117-216 216-216h3462c117 0 216 117 216 216 17 150-100 216-216 216zM15190 12934c-50-50-117-50-117-50H5186c-50 0-117 0-117 50l-982 1149c-50 50-50 117 0 166 0 50 117 117 166 117h11718c50 0 117-50 166-117v-166l-949-1149zm-266 166l117 166h-383l-117-166h383zm-4245 0h383v166h-383v-166zm0 449h433v166h-433v-166zm-716-449h383v166h-383v-166zm-50 449h433v166h-433v-166zm-716-449h383v166h-383v-166zm-117 449h449v166h-433v-166h-17zm-649-449h383l-50 166h-399l67-166zm-166 449h433l-50 166h-433l50-166zm-616-449h383l-50 166h-383l50-166zm-166 449h433l-50 166h-433l50-166zm-549-449h383l-117 166h-383l117-166zm-766 0h383l-117 166h-383l117-166zm-766 0h383l-100 166h-383l100-166zm-333 999h-433l117-166h433l-117 166zm283-449h-383l117-166h383l-117 166zm599 449h-433l117-166h433l-117 166zm216-449h-433l117-166h383l-67 166zm666 449h-433l117-166h433l-117 166zm166-449h-433l117-166h383l-67 166zm216 449l50-166h3862v166H7215zm4245-999h383v166h-383v-166zm50 449h832l-50-433h383l166 599h-1265l-67-166zm50 549v-166h499v166h-499zm832 0v-166h433l50 166h-483zm599-999h383l50 166h-383l-50-166zm117 449h433l50 166h-433l-50-166zm166 549l-50-166h433l50 166h-433zm483-999h383l50 166h-383l-50-166zm166 449h433l50 166h-433l-50-166zm216 549l-50-166h433l50 166h-433zm616-549h383l117 166h-433l-67-166zm266 549l-117-166h449l117 166h-449z"
        />
      </g>
    </g>
  </svg>
);

export default Computer;
