import React from 'react';
import { read, stopaGa } from './quagga/q';
import './barCodeReader.scss';
import { Button, Col, Row } from 'reactstrap';

import { TranslationFunction } from 'i18next';
import { translate } from 'react-i18next';
import IsolatedComponent from '../../../ui/IsolatedComponent/IsolatedComponent';
import { inject, observer } from 'mobx-react';
import { HelpButton } from '../../../ui/HelpButton/HelpButton';
import { convertTranslation } from '../../../utils/convertTranslations';
import { IAllStores } from '../../../stores/allStores.model';
import { RouteComponentProps } from 'react-router';
import { Barcode, ScanCodeType } from '../../../stores/barcode';
import { UCG } from '../../../stores/inscription/inscriptionStore';
import { MsbBoxTypes } from '../../../graphql/graphql.schema';

interface IProps extends RouteComponentProps<IMatchParams> {
  barcode: Barcode;
  updateSingleSerialNumber: (serialNumber: string) => void;
  setMst: (master: string) => void;
  setMacOrAC: (macOrAC: string) => void;
  setUcg: (ucg: UCG) => void;
  onSelect?: (num: string) => void;
  onCodeSelected?: (ucg?: string) => void;
  type?: ScanCodeType;
  t: TranslationFunction;
  getSerialNumberTypeFromString: (string: string) => MsbBoxTypes | null;
  addScannedSerialNumber: (string: string) => void;
}

interface IMatchParams {
  type: ScanCodeType;
}

const ACValidator = '^([A-Fa-f0-9]{12})$';
const MACValidator = '^([A-Za-z0-9]{4}[-]){2}[A-Za-z0-9]{4}$';

@inject((allStores: IAllStores) => {
  return {
    barcode: allStores.barcode,
    setUcg: allStores.inscription.setUcgEdited,
    setMst: allStores.inscription.setMstCode,
    setMacOrAC: allStores.addDevice.setMacOrAC,
    getSerialNumberTypeFromString:
      allStores.battery.getSerialNumberTypeFromString,
    addScannedSerialNumber: allStores.battery.addScannedSerialNumber
  };
})
@observer
class BarCodeReader extends React.Component<IProps> {
  public componentWillUnmount() {
    stopaGa();
  }

  public componentDidMount() {
    const type = this.props.match.params.type;
    if (this.props.barcode.possibleBarcodeTypes!.includes(type)) {
      this.props.barcode.setType(type);
      this.startScanning();
    } else {
      history.back();
    }
  }

  public stopScanning = () => {
    this.props.barcode.setScanningProcess(false);
  };

  public startScanning = () => {
    const { startScanningProcess, type, setScannedCode } = this.props.barcode;

    startScanningProcess();
    if (this.props.barcode.scanningProcess) {
      read(type, (result: string) => {
        if (type === 'MSB') {
          if (this.props.getSerialNumberTypeFromString(result) !== null) {
            setScannedCode({ value: result, type: 'correct' });
            return;
          }
        } else if (type === 'MST') {
          if (!!result.match(ACValidator) || !!result.match(MACValidator)) {
            setScannedCode({ value: result, type: 'correct' });
            return;
          }
        } else if (type === 'PLC') {
          if (!!result.match(ACValidator) || !!result.match(MACValidator)) {
            setScannedCode({ value: result, type: 'correct' });
            return;
          }
        } else if (type === 'UCG') {
          setScannedCode({ value: result, type: 'correct' });
          return;
        }
        setScannedCode({ value: result, type: 'wrong' });
      });
    }
  };

  public render() {
    const {
      barcode,
      t,
      setMst,
      setUcg,
      setMacOrAC,
      addScannedSerialNumber
    } = this.props;
    const { scanningProcess, type, scannedCode } = barcode;
    return (
      <IsolatedComponent title={t(`scanTitle`)}>
        <Col className="barcode-container">
          <Row className="barcode-title">
            {scannedCode && scannedCode.type === 'correct' ? (
              t('correctCode')
            ) : (
              <div>
                {t('scanInstruction')}
                <HelpButton
                  uniqueKey="scanHelp"
                  message={convertTranslation(t(`${type}Help`))}
                />
              </div>
            )}
          </Row>
          {scannedCode &&
            scannedCode.type && (
              <Row className={'barcode-result'}>
                <Col className={'barcode-code'}>
                  {scannedCode.type === 'correct' ? (
                    <span className={'code-correct'}>{scannedCode.value}</span>
                  ) : (
                    <div className={'code-wrong'}>
                      <span>{t('wrongCode')}</span>
                      <span> {scannedCode.value}</span>
                    </div>
                  )}
                </Col>
                {scannedCode &&
                  scannedCode.type === 'correct' && (
                    <Col className={'barcode-save'}>
                      <Button
                        color={'primary'}
                        onClick={() => {
                          if (type === 'MST') {
                            setMst(scannedCode.value);
                          } else if (type === 'PLC') {
                            setMacOrAC(scannedCode.value);
                          } else if (type === 'UCG') {
                            setUcg(scannedCode.value);
                          } else if (type === 'MSB') {
                            addScannedSerialNumber(scannedCode.value);
                          }
                          history.back();
                        }}
                      >
                        Validate
                      </Button>
                    </Col>
                  )}
              </Row>
            )}
          {!scanningProcess && (
            <Row className={'barcode-rescan'}>
              <Button onClick={this.startScanning}>{t('rescan')}</Button>
            </Row>
          )}
          <Row id="cont" style={!scanningProcess ? { display: 'none' } : {}} />
          {scanningProcess && (
            <Row className={'barcode-stop'}>
              <Button onClick={this.stopScanning}>{t('Common:cancel')}</Button>
            </Row>
          )}
        </Col>
      </IsolatedComponent>
    );
  }
}

export default translate(['BarcodeReader', 'Common'])(BarCodeReader);
