/**
 * UPM serial number regex
 *
 * Format:
 *    |  code   | year | month | address |
 *    |    -    |  -   |   -   |    -    |
 *    | M433485 |  22  |  01   |  0001   |
 *
 * - code: fixed to `M433485`
 * - year: 2 digits
 * - month: 2 digits
 * - address: 4 digits (Hexadecimal)
 */
export const MODBUS_PART_SERIAL_NUMBER_REGEX = /^(?<code>M433485)(?<year>\d{2})(?<month>\d{2})(?<address>[A-F\d]{4})$/;

export interface IModbusPartSerialNumberRegexMatch {
  code: string;
  year: string;
  month: string;
  address: string;
}
