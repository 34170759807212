import * as React from 'react';
import { Col, Row } from 'reactstrap';
import { translate, TranslationFunction } from 'react-i18next';
import BadgeStatus from '../BadgeStatus/BadgeStatus';
import { HelpButton } from '../HelpButton/HelpButton';
import MobileSmoothList from '../SmoothList/MobileSmoothList';
import TenantLogo from '../../assets/images/tenant/TenantLogo';
import { get } from 'lodash';
import './customerList.scss';
import { RouteComponentProps, withRouter } from 'react-router';
import classnames from 'classnames';
import {
  MSB_FINISHED,
  MSB_PENDING,
  NO_MSB
} from '../../constants/BATTERY_STATUS';
import { CustomerListItem } from '../../graphql/graphql.schema';

interface IProps extends RouteComponentProps<any> {
  customers: CustomerListItem[];
  t: TranslationFunction;
  canFetchMore: boolean;
  fetchMore: () => void;
}

const MobileCustomerList = ({
  customers,
  t,
  history,
  canFetchMore,
  fetchMore
}: IProps) => {
  return (
    <div className="full-page-list">
      <MobileSmoothList
        rowHeight={107}
        placeHolderText={t('customerEmptyList')}
        fetchMore={fetchMore}
        canFetchMore={canFetchMore}
        onRowClick={item => {
          history.push(`/customers/${item.id}/identity`);
        }}
        columns={[
          {
            title: t('name'),
            columnKey: 'Name',

            bootstrapOption: { xs: 6 },
            render: item => (
              <Row>
                <Col xs={12} className="customer-lastName">
                  {item.lastName}
                </Col>
                <Col xs={12} className="customer-firstName">
                  {item.firstName}
                </Col>
              </Row>
            )
          },

          {
            title: t('address'),
            columnKey: 'Address',
            align: 'right',
            bootstrapOption: { xs: 6 },
            render: item => (
              <Row>
                <Col xs={12}>
                  {item.zipCode} {item.city} {item.country}
                </Col>
              </Row>
            )
          },
          {
            title: t('registrationStatus'),
            columnKey: 'registrationStatus',
            bootstrapOption: { xs: 6 },
            render: (item, index) => (
              <Col>
                <Row className="registration-status">
                  <span className="registration-status-title">
                    {t('MySmartBattery:battery')}:
                  </span>
                  <span
                    className={classnames(
                      'badge-status',
                      item.msbStatus === NO_MSB && 'secondary',
                      item.msbStatus === MSB_PENDING && 'warning',
                      item.msbStatus === MSB_FINISHED && 'success'
                    )}
                  >
                    <label>{t(`msbStatus_${item.msbStatus}`)}</label>
                  </span>
                </Row>
                <Row className="registration-status">
                  <span className="registration-status-title">
                    {t('registrationStatus')}:
                  </span>
                  <span>
                    <BadgeStatus status={item.status} />
                    <HelpButton
                      message={t(`RegistrationStatusToolTips:${item.status}`, {
                        link: `/customers/${item.id}/systems`
                      })}
                      uniqueKey={`customer-list-${index}`}
                    />
                  </span>
                </Row>
              </Col>
            )
          },
          {
            title: t('tenant'),
            columnKey: 'tenant',
            align: 'right',
            bootstrapOption: { xs: 6 },
            render: item => <TenantLogo tenant={get(item, 'tenant')} />
          }
        ]}
        dataSource={customers}
      />
    </div>
  );
};

export default translate(['Customer', 'RegistrationStatusToolTips'])(
  withRouter(MobileCustomerList)
);
