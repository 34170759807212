import * as React from 'react';
import moment from 'moment';
import { TranslationFunction } from 'i18next';
import { translate } from 'react-i18next';
import { Col } from 'reactstrap';
import { get } from 'lodash';

import { ICustomer } from '@mylight/data-model';

import Spinner from '../../ui/Spinner/Spinner';
import ListHeader, { ListTitle } from '../../ui/DataDisplay/List/ListHeader';
import ListItem from '../../ui/DataDisplay/List/ListItem';
import ListPlaceHolder from '../../ui/DataDisplay/List/ListPlaceHolder';
import TenantLogo from '../../assets/images/tenant/TenantLogo';

import history from '../../history';
import List from '../../ui/DataDisplay/List/ListWrapper';
import { inject, observer } from 'mobx-react';

interface IProps {
  t: TranslationFunction;
  customers: ICustomer[];
  error?: string;
  loading?: boolean;
}

function customersSorter(customers: ICustomer[]): ICustomer[] {
  return customers
    .slice()
    .sort((customerA: ICustomer, customerB: ICustomer) => {
      const customerADate = moment(new Date(customerA.registrationDate));
      const customerBDate = moment(new Date(customerB.registrationDate));

      if (customerADate.isValid() && customerBDate.isValid()) {
        if (customerADate.isBefore(customerBDate)) {
          return 1;
        }
        if (customerADate.isAfter(customerBDate)) {
          return -1;
        }
      }
      return 0;
    })
    .slice(0, 10);
}

const LastRegisteredCustomersListView = inject('Store')(
  observer(({ t, customers, loading, error }: IProps) => {
    return (
      <Spinner loading={loading} error={error}>
        <List fluid={true}>
          <ListTitle text={t('lastRegisteredCustomers')} />
          <ListHeader>
            <Col md="2" className="d-none d-sm-none d-md-block">
              {`${t('tenant')}`}
            </Col>

            <Col md="5" className="d-none d-sm-none d-md-block">
              {`${t('name')}`}
            </Col>
            <Col md="5" className="d-none d-sm-none d-md-block">
              {`${t('address')}`}
            </Col>
          </ListHeader>
          <div className="full-list">
            {customers.length === 0 ? (
              <ListPlaceHolder text={t('LastRegisteredCustomerEmptyList')} />
            ) : (
              customers &&
              customersSorter(customers).map((customer: ICustomer) => (
                <ListItem
                  key={customer.id}
                  className="last-customers -row"
                  options={{
                    onClick: () =>
                      history.push(`/customers/${customer.id}/identity`)
                  }}
                >
                  <Col xs={2}>
                    <TenantLogo tenant={get(customer, 'tenant')} />
                  </Col>
                  <Col className="font-weight-bold" xs={5}>{`${
                    customer.lastName ? customer.lastName.toUpperCase() : ''
                  } ${customer.firstName}`}</Col>
                  <Col xs={5}>
                    {`${customer.zipCode} ${customer.city} ${customer.country}`}
                  </Col>
                </ListItem>
              ))
            )}
          </div>
        </List>
      </Spinner>
    );
  })
);

export default translate('Customer')(LastRegisteredCustomersListView);
