import React from 'react';
import { Col, Row } from 'reactstrap';
import { TranslationFunction } from 'i18next';
import { translate } from 'react-i18next';
import './ucgPreconfigurationWizardFeatureInfo.scss';

interface IProps {
  t: TranslationFunction;
  additionalInfo?: boolean;
}

const UcgPreconfigurationWizardFeatureInfo = ({
  t,
  additionalInfo
}: IProps) => (
  <Col className={'ucg-preconfiguration-wizard'}>
    <Row className={'ucg-preconfiguration-wizard-title'}>{t('Common:new')}</Row>
    <Row className={'ucg-preconfiguration-wizard-info'}>
      {additionalInfo
        ? t('UCG:ucgPreconfigurationWizardAdditionalInfo')
        : t('UCG:ucgPreconfigurationWizardInfo')}
    </Row>
  </Col>
);

export default translate(['UCG'])(UcgPreconfigurationWizardFeatureInfo);
