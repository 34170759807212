import React, { useState } from 'react';
import { Mutation, MutationResult } from 'react-apollo';

import gql from 'graphql-tag';
import { RouteComponentProps, withRouter } from 'react-router';
import { PPDeviceConfigFile } from '../../../graphql/graphql.schema';
import UCGView from './UCGView';
import { translate, TranslationFunction } from 'react-i18next';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { getInstallationPath, getMasterPath } from '../routingHelper';
import { UCG } from '../../../stores/inscription/inscriptionStore';
import { ISnackBarParams } from '../../../stores/userFeedback/snackbarStore';
import { DEVICE_INSCRIPTION_FIELD_QUERY } from '../DevicesInscription';
import i18n from '../../../translations/i18n';
import { safeMobxInject } from '../../../stores/storeInjectionHelpers';

interface IProps extends RouteComponentProps<IMatchParams> {
  t: TranslationFunction;
}

interface IStoresProps {
  getInstallStepDone: boolean;
  showSnackBar: (params: ISnackBarParams) => void;
}

interface IMatchParams {
  pvInstallationId: string;
  customerId: string;
  current: string;
}

const SAVE = gql`
    mutation updateDeviceUCG(
        $pvInstallationId: String!
        $ucgSerialNumber: String
        $noUcg: Boolean
        $lang: String
    ) {
        updateDeviceUCG(
            pvInstallationId: $pvInstallationId
            ucgSerialNumber: $ucgSerialNumber
            noUcg: $noUcg
            lang: $lang
        ) {
            ${DEVICE_INSCRIPTION_FIELD_QUERY}
        }
    }
`;

interface IResult {
  updateDeviceUCG: PPDeviceConfigFile;
}

const UCGQuery = safeMobxInject<IStoresProps, IProps>(
  ({ snackBar, inscriptionSteps }) => {
    return {
      showSnackBar: snackBar.showSnackBar,
      getInstallStepDone: inscriptionSteps.getInstallStepDone
    };
  },
  ({
    t,
    history,
    match: {
      params: { customerId, pvInstallationId }
    },
    showSnackBar,
    getInstallStepDone
  }: IProps & IStoresProps) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    return (
      <div>
        <Mutation<IResult>
          mutation={SAVE}
          onCompleted={mutationResponse => {
            if (
              mutationResponse.updateDeviceUCG &&
              mutationResponse.updateDeviceUCG.ucg &&
              mutationResponse.updateDeviceUCG.ucg.ucgSerialNumber !== undefined
            ) {
              showSnackBar!({
                text: t('Success:updateUCG'),
                type: 'success'
              });
              if (getInstallStepDone) {
                return history.push(
                  getMasterPath({
                    customerId,
                    pvInstallationId
                  })
                );
              }
              return history.push(
                getInstallationPath({
                  customerId,
                  pvInstallationId
                })
              );
            }
            return setIsModalOpen(true);
          }}
        >
          {(saveUCG, { called, data, error, loading }: MutationResult) => {
            const alerts =
              data && data.updateDeviceUCG && data.updateDeviceUCG.alertsCode;
            return (
              <div>
                <UCGView
                  done={called && !error && !loading}
                  error={error}
                  saveLoading={loading}
                  save={(ucgValue: UCG) => {
                    saveUCG({
                      // FIXME move into store
                      variables: {
                        pvInstallationId,
                        ucgSerialNumber:
                          ucgValue !== null ? ucgValue : undefined,
                        noUcg: ucgValue === null,
                        lang: i18n.language
                      }
                    });
                  }}
                />
                <Modal
                  isOpen={alerts && alerts.length > 0 && isModalOpen}
                  toggle={() => setIsModalOpen(false)}
                >
                  <ModalHeader toggle={() => setIsModalOpen(false)}>
                    {t('InscriptionAlerts:modalTitle')}
                  </ModalHeader>
                  <ModalBody>
                    {alerts &&
                      alerts.length > 0 &&
                      alerts.map((a: string) => (
                        <div key={a}>{t(`InscriptionAlerts:${a}`)}</div>
                      ))}
                  </ModalBody>
                </Modal>
              </div>
            );
          }}
        </Mutation>
      </div>
    );
  }
);

export default translate(['InscriptionAlerts', 'Errors'])(withRouter(UCGQuery));
