import { Device } from '../graphql/graphql.schema';
import { filter, includes } from 'lodash';
import {
  DEVICES_VISIBLE_IN_CUSTOMER_SYSTEM_TAB,
  GMD,
  GMD_G3,
  MST,
  MST_G3,
  RS485,
  SW,
  SW_G3,
  TPH
} from '../stores/devices_inscription/contants';

export const isEnphaseDevice = (device: Device) => {
  return device.type!.toLowerCase() === 'enp';
};

export const isTadoDevice = (device: Device) => {
  const tadoTypes = ['tdht', 'tdac'];
  return tadoTypes.includes(device.type!.toLowerCase());
};

/**
 *  Order devices list by type : MST / GMD / CMP / TPH Then insert 'CMP' device after their parent
 * @param {any[]} devices
 * @returns {any[]}
 */
export function devicesOrder(devices: any[]): any[] {
  const filteredDevices = devices.filter(
    device =>
      device.type &&
      DEVICES_VISIBLE_IN_CUSTOMER_SYSTEM_TAB.includes(device.type.toLowerCase())
  );

  const devicesMST = filter(filteredDevices, device => {
    return device.type ? device.type === MST : false;
  });
  const devicesGMD = filter(filteredDevices, device => {
    return device.type ? device.type === GMD : false;
  });
  const devicesTPH = filter(filteredDevices, device => {
    return device.type ? device.type === TPH : false;
  });

  const otherDevices = filter(filteredDevices, device => {
    return !includes([MST, GMD, TPH], device.type ? device.type : '');
  }).sort((deviceA, deviceB) => {
    const deviceATypeCompare = deviceA.type ? deviceA.type.toUpperCase() : '';
    const deviceBTypeCompare = deviceB.type ? deviceB.type.toUpperCase() : '';

    if (deviceATypeCompare < deviceBTypeCompare) {
      return -1;
    }
    if (deviceATypeCompare > deviceBTypeCompare) {
      return 1;
    }
    return 0;
  });

  return [...devicesMST, ...devicesGMD, ...devicesTPH, ...otherDevices];
}

export function getDeviceType(device: Device): string {
  if (device.type === MST && device.emulatedFromG3) {
    return MST_G3;
  }
  if (device.macSerialNumber && device.macSerialNumber.includes('_RS')) {
    return RS485;
  }
  if (device.type === GMD && device.emulatedFromG3) {
    return GMD_G3;
  }
  if (device.type === SW && device.emulatedFromG3) {
    return SW_G3;
  }
  return device.type || 'unknown_device';
}
