import { action, observable } from 'mobx';

export type SnackbarType = 'success' | 'error';

export interface ISnackBarParams {
  text: string;
  duration?: number;
  type?: SnackbarType;
}

export class SnackbarStore {
  @observable public text: string = '';
  @observable public visible: boolean = false;
  @observable public type?: SnackbarType = undefined;

  @action.bound
  public showSnackBar({ text, duration, type }: ISnackBarParams) {
    this.text = text;
    this.visible = true;
    this.type = type;
    setTimeout(() => {
      this.hide();
    }, duration || 3000);
  }

  @action.bound
  public showErrorSnackBar({ text, duration }: ISnackBarParams) {
    this.showSnackBar({ text, duration, type: 'error' });
  }

  @action.bound
  public showSuccessSnackBar({ text, duration }: ISnackBarParams) {
    this.showSnackBar({ text, duration, type: 'success' });
  }

  @action.bound
  public hide() {
    this.visible = false;
  }
}

const snackbarStore: SnackbarStore = new SnackbarStore();
export default snackbarStore;
