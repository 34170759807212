import * as React from 'react';

interface IProps {
  className?: string;
}

export const IconPencil = ({ className }: IProps) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.0411 2.95972C12.6505 2.5692 12.0174 2.5692 11.6268 2.95972L11.0982 3.48832L12.5125 4.90253L13.0411 4.37394C13.4316 3.98341 13.4316 3.35025 13.0411 2.95972ZM11.5696 5.84534L10.1554 4.43113L2.66732 11.9192V13.3571H4.05784L11.5696 5.84534ZM10.684 2.01691C11.5953 1.10569 13.0726 1.10569 13.9839 2.01691C14.8951 2.92814 14.8951 4.40552 13.9839 5.31675L4.80539 14.4952C4.68036 14.6202 4.5108 14.6905 4.33398 14.6905H2.00065C1.63246 14.6905 1.33398 14.392 1.33398 14.0238V11.6431C1.33398 11.4663 1.40422 11.2967 1.52925 11.1717L10.684 2.01691Z"
      fill="#6B7280"
    />
  </svg>
);
