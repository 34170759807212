import { EnergyDistributorName } from '../components/customers/installation/battery/activation/constants';

export type Maybe<T> = T;
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  Any: any;
  Upload: any;
}

export interface Alert {
  deviceRoomName?: Maybe<Scalars['String']>;
  deviceTypeId?: Maybe<Scalars['String']>;
  deviceMac?: Maybe<Scalars['String']>;
  severity?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['String']>;
  deviceName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  acknowledged?: Maybe<Scalars['Boolean']>;
  visible?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  deviceRoomTypeId?: Maybe<Scalars['String']>;
  extendedDescription?: Maybe<Scalars['String']>;
}

export interface AuthToken {
  authToken?: Maybe<Scalars['String']>;
}

export interface AuthUser {
  email: Scalars['String'];
  password: Scalars['String'];
}

export interface BoxActivatioNCodeStatus {
  activationCode?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
}

export interface BoxStatus {
  serialNumber?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
}

export interface Company {
  id: Scalars['String'];
  companyName?: Maybe<Scalars['String']>;
  companyCode?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  addressSup?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  companyLocalRegistrationCode?: Maybe<Scalars['String']>;
  proUsers?: Maybe<Array<Maybe<ProUser>>>;
  pvInstallations?: Maybe<Array<Maybe<PVInstallation>>>;
  tenants?: Maybe<Array<Maybe<Tenant>>>;
}

export interface CProUser {
  srsIdUser?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  company?: Maybe<FormCompany>;
}

export interface CProUserCompany {
  proUser: CProUser;
  roleName: Scalars['String'];
  tenantName: Scalars['String'];
}

export interface CUcg {
  characterCode: Scalars['String'];
  model?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Int']>;
  boxType?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  gridType?: Maybe<Scalars['String']>;
  subcontractor?: Maybe<Scalars['String']>;
  materials?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  gmd?: Maybe<ISplitingDevice>;
  tph?: Maybe<ISplitingDevice>;
  mstg3?: Maybe<ISplitingDevice>;
  rs485?: Maybe<Array<Maybe<IRS485Device>>>;
  webdyn?: Maybe<Scalars['Boolean']>;
  electricalProtectionBrand?: Maybe<Scalars['String']>;
  pvProtectionIncluded?: Maybe<Scalars['Boolean']>;
  couplingDevice?: Maybe<Scalars['Boolean']>;
  envoyGatewayIncluded?: Maybe<Scalars['Boolean']>;
  contactor?: Maybe<Scalars['String']>;
  waterHeaterWiring?: Maybe<Scalars['String']>;
  additionalInfo?: Maybe<Scalars['String']>;
  knownDefault?: Maybe<Scalars['String']>;
  todo?: Maybe<Scalars['String']>;
  releasedAt?: Maybe<Scalars['Date']>;
}

export interface Customer {
  id: Scalars['String'];
  srsIdUser?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  addressSup?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  creationDate?: Maybe<Scalars['Date']>;
  birthDate?: Maybe<Scalars['Date']>;
  lastConnection?: Maybe<Scalars['Date']>;
  smsAlertsEnabled?: Maybe<Scalars['Boolean']>;
  emailAlertsEnabled?: Maybe<Scalars['Boolean']>;
  performanceReportsEmailEnabled?: Maybe<Scalars['Boolean']>;
  loginAsByMaintainerEnabled?: Maybe<Scalars['Boolean']>;
  pvInstallations?: Maybe<Array<Maybe<PVInstallation>>>;
  canWrite?: Maybe<Scalars['Boolean']>;
  msbStatus?: Maybe<Scalars['String']>;
  msb?: Maybe<MSB>;
}

export interface CustomerAndActivationCodes {
  customerId?: Maybe<Scalars['String']>;
  activationCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface CustomerIds {
  email: Scalars['String'];
  customerId: Scalars['String'];
  pvInstallationId: Scalars['String'];
  electricInstallation: Scalars['String'];
}

export interface CustomerInput {
  id: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['Date']>;
}

export interface CustomerListFilter {
  column?: Maybe<CustomerListFilterableColumn>;
  rule?: Maybe<CustomerListFilterRule>;
}

export enum CustomerListFilterableColumn {
  status = 'status',
  msbStatus = 'msbStatus'
}

export enum CustomerListFilterRule {
  no = 'no',
  pending = 'pending',
  ok = 'ok'
}

export interface CustomerListItem {
  id: Scalars['String'];
  pvInstallationId: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  tenant?: Maybe<Tenant>;
  status?: Maybe<Scalars['String']>;
  registrationDate?: Maybe<Scalars['Date']>;
  msbStatus?: Maybe<Scalars['String']>;
}

export interface CustomerListPagination {
  offset?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sorting?: Maybe<CustomerListSorting>;
  filters?: Maybe<Array<Maybe<CustomerListFilter>>>;
}

export enum CustomerListSortableColumn {
  tenantName = 'tenantName',
  lastName = 'lastName',
  status = 'status',
  msbStatus = 'msbStatus',
  registrationDate = 'registrationDate'
}

export interface CustomerListSorting {
  column?: Maybe<CustomerListSortableColumn>;
  rule?: Maybe<CustomerListSortingRule>;
}

export enum CustomerListSortingRule {
  ASC = 'ASC',
  DESC = 'DESC'
}

export interface Device {
  macSerialNumber?: Maybe<Scalars['String']>;
  activationCode?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  roomName?: Maybe<Scalars['String']>;
  deviceTypeId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  masterMac?: Maybe<Scalars['String']>;
  modbusAddress?: Maybe<Scalars['String']>;
  modbusReference?: Maybe<Scalars['String']>;
  power?: Maybe<Scalars['Float']>;
  phase?: Maybe<Scalars['Int']>;
  pow1?: Maybe<Scalars['Float']>;
  pow2?: Maybe<Scalars['Float']>;
  pow3?: Maybe<Scalars['Float']>;
  emulatedFromG3?: Maybe<Scalars['Boolean']>;
  greenPlayEnabled?: Maybe<Scalars['Boolean']>;
  alerts?: Maybe<Array<Maybe<Alert>>>;
}

export interface DevicealertsArgs {
  customerId: Scalars['String'];
  visible?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
}

export interface DeviceFunction {
  ct1?: Maybe<Scalars['String']>;
  ct2?: Maybe<Scalars['String']>;
  ct3?: Maybe<Scalars['String']>;
}

export interface DeviceRoom {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  devices?: Maybe<Array<Maybe<PPDevice>>>;
}

export interface Diagnostic {
  id?: Maybe<Scalars['String']>;
  testId?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['String']>;
  mac?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  status?: Maybe<Scalars['String']>;
  codeError?: Maybe<Array<Maybe<Scalars['String']>>>;
  diagnosticSet?: Maybe<DiagnosticSet>;
}

export interface DiagnosticSet {
  id?: Maybe<Scalars['String']>;
  pvInstallation?: Maybe<PVInstallation>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  diagnostics?: Maybe<Array<Maybe<Diagnostic>>>;
}

export interface DMSBBox {
  customerId?: Maybe<Scalars['String']>;
  boxesSerialNumber?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export enum DocumentType {
  ARPE = 'ARPE',
  CAE = 'CAE',
  CONSUEL = 'CONSUEL'
}

export interface EBox {
  customerId?: Maybe<Scalars['String']>;
  boxesSN?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface ECompany {
  id: Scalars['String'];
  companyName?: Maybe<Scalars['String']>;
  companyCode?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  addressSup?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  tenantName: Scalars['String'];
  companyLocalRegistrationCode?: Maybe<Scalars['String']>;
}

export interface ECustomer {
  id: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  addressSup?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  birthDate?: Maybe<Scalars['Date']>;
  cascade?: Maybe<Scalars['Boolean']>;
  smsAlertsEnabled?: Maybe<Scalars['Boolean']>;
  emailAlertsEnabled?: Maybe<Scalars['Boolean']>;
  loginAsByMaintainerEnabled?: Maybe<Scalars['Boolean']>;
}

export interface ECustomerForSrs {
  id: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  addressSup?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  birthDate?: Maybe<Scalars['Date']>;
  smsAlertsEnabled?: Maybe<Scalars['Boolean']>;
  emailAlertsEnabled?: Maybe<Scalars['Boolean']>;
  maintainerEmail?: Maybe<Scalars['String']>;
  loginAsByMaintainerEnabled?: Maybe<Scalars['Boolean']>;
}

export interface EDiagnostic {
  id: Scalars['String'];
  idCustomer: Scalars['String'];
  testGroupName: Scalars['String'];
  results: Array<Maybe<EResults>>;
  status: Scalars['String'];
}

export interface EditStatus {
  type?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  properties?: Maybe<StatusProperties>;
}

export interface EElectricInstallation {
  id: Scalars['String'];
  gridType?: Maybe<Scalars['String']>;
  rackingPower?: Maybe<Scalars['Float']>;
  powerContractType?: Maybe<Scalars['String']>;
  powerContractOption?: Maybe<Scalars['String']>;
  timeSlots?: Maybe<Scalars['String']>;
  powerProvider?: Maybe<Scalars['String']>;
  sellingPower?: Maybe<Scalars['Boolean']>;
  sellingEnergyPrice?: Maybe<Scalars['Float']>;
  internetServiceProvider?: Maybe<Scalars['String']>;
  internetBoxModel?: Maybe<Scalars['String']>;
  masterTypeInternetConnection?: Maybe<Scalars['String']>;
  simSerialNumber?: Maybe<Scalars['String']>;
  simRouterReference?: Maybe<Scalars['String']>;
  pdlNumber?: Maybe<Scalars['String']>;
  electricTariffScheduleEnabled?: Maybe<Scalars['Boolean']>;
  cascade?: Maybe<Scalars['Boolean']>;
}

export interface ElectricInstallation {
  id: Scalars['String'];
  gridType?: Maybe<Scalars['String']>;
  rackingPower?: Maybe<Scalars['Float']>;
  powerContractType?: Maybe<Scalars['String']>;
  powerContractOption?: Maybe<Scalars['String']>;
  timeSlots?: Maybe<Scalars['String']>;
  powerProvider?: Maybe<Scalars['String']>;
  sellingPower?: Maybe<Scalars['Boolean']>;
  sellingEnergyPrice?: Maybe<Scalars['Float']>;
  internetServiceProvider?: Maybe<Scalars['String']>;
  internetBoxModel?: Maybe<Scalars['String']>;
  masterTypeInternetConnection?: Maybe<Scalars['String']>;
  simSerialNumber?: Maybe<Scalars['String']>;
  simRouterReference?: Maybe<Scalars['String']>;
  pdlNumber?: Maybe<Scalars['String']>;
  canWrite?: Maybe<Scalars['Boolean']>;
  energyDistributor?: Maybe<EnergyDistributor>;
}

export interface EMSB {
  customerId?: Maybe<Scalars['String']>;
  pdlNumber?: Maybe<Scalars['String']>;
  caeNumber?: Maybe<Scalars['String']>;
  enedisConnectNumber?: Maybe<Scalars['String']>;
  consuelNumber?: Maybe<Scalars['String']>;
  consuelDeclaredPower?: Maybe<Scalars['Any']>;
  connectionPower?: Maybe<Scalars['Any']>;
  serialNumbers?: Maybe<Array<Maybe<Scalars['String']>>>;
  provider?: Maybe<Scalars['String']>;
}

export interface EnergyInfo {
  deviceName?: Maybe<Scalars['String']>;
  energy?: Maybe<Scalars['Float']>;
}

export interface EPPDevice {
  id?: Maybe<Scalars['String']>;
  deviceType?: Maybe<Scalars['String']>;
  plcMac?: Maybe<Scalars['String']>;
  ethMac?: Maybe<Scalars['String']>;
  activationCode?: Maybe<Scalars['String']>;
  masterMac?: Maybe<Scalars['String']>;
  deviceTypeOverride?: Maybe<Scalars['String']>;
  rx?: Maybe<Scalars['Int']>;
  tx?: Maybe<Scalars['Int']>;
  simNb?: Maybe<Scalars['String']>;
  rssi?: Maybe<Scalars['Int']>;
  rscp?: Maybe<Scalars['Int']>;
  preConfig: EPPDevicePreConfig;
  emulatedFromG3?: Maybe<Scalars['Boolean']>;
}

export interface EPPDevicePreConfig {
  controlledDeviceType?: Maybe<Scalars['String']>;
  controlledDeviceName?: Maybe<Scalars['String']>;
  roomType?: Maybe<Scalars['String']>;
  roomName?: Maybe<Scalars['String']>;
  phase?: Maybe<Scalars['Int']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  compositeId?: Maybe<Scalars['String']>;
  greenPlayEnabled?: Maybe<Scalars['Boolean']>;
}

export interface EPPTadoDevice {
  id: Scalars['String'];
  controlledDeviceType: Scalars['String'];
  deviceName: Scalars['String'];
  srsType?: Maybe<Scalars['String']>;
  tadoType?: Maybe<Scalars['String']>;
  roomName: Scalars['String'];
  roomType: Scalars['String'];
  isInSrs?: Maybe<Scalars['Boolean']>;
}

export interface EProUser {
  id: Scalars['String'];
  srsIdUser?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  enphaseInstallerId?: Maybe<Scalars['Int']>;
  company?: Maybe<ECompany>;
}

export interface EProUserCompany {
  roleName: Scalars['String'];
  proUser: EProUser;
}

export interface EPVInstallation {
  id: Scalars['String'];
  currency?: Maybe<Scalars['String']>;
  converterType?: Maybe<Scalars['String']>;
  panelCount?: Maybe<Scalars['Float']>;
  panelWattPeak?: Maybe<Scalars['Float']>;
  panelManufacturer?: Maybe<Scalars['String']>;
  srsLicenceStatus?: Maybe<Scalars['String']>;
  srsLicenceNumber?: Maybe<Scalars['String']>;
  srsRegistrationStatus?: Maybe<Scalars['String']>;
  srsRegistrationDate?: Maybe<Scalars['Date']>;
  tenant?: Maybe<ETenant>;
  ucgSerialNumber?: Maybe<Scalars['String']>;
  cascade?: Maybe<Scalars['Boolean']>;
}

export interface EPVInstallationForSRS {
  id: Scalars['String'];
  currency?: Maybe<Scalars['String']>;
  converterType?: Maybe<Scalars['String']>;
  panelCount?: Maybe<Scalars['Float']>;
  panelWattPeak?: Maybe<Scalars['Float']>;
  panelManufacturer?: Maybe<Scalars['String']>;
  totalWatt?: Maybe<Scalars['Float']>;
  hasMultipleProductionCounter?: Maybe<Scalars['Boolean']>;
  srsLicenceStatus?: Maybe<Scalars['String']>;
  srsLicenceNumber?: Maybe<Scalars['String']>;
  srsRegistrationStatus?: Maybe<Scalars['String']>;
  srsRegistrationDate?: Maybe<Scalars['Date']>;
  tenant?: Maybe<ETenant>;
  ucgSerialNumber?: Maybe<Scalars['String']>;
  kitSerialNumber?: Maybe<Scalars['String']>;
}

export interface ERegistrationSteps {
  msb?: Maybe<Scalars['String']>;
  partnership?: Maybe<Scalars['String']>;
}

export interface EResults {
  testId: Scalars['String'];
  mac: Scalars['String'];
  result: Scalars['String'];
}

export interface ETenant {
  name?: Maybe<Scalars['String']>;
}

export interface EUcg {
  id: Scalars['String'];
  characterCode?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Int']>;
  boxType?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  gridType?: Maybe<Scalars['String']>;
  subcontractor?: Maybe<Scalars['String']>;
  materials?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  gmd?: Maybe<ISplitingDevice>;
  tph?: Maybe<ISplitingDevice>;
  mstg3?: Maybe<ISplitingDevice>;
  rs485?: Maybe<Array<Maybe<IRS485Device>>>;
  webdyn?: Maybe<Scalars['Boolean']>;
  electricalProtectionBrand?: Maybe<Scalars['String']>;
  pvProtectionIncluded?: Maybe<Scalars['Boolean']>;
  couplingDevice?: Maybe<Scalars['Boolean']>;
  envoyGatewayIncluded?: Maybe<Scalars['Boolean']>;
  contactor?: Maybe<Scalars['String']>;
  waterHeaterWiring?: Maybe<Scalars['String']>;
  additionalInfo?: Maybe<Scalars['String']>;
  knownDefault?: Maybe<Scalars['String']>;
  todo?: Maybe<Scalars['String']>;
  releasedAt?: Maybe<Scalars['Date']>;
}

export interface FCustomer {
  email: Scalars['String'];
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  address: Scalars['String'];
  addressSup?: Maybe<Scalars['String']>;
  zipCode: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  birthDate: Scalars['Date'];
  phoneNumber?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
}

export interface Feature {
  licenceStatus?: Maybe<Scalars['Boolean']>;
  SMSAlerts?: Maybe<Scalars['Boolean']>;
  EmailAlerts?: Maybe<Scalars['Boolean']>;
  PerformanceRecords?: Maybe<Scalars['Boolean']>;
}

export interface FormCompany {
  companyName?: Maybe<Scalars['String']>;
  companyCode?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  addressSup?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  companyLocalRegistrationCode?: Maybe<Scalars['String']>;
}

export interface FormCustomerForSrs {
  idProUser: Scalars['String'];
  idMaintainer?: Maybe<Scalars['String']>;
  srsIdCustomer: Scalars['String'];
  customer: FCustomer;
  gridType?: Maybe<Scalars['String']>;
  tenantName: Scalars['String'];
}

export interface FTenant {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
}

export interface HasMSBBoxes {
  customerId?: Maybe<Scalars['String']>;
  boxes?: Maybe<Scalars['Int']>;
}

export interface IDeviceFunction {
  ct1?: Maybe<Scalars['String']>;
  ct2?: Maybe<Scalars['String']>;
  ct3?: Maybe<Scalars['String']>;
}

export interface InstallerFormCustomer {
  maintainerId: Scalars['String'];
  maintainerCode: Scalars['String'];
  customer: FCustomer;
  gridType?: Maybe<Scalars['String']>;
  tenant: FTenant;
}

export interface IRS485Data {
  modbusAddr?: Maybe<Scalars['Int']>;
  bauderate?: Maybe<Scalars['String']>;
  function?: Maybe<IDeviceFunction>;
}

export interface IRS485Device {
  type?: Maybe<Scalars['String']>;
  data?: Maybe<Array<Maybe<IRS485Data>>>;
}

export interface ISplitingDevice {
  counter?: Maybe<Scalars['Int']>;
  functions?: Maybe<Array<Maybe<IDeviceFunction>>>;
}

export interface JWT {
  jwt?: Maybe<Scalars['String']>;
}

export interface LoginAsResponse {
  uri: Scalars['String'];
}

export interface Maintainer {
  companyName?: Maybe<Scalars['String']>;
  companyCode: Scalars['String'];
  maintainer: ProUser;
}

export interface MaintainerFormCustomer {
  installerId?: Maybe<Scalars['String']>;
  customer: FCustomer;
  gridType?: Maybe<Scalars['String']>;
  tenant: FTenant;
  cascade?: Maybe<Scalars['Boolean']>;
}

export interface ModbusDevice {
  id: Scalars['String'];
  plcMac: Scalars['String'];
}

export interface ModbusDeviceInput {
  reference: Scalars['String'];
  address: Scalars['String'];
}

export interface ModBusReference {
  reference: Scalars['String'];
  defaultAddress?: Maybe<Scalars['Int']>;
  availableAddress: Array<Maybe<Scalars['String']>>;
  phaseCount: Scalars['Int'];
}

export interface ModbusResponse {
  references?: Maybe<Array<Maybe<ModBusReference>>>;
  error?: Maybe<Scalars['String']>;
}

export interface EnergyDistributor {
  id: Scalars['String'];
  name: EnergyDistributorName;
  pdlPattern: Scalars['String'];
}

export interface MSB {
  id?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  pdlNumber?: Maybe<Scalars['String']>;
  pdlNumberModifiedAt?: Maybe<Scalars['Date']>;
  energyDistributor?: Maybe<EnergyDistributor>;
  caeNumber?: Maybe<Scalars['String']>;
  caeNumberModifiedAt?: Maybe<Scalars['Date']>;
  enedisConnectNumber?: Maybe<Scalars['String']>;
  enedisConnectNumberModifiedAt?: Maybe<Scalars['Date']>;
  consuelNumber?: Maybe<Scalars['String']>;
  consuelNumberModifiedAt?: Maybe<Scalars['Date']>;
  consuelDeclaredPower?: Maybe<Scalars['Any']>;
  consuelDeclaredPowerModifiedAt?: Maybe<Scalars['Date']>;
  connectionPower?: Maybe<Scalars['Any']>;
  connectionPowerModifiedAt?: Maybe<Scalars['Date']>;
  strasbourgInjectionPdlNumber?: Maybe<Scalars['String']>;
  strasbourgInjectionPdlNumberModifiedAt?: Maybe<Scalars['Date']>;
  serialNumberModifiedAt?: Maybe<Scalars['Date']>;
  boxesSerialNumber?: Maybe<Array<Maybe<Scalars['String']>>>;
  volume?: Maybe<Scalars['Int']>;
  status?: Maybe<Array<Maybe<MSBStatus>>>;
  sendingCAEDate?: Maybe<Scalars['Date']>;
  sendingARPEDate?: Maybe<Scalars['Date']>;
  sendingCONSUELDate?: Maybe<Scalars['Date']>;
  type?: Maybe<MsbBoxTypes>;
}

export interface MsbBoxType {
  id: Scalars['String'];
  name?: Maybe<MsbBoxTypes>;
}

export enum MsbBoxTypes {
  MYLIGHT = 'MYLIGHT',
  MYLIGHT_MODEM = 'MYLIGHT_MODEM',
  PLANETE_OUI = 'PLANETE_OUI'
}

export interface MSBListFilter {
  column?: Maybe<MSBListFilterableColumn>;
  rule?: Maybe<MSBListFilterRule>;
}

export enum MSBListFilterableColumn {
  msbCaeNumber = 'msbCaeNumber',
  msbEnedisConnectNumber = 'msbEnedisConnectNumber',
  msbConsuelNumber = 'msbConsuelNumber',
  msbSendingARPEDate = 'msbSendingARPEDate',
  msbSNFilled = 'msbSNFilled',
  msbACFilled = 'msbACFilled',
  msbEnedisOk = 'msbEnedisOk',
  msbProviderRegistrationOk = 'msbProviderRegistrationOk',
  msbOk = 'msbOk',
  msbType = 'msbType'
}

export enum MSBListFilterRule {
  isNull = 'isNull',
  isNotNull = 'isNotNull'
}

export interface MSBListItem {
  customerId: Scalars['String'];
  customerFirstName?: Maybe<Scalars['String']>;
  customerLastName?: Maybe<Scalars['String']>;
  customerEmail?: Maybe<Scalars['String']>;
  customerPdlNumber?: Maybe<Scalars['String']>;
  msbVolume?: Maybe<Scalars['Int']>;
  msbCaeNumber?: Maybe<Scalars['String']>;
  msbEnedisConnectNumber?: Maybe<Scalars['String']>;
  msbConsuelNumber?: Maybe<Scalars['String']>;
  msbSendingARPEDate?: Maybe<Scalars['Date']>;
  msbSNFilled?: Maybe<Scalars['Date']>;
  msbACFilled?: Maybe<Scalars['Date']>;
  msbEnedisOk?: Maybe<Scalars['Date']>;
  msbProviderRegistrationOk?: Maybe<Scalars['Date']>;
  msbOk?: Maybe<Scalars['Date']>;
  msbType?: Maybe<MsbBoxTypes>;
  msbPotentiallyStuck?: Maybe<Scalars['Boolean']>;
}

export interface MSBListPagination {
  offset?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sorting?: Maybe<MSBListSorting>;
  filters?: Maybe<Array<Maybe<MSBListFilter>>>;
}

export enum MSBListSortableColumn {
  customerLastName = 'customerLastName',
  customerEmail = 'customerEmail',
  customerPdlNumber = 'customerPdlNumber',
  msbVolume = 'msbVolume',
  msbCaeNumber = 'msbCaeNumber',
  msbEnedisConnectNumber = 'msbEnedisConnectNumber',
  msbConsuelNumber = 'msbConsuelNumber',
  msbSendingARPEDate = 'msbSendingARPEDate',
  msbSNFilled = 'msbSNFilled',
  msbACFilled = 'msbACFilled',
  msbEnedisOk = 'msbEnedisOk',
  msbProviderRegistrationOk = 'msbProviderRegistrationOk',
  msbOk = 'msbOk',
  msbType = 'msbType'
}

export interface MSBListSorting {
  column?: Maybe<MSBListSortableColumn>;
  rule?: Maybe<MSBListSortingRule>;
}

export enum MSBListSortingRule {
  ASC = 'ASC',
  DESC = 'DESC'
}

export interface MSBStatus {
  status?: Maybe<Scalars['String']>;
  validateAt?: Maybe<Scalars['Date']>;
  isValidate?: Maybe<Scalars['Boolean']>;
}

export interface MST {
  generation?: Maybe<Scalars['String']>;
  activationCode?: Maybe<Scalars['String']>;
  ethMac?: Maybe<Scalars['String']>;
}

export interface Mutation {
  login?: Maybe<AuthToken>;
  loginAsSrs?: Maybe<LoginAsResponse>;
  updateCompany?: Maybe<Company>;
  uploadCompanyLogo?: Maybe<Scalars['String']>;
  createCustomerForSrs?: Maybe<Customer>;
  createCustomerFromMaintainer?: Maybe<Customer>;
  createCustomerFromInstaller?: Maybe<Customer>;
  updateCustomer?: Maybe<Customer>;
  updateCustomerForSRS?: Maybe<Customer>;
  assignInstaller?: Maybe<Customer>;
  assignInstallerByEmail?: Maybe<Customer>;
  deleteCustomer?: Maybe<Scalars['String']>;
  saveTadoDevices?: Maybe<Scalars['Boolean']>;
  setDefaultThermostat?: Maybe<Scalars['String']>;
  setTadoHome?: Maybe<Scalars['String']>;
  enphaseCreateAccount?: Maybe<Scalars['String']>;
  enphaseRegisterEnp?: Maybe<Scalars['String']>;
  tadoDisconnect?: Maybe<Scalars['String']>;
  removeDevice?: Maybe<Scalars['String']>;
  createRoom?: Maybe<Scalars['String']>;
  updateRoom?: Maybe<Scalars['String']>;
  saveDiagnostic?: Maybe<Scalars['String']>;
  updateElectricInstallation?: Maybe<ElectricInstallation>;
  uploadCONSUELDocument?: Maybe<Scalars['Date']>;
  uploadARPEDocument?: Maybe<Scalars['Date']>;
  addBoxToMSB?: Maybe<Array<Maybe<BoxStatus>>>;
  deleteBoxFromMSB?: Maybe<Array<Maybe<BoxStatus>>>;
  createMSB?: Maybe<MSB>;
  updateMSBStatus?: Maybe<Scalars['String']>;
  activationCodesValidation?: Maybe<Array<Maybe<BoxActivatioNCodeStatus>>>;
  serialNumbersValidation?: Maybe<Array<Maybe<ValidatedBox>>>;
  updateMSBCaeNumber?: Maybe<Scalars['String']>;
  updateMSB?: Maybe<Array<Maybe<EditStatus>>>;
  updateMetada?: Maybe<Scalars['Any']>;
  createProUser?: Maybe<ProUser>;
  updateProUser?: Maybe<ProUser>;
  deleteProUser?: Maybe<Scalars['String']>;
  uploadDocument?: Maybe<Scalars['String']>;
  updatePVInstallation?: Maybe<PVInstallation>;
  updatePVInstallationForSRS?: Maybe<PVInstallation>;
  addDeviceForPLCPairing?: Maybe<Scalars['String']>;
  updateDeviceUCG?: Maybe<PPDeviceConfigFile>;
  updateDeviceMST?: Maybe<PPDeviceConfigFile>;
  updateDeviceConfig?: Maybe<PPDeviceConfigFile>;
  updateIsDevicesConfigurationModeSelected?: Maybe<Scalars['Boolean']>;
  updateRegistrationStep: RegistrationSteps;
  completeRegistrationOfDevices?: Maybe<PPDeviceConfigFile>;
  addModbusDeviceToMst: PPDeviceConfigFile;
  removeModbusDeviceFrom: PPDeviceConfigFile;
  sendInscriptionSummaryByEmail?: Maybe<SendEmailSuccess>;
  addWModbusDevicesToMst: PPDeviceConfigFile;
  passwordForgot?: Maybe<Success>;
  resendActivationEmail?: Maybe<Success>;
  macToDak?: Maybe<Scalars['String']>;
  continueSubscription?: Maybe<RedirectResult>;
  createUcg?: Maybe<Ucg>;
  updateUcg?: Maybe<Ucg>;
  deleteUcgBySerialNumber?: Maybe<Scalars['String']>;
}

export interface MutationloginArgs {
  input: AuthUser;
}

export interface MutationloginAsSrsArgs {
  id: Scalars['String'];
}

export interface MutationupdateCompanyArgs {
  companyInput: ECompany;
}

export interface MutationuploadCompanyLogoArgs {
  file: Scalars['Upload'];
  proUserId: Scalars['String'];
}

export interface MutationcreateCustomerForSrsArgs {
  input: FormCustomerForSrs;
}

export interface MutationcreateCustomerFromMaintainerArgs {
  input: MaintainerFormCustomer;
}

export interface MutationcreateCustomerFromInstallerArgs {
  input: InstallerFormCustomer;
}

export interface MutationupdateCustomerArgs {
  customer: ECustomer;
}

export interface MutationupdateCustomerForSRSArgs {
  customer: ECustomerForSrs;
}

export interface MutationassignInstallerArgs {
  idCustomer: Scalars['String'];
  idInstaller?: Maybe<Scalars['String']>;
  cascade?: Maybe<Scalars['Boolean']>;
}

export interface MutationassignInstallerByEmailArgs {
  emailCustomer: Scalars['String'];
  emailInstaller?: Maybe<Scalars['String']>;
  cascade?: Maybe<Scalars['Boolean']>;
}

export interface MutationdeleteCustomerArgs {
  idCustomer: Scalars['String'];
  cascade?: Maybe<Scalars['Boolean']>;
}

export interface MutationsaveTadoDevicesArgs {
  customerId: Scalars['String'];
  devices?: Maybe<Array<Maybe<EPPTadoDevice>>>;
}

export interface MutationsetDefaultThermostatArgs {
  customerId: Scalars['String'];
  deviceId: Scalars['String'];
}

export interface MutationsetTadoHomeArgs {
  customerId: Scalars['String'];
  homeId: Scalars['String'];
}

export interface MutationenphaseCreateAccountArgs {
  id: Scalars['String'];
}

export interface MutationenphaseRegisterEnpArgs {
  customerId: Scalars['String'];
  envoySerialNumber: Scalars['String'];
  enphaseSystemId?: Maybe<Scalars['String']>;
  enphaseInstallerId?: Maybe<Scalars['Int']>;
  lang?: Maybe<Scalars['String']>;
}

export interface MutationtadoDisconnectArgs {
  customerId: Scalars['String'];
}

export interface MutationremoveDeviceArgs {
  customerId: Scalars['String'];
  deviceId: Scalars['String'];
}

export interface MutationcreateRoomArgs {
  customerEmail: Scalars['String'];
  roomName: Scalars['String'];
  roomType: Scalars['String'];
}

export interface MutationupdateRoomArgs {
  customerEmail: Scalars['String'];
  roomId: Scalars['String'];
  roomName?: Maybe<Scalars['String']>;
  roomType?: Maybe<Scalars['String']>;
}

export interface MutationsaveDiagnosticArgs {
  input: EDiagnostic;
}

export interface MutationupdateElectricInstallationArgs {
  electricInstallation?: Maybe<EElectricInstallation>;
  forceUpdate?: Maybe<Scalars['Boolean']>;
}

export interface MutationupdateFullInstallationArgs {
  pvInstallation?: Maybe<EPVInstallation>;
  electricInstallation?: Maybe<EElectricInstallation>;
  forceUpdate?: Maybe<Scalars['Boolean']>;
}

export interface MutationuploadCONSUELDocumentArgs {
  file: Scalars['Upload'];
  customerId: Scalars['String'];
}

export interface MutationaddBoxToMSBArgs {
  customerAndBoxes?: Maybe<EBox>;
}

export interface MutationdeleteBoxFromMSBArgs {
  customerAndBoxesSerialNumber?: Maybe<DMSBBox>;
}

export interface MutationcreateMSBArgs {
  MSB?: Maybe<EMSB>;
}

export interface MutationupdateMSBStatusArgs {
  customerAndStatus?: Maybe<UMSBStatus>;
}

export interface MutationactivationCodesValidationArgs {
  customerAndActivationCodes?: Maybe<CustomerAndActivationCodes>;
}

export interface MutationserialNumbersValidationArgs {
  serialNumbers?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface MutationupdateMSBCaeNumberArgs {
  customerAndCaeNumber?: Maybe<UMSBCaeNumber>;
}

export interface MutationupdateMSBArgs {
  msb?: Maybe<UMSB>;
}

export interface MutationupdateMetadaArgs {
  key: Scalars['String'];
  value: Scalars['Any'];
}

export interface MutationcreateProUserArgs {
  proUserCompany: CProUserCompany;
}

export interface MutationupdateProUserArgs {
  proUserCompany: EProUserCompany;
}

export interface MutationdeleteProUserArgs {
  proUserId: Scalars['String'];
}

export interface MutationuploadDocumentArgs {
  file: Scalars['Upload'];
  customerId: Scalars['String'];
  type: DocumentType;
}

export interface MutationupdatePVInstallationArgs {
  pvInstallation?: Maybe<EPVInstallation>;
}

export interface MutationupdatePVInstallationForSRSArgs {
  pvInstallation?: Maybe<EPVInstallationForSRS>;
}

export interface MutationaddDeviceForPLCPairingArgs {
  pvInstallationId: Scalars['String'];
  newDeviceActivationCodeOrMacAddress: Scalars['String'];
}

export interface MutationupdateDeviceUCGArgs {
  pvInstallationId: Scalars['String'];
  ucgSerialNumber?: Maybe<Scalars['String']>;
  noUcg?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
}

export interface MutationupdateDeviceMSTArgs {
  pvInstallationId: Scalars['String'];
  activationCode?: Maybe<Scalars['String']>;
  mac?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
}

export interface MutationupdateDeviceConfigArgs {
  pvInstallationId: Scalars['String'];
  devices?: Maybe<Array<Maybe<EPPDevice>>>;
  lang?: Maybe<Scalars['String']>;
}

export interface MutationupdateIsDevicesConfigurationModeSelectedArgs {
  pvInstallationId: Scalars['String'];
}

export interface MutationupdateRegistrationStepArgs {
  pvInstallationId: Scalars['String'];
  registrationSteps: ERegistrationSteps;
}

export interface MutationcompleteRegistrationOfDevicesArgs {
  id: Scalars['String'];
  lang: Scalars['String'];
}

export interface MutationaddModbusDeviceToMstArgs {
  pvInstallationId: Scalars['String'];
  reference: Scalars['String'];
  address: Scalars['String'];
  lang: Scalars['String'];
}

export interface MutationremoveModbusDeviceFromArgs {
  pvInstallationId: Scalars['String'];
  plcMacToRemove: Scalars['String'];
  lang: Scalars['String'];
}

export interface MutationsendInscriptionSummaryByEmailArgs {
  customerId: Scalars['String'];
  emailAddresses: Scalars['String'];
}

export interface MutationaddWModbusDevicesToMstArgs {
  pvInstallationId: Scalars['String'];
  modbusPartOrUpmSerial: Scalars['String'];
  lang: Scalars['String'];
  devices?: Maybe<Array<Maybe<ModbusDeviceInput>>>;
}

export interface MutationpasswordForgotArgs {
  email?: Maybe<Scalars['String']>;
}

export interface MutationresendActivationEmailArgs {
  email?: Maybe<Scalars['String']>;
}

export interface MutationmacToDakArgs {
  mac?: Maybe<Scalars['String']>;
}

export interface MutationcontinueSubscriptionArgs {
  customerId?: Maybe<Scalars['String']>;
}

export interface MutationcreateUcgArgs {
  ucg?: Maybe<CUcg>;
}

export interface MutationupdateUcgArgs {
  ucg?: Maybe<EUcg>;
}

export interface MutationdeleteUcgBySerialNumberArgs {
  serialNumber?: Maybe<Scalars['String']>;
}

export interface PPDevice {
  id?: Maybe<Scalars['String']>;
  firmware?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  deviceType?: Maybe<Scalars['String']>;
  plcMac?: Maybe<Scalars['String']>;
  ethMac?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['Boolean']>;
  power?: Maybe<Array<Maybe<Scalars['Float']>>>;
  activationCode?: Maybe<Scalars['String']>;
  masterMac?: Maybe<Scalars['String']>;
  deviceTypeOverride?: Maybe<Scalars['String']>;
  rx?: Maybe<Scalars['Int']>;
  tx?: Maybe<Scalars['Int']>;
  plcSignalQuality?: Maybe<Scalars['String']>;
  simNb?: Maybe<Scalars['String']>;
  rssi?: Maybe<Scalars['Int']>;
  rscp?: Maybe<Scalars['Int']>;
  gsmSignalQuality?: Maybe<Scalars['String']>;
  isGSMConnected?: Maybe<Scalars['Boolean']>;
  preConfig: PPDevicePreConfig;
  emulatedFromG3?: Maybe<Scalars['Boolean']>;
}

export interface PPDeviceConfigFile {
  id: Scalars['String'];
  ucg?: Maybe<Ucg>;
  devices?: Maybe<Array<Maybe<PPDevice>>>;
  alertsCode?: Maybe<Array<Maybe<Scalars['String']>>>;
  error?: Maybe<PPDeviceError>;
  mstError?: Maybe<Scalars['String']>;
  mst?: Maybe<MST>;
  isDevicesConfigurationModeSelected?: Maybe<Scalars['Boolean']>;
}

export interface PPDeviceError {
  step?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  mac?: Maybe<Scalars['String']>;
}

export interface PPDevicePreConfig {
  controlledDeviceType?: Maybe<Scalars['String']>;
  controlledDeviceName?: Maybe<Scalars['String']>;
  roomType?: Maybe<Scalars['String']>;
  roomName?: Maybe<Scalars['String']>;
  phase?: Maybe<Scalars['Int']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  compositeId?: Maybe<Scalars['String']>;
  greenPlayEnabled?: Maybe<Scalars['Boolean']>;
  isWirelessModbus?: Maybe<Scalars['Boolean']>;
}

export interface PPModbusDevices {
  reference?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
}

export interface PPTadoDevice {
  id: Scalars['String'];
  controlledDeviceType: Scalars['String'];
  deviceName: Scalars['String'];
  srsType?: Maybe<Scalars['String']>;
  tadoType?: Maybe<Scalars['String']>;
  roomName: Scalars['String'];
  roomType?: Maybe<Scalars['String']>;
  isInSrs: Scalars['Boolean'];
}

export interface PPTadoHome {
  id: Scalars['String'];
  name: Scalars['String'];
}

export interface ProUser {
  id: Scalars['String'];
  srsIdUser?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  enphaseInstallerId?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Date']>;
  company?: Maybe<Company>;
  role?: Maybe<Role>;
  hasRightPairingToolkit?: Maybe<Scalars['Boolean']>;
  hasRightToExportProsizerJSON?: Maybe<Scalars['Boolean']>;
}

export interface ProUserIds {
  email: Scalars['String'];
  proUserId: Scalars['String'];
  companyId: Scalars['String'];
}

export interface Provider {
  countryCode: Scalars['String'];
  name: Scalars['String'];
}

export interface PVInstallation {
  id: Scalars['String'];
  currency?: Maybe<Scalars['String']>;
  converterType?: Maybe<Scalars['String']>;
  panelCount?: Maybe<Scalars['Float']>;
  panelWattPeak?: Maybe<Scalars['Float']>;
  panelManufacturer?: Maybe<Scalars['String']>;
  totalWatt?: Maybe<Scalars['Float']>;
  hasMultipleProductionCounter?: Maybe<Scalars['Boolean']>;
  ucgSerialNumber?: Maybe<Scalars['String']>;
  srsLicenceStatus?: Maybe<Scalars['String']>;
  srsLicenceNumber?: Maybe<Scalars['String']>;
  srsRegistrationDate?: Maybe<Scalars['Date']>;
  srsRegistrationStatus?: Maybe<Scalars['String']>;
  isPending?: Maybe<Scalars['Boolean']>;
  tenant?: Maybe<Tenant>;
  electricInstallations?: Maybe<Array<Maybe<ElectricInstallation>>>;
  customers?: Maybe<Array<Maybe<Customer>>>;
  companies?: Maybe<Array<Maybe<Company>>>;
  canWrite?: Maybe<Scalars['Boolean']>;
}

export interface Query {
  renew?: Maybe<AuthToken>;
  companies?: Maybe<Array<Maybe<Company>>>;
  company?: Maybe<Company>;
  customers?: Maybe<Array<Maybe<Customer>>>;
  customer?: Maybe<Customer>;
  customerIdsByEmail?: Maybe<CustomerIds>;
  getCustomerList?: Maybe<Array<Maybe<CustomerListItem>>>;
  getTadoAuthUri?: Maybe<Scalars['String']>;
  isTadoAccessGranted?: Maybe<Scalars['Boolean']>;
  getTadoDevices?: Maybe<Array<Maybe<PPTadoDevice>>>;
  getTadoHomes?: Maybe<Array<Maybe<PPTadoHome>>>;
  getTadoHomeIdByCustomerId?: Maybe<Scalars['String']>;
  getRooms?: Maybe<Array<Maybe<DeviceRoom>>>;
  diagnosticSetByCustomerId?: Maybe<Array<Maybe<DiagnosticSet>>>;
  internetServiceProviders?: Maybe<Array<Maybe<Scalars['String']>>>;
  powerProviders?: Maybe<Array<Maybe<Scalars['String']>>>;
  powerProvidersWithCountry?: Maybe<Array<Maybe<Provider>>>;
  powerContractTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  internetBoxModels?: Maybe<Array<Maybe<Scalars['String']>>>;
  modbusDevices?: Maybe<Array<Maybe<ModbusDevice>>>;
  getARPEDownloadURL?: Maybe<Scalars['String']>;
  getMySmartBatteryList?: Maybe<Array<Maybe<MSBListItem>>>;
  MsbBoxTypes?: Maybe<Array<Maybe<MsbBoxType>>>;
  hasMSBBoxes?: Maybe<HasMSBBoxes>;
  getMSB?: Maybe<MSB>;
  getMSBStatus?: Maybe<Array<Maybe<MSBStatus>>>;
  buildVersion: Scalars['String'];
  getMetadataByKey?: Maybe<Scalars['Any']>;
  proUser?: Maybe<ProUser>;
  proUsers?: Maybe<Array<Maybe<ProUser>>>;
  proUsersByCompanyId?: Maybe<Array<Maybe<ProUser>>>;
  proUserIdsByEmail?: Maybe<ProUserIds>;
  pvInstallations?: Maybe<Array<Maybe<PVInstallation>>>;
  pvInstallationById?: Maybe<PVInstallation>;
  pvInstallationsRegisteredWithLimit?: Maybe<Array<Maybe<PVInstallation>>>;
  pvInstallationsPending?: Maybe<Array<Maybe<PVInstallation>>>;
  pvInstallationsActivationPending?: Maybe<Array<Maybe<PVInstallation>>>;
  distinctPanelManufacturer?: Maybe<Array<Maybe<Scalars['String']>>>;
  distinctPanelManufacturerWithCountry?: Maybe<Array<Maybe<Provider>>>;
  devicesConfig?: Maybe<PPDeviceConfigFile>;
  energyDistributorByElectricInstallationId?: Maybe<EnergyDistributor>;
  masterConfig?: Maybe<PPDevice>;
  getAvailableMSTModbusAddress?: Maybe<ModbusResponse>;
  getPairedDevicesByMAC?: Maybe<Array<Maybe<PPDevice>>>;
  getPairedDevicesByActivationCode?: Maybe<Array<Maybe<PPDevice>>>;
  getRegistrationSteps: RegistrationSteps;
  getUCGPreconfiguration?: Maybe<UCGPreconfiguration>;
  roles?: Maybe<Array<Maybe<Role>>>;
  roleByName?: Maybe<Role>;
  alerts?: Maybe<Array<Maybe<Alert>>>;
  getOneToOneCustomerData?: Maybe<SrsCustomer>;
  devices?: Maybe<Array<Maybe<Device>>>;
  feature?: Maybe<Feature>;
  srsDeviceTypeIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  srsRoomTypeIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  installers?: Maybe<Array<Maybe<ProUser>>>;
  maintainers?: Maybe<Array<Maybe<Maintainer>>>;
  lastConnectionsByEmail?: Maybe<Array<Maybe<Scalars['Date']>>>;
  system?: Maybe<System>;
  isSystemOnline?: Maybe<Scalars['Boolean']>;
  tenants?: Maybe<Array<Maybe<Tenant>>>;
  tenantByName?: Maybe<Tenant>;
  getAllUcg?: Maybe<Array<Maybe<Ucg>>>;
  getUcgBySerialNumber?: Maybe<Ucg>;
  getMetadata?: Maybe<UcgMetadata>;
}

export interface QuerycompanyArgs {
  id?: Maybe<Scalars['String']>;
}

export interface QuerycustomerArgs {
  id?: Maybe<Scalars['String']>;
}

export interface QuerycustomerIdsByEmailArgs {
  email?: Maybe<Scalars['String']>;
}

export interface QuerygetCustomerListArgs {
  pagination?: Maybe<CustomerListPagination>;
}

export interface QuerygetTadoAuthUriArgs {
  customerId: Scalars['String'];
}

export interface QueryisTadoAccessGrantedArgs {
  customerId: Scalars['String'];
}

export interface QuerygetTadoDevicesArgs {
  customerId: Scalars['String'];
}

export interface QuerygetTadoHomesArgs {
  customerId: Scalars['String'];
}

export interface QuerygetTadoHomeIdByCustomerIdArgs {
  customerId: Scalars['String'];
}

export interface QuerygetRoomsArgs {
  customerEmail: Scalars['String'];
}

export interface QuerydiagnosticSetByCustomerIdArgs {
  idCustomer?: Maybe<Scalars['String']>;
}

export interface QuerypowerProvidersWithCountryArgs {
  countryCode?: Maybe<Scalars['String']>;
}

export interface QuerymodbusDevicesArgs {
  pvInstallationId: Scalars['String'];
}

export interface QuerygetMySmartBatteryListArgs {
  pagination?: Maybe<MSBListPagination>;
}

export interface QueryhasMSBBoxesArgs {
  customerId?: Maybe<Scalars['String']>;
}

export interface QuerygetMSBArgs {
  customerId?: Maybe<Scalars['String']>;
}

export interface QuerygetMSBStatusArgs {
  customerId?: Maybe<Scalars['String']>;
}

export interface QuerygetMetadataByKeyArgs {
  key: Scalars['String'];
}

export interface QueryproUsersByCompanyIdArgs {
  companyId: Scalars['String'];
}

export interface QueryproUserIdsByEmailArgs {
  email: Scalars['String'];
}

export interface QuerypvInstallationByIdArgs {
  id: Scalars['String'];
}

export interface QuerypvInstallationsRegisteredWithLimitArgs {
  limit: Scalars['Int'];
}

export interface QuerydistinctPanelManufacturerWithCountryArgs {
  countryCode?: Maybe<Scalars['String']>;
}

export interface QuerydevicesConfigArgs {
  id: Scalars['String'];
  lang: Scalars['String'];
}

export interface QuerymasterConfigArgs {
  id: Scalars['String'];
  lang: Scalars['String'];
}

export interface QuerygetAvailableMSTModbusAddressArgs {
  pvInstallationId?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
}

export interface QuerygetPairedDevicesByMACArgs {
  mac: Scalars['String'];
}

export interface QuerygetPairedDevicesByActivationCodeArgs {
  activationCode: Scalars['String'];
}

export interface QuerygetRegistrationStepsArgs {
  pvInstallationId: Scalars['String'];
}

export interface QuerygetUCGPreconfigurationArgs {
  pvInstallationId: Scalars['String'];
  lang: Scalars['String'];
}

export interface QueryroleByNameArgs {
  name: Scalars['String'];
}

export interface QueryalertsArgs {
  idCustomer: Scalars['String'];
  visible?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
}

export interface QuerygetOneToOneCustomerDataArgs {
  customerId: Scalars['String'];
}

export interface QuerydevicesArgs {
  customerId?: Maybe<Scalars['String']>;
}

export interface QueryfeatureArgs {
  id?: Maybe<Scalars['String']>;
}

export interface QuerylastConnectionsByEmailArgs {
  email: Scalars['String'];
}

export interface QuerysystemArgs {
  id?: Maybe<Scalars['String']>;
}

export interface QueryisSystemOnlineArgs {
  customerId?: Maybe<Scalars['String']>;
}

export interface QuerytenantByNameArgs {
  name: Scalars['String'];
}

export interface QuerygetUcgBySerialNumberArgs {
  serialNumber: Scalars['String'];
}

export interface RedirectResult {
  uri?: Maybe<Scalars['String']>;
}

export interface RegistrationSteps {
  id: Scalars['String'];
  msb: Scalars['String'];
  partnership: Scalars['String'];
}

export interface Role {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  proUsers?: Maybe<Array<Maybe<ProUser>>>;
}

export interface RS485Data {
  modbusAddr?: Maybe<Scalars['Int']>;
  bauderate?: Maybe<Scalars['String']>;
  function?: Maybe<DeviceFunction>;
}

export interface RS485Device {
  type?: Maybe<Scalars['String']>;
  data?: Maybe<Array<Maybe<RS485Data>>>;
}

export interface SendEmailSuccess {
  success?: Maybe<Scalars['Boolean']>;
}

export interface SplitingDevice {
  counter?: Maybe<Scalars['Int']>;
  functions?: Maybe<Array<Maybe<DeviceFunction>>>;
}

export interface SrsCustomer {
  id: Scalars['String'];
  customer: Customer;
  pVInstallation: PVInstallation;
  electricInstallation: ElectricInstallation;
}

export interface StatusProperties {
  serialNumber?: Maybe<Scalars['String']>;
  pdlNumber?: Maybe<Scalars['String']>;
  caeNumber?: Maybe<Scalars['String']>;
  enedisConnectNumber?: Maybe<Scalars['String']>;
  consuelNumber?: Maybe<Scalars['String']>;
  consuelDeclaredPower?: Maybe<Scalars['Any']>;
  connectionPower?: Maybe<Scalars['Any']>;
}

export interface Success {
  success?: Maybe<Scalars['Boolean']>;
}

export interface System {
  registrationDate?: Maybe<Scalars['Date']>;
  status?: Maybe<Scalars['String']>;
  internetProvider?: Maybe<Scalars['String']>;
  boxSerialNumber?: Maybe<Scalars['String']>;
  masterActivationSerialNumber?: Maybe<Scalars['String']>;
  lastConnection?: Maybe<Scalars['Date']>;
  solarPanel?: Maybe<EnergyInfo>;
  network?: Maybe<EnergyInfo>;
  totalNetwork?: Maybe<EnergyInfo>;
  consumptionUpToDate?: Maybe<Scalars['Boolean']>;
  productionUpToDate?: Maybe<Scalars['Boolean']>;
  localBufferRecovery?: Maybe<Scalars['Boolean']>;
}

export interface Tenant {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  pvInstallations?: Maybe<Array<Maybe<PVInstallation>>>;
  companies?: Maybe<Array<Maybe<Company>>>;
}

export interface Ucg {
  ucgSerialNumber?: Maybe<Scalars['String']>;
  noUcg?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  characterCode?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Int']>;
  boxType?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  gridType?: Maybe<Scalars['String']>;
  subcontractor?: Maybe<Scalars['String']>;
  materials?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  gmd?: Maybe<SplitingDevice>;
  tph?: Maybe<SplitingDevice>;
  mstg3?: Maybe<SplitingDevice>;
  rs485?: Maybe<Array<Maybe<RS485Device>>>;
  webdyn?: Maybe<Scalars['Boolean']>;
  electricalProtectionBrand?: Maybe<Scalars['String']>;
  pvProtectionIncluded?: Maybe<Scalars['Boolean']>;
  couplingDevice?: Maybe<Scalars['Boolean']>;
  envoyGatewayIncluded?: Maybe<Scalars['Boolean']>;
  contactor?: Maybe<Scalars['String']>;
  waterHeaterWiring?: Maybe<Scalars['String']>;
  additionalInfo?: Maybe<Scalars['String']>;
  knownDefault?: Maybe<Scalars['String']>;
  todo?: Maybe<Scalars['String']>;
  releasedAt?: Maybe<Scalars['Date']>;
  deletedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
}

export interface UcgMetadata {
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  subcontractor?: Maybe<Array<Maybe<Scalars['String']>>>;
  materials?: Maybe<Array<Maybe<Scalars['String']>>>;
  location?: Maybe<Array<Maybe<Scalars['String']>>>;
  electricalProtectioBrand?: Maybe<Array<Maybe<Scalars['String']>>>;
  waterHeaterWiring?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface UCGPreconfiguration {
  devices?: Maybe<Array<Maybe<PPDevice>>>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  addedModbusDevices?: Maybe<Array<Maybe<PPModbusDevices>>>;
}

export interface UMSB {
  customerId: Scalars['String'];
  caeNumber?: Maybe<Scalars['String']>;
  enedisConnectNumber?: Maybe<Scalars['String']>;
  consuelNumber?: Maybe<Scalars['String']>;
  consuelDeclaredPower?: Maybe<Scalars['Any']>;
  connectionPower?: Maybe<Scalars['Any']>;
  pdlNumber?: Maybe<Scalars['String']>;
  addingBoxes?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletingBoxes?: Maybe<Array<Maybe<Scalars['String']>>>;
  provider?: Maybe<Scalars['String']>;
  strasbourgInjectionPdlNumber?: Maybe<Scalars['String']>;
}

export interface UMSBCaeNumber {
  customerId?: Maybe<Scalars['String']>;
  caeNumber?: Maybe<Scalars['String']>;
}

export interface UMSBStatus {
  customerId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
}

export interface ValidatedBox {
  serialNumber?: Maybe<Scalars['String']>;
  isValid?: Maybe<Scalars['Boolean']>;
  type?: Maybe<MsbBoxTypes>;
}
