import * as React from 'react';
import './FormInput.scss';
import { Input, Label } from 'reactstrap';
import { InputType } from 'reactstrap/lib/Input';

interface IProps {
  id: string;
  name: string;
  value: string | number;
  error?: string;
  onChange?: (value: string) => void;
  type?: InputType;
  disabled?: boolean;
  backgroundColor?: string;
  ref?: (a: any) => any;
  autoComplete?: boolean;
}

function hasError({ error, value, disabled }: IProps) {
  return error !== undefined && !!value && !disabled;
}

export const FormInput = (formProps: IProps) => {
  const {
    onChange,
    value,
    error,
    name,
    id,
    type,
    disabled,
    backgroundColor,
    ref,
    autoComplete = true
  } = formProps;
  return (
    <div className="input-field">
      <Label className="input-title" htmlFor={id}>
        {name}
      </Label>
      <Input
        id={id}
        type={type ? type : 'text'}
        name={name}
        value={value}
        ref={ref}
        style={{ backgroundColor }}
        onChange={event => {
          event.preventDefault();
          if (typeof onChange !== 'undefined') {
            onChange(event.target.value);
          }
        }}
        disabled={disabled}
        invalid={hasError(formProps)}
        autoComplete={autoComplete ? 'on' : 'off'}
      />
      {hasError(formProps) && <label className="error-message">{error}</label>}
    </div>
  );
};
