import * as React from 'react';

interface IProps {
  filled?: boolean;
}

export const IconFunnel = ({ filled }: IProps) => {
  if (filled) {
    return (
      <svg x="0px" y="0px" viewBox="0 0 1000 1000" width="10" height="10">
        <g id="g8">
          <g
            transform="translate(0.000000,119.000000) scale(0.100000,-0.100000)"
            id="g6"
          >
            <path
              d="M 0.16992188 0.26367188 L 98.386719 151.41211 C 151.96012 234.54885 241.24888 370.56639 294.82227 453.70312 L 393.04102 603.77734 L 393.04102 863.95898 L 393.04102 999.97656 L 500.1875 999.91602 L 607.33398 999.85742 L 607.33398 799.18164 L 607.33398 603.77734 L 705.55078 453.70312 C 760.01196 370.56639 848.41488 234.54885 901.98828 151.41211 L 1000.2051 0.26367188 L 500.1875 0.26367188 L 0.16992188 0.26367188 z "
              transform="matrix(10,0,0,-10,0,1190)"
              id="path4"
            />
          </g>
        </g>
      </svg>
    );
  }
  return (
    <svg x="0px" y="0px" viewBox="0 0 1000 1000" width="10" height="10">
      <g id="g8">
        <g
          transform="translate(0.000000,119.000000) scale(0.100000,-0.100000)"
          id="g6"
        >
          <path
            d="m 489.2137,700.00116 886.4092,-1364.10542 c 483.5,-750.30904 1289.3367,-1977.88074 1772.8366,-2728.18984 l 886.4096,-1354.4135 v -2348.1313 -1227.5689 l 967.0034,0.5482 967.0033,0.5275 v 1811.0972 1763.5273 l 886.4097,1354.4135 c 491.5121,750.3091 1289.3366,1977.8808 1772.8366,2728.18984 L 9514.5318,700.00116 H 5001.8725 Z m 4512.6588,-904.357 c 2315.7927,0 3043.4344,-28.32521 3004.2716,-113.51709 -31.2947,-56.75854 -649.4035,-1021.65377 -1377.0453,-2137.90527 L 5314.8173,-4489.5627 V -6078.8019 -7694.743 l -312.9448,-5.9552 -312.945,-0.2293 v 1432.9305 1787.8309 l -1314.3687,2014.9916 c -719.7735,1116.1433 -1337.7949,2081.13732 -1376.9578,2147.30157 -46.9418,85.19188 578.9482,113.51709 3004.2715,113.51709 z"
            id="path4"
          />
        </g>
      </g>
    </svg>
  );
};
