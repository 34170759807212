import * as React from 'react';
import { Customer } from '../../../graphql/graphql.schema';
import { inject, observer } from 'mobx-react';
import { Store } from '../../../stores';
import { Row } from 'reactstrap';
import { translate, TranslationFunction } from 'react-i18next';
import { ListTitle } from '../../../ui/DataDisplay/List/ListHeader';
import ListPlaceHolder from '../../../ui/DataDisplay/List/ListPlaceHolder';
import DeviceItem from '../devices/DeviceItem';
import List from '../../../ui/DataDisplay/List/ListWrapper';
import { RouteComponentProps, withRouter } from 'react-router';
import RefreshDeviceConfig from '../devices/RefreshDeviceConfig';
import { IAllStores } from '../../../stores/allStores.model';
import { Device } from '../../../stores/inscription/device';
import DeviceFunctionItem from '../functions/DeviceFunctionItem';
import { CompositeDevice } from '../../../stores/inscription/compositeDevice';
import { DeviceCT } from '../../../stores/inscription/deviceCT';

interface IProps {
  Store?: Store;
  t: TranslationFunction;
  activeDevices?: Device[];
}

const SummaryDevicesAndFunctions = inject(
  ({ inscription: inscriptionStore }: IAllStores) => {
    return {
      activeDevices: inscriptionStore.activeDevices
    };
  }
)(
  observer(({ Store: mobix, t, activeDevices }: IProps) => {
    return (
      <Row>
        <List fluid={true}>
          <ListTitle
            text={t('RegistrationMenu:devicesSummary')}
            children={<RefreshDeviceConfig />}
          />
          {activeDevices!.length === 0 ? (
            <ListPlaceHolder text={t('Devices:emptyDoneList')} />
          ) : (
            activeDevices!.map(d => {
              return (
                <div key={d.getKey} className={'devices-and-functions'}>
                  <DeviceItem withoutActions device={d} />
                  {d.isDone ? (
                    <div className={'functions'}>
                      {d instanceof CompositeDevice && d.editedAreCTSplit ? (
                        d.cts.map(
                          (ct: DeviceCT) =>
                            ct.isDone && (
                              <DeviceFunctionItem
                                isUnused
                                withoutActions
                                key={ct.getKey}
                                device={ct}
                                parent={d}
                              />
                            )
                        )
                      ) : (
                        <DeviceFunctionItem
                          isUnused
                          withoutActions
                          device={d}
                          key={d.getKey}
                        />
                      )}
                    </div>
                  ) : (
                    <div className={'no-function'}>
                      <span>{t('Devices:noFunctionLabel')}</span>
                    </div>
                  )}
                </div>
              );
            })
          )}
        </List>
      </Row>
    );
  })
);

export default translate(['Customer', 'RegistrationMenu'])(
  SummaryDevicesAndFunctions
);
