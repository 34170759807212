import { t } from 'i18next';

const supportedCountries = [
  { value: 'FR', label: 'Installation:france' },
  { value: 'CH', label: 'Installation:swiss' }
];

export const getSupportedCountries = () => {
  return supportedCountries.map(supportedCountry => {
    return {
      value: supportedCountry.value,
      label: t(supportedCountry.label)
    };
  });
};
