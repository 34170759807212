import React from 'react';
import { DeviceConnectionType } from '../../../../constants/DeviceConnectionType';
import { HelpModal } from '../../../../ui/HelpModal/HelpModal';
import IsolatedComponent from '../../../../ui/IsolatedComponent/IsolatedComponent';
import { translate, TranslationFunction } from 'react-i18next';
import { Button, Col, Row } from 'reactstrap';
import { RouteComponentProps, withRouter } from 'react-router';
import { inject, observer } from 'mobx-react';
import { IAllStores } from '../../../../stores/allStores.model';
import List from '../../../../ui/DataDisplay/List/ListWrapper';
import { ListTitle } from '../../../../ui/DataDisplay/List/ListHeader';
import ListItem from '../../../../ui/DataDisplay/List/ListItem';
import { IInscriptionNavigationRouterParamProps } from '../../InscriptionNavigation';
import { getDevicePath } from '../../routingHelper';
import ModbusBoxImage from '../../../../assets/images/devices/devicetype/modbus-box.jpg';
import ModbusPartImage from '../../../../assets/images/devices/devicetype/modbus-part.png';
import RS485Image from '../../../../assets/images/devices/devicetype/rs485_MC1.png';

import './addDevice.scss';

interface IProps
  extends RouteComponentProps<IInscriptionNavigationRouterParamProps> {
  t: TranslationFunction;
  possibleConnectionTypes: string[];
  isMstG3: boolean;
}

const AddDeviceTypeList = inject((allStores: IAllStores) => {
  return {
    possibleConnectionTypes: allStores.addDevice.possibleConnectionTypes,
    isMstG3: allStores.inscription.isMstG3
  };
})(
  observer(
    ({
      t,
      history,
      match: {
        params: { customerId, pvInstallationId }
      },
      possibleConnectionTypes,
      isMstG3
    }: IProps) => {
      const goToDevicesList = () =>
        history.push(getDevicePath({ customerId, pvInstallationId }));
      if (!isMstG3) {
        goToDevicesList();
      }

      return (
        <IsolatedComponent
          title={t('addDeviceTitle')}
          md={12}
          xl={12}
          onGoBack={goToDevicesList}
        >
          <Col md={6} sm={12} className={'new-device-list mobile-helper'}>
            <List fluid={true}>
              <ListTitle text={t('addDeviceType')} />
              {possibleConnectionTypes.map(type => (
                <ListItem
                  key={type}
                  className={'device-type'}
                  options={{
                    onClick: () => {
                      history.push(
                        `/devices_inscription/${customerId}/devices/${pvInstallationId}/add/${type}`
                      );
                    }
                  }}
                >
                  <Col className={'device-type-name'}>
                    <span>{t(`addDevice${type.toUpperCase()}`)}</span>
                  </Col>

                  {/* Helper modal RS485 */}
                  {type === DeviceConnectionType.RS485 && (
                    <HelpModal title={t(`addDevice${type.toUpperCase()}`)}>
                      <div className="device-type-image-container">
                        <img
                          className={'device-type-image'}
                          src={RS485Image}
                          alt=""
                        />
                        <p>{t('DeviceType:rs485')}</p>
                      </div>
                    </HelpModal>
                  )}

                  {/* Helper modal W-Modbus*/}
                  {type === DeviceConnectionType.W_MODBUS && (
                    <HelpModal title={t(`addDevice${type.toUpperCase()}`)}>
                      <Row style={{ alignItems: 'center' }}>
                        <Col style={{ flex: 4 }}>
                          <div className="device-type-image-container">
                            <img
                              className={'device-type-image'}
                              src={ModbusPartImage}
                              alt=""
                            />
                            <p>{t('DeviceType:modbus_part')}</p>
                          </div>
                        </Col>
                        <Col
                          style={{ flex: 1 }}
                          className={
                            'text-center text-primary text-uppercase p-0'
                          }
                        >
                          <b>{t('Common:or')}</b>
                        </Col>
                        <Col style={{ flex: 4 }}>
                          <div className="device-type-image-container">
                            <img
                              className={'device-type-image'}
                              src={ModbusBoxImage}
                              alt=""
                            />
                            <p>{t('DeviceType:modbus_box')}</p>
                          </div>
                        </Col>
                      </Row>
                    </HelpModal>
                  )}

                  <Col className="arrow-icon">
                    <i className={'icon-full-arrow-right'} />
                  </Col>
                </ListItem>
              ))}
              <Button
                color={'secondary'}
                className={'cancel-button'}
                onClick={goToDevicesList}
              >
                {t('Common:cancel')}
              </Button>
            </List>
          </Col>
        </IsolatedComponent>
      );
    }
  )
);

export default withRouter(
  translate(['Devices', 'DeviceType', 'Master', 'Common'])(AddDeviceTypeList)
);
