import gql from 'graphql-tag';
import { default as React, useContext } from 'react';
import { Mutation, Query } from 'react-apollo';
import { Device, PPDevice } from '../../../../graphql/graphql.schema';
import { DEVICE_TYPE } from '../../../../stores/devices_inscription/contants';
import { DELAY_REFRESH_CUSTOMER_AND_DEVICE_CONFIG } from '../../../../stores/inscription/inscriptionStore';
import { InstallationContext } from '../Installation.context';
import MasterConfigFieldsView from './MasterConfigFieldsView';

const GET_DEVICES = gql`
  query getDevices($customerId: String!) {
    devices(customerId: $customerId) {
      type
      macSerialNumber
    }
  }
`;

const GET_DEVICES_CONFIG = gql`
  query getDevicesConfig($masterMac: String!) {
    getPairedDevicesByMAC(mac: $masterMac) {
      deviceType
      simNb
      rssi
      rscp
      gsmSignalQuality
      isGSMConnected
      rx
      tx
      plcSignalQuality
    }
  }
`;

const UPDATE_ELECTRIC_INSTALLATION = gql`
  mutation updateElectricInstallation(
    $electricInstallation: EElectricInstallation!
  ) {
    updateElectricInstallation(
      electricInstallation: $electricInstallation
      forceUpdate: true
    ) {
      id
    }
  }
`;

type GqlPPDevice = Pick<
  PPDevice,
  | 'deviceType'
  | 'simNb'
  | 'rssi'
  | 'rscp'
  | 'gsmSignalQuality'
  | 'isGSMConnected'
  | 'rx'
  | 'tx'
  | 'plcSignalQuality'
>;

interface IMasterConfigResponse {
  getPairedDevicesByMAC: GqlPPDevice[];
}

type GqlDevice = Pick<Device, 'type' | 'macSerialNumber'>;

interface IDevicesResponse {
  devices: GqlDevice[];
}

interface IProps {
  customerId: string;
}

export const MasterConfigFieldsQuery: React.FC<IProps> = ({ customerId }) => {
  const { installation, setInstallation } = useContext(InstallationContext);

  if (!installation) {
    return null;
  }

  return (
    <Query query={GET_DEVICES} variables={{ customerId }}>
      {({
        loading: devicesLoading,
        data: devicesData
      }: {
        loading: boolean;
        data?: IDevicesResponse;
      }) => {
        const master =
          devicesData &&
          devicesData.devices &&
          devicesData.devices.find(device => device.type === DEVICE_TYPE.MST);

        return (
          <Query
            query={GET_DEVICES_CONFIG}
            variables={{ masterMac: master && master.macSerialNumber }}
            pollInterval={DELAY_REFRESH_CUSTOMER_AND_DEVICE_CONFIG}
            fetchPolicy={'no-cache'}
          >
            {({
              loading,
              data
            }: {
              loading: boolean;
              data?: IMasterConfigResponse;
            }) => {
              const { electricInstallation } = installation;

              const masterConfig =
                data &&
                data.getPairedDevicesByMAC &&
                data.getPairedDevicesByMAC.find(
                  device => device.deviceType === DEVICE_TYPE.MST
                );

              const plcConfig =
                data &&
                data.getPairedDevicesByMAC &&
                data.getPairedDevicesByMAC.find(
                  device => device.deviceType === DEVICE_TYPE.COM1
                );

              const configSimNumber = masterConfig && masterConfig.simNb;

              return (
                <Mutation
                  mutation={UPDATE_ELECTRIC_INSTALLATION}
                  variables={{
                    electricInstallation: {
                      id: electricInstallation.id,
                      gridType: electricInstallation.gridType,
                      rackingPower: electricInstallation.rackingPower,
                      powerContractType: electricInstallation.powerContractType,
                      powerContractOption:
                        electricInstallation.powerContractOption,
                      timeSlots: electricInstallation.timeSlots,
                      powerProvider: electricInstallation.powerProvider,
                      sellingPower: electricInstallation.sellingPower,
                      sellingEnergyPrice:
                        electricInstallation.sellingEnergyPrice,
                      internetServiceProvider:
                        electricInstallation.internetServiceProvider,
                      internetBoxModel: electricInstallation.internetBoxModel,
                      masterTypeInternetConnection:
                        electricInstallation.masterTypeInternetConnection,
                      simSerialNumber: configSimNumber,
                      simRouterReference:
                        electricInstallation.simRouterReference,
                      pdlNumber: electricInstallation.pdlNumber,
                      cascade: true
                    }
                  }}
                >
                  {updateElectricInstallation => {
                    if (
                      configSimNumber &&
                      installation.simSerialNumber !== configSimNumber
                    ) {
                      updateElectricInstallation()
                        .catch(console.error)
                        .finally(() => {
                          setInstallation({
                            ...installation,
                            simSerialNumber: configSimNumber
                          });
                        });
                    }

                    return (
                      <MasterConfigFieldsView
                        loading={devicesLoading || loading}
                        masterConfig={masterConfig}
                        plcConfig={plcConfig}
                      />
                    );
                  }}
                </Mutation>
              );
            }}
          </Query>
        );
      }}
    </Query>
  );
};
