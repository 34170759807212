import React, { useEffect, useState } from 'react';
import { safeMobxInject } from '../../../../stores/storeInjectionHelpers';
import { RouteComponentProps, withRouter } from 'react-router';
import { translate, TranslationFunction } from 'react-i18next';
import { IAllStores } from '../../../../stores/allStores.model';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { ISnackBarParams } from '../../../../stores/userFeedback/snackbarStore';
import SelectDevicesConfigurationModeModalView from './SelectDevicesConfigurationModeModalView';

interface IMatchParams {
  pvInstallationId: string;
}

interface IProps extends RouteComponentProps<IMatchParams> {
  t: TranslationFunction;
  openUcgPreconfigurationToolModal: () => void;
}

interface IStoresProps {
  isDevicesConfigurationModeSelected: boolean;
  setIsDevicesConfigurationModeSelected: (
    isDevicesConfigurationModeSelected: boolean
  ) => void;
  showSnackBar: (params: ISnackBarParams) => void;
}

interface IParams {
  pvInstallationId: string;
}

interface IResponse {
  updateIsDevicesConfigurationModeSelected: boolean;
}

export const UPDATE_IS_DEVICES_CONFIGURATION_MODE_SELECTED = gql`
  mutation updateIsDevicesConfigurationModeSelected(
    $pvInstallationId: String!
  ) {
    updateIsDevicesConfigurationModeSelected(
      pvInstallationId: $pvInstallationId
    )
  }
`;

const SelectDevicesConfigurationModeModalQuery = safeMobxInject<
  IStoresProps,
  IProps
>(
  ({
    inscription: {
      isDevicesConfigurationModeSelected,
      setIsDevicesConfigurationModeSelected
    },
    snackBar: { showSnackBar }
  }: IAllStores) => ({
    isDevicesConfigurationModeSelected,
    setIsDevicesConfigurationModeSelected,
    showSnackBar
  }),
  ({
    isDevicesConfigurationModeSelected,
    setIsDevicesConfigurationModeSelected,
    t,
    openUcgPreconfigurationToolModal,
    showSnackBar,
    match: {
      params: { pvInstallationId }
    }
  }: IProps & IStoresProps) => {
    const [
      isDeviceConfigurationQuestionModalOpened,
      toggleDeviceConfigurationQuestionModal
    ] = useState(false);

    useEffect(
      () => {
        toggleDeviceConfigurationQuestionModal(
          !isDevicesConfigurationModeSelected
        );
      },
      [isDevicesConfigurationModeSelected]
    );

    return (
      <Mutation<IResponse, IParams>
        mutation={UPDATE_IS_DEVICES_CONFIGURATION_MODE_SELECTED}
        onCompleted={({ updateIsDevicesConfigurationModeSelected }) => {
          setIsDevicesConfigurationModeSelected(
            updateIsDevicesConfigurationModeSelected
          );
          toggleDeviceConfigurationQuestionModal(
            !updateIsDevicesConfigurationModeSelected
          );
          toggleDeviceConfigurationQuestionModal(false);
        }}
        onError={() => {
          showSnackBar({ text: t('Errors:unknown'), type: 'error' });
          toggleDeviceConfigurationQuestionModal(false);
        }}
      >
        {(updateIsDevicesConfigurationModeSelected, { loading }) => {
          const selectDevicesConfigurationMode = async (): Promise<boolean> => {
            const mutationResponse = await updateIsDevicesConfigurationModeSelected(
              {
                variables: {
                  pvInstallationId
                }
              }
            );

            return Boolean(
              mutationResponse &&
                mutationResponse.data &&
                mutationResponse.data.updateIsDevicesConfigurationModeSelected
            );
          };
          return (
            <SelectDevicesConfigurationModeModalView
              selectDevicesConfigurationMode={selectDevicesConfigurationMode}
              isLoading={loading}
              isDeviceConfigurationQuestionModalOpened={
                isDeviceConfigurationQuestionModalOpened
              }
              openUcgPreconfigurationToolModal={
                openUcgPreconfigurationToolModal
              }
            />
          );
        }}
      </Mutation>
    );
  }
);

export default translate('Errors')(
  withRouter(SelectDevicesConfigurationModeModalQuery)
);
