import { FormInput } from '../../../ui/FormInput/ToggleFormInput';
import { CustomForm } from '../../../ui/FormInput/CustomForm';
import { Button, Col } from 'reactstrap';
import { getDevicePath } from '../routingHelper';
import React from 'react';
import SelectInput from '../../../ui/Select/Select';
import gql from 'graphql-tag';
import { Query, QueryResult } from 'react-apollo';
import { inject, observer } from 'mobx-react';
import { IAllStores } from '../../../stores/allStores.model';
import { translate } from 'react-i18next';
import SaveInscriptionQueryButton from '../SaveInscriptionButton/SaveInscriptionQueryButton';
import { Device } from '../../../stores/inscription/device';
import { IDeviceDetailsProps } from './DeviceDetail';
import { withRouter } from 'react-router';

const GET_ROOM_TYPES = gql`
  query srsRoomTypeIds {
    srsRoomTypeIds
  }
`;

const DeviceRoomEdition = inject(({ inscription }: IAllStores) => {
  return {
    getEditedDevice: inscription.getEditedDevice
  };
})(
  observer(
    ({
      t,
      match: {
        params: {
          ethMac: ethMacFromRouter,
          plcMac: plcMacFromRouter,
          pvInstallationId,
          customerId
        }
      },
      history,
      getEditedDevice
    }: IDeviceDetailsProps) => {
      const editedDevice: Device | undefined = getEditedDevice!({
        ethMac: ethMacFromRouter,
        plcMac: plcMacFromRouter
      });
      if (editedDevice) {
        const {
          editedRoomType,
          setEditedRoomType,
          setEditedRoomName,
          editedRoomName,
          editedRoomNameError,
          canSaveRoom
        } = editedDevice;
        return (
          <Query query={GET_ROOM_TYPES}>
            {({ loading, data }: QueryResult) => {
              const roomTypes: string[] = data && data.srsRoomTypeIds;
              return (
                <Col className="form-container">
                  <SelectInput
                    options={
                      (roomTypes &&
                        roomTypes.map((type: string) => {
                          return {
                            label: t!(`RoomName:${type}`),
                            value: type
                          };
                        })) ||
                      []
                    }
                    value={{
                      label:
                        (editedRoomType && t!(`RoomName:${editedRoomType}`)) ||
                        '',
                      value: editedRoomType || ''
                    }}
                    id="roomType"
                    key="roomType"
                    name={t!('Devices:roomType')}
                    onChange={item => {
                      setEditedRoomType(item.value);
                    }}
                  />
                  {!editedDevice.hasDefaultRoomSelected && (
                    <FormInput
                      name={t!('Devices:roomName')}
                      id="roomname"
                      value={editedRoomName || ''}
                      onChange={value => {
                        setEditedRoomName(value);
                      }}
                      error={editedRoomName && editedRoomNameError}
                    />
                  )}
                  <CustomForm>
                    <Button
                      color="secondary"
                      onClick={() =>
                        history.push(
                          getDevicePath({
                            pvInstallationId,
                            customerId
                          })
                        )
                      }
                    >
                      {t!('Common:cancel')}
                    </Button>
                    <SaveInscriptionQueryButton
                      disabled={!canSaveRoom}
                      onCompleted={() => {
                        history.push(
                          getDevicePath({
                            pvInstallationId,
                            customerId
                          })
                        );
                      }}
                    />
                  </CustomForm>
                </Col>
              );
            }}
          </Query>
        );
      }
      return <div />;
    }
  )
);

export default withRouter(
  translate(['Common', 'RoomName', 'Devices'])(DeviceRoomEdition)
);
