import { Col, FormGroup, Label } from 'reactstrap';
import React from 'react';
import { translate, TranslationFunction } from 'react-i18next';
import fileIcon from '../../../../../assets/icons/file.svg';
import './RowFile.scss';
import { IconSuccess } from '../../../../../ui/Icons/IconSuccess';

interface IProps {
  t: TranslationFunction;
  typeFile?: TypeFile;
  fileName?: string;
}

export enum TypeFile {
  CONSUEL = 'CONSUEL',
  ARPE = 'ARPE'
}

const RowFile = ({ t, typeFile, fileName }: IProps) => {
  return (
    <div className="row-consuel-file">
      <FormGroup row>
        <Label
          sm={12}
          md={6}
          className="row-consuel-file__label"
          htmlFor="consuelUpload"
        >
          {t(typeFile === TypeFile.ARPE ? 'arpeUpload' : 'consuelUpload')}
        </Label>
        <Col sm={12} md={6} className="row-consuel-file__content">
          <div className="row-consuel-file__content__file">
            <img
              className="row-consuel-file__content__file__icon"
              src={fileIcon}
            />
            <span
              className="row-consuel-file__content__file__text"
              title={fileName}
            >
              {fileName}
            </span>
          </div>
          <div className="row-consuel-file__check-icon">
            <IconSuccess />
          </div>
        </Col>
      </FormGroup>
    </div>
  );
};

export default translate(['MySmartBattery'])(RowFile);
