import React from 'react';
import { IDeviceIcon } from '../DeviceIcons';

const HairDryer = (props: IDeviceIcon) => (
  <svg
    width="3rem"
    height="3rem"
    viewBox="0 0 20000 20000"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    color="black"
    {...props}
  >
    <g id="prefix__layer">
      <g id="prefix___1405066025568">
        <path
          style={{ fill: `${props.color}` }}
          d="M7863 12939H6383c-173 0-288-115-288-308v-1903c-1000-365-1673-1307-1673-2384 0-1423 1134-2557 2557-2557h5941c173 0 288 115 288 288v2903c0 173-115 288-288 288H9363c-250 596-654 1057-1192 1365v2076c-58 115-192 231-308 231zm-1250-538h942v-1961c0-115 58-231 173-231 538-231 942-654 1134-1250 58-115 115-173 231-173h3441V6402H6920c-1077 0-1961 884-1961 1961 0 884 596 1653 1480 1903 115 58 231 115 231 288l-58 1846z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M6979 9382c-538 0-1000-481-1000-1000 0-538 481-1019 1000-1019 538 0 1019 481 1019 1019-19 519-481 1000-1019 1000zm0-1423c-231 0-423 173-423 423 0 231 173 423 423 423 231 0 423-173 423-423s-192-423-423-423zM14765 7248c-231 0-423-58-654-288-173-173-288-115-423 0-115 115-288 115-423 0-115-115-115-288 0-423 0 0 288-231 596-231 115 0 346 58 596 288 231 231 596 0 596-58 115-115 308-58 423 58 115 115 58 308-58 423-115 38-365 231-654 231z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M14765 8075c-231 0-423-58-654-288-173-173-288-115-423 0-115 115-288 115-423 0-115-115-115-308 0-423 0 0 288-288 654-231 115 0 365 58 596 308 231 231 596 0 596-58 115-115 288-58 423 58 115 115 58 288-58 423-173 96-423 211-711 211zM14765 8959c-231 0-423-58-654-288-173-173-288-115-423 0-115 115-288 115-423 0-115-115-115-288 0-423 0 0 288-308 654-231 115 0 365 58 596 288 231 231 596 0 596-58 115-115 288-58 423 58 115 115 58 288-58 423-173 115-423 231-711 231zM6441 14304c-173 0-308-115-308-308 0-173 115-288 308-288 173 0 231-58 288-115 173-231 115-769 115-942s58-288 231-288 288 58 288 231c0 115 173 884-231 1365-154 231-385 346-692 346z"
        />
      </g>
    </g>
  </svg>
);

export default HairDryer;
