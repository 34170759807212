import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import CustomModal from '../CustomModal/CustomModal';

interface IProps {
  title?: string;
  className?: string;
  size?: 'sm' | 'md' | 'lg';
  children?: any;
}

export const HelpModal: React.FunctionComponent<IProps> = ({
  title,
  className,
  size,
  children
}) => {
  const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);

  const handleIconClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsModalOpened(true);
  };

  const closeModal = () => {
    setIsModalOpened(false);
  };

  return (
    <>
      <FontAwesomeIcon
        className={'text-primary'}
        icon={'question-circle'}
        size={'2x'}
        onClick={handleIconClick}
      />

      <CustomModal
        {...{ title, className, size }}
        handleClose={closeModal}
        isOpened={isModalOpened}
      >
        {children}
      </CustomModal>
    </>
  );
};
