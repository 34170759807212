import { action, computed, observable } from 'mobx';
import customersMode from '../customersMode';
import { t } from 'i18next';

export default class MainMenu {
  @observable public menuIsVisible: boolean = false;
  @observable public title?: string = undefined;
  @observable public backVersion: string = '';

  @action.bound
  public setMenuVisibility(input: boolean) {
    this.menuIsVisible = input;
  }
  @action.bound
  public setTitle(title?: string) {
    this.title = title;
  }

  @action.bound
  public setBackVersion(backVersion: string) {
    this.backVersion = backVersion;
  }

  @computed
  public get mainMenuItems() {
    return [
      {
        icon: 'dashboard',
        key: 'hub',
        name: t('Menu:dashboard'),
        path: 'dashboard'
      },
      {
        icon: 'client',
        key: 'customers',
        name: t('Menu:customers'),
        path: `customers/${customersMode.currentMode}`
      },
      {
        icon: 'company',
        key: 'mySmartBattery',
        name: t('Menu:mySmartBattery'),
        path: `mySmartBattery/list`
      },
      {
        icon: 'support',
        key: 'support',
        name: t('Menu:support'),
        path: 'support'
      },
      {
        icon: 'tool',
        key: 'proSizer',
        name: t('Menu:proSizer'),
        path: 'dashboard'
      },
      {
        icon: 'disconnect',
        key: 'disconnect',
        name: t('Menu:disconnect'),
        path: 'login'
      }
      /* {
        icon: 'company',
        key: 'enterprise',
        name: 'ENTREPRISE',
        path: 'user'
      },
      {
        icon: 'tool',
        key: 'tools',
        name: 'OUTILS',
        path: 'tools'
      },
      {
        icon: 'profile',
        key: 'myAccount',
        name: 'MON COMPTE',
        path: 'my_account'
      }*/
    ];
  }
}
