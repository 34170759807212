import React from 'react';
import { Col, Row } from 'reactstrap';
import { TranslationFunction } from 'i18next';
import { translate } from 'react-i18next';
import './sendInscriptionSummaryByEmailFeature.scss';
import Button from 'reactstrap/lib/Button';

interface IProps {
  t: TranslationFunction;
  openSendInscriptionSummaryModal: (state: boolean) => void;
  isSendInscriptionSummaryModalOpened: boolean;
}

const SendInscriptionSummaryByEmailFeature = ({
  t,
  openSendInscriptionSummaryModal,
  isSendInscriptionSummaryModalOpened
}: IProps) => (
  <Col className={'send-inscription-summary'} xl={6}>
    <Row className={'send-inscription-summary-title'}>{t('Common:new')}</Row>
    <Row className={'send-inscription-summary-info'}>
      {t('DevicesInscription:sendInscriptionSummaryEmailFeatureInfo')}
    </Row>
    <Row className={'send-inscription-summary-button'}>
      <Button
        onClick={() => {
          openSendInscriptionSummaryModal(!isSendInscriptionSummaryModalOpened);
        }}
      >
        {t('DevicesInscription:sendInscriptionSummary')}
      </Button>
    </Row>
  </Col>
);

export default translate(['Common', 'DevicesInscription'])(
  SendInscriptionSummaryByEmailFeature
);
