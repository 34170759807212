import * as React from 'react';
import { translate, TranslationFunction } from 'react-i18next';
import { Query, QueryResult } from 'react-apollo';
import { GET_CUSTOMER } from '../../customers/identity/IdentityQuery';
import { RouteComponentProps, withRouter } from 'react-router';
import { Customer, Provider } from '../../../graphql/graphql.schema';
import gql from 'graphql-tag';
import SelectInput from '../../../ui/Select/Select';

export const GET_POWER_PROVIDER_BY_COUNTRY = gql`
  query powerProvidersWithCountry($countryCode: String) {
    powerProvidersWithCountry(countryCode: $countryCode) {
      name
      countryCode
    }
  }
`;

interface IMatchParams {
  customerId: string;
}

interface IProps extends RouteComponentProps<IMatchParams> {
  t: TranslationFunction;
  onChange: (a: string) => void;
  value: string;
}

interface IResponseGetCustomer {
  customer: Customer;
}

interface IResponseGetProviderByCountry {
  powerProvidersWithCountry: Provider[];
}

const ElectricityProviderSelector = ({
  t,
  match: {
    params: { customerId }
  },
  onChange,
  value
}: IProps) => {
  return (
    <Query query={GET_CUSTOMER} variables={{ id: customerId }}>
      {({
        loading: customerLoading,
        data: cData
      }: QueryResult<IResponseGetCustomer>) => {
        const customerCountry =
          cData && cData.customer && cData.customer.country;
        return (
          <Query
            query={GET_POWER_PROVIDER_BY_COUNTRY}
            variables={{ countryCode: customerCountry }}
            skip={!customerCountry}
          >
            {({
              loading: listPowerProviderloading,
              data: pData
            }: QueryResult<IResponseGetProviderByCountry>) => {
              let listNameProviders: string[] = [];

              if (pData && pData.powerProvidersWithCountry) {
                listNameProviders = pData.powerProvidersWithCountry.map(
                  powerProvider => {
                    return powerProvider.name;
                  }
                );
              }
              return (
                <SelectInput
                  required
                  id="electricityProvider"
                  name={t('electricityProvider')}
                  value={{ value, label: value }}
                  loading={customerLoading || listPowerProviderloading}
                  options={
                    listNameProviders
                      ? listNameProviders.map((r: string) => ({
                          value: r,
                          label: r
                        }))
                      : []
                  }
                  onChange={selectValue => {
                    if (selectValue) {
                      onChange(selectValue.value);
                    }
                  }}
                />
              );
            }}
          </Query>
        );
      }}
    </Query>
  );
};

export default translate(['Customer', 'Installation'])(
  withRouter(ElectricityProviderSelector)
);
