import * as React from 'react';
import { get } from 'lodash';

import { getSmallTenantLogo } from '../../../utils/tools';
import { ITenant } from '@mylight/data-model';

import './TenantLogo.scss';
import { translate } from 'react-i18next';
import { TranslationFunction } from 'i18next';

interface IProps {
  tenant?: ITenant;
  t: TranslationFunction;
  bypass?: string;
}

const TenantLogo = ({ tenant, bypass }: IProps) => (
  <div className="tenant-logo">
    <img
      title={tenant ? tenant.name || '' : ''}
      className={'customer-tenant-logo'}
      src={bypass || getSmallTenantLogo(get(tenant, 'name'))}
    />
  </div>
);

export default translate('Tenant')(TenantLogo);
