import React from 'react';
import { translate, TranslationFunction } from 'react-i18next';
import { Button, Row } from 'reactstrap';
import { IModbusDevice } from '../../../../../stores/inscription/addDeviceStore';
import { safeMobxInject } from '../../../../../stores/storeInjectionHelpers';
import '../addDevice.scss';
import WModbusDeviceForm from './WModbusDeviceForm';
import WModbusDeviceItem from './WModbusDeviceItem';

interface IProps {
  t: TranslationFunction;
}

interface IStoresProps {
  modbusDevicesList: IModbusDevice[];
  addChosenReferenceToModbusDevicesList: () => void;
}

const WModbusDevicesList = safeMobxInject<IStoresProps, IProps>(
  allStores => ({
    modbusDevicesList: allStores.addDevice.modbusDevicesList,
    addChosenReferenceToModbusDevicesList:
      allStores.addDevice.addChosenReferenceToModbusDevicesList
  }),
  ({ t, modbusDevicesList, addChosenReferenceToModbusDevicesList }) => {
    const [showForm, setShowForm] = React.useState<boolean>(
      modbusDevicesList.length <= 1
    );

    const displayForm = () => {
      setShowForm(true);
    };

    const hideForm = () => {
      setShowForm(false);
    };

    const handleAddModbusDevice = () => {
      addChosenReferenceToModbusDevicesList();
      hideForm();
    };

    return (
      <div className={'mt-3'}>
        <Row>{t('Devices:connectedRS485Devices')}</Row>

        <Row>
          <hr style={{ width: '100%' }} />
        </Row>

        {!!modbusDevicesList.length && (
          <ul className={'wmodbus-device-list'}>
            {modbusDevicesList.map((device, index) => (
              <WModbusDeviceItem key={index} device={device} />
            ))}
          </ul>
        )}

        {showForm || !modbusDevicesList.length ? (
          <WModbusDeviceForm
            onSave={handleAddModbusDevice}
            onCancel={hideForm}
            enableCancel={!!modbusDevicesList.length}
          />
        ) : (
          <div className="add-device">
            <div className="form-wrapper">
              <Row className={'add-device-button'}>
                <Button color={'primary'} onClick={displayForm}>
                  {t('Common:add')}
                </Button>
              </Row>
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default translate()(WModbusDevicesList);
