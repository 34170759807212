import { action, observable } from 'mobx';
import * as L from 'leaflet';

export default class CustomersMap {
  @observable public map: any;

  @action.bound
  public setMap(mapRef: L.Map) {
    this.map = mapRef;
  }

  @action.bound
  public deleteMap() {
    this.map.remove();
    this.map = undefined;
  }
}
