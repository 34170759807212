import React, { ReactNode } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './FieldList.scss';
import {
  addressFormatterLong,
  dateFormatter,
  getAddressLink,
  getRandomCharactersString
} from '../../../utils/tools';
import classnames from 'classnames';
import { IContact } from '@mylight/data-model/lib/customers/IContact';
import CustomTooltip from '../../Tooltip/CustomTooltip';

export const FieldList = ({ children }: { children: ReactNode }) => (
  <Container fluid className={classnames('custom-responsive-list')}>
    {children}
  </Container>
);

export const Title = ({
  text,
  addon,
  className
}: {
  text: string;
  addon?: ReactNode;
  className?: string;
}) => (
  <div>
    <Col md={12} className={`list-title ${className ? className : ''}`}>
      <span>{text}</span>
      {addon && <span>{addon}</span>}
    </Col>
  </div>
);

export const Field = ({
  text,
  name,
  addon,
  dataCy
}: {
  text?: any;
  name?: string;
  addon?: ReactNode;
  dataCy?: string;
}) =>
  name ? (
    <Row className="field" {...(dataCy ? { 'data-cy': dataCy } : {})}>
      <Col className="name" xs={12} md={6}>
        <span>{name}</span>
      </Col>
      <Col className="text" xs={12} md={6}>
        {text ? text : '-'}
        {addon && <span>{addon}</span>}
      </Col>
    </Row>
  ) : (
    <Row className="field" {...(dataCy ? { 'data-cy': dataCy } : {})}>
      <Col className="big-text" xs={12}>
        {text ? text : '-'}
        {addon && <span>{addon}</span>}
      </Col>
    </Row>
  );

export const DateField = ({
  value,
  name,
  format
}: {
  value: number | undefined;
  name: string;
  format?: string;
}) => {
  return (
    <Row className="field">
      <Col className="name" xs={12} md={6}>
        <span>{name}</span>
      </Col>
      <Col className="text" xs={12} md={6}>
        {dateFormatter(value, format)}
      </Col>
    </Row>
  );
};

export const CustomField = ({
  children,
  name,
  className
}: {
  children?: ReactNode;
  name?: string;
  className?: string;
}) =>
  name ? (
    <Row className={classnames('field', className)}>
      <Col className="name" xs={12} md={6}>
        <label>{name}</label>
      </Col>
      <Col className="text" xs={12} md={6}>
        {children}
      </Col>
    </Row>
  ) : (
    <Row className={classnames('field', className)}>
      <Col className="big-text" xs={12}>
        {children}
      </Col>
    </Row>
  );

export const FieldEmail = ({
  email,
  name
}: {
  email?: string;
  name: string;
}) => (
  <Row className="field email">
    <Col className="name" xs={12} md={6}>
      {name}
    </Col>
    <Col className="email" xs={12} md={6}>
      {email ? <a href={`mailto:${email}`}>{email}</a> : '-'}
    </Col>
  </Row>
);

export const FieldPhone = ({
  phone,
  name
}: {
  phone?: string;
  name: string;
}) => (
  <Row className="field phone">
    <Col className="name" xs={12} md={6}>
      {name}
    </Col>
    <Col className="phone" xs={12} md={6}>
      {phone ? <a href={`tel:${phone}`}>{phone}</a> : '-'}
    </Col>
  </Row>
);

export const FieldAddress = ({
  contact,
  name
}: {
  contact?: IContact;
  name: string;
}) => {
  const address = addressFormatterLong(contact);
  return (
    <Row className="field address">
      <Col className="name" xs={12} md={6}>
        {name}
      </Col>
      <Col className="address" xs={12} md={6}>
        {contact ? (
          <a
            target="_blank"
            href={getAddressLink(
              `${contact.address}, ${contact.zipCode} ${contact.city}`
            )}
          >
            {address}
          </a>
        ) : (
          address
        )}
      </Col>
    </Row>
  );
};

export const FieldWithError = ({
  text,
  isError,
  name,
  errorMsg,
  hideTextOnError = true
}: {
  text?: ReactNode;
  name?: string;
  isError?: boolean;
  errorMsg: ReactNode;
  hideTextOnError?: boolean;
}) => {
  const selector = getRandomCharactersString();
  const value =
    text && (!isError || (isError && !hideTextOnError)) ? text : '-';
  return (
    <Col md={6} className="field field-with-error">
      <div className="name">
        <div className="text-center value">{value}</div>
        <div className="text-center">
          {name}
          {isError && (
            <span className={'text-center field-error-icon'}>
              <span id={selector}>
                <FontAwesomeIcon icon={'exclamation-circle'} color={'red'} />
              </span>

              <CustomTooltip
                errorMsg={{ text: errorMsg }}
                selector={selector}
              />
            </span>
          )}
        </div>
      </div>
    </Col>
  );
};
