import React from 'react';
import logo from '../../assets/images/domuneo/logo.svg';

const LogoDomuneo = () => (
  <div className="logo-wrapper" data-testid={'logo-domuneo'}>
    <img className="logo" width="100%" src={logo} />
  </div>
);

export default LogoDomuneo;
