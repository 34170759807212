import React from 'react';
import { IDeviceIcon } from '../DeviceIcons';

const Lamp = (props: IDeviceIcon) => (
  <svg
    width="3rem"
    height="3rem"
    viewBox="0 0 20000 20000"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    color="black"
    {...props}
  >
    <g id="prefix__layer">
      <g id="prefix___1405072754160">
        <path
          style={{ fill: `${props.color}` }}
          d="M14320 11667H5825c-80 0-209-64-290-145-80-64-80-209 0-290l2767-6243c64-64 145-145 290-145h2542c145 0 209 80 290 145l3121 6162c0 64 64 64 64 145 0 241-145 370-290 370zm-8077-563h7578l-2896-5663H8721l-2478 5663z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M9927 14160c-145 0-290-145-290-290v-2413c0-145 145-290 290-290s290 145 290 290v2413c0 145-145 290-290 290z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M12131 15078H7803c-145 0-290-145-290-290 0-772 1271-1062 2478-1062s2478 354 2478 1062c-64 225-129 290-338 290zm-3620-563h2912c-290-145-788-209-1496-209-563-16-1062 129-1416 209z"
        />
      </g>
    </g>
  </svg>
);

export default Lamp;
