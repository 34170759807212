import React from 'react';
import {
  Field
} from '../../../ui/DataDisplay/FieldList/FieldList';
import { translate, TranslationFunction } from 'react-i18next';

import '../../customers/installation/battery/battery.scss';
import { safeMobxInject } from '../../../stores/storeInjectionHelpers';
import creationMySmartBattery , { CreationMySmartBattery } from '../../../stores/my_smart_battery/creationMySmartBattery';

interface IProps {
  t: TranslationFunction;
  inscriptionSummary?: boolean;
}

interface IStoresProps {
  creationMySmartBattery: CreationMySmartBattery;
}

const CardBatterySummary = safeMobxInject<IStoresProps, IProps>(
  stores => ({
    creationMySmartBattery: stores.creationMySmartBattery
  }),
  ({ t, inscriptionSummary }: IProps & IStoresProps) => {
    const {
      serialNumber,
      pdlNumber,
      energyDistributor
    } = creationMySmartBattery;

    return (
      <>
        <Field name={t('MySmartBattery:serialNumber')} text={serialNumber} />
        <Field name={t('MySmartBattery:pdlNumber')} text={pdlNumber} />
        { energyDistributor && <Field name={t('MySmartBattery:energyDistributor')} text={t(`energyDistributors.${energyDistributor.name}`)} /> }
      </>
    );
  }
);

export default translate(['MySmartBattery'])(CardBatterySummary);
