import React from 'react';
import { IDeviceIcon } from '../DeviceIcons';

const Aspirator = (props: IDeviceIcon) => (
  <svg
    width="3rem"
    height="3rem"
    viewBox="0 0 20000 20000"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    color="black"
    {...props}
  >
    <path
      d="M15961 13957c-18-154-788-198-1484-192-420-1325-1956-6163-2267-6979-129-337-520-437-1070-411-32-147-78-283-141-404-172-334-448-541-819-614-1524-301-2837 240-3413 869-290 317-401 659-312 964 169 576 1165 357 2674 25 548-121 1111-245 1588-311 31 1188-628 3025-1015 3930-1680-1051-4678-1130-5040 872 190-402 614-752 1217-823 1867-219 2824 2140 1508 3237-30 9-45 33-39 78 356 59 733 98 1169 78 56 126 90 273 272 273 221-36 187-268 273-350 1028-170 1950-776 1752-1947-71-423-333-805-710-1126 217-500 1146-2735 1092-4268 508-21 560 99 560 101 293 769 1748 5343 2216 6820-327 15-571 40-600 65-97 85-94 316-94 316s2529 296 2597 234c69-62 118-159 85-436zM9026 6741c-752 166-1880 414-2110 291-26-132 49-305 209-480 502-549 1681-973 2962-720 225 44 378 159 482 361 36 69 65 146 87 229-482 67-1041 190-1630 320z"
      fill={props.color}
    />
    <path
      d="M6129 14344c-872 0-1598-727-1598-1598 0-872 727-1598 1598-1598 872 0 1598 727 1598 1598 0 854-727 1598-1598 1598zm0-2797c-672 0-1199 527-1199 1199s527 1199 1199 1199 1199-527 1199-1199-527-1199-1199-1199z"
      fill={props.color}
    />
  </svg>
);

export default Aspirator;
