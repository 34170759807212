import gql from 'graphql-tag';
import { inject, observer } from 'mobx-react';
import { Mutation } from 'react-apollo';
import { RouteComponentProps, withRouter } from 'react-router';
import { translate } from 'react-i18next';
import React from 'react';
import { TranslationFunction } from 'i18next';
import ConfirmPopover from '../../../../ui/ConfirmPopover/ConfirmPopover';
import { IAllStores } from '../../../../stores/allStores.model';
import { TadoStore } from '../../../../stores/partnerships/tadoStore';
import { ISnackBarParams } from '../../../../stores/userFeedback/snackbarStore';

export const REMOVE_DEVICE = gql`
  mutation removeDevice($customerId: String!, $deviceId: String!) {
    removeDevice(customerId: $customerId, deviceId: $deviceId)
  }
`;

export interface IProps extends RouteComponentProps<IMatchParams> {
  tadoStore?: TadoStore;
  t: TranslationFunction;
  deviceId: string;
  target: string;
  showSnackBar?: (params: ISnackBarParams) => void;
}

interface IMatchParams {
  customerId: string;
  pvInstallationId: string;
}

const TadoRemoveDeviceButton = inject(({ tadoStore, snackBar }: IAllStores) => {
  return {
    tadoStore,
    showSnackBar: snackBar.showSnackBar
  };
})(
  observer(
    ({
      t,
      tadoStore,
      showSnackBar,
      deviceId,
      target,
      history,
      match: {
        params: { customerId, pvInstallationId }
      }
    }: IProps) => {
      const {
        confirmationWindowTarget,
        setConfirmationWindowTarget
      } = tadoStore!;
      return (
        <Mutation
          mutation={REMOVE_DEVICE}
          onCompleted={async () => {
            await setConfirmationWindowTarget(undefined);
            showSnackBar!({
              type: 'success',
              text: t('TadoPartnership:deviceRemovingSuccess')
            });
            await history.push(
              `/devices_inscription/${customerId}/partnership/${pvInstallationId}/tado/connecting`
            );
          }}
          onError={() =>
            showSnackBar!({
              type: 'error',
              text: t('TadoPartnership:deviceRemovingError')
            })
          }
        >
          {removeDevice => {
            return (
              <div>
                <i
                  id={target}
                  className="icon-trash-active device-delete"
                  onClick={() => {
                    setConfirmationWindowTarget(target);
                  }}
                />
                <ConfirmPopover
                  isOpen={confirmationWindowTarget === target}
                  target={target}
                  toggle={() => setConfirmationWindowTarget(undefined)}
                  message={t('Common:sure')}
                  onAccept={async () => {
                    await removeDevice({
                      variables: { customerId, deviceId }
                    });
                  }}
                  buttonAlign={'center'}
                />
              </div>
            );
          }}
        </Mutation>
      );
    }
  )
);

export default withRouter(
  translate(['Common', 'Partnerships', 'TadoPartnership'])(
    TadoRemoveDeviceButton
  )
);
