import React from 'react';
import { Col, Row } from 'reactstrap';
import { translate, TranslationFunction } from 'react-i18next';

interface IProps {
  t: TranslationFunction;
}
const PLCBestPracticeWarning = ({ t }: IProps) => {
  return (
    <Col>
      <Row>
        {t('Master:bestPracticeGoodRateFlow')}
        <br />
        {t('Master:com1ConnectToMultipleSocket')}
        <br />
        {t('Master:com1DistanceOtherCpl')}
      </Row>
    </Col>
  );
};

export default translate('Master')(PLCBestPracticeWarning);
