import ProUser from './proUser';
import LoginForm from './forms/login/loginForm';
import ForgotPasswordForm from './forms/forgotPassword/forgotPasswordForm';
import MainMenu from './navigation/mainMenu';
import SearchCustomer from './search/customers';
import CustomersMap from './customersMap/customersMap';
import profile from './profile';
import CreateCustomerDropdown from './dropdowns/createCustomer';
import CustomersList from './lists/customersList';
import CreateCustomer from './forms/createCustomer/createCustomerForm';

export class Store {
  public proUser = new ProUser();
  public loginForm = new LoginForm();
  public forgotPasswordForm = new ForgotPasswordForm();
  public mainMenu = new MainMenu();
  public customersMap = new CustomersMap();
  public searchCustomer = new SearchCustomer();
  public customersList = new CustomersList();
  public createCustomer = new CreateCustomer();
  public createCustomerDropdown = new CreateCustomerDropdown();
  public profile = profile;
}
