import React, { ReactNode } from 'react';
import { Button } from 'reactstrap';

import classnames from 'classnames';
import './loader.scss';

export interface IProps {
  children: ReactNode;
  colorClass?: string;
  id?: string;
  className?: string;
  title?: string;
  loading?: boolean;
  disabled?: boolean;
  done?: boolean;
  fail?: boolean;
  ref?: any;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  autoFocus?: boolean;
  style?: React.CSSProperties;
}

class LoadingButton extends React.Component<IProps> {
  public render() {
    const {
      children,
      loading,
      disabled,
      onClick,
      colorClass,
      done,
      fail,
      className,
      ref,
      id,
      title,
      style,
      autoFocus
    } = this.props;
    return (
      <Button
        type="submit"
        ref={ref}
        id={id}
        title={title}
        style={style}
        color={colorClass ? colorClass : 'primary'}
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          if (!disabled && onClick) {
            onClick(e);
          }
        }}
        disabled={disabled}
        className={classnames('btn-loader', { loading, done, fail }, className)}
        autoFocus={autoFocus}
      >
        {children}
      </Button>
    );
  }
}

export default LoadingButton;
