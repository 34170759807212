import React from 'react';
import { Col, Row } from 'reactstrap';
import './deviceItem.scss';
import { translate, TranslationFunction } from 'react-i18next';
import classnames from 'classnames';

import { RouteComponentProps, withRouter } from 'react-router';
import ListItem from '../../../ui/DataDisplay/List/ListItem';
import history from '../../../history';
import { Device } from '../../../stores/inscription/device';
import SaveInscriptionToggleDeviceVisibility from '../SaveInscriptionButton/SaveInscriptionToggleDeviceVisibility';
import ConfirmPopover from '../../../ui/ConfirmPopover/ConfirmPopover';
import AlertNotificationIcon from '../../../ui/Notifications/AlertNotificationIcon';
import { IInscriptionNavigationRouterParamProps } from '../InscriptionNavigation';
import RemoveModbusDevice from '../SaveInscriptionButton/RemoveModbusDevice';
import PlcIcon from '../../../assets/images/devices/icons/connection/PlcIcon';
import ModBusIcon from '../../../assets/images/devices/icons/connection/ModBusIcon';
import TenantLogo from '../../../assets/images/tenant/TenantLogo';
import { GMD } from '../../../stores/devices_inscription/contants';

interface IProps
  extends RouteComponentProps<IInscriptionNavigationRouterParamProps> {
  device: Device;
  t: TranslationFunction;
  isDeleted?: boolean;
  withoutActions?: boolean;
  withoutPower?: boolean;
  withoutStatusConnexion?: boolean;
}

interface IState {
  popoverOpen: boolean;
}

class DeviceItem extends React.Component<IProps, IState> {
  constructor(pp: IProps) {
    super(pp);
    this.state = { popoverOpen: false };
  }

  public render() {
    const {
      device,
      t,
      isDeleted,
      withoutActions,
      withoutStatusConnexion,
      match: {
        params: { customerId, pvInstallationId }
      }
    } = this.props;
    const {
      preConfig,
      activationCode,
      isDeviceConnected,
      isNegativePower,
      deviceType,
      getDeviceFullName,
      getDeviceFullRoomName,
      canBeDeleted,
      plcMac,
      ethMac,
      isMst,
      getIsModbus
    } = device;
    const { roomType } = preConfig;
    const path = `/devices_inscription/${customerId}/devices/${pvInstallationId}/${plcMac}/${ethMac ||
      'none'}`;
    const modbusDeviceIsDisconnected =
      getIsModbus && !device.isDeviceConnected && !device.isDeleted;

    const showPowerWarning =
      !isDeleted &&
      withoutActions &&
      !isMst &&
      !modbusDeviceIsDisconnected &&
      isNegativePower &&
      deviceType === GMD;

    const DeleteButton = canBeDeleted &&
      !withoutActions && (
        <div
          className={classnames('delete-button', isDeleted && 'deleted')}
          onClick={() => {
            this.setState({ popoverOpen: true });
          }}
        >
          <span
            className="trash"
            id={`yayhtml${(device.plcMac || '').toLowerCase()}`}
          >
            {isDeleted ? (
              <i className="icon-add" />
            ) : (
              <i className="icon-trash-active" />
            )}
          </span>
          <ConfirmPopover
            isOpen={this.state.popoverOpen}
            target={`yayhtml${(device.plcMac || '').toLowerCase()}`}
            toggle={() => {
              this.setState({ popoverOpen: false });
            }}
            message={t('Common:sure')}
            buttonAlign={'center'}
            customConfirmButton={
              device.getIsModbus ? (
                <RemoveModbusDevice device={device} />
              ) : (
                <SaveInscriptionToggleDeviceVisibility device={device} />
              )
            }
          />
        </div>
      );
    const EditButton = !isDeleted &&
      !withoutActions && (
        <div className="edit-button">
          <i className="icon-full-arrow-right" />
        </div>
      );

    const DisconnectedNotificationIcon = !withoutStatusConnexion &&
      modbusDeviceIsDisconnected && (
        <span className={'notification-icon modbus-disconnected'}>
          <AlertNotificationIcon
            alert={{
              label: t(`Devices:deviceDisconnectedError`).replace(
                '%deviceName%',
                getDeviceFullName
              )
            }}
            icon={'exclamation-circle'}
          />
        </span>
      );

    const DeviceInfo = (
      <div
        className={'device-container'}
        onClick={() => {
          if (!isDeleted && !withoutActions) {
            history.push(path);
          } else {
            this.setState({ popoverOpen: true });
          }
        }}
      >
        <Col className={classnames('device-info', { modbus: getIsModbus })}>
          <Col>
            <Row className={'device-title'}>
              {device.isMst || device.isEmulatedFromG3 ? (
                <TenantLogo />
              ) : device.getIsModbus ? (
                <ModBusIcon />
              ) : (
                <PlcIcon />
              )}
              <Col>
                <div className="device-name">
                  {!roomType && <span className="call-to-action">!</span>}
                  {getDeviceFullName}
                </div>
                {!withoutStatusConnexion && (
                  <div>
                    <span
                      className={classnames(
                        'badge',
                        'badge-pill',
                        isDeviceConnected
                          ? showPowerWarning
                            ? 'badge-warning'
                            : 'badge-success'
                          : modbusDeviceIsDisconnected
                            ? 'badge-warning'
                            : 'badge-secondary'
                      )}
                    >
                      {isDeviceConnected
                        ? showPowerWarning
                          ? t('Common:warning')
                          : t('Devices:on')
                        : modbusDeviceIsDisconnected
                          ? t('Common:warning')
                          : t('Devices:offline')}
                    </span>
                  </div>
                )}
              </Col>
            </Row>
          </Col>
          <Row>
            <Col className={'device-details'}>
              <div>
                <span className="mac">{t('Devices:mac')}:</span>
                <span>{device.ethMac || device.plcMac}</span>
              </div>
              {!getIsModbus && (
                <div>
                  <span className="activation-code">
                    {t('Devices:activationCode')}:
                  </span>
                  <span>{activationCode}</span>
                </div>
              )}
              <span className={'room-name'}>{getDeviceFullRoomName}</span>
            </Col>
          </Row>
        </Col>
        {DisconnectedNotificationIcon}
        {EditButton}
      </div>
    );

    return (
      <ListItem
        className={classnames(
          'registration-device-item',
          !withoutActions && 'clickable'
        )}
        key={device.getKey}
      >
        {DeviceInfo}
        {DeleteButton}
      </ListItem>
    );
  }
}

export default translate(['Common', 'Devices', 'RoomName'])(
  withRouter(DeviceItem)
);
