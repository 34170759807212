import React from 'react';
import { IDeviceIcon } from '../DeviceIcons';

const GameConsole = (props: IDeviceIcon) => (
  <svg
    width="3rem"
    height="3rem"
    viewBox="0 0 20000 20000"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    color="black"
    {...props}
  >
    <g id="prefix__layer">
      <g id="prefix___1405072748368">
        <path
          style={{ fill: `${props.color}` }}
          d="M6380 12459c79-753 328-1607 664-2285 273-550 709-1205 1243-1209 20-2 40-3 60-3 195 0 470 89 704 494 113 26 252 40 416 43V5621c0-138-108-249-242-249H5688c-133 0-242 111-242 249v7735c0 137 108 249 242 249h723c-67-322-79-706-32-1145zm-170-1824c-96 0-174-111-174-247V6197c0-137 78-247 174-247h116c96 0 174 111 174 247v4191c0 137-78 247-174 247h-116z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M8932 9666c-68-19-139-197-190-258-67-80-150-150-248-186-258-95-515 57-696 237-446 445-711 1101-903 1698-235 727-409 1556-275 2321 7 42 16 84 25 126 112 491 617 1151 1134 725 439-355 689-989 914-1496 48-105 94-211 141-317 24-52 47-105 75-155 43-79 70-69 162-69h2877c82 0 191-42 191 58 19 257 115 513 221 744 209 454 514 944 942 1208 483 301 881-18 1055-506 218-612 157-1327 50-1957-113-665-307-1345-641-1930-172-303-407-597-738-720-282-106-592-46-791 194-53 63-96 135-130 210-49 108-195 99-305 112-321 36-2396 94-2871-40zm233 1552h-550v567h-607v-567h-550v-626h550v-567h607v567h550v626zm3639-1038c157 0 285 131 285 293s-127 293-285 293c-157 0-285-131-285-293s127-293 285-293zm-539 1142c-157 0-285-131-285-293s127-293 285-293c157 0 285 131 285 293s-127 293-285 293zm539 556c-157 0-285-131-285-293s127-293 285-293c157 0 285 131 285 293s-127 293-285 293zm539-556c-157 0-285-131-285-293s127-293 285-293c157 0 285 131 285 293s-127 293-285 293z"
        />
      </g>
    </g>
  </svg>
);

export default GameConsole;
