import * as React from 'react';
import './RefreshIcon.scss';
import classnames from 'classnames';

const RefreshIcon = ({
  active,
  loading
}: {
  active?: boolean;
  loading?: boolean;
}) => (
  <svg
    className={classnames('svg-icons-refresh', { active })}
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 1000 1000"
  >
    <g className={classnames({ loading })}>
      <path d="M929.7,615.1L929.7,615.1c-0.9,0-1.8,0-2.6,0.1c-0.2,0-0.4,0-0.6,0c-0.8,0.1-1.6,0.2-2.4,0.3c-0.3,0-0.5,0.1-0.8,0.1c-0.6,0.1-1.2,0.2-1.8,0.4c-0.4,0.1-0.9,0.2-1.3,0.3c-0.4,0.1-0.9,0.3-1.3,0.4c-0.4,0.1-0.8,0.3-1.2,0.4c-0.2,0.1-0.3,0.1-0.5,0.2c-0.9,0.3-1.8,0.7-2.6,1.1c-0.1,0-0.1,0.1-0.2,0.1c-6.6,3.2-12,8.4-15.5,14.8c-0.1,0.1-0.2,0.3-0.2,0.4c-0.4,0.7-0.7,1.4-1,2.1c-0.2,0.4-0.3,0.8-0.5,1.1c-0.1,0.3-0.3,0.6-0.4,0.9c-0.3,0.7-0.5,1.4-0.7,2.1c0,0.1-0.1,0.2-0.1,0.3c0,0-0.1,0-0.1,0C838.2,803.3,682.7,920,500,920c-182.3,0-337.4-116.1-395.5-278.4c0,0-0.1,0-0.2,0c-3.8-15.2-17.6-26.5-34-26.5c-19.3,0-35,15.7-35,35c0,4.6,0.9,9,2.5,13C105.1,853.6,286.6,990,500,990c161.9,0,305.4-78.5,394.7-199.6v58.5c0,19.2,15.7,35,35,35l0,0c19.3,0,35-15.8,35-35V650.1C964.7,630.8,949,615.1,929.7,615.1z" />
      <path d="M71.8,381.1C71.8,381.1,71.8,381.1,71.8,381.1L71.8,381.1c0.9,0,1.7,0,2.6-0.1c0.2,0,0.4,0,0.6,0c0.8-0.1,1.6-0.2,2.4-0.3c0.3,0,0.5-0.1,0.8-0.1c0.6-0.1,1.1-0.2,1.7-0.3c0.4-0.1,0.9-0.2,1.3-0.3c0.5-0.1,0.9-0.3,1.3-0.4c0.4-0.1,0.9-0.3,1.3-0.4c0.2-0.1,0.3-0.1,0.5-0.2c0.9-0.3,1.8-0.7,2.7-1.2c0,0,0.1,0,0.1-0.1c6.6-3.2,12-8.4,15.5-14.8c0,0,0-0.1,0.1-0.1c0.4-0.8,0.8-1.6,1.2-2.5c0.1-0.2,0.2-0.4,0.3-0.6c0.2-0.4,0.3-0.8,0.5-1.2c0.2-0.6,0.4-1.2,0.6-1.8c0-0.1,0.1-0.2,0.1-0.3C164,195.1,318.6,80,500,80c181.6,0,336.3,115.3,394.9,276.7c0.3,0.8,0.6,1.7,0.9,2.5c0.1,0.3,0.2,0.7,0.4,1c0,0,0,0,0.1,0c5.4,12.3,17.7,20.8,32,20.8c19.3,0,35-15.7,35-35c0-3.8-0.6-7.5-1.8-11c0-0.2,0-0.3-0.1-0.5C893.6,145.4,712.6,10,500,10c-161,0-303.9,77.7-393.2,197.6v-60.4c0-19.3-15.7-35-35-35l0,0c-19.2,0-35,15.8-35,35v198.8c0,0,0,0,0,0C36.8,365.4,52.5,381.1,71.8,381.1z" />
    </g>
  </svg>
);

export default RefreshIcon;
