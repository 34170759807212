import React from 'react';
import { MsbBoxTypes, MSBStatus } from '../../../../../graphql/graphql.schema';
import { BatteryStatusType } from '../../../../../stores/battery.constants';
import List from '../../../../../ui/DataDisplay/List/ListWrapper';
import ListHeader from '../../../../../ui/DataDisplay/List/ListHeader';
import { Col } from 'reactstrap';
import ListItem from '../../../../../ui/DataDisplay/List/ListItem';
import doneIcon from '../../../../../assets/images/check-circle-outline.svg';
import todoIcon from '../../../../../assets/images/check-circle-outline-blank.svg';
import moment from 'moment';
import { translate, TranslationFunction } from 'react-i18next';
import { HelpButton } from '../../../../../ui/HelpButton/HelpButton';

interface IProps {
  msbStatuses: MSBStatus[];
  msbType: MsbBoxTypes | null;
  t: TranslationFunction;
}

const DATE_FORMAT = 'DD/MM/YYYY';

const DesktopMsbStatusList = ({ msbStatuses, msbType, t }: IProps) => {
  return (
    <List fluid={true} style={{ marginTop: 0 }}>
      <ListHeader>
        <Col md={1} xl={1} className="d-none d-sm-none d-md-block" />
        <Col md={4} xl={3}>
          {t('MySmartBattery:date')}
        </Col>
        <Col md={6} xl={5}>
          {t('MySmartBattery:status')}
        </Col>
        <Col md={1} xl={1} />
      </ListHeader>
      {msbStatuses.map((msbStatus, index) => {
        return (
          <ListItem key={index}>
            <Col className="name" md={1} xl={1}>
              <img
                style={{
                  marginRight: '0.75rem',
                  height: msbStatus.isValidate ? 20 : 19,
                  marginLeft: msbStatus.isValidate ? -1 : 0
                }}
                src={msbStatus.isValidate ? doneIcon : todoIcon}
                className="item-step"
                alt="status"
              />
            </Col>
            <Col className="name" md={4} xl={3}>
              <span className="field big-text black-text">
                {msbStatus.validateAt
                  ? moment(msbStatus.validateAt).format(DATE_FORMAT)
                  : '-'}
              </span>
            </Col>
            <Col className="name" md={6} xl={5}>
              <span className="field text black-text">
                {t(
                  `MySmartBattery:${msbStatus.status}_${
                    msbStatus.isValidate
                  }_${msbType}`
                )}
              </span>
            </Col>
            <Col className="name" md={1} xl={1}>
              {msbStatus.status !== BatteryStatusType.OK && (
                <HelpButton
                  message={t(
                    `MySmartBattery:${msbStatus.status}_details_${msbType}`
                  )}
                  uniqueKey={`${msbStatus.status}`}
                />
              )}
            </Col>
          </ListItem>
        );
      })}
    </List>
  );
};

export default translate()(DesktopMsbStatusList);
