import Quagga from 'quagga';

export const stopaGa = d => {
  Quagga.stop();
};
export const read = (type = 'UCG', d) => {
  Quagga.init(
    {
      inputStream: {
        name: 'Live',
        type: 'LiveStream',
        target: document.querySelector('#cont') // Or '#yourElement' (optional)
      },
      locator: {
        patchSize: 'large',
        halfSample: false
      },
      numOfWorkers: 2,
      frequency: 10,
      decoder: {
        readers: ['code_128_reader', 'code_39_reader'],
        multiple: false
      },
      locate: true
    },
    err => {
      if (err) {
        console.log(err);
        return;
      }
      Quagga.start();
      if (Quagga.CameraAccess.getActiveTrack()) {
        const track = Quagga.CameraAccess.getActiveTrack();
        let capabilities = track.getCapabilities();
        let zoom = 1;
        if (capabilities.zoom) {
          zoom = capabilities.zoom.max / 3;
          if (capabilities.zoom.max > 10) {
            zoom = zoom * 2;
          }
        }
        track
          .applyConstraints({ advanced: [{ zoom }] })
          .catch(e => console.log(e));
      }
    }
  );
  Quagga.onProcessed(function(result) {
    var drawingCtx = Quagga.canvas.ctx.overlay,
      drawingCanvas = Quagga.canvas.dom.overlay;

    if (result) {
      if (result.boxes) {
        drawingCtx.clearRect(
          0,
          0,
          parseInt(drawingCanvas.getAttribute('width')),
          parseInt(drawingCanvas.getAttribute('height'))
        );
        result.boxes
          .filter(function(box) {
            return box !== result.box;
          })
          .forEach(function(box) {
            Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, {
              color: '#009dbf',
              lineWidth: 2
            });
          });
      }

      if (result.box) {
        Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, {
          color: '#e7e7f2',
          lineWidth: 2
        });
      }

      if (result.codeResult && result.codeResult.code) {
        Quagga.ImageDebug.drawPath(
          result.line,
          { x: 'x', y: 'y' },
          drawingCtx,
          { color: 'red', lineWidth: 3 }
        );
      }
    }
  });
  let lastResult = null;

  Quagga.onDetected(function(result) {
    var code = result.codeResult.code;

    if (lastResult !== code) {
      lastResult = code;
      d(code);
      // var $node = null, canvas = Quagga.canvas.dom.image;
      //
      // $node = $('<li><div class="thumbnail"><div class="imgWrapper"><img /></div><div class="caption"><h4 class="code"></h4></div></div></li>');
      // $node.find("img").attr("src", canvas.toDataURL());
      // $node.find("h4.code").html(code);
      // $("#result_strip ul.thumbnails").prepend($node);
    }
  });
};
