import React, { ReactNode } from 'react';

import { TranslationFunction } from 'i18next';
import { translate } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import { inject, observer } from 'mobx-react';
import { Store } from '../../stores';
import Spinner from '../Spinner/Spinner';
import { Button, Container, InputGroup, Row } from 'reactstrap';
import './isolatedComponent.scss';
import history from '../../history';
import Col from 'reactstrap/lib/Col';
import classnames from 'classnames';

interface IProps extends RouteComponentProps<any> {
  t: TranslationFunction;
  Store?: Store;
  children: ReactNode;
  title?: string | ReactNode;
  subtitle?: string | ReactNode;
  onGoBack?: () => void;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
}

@inject('Store')
@observer
class IsolatedComponent extends React.Component<IProps> {
  public static defaultProps = {
    md: 10,
    xl: 7
  };
  public render() {
    const {
      Store: mobix,
      t,
      match: {
        params: { customerId, pvInstallationId }
      },
      children,
      title,
      subtitle,
      xs,
      sm,
      md,
      lg,
      xl,
      onGoBack = () => history.goBack()
    } = this.props;

    return (
      <Spinner loading={false}>
        <Container fluid className={'isolated'}>
          <Row className={'isolated-header'}>
            <div className={'go-back'}>
              <i className="icon-full-arrow-right" onClick={() => onGoBack()} />
            </div>
            <div className={'header-content'}>
              <span className={'header-title'}>{title || ''}</span>
              {subtitle && (
                <span className={'header-subtitle'}>{subtitle}</span>
              )}
            </div>
          </Row>
          <Row className={'isolated-content'}>
            <Col
              xs={xs}
              sm={sm}
              md={md}
              lg={lg}
              xl={xl}
              className={'isolated-content-container'}
            >
              {children}
            </Col>
          </Row>
        </Container>
      </Spinner>
    );
  }
}

export default translate(['Partnerships'])(withRouter(IsolatedComponent));
