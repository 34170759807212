import { ApolloError } from 'apollo-client';
import gql from 'graphql-tag';
import React from 'react';
import { QueryResult } from 'react-apollo';
import Query from 'react-apollo/Query';
import { translate, TranslationFunction } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import { Button, Col, Form, Row } from 'reactstrap';
import { ModBusReference } from '../../../../../graphql/graphql.schema';
import { IModbusDevice } from '../../../../../stores/inscription/addDeviceStore';
import { safeMobxInject } from '../../../../../stores/storeInjectionHelpers';
import i18n from '../../../../../translations/i18n';
import IsolatedComponent from '../../../../../ui/IsolatedComponent/IsolatedComponent';
import Spinner from '../../../../../ui/Spinner/Spinner';
import { IInscriptionNavigationRouterParamProps } from '../../../InscriptionNavigation';
import '../addDevice.scss';
import AddWModbusPartDeviceButton from './AddWModbusPartDeviceButton';
import WModbusDevicesList from './WModbusDevicesList';
import WModbusPartSerialInput from './WModbusPartSerialInput';

const GET_MODBUS_REFERENCES = gql`
  query getAvailableMSTModbusAddress(
    $pvInstallationId: String!
    $lang: String
  ) {
    getAvailableMSTModbusAddress(
      pvInstallationId: $pvInstallationId
      lang: $lang
    ) {
      references {
        reference
        availableAddress
        phaseCount
        defaultAddress
      }
      error
    }
  }
`;

interface IProps
  extends RouteComponentProps<IInscriptionNavigationRouterParamProps> {
  t: TranslationFunction;
}

interface IStoresProps {
  setPossibleReferences: (references: ModBusReference[]) => void;
  modbusDevicesListItems: IModbusDevice[];
  modbusDevicesListLength: number;
  resetAddDeviceStore: () => void;
  initErrorsFromGQLError: (error: ApolloError) => void;
}

const AddWModbusPartDevice = safeMobxInject<IStoresProps, IProps>(
  allStores => ({
    setPossibleReferences: allStores.addDevice.setPossibleReferences,
    modbusDevicesListItems: allStores.addDevice.modbusDevicesListItems,
    modbusDevicesListLength: allStores.addDevice.modbusDevicesListLength,
    resetAddDeviceStore: allStores.addDevice.resetAddDeviceStore,
    initErrorsFromGQLError: allStores.inscriptionError.initErrorsFromApolloError
  }),
  ({
    t,
    history,
    match: {
      params: { customerId, pvInstallationId }
    },
    setPossibleReferences,
    modbusDevicesListItems,
    modbusDevicesListLength,
    resetAddDeviceStore,
    initErrorsFromGQLError
  }) => {
    const [modbusPartSerial, setModbusPartSerial] = React.useState<string>('');

    const goBack = () =>
      history.push(
        `/devices_inscription/${customerId}/devices/${pvInstallationId}/add/wmodbus`
      );

    React.useEffect(() => {
      resetAddDeviceStore();
    }, []);

    return (
      <Query
        query={GET_MODBUS_REFERENCES}
        variables={{ pvInstallationId, lang: i18n.language }}
        fetchPolicy={'no-cache'}
        onCompleted={data => {
          setPossibleReferences(data.getAvailableMSTModbusAddress.references);
        }}
        onError={e => initErrorsFromGQLError!(e)}
      >
        {({ loading }: QueryResult) => (
          <IsolatedComponent
            title={t('DeviceType:modbus_part')}
            subtitle={t('Devices:addDeviceTitleWModbusPart')}
            sm={10}
            md={9}
            lg={6}
            xl={5}
            onGoBack={goBack}
          >
            <Spinner loading={loading}>
              <Col className={'add-device'}>
                <Form className={'form-wrapper'}>
                  <WModbusPartSerialInput onChange={setModbusPartSerial} />

                  <WModbusDevicesList />

                  <Row>
                    <hr style={{ width: '100%' }} />
                  </Row>

                  <Row className={'add-device-button'}>
                    <Button color={'secondary'} onClick={goBack}>
                      {t('Common:cancel')}
                    </Button>

                    <AddWModbusPartDeviceButton
                      disabled={!modbusPartSerial || !modbusDevicesListLength}
                      modbusPartSerial={modbusPartSerial}
                      modbusDevices={modbusDevicesListItems}
                    />
                  </Row>
                </Form>
              </Col>
            </Spinner>
          </IsolatedComponent>
        )}
      </Query>
    );
  }
);

export default translate()(withRouter(AddWModbusPartDevice));
