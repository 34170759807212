import React from 'react';
import { ISortingRule } from '../../../utils/tools';
import classnames from 'classnames';

interface IProps {
  sortingRule: ISortingRule;
  sortingKey: string;
}

const SortNotificator = (props: IProps) => {
  const { sortingRule, sortingKey } = props;

  return (
    <i
      className={classnames('icon-sort', {
        down: sortingRule.paramName === sortingKey && sortingRule.reverse,
        up: sortingRule.paramName === sortingKey && !sortingRule.reverse
      })}
    />
  );
};

export default SortNotificator;
