import React, { useState } from 'react';
import { FormInput } from '../../ui/FormInput/FormInput';
import { HelpButton } from '../../ui/HelpButton/HelpButton';
import { convertTranslation } from '../../utils/convertTranslations';
import { t } from 'i18next';
import { ListTitle } from '../../ui/DataDisplay/List/ListHeader';
import LoadingButton from '../../ui/Button/LoadingButton/LoadingButton';
import { safeMobxInject } from '../../stores/storeInjectionHelpers';
import { IAllStores } from '../../stores/allStores.model';
import { translate } from 'react-i18next';
import gql from 'graphql-tag';
import { withApollo, WithApolloClient } from 'react-apollo';
import {
  getErrorsMessageGraphQl,
  IErrorMessageGraphql
} from '../../utils/graphQl';
import { PPDevice } from '../../graphql/graphql.schema';
import { Col, Form, Row } from 'reactstrap';
import { isMobileDevice } from '../../utils/tools';
import masterActivationCodeLocationImage from '../../assets/images/master/master-ac-location.png';

function isActivationCode(masterKey: string) {
  return masterKey.match('^([A-Za-z0-9]{4}[-]){2}[A-Za-z0-9]{4}$');
}

const GET_PAIRED_DEVICES_FROM_MAC = gql`
  query getPairedDevicesFromMAC($masterKey: String!) {
    getPairedDevicesByMAC(mac: $masterKey) {
      id
      firmware
      status
      masterMac
      activationCode
      emulatedFromG3
      power
      state
      plcMac
      ethMac
      deviceTypeOverride
      deviceType
      preConfig {
        compositeId
        greenPlayEnabled
        controlledDeviceType
        controlledDeviceName
        phase
        isDeleted
      }
    }
  }
`;

const GET_PAIRED_DEVICES_FROM_ACTIVATION_CODE = gql`
  query getPairedDevicesByActivationCode($masterKey: String!) {
    getPairedDevicesByActivationCode(activationCode: $masterKey) {
      id
      firmware
      status
      masterMac
      activationCode
      emulatedFromG3
      power
      state
      plcMac
      ethMac
      deviceTypeOverride
      deviceType
      preConfig {
        compositeId
        greenPlayEnabled
        controlledDeviceType
        controlledDeviceName
        phase
        isDeleted
      }
    }
  }
`;

interface IStoreProps {
  masterKeyInvalid: boolean;
  masterKeyEmpty: boolean;
  masterKey: string;
  isLoading: boolean;
  setMasterKey: (masterKey: string) => void;
  setIsLoading: (isloading: boolean) => void;
  setPairedDevices: (pairedDevices: PPDevice[]) => void;
  resetErrors: () => void;
  startSearchPairing: () => void;
  displaySearchPairingErrors: (errors: IErrorMessageGraphql[]) => void;
}

const SearchPairingForm = safeMobxInject<IStoreProps, {}>(
  ({ searchPairingStore }: IAllStores) => {
    return {
      masterKeyInvalid: searchPairingStore.masterKeyInvalid,
      masterKeyEmpty: searchPairingStore.masterKeyEmpty,
      masterKey: searchPairingStore.masterKey,
      isLoading: searchPairingStore.isLoading,
      setMasterKey: searchPairingStore.setMasterKey,
      setIsLoading: searchPairingStore.setIsLoading,
      setPairedDevices: searchPairingStore.setPairedDevices,
      resetErrors: searchPairingStore.resetErrors,
      startSearchPairing: searchPairingStore.startSearchPairing,
      displaySearchPairingErrors: searchPairingStore.displaySearchPairingErrors
    };
  },
  (props: WithApolloClient<IStoreProps>) => {
    const {
      masterKeyInvalid,
      masterKeyEmpty,
      masterKey,
      isLoading,
      setMasterKey,
      setIsLoading,
      setPairedDevices,
      resetErrors,
      startSearchPairing,
      displaySearchPairingErrors,
      client
    } = props;

    const [isPowerUpToDate, setPowerUpToDate] = useState(false);

    const masterHelpButtonMessage = (
      <Col>
        {convertTranslation(
          isMobileDevice()
            ? t('Master:masterHelpMobile')
            : t('Master:masterHelp')
        )}
        <Row className="pt-3 pb-2">
          <img
            style={{
              margin: 'auto',
              width: '20rem',
              height: '20rem'
            }}
            src={masterActivationCodeLocationImage}
            alt={t('Master:masterHelp')}
          />
        </Row>
      </Col>
    );

    return (
      <Form
        onSubmit={event => {
          event.preventDefault();
          startSearchPairing();
          client
            .query({
              query: isActivationCode(masterKey)
                ? GET_PAIRED_DEVICES_FROM_ACTIVATION_CODE
                : GET_PAIRED_DEVICES_FROM_MAC,
              variables: {
                masterKey
              },
              fetchPolicy: 'no-cache'
            })
            .then(({ data }) => {
              setPowerUpToDate(true);
              const pairedDevices = isActivationCode(masterKey)
                ? data.getPairedDevicesByActivationCode
                : data.getPairedDevicesByMAC;
              setPairedDevices(pairedDevices);
            })
            .catch(error => {
              const errors = getErrorsMessageGraphQl(error.graphQLErrors);
              displaySearchPairingErrors(errors);
            })
            .finally(() => {
              setIsLoading(false);
            });
        }}
      >
        <ListTitle text={t('ToolsPairing:testPairing')} />
        <FormInput
          id="master"
          name={t('Master:master')}
          onChange={value => {
            resetErrors();
            setMasterKey(value);
            setPowerUpToDate(false);
          }}
          error={!masterKeyEmpty && masterKeyInvalid && t(`Master:masterError`)}
          addon={
            <HelpButton
              uniqueKey="masterHelp"
              message={masterHelpButtonMessage}
            />
          }
          value={masterKey}
        />
        <div className="text-right">
          <LoadingButton
            id="sendMaster"
            loading={isLoading}
            disabled={masterKeyInvalid}
          >
            {isPowerUpToDate
              ? t('ToolsPairing:refreshPower')
              : t('Common:connect')}
          </LoadingButton>
        </div>
      </Form>
    );
  }
);
export default translate()(withApollo(SearchPairingForm));
