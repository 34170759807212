import React from 'react';
import { IDeviceIcon } from '../DeviceIcons';

const WashingDryingMachine = (props: IDeviceIcon) => (
  <svg
    xmlSpace="preserve"
    width="3rem"
    height="3rem"
    viewBox="0 0 20000 20000"
    id="prefix__svg2"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    color="black"
    {...props}
  >
    <defs id="prefix__defs4">
      <style type="text/css" id="style6">
        {`.prefix__fil0{fill:${props.color}`}
      </style>
    </defs>
    <g id="prefix__Layer_x0020_1" fill={props.color} fillOpacity={1}>
      <g id="prefix__washing_drying_machine" fill={props.color} fillOpacity={1}>
        <path
          style={{ fill: `${props.color}` }}
          d="M13878 17977H6210c-152 0-266-101-266-266V9992c0-152 101-266 266-266h7668c152 0 266 101 266 266v7668c0 51-51 152-101 202-13 63-63 114-165 114zm-7403-582h7137v-7086H6475v7086z"
          id="prefix__path11"
          fill={props.color}
          fillOpacity={1}
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M7614 11296c0 114-89 215-202 215-114 0-215-89-215-215 0-114 89-202 215-202 114 0 202 89 202 202z"
          id="prefix__path13"
          fill={props.color}
          fillOpacity={1}
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M8981 11296c0 114-89 215-202 215-114 0-202-89-202-215 0-114 89-202 202-202 101 0 202 89 202 202z"
          id="prefix__path15"
          fill={props.color}
          fillOpacity={1}
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M12941 11296c0 114-89 215-202 215-114 0-202-89-202-215 0-114 89-202 202-202 101 0 202 89 202 202z"
          id="prefix__path17"
          fill={props.color}
          fillOpacity={1}
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M10120 16927c-1354 0-2404-1101-2404-2404s1101-2404 2404-2404c1354 0 2404 1101 2404 2404 0 1316-1050 2404-2404 2404zm0-4277c-1050 0-1873 835-1873 1873 0 1050 835 1873 1873 1873s1873-835 1873-1873c13-1038-823-1873-1873-1873z"
          id="prefix__path19"
          fill={props.color}
          fillOpacity={1}
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M10120 15990c-835 0-1506-683-1506-1519 0-835 683-1506 1506-1506 835 0 1506 683 1506 1506 13 835-671 1519-1506 1519zm0-2455c-266 0-468 101-683 253-152 165-253 430-253 696 0 519 418 936 936 936 519 0 936-418 936-936 0-531-418-949-936-949z"
          id="prefix__path21"
          fill={props.color}
          fillOpacity={1}
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M13882 10261H6205c-157 0-262-105-262-262V2283c0-157 105-262 262-262h7677c157 0 262 105 262 262v7729c0 92-105 248-262 248zm-7415-575h7154V2598H6467v7088z"
          id="prefix__path23"
          fill={props.color}
          fillOpacity={1}
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M7618 3578c0 118-92 209-209 209-118 0-209-92-209-209 0-118 92-209 209-209 118 0 209 92 209 209z"
          id="prefix__path25"
          fill={props.color}
          fillOpacity={1}
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M8925 3578c0 118-92 209-209 209-118 0-209-92-209-209 0-118 92-209 209-209 105 0 209 92 209 209z"
          id="prefix__path27"
          fill={props.color}
          fillOpacity={1}
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M12940 3578c0 118-92 209-209 209-118 0-209-92-209-209 0-118 92-209 209-209 118 0 209 92 209 209z"
          id="prefix__path29"
          fill={props.color}
          fillOpacity={1}
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M12574 8953H7460c-157 0-262-105-262-262V4833c0-157 105-262 262-262h5114c157 0 262 105 262 262v3858c0 157-105 262-262 262zm-4800-575h4538V5095H7774v3283z"
          id="prefix__path31"
          fill={props.color}
          fillOpacity={1}
        />
      </g>
    </g>
  </svg>
);

export default WashingDryingMachine;
