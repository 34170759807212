import * as React from 'react';
import CustomerSupport from './CustomerSupport';
import TechnicalSupport from './TechnicalSupport';
import { Container } from 'reactstrap';
import ToolsTestPairingLink from './ToolsTestPairingLink';
import { safeMobxInject } from '../../stores/storeInjectionHelpers';
import TechnicalDocumentation from './TechnicalDocumentation';
import './supportView.scss';

interface IStoreProps {
  hasRightPairingToolkit: boolean;
}

const SupportView = safeMobxInject<{}, IStoreProps>(
  stores => ({
    hasRightPairingToolkit: stores.Store.profile.hasRightPairingToolkit
  }),
  ({ hasRightPairingToolkit }: IStoreProps) => (
    <Container fluid className="support-view">
      {hasRightPairingToolkit && <ToolsTestPairingLink />}
      <TechnicalDocumentation />
      <CustomerSupport />
      <TechnicalSupport />
    </Container>
  )
);

export default SupportView;
