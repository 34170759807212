import { ListTitle } from '../../../../../ui/DataDisplay/List/ListHeader';
import { Button } from 'reactstrap';
import React from 'react';
import { translate, TranslationFunction } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import CardDescriptionInscriptionMySmartBattery from './CardDescriptionInscriptionMySmartBattery';
import { PAGE_COME_FROM } from '../creation/MsbCreationWindow';

interface IProps extends RouteComponentProps<IMatchParams> {
  t: TranslationFunction;
}

interface IMatchParams {
  id?: string;
}

const MsbAddView = ({
  t,
  history,
  match: {
    params: { id }
  }
}: IProps) => {
  return (
    <>
      <ListTitle
        text={t('battery')}
        children={
          <Button
            color={'primary'}
            onClick={() => {
              history.push(`/customers/${id}/battery`, {
                from: PAGE_COME_FROM.MY_SMART_BATTERY_ACTIVATION
              });
            }}
          >
            {t('Common:add')}
          </Button>
        }
      />
      <div className="col">
        <CardDescriptionInscriptionMySmartBattery />
      </div>
    </>
  );
};

export default withRouter(translate(['MySmartBattery'])(MsbAddView));
