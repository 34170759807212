import Spinner from '../../../ui/Spinner/Spinner';
import React from 'react';
import './finishInstallation.scss';
import { TranslationFunction } from 'i18next';
import { translate } from 'react-i18next';

interface IProps {
  t: TranslationFunction;
}

const SpinnerFinalisationInscription = ({ t }: IProps) => {
  return (
    <div className={'container--waiting-finalisation-inscription'}>
      <div className={'modal--waiting-finalisation-inscription'}>
        <p className={'text--waiting-finalisation-inscription'}>
          {t('DevicesInscription:waitingFinishInscription')}
        </p>
        <Spinner loading={true} />
      </div>
    </div>
  );
};

export default translate()(SpinnerFinalisationInscription);
