import * as React from 'react';
import { Customer } from '../../../graphql/graphql.schema';
import { translate, TranslationFunction } from 'react-i18next';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import SelectInput from '../../../ui/Select/Select';

const GET_PANELMANUFACTURER = gql`
  query distinctPanelManufacturer {
    distinctPanelManufacturer
  }
`;

const PanelManufacturerSelector = ({
  t,
  value,
  onChange,
  required
}: {
  onChange: (a: string) => void;
  t: TranslationFunction;
  value: string;
  required?: boolean;
}) => (
  <Query query={GET_PANELMANUFACTURER}>
    {({ loading, data }) => {
      return (
        <SelectInput
          required
          id="panelManufacturer"
          name={t('panelManufacturer')}
          value={{ value, label: value }}
          loading={loading}
          options={
            data && data.distinctPanelManufacturer
              ? data.distinctPanelManufacturer.map((r: string) => ({
                  value: r,
                  label: r
                }))
              : []
          }
          onChange={selectValue => {
            if (selectValue) {
              onChange(selectValue.value);
            }
          }}
        />
      );
    }}
  </Query>
);

export default translate(['Customer', 'Installation'])(
  PanelManufacturerSelector
);
