import * as React from 'react';
import './list.scss';
import { Row } from 'reactstrap';

interface IProps {
  text?: string;
}

const ListPlaceHolder = ({ text }: IProps) => (
  <Row className="list-placeholder" xs={12} d={12}>
    {text}
  </Row>
);

export default ListPlaceHolder;
