import React from 'react';
import { Store } from '../../../stores';
import DevicesView from './DevicesView';

export interface IProps {
  Store: Store;
}

const DevicesQuery = ({  }: IProps) => {
  return <DevicesView />;
};

export default DevicesQuery;
