import './MsbInput.scss';
import { Col, FormGroup, Label } from 'reactstrap';
import React, { ReactNode } from 'react';
import classnames from 'classnames';

interface IProps {
  htmlFor?: string;
  label?: string;
  required?: boolean;
  children: ReactNode;
}

export const MsbInlineForm = (formProps: IProps) => {
  const { htmlFor, label, required, children } = formProps;

  return (
    <div
      className={classnames('msb-activation-form-group')}
      data-cy={`${htmlFor}_field`}
    >
      <FormGroup row>
        <Label
          className="msb-activation-form-group__label"
          sm={12}
          md={6}
          htmlFor={htmlFor}
        >
          {label} {required && ' *'}
        </Label>

        <Col sm={12} md={6}>
          {children}
        </Col>
      </FormGroup>
    </div>
  );
};
