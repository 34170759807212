import { default as React } from 'react';

export const IconWarning = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    data-cy={'warning'}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.4 7.99997C14.4 11.5346 11.5346 14.4 7.99998 14.4C4.46535 14.4 1.59998 11.5346 1.59998 7.99997C1.59998 4.46535 4.46535 1.59998 7.99998 1.59998C11.5346 1.59998 14.4 4.46535 14.4 7.99997ZM8.79998 11.2C8.79998 11.6418 8.4418 12 7.99998 12C7.55815 12 7.19998 11.6418 7.19998 11.2C7.19998 10.7581 7.55815 10.4 7.99998 10.4C8.4418 10.4 8.79998 10.7581 8.79998 11.2ZM7.99998 3.99997C7.55815 3.99997 7.19998 4.35815 7.19998 4.79997V7.99997C7.19998 8.4418 7.55815 8.79997 7.99998 8.79997C8.4418 8.79997 8.79998 8.4418 8.79998 7.99997V4.79997C8.79998 4.35815 8.4418 3.99997 7.99998 3.99997Z"
      fill="#FF525A"
    />
  </svg>
);
