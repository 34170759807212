import gql from 'graphql-tag';
import { inject, observer } from 'mobx-react';
import { Mutation, MutationResult, Query, QueryResult } from 'react-apollo';
import { RouteComponentProps, withRouter } from 'react-router';
import { translate } from 'react-i18next';
import React from 'react';
import { TranslationFunction } from 'i18next';
import LoadingButton from '../../../../ui/Button/LoadingButton/LoadingButton';
import { Device } from '../../../../graphql/graphql.schema';
import { IAllStores } from '../../../../stores/allStores.model';
import { TadoStore } from '../../../../stores/partnerships/tadoStore';
import { ISnackBarParams } from '../../../../stores/userFeedback/snackbarStore';

export const SAVE_TADO_DEVICES = gql`
  mutation saveTadoDevices($customerId: String!, $devices: [EPPTadoDevice]) {
    saveTadoDevices(customerId: $customerId, devices: $devices)
  }
`;
export const GET_CUSTOMER_DEVICES = gql`
  query getCustomerDevices($customerId: String!) {
    devices(customerId: $customerId) {
      macSerialNumber
      deviceTypeId
      type
    }
  }
`;

export interface IProps extends RouteComponentProps<IMatchParams> {
  tadoStore?: TadoStore;
  t: TranslationFunction;
  showSnackBar?: (params: ISnackBarParams) => void;
}

interface IMatchParams {
  customerId: string;
  pvInstallationId: string;
}

interface IMutation {
  saveTadoDevices?: boolean;
  devices?: Device[];
}

interface IResponse {
  devices: Device[];
  getTadoHomeIdByCustomerId: string;
  isTadoAccessGranted: boolean;
}

const TadoSaveDevicesButton = inject(({ tadoStore, snackBar }: IAllStores) => {
  return {
    tadoStore,
    showSnackBar: snackBar.showSnackBar
  };
})(
  observer(
    ({
      tadoStore,
      showSnackBar,
      match: {
        params: { customerId }
      },
      t
    }: IProps) => {
      const {
        tadoAccessGranted,
        editedDevice,
        setAllDevices,
        ignoreTadoDevices
      } = tadoStore!;

      return (
        <Mutation mutation={SAVE_TADO_DEVICES}>
          {(saveTadoDevices, { loading, data }: MutationResult<IMutation>) => {
            return (
              <Query
                query={GET_CUSTOMER_DEVICES}
                variables={{ customerId }}
                fetchPolicy={'network-only'}
                errorPolicy={'all'}
                skip={!data}
                onCompleted={(response: IResponse) => {
                  setAllDevices(response && response.devices);
                  ignoreTadoDevices(tadoStore!.unsavedTadoDevices);
                  showSnackBar!({
                    type: 'success',
                    text: t('TadoPartnership:devicesAddingSuccess')
                  });
                }}
                onError={() => {
                  showSnackBar!({
                    type: 'error',
                    text: t('TadoPartnership:devicesAddingError')
                  });
                }}
              >
                {({
                  loading: queryLoading,
                  data: queryData
                }: QueryResult<IResponse>) => {
                  return (
                    <LoadingButton
                      className="update"
                      onClick={() => {
                        saveTadoDevices({
                          variables: {
                            customerId,
                            devices: tadoStore!.tadoDevicesToSave
                          }
                        });
                      }}
                      disabled={editedDevice !== '' || !tadoAccessGranted}
                      loading={loading || queryLoading}
                      done={
                        data &&
                        data.saveTadoDevices &&
                        queryData &&
                        queryData.devices &&
                        queryData.devices.length > 0
                      }
                    >
                      {t('TadoPartnership:validate')}
                    </LoadingButton>
                  );
                }}
              </Query>
            );
          }}
        </Mutation>
      );
    }
  )
);

export default withRouter(
  translate(['TadoPartnership'])(TadoSaveDevicesButton)
);
