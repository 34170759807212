import { ListTitle } from '../../../../../ui/DataDisplay/List/ListHeader';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { translate, TranslationFunction } from 'react-i18next';
import { safeMobxInject } from '../../../../../stores/storeInjectionHelpers';
import BatterySummaryPlaneteOui from '../info/BatterySummaryPlaneteOui';

interface IProps extends RouteComponentProps<IMatchParams> {
  t: TranslationFunction;
}

interface IStoresProps {
  canEditMSB: boolean;
}
interface IMatchParams {
  id?: string;
}

const MsbActivationPlaneteOuiView = safeMobxInject<IStoresProps, IProps>(
  stores => ({
    canEditMSB: stores.mySmartBattery.canEditMSB
  }),
  ({
    t,
  }: IProps & IStoresProps) => {
    return (
      <>
        <ListTitle text={t('battery')} />
        <BatterySummaryPlaneteOui />
      </>
    );
  }
);

export default withRouter(
  translate(['MySmartBattery'])(MsbActivationPlaneteOuiView)
);
