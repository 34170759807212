import React from 'react';
import { IDeviceIcon } from '../DeviceIcons';

const Printer = (props: IDeviceIcon) => (
  <svg
    width="3rem"
    height="3rem"
    viewBox="0 0 20000 20000"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    color="black"
    {...props}
  >
    <defs>
      <style>
        {`.prefix__str0{stroke:${
          props.color
        };stroke-width:20}.prefix__fil0{fill:${props.color}}`}
      </style>
    </defs>
    <g id="prefix__Layer_x0020_1">
      <g id="prefix__printer">
        <path
          style={{ fill: `${props.color}` }}
          d="M15102 12927H5054c-218 0-363-145-363-363V7758c0-218 145-363 363-363h10048c218 0 363 145 363 363v4806c0 218-145 363-363 363zm-9685-653h9322V8121H5417v4153z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M13324 9101H6886c-218 0-363-145-363-363s145-363 363-363h6348c218 0 363 145 363 363 18 200-127 363-272 363zM13324 11749H6886c-218 0-363-145-363-363s145-381 363-381h6348c218 0 363 145 363 381 18 218-127 363-272 363zM10375 10009c0 200-163 363-363 363s-363-163-363-363 163-363 363-363 363 163 363 363z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M12962 11458c0-218-145-363-363-363H7557c-218 0-363 145-363 363v4516c0 218 145 363 363 363h5024c218 0 363-145 363-363v-4516h18zm-671 4208H7920v-3845h4371v3845zM12581 9010H7630c-218 0-363-145-363-363V4802c0-218 145-381 363-381h3627c73 0 145 0 218 73l1415 1397c73 73 73 145 73 218v2448c0 308-163 453-381 453zm-4661-653h4280V6435l-1179-1179H7920v3101z"
        />
        <path
          className="prefix__fil0 prefix__str0"
          d="M8506 13851h3182c89 114 112 236 0 371H8506c-86-124-79-247 0-371zM8506 14445h3182c89 114 112 236 0 371H8506c-86-124-79-247 0-371zM8506 15032h3182c89 114 112 236 0 371H8506c-86-124-79-247 0-371z"
        />
      </g>
    </g>
  </svg>
);

export default Printer;
