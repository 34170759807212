import React, { useEffect } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { getUCGPath } from '../routingHelper';
import List from '../../../ui/DataDisplay/List/ListWrapper';
import ListItem from '../../../ui/DataDisplay/List/ListItem';
import classnames from 'classnames';
import { ListTitle } from '../../../ui/DataDisplay/List/ListHeader';
import './partnerships.scss';
import history from '../../../history';
import { TranslationFunction } from 'i18next';
import { translate } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import Spinner from '../../../ui/Spinner/Spinner';
import { IThirdParty } from '../../../stores/inscription/inscriptionStore';
import { withApollo, WithApolloClient } from 'react-apollo';
import gql from 'graphql-tag';
import { IInscriptionNavigationRouterParamProps } from '../InscriptionNavigation';
import { useScrollToInscriptionStep } from '../../../utils/hooks';
import { safeMobxInject } from '../../../stores/storeInjectionHelpers';
import { InscriptionStep } from '../../../stores/inscription/inscriptionStepsStore';
import ShowAlert from '../../../ui/ShowAlert/ShowAlert';
import { StepStatus } from '../../../ui/Stepper/Stepper';

interface IProps
  extends RouteComponentProps<IInscriptionNavigationRouterParamProps> {
  t: TranslationFunction;
  data?: any;
  loading?: boolean;
  error?: any;
}

interface IStoresProps {
  tadoStatus: string;
  enphaseStatus: string;
  partnershipStep: string;
  setPartnershipStep: (step: StepStatus) => void;
}
export const SAVE_INSCRIPTION_STEP_QUERY = gql`
  mutation setStepDone(
    $pvInstallationId: String!
    $registrationSteps: ERegistrationSteps!
  ) {
    updateRegistrationStep(
      pvInstallationId: $pvInstallationId
      registrationSteps: $registrationSteps
    ) {
      id
      msb
      partnership
    }
  }
`;

const PartnershipsListView = safeMobxInject<IStoresProps, IProps>(
  stores => ({
    tadoStatus: stores.tadoStore.status,
    enphaseStatus: stores.enphaseStore.status,
    partnershipStep: stores.inscriptionSteps.partnershipStep,
    setPartnershipStep: stores.inscriptionSteps.setPartnershipStep
  }),
  ({
    loading,
    tadoStatus,
    enphaseStatus,
    partnershipStep,
    t,
    client,
    match: {
      params: { customerId, pvInstallationId }
    },
    setPartnershipStep
  }: WithApolloClient<IProps> & IStoresProps) => {
    useScrollToInscriptionStep(InscriptionStep.PARTNERS);

    useEffect(() => {
      if (connectingInProgress) {
        updatePartnershipRegistrationStatus('todo');
      }
      return () => {
        if (!connectingInProgress) {
          updatePartnershipRegistrationStatus('done');
        }
      };
    }, []);

    const partnershipsList: IThirdParty[] = [
      {
        id: 'tado',
        name: 'Tado',
        status: tadoStatus,
        routerLink: 'tado/connecting'
      },
      {
        id: 'enphase',
        name: 'Enphase',
        status: enphaseStatus,
        routerLink: 'enphase/connecting'
      }
    ];

    const connectingInProgress = partnershipsList.some(
      p => p.status === 'inProgress'
    );

    const updatePartnershipRegistrationStatus = (status: StepStatus) => {
      client
        .mutate({
          mutation: SAVE_INSCRIPTION_STEP_QUERY,
          variables: {
            pvInstallationId,
            registrationSteps: {
              partnership: status
            }
          }
        })
        .then(() => setPartnershipStep(status));
    };
    const list = (
      <List fluid={true}>
        <ListTitle text={t('Partnerships:partners')} />
        {partnershipsList.map(thirdParty => {
          return (
            <ListItem
              key={thirdParty.id}
              options={{
                onClick: () => {
                  history.push(
                    `/devices_inscription/${customerId}/partnership/${pvInstallationId}/${
                      thirdParty.routerLink
                    }`
                  );
                }
              }}
              className={classnames(
                'custom-list-item',
                history.location.pathname.includes(thirdParty.id) && 'active'
              )}
            >
              <Col xs={6}>
                <div className="partnership-name">
                  <span>{t(`Partnerships:${thirdParty.id}SystemName`)}</span>
                </div>
                <div>
                  <span
                    className={classnames(
                      'badge',
                      'badge-pill',
                      thirdParty.status === 'connected' && 'badge-success',
                      thirdParty.status === 'disconnected' && 'badge-secondary',
                      thirdParty.status === 'inProgress' && 'badge-warning'
                    )}
                  >
                    {t(`Partnerships:${thirdParty.status}Status`)}
                  </span>
                </div>
              </Col>
              <Col xs={6}>
                <Row className="arrow-icon">
                  <i className="icon-full-arrow-right" />
                </Row>
              </Col>
            </ListItem>
          );
        })}
        {connectingInProgress && (
          <ShowAlert message={t('Partnerships:connectingInProgress')} />
        )}
        <ListItem className="custom-list-item validate-button">
          <Button
            color="primary"
            onClick={() => {
              updatePartnershipRegistrationStatus('done');
              history.push(
                getUCGPath({
                  pvInstallationId,
                  customerId
                })
              );
            }}
            disabled={loading || connectingInProgress}
          >
            {t('Partnerships:validate')}
          </Button>
        </ListItem>
      </List>
    );

    return (
      <Spinner loading={loading}>
        <Col lg={6} className={'partnerships-list mobile-helper'}>
          {list}
        </Col>
      </Spinner>
    );
  }
);

export default translate([
  'Partnerships, TadoPartnership, EnphasePartnership, WiserPartnership'
])(withRouter(withApollo(PartnershipsListView)));
