import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Col, Row } from 'reactstrap';
import { translate, TranslationFunction } from 'react-i18next';

import { Field, FieldList } from '../../../ui/DataDisplay/FieldList/FieldList';
import { ListTitle } from '../../../ui/DataDisplay/List/ListHeader';
import { RouteComponentProps, withRouter } from 'react-router';
import { Device } from '../../../graphql/graphql.schema';
import { Query, QueryResult } from 'react-apollo';
import gql from 'graphql-tag';
import Spinner from '../../../ui/Spinner/Spinner';
import classnames from 'classnames';
import { TadoStore } from '../../../stores/partnerships/tadoStore';
import { EnphaseStore } from '../../../stores/partnerships/enphaseStore';
import { IAllStores } from '../../../stores/allStores.model';
import { IThirdParty } from '../../../stores/inscription/inscriptionStore';

export const CHECK_TADO_CONNECTION = gql`
  query checkTadoConnection($customerId: String!) {
    devices(customerId: $customerId) {
      deviceTypeId
      type
    }
    getTadoHomeIdByCustomerId(customerId: $customerId)
    isTadoAccessGranted(customerId: $customerId)
  }
`;

interface IProps extends RouteComponentProps<IMatchParams> {
  tadoStore?: TadoStore;
  enphaseStore?: EnphaseStore;
  t: TranslationFunction;
}

interface IMatchParams {
  customerId: string;
}

interface IResponse {
  mutationResponse?: any;
  devices: Device[];
  getTadoHomeIdByCustomerId: string;
  isTadoAccessGranted: boolean;
}

const SummaryPartnerships = inject((allStores: IAllStores) => {
  return {
    tadoStore: allStores.tadoStore,
    enphaseStore: allStores.enphaseStore
  };
})(
  observer(
    ({
      tadoStore,
      enphaseStore,
      t,
      match: {
        params: { customerId }
      }
    }: IProps) => {
      return (
        <Query
          query={CHECK_TADO_CONNECTION}
          variables={{ customerId }}
          fetchPolicy={'no-cache'}
          errorPolicy={'all'}
          onCompleted={data => {
            const isTadoAccessGranted = data && data.isTadoAccessGranted;
            const devices = data && data.devices;
            const tadoHomeId = data && data.getTadoHomeIdByCustomerId;
            const { grantTadoAccess, setHomeId } = tadoStore!;
            grantTadoAccess(isTadoAccessGranted);
            tadoStore!.setAllDevices(devices || []);
            enphaseStore!.setAllDevices(devices || []);
            setHomeId(tadoHomeId || undefined);
          }}
        >
          {({ loading }: QueryResult<IResponse>) => {
            const partnershipsList: IThirdParty[] = [
              {
                id: 'tado',
                name: 'Tado',
                status: tadoStore!.status,
                routerLink: 'tado/connecting'
              },
              {
                id: 'enphase',
                name: 'Enphase',
                status: enphaseStore!.status,
                routerLink: 'enphase/connecting'
              }
            ];
            return (
              <Spinner loading={loading}>
                <Row>
                  <Col className={'partnerships'}>
                    <FieldList>
                      <ListTitle text={t('RegistrationMenu:partnership')} />
                      {partnershipsList.map(partnership => {
                        return (
                          <Field
                            text={
                              <Row className={'partnerships-item'}>
                                <Col xs={7} sm={9} lg={7} xl={6}>
                                  <span>{partnership.name}</span>
                                </Col>
                                <Col xs={5} sm={3} lg={5} xl={6}>
                                  <span
                                    className={classnames(
                                      'badge',
                                      'badge-pill',
                                      partnership.status === 'connected' &&
                                        'badge-success',
                                      partnership.status === 'disconnected' &&
                                        'badge-secondary',
                                      partnership.status === 'inProgress' &&
                                        'badge-warning'
                                    )}
                                  >
                                    {t(
                                      `Partnerships:${partnership.status}Status`
                                    )}
                                  </span>
                                </Col>
                              </Row>
                            }
                            key={partnership.name}
                          />
                        );
                      })}
                    </FieldList>
                  </Col>
                </Row>
              </Spinner>
            );
          }}
        </Query>
      );
    }
  )
);

export default translate(['Partnerships', 'RegistrationMenu'])(
  withRouter(SummaryPartnerships)
);
