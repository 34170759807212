import React, { ReactNode } from 'react';

import { TranslationFunction } from 'i18next';
import { translate } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import { inject, observer } from 'mobx-react';
import Spinner from '../../../ui/Spinner/Spinner';
import { Container, Row } from 'reactstrap';
import './partnerships.scss';
import history from '../../../history';
import Col from 'reactstrap/lib/Col';
import classnames from 'classnames';
import { IThirdParty } from '../../../stores/inscription/inscriptionStore';
import { IAllStores } from '../../../stores/allStores.model';
import { TadoStore } from '../../../stores/partnerships/tadoStore';
import { EnphaseStore } from '../../../stores/partnerships/enphaseStore';
import { IInscriptionNavigationRouterParamProps } from '../InscriptionNavigation';

interface IMatchParams extends IInscriptionNavigationRouterParamProps {
  partner: string;
}

interface IProps extends RouteComponentProps<IMatchParams> {
  t: TranslationFunction;
  tadoStore?: TadoStore;
  enphaseStore?: EnphaseStore;
  children: ReactNode;
}

@inject(({ tadoStore, enphaseStore }: IAllStores) => ({
  tadoStore,
  enphaseStore
}))
@observer
class Partnership extends React.Component<IProps> {
  public render() {
    const {
      t,
      match: {
        params: { customerId, pvInstallationId, partner }
      },
      tadoStore,
      enphaseStore,
      children
    } = this.props;
    const partnershipsList: IThirdParty[] = [
      {
        id: 'tado',
        name: 'Tado',
        status: tadoStore!.status,
        routerLink: 'tado/connecting'
      },
      {
        id: 'enphase',
        name: 'Enphase',
        status: enphaseStore!.status,
        routerLink: 'enphase/connecting'
      }
    ];
    const chosenPartnership = partnershipsList.find(
      thirdParty => thirdParty.id === partner
    )!;
    return (
      <Spinner loading={false}>
        <Container fluid className={'partnership'}>
          <Row className={'partnership-header'}>
            <div className={'arrow-back'}>
              <i
                className="icon-full-arrow-right"
                onClick={() =>
                  history.push(
                    `/devices_inscription/${customerId}/partnership/${pvInstallationId}`
                  )
                }
              />
            </div>
            <div className={'header-content'}>
              <span className={'header-title'}>
                {t(`Partnerships:${chosenPartnership.id}SystemName`)}
              </span>
            </div>
            <div className={'header-badge'}>
              <span
                className={classnames(
                  'badge',
                  'badge-pill',
                  chosenPartnership.status === 'connected' && 'badge-success',
                  chosenPartnership.status === 'disconnected' &&
                    'badge-secondary',
                  chosenPartnership.status === 'inProgress' && 'badge-warning'
                )}
              >
                {t(`Partnerships:${chosenPartnership.status}Status`)}
              </span>
            </div>
          </Row>
          <Row className={'partnership-content'}>
            <Col md={10} xl={7}>
              {children}
            </Col>
          </Row>
        </Container>
      </Spinner>
    );
  }
}

export default translate(['Partnerships'])(withRouter(Partnership));
