import React, { useState } from 'react';
import { Query, QueryResult } from 'react-apollo';

import gql from 'graphql-tag';
import CustomersListView from './CustomersListView';
import {
  CustomerListFilter,
  CustomerListItem,
  CustomerListPagination,
  CustomerListSorting
} from '../../../graphql/graphql.schema';
import { safeMobxInject } from '../../../stores/storeInjectionHelpers';

export const GET_CURRENT_PRO_USER_CUSTOMERS = gql`
  query getCustomerList($pagination: CustomerListPagination) {
    getCustomerList(pagination: $pagination) {
      id
      pvInstallationId
      lastName
      firstName
      email
      longitude
      latitude
      address
      zipCode
      city
      country
      registrationDate
      status
      tenant {
        id
        name
      }
      msbStatus
    }
  }
`;

interface IResponse {
  getCustomerList: [CustomerListItem];
}

interface IStoresProps {
  filterRule: CustomerListFilter[];
  sortingRule: CustomerListSorting;
  searchRule: string;
  setOffset: (offset: number) => void;
  offset: number;
  pageSize: number;
}

const CustomersListQuery = safeMobxInject<IStoresProps, {}>(
  ({ Store: { customersList } }) => ({
    filterRule: customersList.filterRule,
    sortingRule: customersList.sortingRule,
    searchRule: customersList.searchRule,
    pageSize: customersList.pageSize,
    offset: customersList.offset,
    setOffset: customersList.setOffset
  }),
  ({
    filterRule,
    searchRule,
    sortingRule,
    pageSize,
    offset,
    setOffset
  }: IStoresProps) => {
    const [customerList, setCustomerList] = useState<CustomerListItem[]>([]);
    const [canFetchMore, setCanFetchMore] = useState(true);

    const pagination: CustomerListPagination = {
      pageSize,
      search: searchRule,
      sorting: sortingRule,
      filters: filterRule
    };

    return (
      <Query
        query={GET_CURRENT_PRO_USER_CUSTOMERS}
        fetchPolicy={'cache-and-network'}
        variables={{ pagination }}
        onCompleted={(data: IResponse) => {
          const newCustomerList: CustomerListItem[] = data
            ? data.getCustomerList
            : [];
          setOffset(newCustomerList.length);
          setCanFetchMore(newCustomerList.length % pageSize === 0);
          setCustomerList(newCustomerList);
        }}
      >
        {({ loading, fetchMore }: QueryResult<IResponse>) => {
          return (
            <CustomersListView
              loading={loading}
              customers={customerList}
              canFetchMore={canFetchMore}
              fetchMore={() =>
                fetchMore({
                  query: GET_CURRENT_PRO_USER_CUSTOMERS,
                  variables: {
                    pagination: { ...pagination, offset, pageSize }
                  },
                  updateQuery: (previousResult, { fetchMoreResult }) => {
                    const previousList = previousResult.getCustomerList || [];
                    const currentList =
                      (fetchMoreResult && fetchMoreResult.getCustomerList) ||
                      [];
                    setCanFetchMore(currentList.length === pageSize);

                    const newList = [...previousList, ...currentList];
                    return {
                      getCustomerList: newList
                    };
                  }
                })
              }
            />
          );
        }}
      </Query>
    );
  }
);

export default CustomersListQuery;
