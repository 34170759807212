import { TranslationFunction } from 'i18next';
import Col from 'reactstrap/lib/Col';
import {
  DEVICE_TYPE,
  FUNCTION_TYPE,
  GMD,
  GMD_G3,
  TPH
} from '../../../stores/devices_inscription/contants';
import SelectInput from '../../../ui/Select/Select';
import { Row } from 'reactstrap';
import classnames from 'classnames';
import { FormInput } from '../../../ui/FormInput/FormInput';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { translate } from 'react-i18next';
import { Store } from '../../../stores';
import { inject, observer } from 'mobx-react';
import { CompositeDevice } from '../../../stores/inscription/compositeDevice';
import { DeviceCT } from '../../../stores/inscription/deviceCT';
import {
  Device,
  GREENPLAY_DEVICES,
  WATER_HEATER
} from '../../../stores/inscription/device';
import PossibleAlert from './PossibleAlert';
import { CustomForm } from '../../../ui/FormInput/CustomForm';
import Toggle from '../../../ui/Button/Toggle/Toggle';
import ShowAlert from '../../../ui/ShowAlert/ShowAlert';

interface ISplitProps extends RouteComponentProps<any> {
  Store?: Store;
  t: TranslationFunction;
  device: CompositeDevice;
  possibleOtherDeviceWithGlobalConsumption: Device | undefined;
}

@inject('Store')
@observer
class SplitDeviceEditing extends Component<ISplitProps> {
  public render() {
    const {
      t,
      device,
      possibleOtherDeviceWithGlobalConsumption,
      match: {
        params: { pvInstallationId, customerId }
      }
    } = this.props;

    const { CT } = DEVICE_TYPE;

    return (
      device.editedControlledDeviceType === FUNCTION_TYPE.COMPOSITE_DEVICE && (
        <Col className="splitable-device-functions">
          {device.cts.map((c: DeviceCT) => {
            const hasTooManyGlobalConsumptionSelected =
              device.hasTooManyGlobalConsumptionSelected;
            const hasSelectedGlobalConsumption = c.hasSelectedGlobalConsumption;
            const deviceFunction = c.editedControlledDeviceType;
            return (
              <Col key={c.phase} className={'device-phase-function'}>
                <div className={'device-phase-name'}>
                  <Col>
                    <Row>CT{c.phase}</Row>
                  </Col>
                  <Col
                    className={classnames(
                      'badge',
                      'badge-pill',
                      device.deviceType === GMD && c.isNegativePower
                        ? 'badge-warning'
                        : 'badge-success'
                    )}
                  >
                    {c.ctPower.toFixed(1)} W
                  </Col>
                </div>
                <div>
                  <FormInput
                    id={`CT${c.phase}`}
                    name={t('Devices:functionName')}
                    value={c.editedControlledDeviceName || ''}
                    onChange={v => {
                      c.setEditedControlledDeviceName(v);
                    }}
                    error={
                      c.editedControlledDeviceName &&
                      c.editedControlledDeviceNameError
                    }
                  />
                </div>
                <div>
                  <SelectInput
                    options={c.getFunctionOptions}
                    value={
                      deviceFunction
                        ? {
                            label:
                              t(`DevicesFunctions:${deviceFunction}`) || '',
                            value: deviceFunction || ''
                          }
                        : undefined
                    }
                    placeholder={t('Devices:defineFunction')}
                    id="function"
                    key="function"
                    name={t('Devices:functionType')}
                    onChange={item => {
                      c.setEditedControlledDeviceType(item.value);
                      // FIXME put in mobx, only phase 1
                      if (c.phase === 1 && device.deviceType === TPH) {
                        c.setEditedGreenPlayEnabled(
                          item.value === WATER_HEATER
                        );
                      }
                    }}
                  />
                  {device.getDeviceType === GMD_G3 &&
                    c.editedControlledDeviceType ===
                      FUNCTION_TYPE.WATER_HEATER && (
                      <ShowAlert
                        message={t(
                          'Devices:gmdG3CTWaterHeaterFunctionSelected'
                        )}
                      />
                    )}
                  {hasSelectedGlobalConsumption &&
                    possibleOtherDeviceWithGlobalConsumption && (
                      <PossibleAlert
                        currentDevice={device}
                        deviceWithGC={possibleOtherDeviceWithGlobalConsumption}
                        pvInstallationId={pvInstallationId}
                        customerId={customerId}
                      />
                    )}
                  {hasSelectedGlobalConsumption &&
                    !possibleOtherDeviceWithGlobalConsumption &&
                    hasTooManyGlobalConsumptionSelected && <PossibleAlert />}
                </div>
                {c.phase === 1 &&
                  device.getCanHaveGreenPlay &&
                  GREENPLAY_DEVICES.includes(c.editedControlledDeviceType) && (
                    <CustomForm id={`greenplay`} name={t('greenplay')}>
                      <Toggle
                        onCheckChange={checked => {
                          c.setEditedGreenPlayEnabled(checked);
                        }}
                        labelTrue={t(`Common:yes`)}
                        labelFalse={t(`Common:no`)}
                        checked={c.editedGreenPlayEnabled}
                      />
                    </CustomForm>
                  )}
              </Col>
            );
          })}
        </Col>
      )
    );
  }
}

export default withRouter(
  translate(['Devices', 'DeviceFunctions'])(SplitDeviceEditing)
);
