import React, { useEffect, useState } from 'react';
import { translate, TranslationFunction } from 'react-i18next';
import '../battery.scss';
import { RouteComponentProps, withRouter } from 'react-router';
import { ListTitle } from '../../../../../ui/DataDisplay/List/ListHeader';
import { CustomField, Field } from '../../../../../ui/DataDisplay/FieldList/FieldList';
import MsbStatusText from './MsbStatusText';
import { MsbStatus } from './constants';
import { safeMobxInject } from '../../../../../stores/storeInjectionHelpers';
import moment from 'moment';
import '../../../../../ui/FormInput/FormInput.scss';
import { withApollo, WithApolloClient } from 'react-apollo';
import { MySmartBattery } from '../../../../../stores/my_smart_battery/mySmartBattery';
import activationMySmartBatteryMylight, {
  ActivationMySmartBatteryMylight
} from '../../../../../stores/my_smart_battery/activationMySmartBatteryMylight';
import {
  eldPdlValidator,
  msbMylightModemSerialNumberValidator
} from '../../../../../utils/validators';
import { MsbInlineForm } from './MsbInlineForm';
import { MsbInputText } from './MsbInputText';
import MsbDeletionModalOpenButton from './../deletion/MsbDeletionModalOpenButton';

interface IStoresProps {
  mySmartBattery: MySmartBattery;
  activationMsbMylight: ActivationMySmartBatteryMylight;
}

interface IProps extends RouteComponentProps<IMatchParams> {
  t: TranslationFunction;
  refetchInstallationQuery: any;
}

interface IMatchParams {
  id: string;
}

const MsbActivationMylightView = safeMobxInject<IStoresProps, IProps>(
  stores => ({
    mySmartBattery: stores.mySmartBattery,
    activationMsbMylight: stores.activationMySmartBatteryMylight,
  }),
  ({
    t,
    mySmartBattery,
    activationMsbMylight,
    client,
    refetchInstallationQuery,
    match: {
      params: { id: customerId }
    }
  }: WithApolloClient<IProps & IStoresProps>) => {
    useEffect(
      () => {
        setPdlNumber(activationMsbMylight.pdlNumber);
        setSerialNumber(activationMsbMylight.serialNumber);
      },
      [activationMsbMylight.pdlNumber, activationMsbMylight.serialNumber]
    );

    const DATE_FORMAT = t('MySmartBattery:enteredOnFormatDate');

    const [serialNumber, setSerialNumber] = useState(
      activationMsbMylight.serialNumber
    );
    const [isSerialNumberLoading, setIsSerialNumberLoading] = useState(false);
    const [serialNumberTextError, setSerialNumberTextError] = useState<
      string | undefined
    >(undefined);

    const [pdlNumber, setPdlNumber] = useState(activationMsbMylight.pdlNumber);
    const [isPdlNumberLoading, setIsPdlNumberLoading] = useState(false);
    const [pdlNumberTextError, setPdlNumberTextError] = useState<
      string | undefined
    >(undefined);
    const getDateMsbStatus = () => {
      if (mySmartBattery.isMSBActivated) {
        if (
          mySmartBattery.msbOkStatus &&
          mySmartBattery.msbOkStatus.validateAt
        ) {
          return moment(mySmartBattery.msbOkStatus.validateAt).format(
            DATE_FORMAT
          );
        }
        return t('Common:unknown');
      }

      if (
        mySmartBattery.msbSnFilledStatus &&
        mySmartBattery.msbSnFilledStatus.validateAt
      ) {
        return moment(mySmartBattery.msbSnFilledStatus.validateAt).format(
          DATE_FORMAT
        );
      }
      return t('Common:unknown');
    };

    const getTextMsbStatus = () => {
      return mySmartBattery.isMSBActivated
        ? MsbStatus.ACTIVATED
        : MsbStatus.CREATED;
    };

    const getTextserialNumberModifiedAt = () => {
      return activationMySmartBatteryMylight.serialNumberModifiedAt === null
        ? ''
        : `${t('enteredOn')} ${moment(
            activationMySmartBatteryMylight.serialNumberModifiedAt
          ).format(DATE_FORMAT)}`;
    };

    const onChangeSerialNumberField = (value: string) => {
      setSerialNumber(value);
      activationMsbMylight.serialNumberInputState = undefined;
    };

    const onBlurSerialNumberField = () => {
      if (isSerialNumberChanged()) {
        const { isValid, error } = checkSerialNumberFormat();
        if (isValid) {
          saveSerialNumber();
        } else {
          activationMsbMylight.serialNumberInputState = 'error';
          setSerialNumberTextError(error);
        }
      }
    };

    const isSerialNumberChanged = () => {
      return serialNumber !== activationMsbMylight.serialNumberSaved;
    };

    const checkSerialNumberFormat = () => {
      const error: string | undefined = msbMylightModemSerialNumberValidator(
        serialNumber
      );
      const isValid = !error;
      return {
        isValid,
        error
      };
    };

    const saveSerialNumber = async () => {
      setIsSerialNumberLoading(true);
      try {
        await activationMsbMylight.updateSerialNumber(
          client,
          customerId,
          serialNumber
        );
      } catch (e) {
        setSerialNumberTextError(e);
      }
      setIsSerialNumberLoading(false);
    };

    const getTextpdlNumberModifiedAt = () => {
      return activationMsbMylight.pdlNumberModifiedAt === null
        ? ''
        : `${t('enteredOn')} ${moment(
            activationMsbMylight.pdlNumberModifiedAt
          ).format(DATE_FORMAT)}`;
    };

    const onChangePdlNumberField = (value: string) => {
      setPdlNumber(value);
      activationMsbMylight.pdlNumberInputState = undefined;
    };

    const onBlurPdlNumberField = async () => {
      if (isPdlNumberChanged()) {
        const { isValid, error } = checkPdlNumberFormat();
        if (isValid) {
          await savePdlNumber();
          refetchInstallationQuery();
        } else {
          activationMsbMylight.pdlNumberInputState = 'error';
          setPdlNumberTextError(error);
        }
      }
    };

    const isPdlNumberChanged = () => {
      return pdlNumber !== activationMsbMylight.pdlNumberSaved;
    };

    const checkPdlNumberFormat = () => {
      const error: string | undefined = eldPdlValidator(pdlNumber);
      const isValid = !error;
      return {
        isValid,
        error
      };
    };

    const savePdlNumber = async () => {
      setIsPdlNumberLoading(true);
      try {
        await activationMsbMylight.updatePdlNumber(
          client,
          customerId,
          pdlNumber
        );
      } catch (e) {
        setPdlNumberTextError(e);
      }
      setIsPdlNumberLoading(false);
    };

    const getTextEnergyDistributor = () => {
      return activationMsbMylight.energyDistributor
        ? t(`energyDistributors.${activationMsbMylight.energyDistributor.name}`)
        : t('Common:unknown');
    };

    const getTextVolume = () => {
      return activationMsbMylight.volume > 0
        ? `${activationMsbMylight.volume} kWh`
        : t('onHold');
    };

    const canMsbBeDeleted = (): boolean => {
      return (!mySmartBattery.isMSBActivated 
        && !mySmartBattery.isMSBEnedisGridInjectionRequested
        && !mySmartBattery.isMSBStrasbourgElectricityAdministrativeProcedureRequested);
    };

    return (
      <>
        <ListTitle
          text={t('battery')}
          children={
            <MsbStatusText
              date={getDateMsbStatus()}
              status={getTextMsbStatus()}
            />
          }
        />
        <MsbInlineForm htmlFor="serialNumber" label={t('serialNumber')}>
          <MsbInputText
            disabled={
              mySmartBattery.isMSBActivated ||
              mySmartBattery.isMSBEnedisGridInjectionRequested ||
              mySmartBattery.isMSBStrasbourgElectricityAdministrativeProcedureRequested
            }
            id="serialNumber"
            loading={isSerialNumberLoading}
            inputState={activationMsbMylight.serialNumberInputState}
            name="serialNumber"
            value={serialNumber}
            onChange={value => onChangeSerialNumberField(value)}
            onBlur={() => onBlurSerialNumberField()}
            helperText={{
              info: getTextserialNumberModifiedAt(),
              error: serialNumberTextError
            }}
          />
        </MsbInlineForm>

        <MsbInlineForm htmlFor="pdlNumber" label={t('pdlNumber')}>
          <MsbInputText
            disabled={
              mySmartBattery.isMSBActivated ||
              mySmartBattery.isMSBEnedisGridInjectionRequested ||
              mySmartBattery.isMSBStrasbourgElectricityAdministrativeProcedureRequested
            }
            id="pdlNumber"
            loading={isPdlNumberLoading}
            inputState={activationMsbMylight.pdlNumberInputState}
            name="pdlNumber"
            value={pdlNumber}
            onChange={value => onChangePdlNumberField(value)}
            onBlur={() => onBlurPdlNumberField()}
            helperText={{
              info: getTextpdlNumberModifiedAt(),
              error: pdlNumberTextError
            }}
          />
        </MsbInlineForm>
        <Field
          name={t('energyDistributor')}
          text={getTextEnergyDistributor()}
        />
        <Field name={t('volumeShort')} text={getTextVolume()} />

        { canMsbBeDeleted() && 
          <CustomField className='mt-4'
            children={ 
              <MsbDeletionModalOpenButton
                client={ client } 
                customerId={ customerId }
                classList={ 'ml-auto' }
              /> 
            }
          />
        }
      </>
    );
  }
);

export default withRouter(
  translate(['MySmartBattery', 'Common'])(withApollo(MsbActivationMylightView))
);
