import * as React from 'react';
import { QueryResult } from 'react-apollo';

import gql from 'graphql-tag';
import { RouteComponentProps, withRouter } from 'react-router';
import Query from 'react-apollo/Query';
import { IAllStores } from '../../../../../stores/allStores.model';
import AddModbusDevice from './AddModbusDevice';
import { IInscriptionNavigationRouterParamProps } from '../../../InscriptionNavigation';
import { ApolloError } from 'apollo-client';
import { safeMobxInject } from '../../../../../stores/storeInjectionHelpers';
import { ModBusReference } from '../../../../../graphql/graphql.schema';
import i18n from '../../../../../translations/i18n';

export interface IProps
  extends RouteComponentProps<IInscriptionNavigationRouterParamProps> {}

const GET_MODBUS_REFERENCES = gql`
  query getAvailableMSTModbusAddress(
    $pvInstallationId: String!
    $lang: String
  ) {
    getAvailableMSTModbusAddress(
      pvInstallationId: $pvInstallationId
      lang: $lang
    ) {
      references {
        reference
        availableAddress
        phaseCount
        defaultAddress
      }
      error
    }
  }
`;

interface IStoreProps {
  setPossibleReferences: (references: ModBusReference[]) => void;
  initErrorsFromGQLError: (error: ApolloError) => void;
}

const GetModbusReferencesQuery = safeMobxInject<IStoreProps, IProps>(
  ({ addDevice, inscriptionError, inscription: { gridType } }: IAllStores) => {
    return {
      gridType,
      setPossibleReferences: addDevice.setPossibleReferences,
      initErrorsFromGQLError: inscriptionError.initErrorsFromApolloError
    };
  },
  ({
    match: {
      params: { customerId, current, pvInstallationId }
    },
    setPossibleReferences,
    initErrorsFromGQLError
  }: IProps & IStoreProps) => {
    return (
      <Query
        query={GET_MODBUS_REFERENCES}
        variables={{ pvInstallationId, lang: i18n.language }}
        fetchPolicy={'no-cache'}
        onCompleted={data => {
          setPossibleReferences!(data.getAvailableMSTModbusAddress.references);
        }}
        onError={e => initErrorsFromGQLError!(e)}
      >
        {({ data, error, loading }: QueryResult) => (
          <AddModbusDevice loading={loading} />
        )}
      </Query>
    );
  }
);
export default withRouter(GetModbusReferencesQuery);
