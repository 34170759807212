import React from 'react';
import { IDeviceIcon } from '../DeviceIcons';

const TV = (props: IDeviceIcon) => (
  <svg
    width="3rem"
    height="3rem"
    viewBox="0 0 20000 20000"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    color="black"
    {...props}
  >
    <g id="prefix__Layer_x0020_1">
      <g id="prefix___1405061466832">
        <path
          style={{ fill: `${props.color}` }}
          d="M4547 13415h4240c140 0 300-140 300-300v-1160c0-140-140-300-300-300H4547c-140 0-300 140-300 300v1160c0 160 160 300 300 300zm280-1160h3720v580H4827v-580zM11107 13415h4240c140 0 300-140 300-300v-1160c0-140-140-300-300-300h-4240c-140 0-300 140-300 300v1160c20 160 160 300 300 300zm300-1160h3720v580h-3720v-580z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M16227 10575H3747c-300 0-580 220-580 580v4020c0 140 140 300 300 300 140 0 300-140 300-300v-580h12480v580c0 140 140 300 300 300 140 0 300-140 300-300v-4020c-40-280-340-580-620-580zm0 3440H3747v-2860h12480v2860zM6007 9695h7960c300 0 580-220 580-580V5095c0-300-220-580-580-580H6007c-300 0-580 220-580 580v4020c0 360 220 580 580 580zm0-4520h7960v4020H6007V5175z"
        />
      </g>
    </g>
  </svg>
);

export default TV;
