import * as React from 'react';

export const IconLogout = () => (
  <svg
    baseProfile="tiny"
    x="0px"
    y="0px"
    width="20pc"
    height="20px"
    viewBox="-0.8 -0.5 177 202"
  >
    <defs />
    <path
      fill="none"
      stroke="#afafb7"
      strokeWidth="20"
      strokeLinecap="round"
      d="M33.7,64.3C22.1,77.2,15,94.3,15,113  c0,40.1,32.5,72.7,72.7,72.7c40.1,0,72.7-32.5,72.7-72.7c0-18.7-7.1-35.8-18.7-48.7"
    />
    <line
      fill="none"
      stroke="#afafb7"
      strokeWidth="20"
      strokeLinecap="round"
      x1="87.8"
      y1="15"
      x2="87.8"
      y2="113"
    />
  </svg>
);
