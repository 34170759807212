import * as React from 'react';
import { translate, TranslationFunction } from 'react-i18next';
import { Label, Input } from 'reactstrap';
import Spinner from '../Spinner/Spinner';

export interface IProps {
  t: TranslationFunction;
  onChange?: (a: string) => void;
  name?: string;
  id: string;
  value?: string;
  options?: string[];
  required?: boolean;
  loading?: boolean;
}

const AutoComplete = ({
  t,
  onChange,
  name,
  id,
  value,
  options,
  required,
  loading
}: IProps) => (
  <div className="input-field">
    <Label className="input-title" htmlFor={id}>
      {name} {required && ' *'}{' '}
    </Label>
    <div className={'input-group mb-3'}>
      <Spinner loading={loading}>
        <Input
          list={id}
          value={value || ''}
          type="text"
          onChange={({ target: { value: v } }: any) => {
            if (onChange) {
              onChange(v);
            }
          }}
        />
      </Spinner>
    </div>
    <datalist id={id}>
      {options && options.map(e => <option key={e} value={e} />)}
    </datalist>
  </div>
);

export default translate('Common')(AutoComplete);
