import gql from 'graphql-tag';
import { inject, observer } from 'mobx-react';
import { Mutation, MutationResult } from 'react-apollo';
import history from '../../../../../history';
import { RouteComponentProps, withRouter } from 'react-router';
import { translate } from 'react-i18next';
import React from 'react';
import { TranslationFunction } from 'i18next';
import LoadingButton from '../../../../../ui/Button/LoadingButton/LoadingButton';
import { IAllStores } from '../../../../../stores/allStores.model';
import { ApolloError } from 'apollo-client';
import { IInscriptionNavigationRouterParamProps } from '../../../InscriptionNavigation';
import { getDevicePath } from '../../../routingHelper';
import { DEVICE_INSCRIPTION_FIELD_QUERY } from '../../../DevicesInscription';
import { ModBusReference } from '../../../../../graphql/graphql.schema';
import i18n from '../../../../../translations/i18n';

export const ADD_MODBUS_DEVICE = gql`
    mutation addModbusDeviceToMst(
        $pvInstallationId: String!
        $reference: String!
        $address: String!
        $lang: String!
    ) {
        addModbusDeviceToMst(
            pvInstallationId: $pvInstallationId
            reference: $reference
            address: $address
            lang:$lang
        ) {
            ${DEVICE_INSCRIPTION_FIELD_QUERY}
        }
    }
`;

export interface IProps
  extends RouteComponentProps<IInscriptionNavigationRouterParamProps> {
  t: TranslationFunction;
  disabled: boolean;
  initErrorsFromGQLError?: (e: ApolloError) => void;
  chosenReference?: ModBusReference;
  chosenAddress?: string;
}

interface IMutationResponse {
  addModbusDeviceToMst: any;
}

const AddModbusDeviceButton = inject(
  ({ addDevice, inscriptionError }: IAllStores) => {
    return {
      chosenReference: addDevice.chosenReference,
      chosenAddress: addDevice.chosenAddress,
      initErrorsFromGQLError: inscriptionError.initErrorsFromApolloError
    };
  }
)(
  observer(
    ({
      match: {
        params: { customerId, pvInstallationId, current }
      },
      t,
      disabled,
      chosenReference,
      chosenAddress,
      initErrorsFromGQLError
    }: IProps) => {
      return (
        <Mutation
          mutation={ADD_MODBUS_DEVICE}
          onCompleted={data => {
            if (data.addModbusDeviceToMst.error) {
              initErrorsFromGQLError!(data.addModbusDeviceToMst.error);
            } else {
              history.push(getDevicePath({ customerId, pvInstallationId }));
            }
          }}
          onError={e => initErrorsFromGQLError!(e)}
        >
          {(
            addModbusDeviceToMst,
            { loading }: MutationResult<IMutationResponse>
          ) => (
            <LoadingButton
              className="update"
              onClick={event => {
                event.preventDefault();
                addModbusDeviceToMst({
                  variables: {
                    pvInstallationId,
                    reference: chosenReference!.reference,
                    address: chosenAddress,
                    lang: i18n.language
                  }
                });
              }}
              disabled={disabled}
              loading={loading}
            >
              {t('Common:add')}
            </LoadingButton>
          )}
        </Mutation>
      );
    }
  )
);

export default withRouter(translate(['Common'])(AddModbusDeviceButton));
