import { observable, action } from 'mobx';

export class WiserStore {
  @observable public status: string = 'disconnected';

  @action.bound
  public updateStatus(status: string) {
    this.status = status;
  }
}

const wiserStore: WiserStore = new WiserStore();
export default wiserStore;
