import { action, observable } from 'mobx';
import { stopaGa } from '../components/devices_inscription/barcode/quagga/q';

export interface IBarCode {
  type: 'correct' | 'wrong' | undefined;
  value: string;
}

export type ScanCodeType = 'MST' | 'UCG' | 'MSB' | 'PLC';

export class Barcode {
  @observable public type: ScanCodeType | undefined = undefined;
  @observable public scanningProcess: boolean = false;
  @observable public scannedCode: IBarCode = { type: undefined, value: '' };
  @observable
  public possibleBarcodeTypes: ScanCodeType[] = ['MST', 'UCG', 'MSB', 'PLC'];

  @action.bound
  public setType(type: ScanCodeType | undefined) {
    this.type = type;
  }
  @action.bound
  public setScanningProcess(scanning: boolean) {
    this.scanningProcess = scanning;
  }

  @action.bound
  public startScanningProcess() {
    this.setScannedCode({ type: undefined, value: '' });
    this.setScanningProcess(true);
  }
  @action.bound
  public setScannedCode(scannedCode: IBarCode) {
    if (scannedCode.type === 'correct') {
      this.setScanningProcess(false);
      stopaGa();
    }
    this.scannedCode = scannedCode;
  }
}

const barcode: Barcode = new Barcode();
export default barcode;
