export default {
  SETUP_OK: 'setup_ok',
  USER_PASSWORD_SET: 'user_password_set',
  USER_SETTINGS_SET: 'user_settings_set',
  USER_ACCEPTED_LICENCE: 'user_accepted_licence',
  OK: 'ok',
  SETUP_SELECTED_PRODUCTION_DEVICE: 'setup_selected_production_device',
  SETUP_INSTALLATION_DETAILS_SET: 'setup_installation_details_set',
  SETUP_INSTALLATION_DETAILS_SET2: 'setup_installation_details_set2',
  SETUP_DEVICES_REGISTERED: 'setup_devices_registered',
  SETUP_CONNECT_TADO: 'setup_connect_tado',
  SETUP_CHECK_WIRING: 'setup_check_wiring',
  SETUP_USER_REGISTERED: 'setup_user_registered'
};
