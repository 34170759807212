import * as React from 'react';

import { TranslationFunction } from 'i18next';
import { Container, Row, Col } from 'reactstrap';
import { RouteComponentProps, withRouter } from 'react-router';

import CustomerSupport from '../../support/CustomerSupport';
import Spinner from '../../../ui/Spinner/Spinner';

interface IMatchParams {
  id: string;
}

export interface IProps extends RouteComponentProps<IMatchParams> {
  t: TranslationFunction;
}

const SAVToolsView = ({
  match: {
    params: { id }
  }
}: IProps) => {
  return (
    <Spinner>
      <Container fluid>
        <Col className="mobile-helper">
          <Row>
            <CustomerSupport />
          </Row>
        </Col>
      </Container>
    </Spinner>
  );
};

export default withRouter(SAVToolsView);
