import React, { ReactNode } from 'react';

import { Col, Container, Row } from 'reactstrap';

import './loginBox.scss';
import Logo from './Logo';
import LogoDomuneo from './LogoDomuneo';
import myProPortal from '../../assets/images/my_pro_portal.png';
import LogoPlaneteOui from './LogoPlaneteOui';
interface IProps {
  children: ReactNode;
}
export const LoginBox = ({ children }: IProps) => (
  <div className="login-box">
    <Container>
      <Row>
        <Col className="logo-container logo-mylight">
          {window.location.href.includes('domuneo') ? (
            <LogoDomuneo />
          ) : window.location.href.includes('planete') ? (
            <LogoPlaneteOui />
          ) : (
            <Logo />
          )}
        </Col>
      </Row>
      <Row>
        <Col className="logo-container logo-portal-pro">
          <img style={{ width: '150px' }} src={myProPortal} />
        </Col>
      </Row>
      <Row className="login-box-content">
        <Col>{children}</Col>
      </Row>
    </Container>
  </div>
);
