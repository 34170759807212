import RegistrationStatus from '../../constants/REGISTRATION_STATUS';
import classnames from 'classnames';
import { T } from '../../components/translations/T';
import React, { ReactNode } from 'react';
import './badgeStatus.scss';
interface IProps {
  status: string | undefined;
  children?: ReactNode;
}

const BadgeStatus = ({ status, children }: IProps) => {
  return (
    <span
      className={classnames(
        status === RegistrationStatus.OK ? 'success' : 'warning',
        'badge-status'
      )}
    >
      <label>
        <T ns="RegistrationStatus" tk={status || ''} />
      </label>
      {children}
    </span>
  );
};

export default BadgeStatus;
