import { Col, Row, Alert } from 'reactstrap';
import { CSSProperties, default as React } from 'react';
interface IProps {
  message: string | React.ReactNode;
  type?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'warning'
    | 'danger'
    | 'info'
    | 'light'
    | 'dark';
  style?: CSSProperties;
  padding?: number;
  margin?: number;
}
const ShowAlert = ({
  message,
  type,
  style,
  padding = 3,
  margin = 3
}: IProps) => (
  <Row style={style}>
    <Col lg={12} className={`pt-${padding}`}>
      <Alert color={type || 'warning'} className={`mb-${margin}`}>
        {message}
      </Alert>
    </Col>
  </Row>
);
export default ShowAlert;
