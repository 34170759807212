import { Mutation, MutationResult } from 'react-apollo';

import React from 'react';
import { translate, TranslationFunction } from 'react-i18next';
import LoadingButton from '../../../ui/Button/LoadingButton/LoadingButton';
import gql from 'graphql-tag';
import { EPPDevice, PPDeviceConfigFile } from '../../../graphql/graphql.schema';
import { IAllStores } from '../../../stores/allStores.model';
import { Device } from '../../../stores/inscription/device';
import { ApolloError } from 'apollo-client';
import { DEVICE_INSCRIPTION_FIELD_QUERY } from '../DevicesInscription';
import { safeMobxInject } from '../../../stores/storeInjectionHelpers';
import snackbarStore, {
  ISnackBarParams
} from '../../../stores/userFeedback/snackbarStore';
import i18n from '../../../translations/i18n';

const REMOVE_MODBUS_DEVICE = gql`
  mutation removeModbusDeviceFrom($plcMacToRemove: String!, $pvInstallationId: String!, $lang: String!) {
      removeModbusDeviceFrom(pvInstallationId:$pvInstallationId,plcMacToRemove:$plcMacToRemove, lang: $lang ) {
     ${DEVICE_INSCRIPTION_FIELD_QUERY}
    }
  }
`;

interface IProps {
  t: TranslationFunction;
  disabled?: boolean;
  pvInstallationId?: string;
  getMutationAndToggleVisibility?: (device: Device) => EPPDevice[];
  device: Device;
}

interface IStoresProps {
  pvInstallationId: string;
  initErrorsFromGQLError: (e: ApolloError) => void;
  showSnackBar: (params: ISnackBarParams) => void;
}

interface IResponse {
  removeModbusDeviceFrom: PPDeviceConfigFile;
}

interface IParams {
  pvInstallationId: string;
  plcMacToRemove: string;
  lang: string;
}

const RemoveModbusDevice = safeMobxInject<IStoresProps, IProps>(
  ({ inscription, inscriptionError }: IAllStores) => {
    return {
      pvInstallationId: inscription.id,
      initErrorsFromGQLError: inscriptionError.initErrorsFromApolloError,
      showSnackBar: snackbarStore.showSnackBar
    };
  },
  ({
    disabled,
    t,
    pvInstallationId,
    device,
    initErrorsFromGQLError,
    showSnackBar
  }: IProps & IStoresProps) => {
    return (
      <Mutation<IResponse, IParams>
        mutation={REMOVE_MODBUS_DEVICE}
        onCompleted={data => {
          if (data.removeModbusDeviceFrom.error) {
            return showSnackBar({
              text: t('Errors:serverError'),
              type: 'error'
            });
          }
          return showSnackBar({
            text: t('Devices:deletingModbusDevice'),
            type: 'success'
          });
        }}
        onError={e => initErrorsFromGQLError(e)}
      >
        {(
          save,
          { called, data, error, loading }: MutationResult<IResponse>
        ) => {
          const fail = Boolean(
            !loading &&
              (error ||
                (data &&
                  data.removeModbusDeviceFrom &&
                  data.removeModbusDeviceFrom &&
                  data.removeModbusDeviceFrom.error))
          );
          const done = called && !error && !loading && !fail;
          return (
            <LoadingButton
              disabled={disabled}
              onClick={() => {
                save({
                  variables: {
                    pvInstallationId,
                    plcMacToRemove: device.plcMac || '',
                    lang: i18n.language
                  }
                });
              }}
              done={done}
              fail={fail}
              loading={loading}
            >
              {t('yes')}
            </LoadingButton>
          );
        }}
      </Mutation>
    );
  }
);

export default translate(['Common', 'Devices'])(RemoveModbusDevice);
