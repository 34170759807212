import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import LoginView from './components/login/LoginView';
import Customers from './components/customers/Customers';
import CustomersMenu from './components/customers/navigation/CustomerMenu';
import ProContact from './components/customers/pro_contact/ProContact';
import Identity from './components/customers/identity/IdentityQuery';
import EditIdentity from './components/customers/identity/edit/EditIdentityQuery';
import EditInstallationQuery from './components/customers/installation/edit/EditInstallationQuery';
import MainMenu from './components/navigation/MainNavigation';
import Installation from './components/customers/installation/InstallationQuery';
import CustomersListQuery from './components/customers/customersList/CustomersListQuery';
import SystemView from './components/customers/system/SystemQuery';
import CustomersMap from './components/customers/map/Map';
import Dashboard from './components/dashboard/Dashboard';
import ContextQuery from './components/context/ContextQuery';
import SupportView from './components/support/SupportView';
import ForgotPasswordView from './components/forgotPassword/ForgotPasswordView';
import SAVToolsView from './components/customers/sav_tools/SAVToolsView';
import CreateCustomer from './components/createCustomer/CreateCustomer';
import NotFound from './components/404/NotFound';
import DevicesInscription from './components/devices_inscription/DevicesInscription';
import UCGQuery from './components/devices_inscription/ucg/UCGQuery';
import MasterQuery from './components/devices_inscription/master/MasterQuery';
import DevicesQuery from './components/devices_inscription/devices/DevicesQuery';
import FunctionsView from './components/devices_inscription/functions/FunctionsView';
import EditDeviceFunction from './components/devices_inscription/functions/EditDeviceFunction';
import FinishInstallationQuery from './components/devices_inscription/finish/FinishInstallationQuery';
import DiagnosticDetailQuery from './components/customers/system/deviceTests/DiagnosticDetailQuery';
import PartnershipsListQuery from './components/devices_inscription/partnership/PartnershipsListQuery';
import TadoAccessQuery from './components/devices_inscription/partnership/tado/TadoAccessQuery';
import TadoDevicesQuery from './components/devices_inscription/partnership/tado/TadoDevicesQuery';
import EnphaseRegistrationQuery from './components/devices_inscription/partnership/enphase/EnphaseRegistrationQuery';
import TadoHomeQuery from './components/devices_inscription/partnership/tado/TadoHomeQuery';
import Partnership from './components/devices_inscription/partnership/Partnership';
import EditInstallationQueryFormInscription from './components/devices_inscription/installation/EditInstallationQueryFormInscription';
import SnackBar from './ui/UserFeedback/SnackBar';
import InscriptionNavigation from './components/devices_inscription/InscriptionNavigation';
import BarCodeReader from './components/devices_inscription/barcode/BarCodeReader';
import AddPLCDevice from './components/devices_inscription/devices/addDevice/AddPLCDevice/AddPLCDevice';
import AddDeviceTypeList from './components/devices_inscription/devices/addDevice/AddDeviceTypeList';
import GetModbusReferencesQuery from './components/devices_inscription/devices/addDevice/AddModbusDevice/GetModbusReferencesQuery';
import DeviceDetail from './components/devices_inscription/device_detail/DeviceDetail';
import ToolsTestPairingView from './components/toolsTestPairing/ToolsTestPairingView';
import MySmartBatteryListPage from './components/my_smart_battery/MySmartBatteryListPage';
import MySmartBatteryListQuery from './components/my_smart_battery/my_smart_battery_list/MySmartBatteryListQuery';
import { safeMobxInject } from './stores/storeInjectionHelpers';
import SelectWModbusDevice from './components/devices_inscription/devices/addDevice/SelectWModbusDevice/SelectWModbusDevice';
import AddWModbusBoxDevice from './components/devices_inscription/devices/addDevice/AddWModbusBoxDevice/AddWModbusBoxDevice';
import AddWModbusPartDevice from './components/devices_inscription/devices/addDevice/AddWModbusPartDevice/AddWModbusPartDevice';
import MsbCreationWindow from './components/customers/installation/battery/creation/MsbCreationWindow';
import DeviceInscriptionMySmartBatteryForm from './components/devices_inscription/mySmartBattery/DeviceInscriptionMySmartBatteryForm';

interface IStoresProps {
  isReadOnly: boolean;
}

const PrivateRoute = safeMobxInject<IStoresProps, {}>(
  ({ Store: { profile } }) => ({ isReadOnly: profile.isReadOnly }),
  ({ children, isReadOnly, ...rest }) => {
    return (
      <Route
        {...rest}
        render={() =>
          isReadOnly ? (
            <ContextQuery>
              <MySmartBatteryListPage isReadOnly={true}>
                <MySmartBatteryListQuery />
              </MySmartBatteryListPage>
            </ContextQuery>
          ) : (
            children
          )
        }
      />
    );
  }
);

const AppRoute = () => {
  return (
    <>
      <Switch>
        <Route exact path="/login" component={LoginView} />
        <Route exact path="/404" component={NotFound} />
        <Route exact path="/forgotPassword" component={ForgotPasswordView} />
        <Route exact path="/">
          <Redirect to="/login" />
        </Route>
        <Route exact path="/home">
          <Redirect to="/dashboard" />
        </Route>
        <PrivateRoute>
          <ContextQuery>
            <Switch>
              <Route path="/devices_inscription/:customerId/:current/:pvInstallationId/">
                <DevicesInscription>
                  <Switch>
                    <Route path="/devices_inscription/:customerId/partnership/:pvInstallationId/:partner">
                      <Partnership>
                        <Route path="/devices_inscription/:customerId/partnership/:pvInstallationId/tado">
                          <Switch>
                            <Route
                              exact
                              path="/devices_inscription/:customerId/partnership/:pvInstallationId/tado/connecting"
                              component={TadoAccessQuery}
                            />
                            <Route
                              exact
                              path="/devices_inscription/:customerId/partnership/:pvInstallationId/tado/home"
                              component={TadoHomeQuery}
                            />
                            <Route
                              exact
                              path="/devices_inscription/:customerId/partnership/:pvInstallationId/tado/devices"
                              component={TadoDevicesQuery}
                            />
                          </Switch>
                        </Route>
                        <Route
                          path="/devices_inscription/:customerId/partnership/:pvInstallationId/enphase/connecting"
                          component={EnphaseRegistrationQuery}
                        />
                      </Partnership>
                    </Route>
                    <Route
                      exact
                      path="/devices_inscription/:customerId/devices/:pvInstallationId/add"
                      component={AddDeviceTypeList}
                    />
                    <Route
                      exact
                      path="/devices_inscription/:customerId/devices/:pvInstallationId/add/plc"
                      component={AddPLCDevice}
                    />
                    <Route
                      exact
                      path="/devices_inscription/:customerId/devices/:pvInstallationId/add/rs485"
                      component={GetModbusReferencesQuery}
                    />
                    <Route
                      exact
                      path="/devices_inscription/:customerId/devices/:pvInstallationId/add/wmodbus"
                      component={SelectWModbusDevice}
                    />
                    <Route
                      exact
                      path="/devices_inscription/:customerId/devices/:pvInstallationId/add/wmodbus/box"
                      component={AddWModbusBoxDevice}
                    />
                    <Route
                      exact
                      path="/devices_inscription/:customerId/devices/:pvInstallationId/add/wmodbus/part"
                      component={AddWModbusPartDevice}
                    />
                    <Route
                      path="/devices_inscription/:customerId/functions/:pvInstallationId/:plcMac/:ethMac"
                      component={EditDeviceFunction}
                    />
                    <Route
                      path="/devices_inscription/:customerId/devices/:pvInstallationId/:plcMac/:ethMac"
                      component={DeviceDetail}
                    />
                    <Route path="/devices_inscription/:customerId/:current/:pvInstallationId">
                      <InscriptionNavigation>
                        <Switch>
                          <Route
                            exact
                            path="/devices_inscription/:customerId/pv_installation/:pvInstallationId"
                            component={EditInstallationQueryFormInscription}
                          />
                          <Route
                            exact
                            path="/devices_inscription/:customerId/battery/:pvInstallationId"
                            component={DeviceInscriptionMySmartBatteryForm}
                          />
                          <Route
                            path="/devices_inscription/:customerId/partnership/:pvInstallationId"
                            component={PartnershipsListQuery}
                          />
                          <Route
                            exact
                            path="/devices_inscription/:customerId/ucg/:pvInstallationId"
                            component={UCGQuery}
                          />
                          <Route
                            exact
                            path="/devices_inscription/:customerId/master/:pvInstallationId"
                            component={MasterQuery}
                          />
                          <Route
                            exact
                            path="/devices_inscription/:customerId/devices/:pvInstallationId"
                            component={DevicesQuery}
                          />
                          <Route
                            exact
                            path="/devices_inscription/:customerId/functions/:pvInstallationId"
                            component={FunctionsView}
                          />
                          <Route
                            exact
                            path="/devices_inscription/:customerId/finish/:pvInstallationId"
                            component={FinishInstallationQuery}
                          />
                        </Switch>
                      </InscriptionNavigation>
                    </Route>
                  </Switch>
                </DevicesInscription>
              </Route>
              <Route exact path="/barcode/:type" component={BarCodeReader} />
              <Route exact path="/createCustomer">
                <CreateCustomer />
              </Route>
              <Route
                exact
                path={'/customers/:customerId/battery'}
                component={MsbCreationWindow}
              />
              <Route path="/customers/:id/edit">
                <Switch>
                  <Route
                    exact
                    path="/customers/:id/edit/identity"
                    component={EditIdentity}
                  />
                  <Route
                    exact
                    path="/customers/:customerId/edit/installation/:pVInstallationId"
                    component={EditInstallationQuery}
                  />
                </Switch>
              </Route>
              <Route path="/:current">
                <MainMenu>
                  <Switch>
                    <Route
                      exact
                      path="/toolTestPairing"
                      component={ToolsTestPairingView}
                    />
                    <Route exact path="/dashboard" component={Dashboard} />
                    <Route
                      exact
                      path="/customers/:customerId/diagnostics/:testSetId"
                      component={DiagnosticDetailQuery}
                    />
                    <Route exact path="/customers/:current">
                      <Customers>
                        <Switch>
                          <Route
                            exact
                            path="/customers/list"
                            component={CustomersListQuery}
                          />
                          <Route
                            exact
                            path="/customers/map"
                            component={CustomersMap}
                          />
                        </Switch>
                      </Customers>
                    </Route>
                    <Route path="/customers/:id/:current">
                      <CustomersMenu
                        baseRoute="/customers"
                        previousRoute="/customers/list"
                      >
                        <Switch>
                          <Route
                            exact
                            path="/customers/:id/identity"
                            component={Identity}
                          />
                          <Route
                            exact
                            path="/customers/:id/installation"
                            component={Installation}
                          />
                          <Route
                            exact
                            path="/customers/:id/pro_contact"
                            component={ProContact}
                          />
                          <Route
                            exact
                            path="/customers/:id/systems"
                            component={SystemView}
                          />
                          <Route
                            exact
                            path="/customers/:id/support"
                            component={SAVToolsView}
                          />
                        </Switch>
                      </CustomersMenu>
                    </Route>
                    <Route exact path="/mySmartBattery/:current">
                      <MySmartBatteryListPage>
                        <Route
                          exact
                          path="/mySmartBattery/list"
                          component={MySmartBatteryListQuery}
                        />
                      </MySmartBatteryListPage>
                    </Route>
                    <Route exact path="/support" component={SupportView} />
                    <Route path="/">
                      <Redirect to="/404" />
                    </Route>
                  </Switch>
                </MainMenu>
              </Route>
            </Switch>
          </ContextQuery>
        </PrivateRoute>
      </Switch>
      <SnackBar />
    </>
  );
};

export default AppRoute;
