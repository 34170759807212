import React from 'react';
import { Col, Row } from 'reactstrap';
import '../devices/deviceItem.scss';
import { translate, TranslationFunction } from 'react-i18next';
import classnames from 'classnames';

import ListItem from '../../../ui/DataDisplay/List/ListItem';
import TenantLogo from '../../../assets/images/tenant/TenantLogo';
import { safeMobxInject } from '../../../stores/storeInjectionHelpers';

interface IStoresProps {
  isMSTConnected: boolean;
  mstCode?: string;
  mstEthMac?: string;
  mstName?: string;
}

interface IProps {
  t: TranslationFunction;
}

const MasterDetails = safeMobxInject<IStoresProps, IProps>(
  stores => ({
    isMSTConnected: stores.inscription.isMSTConnected,
    mstCode: stores.inscription.mstCode,
    mstEthMac: stores.inscription.mstEthMac,
    mstName: stores.inscription.mstName
  }),
  ({
    t,
    isMSTConnected,
    mstCode,
    mstEthMac,
    mstName
  }: IStoresProps & IProps) => {
    const DeviceInfo = (
      <div className={'device-container'}>
        <Col className={classnames('device-info')}>
          <Col>
            <Row className={'device-title'}>
              <TenantLogo />
              <Col>
                <div className="device-name">{mstName && t(mstName)}</div>
                <div>
                  <span
                    className={classnames(
                      'badge',
                      'badge-pill',
                      isMSTConnected ? 'badge-success' : 'badge-secondary'
                    )}
                  >
                    {isMSTConnected ? t('Devices:on') : t('Devices:offline')}
                  </span>
                </div>
              </Col>
            </Row>
          </Col>
          <Row>
            <Col className={'device-details'}>
              <div>
                <span className="mac">{t('Devices:mac')}:</span>
                <span>{mstEthMac}</span>
              </div>
              <div>
                <span className="activation-code">
                  {t('Devices:activationCode')}:
                </span>
                <span>{mstCode}</span>
              </div>
            </Col>
          </Row>
        </Col>
      </div>
    );

    return (
      <ListItem className={classnames('registration-device-item')}>
        {DeviceInfo}
      </ListItem>
    );
  }
);

export default translate(['Common', 'Devices', 'RoomName'])(MasterDetails);
