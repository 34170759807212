import * as React from 'react';

import gql from 'graphql-tag';
import { RouteComponentProps, withRouter } from 'react-router';
import { observer, inject } from 'mobx-react';
import { Query, QueryResult } from 'react-apollo';
import { PPTadoHome } from '../../../../graphql/graphql.schema';
import TadoHomeView from './TadoHomeView';
import history from '../../../../history';
import { IAllStores } from '../../../../stores/allStores.model';
import { TadoStore } from '../../../../stores/partnerships/tadoStore';
import { IInscriptionNavigationRouterParamProps } from '../../InscriptionNavigation';

export const GET_TADO_HOME = gql`
  query getTadoHomes($customerId: String!) {
    getTadoHomes(customerId: $customerId) {
      id
      name
    }
  }
`;
interface IProps
  extends RouteComponentProps<IInscriptionNavigationRouterParamProps> {
  tadoStore?: TadoStore;
}
interface IResponse {
  getTadoHomes?: PPTadoHome[];
}

const TadoHomeQuery = inject(({ tadoStore }: IAllStores) => {
  return {
    tadoStore
  };
})(
  observer(
    ({
      tadoStore,
      match: {
        params: { customerId, pvInstallationId }
      }
    }: IProps) => {
      const { status, tadoAccessGranted } = tadoStore!;
      if (status === 'disconnected' && !tadoAccessGranted) {
        history.push(
          `/devices_inscription/${customerId}/partnership/${pvInstallationId}/tado/connecting`
        );
      } else if (status === 'connected') {
        history.push(
          `/devices_inscription/${customerId}/partnership/${pvInstallationId}/tado/devices`
        );
      }
      return (
        <Query query={GET_TADO_HOME} variables={{ customerId }}>
          {({ loading, data }: QueryResult<IResponse>) => {
            return (
              <TadoHomeView
                loading={loading}
                tadoHomes={(data && data.getTadoHomes) || []}
              />
            );
          }}
        </Query>
      );
    }
  )
);

export default withRouter(TadoHomeQuery);
