import React from 'react';
import { IDeviceIcon } from '../DeviceIcons';

const DVDPlayer = (props: IDeviceIcon) => (
  <svg
    width="3rem"
    height="3rem"
    viewBox="0 0 20000 20000"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    color="black"
    {...props}
  >
    <g id="prefix__layer">
      <g id="prefix___2098533576112">
        <path
          style={{ fill: `${props.color}` }}
          d="M13738 12229c-204 0-312-109-421-258H6592c-109 163-258 258-421 258s-367-109-421-258h-258c-421 0-734-312-734-734V8248c0-367 312-679 734-679h9184c367 0 679 312 679 679v2989c0 421-312 734-679 734h-530c-95 149-204 258-408 258zM5437 7977c-149 0-258 163-258 258v2989c0 109 109 258 258 258h476c109 0 204 109 204 204 0 0 0 54 54 54 0 0 54 0 54-54 0-109 109-204 204-204h7092c109 0 204 54 204 163 54-109 109-163 204-163h679c163 0 258-163 258-258V8249c0-109-109-258-258-258H5437v-14zm8785 2501c-258 0-476-204-476-476 0-258 204-476 476-476 258 0 476 204 476 476s-217 476-476 476zm0-516s-54 0 0 0c-54 54 0 54 0 54v-54zm-2440-619H5913c-109 0-204-109-204-217v-265c0-109 109-217 204-217h5883c109 0 204 109 204 217v265c4 122-54 217-217 217z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M12123 10890c-109 0-196-58-254-167-29 109-138 167-254 167s-196-58-225-138c-58 87-138 138-225 138-138 0-254-109-254-254 0-138 109-254 254-254 116 0 196 58 225 138 58-80 138-138 225-138 109 0 196 58 254 167 29-109 138-167 254-167 138 0 254 109 254 254s-116 254-254 254zm0-275c-29 29 0 29 0 29s29-7 0-29c29 0 29 0 0 0zm-478 0s-29 0 0 0c-29 29 0 29 0 29s29-7 0-29c29 0 0 0 0 0zm-471 0c-29 29 0 29 0 29 29-7 29-7 0-29 29 0 29 0 0 0zM7071 10594H5867c-84 0-158-109-158-204v-312c0-109 84-204 158-204h1215c84 0 158 109 158 204v312c0 95-84 204-169 204zM8808 10594H7604c-84 0-158-109-158-204v-312c0-109 84-204 158-204h1215c84 0 158 109 158 204v312c0 95-84 204-169 204z"
        />
      </g>
    </g>
  </svg>
);

export default DVDPlayer;
