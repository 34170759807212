import { default as React } from 'react';

export const IconDownload = () => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.59961 20.4C3.59961 19.7373 4.13687 19.2 4.79961 19.2H19.1996C19.8624 19.2 20.3996 19.7373 20.3996 20.4C20.3996 21.0628 19.8624 21.6 19.1996 21.6H4.79961C4.13687 21.6 3.59961 21.0628 3.59961 20.4ZM7.55108 11.1515C8.01971 10.6829 8.77951 10.6829 9.24814 11.1515L10.7996 12.703L10.7996 3.60002C10.7996 2.93728 11.3369 2.40002 11.9996 2.40002C12.6623 2.40002 13.1996 2.93728 13.1996 3.60002L13.1996 12.703L14.7511 11.1515C15.2197 10.6829 15.9795 10.6829 16.4481 11.1515C16.9168 11.6201 16.9168 12.3799 16.4481 12.8486L12.8481 16.4486C12.6231 16.6736 12.3179 16.8 11.9996 16.8C11.6813 16.8 11.3761 16.6736 11.1511 16.4486L7.55108 12.8486C7.08245 12.3799 7.08245 11.6201 7.55108 11.1515Z"
      fill="#018FA7"
    />
  </svg>
);
