import React, { Component } from 'react';
import List from '../../../../ui/DataDisplay/List/ListWrapper';
import { inject, observer } from 'mobx-react';
import ListItem from '../../../../ui/DataDisplay/List/ListItem';
import { Button, Col, Row } from 'reactstrap';
import Spinner from '../../../../ui/Spinner/Spinner';
import { translate, TranslationFunction } from 'react-i18next';
import tools from '../../../../utils/tools';
import history from '../../../../history';
import { RouteComponentProps, withRouter } from 'react-router';
import { IAllStores } from '../../../../stores/allStores.model';
import { TadoStore } from '../../../../stores/partnerships/tadoStore';

interface IProps extends RouteComponentProps<IMatchParams> {
  tadoStore?: TadoStore;
  t: TranslationFunction;
  data?: any;
  loading?: boolean;
  error?: any;
  tadoUri: string;
}
interface IMatchParams {
  customerId: string;
  pvInstallationId: string;
}

@inject(({ tadoStore }: IAllStores) => {
  return {
    tadoStore
  };
})
@observer
class TadoAuthenticationView extends Component<IProps> {
  public render() {
    const {
      tadoStore,
      t,
      match: {
        params: { customerId, pvInstallationId }
      }
    } = this.props;
    const { tadoConnectInProgress, setTadoConnectInProgress } = tadoStore!;

    return (
      <List fluid={true}>
        <ListItem className="tado-container">
          <Col className="tado-content">
            <Row>
              <span className="tado-title">
                {tadoConnectInProgress
                  ? t('TadoPartnership:connectingTado')
                  : t('TadoPartnership:login')}
              </span>
            </Row>
            {tadoConnectInProgress && (
              <div className={'tado-auth-loading'}>
                <Spinner loading={true} />
              </div>
            )}
            <Row className="tado-button">
              <Button
                color="primary connect"
                onClick={() => {
                  setTadoConnectInProgress(true);
                  tools.openNewWindowWithParams(
                    this.props.tadoUri,
                    'width=1280,height=768'
                  );
                }}
              >
                {tadoConnectInProgress
                  ? t('TadoPartnership:reopenTadoLoginWindow')
                  : t('TadoPartnership:connectingTado')}
              </Button>
            </Row>
            <Row className="tado-button">
              <Button
                color="primary outline"
                onClick={() => {
                  setTadoConnectInProgress(false);
                  history.push(
                    `/devices_inscription/${customerId}/partnership/${pvInstallationId}`
                  );
                }}
              >
                {t('Partnerships:cancel')}
              </Button>
            </Row>
          </Col>
        </ListItem>
      </List>
    );
  }
}

export default translate(['TadoPartnership, Partnerships'])(
  withRouter(TadoAuthenticationView)
);
