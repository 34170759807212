import React from 'react';
import { IDeviceIcon } from '../DeviceIcons';

const WiserThermostat = (props: IDeviceIcon) => (
  <svg
    width="3rem"
    height="3rem"
    viewBox="0 0 20000 20000"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    color="black"
    {...props}
  >
    <g id="prefix__Layer_x0020_1">
      <path
        style={{ fill: `${props.color}` }}
        d="M10369 15089v-4201h-823v4199c-495 166-823 618-823 1152 0 700 536 1236 1236 1236s1236-536 1236-1236c-2-532-332-984-827-1150z"
      />
      <path
        style={{ fill: `${props.color}` }}
        d="M11194 14596V3048c0-700-536-1236-1236-1236s-1236 577-1236 1236v11546c-495 370-823 988-823 1647 0 1152 907 2058 2058 2058 1152 0 2058-907 2058-2058 0-659-329-1275-823-1645zm-1236 3294c-907 0-1647-741-1647-1647 0-618 329-1152 823-1440V3048c0-452 370-823 823-823 452 0 823 370 823 823v11752c495 289 823 823 823 1441 4 907-736 1649-1643 1649z"
      />
    </g>
  </svg>
);

export default WiserThermostat;
