import React from 'react';
import { Col, Row } from 'reactstrap';
import { translate, TranslationFunction } from 'react-i18next';

interface IProps {
  t: TranslationFunction;
}
const RebootMasterWarning = ({ t }: IProps) => {
  return (
    <Col>
      <Row>
        <>
          <b>{`${t('Master:informations')} `}</b>
          {t('Master:informationRebootMasterWhenChangeTypeConnection')}
        </>
      </Row>
    </Col>
  );
};

export default translate('Master')(RebootMasterWarning);
