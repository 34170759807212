import React from 'react';

import gql from 'graphql-tag';
import { RouteComponentProps, withRouter } from 'react-router';
import { safeMobxInject } from '../../../../stores/storeInjectionHelpers';
import EnphaseEnvoyView from './EnphaseEnvoyView';
import { Mutation, MutationFn, MutationResult, Query } from 'react-apollo';
import { TranslationFunction } from 'i18next';
import { translate } from 'react-i18next';
import { EnphaseStore } from '../../../../stores/partnerships/enphaseStore';
import i18n from '../../../../translations/i18n';

export const GET_ENPHASE_INSTALLER_ID = gql`
  query GetEnphaseInstallerId {
    proUser {
      id
      enphaseInstallerId
    }
  }
`;

export const REGISTER_ENPHASE_SYSTEM = gql`
  mutation enphaseRegisterEnp(
    $customerId: String!
    $envoySerialNumber: String!
    $enphaseSystemId: String
    $enphaseInstallerId: Int
    $lang: String
  ) {
    enphaseRegisterEnp(
      customerId: $customerId
      envoySerialNumber: $envoySerialNumber
      enphaseSystemId: $enphaseSystemId
      enphaseInstallerId: $enphaseInstallerId
      lang: $lang
    )
  }
`;

export interface IStoreProps {
  enphaseStore: EnphaseStore;
}

export interface IProps extends RouteComponentProps<IMatchParams> {
  t: TranslationFunction;
}

interface IMatchParams {
  customerId: string;
  pvInstallationId: string;
}

interface IMutationVars {
  customerId: string;
  envoySerialNumber: string;
  enphaseSystemId?: string;
  enphaseInstallerId?: number;
  lang?: string;
}

const EnphaseEnvoyQuery = safeMobxInject<IStoreProps, IProps>(
  allStores => ({
    enphaseStore: allStores.enphaseStore
  }),
  ({
    enphaseStore,
    t,
    match: {
      params: { customerId, pvInstallationId }
    },
    history
  }) => (
    <Query query={GET_ENPHASE_INSTALLER_ID}>
      {({ data: dataProUser, loading: loadingProUser }) => {
        if (dataProUser && dataProUser.proUser) {
          enphaseStore.updateEnphaseInstallerId(
            dataProUser.proUser.enphaseInstallerId
          );
        }

        return (
          <Mutation
            mutation={REGISTER_ENPHASE_SYSTEM}
            onCompleted={() => {
              history.push(
                `/devices_inscription/${customerId}/partnership/${pvInstallationId}`
              );
            }}
          >
            {(
              enphaseRegisterEnp: MutationFn<any, IMutationVars>,
              { loading, error }: MutationResult<any>
            ) => {
              const registerEnphaseDevice = () =>
                enphaseRegisterEnp({
                  variables: {
                    customerId,
                    envoySerialNumber: enphaseStore.envoySerialNumber,
                    lang: i18n.language,
                    ...(enphaseStore.envoyRegistered
                      ? { enphaseSystemId: enphaseStore.enphaseSystemId }
                      : { enphaseInstallerId: enphaseStore.enphaseInstallerId })
                  }
                });

              enphaseStore.clearEnvoyErrors();

              if (error) {
                error.graphQLErrors.map(e => {
                  if (
                    e.message.error === 'enphase.device.serialnumbers.invalid'
                  ) {
                    enphaseStore.addEnvoyError(
                      `${t('EnphaseErrors:serialNumber')}`
                    );
                  } else if (
                    e.message.error === 'ecn.activation.code.invalid'
                  ) {
                    enphaseStore.addEnvoyError(
                      `${t('EnphaseErrors:activationCode')}`
                    );
                  } else if (
                    e.message.error === 'enphase.device.systemowner.invalid' ||
                    e.message.error ===
                      'enphase.device.serialnumbers.duplicated'
                  ) {
                    enphaseStore.addEnvoyError(`${t('EnphaseErrors:owner')}`);
                  } else if (e.message.error === 'systemId.invalid') {
                    enphaseStore.addEnvoyError(
                      `${t('EnphaseErrors:systemId')}`
                    );
                  } else {
                    enphaseStore.addEnvoyError(`${t('EnphaseErrors:unknown')}`);
                  }
                });
              }

              return (
                <EnphaseEnvoyView
                  loading={loadingProUser || loading}
                  registerEnphaseDevice={registerEnphaseDevice}
                />
              );
            }}
          </Mutation>
        );
      }}
    </Query>
  )
);

export default withRouter(translate()(EnphaseEnvoyQuery));
