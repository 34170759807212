import React from 'react';
import { translate, TranslationFunction } from 'react-i18next';
import { Button, Collapse, Row } from 'reactstrap';
import { ModBusReference } from '../../../../../graphql/graphql.schema';
import { safeMobxInject } from '../../../../../stores/storeInjectionHelpers';
import SelectInput from '../../../../../ui/Select/Select';
import '../addDevice.scss';

interface IProps {
  t: TranslationFunction;
  onSave: () => void;
  onCancel: () => void;
  enableCancel: boolean;
}

interface IStoresProps {
  availableReferences: string[];
  chosenReference?: ModBusReference;
  setChosenReference: (reference: string) => void;
  availableAddresses: string[];
  chosenAddress?: string;
  setChosenAddress: (address: string) => void;
  resetChosenReferenceAndAddress: () => void;
}

const WModbusDeviceForm = safeMobxInject<IStoresProps, IProps>(
  (allStores): IStoresProps => ({
    availableReferences: allStores.addDevice.availableReferences,
    chosenReference: allStores.addDevice.chosenReference,
    setChosenReference: allStores.addDevice.setChosenReference,
    availableAddresses: allStores.addDevice.availableAddresses,
    chosenAddress: allStores.addDevice.chosenAddress,
    setChosenAddress: allStores.addDevice.setChosenAddress,
    resetChosenReferenceAndAddress:
      allStores.addDevice.resetChosenReferenceAndAddress
  }),
  ({
    t,
    onSave,
    onCancel,
    enableCancel,
    availableReferences,
    chosenReference,
    setChosenReference,
    availableAddresses,
    chosenAddress,
    setChosenAddress,
    resetChosenReferenceAndAddress
  }) => {
    const handleAddDevice = () => {
      if (chosenReference && chosenAddress) {
        onSave();
      }
    };

    const handleCancel = () => {
      resetChosenReferenceAndAddress();
      onCancel();
    };

    React.useEffect(() => {
      resetChosenReferenceAndAddress();
    }, []);

    return (
      <div className={'mt-3'}>
        <Row>
          <SelectInput
            options={availableReferences.map(r => ({
              label: r,
              value: r
            }))}
            name={t('Devices:modbusReference')}
            id={'reference'}
            onChange={e => setChosenReference(e.value)}
            placeholder={t('Devices:chooseReference')}
            value={
              chosenReference
                ? {
                    label: chosenReference.reference,
                    value: chosenReference.reference
                  }
                : null
            }
          />
        </Row>

        <Collapse isOpen={!!chosenReference}>
          <Row>
            <SelectInput
              options={availableAddresses.map(r => ({
                label: r,
                value: r
              }))}
              name={t('Devices:modbusAddress')}
              id={'modbus'}
              onChange={e => setChosenAddress(e.value)}
              placeholder={t('Devices:chooseAddress')}
              value={
                chosenAddress
                  ? { label: chosenAddress, value: chosenAddress }
                  : null
              }
            />
          </Row>
        </Collapse>

        <Row className={'add-device-button'}>
          {enableCancel && (
            <Button color={'secondary'} onClick={handleCancel}>
              {t('Common:cancel')}
            </Button>
          )}

          <Button
            color={'primary'}
            onClick={handleAddDevice}
            disabled={!chosenReference || !chosenAddress}
          >
            {t('Common:associate')}
          </Button>
        </Row>
      </div>
    );
  }
);

export default translate()(WModbusDeviceForm);
