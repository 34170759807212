import * as React from 'react';
import { Query, QueryResult } from 'react-apollo';
import { RouteComponentProps, withRouter } from 'react-router';
import gql from 'graphql-tag';
import Spinner from '../../../../../ui/Spinner/Spinner';
import { Customer, MSB } from '../../../../../graphql/graphql.schema';
import { IBarCode } from '../../../../../stores/barcode';
import { safeMobxInject } from '../../../../../stores/storeInjectionHelpers';

export interface IProps extends RouteComponentProps<IMatchParams> {
  children?: any;
  skip?: boolean;
}

interface IStoresProps {
  setScannedCode: (code: IBarCode) => void;
  setCustomerCountry: (country: string) => void;
  setCustomerTenant: (tenant: string) => void;
  setMSBInfo: (MSBInfo: MSB) => void;
  clearMSBInfo: (withSerialNumbersReset?: boolean) => void;
  setCustomerStatus: (customer: string) => void;
  setPVInstallationId: (id: string) => void;
  setPdlNumber: (pdlNumber: string) => void;
}

interface IMatchParams {
  customerId: string;
  id: string;
}

interface IResponse {
  getMSB?: MSB;
  customer?: Customer;
}

const GET_MSB_INFO = gql`
  query getMSB($customerId: String!) {
    customer(id: $customerId) {
      id
      country
      pvInstallations {
        id
        srsRegistrationStatus
        electricInstallations {
          id
          pdlNumber
        }
        tenant {
          id
          name
        }
      }
    }
    getMSB(customerId: $customerId) {
      id
      status {
        status
        validateAt
        isValidate
      }
      pdlNumber
      pdlNumberModifiedAt
      energyDistributor {
        id
        name
      }
      caeNumber
      enedisConnectNumber
      enedisConnectNumberModifiedAt
      consuelNumber
      consuelNumberModifiedAt
      consuelDeclaredPower
      consuelDeclaredPowerModifiedAt
      connectionPower
      connectionPowerModifiedAt
      strasbourgInjectionPdlNumber
      strasbourgInjectionPdlNumberModifiedAt
      serialNumberModifiedAt
      boxesSerialNumber
      volume
      customerId
      sendingCAEDate
      sendingARPEDate
      sendingCONSUELDate
      type
    }
  }
`;

const BatteryGetInfoQuery = safeMobxInject<IStoresProps, IProps>(
  stores => ({
    setMSBInfo: stores.battery.setMSBInfo,
    clearMSBInfo: stores.battery.clearMSBInfo,
    setCustomerStatus: stores.battery.setCustomerStatus,
    setPdlNumber: stores.battery.setPdlNumber,
    setScannedCode: stores.barcode.setScannedCode,
    setCustomerCountry: stores.inscription.setCustomerCountry,
    setCustomerTenant: stores.inscription.setCustomerTenant,
    setPVInstallationId: stores.inscription.setPVInstallationId
  }),
  ({
    children,
    match: {
      params: { customerId, id }
    },
    history,
    skip,
    setScannedCode,
    setMSBInfo,
    clearMSBInfo,
    setCustomerStatus,
    setPdlNumber,
    setCustomerCountry,
    setCustomerTenant,
    setPVInstallationId
  }: IProps & IStoresProps) => {
    return (
      <Query
        query={GET_MSB_INFO}
        variables={{ customerId: customerId || id }}
        fetchPolicy={'no-cache'}
        errorPolicy={'ignore'}
        skip={skip || false}
        onCompleted={(response: IResponse) => {
          const MSBInfo = response.getMSB;
          const customerInfo = response.customer;
          const customerInfoPdlNumber =
            customerInfo &&
            customerInfo.pvInstallations![0].electricInstallations![0]
              .pdlNumber!;

          if (MSBInfo) {
            setMSBInfo(MSBInfo);
          } else {
            clearMSBInfo();
          }

          if (customerInfo) {
            setCustomerStatus(
              customerInfo.pvInstallations![0].srsRegistrationStatus!
            );
            setCustomerCountry(customerInfo.country!);
            setCustomerTenant(customerInfo.pvInstallations![0].tenant!.name!);
            setPVInstallationId(customerInfo.pvInstallations![0].id);
            if ((!MSBInfo || !MSBInfo.pdlNumber) && customerInfoPdlNumber) {
              setPdlNumber(customerInfoPdlNumber);
            }
          }

          setScannedCode({ type: undefined, value: '' });
        }}
      >
        {({ loading }: QueryResult<IResponse>) => {
          return <Spinner loading={loading}>{children}</Spinner>;
        }}
      </Query>
    );
  }
);

export default withRouter(BatteryGetInfoQuery);
