import { default as React } from 'react';

interface IProps {
  size?: number;
}

export const IconUpload = () => (
  <svg width="17" height="16" viewBox="0 0 17 16">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.83724 11.3333C3.83724 12.0697 4.43419 12.6667 5.17057 12.6667L11.8372 12.6667C12.5736 12.6667 13.1706 12.0697 13.1706 11.3333L13.1706 10.6667C13.1706 10.2985 13.469 10 13.8372 10C14.2054 10 14.5039 10.2985 14.5039 10.6667L14.5039 11.3333C14.5039 12.8061 13.31 14 11.8372 14L5.17057 14C3.69781 14 2.50391 12.8061 2.50391 11.3333L2.50391 10.6667C2.50391 10.2985 2.80238 10 3.17057 10C3.53876 10 3.83724 10.2985 3.83724 10.6667L3.83724 11.3333ZM5.36583 5.80474C5.10549 5.54439 5.10549 5.12228 5.36583 4.86193L8.0325 2.19526C8.15753 2.07024 8.32709 2 8.50391 2C8.68072 2 8.85029 2.07024 8.97531 2.19526L11.642 4.86193C11.9023 5.12228 11.9023 5.54439 11.642 5.80474C11.3816 6.06509 10.9595 6.06509 10.6992 5.80474L9.17057 4.27614L9.17057 10.6667C9.17057 11.0349 8.87209 11.3333 8.5039 11.3333C8.13571 11.3333 7.83724 11.0349 7.83724 10.6667L7.83724 4.27614L6.30864 5.80474C6.04829 6.06509 5.62618 6.06509 5.36583 5.80474Z"
    />
  </svg>
);
