import { inject, observer } from 'mobx-react';
import { Store } from '../../stores';
import CreateCustomerByInstallerQuery from './Installer/CreateCustomerForInstallerQuery';
import CreateCustomerByMaintainerQuery from './Maintainer/CreateCustomerByMaintainerQuery';
import { RouteComponentProps, withRouter } from 'react-router';
import { translate, TranslationFunction } from 'react-i18next';
import { default as React, useEffect } from 'react';
import { UserTypes } from '../../constants/UserTypes';

interface IProps extends RouteComponentProps {
  Store?: Store;
  t: TranslationFunction;
}
const CreateCustomer = inject('Store')(
  observer(({ Store: mobix, t, history }: IProps) => {
    useEffect(() => {
      mobix!.createCustomer.reset();
    }, []);

    if (mobix!.profile.userType === UserTypes.INSTALLER) {
      return <CreateCustomerByInstallerQuery />;
    }
    if (mobix!.profile.userType === UserTypes.MAINTAINER) {
      return <CreateCustomerByMaintainerQuery />;
    }

    if (mobix!.profile.userType === UserTypes.ADMINISTRATOR) {
      return <span>Only maintainer and installer can create customer</span>;
    }
    history.push('/dashboard');
    // should not append anymore ...
    return <span>{t('redirecting')}</span>;
  })
);

export default translate('Commmon')(withRouter(CreateCustomer));
