import { default as React, useState } from 'react';
import { Query, QueryResult } from 'react-apollo';
import { RouteComponentProps, withRouter } from 'react-router';
import InstallationView from './InstallationView';
import {
  extractInstallation,
  IExtractedInstallation
} from './installationExtractor';
import { Customer } from '../../../graphql/graphql.schema';
import { GET_CUSTOMER } from '../identity/IdentityQuery';
import { InstallationContext } from './Installation.context';

interface IResponse {
  customer: Customer;
}

interface IMatchParams {
  id: string;
}

export interface IProps extends RouteComponentProps<IMatchParams> {}

const InstallationQuery = ({
  match: {
    params: { id }
  },
  history
}: IProps) => {
  const [installation, setInstallation] = useState<IExtractedInstallation>();

  return (
    <Query
      query={GET_CUSTOMER}
      variables={{ id }}
      onCompleted={data => {
        setInstallation(
          data && data.customer && extractInstallation(data.customer)
        );
      }}
      fetchPolicy={'no-cache'}
    >
      {({ loading, error, refetch }: QueryResult<IResponse>) => (
        <InstallationContext.Provider value={{ installation, setInstallation }}>
          <InstallationView
            customerId={id}
            loading={loading}
            error={error}
            refetch={refetch}
            goToEdit={() => {
              if (installation) {
                history.push(
                  `/customers/${id}/edit/installation/${installation.id}`
                );
              }
            }}
          />
        </InstallationContext.Provider>
      )}
    </Query>
  );
};

export default withRouter(InstallationQuery);
