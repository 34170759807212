import React, { Component } from 'react';
import IsolatedComponent from '../../../../../ui/IsolatedComponent/IsolatedComponent';
import { translate, TranslationFunction } from 'react-i18next';
import { FormInput } from '../../../../../ui/FormInput/FormInput';
import { Button, Col, Form, Row } from 'reactstrap';

import '../addDevice.scss';
import { RouteComponentProps, withRouter } from 'react-router';
import { inject, observer } from 'mobx-react';
import { IAllStores } from '../../../../../stores/allStores.model';
import CustomModal from '../../../../../ui/CustomModal/CustomModal';
import { IInscriptionNavigationRouterParamProps } from '../../../InscriptionNavigation';
import ScanBarcodeButton from '../../../barcode/ScanBarcodeButton';
import AddPLCDeviceButton from './AddPLCDeviceButton';
import { getDevicePath } from '../../../routingHelper';
import { convertTranslation } from '../../../../../utils/convertTranslations';

interface IProps
  extends RouteComponentProps<IInscriptionNavigationRouterParamProps> {
  t: TranslationFunction;
  macOrAC: string;
  setMacOrAC: (macOrAC: string) => void;
  canAddPLCDevice: boolean;
  resetAddDeviceStore?: () => void;
}
interface IState {
  isModalOpen: boolean;
}
@inject((allStores: IAllStores) => {
  return {
    macOrAC: allStores.addDevice.macOrAC,
    setMacOrAC: allStores.addDevice.setMacOrAC,
    canAddPLCDevice: allStores.addDevice.canAddPLCDevice,
    resetAddDeviceStore: allStores.addDevice.resetAddDeviceStore
  };
})
@observer
class AddPLCDevice extends Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = {
      isModalOpen: false
    };
  }

  public componentWillUnmount(): void {
    this.props.resetAddDeviceStore!();
  }

  public render() {
    const { isModalOpen } = this.state;
    const {
      t,
      history,
      match: {
        params: { customerId, pvInstallationId }
      },
      macOrAC,
      setMacOrAC,
      canAddPLCDevice
    } = this.props;
    const goBackToDeviceConnectionTypesList = () =>
      history.push(
        `/devices_inscription/${customerId}/devices/${pvInstallationId}/add`
      );
    return (
      <IsolatedComponent
        title={t('plcType')}
        subtitle={t('addDeviceTitlePLC')}
        sm={10}
        md={9}
        lg={6}
        xl={5}
        onGoBack={goBackToDeviceConnectionTypesList}
      >
        <Col className={'add-device plc'}>
          <Form className={'form-wrapper'}>
            <Row className={'scan-button'}>
              <ScanBarcodeButton codeType={'PLC'} />
            </Row>
            <Row>
              <FormInput
                id={'plc'}
                name={t('macOrAC')}
                value={macOrAC}
                onChange={e => setMacOrAC(e)}
                error={macOrAC && (canAddPLCDevice ? '' : t('invalidMacOrAC'))}
              />
            </Row>
            <Row className={'add-device-button plc'}>
              <Button
                color={'secondary'}
                onClick={goBackToDeviceConnectionTypesList}
              >
                {t('Common:cancel')}
              </Button>
              <AddPLCDeviceButton
                disabled={!canAddPLCDevice}
                showConfirmModal={() =>
                  this.setState({ isModalOpen: !isModalOpen })
                }
              />
            </Row>
          </Form>
        </Col>
        <CustomModal
          isOpened={isModalOpen}
          title={t('plcConnectingInProgress')}
          handleConfirm={() => {
            history.push(getDevicePath({ customerId, pvInstallationId }));
            this.setState({ isModalOpen: !isModalOpen });
          }}
          confirmLabel={t('Common:agree')}
        >
          {convertTranslation(t('plcConnectingInProgressExtended'))}
        </CustomModal>
      </IsolatedComponent>
    );
  }
}

export default withRouter(translate(['Devices', 'Common'])(AddPLCDevice));
