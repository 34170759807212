import * as React from 'react';
import { Query, QueryResult } from 'react-apollo';

import gql from 'graphql-tag';
import { RouteComponentProps, withRouter } from 'react-router';
import IsOfflineIndicator from './IsOfflineIndicator';

export const GET_ONLINE_STATUS = gql`
  query isSystemOnline($id: String!) {
    isSystemOnline(customerId: $id)
  }
`;

interface IResponse {
  isSystemOnline: boolean;
}

interface IMatchParams {
  id: string;
}

interface IProps extends RouteComponentProps<IMatchParams> {}

const IsOfflineIndicatorQuery = ({
  match: {
    params: { id }
  }
}: IProps) => {
  return (
    <Query query={GET_ONLINE_STATUS} variables={{ id }} pollInterval={60000}>
      {({ loading, data, error }: QueryResult<IResponse>) => {
        return !loading && !error && data && data.isSystemOnline === false ? (
          <IsOfflineIndicator />
        ) : null;
      }}
    </Query>
  );
};

export default withRouter(IsOfflineIndicatorQuery);
