import gql from 'graphql-tag';
import { Mutation, MutationResult } from 'react-apollo';
import { translate } from 'react-i18next';
import React from 'react';
import { TranslationFunction } from 'i18next';
import { ISnackBarParams } from '../../../../../stores/userFeedback/snackbarStore';
import { IUploadedFile } from './BatteryDocumentUploadModal';
import { safeMobxInject } from '../../../../../stores/storeInjectionHelpers';
import {
  DocumentType,
  Mutation as IMutation
} from '../../../../../graphql/graphql.schema';
import BatteryDocumentUploadButton from './BatteryDocumentUploadButton';
import { isNumber } from 'lodash';

interface IProps {
  t: TranslationFunction;
  disabled?: boolean;
  signedDocument: IUploadedFile | undefined;
  documentType: DocumentType;
  onCompleted: (date: Date) => void;
}

interface IStoresProps {
  showSnackBar: (params: ISnackBarParams) => void;
}

const UPLOAD_ARPE_DOCUMENT = gql`
  mutation uploadARPEDocument($file: Upload!, $customerId: String!) {
    uploadARPEDocument(file: $file, customerId: $customerId)
  }
`;

const UploadArpeQuery = safeMobxInject<IStoresProps, IProps>(
  stores => ({
    showSnackBar: stores.snackBar.showSnackBar
  }),
  ({
    t,
    disabled,
    signedDocument,
    documentType,
    onCompleted,
    showSnackBar
  }: IProps & IStoresProps) => {
    return (
      <Mutation
        mutation={UPLOAD_ARPE_DOCUMENT}
        onCompleted={(data: Partial<IMutation>) => {
          const isSuccess = isNumber(data.uploadARPEDocument);
          if (isSuccess) {
            showSnackBar({
              text: t('MySmartBattery:pdfSendingSuccess'),
              type: 'success'
            });
            onCompleted(data.uploadARPEDocument);
          } else {
            showSnackBar({
              text: t('MySmartBattery:pdfSendingError'),
              type: 'error'
            });
          }
        }}
        onError={() =>
          showSnackBar({
            text: t('MySmartBattery:pdfSendingError'),
            type: 'error'
          })
        }
      >
        {(uploadDocument, { loading }: MutationResult) => {
          return (
            <BatteryDocumentUploadButton
              disabled={disabled}
              loading={loading}
              uploadDocument={uploadDocument}
              signedDocument={signedDocument}
              documentType={documentType}
            />
          );
        }}
      </Mutation>
    );
  }
);

export default translate(['Common', 'MySmartBattery'])(UploadArpeQuery);
