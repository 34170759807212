import React, { ReactNode } from 'react';
import { Query } from 'react-apollo';
import { RouteComponentProps, withRouter } from 'react-router';
import { IInscriptionNavigationRouterParamProps } from './InscriptionNavigation';
import { GET_CUSTOMER_AND_DEVICE_CONFIG } from './DevicesInscription';
import { DELAY_REFRESH_CUSTOMER_AND_DEVICE_CONFIG } from '../../stores/inscription/inscriptionStore';
import i18n from '../../translations/i18n';

interface IProps
  extends RouteComponentProps<IInscriptionNavigationRouterParamProps> {
  children: ReactNode;
  fetchPolicy?: 'cache-and-network' | 'no-cache';
}

const RefreshDevicesInscriptionQuery = ({
  match: {
    params: { customerId, pvInstallationId }
  },
  children,
  fetchPolicy = 'no-cache'
}: IProps) => {
  return (
    <Query
      query={GET_CUSTOMER_AND_DEVICE_CONFIG}
      variables={{ customerId, pvInstallationId, lang: i18n.language }}
      fetchPolicy={fetchPolicy}
      pollInterval={DELAY_REFRESH_CUSTOMER_AND_DEVICE_CONFIG}
    >
      {() => {
        return <>{children}</>;
      }}
    </Query>
  );
};

export default withRouter(RefreshDevicesInscriptionQuery);
