import React from 'react';
import { IDeviceIcon } from '../DeviceIcons';

const CompositeDevice = (props: IDeviceIcon) => (
  <svg
    width="3rem"
    height="3rem"
    viewBox="0 0 20000 20000"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    color="black"
    {...props}
  >
    <g id="prefix__layer">
      <g id="prefix__asoka_electric_counter">
        <path
          style={{ fill: `${props.color}` }}
          d="M14064 13451H6103c-241 0-389-148-389-389V7138c0-241 148-389 389-389h7961c241 0 389 148 389 389v5924c0 74-74 241-148 315-19-19-93 74-241 74zm-7628-768h7285V7434H6436v5249z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M14064 15034H6103c-241 0-389-148-389-389V5537c0-241 148-389 389-389h7961c241 0 389 148 389 389v9108c0 74-74 241-148 315-19-19-93 74-241 74zm-7628-768h7285V5833H6436v8433z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M12738 9672H7460c-160 0-258-98-258-258V8378c0-160 98-258 258-258h5278c160 0 258 98 258 258v1036c0 49-49 160-98 209-12-12-61 49-160 49zm-5057-509h4830v-588H7681v588z"
        />
      </g>
    </g>
  </svg>
);

export default CompositeDevice;
