import * as React from 'react';

const ProsizerIcon = () => {
  return (
    <svg width="100%" viewBox="0 0 200 200">
      <path
        d="M 96.1,71.1 C 91.115027,71.1 86.602203,69.087153 83.329562,65.829494 80.03719,62.552194 78,58.015027 78,53 78,43 86.1,35 96.1,35 c 10,0 18.1,8.1 18.1,18.1 0,10 -8.1,18 -18.1,18 z m 0,-27.2 c -5,0 -9.2,4.2 -9.2,9.2 0,5 4.2,9.2 9.2,9.2 5,0 9.2,-4.2 9.2,-9.2 0,-5 -4.2,-9.2 -9.2,-9.2 z"
        id="path6386"
      />
      <path
        d="m 177.5,162.5 -155.8,0 c -1.7,0 -3.1,-0.8 -3.9,-2.2 -0.8,-1.4 -0.8,-3.1 0,-4.4 l 33.9,-54.2 c 0.8,-1.4 2.2,-2.2 3.9,-2.2 l 88.1,0 c 1.7,0 3.1,0.8 3.9,2.2 l 33.9,54.2 c 0.8,1.4 0.8,3.1 0,4.4 -0.8,1.3 -2.3,2.2 -4,2.2 z m -147.8,-8.9 139.7,0 -9.82596,-15.72848 L 141.1,108.3 l -83,0 z"
        id="path6388"
      />
      <path
        d="M99.4 162.5c-2.5 0-4.4-1.9-4.4-4.4v-54.2c0-2.5 1.9-4.4 4.4-4.4 2.5 0 4.4 1.9 4.4 4.4v54.2c.4 2.5-1.9 4.4-4.4 4.4z"
        id="path6390"
      />
      <path
        d="M58.9 162.5c-.6 0-.8 0-1.4-.3-2.2-.8-3.6-3.3-3.1-5.6l16.9-54.2c.8-2.2 3.3-3.6 5.6-3.1 2.2.8 3.6 3.3 3.1 5.6l-16.9 54.2c-.6 2.3-2.3 3.4-4.2 3.4z"
        id="path6392"
      />
      <path
        d="m 140.3,162.5 c -1.9,0 -3.6,-1.1 -4.2,-3.1 L 127.87583,133.02427 119.2,105.2 c -0.8,-2.2 0.6,-5 3.1,-5.6 2.2,-0.8 5,0.6 5.6,3.1 l 16.7,53.9 c 0.8,2.2 -0.6,5 -3.1,5.6 -0.4,0.3 -0.9,0.3 -1.2,0.3 z"
        id="path6394"
      />
      <path
        d="M151.9 121.9h-104.7c-2.5 0-4.4-1.9-4.4-4.4 0-2.5 1.9-4.4 4.4-4.4h105c2.5 0 4.4 1.9 4.4 4.4.1 2.5-2.2 4.4-4.7 4.4z"
        id="path6396"
      />
      <path
        d="M164.7 142.2h-130.3c-2.5 0-4.4-1.9-4.4-4.4 0-2.5 1.9-4.4 4.4-4.4h130.3c2.5 0 4.4 1.9 4.4 4.4.1 2.5-1.9 4.4-4.4 4.4z"
        id="path6398"
      />
      <path
        d="M96.1 30.6c-2.5 0-4.4-1.9-4.4-4.4v-3.6c0-2.5 1.9-4.4 4.4-4.4 2.5 0 4.4 1.9 4.4 4.4v3.3c.1 2.4-1.9 4.7-4.4 4.7z"
        id="path6400"
      />
      <path
        d="M76.9 38.3c-1.1 0-2.2-.6-3.1-1.4l-2.5-2.5c-1.7-1.7-1.7-4.4 0-6.4 1.7-1.7 4.4-1.7 6.4 0l2.5 2.5c1.7 1.7 1.7 4.4 0 6.4-.8 1.2-2.1 1.4-3.3 1.4z"
        id="path6402"
      />
      <path
        d="M69.2 57.5h-3.3c-2.5 0-4.4-1.9-4.4-4.4 0-2.5 1.9-4.4 4.4-4.4h3.3c2.5 0 4.4 1.9 4.4 4.4 0 2.5-1.9 4.4-4.4 4.4z"
        id="path6404"
      />
      <path
        d="M74.7 79.2c-1.1 0-2.2-.6-3.1-1.4-1.7-1.7-1.7-4.4 0-6.4l2.5-2.5c1.7-1.7 4.4-1.7 6.4 0 1.7 1.7 1.7 4.4 0 6.4l-2.8 2.5c-.8.8-1.9 1.4-3 1.4z"
        id="path6406"
      />
      <path
        d="M96.1 88.1c-2.5 0-4.4-1.9-4.4-4.4v-3.3c0-2.5 1.9-4.4 4.4-4.4 2.5 0 4.4 1.9 4.4 4.4v3.3c.1 2.4-1.9 4.4-4.4 4.4z"
        id="path6408"
      />
      <path
        d="M117.8 79.2c-1.1 0-2.2-.6-3.1-1.4l-2.5-2.5c-1.7-1.7-1.7-4.4 0-6.4 1.7-1.7 4.4-1.7 6.4 0l2.5 2.5c1.7 1.7 1.7 4.4 0 6.4-1.1.8-2.2 1.4-3.3 1.4z"
        id="path6410"
      />
      <path
        d="M126.7 57.5h-3.3c-2.5 0-4.4-1.9-4.4-4.4 0-2.5 1.9-4.4 4.4-4.4h3.3c2.5 0 4.4 1.9 4.4 4.4 0 2.5-1.9 4.4-4.4 4.4z"
        id="path6412"
      />
      <path
        d="M115.3 38.3c-1.1 0-2.2-.6-3.1-1.4-1.7-1.7-1.7-4.4 0-6.4l2.5-2.5c1.7-1.7 4.4-1.7 6.4 0 1.7 1.7 1.7 4.4 0 6.4l-2.5 2.5c-.8 1.2-2.2 1.4-3.3 1.4z"
        id="path6414"
      />
    </svg>
  );
};

export default ProsizerIcon;
