import { ListTitle } from '../../../ui/DataDisplay/List/ListHeader';
import ListItem from '../../../ui/DataDisplay/List/ListItem';
import { Col, Form, Row } from 'reactstrap';
import MasterConnectionTypeSelector from '../installation/MasterConnectionTypeSelector';
import { ISelectValue } from '../../../ui/Select/Select';
import SetMasterTypeConnectionButton from './SetMasterTypeConnectionButton';
import List from '../../../ui/DataDisplay/List/ListWrapper';
import React, { useState } from 'react';
import { safeMobxInject } from '../../../stores/storeInjectionHelpers';
import { translate, TranslationFunction } from 'react-i18next';
import ScanBarcodeButton from '../barcode/ScanBarcodeButton';
import { FormInput } from '../../../ui/FormInput/FormInput';
import { HelpButton } from '../../../ui/HelpButton/HelpButton';
import { convertTranslation } from '../../../utils/convertTranslations';
import { isMobileDevice } from '../../../utils/tools';
import LoadingButton from '../../../ui/Button/LoadingButton/LoadingButton';
import MasterErrors from './MasterErrors';
import ConfirmPopover from '../../../ui/ConfirmPopover/ConfirmPopover';
import MasterDetails from './MasterDetails';
import RefreshDeviceConfig from '../devices/RefreshDeviceConfig';
import GsmQualitySignal from './GsmQualitySignal';
import MasterConnectionTypePlcMylightAdditionalInfo from './MasterConnectionTypePlcMylightAdditionalInfo';
import './Master.scss';
import InputSerialNumber from './InputSerialNumber';
import SelectRouterReference from './SelectRouterReference';
import classnames from 'classnames';
import masterActivationCodeLocationImage from '../../../assets/images/master/master-ac-location.png';
import { InscriptionStore } from '../../../stores/inscription/inscriptionStore';
import MasterWarnings from './MasterWarnings';
import RefreshDevicesInscriptionQuery from '../RefreshDevicesInscriptionQuery';

interface IStoresProps {
  inscriptionStore: InscriptionStore;
}

interface IProps {
  t: TranslationFunction;
  saveLoading: boolean;
  registerMaster: () => void;
}

const MasterRegistered = safeMobxInject<IStoresProps, IProps>(
  stores => ({
    inscriptionStore: stores.inscription
  }),
  ({
    t,
    saveLoading,
    registerMaster,
    inscriptionStore
  }: IProps & IStoresProps) => {
    const [isConfirmOpen, displayConfirm] = useState(false);

    const {
      isMSTConnected,
      masterDevice,
      masterTypeInternetConnection,
      setMasterTypeInternetConnection,
      simRouterReference,
      setSimRouterReference,
      isSimRouterReferenceValid,
      simSerialNumber,
      setSimSerialNumber,
      simSerialNumberError,
      canUpdateMasterConnectionType,
      setEditedMST,
      editedMST,
      editedMSTInvalid,
      adapterCplMylight,
      isMasterConnectedViaGSM,
      isGsmSignalInfoDisplayed,
      isPlcSignalInfoDisplayed,
      masterHasSimNb
    } = inscriptionStore;

    const masterHelpButtonMessage = (
      <Col>
        {convertTranslation(
          isMobileDevice()
            ? t('Master:masterHelpMobile')
            : t('Master:masterHelp')
        )}
        <Row className="pt-3 pb-2">
          <img
            style={{
              margin: 'auto',
              width: '20rem',
              height: '20rem'
            }}
            src={masterActivationCodeLocationImage}
            alt={t('Master:masterHelp')}
          />
        </Row>
      </Col>
    );
    return (
      <RefreshDevicesInscriptionQuery fetchPolicy={'cache-and-network'}>
        <List fluid>
          <Row>
            <Col lg={9} xl={6}>
              <MasterWarnings />
            </Col>
          </Row>
          <Row>
            <Col lg={9} xl={6}>
              <ListTitle
                text={
                  isMSTConnected
                    ? t('Master:connectedMST')
                    : t('Master:disconnectedMST')
                }
                children={
                  <RefreshDeviceConfig label={t('Master:recontactMaster')} />
                }
              />
              <ListItem>
                <Form
                  className="form-wrapper"
                  onSubmit={e => {
                    e.preventDefault();
                  }}
                >
                  <MasterDetails />
                  <MasterConnectionTypeSelector
                    disabled={!isMSTConnected || masterHasSimNb}
                    value={masterTypeInternetConnection}
                    onChange={(internetConnectionType: ISelectValue) =>
                      setMasterTypeInternetConnection(
                        internetConnectionType.value
                      )
                    }
                  />
                  {isMasterConnectedViaGSM && (
                    <>
                      {isGsmSignalInfoDisplayed && (
                        <div className={'container-qualitySignal'}>
                          <span
                            className={classnames(
                              'input-title',
                              'text-qualitySignal'
                            )}
                          >
                            {`${t('Devices:signalReceptionGSM')} `}
                          </span>
                          <GsmQualitySignal
                            rssi={masterDevice && masterDevice.rssi}
                            rscp={masterDevice && masterDevice.rscp}
                            gsmSignalQuality={
                              masterDevice && masterDevice.gsmSignalQuality
                            }
                          />
                        </div>
                      )}
                      <SelectRouterReference
                        disabled={!isMSTConnected || masterHasSimNb}
                        isValid={isSimRouterReferenceValid}
                        simRouterReference={simRouterReference}
                        setSimRouterReference={setSimRouterReference}
                      />
                      <InputSerialNumber
                        disabled={!isMSTConnected || masterHasSimNb}
                        onSimSerialNumberChange={(serialNumber: string) =>
                          setSimSerialNumber(serialNumber)
                        }
                        simSerialNumber={simSerialNumber}
                        simSerialNumberError={
                          simSerialNumber &&
                          simSerialNumberError &&
                          t('Errors:numberFormatError')
                        }
                      />
                    </>
                  )}
                  {isPlcSignalInfoDisplayed &&
                    adapterCplMylight && (
                      <>
                        {`${t('Devices:pclRateBetweenMasterAndComOne')} `}
                        <MasterConnectionTypePlcMylightAdditionalInfo
                          tx={adapterCplMylight.tx}
                          rx={adapterCplMylight.rx}
                          plcSignalQuality={adapterCplMylight.plcSignalQuality}
                        />
                      </>
                    )}
                  <SetMasterTypeConnectionButton
                    disabled={!canUpdateMasterConnectionType}
                  />
                </Form>
              </ListItem>
            </Col>
          </Row>
        </List>
        <List fluid>
          <Row>
            <Col lg={9} xl={6}>
              <ListTitle
                text={t('Master:updateMST')}
                children={<ScanBarcodeButton codeType={'MST'} />}
              />
              <ListItem>
                <Form
                  className="form-wrapper"
                  onSubmit={e => {
                    e.preventDefault();
                  }}
                >
                  <FormInput
                    id="master"
                    name={t('Master:master')}
                    onChange={value => setEditedMST(value)}
                    error={
                      editedMST && editedMSTInvalid && t(`Master:masterError`)
                    }
                    addon={
                      <HelpButton
                        uniqueKey="masterHelp"
                        message={masterHelpButtonMessage}
                      />
                    }
                    value={editedMST || ''}
                  />

                  <div className="text-right mt-3">
                    <LoadingButton
                      id="sendMST"
                      loading={saveLoading}
                      disabled={editedMSTInvalid}
                      onClick={() => {
                        displayConfirm(true);
                      }}
                    >
                      {t('Common:connect')}
                    </LoadingButton>
                  </div>
                  <MasterErrors />
                </Form>
              </ListItem>
            </Col>
          </Row>
        </List>
        <ConfirmPopover
          target="sendMST"
          toggle={() => displayConfirm(false)}
          isOpen={isConfirmOpen}
          loading={saveLoading}
          message={t('Master:eraseMessage')}
          onAccept={() => {
            registerMaster();
            displayConfirm(false);
          }}
        />
      </RefreshDevicesInscriptionQuery>
    );
  }
);

export default translate()(MasterRegistered);
