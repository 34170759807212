import React from 'react';
import { IDeviceIcon } from '../DeviceIcons';

const Dishwasher = (props: IDeviceIcon) => (
  <svg
    width="3rem"
    height="3rem"
    viewBox="0 0 20000 20000"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    color="black"
    {...props}
  >
    <g id="prefix__layer">
      <g id="prefix___2098630774640">
        <path
          style={{ fill: `${props.color}` }}
          d="M14473 14866H5906c-169 0-296-113-296-296V5961c0-183 113-296 296-296h8567c169 0 296 113 296 296v8567c0 56-56 183-113 240-14 99-70 99-183 99zm-8215-634h7975V6313H6258v7919z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M13191 13654H7245c-169 0-296-113-296-296V7299c0-183 113-296 296-296h5946c183 0 296 113 296 296v6059c0 56-56 169-113 225-14 70-70 70-183 70zm-5650-592h5354V7651H7541v5411z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M8471 8342c0 127-99 240-240 240-127 0-240-99-240-240s99-240 240-240c127 14 240 113 240 240zM12374 8342c0 127-99 240-240 240-127 0-240-99-240-240s99-240 240-240c127 14 240 113 240 240zM10683 8638H9640c-169 0-296-113-296-296 0-169 113-296 296-296h1043c169 0 296 113 296 296s-127 296-296 296z"
        />
      </g>
    </g>
  </svg>
);

export default Dishwasher;
