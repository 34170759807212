import React from 'react';
import { TranslationFunction } from 'i18next';
import { Device } from '../../../../graphql/graphql.schema';
import { translate } from 'react-i18next';
import { ListTitle } from '../../../../ui/DataDisplay/List/ListHeader';
import ListPlaceHolder from '../../../../ui/DataDisplay/List/ListPlaceHolder';
import './SystemDevices.scss';
import SystemDevice from './SystemDevice';
import SystemDeviceFunction from './SystemDeviceFunction';
import { CMP, GMD, TPH } from '../../../../stores/devices_inscription/contants';
import { devicesOrder } from '../../../../utils/deviceHelpers';
import { FieldList } from '../../../../ui/DataDisplay/FieldList/FieldList';

interface IProps {
  t: TranslationFunction;
  devices: Device[];
  isThreePhaseInstallation: boolean;
}

const SystemDevicesView = ({
  t,
  devices,
  isThreePhaseInstallation
}: IProps) => {
  const orderedDevices = devicesOrder(devices);
  const filteredDevices = orderedDevices.filter(d => d.type !== CMP);

  return (
    <FieldList>
      <ListTitle text={t('Customer:devices')} />
      {filteredDevices.length === 0 ? (
        <ListPlaceHolder text={t('Devices:emptyDoneList')} />
      ) : (
        filteredDevices.map(device => {
          const isCompositeDevice = device.type === TPH || device.type === GMD;
          const isCompositeCtSplit =
            isCompositeDevice && device.deviceTypeId === 'composite_device';
          const deviceChildren = isCompositeCtSplit
            ? orderedDevices.filter(d => d.masterMac === device.macSerialNumber)
            : [];
          return (
            <div
              key={device.macSerialNumber}
              className={'system-devices-and-functions'}
            >
              <SystemDevice device={device} />
              <div className={'device-functions'}>
                {isCompositeCtSplit ? (
                  deviceChildren
                    .sort((a, b) => (a.phase > b.phase ? 1 : -1))
                    .map(child => (
                      <SystemDeviceFunction
                        key={child.macSerialNumber}
                        device={child}
                      />
                    ))
                ) : (
                  <SystemDeviceFunction
                    device={device}
                    isThreePhaseInstallation={isThreePhaseInstallation}
                  />
                )}
              </div>
            </div>
          );
        })
      )}
    </FieldList>
  );
};

export default translate(['Customer', 'DeviceType', 'DevicesFunctions'])(
  SystemDevicesView
);
