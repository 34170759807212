import React, { ReactNode } from 'react';
import './list.scss';
import { Col, Row } from 'reactstrap';

interface IProps {
  children: ReactNode;
  options?: any;
}

const TableHeader = ({ children, options }: IProps) => (
  <div className="headers" {...options}>
    {children}
  </div>
);

export const ListTitle = ({
  text,
  children = null
}: {
  text: any;
  children?: ReactNode;
}) => (
  <Col md={12} className="list-title">
    <span>{text}</span>
    {children}
  </Col>
);
export const ListHeader = ({ children }: { children: ReactNode }) => (
  <Row className="list-title">{children}</Row>
);
export default TableHeader;
