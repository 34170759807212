import logoDomuneo from '../assets/images/domuneo/logo.svg';
import logoMyhome from '../assets/images/myhome/logo.svg';
import logoPlaneteoui from '../assets/images/planeteoui/logo.svg';
import logoEngie from '../assets/images/engie/logo.svg';

export const getTenantLogo = (tenantName: string) => {
  switch (tenantName) {
    case 'mypower':
      return logoEngie;
    case 'domuneo':
      return logoDomuneo;
    case 'planeteoui':
      return logoPlaneteoui;
    default:
      return logoMyhome;
  }
};
