import React from 'react';
import LoadingButton from '../../../ui/Button/LoadingButton/LoadingButton';
import { translate, TranslationFunction } from 'react-i18next';
import {
  EElectricInstallation,
  ElectricInstallation,
  EPVInstallation,
  PVInstallation
} from '../../../graphql/graphql.schema';
import Mutation, { MutationResult } from 'react-apollo/Mutation';
import {
  GET_INSTALLATION,
  UPDATE_INSTALLATIONS
} from '../../customers/installation/edit/EditInstallationQuery';
import { RouteComponentProps, withRouter } from 'react-router';
import { IInscriptionNavigationRouterParamProps } from '../InscriptionNavigation';
import { ISnackBarParams } from '../../../stores/userFeedback/snackbarStore';
import { Query, QueryResult } from 'react-apollo';
import { getDevicePath } from '../routingHelper';
import { safeMobxInject } from '../../../stores/storeInjectionHelpers';

interface IProps
  extends RouteComponentProps<IInscriptionNavigationRouterParamProps> {
  t: TranslationFunction;
  disabled?: boolean;
}

interface IStoresProps {
  showSnackBar: (params: ISnackBarParams) => void;
  masterTypeInternetConnection?: string;
  simSerialNumber: string;
  simRouterReference: string;
  setElectricalInstallation: (
    electricInstallation: ElectricInstallation
  ) => void;
  editedElectricalInstallation: EElectricInstallation;
  setPvInstallation: (pvInstallation: PVInstallation) => void;
  editedPVInstallation: EPVInstallation;
}

interface IResponse {
  pvInstallationById: PVInstallation;
}

const SetMasterTypeConnectionButton = safeMobxInject<IStoresProps, IProps>(
  allStores => ({
    showSnackBar: allStores.snackBar.showSnackBar,
    masterTypeInternetConnection:
      allStores.inscription.masterTypeInternetConnection,
    simSerialNumber: allStores.inscription.simSerialNumber,
    simRouterReference: allStores.inscription.simRouterReference,
    setElectricalInstallation:
      allStores.editElectricalInstallation.setElectricalInstallation,
    editedElectricalInstallation:
      allStores.editElectricalInstallation.editedElectricalInstallation,
    setPvInstallation: allStores.editPVInstallation.setPvInstallation,
    editedPVInstallation: allStores.editPVInstallation.editedPVInstallation
  }),
  ({
    t,
    masterTypeInternetConnection,
    simSerialNumber,
    simRouterReference,
    setElectricalInstallation,
    editedElectricalInstallation,
    editedPVInstallation,
    setPvInstallation,
    showSnackBar,
    disabled,
    match: {
      params: { customerId, pvInstallationId }
    },
    history
  }: IProps & IStoresProps) => {
    return (
      <Query
        query={GET_INSTALLATION}
        variables={{ customerId, pVInstallationId: pvInstallationId }}
        fetchPolicy={'no-cache'}
        onCompleted={(data: IResponse) => {
          const pvInstallation = data.pvInstallationById;
          setPvInstallation(pvInstallation);
          const [electricalInstall]: ElectricInstallation[] =
            pvInstallation.electricInstallations || [];
          if (electricalInstall) {
            setElectricalInstallation(electricalInstall);
          }
        }}
      >
        {({ refetch }: QueryResult) => {
          return (
            <Mutation
              mutation={UPDATE_INSTALLATIONS}
              onCompleted={() => {
                refetch();
                showSnackBar({
                  type: 'success',
                  text: t('Success:updateMaster')
                });
                history.push(getDevicePath({ pvInstallationId, customerId }));
              }}
              onError={error => {
                const onSetMasterTypeError =
                  error.graphQLErrors[0] &&
                  error.graphQLErrors[0].message.error;

                let errorMessage = onSetMasterTypeError
                  ? onSetMasterTypeError.message
                  : t('Errors:unknownError');

                if (onSetMasterTypeError.type === 'PdlIsNotUniqueException') {
                  errorMessage = t('Installation:pdlIsNotUnique');
                }

                showSnackBar({
                  text: errorMessage,
                  type: 'error'
                });
              }}
            >
              {(updateInstallations, { data, loading }: MutationResult) => {
                return (
                  <div className="text-right mt-3">
                    <LoadingButton
                      onClick={event => {
                        event.preventDefault();
                        updateInstallations({
                          variables: {
                            pVInstallation: editedPVInstallation,
                            electricalInstallation: {
                              ...editedElectricalInstallation,
                              masterTypeInternetConnection,
                              simSerialNumber,
                              simRouterReference
                            }
                          }
                        });
                      }}
                      loading={loading}
                      disabled={disabled}
                    >
                      {t('InstallationEdit:saveEditButton')}
                    </LoadingButton>
                  </div>
                );
              }}
            </Mutation>
          );
        }}
      </Query>
    );
  }
);

export default withRouter(
  translate(['InstallationEdit', 'Success', 'Errors'])(
    SetMasterTypeConnectionButton
  )
);
