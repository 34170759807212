import React, { useEffect } from 'react';
import Spinner from '../../../../../ui/Spinner/Spinner';
import { FieldList } from '../../../../../ui/DataDisplay/FieldList/FieldList';

import '../battery.scss';
import { safeMobxInject } from '../../../../../stores/storeInjectionHelpers';
import MsbActivationMylightView from './MsbActivationMylightView';
import EnedisGridInjectionRequested from './Enedis/EnedisGridInjectionRequestedView';
import MsbAddView from './MsbAddView';
import MsbActivationPlaneteOuiView from './MsbActivationPlaneteOuiView';
import { RouteComponentProps, withRouter } from 'react-router';
import { withApollo, WithApolloClient } from 'react-apollo';
import { Alert } from 'reactstrap';
import { translate, TranslationFunction } from 'react-i18next';
import EnedisGridInjectionRequestedButton from './Enedis/EnedisGridInjectionRequestedButton';
import { MySmartBattery } from '../../../../../stores/my_smart_battery/mySmartBattery';
import { ActivationMySmartBatteryMylight } from '../../../../../stores/my_smart_battery/activationMySmartBatteryMylight';
import { ActivationMySmartBatteryEnedisGridInjection } from '../../../../../stores/my_smart_battery/activationMySmartBatteryEnedisGridInjection';
import StrasbourgElectricityAdministrativeProcedureRequestedButton from './StrasbourgElectricity/StrasbourgElectricityAdministrativeProcedureRequestedButton';
import StrasbourgElectricityAdministrativeProcedureView from './StrasbourgElectricity/StrasbourgElectricityAdministrativeProcedureView';
import { ActivationMySmartBatteryStrasbourgElectricityAdministrativeProcedure } from '../../../../../stores/my_smart_battery/activationMySmartBatteryStrasbourgElectricityAdministrativeProcedure';

interface IStoresProps {
  mySmartBattery: MySmartBattery;
  activationMySmartBatteryMylight: ActivationMySmartBatteryMylight;
  activationMySmartBatteryEnedisGridInjection: ActivationMySmartBatteryEnedisGridInjection;
  activationMySmartBatteryStrasbourgElectricityAdministrativeProcedure: ActivationMySmartBatteryStrasbourgElectricityAdministrativeProcedure;
}

interface IProps extends RouteComponentProps<IMatchParams> {
  refetchInstallationQuery: any;
  t: TranslationFunction;
}

interface IMatchParams {
  id: string;
}

const MsbActivationView = safeMobxInject<IStoresProps, IProps>(
  stores => ({
    mySmartBattery: stores.mySmartBattery,
    activationMySmartBatteryMylight: stores.activationMySmartBatteryMylight,
    activationMySmartBatteryEnedisGridInjection:
      stores.activationMySmartBatteryEnedisGridInjection,
    activationMySmartBatteryStrasbourgElectricityAdministrativeProcedure:
      stores.activationMySmartBatteryStrasbourgElectricityAdministrativeProcedure
  }),
  ({
    mySmartBattery,
    activationMySmartBatteryMylight,
    activationMySmartBatteryEnedisGridInjection,
    activationMySmartBatteryStrasbourgElectricityAdministrativeProcedure,
    t,
    client,
    refetchInstallationQuery,
    match: {
      params: { id: customerId }
    }
  }: WithApolloClient<IStoresProps & IProps>) => {
    useEffect(
      () => {
        activationMySmartBatteryMylight.clearActivationMsbMylight();
        activationMySmartBatteryEnedisGridInjection.clearActivationMsbEnedisGridInjection();
        activationMySmartBatteryStrasbourgElectricityAdministrativeProcedure.clearActivationMsbStrasbourgElectricityAdministrativeProcedure();
        mySmartBattery.loadMsb(client, customerId).then((msb: any) => {
          if (msb) {
            mySmartBattery.setActivationMsb(msb);
            activationMySmartBatteryMylight.setActivationMsbMylight(msb);
            if (mySmartBattery.isMsbEnedis) {
              activationMySmartBatteryEnedisGridInjection.setActivationMsbEnedisGridInjection(
                msb
              );
            }
            if (mySmartBattery.isMsbStrasbourgElectricity) {
              activationMySmartBatteryStrasbourgElectricityAdministrativeProcedure.setActivationMsbStrasbourgElectricityAdministrativeProcedure(
                msb
              );
            }
          }
        });
      },
      [customerId]
    );

    if (mySmartBattery.haveRightToAccessMSB) {
      return (
        <Spinner loading={mySmartBattery.isLoadingLoadMsb}>
          {mySmartBattery.isErrorLoadMsb ? (
            <div style={{ marginTop: '1rem' }}>
              <Alert color="danger">{t('errorLoadingMsbActivation')}</Alert>
            </div>
          ) : (
            <FieldList>
              {mySmartBattery.canAddMSB ? (
                <MsbAddView />
              ) : mySmartBattery.isMsbMylight ? (
                <>
                  <MsbActivationMylightView
                    refetchInstallationQuery={refetchInstallationQuery}
                  />
                  {mySmartBattery.isMsbEnedis && (
                    <>
                      <EnedisGridInjectionRequested />
                      {!mySmartBattery.isMSBActivated &&
                        !mySmartBattery.isMSBEnedisGridInjectionRequested && (
                          <EnedisGridInjectionRequestedButton />
                        )}
                    </>
                  )}
                  {mySmartBattery.isMsbStrasbourgElectricity && (
                    <>
                      <StrasbourgElectricityAdministrativeProcedureView />
                      {!mySmartBattery.isMSBActivated &&
                        !mySmartBattery.isMSBStrasbourgElectricityAdministrativeProcedureRequested && (
                          <StrasbourgElectricityAdministrativeProcedureRequestedButton />
                        )}
                    </>
                  )}
                </>
              ) : (
                <MsbActivationPlaneteOuiView />
              )}
            </FieldList>
          )}
        </Spinner>
      );
    }
    return <></>;
  }
);

export default translate(['MySmartBattery'])(
  withRouter(withApollo(MsbActivationView))
);
