import React from 'react';
import { IDeviceIcon } from '../DeviceIcons';

const RefrigeratorFreezer = (props: IDeviceIcon) => (
  <svg
    width="3rem"
    height="3rem"
    viewBox="0 0 20000 20000"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    {...props}
  >
    <g id="prefix__Layer_x0020_1">
      <g id="prefix__refrigerator_freezer">
        <path
          style={{ fill: `${props.color}` }}
          d="M13657 16907H6432c-177 0-306-145-306-306V4307c0-177 145-306 306-306h7225c177 0 306 145 306 306v12294c16 177-129 306-306 306zm-6919-628h6598V4613H6738v11666z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M13641 9118H6432c-177 0-306-145-306-306s145-306 306-306h7209c177 0 306 145 306 306s-129 306-306 306zM12322 7590c-177 0-306-145-306-306V5804c0-177 145-306 306-306s306 145 306 306v1464c0 177-145 322-306 322zM12322 11983c-177 0-306-145-306-306v-1480c0-177 145-306 306-306s306 145 306 306v1480c0 161-145 306-306 306zM9377 7236l64-547-451 338c-64 48-113 64-161 64s-97-16-129-64c-48-32-64-97-64-161s48-129 145-161l531-225-531-241c-97-32-145-97-145-161s16-129 64-161c32-32 80-64 129-64 48 0 97 16 161 64l451 338-64-531c-16-64 0-113 48-161 32-48 80-64 145-64 64 0 113 16 145 64s48 97 48 161l-64 547 451-338c48-32 97-64 161-64 48 0 97 16 129 64 32 32 64 97 64 161s-48 129-145 161l-531 209 531 225c97 32 145 97 145 161s-16 129-64 161c-48 48-80 64-129 64-48 0-97-16-161-64l-451-338 64 531c16 64 0 113-48 161-32 48-80 64-145 64-64 0-113-16-145-64-32-16-48-64-48-129z"
        />
      </g>
    </g>
  </svg>
);

export default RefrigeratorFreezer;
