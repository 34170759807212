import React from 'react';
import { IDeviceIcon } from '../DeviceIcons';

const Freezer = (props: IDeviceIcon) => (
  <svg
    width="3rem"
    height="3rem"
    viewBox="0 0 20000 20000"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    color="black"
    {...props}
  >
    <g id="prefix__layer">
      <g id="prefix___1405066031072">
        <path
          style={{ fill: `${props.color}` }}
          d="M9841 10968l95-703-572 429c-95 48-143 95-191 95s-95-48-191-95c-95-48-95-143-95-238s48-191 191-238l703-286-703-286c-143-48-191-143-191-191 0-95 48-143 95-191 48-48 95-95 191-95 95 0 143 48 191 95l572 429-95-703c0-95 0-143 48-191s95-95 191-95c95 0 143 48 191 95 48 48 48 143 48 191l-95 703 572-429c48-48 143-95 191-95s143 0 191 95c48 48 95 143 95 191 0 95-48 191-191 238l-715 286 703 286c143 48 191 143 191 238s-48 191-95 238c-48 48-95 95-191 95-48 0-143-48-191-95l-560-429 95 715c0 95 0 143-48 191s-95 95-191 95c-95 0-143-48-191-95 0-107-48-155-48-250z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M12772 14697H7434c-95 0-191-95-191-191V5451c0-95 95-191 191-191h5326c95 0 191 95 191 191v9055c0 95-95 191-179 191zm-5147-381h4909V5642H7625v8674z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M11772 7858c-95 0-191-95-191-191V6535c0-95 95-191 191-191 95 0 191 95 191 191v1084c0 143-95 238-191 238z"
        />
      </g>
    </g>
  </svg>
);

export default Freezer;
