import * as React from 'react';
import { MutationResult, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { translate } from 'react-i18next';
import { TranslationFunction } from 'i18next';

import './loginAsLink.scss';
import tools from '../../utils/tools';
import LoadingButton from '../../ui/Button/LoadingButton/LoadingButton';

export const LOG_IN_AS_MUTATION = gql`
  mutation loginAs($id: String!) {
    loginAsSrs(id: $id) {
      uri
    }
  }
`;

interface ILoginAs {
  uri: string;
}

interface IResponse {
  loginAsSrs: ILoginAs;
}

interface IProps {
  email: string;
  t: TranslationFunction;
  disabled?: boolean;
}

const LoginAsLink = ({ email, t, disabled }: IProps) => {
  return (
    <Mutation mutation={LOG_IN_AS_MUTATION}>
      {(
        loginAs,
        { called, loading, data, error }: MutationResult<IResponse>
      ) => {
        if (!called) {
          loginAs({ variables: { id: email } });
        }
        return (
          <div className="loginAs-link">
            <LoadingButton
              onClick={() => {
                if (data && !disabled) {
                  tools.openNewTabfForURI(data.loginAsSrs.uri);
                }
              }}
              disabled={!!error || disabled}
              loading={false}
            >
              {t('loginAsLink')}
            </LoadingButton>
          </div>
        );
      }}
    </Mutation>
  );
};
export default translate('LoginAs')(LoginAsLink);
