import React, { ReactNode } from 'react';
import './loginActionWrapper.scss';

interface IProps {
  children: ReactNode;
}

const LoginActionWrapper = ({ children }: IProps) => (
  <div className="login-action-wrapper">{children}</div>
);

export default LoginActionWrapper;
