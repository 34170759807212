import * as React from 'react';
import './toggle.scss';
import PopoverHeader from 'reactstrap/lib/PopoverHeader';
import { Button, InputGroup, PopoverBody } from 'reactstrap';
import Popover from 'reactstrap/lib/Popover';
import { translate, TranslationFunction } from 'react-i18next';

interface IProps {
  t: TranslationFunction;
  checked?: boolean;
  labelTrue?: string;
  className?: string;
  labelFalse?: string;
  onCheckChange?: (value: boolean) => void;
  customButton?: any;
  disabled?: boolean;
}
interface IState {
  isPopoverOpened?: boolean;
}

class Toggle extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = {
      isPopoverOpened: false
    };
  }

  public render() {
    const {
      checked,
      onCheckChange,
      labelTrue,
      labelFalse,
      className,
      t,
      customButton,
      disabled = false
    } = this.props;
    return (
      <div className={`toggle-container ${className ? className : ''}`}>
        <span
          className="un-selectable"
          onClick={() =>
            !disabled &&
            checked === true &&
            this.setState({ isPopoverOpened: true })
          }
        >
          {labelFalse}
        </span>
        <label
          className={`tgl-btn${checked ? ' checked' : ''}`}
          onClick={() => {
            if (!disabled && onCheckChange) {
              if (checked === true) {
                this.setState({ isPopoverOpened: true });
              } else {
                onCheckChange(!checked);
              }
            }
          }}
          id={'toggle'}
        />
        <span
          className="un-selectable"
          onClick={() =>
            !disabled &&
            checked === false &&
            onCheckChange &&
            onCheckChange(true)
          }
        >
          {labelTrue}
        </span>
        <Popover
          placement="bottom"
          isOpen={this.state.isPopoverOpened}
          target={`toggle`}
          toggle={() => !disabled && this.setState({ isPopoverOpened: false })}
        >
          <PopoverHeader>{t('Common:sure')}</PopoverHeader>
          <PopoverBody>
            <InputGroup
              onClick={() => this.setState({ isPopoverOpened: false })}
            >
              <Button color="secondary">{t('Common:no')}</Button>
              {customButton ? (
                customButton
              ) : (
                <Button
                  color="primary"
                  onClick={() => {
                    if (!disabled && onCheckChange) {
                      onCheckChange(false);
                    }
                  }}
                  style={{ marginLeft: '10px' }}
                >
                  {t('Common:yes')}
                </Button>
              )}
            </InputGroup>
          </PopoverBody>
        </Popover>
      </div>
    );
  }
}

export default translate(['Common'])(Toggle);
