import React, { useEffect } from 'react';
import { ApolloProvider } from 'react-apollo';
import { Provider } from 'mobx-react';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { Router } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import history from './history';

import { createApolloLink, handleTokenRenew } from './ApolloLink';
import AppRoute from './AppRoute';
import i18n from './translations/i18n';

import { fontAwesomelibrary } from './utils/fontAwesomeLibrary';

import { Store } from './stores';
import editElectricalInstallation from './stores/installations/editElectricalInstallationStore';
import snackBar from './stores/userFeedback/snackbarStore';
import environment from './stores/environment/environment';
import editCustomer from './stores/editCustomer/editCustomer';
import editPVInstallation from './stores/installations/editPVInstallationStore';
import battery from './stores/battery';
import barcode from './stores/barcode';
import inscription from './stores/inscription/inscriptionStore';
import inscriptionError from './stores/inscription/inscriptionErrorStore';
import inscriptionSteps from './stores/inscription/inscriptionStepsStore';
import addDevice from './stores/inscription/addDeviceStore';
import customersMode from './stores/customersMode';
import tadoStore from './stores/partnerships/tadoStore';
import enphaseStore from './stores/partnerships/enphaseStore';
import wiserStore from './stores/partnerships/wiserStore';
import searchPairingStore from './stores/searchPairing/searchPairingStore';
import mySmartBatteryList from './stores/lists/mySmartBatteryListStore';
import activationMySmartBatteryMylight from './stores/my_smart_battery/activationMySmartBatteryMylight';
import mySmartBattery from './stores/my_smart_battery/mySmartBattery';
import activationMySmartBatteryEnedisGridInjection from './stores/my_smart_battery/activationMySmartBatteryEnedisGridInjection';
import activationMySmartBatteryStrasbourgElectricityAdministrativeProcedure from './stores/my_smart_battery/activationMySmartBatteryStrasbourgElectricityAdministrativeProcedure';
import creationMySmartBattery from './stores/my_smart_battery/creationMySmartBattery';

fontAwesomelibrary();

const getLanguage = (): string => {
  return (
    i18n.language ||
    (typeof window !== 'undefined' && window.localStorage.i18nextLng)
  );
};

const client = (url: string) => {
  const apolloClient = new ApolloClient({
    cache: new InMemoryCache({ dataIdFromObject: object => object.id }),
    link: createApolloLink(url)
  });
  handleTokenRenew(apolloClient);
  return apolloClient;
};

const App = ({ config }: { config: any }) => {
  environment.setConfig(config);

  const mobxStore = {
    editElectricalInstallation,
    editPVInstallation,
    snackBar,
    environment,
    editCustomer,
    inscription,
    inscriptionSteps,
    inscriptionError,
    mySmartBattery,
    activationMySmartBatteryMylight,
    activationMySmartBatteryEnedisGridInjection,
    activationMySmartBatteryStrasbourgElectricityAdministrativeProcedure,
    creationMySmartBattery,
    battery,
    mySmartBatteryList,
    barcode,
    addDevice,
    customersMode,
    tadoStore,
    enphaseStore,
    wiserStore,
    searchPairingStore,
    Store: new Store()
  };

  // @ts-ignore TS2339
  if (window.Cypress) {
    // @ts-ignore TS2339
    window.__stores__ = mobxStore;
  }

  useEffect(() => {
    const htmlEl = document.getElementsByTagName('html');
    if (htmlEl && htmlEl.length > 0) {
      htmlEl[0].setAttribute('lang', getLanguage());
    }
  }, []);

  return (
    <I18nextProvider i18n={i18n}>
      <Router history={history}>
        <ApolloProvider client={client(config.REACT_APP_MY_LIGHT_BACKEND_URL)}>
          <Provider {...mobxStore}>
            <AppRoute />
          </Provider>
        </ApolloProvider>
      </Router>
    </I18nextProvider>
  );
};

export default App;
