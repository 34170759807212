import React from 'react';

import './InfoCard.scss';

interface IProps {
  title: string;
  stepNumber?: number;
}

export const InfoCard: React.FC<IProps> = ({ title, stepNumber, children }) => (
  <div className="info-card">
    {stepNumber && (
      <div className="info-card__step-number">
        <span>{stepNumber}</span>
      </div>
    )}

    <div className="info-card__body">
      <div className="info-card__body__title">{title}</div>

      {children}
    </div>
  </div>
);
