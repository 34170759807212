import * as React from 'react';

import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { RouteComponentProps, withRouter } from 'react-router';
import Spinner from '../../../ui/Spinner/Spinner';
import SummaryInstallation from './SummaryInstallation';

interface IMatchParams {
  customerId: string;
}

export const GET_CUSTOMER = gql`
  query getOneToOneCustomerData($customerId: String!) {
    getOneToOneCustomerData(customerId: $customerId) {
      id
      customer {
        id
        firstName
        lastName
        address
        country
        email
        city
        zipCode
      }
      pVInstallation {
        id
        panelCount
        panelManufacturer
        panelWattPeak
        ucgSerialNumber
      }
      electricInstallation {
        id
        gridType
        pdlNumber
        internetServiceProvider
        rackingPower
        masterTypeInternetConnection
        simSerialNumber
        simRouterReference
      }
    }
  }
`;

const SummaryInstallationQuery = ({
  match: {
    params: { customerId }
  }
}: RouteComponentProps<IMatchParams>) => {
  return (
    <Query query={GET_CUSTOMER} variables={{ customerId }}>
      {({ data, loading, error }) => (
        <Spinner loading={loading} error={error && 'error'}>
          <SummaryInstallation
            panelCount={
              data &&
              data.getOneToOneCustomerData &&
              data.getOneToOneCustomerData.pVInstallation.panelCount
            }
            panelWattPeak={
              data &&
              data.getOneToOneCustomerData &&
              data.getOneToOneCustomerData.pVInstallation.panelWattPeak
            }
            panelManufacturer={
              data &&
              data.getOneToOneCustomerData &&
              data.getOneToOneCustomerData.pVInstallation.panelManufacturer
            }
            pdlNumber={
              data &&
              data.getOneToOneCustomerData &&
              data.getOneToOneCustomerData.electricInstallation.pdlNumber
            }
            internetServiceProvider={
              data &&
              data.getOneToOneCustomerData &&
              data.getOneToOneCustomerData.electricInstallation
                .internetServiceProvider
            }
            rackingPower={
              data &&
              data.getOneToOneCustomerData &&
              data.getOneToOneCustomerData.electricInstallation.rackingPower
            }
            gridType={
              data &&
              data.getOneToOneCustomerData &&
              data.getOneToOneCustomerData.electricInstallation.gridType
            }
            masterTypeInternetConnection={
              data &&
              data.getOneToOneCustomerData &&
              data.getOneToOneCustomerData.electricInstallation
                .masterTypeInternetConnection
            }
            simSerialNumber={
              data &&
              data.getOneToOneCustomerData &&
              data.getOneToOneCustomerData.electricInstallation.simSerialNumber
            }
            simRouterReference={
              data &&
              data.getOneToOneCustomerData &&
              data.getOneToOneCustomerData.electricInstallation
                .simRouterReference
            }
          />
        </Spinner>
      )}
    </Query>
  );
};

export default withRouter(SummaryInstallationQuery);
