import React from 'react';
import { IDeviceIcon } from '../DeviceIcons';

const Kettle = (props: IDeviceIcon) => (
  <svg
    width="3rem"
    height="3rem"
    viewBox="0 0 20000 20000"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    color="black"
    {...props}
  >
    <g id="prefix__layer">
      <g id="prefix__kettle">
        <path
          style={{ fill: `${props.color}` }}
          d="M11502 9390c-56 0-112 0-183-56-112-112 56-295 183-351l998-998H7425c-112 0-295 56-295-112 0-112 112-295 295-295h5440c112 0 183 56 239 183 56 112 0 239-56 295l-1307 1279c-56 56-183 56-239 56z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M12331 14591H7664c-56 0-183-56-183-112s-56-112-56-239l1068-6438c0-56 0-351 183-647 183-295 534-717 1237-717 1068 0 1476 829 1476 1237l1237 6565c0 56 0 183-56 239-127 112-183 112-239 112zm-4428-351h4203l-1237-6565c0-56-112-829-998-829-351 0-647 112-773 408-112 239-112 478-112 478v56l-1082 6452z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M8493 8968H7368c-112 0-295-56-295-183 0-112 112-183 295-183h1125c112 0 295 56 295 183s-127 183-295 183z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M7299 9573c-112 0-239-112-239-295V7324c0-112 112-295 239-295 112 0 239 112 239 295v1954c0 169-112 295-239 295zM11502 7197c-56 0-112 0-112-56-56-112-112-239 0-351 183-239 112-408-56-773-239-478 112-998 112-1068 56-112 183-112 295-56 56 56 112 239 56 351s-183 351-112 478c183 351 408 829 56 1364-127 56-183 112-239 112zM12387 7197c-56 0-112 0-112-56-56-112-112-239 0-351 183-239 112-408-56-773-239-478 112-998 112-1068 56-112 183-112 295-56 56 56 112 239 56 351s-183 351-112 478c183 351 408 829 56 1364-127 56-183 112-239 112z"
        />
      </g>
    </g>
  </svg>
);

export default Kettle;
