import React from 'react';
import { inject, observer } from 'mobx-react';
import { IAllStores } from '../../../stores/allStores.model';
import { translate } from 'react-i18next';
import { Device } from '../../../stores/inscription/device';
import { IDeviceDetailsProps } from './DeviceDetail';
import { withRouter } from 'react-router';
import { Field, FieldList } from '../../../ui/DataDisplay/FieldList/FieldList';
import classnames from 'classnames';
import { Col } from 'reactstrap';
import { CompositeDevice } from '../../../stores/inscription/compositeDevice';

const DeviceInfo = inject(({ inscription }: IAllStores) => {
  return {
    getEditedDevice: inscription.getEditedDevice
  };
})(
  observer(
    ({
      t,
      match: {
        params: {
          ethMac: ethMacFromRouter,
          plcMac: plcMacFromRouter,
          pvInstallationId,
          customerId
        }
      },
      history,
      getEditedDevice
    }: IDeviceDetailsProps) => {
      const editedDevice: Device | undefined = getEditedDevice!({
        ethMac: ethMacFromRouter,
        plcMac: plcMacFromRouter
      });
      if (editedDevice) {
        const {
          getDeviceFullName,
          activationCode,
          ethMac,
          plcMac,
          firmware,
          state,
          getDeviceFullRoomName
        } = editedDevice;

        if (editedDevice instanceof CompositeDevice) {
          const { isModbus, modbusReference } = editedDevice;
          if (isModbus) {
            return (
              <Col>
                <FieldList>
                  <Field
                    name={t!('Devices:reference')}
                    text={modbusReference}
                  />
                  <Field
                    name={t!('Devices:deviceState')}
                    text={
                      <span
                        className={classnames(
                          'badge',
                          'badge-pill',
                          state ? 'badge-success' : 'badge-error'
                        )}
                      >
                        {state ? t!('Devices:stateOn') : t!('Devices:stateOff')}
                      </span>
                    }
                  />
                  <Field
                    name={t!('Devices:room')}
                    text={getDeviceFullRoomName}
                  />
                </FieldList>
              </Col>
            );
          }
        }

        return (
          <Col>
            <FieldList>
              <Field name={t!('Devices:name')} text={getDeviceFullName} />
              <Field
                name={t!('Devices:activationCode')}
                text={activationCode}
              />
              <Field
                name={t!('Devices:mac')}
                text={ethMac === 'none' ? plcMac : ethMac}
              />
              <Field name={t!('Devices:firmware')} text={firmware} />
              <Field
                name={t!('Devices:deviceState')}
                text={
                  <span
                    className={classnames(
                      'badge',
                      'badge-pill',
                      state ? 'badge-success' : 'badge-error'
                    )}
                  >
                    {state ? t!('Devices:stateOn') : t!('Devices:stateOff')}
                  </span>
                }
              />
              <Field name={t!('Devices:room')} text={getDeviceFullRoomName} />
            </FieldList>
          </Col>
        );
      }
      return <div />;
    }
  )
);

export default withRouter(translate(['Devices'])(DeviceInfo));
