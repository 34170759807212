import { observable, action, computed } from 'mobx';
import { Device, PPTadoDevice } from '../../graphql/graphql.schema';
import { isTadoDevice } from '../../utils/deviceHelpers';

export class TadoStore {
  @observable public tadoConnectInProgress: boolean = false;
  @observable public tadoAccessGranted: boolean = false;

  @observable public homeId: string | undefined = undefined;
  @observable
  public selectedTadoHome: { id: string; name: string } = { id: '', name: '' };

  @observable public allDevices: any = [];
  @observable public storedTadoDevices: PPTadoDevice[] = [];
  @observable public ignoredTadoDevices: string[] = [];

  @observable public editedDevice: string = '';

  @observable public confirmationWindowTarget: string | undefined;

  @computed
  get tadoDevicesPresent() {
    if (
      this.allDevices.filter((device: any) => isTadoDevice(device)).length > 0
    ) {
      return true;
    }
    return false;
  }
  @computed
  get status() {
    if (this.tadoAccessGranted || (this.homeId && this.homeId !== '')) {
      return this.tadoDevicesPresent ? 'connected' : 'inProgress';
    }

    return 'disconnected';
  }

  @computed
  get savedTadoDevices() {
    return this.storedTadoDevices.filter(
      td =>
        this.allDevices.filter((d: Device) => d.macSerialNumber === td.id)
          .length > 0
    );
  }
  @computed
  get unsavedTadoDevices() {
    return this.storedTadoDevices.filter(
      td => this.savedTadoDevices.filter(d => d.id === td.id).length === 0
    );
  }

  @computed
  get tadoDevicesToSave() {
    if (this.status === 'connected') {
      return this.unsavedTadoDevices.filter(
        td => this.ignoredTadoDevices.filter(id => id === td.id).length === 0
      );
    }
    return this.storedTadoDevices.filter(
      td => this.ignoredTadoDevices.filter(id => id === td.id).length === 0
    );
  }

  @action.bound
  public setTadoConnectInProgress(status: boolean) {
    this.tadoConnectInProgress = status;
  }
  @action.bound
  public grantTadoAccess(status: boolean) {
    this.tadoAccessGranted = status;
  }

  @action.bound
  public updateTadoHome(newTadoHome: { id: string; name: string }) {
    this.selectedTadoHome = newTadoHome;
  }
  @action.bound
  public setHomeId(homeId?: string) {
    this.homeId = homeId;
  }

  @action.bound
  public setAllDevices(devices: Device[]) {
    this.allDevices = devices;
  }

  @action.bound
  public storeTadoDevices(devices: any) {
    this.storedTadoDevices = devices;
  }

  @action.bound
  public ignoreTadoDevice(device: PPTadoDevice) {
    if (this.ignoredTadoDevices.includes(device.id)) {
      this.ignoredTadoDevices.splice(
        this.ignoredTadoDevices.indexOf(device.id),
        1
      );
    } else this.ignoredTadoDevices.push(device.id);
  }
  @action.bound
  public ignoreTadoDevices(devices: PPTadoDevice[]) {
    this.clearIgnoredTadoDevices();
    devices.map(d => this.ignoredTadoDevices.push(d.id));
  }
  @action.bound
  public clearIgnoredTadoDevices() {
    this.ignoredTadoDevices = [];
  }

  @action.bound
  public toggleDeviceEditing(id: string) {
    if (id === this.editedDevice) {
      return (this.editedDevice = '');
    }
    return (this.editedDevice = id);
  }
  @action.bound
  public updateDeviceInfo(
    id: string,
    param: 'deviceName' | 'roomName' | 'roomType',
    info: string
  ) {
    this.storedTadoDevices.forEach(device => {
      if (device.id === id) {
        device[param] = info;
      }
    });
  }

  @action.bound
  public resetTadoStore() {
    this.setTadoConnectInProgress(false);
    this.grantTadoAccess(false);
    this.setAllDevices([]);
    this.setHomeId(undefined);
    this.storeTadoDevices([]);
  }
  @action.bound
  public setConfirmationWindowTarget(target?: string) {
    this.confirmationWindowTarget = target;
  }
}

const tadoStore: TadoStore = new TadoStore();
export default tadoStore;
