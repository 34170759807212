import React from 'react';
import { IDeviceIcon } from '../DeviceIcons';

const WineCellar = (props: IDeviceIcon) => (
  <svg
    xmlSpace="preserve"
    width="3rem"
    height="3rem"
    viewBox="0 0 20000 20000"
    id="prefix__svg2"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    color="black"
    {...props}
  >
    <defs id="prefix__defs4">
      <style type="text/css" id="style6">
        {`.prefix__fil0{fill:{props.color}}`}
      </style>
    </defs>
    <g id="prefix__Layer_x0020_1" fill={props.color} fillOpacity={1}>
      <g id="prefix___1440935414320" fill={props.color} fillOpacity={1}>
        <path
          style={{ fill: `${props.color}` }}
          d="M15985 17404c-160 0-320-160-320-320V3544H4185v13460c0 160-160 320-320 320s-320-160-320-320V3224c0-160 160-320 320-320h12120c160 0 320 160 320 320v13860c0 160-160 320-320 320z"
          id="prefix__path11"
          fill={props.color}
          fillOpacity={1}
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M15825 7644H4025c-160 0-320-160-320-320s160-320 320-320h11900c160 0 320 160 320 320-20 160-260 320-420 320z"
          id="prefix__path13"
          fill={props.color}
          fillOpacity={1}
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M15905 11664H3945c-160 0-320-160-320-320s160-320 320-320h11980c160 0 320 160 320 320-20 160-180 320-340 320z"
          id="prefix__path15"
          fill={props.color}
          fillOpacity={1}
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M15905 15684H3865c-160 0-320-160-320-320s160-320 320-320h12060c160 0 320 160 320 320-20 160-180 320-340 320z"
          id="prefix__path17"
          fill={props.color}
          fillOpacity={1}
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M9925 7004c-860 0-1580-700-1580-1580 0-860 700-1580 1580-1580 860 0 1580 700 1580 1580s-720 1580-1580 1580zm0-2520c-480 0-860 400-860 860 0 480 400 860 860 860 480 0 860-400 860-860 0-380-380-860-860-860z"
          id="prefix__path19"
          fill={props.color}
          fillOpacity={1}
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M8045 11024c-860 0-1580-720-1580-1580s700-1580 1580-1580c860 0 1580 700 1580 1580-100 880-720 1580-1580 1580zm0-2440c-480 0-860 400-860 860 0 480 400 860 860 860 480 0 860-400 860-860s-400-860-860-860z"
          id="prefix__path21"
          fill={props.color}
          fillOpacity={1}
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M11825 11024c-860 0-1580-720-1580-1580s720-1580 1580-1580 1580 700 1580 1580c-20 880-720 1580-1580 1580zm0-2440c-480 0-860 400-860 860 0 480 400 860 860 860 480 0 860-400 860-860s-400-860-860-860z"
          id="prefix__path23"
          fill={props.color}
          fillOpacity={1}
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M6465 15124c-860 0-1580-720-1580-1580s700-1580 1580-1580 1580 720 1580 1580-720 1580-1580 1580zm0-2440c-480 0-860 400-860 860 0 480 400 860 860 860 480 0 860-400 860-860s-320-860-860-860z"
          id="prefix__path25"
          fill={props.color}
          fillOpacity={1}
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M9925 15124c-860 0-1580-720-1580-1580s720-1580 1580-1580 1580 720 1580 1580c-80 880-720 1580-1580 1580zm0-2440c-480 0-860 400-860 860 0 480 400 860 860 860 480 0 860-400 860-860s-380-860-860-860z"
          id="prefix__path27"
          fill={props.color}
          fillOpacity={1}
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M13305 15124c-860 0-1580-720-1580-1580s720-1580 1580-1580 1580 720 1580 1580-700 1580-1580 1580zm0-2440c-480 0-860 400-860 860 0 480 400 860 860 860 480 0 860-400 860-860 20-460-300-860-860-860z"
          id="prefix__path29"
          fill={props.color}
          fillOpacity={1}
        />
      </g>
    </g>
  </svg>
);

export default WineCellar;
