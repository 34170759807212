import { action, computed, observable } from 'mobx';
import { EnergyDistributor, MSB } from '../../graphql/graphql.schema';
import { MsbActivationInputState } from '../../components/customers/installation/battery/activation/MsbInputText';
import { WithApolloClient } from 'react-apollo';
import { t } from 'i18next';
import gql from 'graphql-tag';

const CHECK_PDL_NUMBER = gql`
  query checkPdlNumber($pdlNumber: String!, $customerId: String) {
    checkPdlNumber(pdlNumber: $pdlNumber, customerId: $customerId) {
      id
      name
    }
  }
`;

const CHECK_SERIAL_NUMBER = gql`
  query checkSerialNumber($serialNumber: String!) {
    checkSerialNumber(serialNumber: $serialNumber)
  }
`;

const CREATE_MSB_MYLIGHT = gql`
  mutation createMsbMylight(
    $serialNumber: String!
    $pdlNumber: String!
    $customerId: String!
  ) {
    createMsbMylight(
      customerId: $customerId
      serialNumber: $serialNumber
      pdlNumber: $pdlNumber
    )
  }
`;

const UPDATE_PDL_NUMBER_BY_CUSTOMER_ID = gql`
  mutation updatePdlNumberByCustomerId(
    $customerId: String!
    $pdlNumber: String!
  ) {
    updatePdlNumberByCustomerId(
      customerId: $customerId
      pdlNumber: $pdlNumber
    ) {
      pdlNumber
      pdlNumberModifiedAt
    }
  }
`;

const GET_PDL_NUMBER_BY_CUSTOMER_ID = gql`
  query getPdlNumberByCustomerId($customerId: String!) {
    getPdlNumberByCustomerId(customerId: $customerId) {
      pdlNumber
    }
  }
`;

export class CreationMySmartBattery {
  @observable public hasMsb: boolean = false;
  @observable public energyDistributor: EnergyDistributor | null = null;
  @observable public serialNumber: string = '';
  @observable
  public serialNumberInputState?: MsbActivationInputState = undefined;
  @observable public pdlNumber: string = '';
  @observable public pdlNumberInputState?: MsbActivationInputState = undefined;
  @observable public isLoadingCreateMsb: boolean = false;
  @observable public isLoadingUpdateMsb: boolean = false;
  @observable public isLoadingGetPdlNumber: boolean = false;

  @computed
  public get isMsbMylightFormValid(): boolean {
    return (
      this.serialNumberInputState === 'success' &&
      this.pdlNumberInputState === 'success'
    );
  }

  @action.bound
  public setPdlNumber(pdlNumber: string): void {
    this.pdlNumber = pdlNumber;
  }

  @action.bound
  public setSerialNumber(serialNumber: string): void {
    this.serialNumber = serialNumber;
  }

  @action.bound
  public async checkPdlNumber(
    client: WithApolloClient<any>,
    pdlNumber: string,
    customerId: string
  ) {
    try {
      const { data } = await client.query({
        query: CHECK_PDL_NUMBER,
        fetchPolicy: 'no-cache',
        variables: {
          pdlNumber,
          customerId
        }
      });

      this.energyDistributor = data.checkPdlNumber as EnergyDistributor;
      this.pdlNumberInputState = 'success';
      return Promise.resolve();
    } catch (error) {
      this.pdlNumberInputState = 'error';
      return Promise.reject(
        t(`MySmartBattery:errors.${error.graphQLErrors[0].message.errorCode}`)
      );
    }
  }

  @action.bound
  public async checkSerialNumber(
    client: WithApolloClient<any>,
    serialNumber: string
  ) {
    try {
      await client.query({
        query: CHECK_SERIAL_NUMBER,
        fetchPolicy: 'no-cache',
        variables: {
          serialNumber
        }
      });

      this.serialNumberInputState = 'success';
      return Promise.resolve();
    } catch (error) {
      this.serialNumberInputState = 'error';
      return Promise.reject(
        t(`MySmartBattery:errors.${error.graphQLErrors[0].message.errorCode}`)
      );
    }
  }

  @action.bound
  public async createMsbMylight(
    client: WithApolloClient<any>,
    customerId: string
  ) {
    try {
      this.isLoadingCreateMsb = true;
      await client.mutate({
        mutation: CREATE_MSB_MYLIGHT,
        variables: {
          customerId,
          serialNumber: this.serialNumber,
          pdlNumber: this.pdlNumber
        }
      });
      this.isLoadingCreateMsb = false;
      return Promise.resolve();
    } catch (error) {
      this.isLoadingCreateMsb = false;
      return Promise.reject(
        t(`MySmartBattery:errors.${error.graphQLErrors[0].message.errorCode}`)
      );
    }
  }

  @action.bound
  public async updateMsbMylight(
    client: WithApolloClient<any>,
    customerId: string
  ) {
    try {
      this.isLoadingUpdateMsb = true;
      await client.mutate({
        mutation: UPDATE_PDL_NUMBER_BY_CUSTOMER_ID,
        variables: {
          customerId,
          pdlNumber: this.pdlNumber
        }
      });
      this.isLoadingUpdateMsb = false;
      return Promise.resolve();
    } catch (error) {
      this.isLoadingUpdateMsb = false;
      return Promise.reject(
        t(`MySmartBattery:errors.${error.graphQLErrors[0].message.errorCode}`)
      );
    }
  }

  @action.bound
  public async getPdlNumberByCustomerId(
    client: WithApolloClient<any>,
    customerId: string
  ) {
    try {
      this.isLoadingGetPdlNumber = true;
      const { data, errors } = await client.query({
        query: GET_PDL_NUMBER_BY_CUSTOMER_ID,
        fetchPolicy: 'no-cache',
        variables: {
          customerId
        }
      });
      this.isLoadingGetPdlNumber = false;

      if (errors) {
        return;
      }

      const { pdlNumber } = data.getPdlNumberByCustomerId;
      this.setPdlNumber(pdlNumber);
      return pdlNumber;
    } catch (error) {
      this.isLoadingGetPdlNumber = false;
      return Promise.reject(
        t(`MySmartBattery:errors.${error.graphQLErrors[0].message.errorCode}`)
      );
    }
  }

  @action.bound
  public setCreationMsbMylight(msb: MSB): void {
    if (msb) {
      this.hasMsb = true;
    }

    if (msb.energyDistributor) {
      this.energyDistributor = msb.energyDistributor;
    }
    if (msb.boxesSerialNumber && msb.boxesSerialNumber[0]) {
      this.serialNumber = msb.boxesSerialNumber[0];
    }
    if (msb.pdlNumber) {
      this.pdlNumber = msb.pdlNumber;
    }
  }

  @action.bound
  public clearCreationMsbMylight(): void {
    this.hasMsb = false;
    this.energyDistributor = null;
    this.serialNumber = '';
    this.serialNumberInputState = undefined;
    this.pdlNumber = '';
    this.pdlNumberInputState = undefined;
  }
}

const creationMySmartBattery: CreationMySmartBattery = new CreationMySmartBattery();
export default creationMySmartBattery;
