import React from 'react';
import { MSBListFilter, MSBListItem } from '../../../graphql/graphql.schema';
import Spinner from '../../../ui/Spinner/Spinner';
import MediaQuery from 'react-responsive';
import MySmartBatteryListDesktop from './MySmartBatteryListDesktop';
import MySmartBatteryListMobile from './MySmartBatteryListMobile';
import {
  BREAK_POINT_LARGE__WIDTH,
  BREAK_POINT_LARGE_SAFE_MIN_WIDTH
} from '../../../constants/BREAK_POINT_WIDTH';
import { safeMobxInject } from '../../../stores/storeInjectionHelpers';

interface IProps {
  mySmartBatteryList: MSBListItem[];
  loading: boolean;
  canFetchMore: boolean;
  fetchMore: () => void;
  loginAsForReadOnly: (customerEmail: string) => void;
}
interface IStoresProps {
  isReadOnly: boolean;
  filterRule: MSBListFilter[];
}
const MySmartBatteryListView = safeMobxInject<IStoresProps, IProps>(
  ({ Store: { profile }, mySmartBatteryList }) => ({
    isReadOnly: profile.isReadOnly,
    filterRule: mySmartBatteryList.filterRule
  }),
  ({
    loading,
    mySmartBatteryList,
    canFetchMore,
    fetchMore,
    isReadOnly,
    loginAsForReadOnly,
    filterRule
  }: IProps & IStoresProps) => {
    return (
      <Spinner loading={loading}>
        <MediaQuery query={`(max-width: ${BREAK_POINT_LARGE__WIDTH})`}>
          <MySmartBatteryListMobile
            mySmartBatteryList={mySmartBatteryList || []}
            filters={filterRule}
            canFetchMore={canFetchMore}
            fetchMore={fetchMore}
            isReadOnly={isReadOnly}
            loginAsForReadOnly={loginAsForReadOnly}
          />
        </MediaQuery>
        <MediaQuery query={`(min-width: ${BREAK_POINT_LARGE_SAFE_MIN_WIDTH})`}>
          <MySmartBatteryListDesktop
            mySmartBatteryList={mySmartBatteryList || []}
            filters={filterRule}
            canFetchMore={canFetchMore}
            fetchMore={fetchMore}
            isReadOnly={isReadOnly}
            loginAsForReadOnly={loginAsForReadOnly}
          />
        </MediaQuery>
      </Spinner>
    );
  }
);

export default MySmartBatteryListView;
