import * as React from 'react';
import { translate, TranslationFunction } from 'react-i18next';
import { FormInput } from '../../../ui/FormInput/FormInput';

interface IProps {
  t: TranslationFunction;
  disabled?: boolean;
  onSimSerialNumberChange: (simSerialNumber: string) => void;
  simSerialNumber?: string;
  simSerialNumberError: string | undefined;
}

const InputSerialNumber = ({
  t,
  disabled,
  onSimSerialNumberChange,
  simSerialNumber,
  simSerialNumberError
}: IProps) => {
  return (
    <FormInput
      id="simSerialNumber"
      name={t('Installation:simSerialNumber')}
      disabled={disabled}
      onChange={onSimSerialNumberChange}
      value={simSerialNumber || ''}
      error={simSerialNumberError}
    />
  );
};

export default translate()(InputSerialNumber);
