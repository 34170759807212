import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import './functionsItem.scss';
import { translate, TranslationFunction } from 'react-i18next';
import { ListTitle } from '../../../ui/DataDisplay/List/ListHeader';
import List from '../../../ui/DataDisplay/List/ListWrapper';
import { RouteComponentProps, withRouter } from 'react-router';
import ListItem from '../../../ui/DataDisplay/List/ListItem';
import { Device } from '../../../stores/inscription/device';
import SaveInscriptionQueryButton from '../SaveInscriptionButton/SaveInscriptionQueryButton';
import { getFinishPath } from '../routingHelper';
import DeviceFunctionItem from './DeviceFunctionItem';
import { CompositeDevice } from '../../../stores/inscription/compositeDevice';
import { ISnackBarParams } from '../../../stores/userFeedback/snackbarStore';
import { safeMobxInject } from '../../../stores/storeInjectionHelpers';
import { IInscriptionNavigationRouterParamProps } from '../InscriptionNavigation';
import ShowAlerts from '../../../ui/ShowAlert/ShowAlerts';
import { useScrollToInscriptionStep } from '../../../utils/hooks';
import { InscriptionStep } from '../../../stores/inscription/inscriptionStepsStore';
import RefreshDevicesInscriptionQuery from '../RefreshDevicesInscriptionQuery';

interface IProps
  extends RouteComponentProps<IInscriptionNavigationRouterParamProps> {
  t: TranslationFunction;
}

interface IStoresProps {
  devicesDone: Device[];
  devicesTodo: Device[];
  hasForbiddenDevice: boolean;
  hasMC3D01RMCModbusConfiguredAsProductionCounter: boolean;
  hasCounter: boolean;
  showSnackBar: (params: ISnackBarParams) => void;
}

const FunctionsView = safeMobxInject<IStoresProps, IProps>(
  stores => ({
    devicesDone: stores.inscription.devicesDone,
    devicesTodo: stores.inscription.devicesTodo,
    hasMC3D01RMCModbusConfiguredAsProductionCounter:
      stores.inscription.hasMC3D01RMCModbusConfiguredAsProductionCounter,
    showSnackBar: stores.snackBar.showSnackBar,
    hasCounter: stores.inscriptionSteps.getHasCounter,
    hasForbiddenDevice: stores.inscriptionSteps.getFunctionsHasForbidden
  }),
  ({
    t,
    history,
    devicesDone,
    devicesTodo,
    hasCounter,
    showSnackBar,
    hasForbiddenDevice,
    hasMC3D01RMCModbusConfiguredAsProductionCounter,
    match: {
      params: { pvInstallationId, customerId }
    }
  }: IProps & IStoresProps) => {
    useScrollToInscriptionStep(InscriptionStep.FUNCTIONS);

    return (
      <RefreshDevicesInscriptionQuery>
        <Col>
          <ShowAlerts
            messages={[
              { isShown: !hasCounter, text: t('StepTodo:functionsNoCounter') },
              {
                isShown: hasForbiddenDevice,
                text: t('StepTodo:functionsForbidden')
              },
              {
                isShown: hasMC3D01RMCModbusConfiguredAsProductionCounter,
                type: 'info',
                text: t('StepTodo:modbusProductionCounter')
              }
            ]}
          />

          <Row>
            <Col lg={6} className={'mobile-helper'}>
              <List fluid={true}>
                <ListTitle text={t('Devices:doneListTitle')} />
                {devicesDone.map(
                  device =>
                    device instanceof CompositeDevice &&
                    device.editedAreCTSplit ? (
                      device.cts.map(
                        ct =>
                          ct.isDone && (
                            <DeviceFunctionItem
                              key={ct.getKey}
                              device={ct}
                              parent={device}
                            />
                          )
                      )
                    ) : (
                      <DeviceFunctionItem key={device.getKey} device={device} />
                    )
                )}
                <ListItem className={'save-button'}>
                  <SaveInscriptionQueryButton
                    onCompleted={() => {
                      showSnackBar({
                        text: t('Success:updateFunctions'),
                        type: 'success'
                      });
                      history.push(
                        getFinishPath({
                          pvInstallationId,
                          customerId
                        })
                      );
                    }}
                  />
                </ListItem>
              </List>
            </Col>
            {devicesTodo.length !== 0 && (
              <Col lg={6} className={'mobile-helper'}>
                <List fluid={true}>
                  <ListTitle text={t('Devices:unusedFunctions')} />
                  {devicesTodo.map((device: any) => (
                    <DeviceFunctionItem
                      isUnused
                      key={device.getKey}
                      device={device}
                    />
                  ))}
                </List>
              </Col>
            )}
          </Row>
        </Col>
      </RefreshDevicesInscriptionQuery>
    );
  }
);

export default translate(['Common', 'Devices', 'RoomName'])(
  withRouter(FunctionsView)
);
