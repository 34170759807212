import { action, computed, observable } from 'mobx';

export const splitRangesStr = (str?: string) =>
  str ? str.split('off').filter(g => g.includes('mon')) : [];

export class TimeRange {
  @computed
  get getStr() {
    return `${this.startD} ${this.startH}:${this.startM} on;${this.endD} ${
      this.endH
    }:${this.endM} off`;
  }
  @observable public startD: string = 'mon';

  @observable public startH: number = 0;

  @observable public startM: number = 0;

  @observable public endH: number = 8;

  @observable public endM: number = 0;

  /**
   * @param srsRangeStr (i.e "mon 10:0 on;mon 12:0")
   */

  @action.bound
  public fromSrsStr(srsRangeStr: string) {
    const [a, b] = srsRangeStr.split('on;');
    const [sh, sm] = a
      .split(/\W/)
      .map(substr => (substr === '' ? NaN : Number(substr)))
      .filter(number => !isNaN(number));
    const [eh, em] = b
      .split(/\W/)
      .map(substr => (substr === '' ? NaN : Number(substr)))
      .filter(number => !isNaN(number));
    this.startM = sm;
    this.startH = sh;
    this.endH = eh;
    this.endM = em;
  }

  @action.bound
  public setStartM(startM: number) {
    this.startM = startM;
  }

  @action.bound
  public setStartH(startH: number) {
    this.startH = startH;
  }

  @action.bound
  public setEndM(endM: number) {
    this.endM = endM;
  }

  @action.bound
  public setEndH(endH: number) {
    this.endH = endH;
  }

  @computed
  get endD(): string {
    return this.endH < this.startH ||
      (this.endH === this.startH && this.endM <= this.startM)
      ? 'tue'
      : 'mon';
  }
}
