import * as React from 'react';
import { Mutation, MutationResult, Query, QueryResult } from 'react-apollo';

import gql from 'graphql-tag';
import { RouteComponentProps, withRouter } from 'react-router';
import {
  EElectricInstallation,
  ElectricInstallation,
  EnergyDistributor,
  EPVInstallation,
  PVInstallation
} from '../../../graphql/graphql.schema';
import { translate, TranslationFunction } from 'react-i18next';
import EditInstallationFormforInscription from './EditInstallationFormforInscription';
import { getMsbPath, getPartnerPath } from '../routingHelper';
import { ISnackBarParams } from '../../../stores/userFeedback/snackbarStore';
import { UPDATE_INSTALLATIONS } from '../../customers/installation/edit/EditInstallationQuery';
import { IInscriptionNavigationRouterParamProps } from '../InscriptionNavigation';
import { safeMobxInject } from '../../../stores/storeInjectionHelpers';
import { ApolloError } from 'apollo-client';

export const GET_INSTALLATION = gql`
  query getInstallation($pvInstallationId: String!) {
    pvInstallationById(id: $pvInstallationId) {
      id
      canWrite
      panelCount
      panelWattPeak
      panelManufacturer
      srsRegistrationDate
      srsRegistrationStatus
      currency
      srsLicenceStatus
      electricInstallations {
        id
        sellingPower
        sellingEnergyPrice
        powerProvider
        powerContractType
        powerContractOption
        rackingPower
        internetBoxModel
        gridType
        internetServiceProvider
        masterTypeInternetConnection
        timeSlots
        pdlNumber
        simSerialNumber
        simRouterReference
        energyDistributor {
          id
          name
        }
      }
    }
  }
`;

export const GET_ENERGY_DISTRIBUTOR = gql`
  query getEnergyDistributorByElectricInstallationId(
    $electricInstallationId: String!
  ) {
    energyDistributorByElectricInstallationId(
      electricInstallationId: $electricInstallationId
    ) {
      id
      name
      pdlPattern
    }
  }
`;

export interface IUpdateInstallation {
  pVInstallation: EPVInstallation;
  electricalInstallation: EElectricInstallation;
}

interface IResponse {
  pvInstallationById: PVInstallation;
  powerProviders: string[];
}

interface IEnergyDistributorResponse {
  energyDistributorByElectricInstallationId: EnergyDistributor;
}

interface IStoresProps {
  electricInstallationId?: string;
  setElectricalInstallation: (
    electricInstallation: ElectricInstallation
  ) => void;
  setEnergyDistributor: (energyDistributor: EnergyDistributor) => void;
  setPvInstallation: (electricInstallation: PVInstallation) => void;
  showSnackBar: (params: ISnackBarParams) => void;
  setElectricalInstallationEdited: (edited: boolean) => void;
  setPVInstallationEdited: (edited: boolean) => void;
  haveRightToAccessMSB: boolean;
  setInstallationStep: (status: 'done') => void;
}

export interface IProps
  extends RouteComponentProps<IInscriptionNavigationRouterParamProps> {
  t: TranslationFunction;
}

const EditInstallationQueryFormInscription = safeMobxInject<
  IStoresProps,
  IProps
>(
  allStores => ({
    electricInstallationId: allStores.editElectricalInstallation.id,
    setElectricalInstallation:
      allStores.editElectricalInstallation.setElectricalInstallation,
    setEnergyDistributor:
      allStores.editElectricalInstallation.setEnergyDistributor,
    setPvInstallation: allStores.editPVInstallation.setPvInstallation,
    showSnackBar: allStores.snackBar.showSnackBar,
    setElectricalInstallationEdited:
      allStores.editElectricalInstallation.setEdited,
    setPVInstallationEdited: allStores.editPVInstallation.setEdited,
    haveRightToAccessMSB: allStores.battery.haveRightToAccessMSB,
    setInstallationStep: allStores.inscriptionSteps.setInstallationStep
  }),
  (props: IProps & IStoresProps) => {
    const {
      electricInstallationId,
      setElectricalInstallation,
      setEnergyDistributor,
      setPvInstallation,
      history,
      showSnackBar,
      t,
      match: {
        params: { customerId, pvInstallationId }
      },
      haveRightToAccessMSB,
      setElectricalInstallationEdited,
      setPVInstallationEdited,
      setInstallationStep
    } = props;

    return (
      <Query
        query={GET_INSTALLATION}
        variables={{ pvInstallationId, customerId }}
        fetchPolicy={'no-cache'}
        onCompleted={(data: IResponse) => {
          const pvInstallation = data.pvInstallationById;
          setPvInstallation(pvInstallation);
          const [electricalInstall]: ElectricInstallation[] =
            pvInstallation.electricInstallations || [];
          if (electricalInstall) {
            setElectricalInstallation(electricalInstall);
          }
        }}
      >
        {({ loading: loadingGetInstallation }: QueryResult<IResponse>) => {
          if (!electricInstallationId) {
            return '<></>';
          }
          return (
            <Query
              query={GET_ENERGY_DISTRIBUTOR}
              variables={{ electricInstallationId }}
              fetchPolicy={'no-cache'}
              onCompleted={(data: IEnergyDistributorResponse) => {
                if (data.energyDistributorByElectricInstallationId) {
                  const energyDistributor =
                    data.energyDistributorByElectricInstallationId;
                  setEnergyDistributor(energyDistributor);
                }
              }}
              onError={() => {
                showSnackBar({
                  text: t('Common:loadError'),
                  type: 'error'
                });
                setTimeout(
                  () => history.push(`/customers/${customerId}/installation`),
                  500
                );
              }}
            >
              {({
                loading: loadingGetEnergyDistributor
              }: QueryResult<IEnergyDistributorResponse>) => {
                return (
                  <Mutation
                    mutation={UPDATE_INSTALLATIONS}
                    onCompleted={() => {
                      setElectricalInstallationEdited(false);
                      setPVInstallationEdited(false);
                      setInstallationStep('done');
                      showSnackBar({
                        text: t('Success:updateInstallation'),
                        type: 'success'
                      });
                      if (haveRightToAccessMSB) {
                        history.push(
                          getMsbPath({
                            customerId,
                            pvInstallationId
                          })
                        );
                      } else {
                        history.push(
                          getPartnerPath({
                            customerId,
                            pvInstallationId
                          })
                        );
                      }
                    }}
                    onError={(error: ApolloError) => {
                      let errorMessage = t('Errors:serverError');

                      if (error.graphQLErrors[0].message.message) {
                        errorMessage =
                          error.graphQLErrors[0].message.message.message ||
                          error.graphQLErrors[0].message.message ||
                          errorMessage;
                        if (
                          error.graphQLErrors[0].message.message.type ===
                          'PdlIsNotUniqueException'
                        ) {
                          errorMessage = t('Installation:pdlIsNotUnique');
                        }
                      }

                      showSnackBar({
                        text: errorMessage,
                        type: 'error'
                      });
                    }}
                  >
                    {(
                      updateInstallations,
                      {
                        called,
                        data: saveResult,
                        loading: saveLoading
                      }: MutationResult<IUpdateInstallation>
                    ) => (
                      <EditInstallationFormforInscription
                        loading={loadingGetInstallation || loadingGetEnergyDistributor}
                        done={called && !!saveResult}
                        saveLoading={saveLoading}
                        save={({
                          pVInstallation,
                          electricalInstallation
                        }: IUpdateInstallation) => {
                          updateInstallations({
                            variables: {
                              pVInstallation,
                              electricalInstallation
                            }
                          });
                        }}
                      />
                    )}
                  </Mutation>
                );
              }}
            </Query>
          );
        }}
      </Query>
    );
  }
);

export default translate('InstallationEdit')(
  withRouter(EditInstallationQueryFormInscription)
);
