import React, { Component } from 'react';

import { inject, observer } from 'mobx-react';
import List from '../../../../ui/DataDisplay/List/ListWrapper';
import Spinner from '../../../../ui/Spinner/Spinner';
import { ListTitle } from '../../../../ui/DataDisplay/List/ListHeader';
import EnphaseEmailQuery from './EnphaseEmailQuery';
import EnphaseEnvoyQuery from './EnphaseEnvoyQuery';
import { translate, TranslationFunction } from 'react-i18next';
import {
  Field,
  FieldList
} from '../../../../ui/DataDisplay/FieldList/FieldList';
import EnphaseDisconnectButton from './EnphaseDisconnectButton';
import { IAllStores } from '../../../../stores/allStores.model';
import { EnphaseStore } from '../../../../stores/partnerships/enphaseStore';

interface IProps {
  enphaseStore?: EnphaseStore;
  t: TranslationFunction;
  loading?: boolean;
  createEnphaseAccount?: any;
}

@inject(({ enphaseStore }: IAllStores) => {
  return {
    enphaseStore
  };
})
@observer
class EnphaseRegistrationView extends Component<IProps> {
  public componentWillMount(): void {
    this.props.enphaseStore!.resetEnphaseStore();
    this.props.createEnphaseAccount();
  }

  public render() {
    const { enphaseStore, loading, createEnphaseAccount, t } = this.props;

    const { customerEmail, customerEnphaseDevice } = enphaseStore!;

    const deviceId =
      (customerEnphaseDevice && customerEnphaseDevice.macSerialNumber) || '';

    return (
      <List fluid={true}>
        {enphaseStore!.status === 'connected' ? (
          <div className="enphase-disconnect-container">
            <FieldList>
              <ListTitle text={t('EnphasePartnership:enphaseDetails')} />
              <Field
                name={t('EnphasePartnership:email')}
                text={
                  customerEmail // FIXME use new store or Apollo
                }
              />
              <Field
                name={t('EnphasePartnership:enphaseSystemId')}
                text={deviceId}
              />
              <div className={'enphase-disconnect-button'}>
                <EnphaseDisconnectButton deviceId={deviceId} />
              </div>
            </FieldList>
          </div>
        ) : (
          <div className="enphase-container">
            <EnphaseEmailQuery createEnphaseAccount={createEnphaseAccount} />
            <EnphaseEnvoyQuery />
          </div>
        )}
      </List>
    );
  }
}

export default translate(['EnphasePartnership, Partnerships'])(
  EnphaseRegistrationView
);
