import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faExclamationCircle,
  faPlus,
  faQuestionCircle,
  faTrash
} from '@fortawesome/free-solid-svg-icons';

export const fontAwesomelibrary = () => {
  library.add(faExclamationCircle);
  library.add(faQuestionCircle);
  library.add(faPlus);
  library.add(faTrash);
};
