import React, { ReactNode } from 'react';

import './background.scss';

interface IProps {
  children: ReactNode;
}
export const Background = ({ children }: IProps) => (
  <div className="background">{children}</div>
);
