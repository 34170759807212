import { action, observable } from 'mobx';

export class Environment {
  @observable public backUrl: string = '';
  @observable public prosizerUrl: string = '';
  @observable public blobStorageConsuelUrl: string = '';
  @observable public blobStorageArpeUrl: string = '';
  @observable public appinsightInstrumentation: string = '';
  @observable public googleApiKey: string = '';
  @observable public accountCreationUrl: string = '';
  @observable public publicUrl: string = '';

  @action.bound
  public setConfig({
    REACT_APP_PROSIZER_URL,
    REACT_APP_CONSUEL_URL,
    REACT_APP_ARPE_URL,
    REACT_APP_MY_LIGHT_BACKEND_URL,
    REACT_APP_MY_LIGHT_APPINSIGHT_INSTRUMENTATION_KEY,
    REACT_APP_GOOGLEAPIS_API_KEY,
    REACT_APP_ACCOUNT_CREATION_URL,
    PUBLIC_URL
  }: any) {
    this.prosizerUrl = REACT_APP_PROSIZER_URL;
    this.blobStorageConsuelUrl = REACT_APP_CONSUEL_URL;
    this.blobStorageArpeUrl = REACT_APP_ARPE_URL;
    this.backUrl = REACT_APP_MY_LIGHT_BACKEND_URL;
    this.appinsightInstrumentation = REACT_APP_MY_LIGHT_APPINSIGHT_INSTRUMENTATION_KEY;
    this.googleApiKey = REACT_APP_GOOGLEAPIS_API_KEY;
    this.accountCreationUrl = REACT_APP_ACCOUNT_CREATION_URL;
    this.publicUrl = PUBLIC_URL;
  }
}

const environmentStore: Environment = new Environment();
export default environmentStore;
