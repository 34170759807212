import { action, observable } from 'mobx';

export default class ProUser {
  @observable public email: string = '';
  @observable public lastName: string = '';
  @observable public loggedIn: boolean = false;

  @action
  public setEmail(input: string) {
    this.email = input;
  }

  public getToken() {
    return localStorage.getItem('Auth-Token');
  }
}
