import { RouteComponentProps, withRouter } from 'react-router';
import { IInscriptionNavigationRouterParamProps } from '../../../devices_inscription/InscriptionNavigation';
import { TranslationFunction } from 'i18next';
import {
  CMP,
  FUNCTION_TYPE,
  GMD,
  RS485,
  TPH
} from '../../../../stores/devices_inscription/contants';
import ListItem from '../../../../ui/DataDisplay/List/ListItem';
import classnames from 'classnames';
import { Col, Row } from 'reactstrap';
import DeviceIcon from '../../../../assets/images/devices/DeviceIcons';
import { translate } from 'react-i18next';
import React from 'react';
import { Device } from '../../../../graphql/graphql.schema';
import { getDeviceType } from '../../../../utils/deviceHelpers';

interface IProps
  extends RouteComponentProps<IInscriptionNavigationRouterParamProps> {
  device: Device;
  parent?: Device;
  t: TranslationFunction;
  isThreePhaseInstallation?: boolean;
}

const SystemDeviceFunction = ({
  t,
  device,
  isThreePhaseInstallation
}: IProps) => {
  const isModbus = getDeviceType(device) === RS485;
  const isModbusMC3D01RMConfiguredAsProductionCounter =
    isModbus &&
    device.modbusReference === 'MC3D01RM' &&
    device.deviceTypeId === FUNCTION_TYPE.PRODUCTION_COUNTER;
  const isCompositeDeviceNotCtSplit =
    isThreePhaseInstallation &&
    device.type === GMD &&
    !isModbus &&
    device.deviceTypeId !== 'composite_device';
  const isCmpDevice = device.type === CMP;

  const getDeviceFunctionName = () => {
    let deviceFunctionName = device.name;
    if (isCmpDevice && device.phase) {
      deviceFunctionName = `CT${device.phase} - ${deviceFunctionName}`;
    }
    return deviceFunctionName;
  };

  const getDevicePower = (
    phaseIndex?: number
  ): { power: string; unit: string } => {
    let power: number | undefined;
    switch (phaseIndex) {
      case 1:
        power = device.pow1;
        break;
      case 2:
        power = device.pow2;
        break;
      case 3:
        power = device.pow3;
        break;
      default:
        power = device.power;
    }

    if (typeof power === 'undefined' || power === null) {
      return {
        power: '0',
        unit: 'W'
      };
    }

    if (isModbusMC3D01RMConfiguredAsProductionCounter) {
      power = Math.abs(power);
    }

    if (Math.abs(Number(power)) > 1000) {
      return {
        power: (Math.round(Number(power)) / 1000).toFixed(2),
        unit: 'kW'
      };
    }
    return {
      power: String(Math.round(Number(power))),
      unit: 'W'
    };
  };

  const devicePower = getDevicePower();
  return (
    <ListItem
      className={classnames(
        'system-device-function-item',
        isThreePhaseInstallation &&
          isCompositeDeviceNotCtSplit &&
          'for-composite'
      )}
      key={device.macSerialNumber}
    >
      <Row className={'function-container'}>
        <Col className={'device-info'}>
          <Row>
            <Col className={'device-icon'}>
              <DeviceIcon
                type={device.deviceTypeId || ''}
                height={'60px'}
                width={'60px'}
              />
            </Col>
            <Col className={'device-details'}>
              <div className="device-type">{getDeviceFunctionName()}</div>
              {device.greenPlayEnabled && (
                <span className="green-play">
                  {t('Devices:greenPlayLabel')}
                </span>
              )}
              <div className="device-mac">
                {t(`DevicesFunctions:${device.deviceTypeId}`)}
              </div>
            </Col>
          </Row>
        </Col>
        <Col className={'device-power'}>
          <span
            className={classnames('badge', 'badge-pill', 'badge-success')}
            style={{ textTransform: 'none' }}
          >
            {`${devicePower.power} ${devicePower.unit}`}
          </span>
        </Col>
      </Row>
      {isThreePhaseInstallation &&
        isCompositeDeviceNotCtSplit && (
          <Row>
            <Col className={'composite-device'}>
              {[...Array(3)].map((_, childIndex) => {
                const childPower = getDevicePower(childIndex + 1);
                return (
                  <Row key={childIndex} className={'composite-device-children'}>
                    <Col className={'children-phase'}>- CT{childIndex + 1}</Col>
                    <Col className={'children-power'}>
                      <span
                        className={classnames(
                          'badge',
                          'badge-pill',
                          'badge-success'
                        )}
                        style={{ textTransform: 'none' }}
                      >
                        {`${childPower.power} ${childPower.unit}`}
                      </span>
                    </Col>
                  </Row>
                );
              })}
            </Col>
          </Row>
        )}
    </ListItem>
  );
};

export default withRouter(
  translate(['Common', 'Devices', 'RoomName', 'DevicesFunctions'])(
    SystemDeviceFunction
  )
);
