import { default as React } from 'react';

export const IconSuccess = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    data-cy={'success'}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3661 4.23431C13.6785 4.54673 13.6785 5.05327 13.3661 5.36569L6.96608 11.7657C6.65366 12.0781 6.14713 12.0781 5.83471 11.7657L2.63471 8.56568C2.32229 8.25326 2.32229 7.74673 2.63471 7.43431C2.94712 7.12189 3.45366 7.12189 3.76608 7.43431L6.40039 10.0686L12.2347 4.23431C12.5471 3.9219 13.0537 3.9219 13.3661 4.23431Z"
      fill="#10B981"
    />
  </svg>
);
