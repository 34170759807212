import React from 'react';
import { IDeviceIcon } from '../DeviceIcons';

const TadoAC = (props: IDeviceIcon) => (
  <svg
    xmlSpace="preserve"
    width="3rem"
    height="3rem"
    viewBox="0 0 20000 20000"
    id="prefix__svg5908"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    color="black"
    {...props}
  >
    <defs id="prefix__defs5910">
      <style type="text/css" id="style5912">
        {`.prefix__fil0{fill:${props.color}`}
      </style>
    </defs>
    <g id="prefix__Layer_x0020_1">
      <path
        id="prefix__path5917"
        d="M3903 8444c-56 2396-335 3206 1622 3206 94-1230-116-1090 4630-1098 4567-9 4219-101 4293 1098 462 0 850 16 1203-258 677-525 805-2906 377-3586-246-390-279-412-769-537 0 1250-55 2109-1298 2109H6173c-1240 0-1536-1088-1298-2109-631 79-952 276-973 1174z"
        style={{ fill: `${props.color}` }}
        fill={props.color}
      />
      <path
        id="prefix__path5919"
        d="M5867 11643c1121 3 7027-22 8285 7 107-563-96-801-686-801l-6548-10c-852-10-1093 121-1051 804z"
        style={{ fill: `${props.color}` }}
        fill={props.color}
      />
      <path
        id="prefix__path5921"
        d="M5630 7995h8986c203-181 225-361 0-541H5630c-192 180-185 361 0 541z"
        style={{ fill: `${props.color}` }}
        fill={props.color}
      />
      <path
        d="M11204 12137h-325v811h325z"
        id="prefix__polygon5923"
        fill={props.color}
      />
      <path
        id="prefix__path5925"
        d="M5630 8835h8986c203-181 225-361 0-541H5630c-192 180-185 361 0 541z"
        style={{ fill: `${props.color}` }}
        fill={props.color}
      />
      <path
        d="M9574 12137h-325v811h325z"
        id="prefix__polygon5927"
        fill={props.color}
      />
      <path
        d="M10389 12137h-325v811h325z"
        id="prefix__polygon5929"
        fill={props.color}
      />
    </g>
  </svg>
);

export default TadoAC;
