import React from 'react';
import { IDeviceIcon } from '../DeviceIcons';

const AlarmClock = (props: IDeviceIcon) => (
  <svg
    width="3rem"
    height="3rem"
    viewBox="0 0 20000 20000"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    color="black"
    {...props}
  >
    <g id="prefix__layer">
      <g id="prefix__alarm_clock">
        <path
          style={{ fill: `${props.color}` }}
          d="M8762 4707h2533c514 106 895 343 955 902 216 73 410 167 607 261 131-53 168-345 347-366 380-44 131 421-17 539 361 299 726 592 850 1128v330c-115 245-109 475-294 555-277 119-678-128-886-226-144-68-382-194-572-278-131-56-121-96-157-17-39 91 260 255 313 295 795 610 1482 1583 1562 2863 95 1567-494 2367-1215 3158 110 164 194 293 329 485 84 121 558 649 105 626-149-8-284-262-382-400-134-188-216-343-313-468-575 460-1278 792-2221 886h-589c-932-99-1641-421-2204-886-90 127-198 263-330 452-95 134-200 396-348 416-80 10-211-54-227-122-32-146 217-392 295-504 130-182 214-306 330-450-614-676-1117-1463-1215-2656v-485c146-1338 714-2282 1563-2932 108-83 263-126 330-261-91-86-246 41-365 104-226 121-883 551-1231 400-182-78-202-354-296-555v-313c118-530 455-841 833-1112-26-182-272-242-243-433 16-104 127-152 227-139 176 24 217 327 348 383 180-102 374-192 590-261 67-568 449-823 988-919zm537 1388c96 388-368 686-625 868-154 109-515 282-485 381 32 106 211 3 347-52 449-178 824-273 1353-294-34-291-87-560-121-851-128 8-280 76-399 35-74-46-71-171-70-295 237-112 642-146 919-157 78 46 73 173 71 296-83 91-157 74-301 103 22 307 81 581 122 868 560-1 967 115 1376 277 107 42 311 216 364 34-37-71-127-134-208-191-258-178-560-353-746-571-439-517 188-1137 954-1025-259-697-1633-529-2638-486-547 24-933 113-1040 486 613-39 1025 160 1127 573zM7355 8594c-537 597-993 1460-901 2636 83 1077 517 1814 1162 2395 636 570 1514 1001 2638 937 1046-59 1831-557 2394-1163 589-633 984-1453 937-2637-21-514-173-977-381-1387-554-1095-1734-2068-3418-1945-1075 78-1896 567-2430 1163z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M9681 9376c-29 102-157 106-243 155 184 396 366 792 556 1180 90 31 178 65 243 121 644-183 1303-351 1961-521-3-101-78-206-35-295 253 14 566 119 781 227-182 205-341 433-608 554-36-74-69-152-70-260-682 143-1318 336-1977 503-83 376-728 155-486-209-178-407-381-787-556-1198-122 5-164 91-295 87-24-256 8-604 87-815 239 130 461 279 642 470z"
        />
        <ellipse
          style={{ fill: `${props.color}` }}
          cx={10028}
          cy={7787}
          rx={136}
          ry={184}
        />
        <ellipse
          style={{ fill: `${props.color}` }}
          transform="matrix(-.40707 .41586 -.41586 -.40707 7739.49 8725.47)"
          rx={233}
          ry={317}
        />
        <ellipse
          style={{ fill: `${props.color}` }}
          transform="matrix(.01245 .58183 -.58183 .01245 6808.33 11008.1)"
          rx={233}
          ry={317}
        />
        <ellipse
          style={{ fill: `${props.color}` }}
          transform="rotate(43.181 -12916.635 16419.34) scale(.58195)"
          rx={233}
          ry={316}
        />
        <ellipse
          style={{ fill: `${props.color}` }}
          transform="matrix(.58143 -.02438 .02438 .58143 10028.2 14167.1)"
          rx={233}
          ry={316}
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M12326 13150c76 68 96 168 46 224s-152 46-228-22c-75-68-96-168-46-224s152-46 228 22z"
        />
        <ellipse
          style={{ fill: `${props.color}` }}
          transform="matrix(-.03701 -.58079 .58079 -.03701 13185.5 11007.3)"
          rx={233}
          ry={316}
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M12138 8608c67-77 166-100 223-51s49 151-17 228c-67 77-166 100-223 51s-49-151 17-228z"
        />
      </g>
    </g>
  </svg>
);

export default AlarmClock;
