import React from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { translate, TranslationFunction } from 'react-i18next';
import Spinner from '../../../../ui/Spinner/Spinner';
import styles from './SelectDevicesConfigurationModeModal.module.scss';

interface IProps {
  t: TranslationFunction;
  isDeviceConfigurationQuestionModalOpened: boolean;
  isLoading: boolean;
  openUcgPreconfigurationToolModal: () => void;
  selectDevicesConfigurationMode: () => Promise<boolean>;
}

const SelectDevicesConfigurationModeModalView = ({
  t,
  openUcgPreconfigurationToolModal,
  isLoading,
  isDeviceConfigurationQuestionModalOpened,
  selectDevicesConfigurationMode
}: IProps) => {
  return (
    <Modal
      className={styles.modalContainer}
      isOpen={isDeviceConfigurationQuestionModalOpened}
      centered
    >
      <ModalHeader>{t('devicesConfigurationModeTitle')}</ModalHeader>
      <ModalBody className={styles.modalInnerContainer}>
        {isLoading || !isDeviceConfigurationQuestionModalOpened ? (
          <Spinner loading transparent />
        ) : (
          <div className={styles.buttonContainer}>
            <Button
              color="primary"
              onClick={async () => {
                const isSuccess = await selectDevicesConfigurationMode();
                if (isSuccess) {
                  openUcgPreconfigurationToolModal();
                }
              }}
            >
              {t('devicesConfigurationModeAuto')}
            </Button>

            <Button onClick={selectDevicesConfigurationMode}>
              {t('devicesConfigurationModeManual')}
            </Button>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default translate('Devices')(SelectDevicesConfigurationModeModalView);
