import * as React from 'react';
import { ICustomerExtended } from '@mylight/data-model';

import RegistrationStatus from '../../../constants/REGISTRATION_STATUS';
import { getTenantLogo } from '../../../utils/logo';
import LoginAsLink from '../../../components/login_as/LoginAsLink';
import { translate } from 'react-i18next';
import { TranslationFunction } from 'i18next';
import './fixedHeader.scss';
import { ApolloError } from 'apollo-client';
import ContinueSubscriptionButton from '../../../components/redirect/ContinueSubscriptionButton';
import { shouldDisplayContinueInstallation } from '../../../utils/tools';

interface IProps {
  customer: ICustomerExtended | undefined;
  loading: boolean;
  error: ApolloError | undefined;

  t: TranslationFunction;
}
const RedirectButton = ({ customer }: { customer: any }) =>
  shouldDisplayContinueInstallation(customer.status) ? (
    <ContinueSubscriptionButton
      customerId={customer.id}
      color="primary"
      textKey="inscriptionFull"
      pvInstallationId={customer.pvInstallationId}
      srsRegistrationStatus={customer.status}
    />
  ) : (
    <LoginAsLink email={customer.email} disabled={!customer.loginAsAllowed} />
  );
const FixedHeader = ({ customer, loading, error, t }: IProps) => {
  return (
    <div className="fixed-header">
      {customer &&
        customer.tenant &&
        customer.tenant.name && (
          <div className={'tenant-info'}>
            <img
              alt="logo-mylight"
              className={'logo'}
              src={getTenantLogo(customer.tenant.name)}
            />
            <RedirectButton customer={customer} />
          </div>
        )}
      <div
        className="status"
        style={{
          color:
            customer && customer.status === RegistrationStatus.OK
              ? '#96D74F'
              : '#F79E0D'
        }}
      >
        {customer
          ? t(`RegistrationStatus:${customer.status}`).toUpperCase()
          : '-'}
      </div>
    </div>
  );
};

export default translate(['Customer', 'RegistrationStatus'])(FixedHeader);
