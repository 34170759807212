import { action, observable } from 'mobx';
import {
  CustomerListFilter,
  CustomerListFilterableColumn,
  CustomerListFilterRule,
  CustomerListSortableColumn,
  CustomerListSorting,
  CustomerListSortingRule
} from '../../graphql/graphql.schema';

export const possibleCustomerListFilterOptions: CustomerListFilterRule[] = [
  CustomerListFilterRule.no,
  CustomerListFilterRule.pending,
  CustomerListFilterRule.ok
];

export default class CustomersList {
  public pageSize: number = 10;

  @observable
  public sortingRule: CustomerListSorting = {
    column: CustomerListSortableColumn.registrationDate,
    rule: CustomerListSortingRule.DESC
  };
  @observable public filterRule: CustomerListFilter[] = [];
  @observable public searchRule: string = '';
  @observable public offset: number = 0;

  @action.bound
  public setSearchRule(searchRule: string) {
    this.searchRule = searchRule;
  }
  @action.bound
  public setOffset(offset: number) {
    this.offset = offset;
  }

  @action.bound
  public resetFilterRule(): void {
    this.filterRule = [];
  }

  @action.bound
  public toggleFilterRule(
    columnName: CustomerListFilterableColumn,
    ruleName: CustomerListFilterRule
  ): void {
    const indexOfTheSameFilter = this.filterRule.findIndex(
      ({ column, rule }) => column === columnName && rule === ruleName
    );
    if (indexOfTheSameFilter > -1) {
      this.filterRule = [
        ...this.filterRule.slice(0, indexOfTheSameFilter),
        ...this.filterRule.slice(indexOfTheSameFilter + 1)
      ];
    } else {
      this.filterRule = [
        ...this.filterRule,
        { rule: ruleName, column: columnName }
      ];
    }
  }

  @action.bound
  public setSortingRule(column: CustomerListSortableColumn) {
    this.sortingRule = {
      column,
      rule:
        this.sortingRule.column === column &&
        this.sortingRule.rule === CustomerListSortingRule.ASC
          ? CustomerListSortingRule.DESC
          : CustomerListSortingRule.ASC
    };
  }
}
