import React from 'react';

import { inject, observer } from 'mobx-react';
import ListItem from '../../../../ui/DataDisplay/List/ListItem';
import ListHeader from '../../../../ui/DataDisplay/List/ListHeader';
import { Col, Row } from 'reactstrap';
import { TranslationFunction } from 'i18next';
import { translate } from 'react-i18next';
import Spinner from '../../../../ui/Spinner/Spinner';
import classnames from 'classnames';
import ListPlaceHolder from '../../../../ui/DataDisplay/List/ListPlaceHolder';
import List from '../../../../ui/DataDisplay/List/ListWrapper';
import { Store } from '../../../../stores';
import { Link } from 'react-router-dom';
import SystemDiagnosticQuery, { Status } from '../SystemDiagnosticQuery';
import moment from 'moment';
import RightHandler from '../../../rights/RightHandler/RightHandler';
import { DiagnosticSet } from '../../../../graphql/graphql.schema';

export interface IProps {
  t: TranslationFunction;
  diagnosticSummary: DiagnosticSet[];
  loading: boolean;
  error?: string;
  customerId: string;
  Store?: Store;
}

const DiagnosticSummaryList = inject('Store')(
  observer(
    ({
      t,
      diagnosticSummary,
      loading,
      customerId,
      error,
      Store: mobix
    }: IProps) => {
      const t48 = diagnosticSummary.find(d => d.name === 'T48');
      return (
        <Spinner loading={loading} error={error}>
          <List fluid={true}>
            <Row className="list-title">
              <Col xs={4}>{t('summaryTitle')}</Col>
              <Col className="text-right" xs={8}>
                <RightHandler featureKey="SEND_T48">
                  <SystemDiagnosticQuery
                    id={customerId}
                    status={t48 && (t48.status as Status)}
                  />
                </RightHandler>
              </Col>
            </Row>
            <ListHeader>
              <Col md="3" className="d-none d-sm-none d-md-block">
                {`${t('testNameLabel')}`}
              </Col>
              <Col md="2" className="d-none d-sm-none d-md-block">
                {`${t('testSetStatus')}`}
              </Col>
              <Col md="1" className="text-center">
                {`${t('testErrorLabel')}`}
              </Col>
              <Col md="1" className="text-center">
                {`${t('testOkLabel')}`}
              </Col>

              <Col md="3" className="text-center">
                {`${t('date')}`}
              </Col>
              <Col md="2" className="text-center" />
            </ListHeader>
            {diagnosticSummary.length === 0 ? (
              <ListPlaceHolder text={t('emptyTestList')} />
            ) : (
              diagnosticSummary.map((sub: DiagnosticSet) => {
                const { nbError, nbSuccess } = (sub.diagnostics || []).reduce(
                  (a, b) => {
                    if (b.status === 'ok') {
                      a.nbSuccess = a.nbSuccess + 1;
                    } else {
                      a.nbError = a.nbError + 1;
                    }
                    return a;
                  },
                  { nbError: 0, nbSuccess: 0 }
                );
                return (
                  <ListItem key={`${sub.id}`}>
                    <Col className="name" xs={3} md={3}>
                      {sub.name}
                    </Col>
                    <Col xs={2} md={2}>
                      {t(sub.status || '')}
                    </Col>
                    <Col xs={1} md={1} className="text-center">
                      <span
                        className={classnames(
                          'badge',
                          'badge-pill',
                          nbSuccess === 0 ? 'badge-secondary' : 'badge-error'
                        )}
                      >
                        {nbError}
                      </span>
                    </Col>
                    <Col xs={1} md={1} className="text-center">
                      <span
                        className={classnames(
                          'badge',
                          'badge-pill',
                          nbSuccess === 0 ? 'badge-secondary' : 'badge-success'
                        )}
                      >
                        {nbSuccess}
                      </span>
                    </Col>
                    <Col xs={3} md={3} className="text-center">
                      <span>{moment(sub.createdAt).format('DD/MM/YY')}</span>
                    </Col>
                    <Col xs={2} md={2} className="text-center">
                      <Link
                        className={classnames('btn', 'btn-secondary')}
                        to={`/customers/${customerId}/diagnostics/${sub.id}`}
                      >
                        {t('details')}
                      </Link>
                    </Col>
                  </ListItem>
                );
              })
            )}
          </List>
        </Spinner>
      );
    }
  )
);

export default translate('DiagnosticSummary')(DiagnosticSummaryList);
