import { action, observable } from 'mobx';

export default class CreateCustomer {
  @observable public isOpen: boolean = false;

  @action.bound
  public toggleDropdown() {
    this.isOpen = !this.isOpen;
  }
}
