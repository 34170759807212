import * as React from 'react';
import { Query, QueryResult } from 'react-apollo';
import { GridType, ISystem } from '@mylight/data-model';

import RegistrationStatus from '../../../constants/REGISTRATION_STATUS';

import gql from 'graphql-tag';
import { RouteComponentProps, withRouter } from 'react-router';
import { getErrorsMessageGraphQl } from '../../../utils/graphQl';
import SystemView from './SystemView';
import Spinner from '../../../ui/Spinner/Spinner';
import {
  Customer,
  Device,
  ElectricInstallation,
  PVInstallation
} from '../../../graphql/graphql.schema';
import ContinueSubscriptionButton from '../../redirect/ContinueSubscriptionButton';
import { TranslationFunction } from 'i18next';
import { safeMobxInject } from '../../../stores/storeInjectionHelpers';
import ListPlaceHolder from '../../../ui/DataDisplay/List/ListPlaceHolder';
import { translate } from 'react-i18next';
import { extractCustomer } from '../customersList/customerExtractor';
import { UserTypes } from '../../../constants/UserTypes';

export const GET_SYSTEM = gql`
  query getSystem($id: String!) {
    system(id: $id) {
      boxSerialNumber
      internetProvider
      lastConnection
      masterActivationSerialNumber
      registrationDate
      status
      solarPanel {
        deviceName
        energy
      }
      network {
        deviceName
        energy
      }
      totalNetwork {
        deviceName
        energy
      }
      localBufferRecovery
      consumptionUpToDate
      productionUpToDate
    }
    getOneToOneCustomerData(customerId: $id) {
      id
      customer {
        id
        firstName
        lastName
        address
        country
        email
        city
        zipCode
        msbStatus
        smsAlertsEnabled
        emailAlertsEnabled
        pvInstallations {
          srsRegistrationStatus
          tenant {
            id
            name
          }
        }
      }
      pVInstallation {
        id
        canWrite
        panelCount
        panelWattPeak
        panelManufacturer
        srsRegistrationDate
        srsRegistrationStatus
        currency
        srsLicenceStatus
        ucgSerialNumber
      }
      electricInstallation {
        id
        sellingPower
        sellingEnergyPrice
        powerProvider
        powerContractType
        powerContractOption
        rackingPower
        internetBoxModel
        gridType
        internetServiceProvider
        masterTypeInternetConnection
        timeSlots
        pdlNumber
      }
    }
    isSystemOnline(customerId: $id)
    devices(customerId: $id) {
      deviceTypeId
      macSerialNumber
      activationCode
      name
      masterMac
      power
      pow1
      pow2
      pow3
      status
      type
      emulatedFromG3
      greenPlayEnabled
      phase
      roomName
      modbusAddress
      modbusReference
      alerts(customerId: $id, active: true) {
        label
        description
        extendedDescription
      }
    }
  }
`;

interface IResponse {
  system: ISystem;
  getOneToOneCustomerData: {
    pVInstallation?: PVInstallation;
    customer?: Customer;
    electricInstallation?: ElectricInstallation;
  };
  isSystemOnline: boolean;
  devices: Device[];
}

interface IMatchParams {
  id: string;
}

export interface IProps extends RouteComponentProps<IMatchParams> {
  t: TranslationFunction;
}

interface IStoresProps {
  userType: UserTypes | null;
}

export function isInstallationDone({
  pvInstallation,
  srsRegistrationStatus
}: {
  pvInstallation?: PVInstallation;
  srsRegistrationStatus?: string;
}) {
  const status = srsRegistrationStatus
    ? srsRegistrationStatus
    : pvInstallation && pvInstallation.srsRegistrationStatus;
  return (
    status === RegistrationStatus.SETUP_OK ||
    status === RegistrationStatus.OK ||
    status === RegistrationStatus.USER_PASSWORD_SET ||
    status === RegistrationStatus.USER_SETTINGS_SET ||
    status === RegistrationStatus.USER_ACCEPTED_LICENCE
  );
}

const SystemQuery = safeMobxInject<IStoresProps, IProps>(
  stores => ({ userType: stores.Store.profile.userType }),
  ({
    match: {
      params: { id }
    },
    t,
    userType
  }: IStoresProps & IProps) => {
    return (
      <Query
        query={GET_SYSTEM}
        variables={{ id }}
        pollInterval={60000}
        fetchPolicy={'no-cache'}
      >
        {({ loading, data, error }: QueryResult<IResponse>) => {
          const pVInstallation =
            data &&
            data.getOneToOneCustomerData &&
            data.getOneToOneCustomerData.pVInstallation;

          const canAccessData =
            data &&
            data.getOneToOneCustomerData &&
            data.getOneToOneCustomerData.customer &&
            data.getOneToOneCustomerData.customer.loginAsByMaintainerEnabled;

          const isThreePhaseInstallation = Boolean(
            data &&
              data.getOneToOneCustomerData &&
              data.getOneToOneCustomerData.electricInstallation &&
              data.getOneToOneCustomerData.electricInstallation.gridType ===
                GridType.THREE_PHASE
          );

          const customer =
            data &&
            data.getOneToOneCustomerData &&
            data.getOneToOneCustomerData.customer
              ? extractCustomer(data.getOneToOneCustomerData.customer)
              : undefined;

          const devices = data && data.devices ? data.devices : [];

          const isSystemOnline = Boolean(data && data.isSystemOnline);

          const errorMessage: string | undefined = error
            ? error.graphQLErrors
              ? getErrorsMessageGraphQl(error.graphQLErrors)
                  .map(e => e.message)
                  .join('\n')
              : error.message
            : undefined;

          return (
            <Spinner
              loading={loading}
              error={
                errorMessage && errorMessage.includes('user.not.found')
                  ? t('Errors:installationUserNotFound')
                  : errorMessage
              }
            >
              {isInstallationDone({ pvInstallation: pVInstallation }) ? (
                <SystemView
                  customer={customer}
                  system={data ? data.system : undefined}
                  devices={devices}
                  canAccessData={canAccessData}
                  isSystemOnline={isSystemOnline}
                  isThreePhaseInstallation={isThreePhaseInstallation}
                  pvInstallation={pVInstallation}
                />
              ) : pVInstallation && userType !== UserTypes.ADMINISTRATOR ? (
                <div className="text-center pt-4 pb-4">
                  <ContinueSubscriptionButton
                    customerId={id}
                    textKey="inscriptionFull"
                    color="primary"
                    pvInstallationId={
                      (pVInstallation && pVInstallation.id) || ''
                    }
                    srsRegistrationStatus={
                      pVInstallation.srsRegistrationStatus || ''
                    }
                  />
                </div>
              ) : (
                <div className={'pt-3'}>
                  <ListPlaceHolder text={t('Common:noInstallation')} />
                </div>
              )}
            </Spinner>
          );
        }}
      </Query>
    );
  }
);

export default translate(['Errors', 'Common'])(withRouter(SystemQuery));
