import { createContext } from 'react';
import { IExtractedInstallation } from './installationExtractor';

export interface IInstallationContext {
  installation?: IExtractedInstallation;
  setInstallation: (installation?: IExtractedInstallation) => void;
}

export const InstallationContext = createContext<IInstallationContext>(
  {} as IInstallationContext
);
