import { action, observable } from 'mobx';

export default class LoginForm {
  @observable public emailField: string = '';
  @observable public passwordField: string = '';
  @observable public showPassword: boolean = false;

  @action.bound
  public updateEmail(input: string) {
    this.emailField = input;
  }

  @action.bound
  public updatePassword(input: string) {
    this.passwordField = input;
  }

  @action.bound
  public setShowPassword(show: boolean) {
    this.showPassword = show;
  }
}
