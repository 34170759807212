import React from 'react';
import { IDeviceIcon } from '../DeviceIcons';

const WashingMachine = (props: IDeviceIcon) => (
  <svg
    xmlSpace="preserve"
    width="3rem"
    height="3rem"
    viewBox="0 0 20000 20000"
    id="prefix__svg2"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    color="black"
    {...props}
  >
    <defs id="prefix__defs4">
      <style type="text/css" id="style6">
        {`.prefix__fil0{fill:${props.color}`}
      </style>
    </defs>
    <g id="prefix__Layer_x0020_1" fill={props.color} fillOpacity={1}>
      <g id="prefix___1405057724208" fill={props.color} fillOpacity={1}>
        <path
          style={{ fill: `${props.color}` }}
          d="M16160 16568H4040c-240 0-420-160-420-420V3948c0-240 160-420 420-420h12120c240 0 420 160 420 420v12120c0 80-80 240-160 320-20 100-100 180-260 180zm-11700-920h11280V4448H4460v11200z"
          id="prefix__path11"
          fill={props.color}
          fillOpacity={1}
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M6260 6008c0 180-140 340-320 340s-340-140-340-340c0-180 140-320 340-320 180 0 320 140 320 320z"
          id="prefix__path13"
          fill={props.color}
          fillOpacity={1}
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M8420 6008c0 180-140 340-320 340s-320-140-320-340c0-180 140-320 320-320 160 0 320 140 320 320z"
          id="prefix__path15"
          fill={props.color}
          fillOpacity={1}
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M14680 6008c0 180-140 340-320 340s-320-140-320-340c0-180 140-320 320-320 160 0 320 140 320 320z"
          id="prefix__path17"
          fill={props.color}
          fillOpacity={1}
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M10220 14908c-2140 0-3800-1740-3800-3800s1740-3800 3800-3800c2140 0 3800 1740 3800 3800 0 2080-1660 3800-3800 3800zm0-6760c-1660 0-2960 1320-2960 2960 0 1660 1320 2960 2960 2960s2960-1320 2960-2960c20-1640-1300-2960-2960-2960z"
          id="prefix__path19"
          fill={props.color}
          fillOpacity={1}
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M10220 13428c-1320 0-2380-1080-2380-2400s1080-2380 2380-2380c1320 0 2380 1080 2380 2380 20 1320-1060 2400-2380 2400zm0-3880c-420 0-740 160-1080 400-240 260-400 680-400 1100 0 820 660 1480 1480 1480s1480-660 1480-1480c0-840-660-1500-1480-1500z"
          id="prefix__path21"
          fill={props.color}
          fillOpacity={1}
        />
      </g>
    </g>
  </svg>
);

export default WashingMachine;
