import React, { useEffect, useState } from 'react';
import { translate, TranslationFunction } from 'react-i18next';
import {
  Alert,
  Button,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  Row
} from 'reactstrap';
import { getSupportedCountries } from '../../../utils/countries';
import { FormInput } from '../../FormInput/FormInput';
import GeolocationInput from '../CoordinateInput/GeolocationInput';
import LoadingButton from '../../Button/LoadingButton/LoadingButton';
import { CustomForm } from '../../FormInput/CustomForm';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import SelectInput, { ISelectValue } from '../../Select/Select';
import { IAllStores } from '../../../stores/allStores.model';
import { ISnackBarParams } from '../../../stores/userFeedback/snackbarStore';
import './AddressForm.scss';
import { ListTitle } from '../../DataDisplay/List/ListHeader';
import { safeMobxInject } from '../../../stores/storeInjectionHelpers';

type AddressPredicate = (c: google.maps.GeocoderAddressComponent) => boolean;

interface IStoreProps {
  googleApiKey: string;
  showSnackBar: (param: ISnackBarParams) => void;
}

interface IPropsType {
  cityError?: string;
  addressError?: string;
  countryError?: string;
  zipCodeError?: string;
  emailAddressError?: string;
  geolocationError?: string;
  initToCurrentPos?: boolean;
  id?: string;
  address: string;
  zipCode: string;
  city: string;
  country: ISelectValue;
  additionalAddress?: string;
  latitude?: number;
  longitude?: number;
  t: TranslationFunction;
  onAddressUpdate: (input: string) => void;
  onZipCodeUpdate: (input: string) => void;
  onCityUpdate: (input: string) => void;
  onCountryUpdate: (input: ISelectValue) => void;
  onAdditionalAddressUpdate?: (input: string) => void;
  onLatitudeUpdate: (input?: number) => void;
  onLongitudeUpdate: (input?: number) => void;
}

const AddressPicker = safeMobxInject<IStoreProps, IPropsType>(
  (allStores: IAllStores) => {
    return {
      googleApiKey: allStores.environment.googleApiKey,
      showSnackBar: allStores.snackBar.showSnackBar
    };
  },
  (props: IPropsType & IStoreProps) => {
    const geocoder = new google.maps.Geocoder();
    const {
      t,
      id,
      address,
      zipCode,
      city,
      country,
      additionalAddress,
      latitude,
      longitude,
      addressError,
      cityError,
      countryError,
      zipCodeError,
      onAddressUpdate,
      onZipCodeUpdate,
      onCityUpdate,
      onCountryUpdate,
      onAdditionalAddressUpdate,
      onLatitudeUpdate,
      onLongitudeUpdate,
      showSnackBar
    } = props;
    const [isModalOpen, setIsModalOpen]: [boolean, any] = useState(false);
    const [isLoading, setIsLoading]: [boolean, any] = useState(false);
    const [foundAddress, setFoundAddress]: [
      google.maps.GeocoderResult[],
      any
    ] = useState([]);
    const [searchAddress, setSearchAddress]: [string, any] = useState('');
    const [displayAdresseField, setDisplayAdresseField]: [
      boolean,
      any
    ] = useState(!!id);
    useEffect(
      () => {
        setDisplayAdresseField(!!id);
      },
      [id]
    );
    return (
      <>
        <ListTitle
          text={t('address')}
          children={
            displayAdresseField && (
              <Button
                onClick={event => {
                  event.preventDefault();
                  newSearch();
                  setDisplayAdresseField(false);
                }}
              >
                {t('Customer:newAddressSearch')}
              </Button>
            )
          }
        />

        <FormGroup>
          <div>
            {!displayAdresseField && (
              <Row>
                <Col className="form-group">
                  <InputGroup>
                    <Input
                      id="searchAddress"
                      type="text"
                      value={searchAddress}
                      onChange={event => {
                        setSearchAddress(event.target.value);
                      }}
                      onKeyPress={event => {
                        if (event.key === 'Enter') {
                          event.preventDefault();
                          geocode();
                        }
                      }}
                    />
                    <InputGroupAddon addonType="append">
                      <LoadingButton
                        loading={isLoading}
                        onClick={event => {
                          event.preventDefault();
                          geocode();
                        }}
                      >
                        {t('Customer:searchAddress')}
                      </LoadingButton>
                    </InputGroupAddon>
                  </InputGroup>
                </Col>
              </Row>
            )}
            {displayAdresseField && (
              <>
                <Row>
                  <Col className="form-group">
                    <FormInput
                      required
                      value={address || ''}
                      onChange={onAddressUpdate}
                      error={address && addressError && t('addressError')}
                      id="address"
                      name={t('address')}
                      type="text"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="form-group">
                    <FormInput
                      value={additionalAddress || ''}
                      onChange={onAdditionalAddressUpdate}
                      id="additionalAddress"
                      name={t('additionalAddress')}
                      type="text"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="form-group">
                    <FormInput
                      required
                      value={zipCode}
                      onChange={onZipCodeUpdate}
                      id="zipCode"
                      error={zipCode && zipCodeError && t('zipCodeError')}
                      name={t('zipCode')}
                      type="text"
                    />
                  </Col>

                  <Col className="form-group">
                    <FormInput
                      required
                      value={city}
                      error={city && cityError && t('cityError')}
                      onChange={onCityUpdate}
                      id="city"
                      name={t('city')}
                      type="text"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="form-group">
                    <SelectInput
                      id="country"
                      required
                      name={t('country')}
                      value={country}
                      options={getSupportedCountries()}
                      error={country && countryError && t('countryError')}
                      onChange={onCountryUpdate}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="form-group">
                    <CustomForm name={t('Common:geoLocation')} required={true}>
                      <GeolocationInput
                        onCurrentPositionInitialized={latLng => {
                          onLatitudeUpdate(latLng.latitude);
                          onLongitudeUpdate(latLng.longitude);
                        }}
                        onPositionUpdate={latLng => {
                          onLatitudeUpdate(latLng.latitude);
                          onLongitudeUpdate(latLng.longitude);
                        }}
                        latitude={latitude}
                        longitude={longitude}
                      />
                    </CustomForm>
                  </Col>
                </Row>
                <Row>
                  <Col className="form-group">
                    <Alert color="primary">
                      {t('Customer:canMoveCursorPositionInstallation')}
                    </Alert>
                  </Col>
                </Row>
              </>
            )}
            <Row>
              <Col className="form-group">
                <FormInput
                  required
                  disabled
                  value={longitude || ''}
                  id="longitude"
                  name={t('Customer:longitude')}
                  type="text"
                />
              </Col>

              <Col className="form-group">
                <FormInput
                  required
                  disabled
                  value={latitude || ''}
                  id="city"
                  name={t('Customer:latitude')}
                  type="text"
                />
              </Col>
            </Row>

            <Modal
              isOpen={isModalOpen}
              toggle={() => {
                setIsModalOpen(!isModalOpen);
              }}
            >
              <ModalHeader
                toggle={() => {
                  setIsModalOpen(!isModalOpen);
                }}
              >
                {t('choseAddress')}
              </ModalHeader>
              <ModalBody>
                <ListGroup>
                  {foundAddress.map(fa => (
                    <ListGroupItem
                      key={fa.place_id}
                      style={{ cursor: 'pointer' }}
                      onClick={() => selectAddress(fa)}
                    >
                      {fa.formatted_address}
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </ModalBody>
            </Modal>
          </div>
        </FormGroup>
      </>
    );

    /**
     * selectAddress selects the given address and reset the search input.
     *
     * @param  {geocoderAddress} google.maps.GeocoderResult Input Address
     */
    function selectAddress(geocoderAddress: google.maps.GeocoderResult) {
      onLatitudeUpdate(geocoderAddress.geometry.location.lat());
      onLongitudeUpdate(geocoderAddress.geometry.location.lng());

      geocoderAddress.address_components.forEach(entry => {
        if (entry.types.includes('locality')) {
          onCityUpdate(entry.long_name);
        } else if (entry.types.includes('postal_code')) {
          onZipCodeUpdate(entry.long_name);
        } else if (entry.types.includes('route')) {
          const streetNumber = geocoderAddress.address_components.find(p =>
            p.types.includes('street_number')
          );

          onAddressUpdate(
            streetNumber
              ? `${streetNumber.long_name} ${entry.long_name}`
              : entry.long_name
          );
        } else if (entry.types.includes('country')) {
          const countryValue = getSupportedCountries().find(
            c => c.value === entry.short_name
          );

          if (countryValue) {
            onCountryUpdate(countryValue);
          }
        }
      });

      setSearchAddress('');
      setDisplayAdresseField(true);
      setIsModalOpen(false);
    }

    function newSearch() {
      onLatitudeUpdate(undefined);
      onLongitudeUpdate(undefined);
      setDisplayAdresseField(false);
    }

    /**
     * geocode makes a call to Google's geocoder API to
     * retrieve a list of addresses matching the most with the address the user
     * is searching for.
     *
     * A set of filters is applies on addresses to make sure we suggest only
     * suitable items.
     */
    function geocode() {
      setIsLoading(true);

      geocoder.geocode({ address: searchAddress }, (addresses, status) => {
        setIsLoading(false);

        if (
          status !== google.maps.GeocoderStatus.OK &&
          status !== google.maps.GeocoderStatus.ZERO_RESULTS
        ) {
          showSnackBar({
            text: t('Errors:geocoderError'),
            type: 'error'
          });

          return;
        }

        // Validation rules an address must satisfy
        const addressValidationRule: AddressPredicate[] = [
          c => c.types.includes('route'),
          c => c.types.includes('locality'),
          c => c.types.includes('postal_code'),
          c => {
            if (!c.types.includes('country')) return false;

            // Check if the country is supported
            return !!getSupportedCountries().find(
              entry => c.short_name === entry.value
            );
          }
        ];

        // Filter addresses to only keep those fullfilling previously defined rules.
        const filteredAddresses = addresses.filter(addr =>
          addressValidationRule.every(
            rule => !!addr.address_components.find(component => rule(component))
          )
        );

        // Display an error message if none of the addresses match the criteria
        if (filteredAddresses.length > 0) {
          handleGeocodeResponse(filteredAddresses);
        } else {
          showSnackBar({ text: t('addressNotFound') });
        }
      });
    }

    function handleGeocodeResponse(response: google.maps.GeocoderResult[]) {
      if (response.length >= 1) {
        setIsModalOpen(true);
        setFoundAddress(response);
      } else {
        showSnackBar({ text: t('addressNotFound') });
      }
    }
  }
);

export default translate(['Common'])(AddressPicker);
