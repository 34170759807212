import { Col, Row } from 'reactstrap';
import React from 'react';
import './deviceDetail.scss';
import { inject, observer } from 'mobx-react';
import { IAllStores } from '../../../stores/allStores.model';
import { Device } from '../../../stores/inscription/device';
import { withRouter } from 'react-router';
import DeviceRoomEdition from './DeviceRoomEdition';
import DeviceInfo from './DeviceInfo';
import DeviceImage from './DeviceImage';
import { IDeviceDetailsProps } from './DeviceDetail';

const DeviceDetails = inject(
  ({ inscription: inscriptionStore }: IAllStores) => {
    return {
      getEditedDevice: inscriptionStore.getEditedDevice
    };
  }
)(
  observer(
    ({
      roomTypes,
      getEditedDevice,
      gridType,
      match: {
        params: { ethMac: ethMacFromRouter, plcMac: plcMacFromRouter }
      }
    }: IDeviceDetailsProps) => {
      const editedDevice: Device | undefined = getEditedDevice!({
        ethMac: ethMacFromRouter,
        plcMac: plcMacFromRouter
      });
      if (editedDevice) {
        return (
          <Col sm={12} lg={6} className={'device-details'}>
            <Row>
              <DeviceImage />
            </Row>
            <Row>
              <DeviceInfo />
            </Row>
            {editedDevice.isDeviceHasRoomEdition && (
              <Row>
                <DeviceRoomEdition />
              </Row>
            )}
          </Col>
        );
      }
      return <div />;
    }
  )
);

export default withRouter(DeviceDetails);
