import * as React from 'react';
import { translate } from 'react-i18next';
import { TranslationFunction } from 'i18next';
import { Col } from 'reactstrap';

import { get, sortBy } from 'lodash';

import Spinner from '../../ui/Spinner/Spinner';
import ListHeader, { ListTitle } from '../../ui/DataDisplay/List/ListHeader';
import ListItem from '../../ui/DataDisplay/List/ListItem';
import ListPlaceHolder from '../../ui/DataDisplay/List/ListPlaceHolder';
import TenantLogo from '../../assets/images/tenant/TenantLogo';
import List from '../../ui/DataDisplay/List/ListWrapper';
import history from '../../history';
import { Customer } from '../../graphql/graphql.schema';
import BadgeStatus from '../../ui/BadgeStatus/BadgeStatus';

interface IProps {
  t: TranslationFunction;
  customers?: any[];
  error?: string;
  loading?: boolean;
}

const PendingActivationCustomersListView = ({
  t,
  customers,
  loading,
  error
}: IProps) => {
  const pendingCustomer = (customers || []).slice(0, 10);

  const orderedCustomers = customers
    ? sortBy(
        pendingCustomer,
        customer => (customer.lastName ? customer.lastName.toLowerCase() : '')
      )
    : [];
  return (
    <Spinner loading={loading} error={error}>
      <List fluid>
        <ListTitle text={t('pendingActivationCustomers')} />

        <ListHeader>
          <Col xs={2} className="d-none d-sm-none d-md-block">
            {`${t('tenant')}`}
          </Col>

          <Col xs={5} className="d-none d-sm-none d-md-block">
            {`${t('name')}`}
          </Col>
          <Col
            xs={5}
            className="d-none d-sm-none d-md-block text-right"
            style={{ minWidth: '9rem' }}
          >
            {`${t('registrationStatus')}`}
          </Col>
        </ListHeader>
        {orderedCustomers.length === 0 && (
          <ListPlaceHolder text={t('pendingCustomerEmptyList')} />
        )}
        {orderedCustomers.map((customer: any) => (
          <ListItem
            key={customer.id}
            options={{
              onClick: () => history.push(`/customers/${customer.id}/identity`)
            }}
          >
            <Col xs={2}>
              <TenantLogo tenant={get(customer, 'tenant')} />
            </Col>
            <Col className="font-weight-bold text-left" xs={5}>
              {`${customer.lastName ? customer.lastName.toUpperCase() : ''} ${
                customer.firstName
              }`}
            </Col>
            <Col xs={5} className="text-right">
              <BadgeStatus status={customer.status} />
            </Col>
          </ListItem>
        ))}
      </List>
    </Spinner>
  );
};

export default translate('Customer')(PendingActivationCustomersListView);
