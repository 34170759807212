import * as React from 'react';
import L, { Marker, Map } from 'leaflet';
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet/dist/leaflet.css';
import './GeoLocationInput.scss';
import { translate } from 'react-i18next';
import { TranslationFunction } from 'i18next';
import { getCustomIcon } from '../../CustomersMap/CustomersMap';

interface IPropsType {
  t: TranslationFunction;
  onPositionUpdate?: (latlng: { latitude: number; longitude: number }) => void;
  onCurrentPositionInitialized?: (
    latlng: { latitude: number; longitude: number }
  ) => void;
  initToCurrentPosition?: boolean;
  latitude?: number;
  longitude?: number;
}

interface IState {
  marker?: Marker;
  map?: Map;
}

class GeolocationInput extends React.Component<IPropsType, IState> {
  constructor(props: IPropsType) {
    super(props);
    this.state = {};
  }

  public componentWillUnmount() {
    if (this.state && this.state.map) {
      this.state.map.remove();
    }
  }

  public componentWillReceiveProps(next: IPropsType) {
    if (
      next.latitude !== this.props.latitude ||
      next.longitude !== this.props.longitude
    ) {
      if (next.latitude && next.longitude) {
        this.updateLatLng([next.latitude, next.longitude]);
      }
    }
  }

  public async componentDidMount() {
    await this.initMap([this.props.latitude || 45, this.props.longitude || 5]);
    if (this.props.initToCurrentPosition) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;

          if (this.props.onCurrentPositionInitialized) {
            this.props.onCurrentPositionInitialized({
              latitude,
              longitude
            });
          }
          this.updateLatLng([latitude, longitude]);
        });
      }
    }
  }

  public render() {
    return (
      <div>
        <div id="mapInput" />
      </div>
    );
  }

  private async initMap(center: [number, number] = [45, 5]) {
    try {
      const map = L.map('mapInput').setView(center, 17);
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(map);
      const markersCluster = L.markerClusterGroup();
      const latLng = new L.LatLng(center[0], center[1]);
      const marker = new L.Marker(latLng, {
        icon: getCustomIcon('#008dbf'),
        draggable: true
      }).on('dragend', event => {
        const position = event.target.getLatLng();

        if (this.props.onPositionUpdate) {
          this.props.onPositionUpdate({
            latitude: position.lat,
            longitude: position.lng
          });
        }
      });
      markersCluster.addLayer(marker);

      map.addLayer(markersCluster);
      this.setState({
        map,
        marker
      });
    } catch (e) {
      console.error(e);
    }
  }
  private updateLatLng([latitude, longitude]: [number, number]) {
    if (this.state.marker) {
      this.state.marker.setLatLng([latitude || 0, longitude || 0]);
      this.state.marker.setLatLng([latitude || 0, longitude || 0]);
      const map = this.state.map;
      if (map) {
        map.setView(this.state.marker.getLatLng(), map.getZoom());
      }
    }
  }
}

export default translate('RegistrationStatus')(GeolocationInput);
