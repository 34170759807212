import * as React from 'react';
import { Query, QueryResult, withApollo, WithApolloClient } from 'react-apollo';

import gql from 'graphql-tag';
import {
  MSBListFilter,
  MSBListItem,
  MSBListPagination,
  MSBListSorting
} from '../../../graphql/graphql.schema';
import MySmartBatteryListView from './MySmartBatteryListView';
import { safeMobxInject } from '../../../stores/storeInjectionHelpers';
import { useState } from 'react';
import { LOG_IN_AS_MUTATION } from '../../login_as/LoginAsLink';
import tools from '../../../utils/tools';
import { ISnackBarParams } from '../../../stores/userFeedback/snackbarStore';
import { TranslationFunction } from 'i18next';
import { translate } from 'react-i18next';

export const GET_MY_SMART_BATTERY_LIST = gql`
  query getMySmartBatteryList($pagination: MSBListPagination) {
    getMySmartBatteryList(pagination: $pagination) {
      customerId
      customerFirstName
      customerLastName
      customerEmail
      customerPdlNumber
      msbVolume
      msbCaeNumber
      msbEnedisConnectNumber
      msbConsuelNumber
      msbSendingARPEDate
      msbSNFilled
      msbACFilled
      msbEnedisOk
      msbProviderRegistrationOk
      msbOk
      msbType
      msbPotentiallyStuck
    }
  }
`;

interface IResponse {
  getMySmartBatteryList: MSBListItem[];
}

interface IProps {
  t: TranslationFunction;
}

interface IStoresProps {
  filterRule: MSBListFilter[];
  sortingRule: MSBListSorting;
  searchRule: string;
  showSnackBar: (params: ISnackBarParams) => void;
}

const pageSize = 10;

const MySmartBatteryListQuery = safeMobxInject<IStoresProps, IProps>(
  ({ snackBar, mySmartBatteryList }) => ({
    filterRule: mySmartBatteryList.filterRule,
    sortingRule: mySmartBatteryList.sortingRule,
    searchRule: mySmartBatteryList.searchRule,
    showSnackBar: snackBar.showSnackBar
  }),
  ({
    filterRule,
    searchRule,
    sortingRule,
    client,
    showSnackBar,
    t
  }: WithApolloClient<IProps & IStoresProps>) => {
    const [mySmartBatteryList, setMySmartBatteryList] = useState<MSBListItem[]>(
      []
    );
    const [loginAsLoading, setLoginAsLoading] = useState<boolean>(false);
    const [offset, setOffset] = useState(pageSize);
    const [canFetchMore, setCanFetchMore] = useState(true);

    const pagination: MSBListPagination = {
      pageSize,
      search: searchRule,
      sorting: sortingRule,
      filters: filterRule
    };

    const loginAsForReadOnly = (customerEmail: string) => {
      setLoginAsLoading(true);
      return client
        .mutate({
          mutation: LOG_IN_AS_MUTATION,
          variables: { id: customerEmail }
        })
        .then(({ data }) => {
          if (data) {
            return tools.openNewTabfForURI(data.loginAsSrs.uri);
          }
        })
        .catch(() => {
          showSnackBar({
            text: t('Errors:unknown'),
            type: 'error'
          });
        })
        .finally(() => setLoginAsLoading(false));
    };

    return (
      <Query
        query={GET_MY_SMART_BATTERY_LIST}
        fetchPolicy={'cache-and-network'}
        variables={{ pagination }}
        onCompleted={(data: IResponse) => {
          const newMySmartBatteryList: MSBListItem[] = data
            ? data.getMySmartBatteryList
            : [];
          setOffset(newMySmartBatteryList.length);
          setCanFetchMore(newMySmartBatteryList.length % pageSize === 0);
          setMySmartBatteryList(newMySmartBatteryList);
        }}
      >
        {({ loading, fetchMore }: QueryResult<IResponse>) => {
          return (
            <MySmartBatteryListView
              loading={loading || loginAsLoading}
              mySmartBatteryList={mySmartBatteryList}
              canFetchMore={canFetchMore}
              loginAsForReadOnly={loginAsForReadOnly}
              fetchMore={() =>
                fetchMore({
                  query: GET_MY_SMART_BATTERY_LIST,
                  variables: {
                    pagination: { ...pagination, offset, pageSize }
                  },
                  updateQuery: (previousResult, { fetchMoreResult }) => {
                    const previousList =
                      previousResult.getMySmartBatteryList || [];
                    const currentList =
                      (fetchMoreResult &&
                        fetchMoreResult.getMySmartBatteryList) ||
                      [];
                    setCanFetchMore(currentList.length === pageSize);

                    const newList = [...previousList, ...currentList];
                    return {
                      getMySmartBatteryList: newList
                    };
                  }
                })
              }
            />
          );
        }}
      </Query>
    );
  }
);

export default translate(['Errors'])(withApollo(MySmartBatteryListQuery));
