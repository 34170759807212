import React from 'react';
import { Col, Row } from 'reactstrap';
import '../devices_inscription/devices/deviceItem.scss';
import './devicePaired.scss';
import { translate, TranslationFunction } from 'react-i18next';
import classnames from 'classnames';

import ListItem from '../../ui/DataDisplay/List/ListItem';
import AlertNotificationIcon from '../../ui/Notifications/AlertNotificationIcon';
import PlcIcon from '../../assets/images/devices/icons/connection/PlcIcon';
import ModBusIcon from '../../assets/images/devices/icons/connection/ModBusIcon';
import TenantLogo from '../../assets/images/tenant/TenantLogo';
import { Device } from '../../stores/inscription/device';

interface IProps {
  device: Device;
  t: TranslationFunction;
}

export const DevicePaired = ({ device, t }: IProps) => {
  const {
    activationCode,
    isDeviceConnected,
    getDeviceFullName,
    getIsModbus,
    isDeleted,
    power
  } = device;

  const roundPower = (p: number) => {
    return p ? p.toFixed(1) : '0';
  };

  const modbusDeviceIsDisconnected =
    getIsModbus && !device.isDeviceConnected && !isDeleted;

  const DisconnectedNotificationIcon = modbusDeviceIsDisconnected && (
    <span className={'notification-icon modbus-disconnected'}>
      <AlertNotificationIcon
        alert={{
          label: t(`Devices:deviceDisconnectedError`).replace(
            '%deviceName%',
            getDeviceFullName
          )
        }}
        icon={'exclamation-circle'}
      />
    </span>
  );

  const DeviceInfo = (
    <div className={'device-container'}>
      <Col
        className={classnames('device-info', {
          modbus: getIsModbus
        })}
      >
        <Col>
          <Row className={'device-title'}>
            {device.isMst || device.isEmulatedFromG3 ? (
              <TenantLogo />
            ) : device.getIsModbus ? (
              <ModBusIcon />
            ) : (
              <PlcIcon />
            )}
            <Col>
              <div className="device-name">{getDeviceFullName}</div>
              <div>
                <span
                  className={classnames(
                    'badge',
                    'badge-pill',
                    isDeviceConnected
                      ? 'badge-success'
                      : modbusDeviceIsDisconnected
                        ? 'badge-warning'
                        : 'badge-secondary'
                  )}
                >
                  {isDeviceConnected
                    ? t('Devices:on')
                    : modbusDeviceIsDisconnected
                      ? t('Common:warning')
                      : t('Devices:offline')}
                </span>
              </div>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row className={'device-details'}>
            <div>
              <span className="mac">{t('Devices:mac')}:</span>
              <span>{device.ethMac || device.plcMac}</span>
            </div>
            {!getIsModbus && (
              <div>
                <span className="activation-code">
                  {t('Devices:activationCode')}:
                </span>
                <span>{activationCode}</span>
              </div>
            )}
          </Row>
        </Col>
      </Col>
      {power && (
        <Col>
          <Row>
            <Col className={'device-power'}>
              {power.length > 1 ? (
                power.map((p, index) => {
                  return (
                    <div className={'ct-container'} key={index}>
                      {!modbusDeviceIsDisconnected && (
                        <span>
                          CT{index + 1}: <b>{roundPower(power[index])} W</b>
                        </span>
                      )}
                    </div>
                  );
                })
              ) : (
                <div className={'ct-container'}>
                  {!modbusDeviceIsDisconnected && (
                    <span>
                      CT1: <b>{roundPower(power[0])} W</b>
                    </span>
                  )}
                </div>
              )}
            </Col>
          </Row>
        </Col>
      )}
      {DisconnectedNotificationIcon}
    </div>
  );
  return (
    <ListItem className={'registration-device-item'} key={device.getKey}>
      {DeviceInfo}
    </ListItem>
  );
};

export default translate(['Common', 'Devices', 'RoomName'])(DevicePaired);
