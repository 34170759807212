import React from 'react';
import { TranslationFunction } from 'i18next';
import { translate } from 'react-i18next';
import {
  MsbBoxTypes,
  MSBListFilter,
  MSBListFilterableColumn,
  MSBListFilterRule,
  MSBListItem,
  MSBListSortableColumn
} from '../../../graphql/graphql.schema';
import { dateFormatter } from '../../../utils/tools';
import MSBSmoothList from '../../../ui/SmoothList/MSBSmoothList';
import { RouteComponentProps, withRouter } from 'react-router';
import IconCompanyMSB from '../../svg/IconCompanyMSB';

interface IProps extends RouteComponentProps {
  t: TranslationFunction;
  mySmartBatteryList: MSBListItem[];
  filters: MSBListFilter[];
  canFetchMore: boolean;
  isReadOnly: boolean;
  fetchMore: () => void;
  loginAsForReadOnly: (customerEmail: string) => void;
}

const MySmartBatteryListDesktop = ({
  t,
  mySmartBatteryList,
  filters,
  history,
  canFetchMore,
  fetchMore,
  isReadOnly,
  loginAsForReadOnly
}: IProps) => {
  const onRowClick = (item: MSBListItem) => {
    if (isReadOnly && item.customerEmail) {
      return loginAsForReadOnly(item.customerEmail);
    }
    return history.push(`/customers/${item.customerId}/installation`);
  };

  const isMyLightEnergyMSBFilterEnabled = filters.some(
    filter =>
      filter.column === MSBListFilterableColumn.msbType &&
      filter.rule === MSBListFilterRule.isNotNull
  );
  const isPlaneteOuiMSBFilterEnabled = filters.some(
    filter =>
      filter.column === MSBListFilterableColumn.msbType &&
      filter.rule === MSBListFilterRule.isNull
  );

  return (
    <div className={'my-smart-battery-list full-page-list desktop'}>
      <MSBSmoothList
        placeholder={t('MySmartBattery:msbEmptyList')}
        rowHeight={63}
        onRowClick={onRowClick}
        canFetchMore={canFetchMore}
        fetchMore={fetchMore}
        columns={[
          {
            isFilterable: false,
            isSortable: true,
            title: t('MySmartBattery:msbIcon'),
            sortingKey: MSBListSortableColumn.msbType,
            columnKey: MSBListSortableColumn.msbType,
            bootstrapOption: { md: 1 },
            render: (item: MSBListItem) => (
              <div className={'icon'}>
                <IconCompanyMSB
                  isMSBPotentiallyStuck={item.msbPotentiallyStuck}
                  isMSBActivated={item.msbOk}
                  msbType={item.msbType}
                />
              </div>
            )
          },
          {
            title: t('Customer:name'),
            sortingKey: MSBListSortableColumn.customerLastName,
            columnKey: MSBListSortableColumn.customerLastName,
            bootstrapOption: {
              md: isReadOnly ? 1 : 2
            },
            render: (item: MSBListItem) => (
              <div
                className={'list-item ellipsis font-weight-bold'}
                style={{ textAlign: 'left' }}
              >
                {`${item.customerLastName} ${item.customerFirstName}`}
              </div>
            )
          },
          {
            isHidden: !isReadOnly,
            title: t('Customer:email'),
            columnKey: MSBListSortableColumn.customerEmail,
            sortingKey: MSBListSortableColumn.customerEmail,
            bootstrapOption: { md: isMyLightEnergyMSBFilterEnabled ? 2 : 1 },
            render: (item: MSBListItem) => (
              <div
                className={'list-item ellipsis font-weight-bold'}
                style={{ textAlign: 'center' }}
              >
                {`${item.customerEmail ? item.customerEmail : '-'}`}
              </div>
            )
          },
          {
            title: t('MySmartBattery:volumeShort'),
            sortingKey: MSBListSortableColumn.msbVolume,
            columnKey: MSBListSortableColumn.msbVolume,
            bootstrapOption: { md: 1 },
            render: (item: MSBListItem) => (
              <div className={'list-item'}>
                {item.msbVolume ? `${item.msbVolume}kWh` : '-'}
              </div>
            )
          },
          {
            isHidden: !isReadOnly,
            title: t('Customer:pdl'),
            columnKey: MSBListSortableColumn.customerPdlNumber,
            sortingKey: MSBListSortableColumn.customerPdlNumber,
            bootstrapOption: { md: 1 },
            render: (item: MSBListItem) => (
              <div
                className={'list-item ellipsis'}
                style={{ textAlign: 'center' }}
              >
                {`${item.customerPdlNumber ? item.customerPdlNumber : '-'}`}
              </div>
            )
          },
          {
            isHidden: isMyLightEnergyMSBFilterEnabled,
            title: t('MySmartBattery:caeNumberShort'),
            sortingKey: MSBListSortableColumn.msbCaeNumber,
            columnKey: MSBListSortableColumn.msbCaeNumber,
            bootstrapOption: { md: isReadOnly ? 1 : 2 },
            render: (item: MSBListItem) => (
              <div className={'list-item ellipsis'}>
                {item.msbCaeNumber || '-'}
              </div>
            )
          },
          {
            isHidden: isPlaneteOuiMSBFilterEnabled,
            title: t('MySmartBattery:enedisConnect'),
            sortingKey: MSBListSortableColumn.msbEnedisConnectNumber,
            columnKey: MSBListSortableColumn.msbEnedisConnectNumber,
            bootstrapOption: { md: isReadOnly ? 1 : 2 },
            render: (item: MSBListItem) => (
              <div className={'list-item ellipsis'}>
                {item.msbEnedisConnectNumber || '-'}
              </div>
            )
          },
          {
            isHidden: isPlaneteOuiMSBFilterEnabled,
            title: t('MySmartBattery:consuelNumber'),
            sortingKey: MSBListSortableColumn.msbConsuelNumber,
            columnKey: MSBListSortableColumn.msbConsuelNumber,
            bootstrapOption: { md: isReadOnly ? 1 : 2 },
            render: (item: MSBListItem) => (
              <div className={'list-item ellipsis'}>
                {item.msbConsuelNumber || '-'}
              </div>
            )
          },
          {
            isHidden: isMyLightEnergyMSBFilterEnabled,
            title: t('MySmartBattery:sendingARPEDate'),
            sortingKey: MSBListSortableColumn.msbSendingARPEDate,
            columnKey: MSBListSortableColumn.msbSendingARPEDate,
            bootstrapOption: { md: 1 },
            render: (item: MSBListItem) => {
              return (
                <div className={'list-item'}>
                  {dateFormatter(item.msbSendingARPEDate, 'DD/MM/YY') || '-'}
                </div>
              );
            }
          },
          {
            title: t(
              `MySmartBattery:msbSNFilled_${
                isMyLightEnergyMSBFilterEnabled
                  ? MsbBoxTypes.MYLIGHT
                  : MsbBoxTypes.PLANETE_OUI
              }`
            ),
            sortingKey: MSBListSortableColumn.msbSNFilled,
            columnKey: MSBListSortableColumn.msbSNFilled,
            bootstrapOption: { md: isMyLightEnergyMSBFilterEnabled ? 2 : 1 },
            render: (item: MSBListItem) => (
              <div className={'list-item'}>
                {dateFormatter(item.msbSNFilled, 'DD/MM/YY') || '-'}
              </div>
            )
          },
          {
            isHidden: isMyLightEnergyMSBFilterEnabled,
            title: t(`MySmartBattery:msbACFilled`),
            sortingKey: MSBListSortableColumn.msbACFilled,
            columnKey: MSBListSortableColumn.msbACFilled,
            bootstrapOption: { md: 1 },
            render: (item: MSBListItem) => {
              return (
                <div className={'list-item'}>
                  {dateFormatter(item.msbACFilled, 'DD/MM/YY') || '-'}
                </div>
              );
            }
          },
          {
            isHidden: isMyLightEnergyMSBFilterEnabled,
            title: t('MySmartBattery:msbEnedisOk'),
            sortingKey: MSBListSortableColumn.msbEnedisOk,
            columnKey: MSBListSortableColumn.msbEnedisOk,
            bootstrapOption: { md: 1 },
            render: item => {
              return (
                <div className={'list-item'}>
                  {dateFormatter(item.msbEnedisOk, 'DD/MM/YY') || '-'}
                </div>
              );
            }
          },
          {
            isHidden: isMyLightEnergyMSBFilterEnabled,
            title: t('MySmartBattery:msbProviderRegistrationOk'),
            sortingKey: MSBListSortableColumn.msbProviderRegistrationOk,
            columnKey: MSBListSortableColumn.msbProviderRegistrationOk,
            bootstrapOption: { md: 1 },
            render: item => {
              return (
                <div className={'list-item'}>
                  {dateFormatter(item.msbProviderRegistrationOk, 'DD/MM/YY') ||
                    '-'}
                </div>
              );
            }
          },
          {
            title: t('MySmartBattery:msbOk'),
            sortingKey: MSBListSortableColumn.msbOk,
            columnKey: MSBListSortableColumn.msbOk,
            bootstrapOption: { md: isMyLightEnergyMSBFilterEnabled ? 2 : 1 },
            render: item => (
              <div className={'list-item'}>
                {dateFormatter(item.msbOk, 'DD/MM/YY') || '-'}
              </div>
            )
          }
        ]}
        dataSource={mySmartBatteryList}
      />
    </div>
  );
};

export default withRouter(
  translate(['MySmartBattery', 'Customer', 'Installation'])(
    MySmartBatteryListDesktop
  )
);
