import React from 'react';
import LogoSVG from './LogoSVG';

const Logo = () => (
  <div
    className="logo-wrapper"
    data-testid={'logo-mylight'}>
      <LogoSVG />
   </div>
);

export default Logo;
