import React, { ReactNode, useState } from 'react';
import classnames from 'classnames';
import './smoothList.scss';
import {
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Popover,
  PopoverBody,
  Row
} from 'reactstrap';
import SortNotificator from './SortNotificator/SortNotificator';
import EmptyCollectionPlaceHolder from '../DataDisplay/List/ListPlaceHolder';
import { safeMobxInject } from '../../stores/storeInjectionHelpers';
import { translate, TranslationFunction } from 'react-i18next';
import { IconFunnel } from '../Icons/IconFunnel';
import {
  CustomerListFilter,
  CustomerListFilterableColumn,
  CustomerListFilterRule,
  CustomerListSortableColumn,
  CustomerListSorting,
  CustomerListSortingRule
} from '../../graphql/graphql.schema';
import { possibleCustomerListFilterOptions } from '../../stores/lists/customersList';

export interface IColumn {
  title: string;
  dataKey?: string;
  render?: (item: any, index?: number) => ReactNode | string;
  columnKey: string;
  align?: string;
  bootstrapOption?: any;
  sortingKey?: CustomerListSortableColumn;
}

interface IProps {
  t: TranslationFunction;
  columns: IColumn[];
  dataSource: any[];
  rowHeight: number;
  placeholder: string;
  onRowClick?: (item: any, index?: number) => void;
  canFetchMore: boolean;
  fetchMore: () => void;
}

interface IStoresProps {
  filterRule: CustomerListFilter[];
  toggleFilterRule: (
    column: CustomerListFilterableColumn,
    rule: CustomerListFilterRule
  ) => void;
  sortingRule: CustomerListSorting;
  setSortingRule: (column: CustomerListSortableColumn) => void;
}

const titleRowSize = 54;

const SmoothList = safeMobxInject<IStoresProps, IProps>(
  ({ Store: { customersList } }) => ({
    filterRule: customersList.filterRule,
    toggleFilterRule: customersList.toggleFilterRule,
    sortingRule: customersList.sortingRule,
    setSortingRule: customersList.setSortingRule
  }),
  ({
    t,
    columns,
    dataSource,
    onRowClick,
    rowHeight,
    placeholder,
    sortingRule,
    setSortingRule,
    filterRule,
    toggleFilterRule,
    canFetchMore,
    fetchMore
  }: IProps & IStoresProps) => {
    const [isOpened, toggleOpened] = useState<
      CustomerListFilterableColumn | undefined
    >(undefined);
    const isFilterApplied = (
      columnName: CustomerListFilterableColumn,
      rule?: CustomerListFilterRule
    ): boolean => {
      const appliedFilter = filterRule.filter(
        ({ column }) => column === columnName
      );
      if (rule) {
        return appliedFilter
          ? appliedFilter.some(filter => filter.rule === rule)
          : false;
      }
      return appliedFilter.length > 0;
    };

    const isColumnWithFilters = (column: string): boolean => {
      return column in CustomerListFilterableColumn;
    };

    const renderListRows = () => {
      if (dataSource.length === 0) {
        return <EmptyCollectionPlaceHolder text={placeholder} />;
      }
      return dataSource.map((item, index) => {
        if (index !== dataSource.length) {
          return (
            <Col
              style={{ height: rowHeight }}
              key={index}
              onClick={() => {
                if (onRowClick) {
                  onRowClick(dataSource[index], index);
                }
              }}
            >
              <Row className="item-row" style={{ marginRight: 0 }}>
                {columns.map(column => (
                  <Col
                    style={{ textAlign: column.align }}
                    className="item-col"
                    {...column.bootstrapOption}
                    key={`${column.columnKey}-${index}`}
                  >
                    {column.dataKey
                      ? dataSource[index][column.dataKey]
                      : column.render
                        ? column.render(dataSource[index], index)
                        : ''}
                  </Col>
                ))}
              </Row>
            </Col>
          );
        }
      });
    };

    const renderFetchMoreButton = () => {
      return dataSource.length > 0 ? (
        <Row
          className="item-row"
          style={{
            height: rowHeight,
            marginRight: '15px',
            justifyContent: 'center',
            backgroundColor: canFetchMore ? 'aliceblue' : 'white',
            borderBottom: 'none',
            cursor: canFetchMore ? 'pointer' : 'unset'
          }}
          onClick={canFetchMore ? fetchMore : undefined}
        >
          <div>
            {canFetchMore ? t('Common:fetchMore') : t('Common:noMoreResults')}
          </div>
        </Row>
      ) : null;
    };

    return (
      <div className={classnames('smooth-list')}>
        <Container fluid style={{ height: '100%', paddingRight: 0 }}>
          <Col className="titles-row">
            {columns.map((column, key) => (
              <Col
                {...column.bootstrapOption}
                key={key}
                onClick={() => {
                  if (column.sortingKey) {
                    setSortingRule(column.sortingKey);
                  }
                }}
              >
                <div
                  className={'titles-row-title-container'}
                  style={
                    column.columnKey === 'registrationDate' ||
                    column.columnKey === 'tenant' ||
                    column.columnKey === 'msbStatus'
                      ? { display: 'flex', justifyContent: 'center' }
                      : {}
                  }
                >
                  {isColumnWithFilters(column.columnKey) && (
                    <i
                      className={classnames('icon-filter', {
                        filled: isFilterApplied(
                          column.columnKey as CustomerListFilterableColumn
                        )
                      })}
                      id={column.columnKey}
                      onClick={e => {
                        if (isColumnWithFilters(column.columnKey)) {
                          e.stopPropagation();
                          const col = column.columnKey as CustomerListFilterableColumn;
                          if (isOpened === col) {
                            return toggleOpened(undefined);
                          }
                          return toggleOpened(col);
                        }
                      }}
                    >
                      <IconFunnel
                        filled={isFilterApplied(
                          column.columnKey as CustomerListFilterableColumn
                        )}
                      />
                    </i>
                  )}
                  <span className={'titles-row-title'}>{column.title}</span>
                  {column.sortingKey && (
                    <div className={'sort-notificator-container'}>
                      <SortNotificator
                        sortingKey={column.sortingKey}
                        sortingRule={{
                          paramName: sortingRule.column || '',
                          reverse:
                            sortingRule.rule === CustomerListSortingRule.DESC
                        }}
                      />
                    </div>
                  )}
                </div>
                {isColumnWithFilters(column.columnKey) && (
                  <Popover
                    placement="bottom"
                    isOpen={isOpened === column.columnKey}
                    target={column.columnKey}
                    className={'filters-popover'}
                    toggle={() => {
                      toggleOpened(undefined);
                    }}
                    trigger={'legacy'}
                  >
                    <PopoverBody>
                      <ListGroup>
                        {possibleCustomerListFilterOptions.map(filter => {
                          const col = column.columnKey as CustomerListFilterableColumn;
                          return (
                            <ListGroupItem
                              key={filter}
                              active={isFilterApplied(col, filter)}
                              onClick={e => {
                                e.stopPropagation();
                                toggleFilterRule(col, filter);
                                toggleOpened(undefined);
                              }}
                              tag="button"
                              action
                            >
                              <div className={'filter-option'}>
                                <span>
                                  {t(`Common:listFilter_${col}_${filter}`)}
                                </span>
                                {isFilterApplied(col, filter) && (
                                  <i className="icon-ok" />
                                )}
                              </div>
                            </ListGroupItem>
                          );
                        })}
                      </ListGroup>
                    </PopoverBody>
                  </Popover>
                )}
              </Col>
            ))}
          </Col>
          <Col
            style={{
              padding: 0,
              height: `calc(100% - ${titleRowSize}px`,
              overflow: 'auto'
            }}
          >
            {renderListRows()}
            {renderFetchMoreButton()}
          </Col>
        </Container>
      </div>
    );
  }
);

export default translate(['Customer', 'statusFilters'])(SmoothList);
