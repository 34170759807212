import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { FieldList, Title } from '../../ui/DataDisplay/FieldList/FieldList';
import { translate, TranslationFunction } from 'react-i18next';
import tools from '../../utils/tools';

interface IProps {
  t: TranslationFunction;
}
const TechnicalDocumentation = ({ t }: IProps) => {
  const openNewTabWithDocumentationPage = () => {
    const documentationPageLink =
      'https://faq.mylight150.com/fr/#gestiondelenergie';
    tools.openNewTabfForURI(documentationPageLink);
  };

  return (
    <Col className="technical-support mobile-helper" xs={12}>
      <FieldList>
        <Title text={t('documentationTitle')} />
        <Row>
          <Col sm={12} lg={6} xl={3}>
            <Button block onClick={openNewTabWithDocumentationPage}>
              {t('technicalDocumentation')}
            </Button>
          </Col>
        </Row>
      </FieldList>
    </Col>
  );
};

export default translate('Support')(TechnicalDocumentation);
