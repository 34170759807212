import { observable, action, computed } from 'mobx';

import { emailValidator } from '../../../utils/validators';

export default class ForgotPasswordForm {
  @observable public emailField: string = '';

  @action.bound
  public updateEmail(input: string) {
    this.emailField = input;
  }

  @computed
  get emailError() {
    return emailValidator(this.emailField);
  }

  @computed
  get forgotPasswordFormIsInvalid() {
    return Boolean(this.emailError);
  }
}
