import { omit } from 'lodash';

// tslint:disable-next-line:only-arrow-functions
const omitDeepLodash = function(input: any, props: any) {
  function omitDeepOnOwnProps(obj: any): any {
    if (typeof input === 'undefined') {
      return input;
    }

    if (!Array.isArray(obj) && !isObject(obj)) {
      return obj;
    }

    if (Array.isArray(obj)) {
      return omitDeepLodash(obj, props);
    }

    const o: any = {};
    for (const [key, value] of Object.entries(obj)) {
      o[key] = !isNil(value) ? omitDeepLodash(value, props) : value;
    }

    return omit(o, props);
  }

  if (arguments.length > 2) {
    // tslint:disable-next-line:no-parameter-reassignment
    props = Array.prototype.slice.call(arguments).slice(1);
  }

  if (Array.isArray(input)) {
    return input.map(omitDeepOnOwnProps);
  }

  return omitDeepOnOwnProps(input);
};

function isNil(value: any) {
  return value === null || value === undefined;
}

function isObject(obj: any) {
  return Object.prototype.toString.call(obj) === '[object Object]';
}

export default omitDeepLodash;
