import React from 'react';
import { translate, TranslationFunction } from 'react-i18next';
import { Button, Col, Row } from 'reactstrap';
import imgPositionPart from '../../../../../assets/images/modbus-part/modbus-part-serial.jpg';
import {
  IModbusPartSerialNumberRegexMatch,
  MODBUS_PART_SERIAL_NUMBER_REGEX
} from '../../../../../constants/ModbusPartSerialNumberRegex';
import CustomModal from '../../../../../ui/CustomModal/CustomModal';
import { FormInput } from '../../../../../ui/FormInput/FormInput';

interface IProps {
  t: TranslationFunction;
  onChange: (value: string) => void;
}

const WModbusPartSerialInput = ({ t, onChange }: IProps) => {
  const [deviceSerial, setDeviceSerial] = React.useState<string>('');
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

  const handleDeviceSerialChange = (value: string) => {
    const newValue = value.toUpperCase();
    setDeviceSerial(newValue);
    onChange(newValue);
  };

  const isDeviceSerialValid = (): boolean => {
    const match = deviceSerial.match(MODBUS_PART_SERIAL_NUMBER_REGEX);
    if (!match || !match.groups) return false;

    const serial = (match.groups as any) as IModbusPartSerialNumberRegexMatch;

    // validate year number, must be between 2000 and current year
    const yearNumber = parseInt(serial.year, 10);
    const currentYearNumber = new Date().getFullYear() % 100;
    const isYearValid = yearNumber >= 20 && yearNumber <= currentYearNumber;

    // validate month number, must be between 1 and 12
    const monthNumber = parseInt(serial.month, 10);
    const isMonthValid = monthNumber >= 1 && monthNumber <= 12;

    return isYearValid && isMonthValid;
  };

  return (
    <Row>
      <FormInput
        id={'wmodbus-part'}
        name={t('Devices:addDeviceWModbusPartSerial')}
        value={deviceSerial}
        onChange={handleDeviceSerialChange}
        addon={
          <Button
            className={'hint-button'}
            size="sm"
            onClick={() => setIsModalOpen(true)}
          >
            {t('WModbusPart:whereIsSerial')}
          </Button>
        }
        error={
          deviceSerial &&
          !isDeviceSerialValid() &&
          t('Errors:wModbusPartSerialError')
        }
        placeholder={'M433485XXXXXXXX'}
      />
      <CustomModal
        isOpened={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        title={t('WModbusPart:whereIsSerial')}
      >
        <Col className={'upm-image-modal'}>
          <Row> {t('WModbusPart:positionSerial')}</Row>
          <Row className="pt-4">
            <img src={imgPositionPart} alt="" />
          </Row>
        </Col>
      </CustomModal>
    </Row>
  );
};

export default translate()(WModbusPartSerialInput);
