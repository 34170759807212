import { default as React, ReactNode } from 'react';
import './responsiveSubMenu.scss';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import {
  BREAK_POINT_SAFE_MIN_WIDTH,
  BREAK_POINT_WIDTH
} from '../../constants/BREAK_POINT_WIDTH';
import MediaQuery from 'react-responsive';
import { IMenuItem } from '../../components/customers/navigation/CustomerMenu';

interface IState {
  active: boolean;
}

interface IProps {
  menuItems: IMenuItem[];
  children?: ReactNode;
  baseRoute: string;
  previousRoute?: string;
  currentItem?: IMenuItem;
  fixedHeaderContent?: ReactNode;
}

const Desktop = ({
  menuItems,
  children,
  baseRoute,
  previousRoute,
  currentItem,
  fixedHeaderContent
}: IProps) => {
  return (
    <MediaQuery query={`(min-width: ${BREAK_POINT_SAFE_MIN_WIDTH})`}>
      <div className="menu-content">
        <div className="menu-header">
          <div className="header-content-fix">
            {fixedHeaderContent ? fixedHeaderContent : ''}
          </div>
        </div>
        <div className="content-wrapper">{children}</div>
      </div>
      <div className="menu-navigation">
        {menuItems.filter(item => !item.hidden).map(item => (
          <Link
            key={item.key}
            className={classnames('menu-item', {
              active: currentItem && currentItem.key === item.key
            })}
            to={`${baseRoute}/${item.path}`}
          >
            {item.name} <i className="right-arrow" />
          </Link>
        ))}
      </div>
    </MediaQuery>
  );
};

class ResponsiveSubMenu extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { active: true };
  }

  public render() {
    const {
      children,
      menuItems,
      currentItem,
      baseRoute,
      fixedHeaderContent
    } = this.props;
    const firstItem = menuItems[0];
    const currentIsFirst = currentItem && firstItem.key === currentItem.key;
    return (
      <div className="menu">
        <Desktop
          {...{
            fixedHeaderContent,
            currentIsFirst,
            children,
            menuItems,
            currentItem,
            baseRoute
          }}
        />

        <MediaQuery query={`(max-width: ${BREAK_POINT_WIDTH})`}>
          <div className="menu-content">
            <div className="menu-header">
              <div className="header-content-fix">
                {fixedHeaderContent ? fixedHeaderContent : ''}
              </div>
            </div>
            <div
              className={classnames('content-wrapper', {
                active: !currentIsFirst
              })}
            />
          </div>
          <div
            className={classnames('menu-navigation', {
              subMenu: !currentIsFirst
            })}
          >
            {menuItems.filter(item => !item.hidden).map(item => (
              <div key={item.key}>
                <Link
                  onClick={() => {
                    this.setState({
                      active:
                        (!this.state.active &&
                          !!currentItem &&
                          currentItem.key === item.key) ||
                        (!!currentItem && currentItem.key !== item.key)
                    });
                  }}
                  className={classnames('menu-item', {
                    active: currentItem && currentItem.key === item.key
                  })}
                  to={`${baseRoute}/${item.path}`}
                >
                  {item.name}{' '}
                  <i
                    className={
                      this.state.active &&
                      currentItem &&
                      currentItem.key === item.key
                        ? 'down-arrow'
                        : 'right-arrow'
                    }
                  />
                </Link>
                {currentItem && currentItem.key === item.key ? (
                  <div
                    className={
                      this.state.active
                        ? 'sub-menu-content'
                        : 'sub-menu-content hidden'
                    }
                  >
                    {children}
                  </div>
                ) : (
                  ''
                )}
              </div>
            ))}
          </div>
        </MediaQuery>
      </div>
    );
  }
}

export default ResponsiveSubMenu;
