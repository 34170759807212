import { action, computed, observable } from 'mobx';
import { WithApolloClient } from 'react-apollo';
import { t } from 'i18next';
import gql from 'graphql-tag';
import { MSB } from '../../graphql/graphql.schema';
import { MsbActivationInputState } from '../../components/customers/installation/battery/activation/MsbInputText';
import snackbarStore from '../userFeedback/snackbarStore';
import mySmartBattery from './mySmartBattery';
import activationMySmartBatteryMylight from './activationMySmartBatteryMylight';

const UPDATE_ENEDIS_CONNECT_NUMBER_BY_CUSTOMER_ID = gql`
  mutation updateEnedisConnectNumberByCustomerId(
    $customerId: String!
    $enedisConnectNumber: String!
  ) {
    updateEnedisConnectNumberByCustomerId(
      customerId: $customerId
      enedisConnectNumber: $enedisConnectNumber
    ) {
      enedisConnectNumber
      enedisConnectNumberModifiedAt
    }
  }
`;

const UPDATE_CONSUEL_NUMBER_BY_CUSTOMER_ID = gql`
  mutation updateConsuelNumberByCustomerId(
    $customerId: String!
    $consuelNumber: String!
  ) {
    updateConsuelNumberByCustomerId(
      customerId: $customerId
      consuelNumber: $consuelNumber
    ) {
      consuelNumber
      consuelNumberModifiedAt
    }
  }
`;

const UPDATE_CONSUEL_DECLARED_POWER_BY_CUSTOMER_ID = gql`
  mutation updateConsuelDeclaredPowerByCustomerId(
    $customerId: String!
    $consuelDeclaredPower: Float!
  ) {
    updateConsuelDeclaredPowerByCustomerId(
      customerId: $customerId
      consuelDeclaredPower: $consuelDeclaredPower
    ) {
      consuelDeclaredPower
      consuelDeclaredPowerModifiedAt
    }
  }
`;

const REQUEST_ENEDIS_GRID_INJECTION = gql`
  mutation requestEnedisGridInjection($customerId: String!) {
    requestEnedisGridInjection(customerId: $customerId) {
      statusModifiedAt
    }
  }
`;

const UPDATE_CONNECTION_POWER_BY_CUSTOMER_ID = gql`
  mutation updateConnectionPowerByCustomerId(
    $customerId: String!
    $connectionPower: Float!
  ) {
    updateConnectionPowerByCustomerId(
      customerId: $customerId
      connectionPower: $connectionPower
    ) {
      connectionPower
      connectionPowerModifiedAt
    }
  }
`;

export class ActivationMySmartBatteryEnedisGridInjection {
  @observable public enedisConnectNumber: string = '';
  @observable public enedisConnectNumberSaved: string = '';
  @observable public enedisConnectNumberModifiedAt: Date | null = null;
  @observable
  public enedisConnectNumberInputState?: MsbActivationInputState = undefined;
  @observable public consuelNumber: string = '';
  @observable public consuelNumberSaved: string = '';
  @observable public consuelNumberModifiedAt: Date | null = null;
  @observable
  public consuelNumberInputState?: MsbActivationInputState = undefined;
  @observable public consuelDeclaredPower?: number = undefined;
  @observable public consuelDeclaredPowerSaved?: number = undefined;
  @observable public consuelDeclaredPowerModifiedAt: Date | null = null;
  @observable
  public consuelDeclaredPowerInputState?: MsbActivationInputState = undefined;
  @observable public sendingCONSUELDate: Date | null = null;
  @observable public isLoadingrequestEnedisGridInjection: boolean = false;
  @observable public isErrorrequestEnedisGridInjection: boolean = false;
  @observable public connectionPower?: number = undefined;
  @observable public connectionPowerSaved?: number = undefined;
  @observable public connectionPowerModifiedAt: Date | null = null;
  @observable
  public connectionPowerInputState?: MsbActivationInputState = undefined;

  @computed
  public get isEnedisGridInjectionFormValid(): boolean {
    return (
      this.enedisConnectNumberSaved !== '' &&
      this.enedisConnectNumberInputState !== 'error' &&
      this.consuelNumberSaved !== '' &&
      this.consuelNumberInputState !== 'error' &&
      this.consuelDeclaredPowerSaved !== undefined &&
      this.consuelDeclaredPowerInputState !== 'error' &&
      this.connectionPowerSaved !== undefined &&
      this.connectionPowerInputState !== 'error' &&
      this.sendingCONSUELDate !== null
    );
  }

  @action.bound
  public async updateEnedisConnectNumber(
    client: WithApolloClient<any>,
    customerId: string,
    enedisConnectNumber: string
  ) {
    try {
      const { data } = await client.mutate({
        mutation: UPDATE_ENEDIS_CONNECT_NUMBER_BY_CUSTOMER_ID,
        variables: {
          customerId,
          enedisConnectNumber
        }
      });
      this.enedisConnectNumber =
        data.updateEnedisConnectNumberByCustomerId.enedisConnectNumber;
      this.enedisConnectNumberSaved =
        data.updateEnedisConnectNumberByCustomerId.enedisConnectNumber;
      this.enedisConnectNumberModifiedAt =
        data.updateEnedisConnectNumberByCustomerId.enedisConnectNumberModifiedAt;
      this.enedisConnectNumberInputState = 'success';
      return Promise.resolve(data.updateEnedisConnectNumberByCustomerId);
    } catch (error) {
      this.enedisConnectNumberInputState = 'error';
      return Promise.reject(
        t(`MySmartBattery:errors.${error.graphQLErrors[0].message.errorCode}`)
      );
    }
  }

  @action.bound
  public async updateConsuelNumber(
    client: WithApolloClient<any>,
    customerId: string,
    consuelNumber: string
  ) {
    try {
      const { data } = await client.mutate({
        mutation: UPDATE_CONSUEL_NUMBER_BY_CUSTOMER_ID,
        variables: {
          customerId,
          consuelNumber
        }
      });
      this.consuelNumber = data.updateConsuelNumberByCustomerId.consuelNumber;
      this.consuelNumberSaved =
        data.updateConsuelNumberByCustomerId.consuelNumber;
      this.consuelNumberModifiedAt =
        data.updateConsuelNumberByCustomerId.consuelNumberModifiedAt;
      this.consuelNumberInputState = 'success';
      return Promise.resolve(data.updateConsuelNumberByCustomerId);
    } catch (error) {
      this.consuelNumberInputState = 'error';
      return Promise.reject(
        t(`MySmartBattery:errors.${error.graphQLErrors[0].message.errorCode}`)
      );
    }
  }

  @action.bound
  public async updateConsuelDeclaredPower(
    client: WithApolloClient<any>,
    customerId: string,
    consuelDeclaredPower: number
  ) {
    try {
      const { data } = await client.mutate({
        mutation: UPDATE_CONSUEL_DECLARED_POWER_BY_CUSTOMER_ID,
        variables: {
          customerId,
          consuelDeclaredPower
        }
      });
      this.consuelDeclaredPower =
        data.updateConsuelDeclaredPowerByCustomerId.consuelDeclaredPower;
      this.consuelDeclaredPowerSaved =
        data.updateConsuelDeclaredPowerByCustomerId.consuelDeclaredPower;
      this.consuelDeclaredPowerModifiedAt =
        data.updateConsuelDeclaredPowerByCustomerId.consuelDeclaredPowerModifiedAt;
      this.consuelDeclaredPowerInputState = 'success';
      return Promise.resolve(data.updateConsuelDeclaredPowerByCustomerId);
    } catch (error) {
      this.consuelDeclaredPowerInputState = 'error';
      return Promise.reject(
        t(`MySmartBattery:errors.${error.graphQLErrors[0].message.errorCode}`)
      );
    }
  }

  @action.bound
  public async updateConnectionPower(
    client: WithApolloClient<any>,
    customerId: string,
    connectionPower: number
  ) {
    try {
      const { data } = await client.mutate({
        mutation: UPDATE_CONNECTION_POWER_BY_CUSTOMER_ID,
        variables: {
          customerId,
          connectionPower
        }
      });
      this.connectionPower =
        data.updateConnectionPowerByCustomerId.connectionPower;
      this.connectionPowerSaved =
        data.updateConnectionPowerByCustomerId.connectionPower;
      this.connectionPowerModifiedAt =
        data.updateConnectionPowerByCustomerId.connectionPowerModifiedAt;
      this.connectionPowerInputState = 'success';
      return Promise.resolve(data.updateConnectionPowerByCustomerId);
    } catch (error) {
      this.connectionPowerInputState = 'error';
      return Promise.reject(
        t(`MySmartBattery:errors.${error.graphQLErrors[0].message.errorCode}`)
      );
    }
  }

  @action.bound
  public setActivationMsbEnedisGridInjection(msb: MSB): void {
    this.clearActivationMsbEnedisGridInjection();
    if (msb.enedisConnectNumber) {
      this.enedisConnectNumber = msb.enedisConnectNumber || '';
      this.enedisConnectNumberSaved = msb.enedisConnectNumber || '';
      this.enedisConnectNumberModifiedAt =
        msb.enedisConnectNumberModifiedAt || null;
    }
    if (msb.consuelNumber) {
      this.consuelNumber = msb.consuelNumber || '';
      this.consuelNumberSaved = msb.consuelNumber || '';
      this.consuelNumberModifiedAt = msb.consuelNumberModifiedAt || null;
    }

    if (msb.consuelDeclaredPower) {
      this.consuelDeclaredPower = msb.consuelDeclaredPower || undefined;
      this.consuelDeclaredPowerSaved = msb.consuelDeclaredPower || undefined;
      this.consuelDeclaredPowerModifiedAt =
        msb.consuelDeclaredPowerModifiedAt || null;
    }

    if (msb.connectionPower) {
      this.connectionPower = msb.connectionPower || undefined;
      this.connectionPowerSaved = msb.connectionPower || undefined;
      this.connectionPowerModifiedAt =
        msb.connectionPowerModifiedAt || null;
    }
    
    this.sendingCONSUELDate = msb.sendingCONSUELDate || null;
  }

  @action.bound
  public clearActivationMsbEnedisGridInjection(): void {
    this.enedisConnectNumber = '';
    this.enedisConnectNumberSaved = '';
    this.enedisConnectNumberModifiedAt = null;
    this.enedisConnectNumberInputState = undefined;
    this.consuelNumber = '';
    this.consuelNumberSaved = '';
    this.consuelNumberModifiedAt = null;
    this.consuelNumberInputState = undefined;
    this.consuelDeclaredPower = undefined;
    this.consuelDeclaredPowerSaved = undefined;
    this.consuelDeclaredPowerModifiedAt = null;
    this.consuelDeclaredPowerInputState = undefined;
    this.connectionPower = undefined;
    this.connectionPowerSaved = undefined;
    this.connectionPowerModifiedAt = null;
    this.connectionPowerInputState = undefined;
    this.sendingCONSUELDate = null;
  }

  @action.bound
  public async requestEnedisGridInjection(
    client: WithApolloClient<any>,
    customerId: string
  ): Promise<void> {
    try {
      this.isLoadingrequestEnedisGridInjection = true;
      this.isErrorrequestEnedisGridInjection = false;
      const { errors } = await client.mutate({
        mutation: REQUEST_ENEDIS_GRID_INJECTION,
        variables: { customerId }
      });
      this.isLoadingrequestEnedisGridInjection = false;
      if (errors) {
        this.isErrorrequestEnedisGridInjection = true;
        return;
      }
      const msb = await mySmartBattery.loadMsb(client, customerId);
      if (msb) {
        mySmartBattery.setActivationMsb(msb);
        activationMySmartBatteryMylight.setActivationMsbMylight(msb);
        this.setActivationMsbEnedisGridInjection(msb);
      }
    } catch (error) {
      this.isLoadingrequestEnedisGridInjection = false;
      this.isErrorrequestEnedisGridInjection = true;
      snackbarStore.showErrorSnackBar({
        text: t('MySmartBattery:errors.requestEnedisGridInjectionFailed')
      });
    }
  }
}

const activationMySmartBatteryEnedisGridInjection: ActivationMySmartBatteryEnedisGridInjection = new ActivationMySmartBatteryEnedisGridInjection();
export default activationMySmartBatteryEnedisGridInjection;
