import { action, observable } from 'mobx';

export class CustomersMode {
  @observable public currentMode: string = 'list';

  @action.bound
  public setMode(mode: string) {
    this.currentMode = mode;
  }
}

const customersMode = new CustomersMode();
export default customersMode;
