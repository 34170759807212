import React, { ReactNode } from 'react';
import './list.scss';
import { Container } from 'reactstrap';

export const List = ({
  children,
  fluid,
  style
}: {
  children: ReactNode;
  fluid?: boolean;
  style?: React.CSSProperties;
}) => (
  <Container fluid={fluid} style={style} className="list-wrapper">
    {children}
  </Container>
);
export default List;
