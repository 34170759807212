import {
  BREAK_POINT_SAFE_MIN_WIDTH,
  BREAK_POINT_WIDTH
} from '../../constants/BREAK_POINT_WIDTH';
import React, { ReactNode } from 'react';
import MainNavigation from '../navigation/MainNavigation';
import Stepper from '../../ui/Stepper/Stepper';
import ResponsiveSubMenuStepper from '../../ui/SubMenuStepper/ResponsiveSubMenuStepper';
import { RouteComponentProps, withRouter } from 'react-router';
import RegistrationStepper from '../../ui/RegistrationStepper/RegistrationStepper';
import MediaQuery from 'react-responsive';
import { inject, observer } from 'mobx-react';
import { IAllStores } from '../../stores/allStores.model';

export interface IInscriptionNavigationRouterParamProps {
  pvInstallationId: string;
  current: string;
  customerId: string;
}

interface IProps
  extends RouteComponentProps<IInscriptionNavigationRouterParamProps> {
  children: ReactNode;
}

const InscriptionNavigation = inject((allStores: IAllStores) => {
  return {
    mstNotConnected: allStores.inscriptionError.unableToJoinMST
  };
})(
  observer(
    ({
      children,
      match: {
        params: { pvInstallationId, current, customerId }
      }
    }: IProps) => (
      <React.Fragment>
        <MediaQuery query={`(max-width: ${BREAK_POINT_WIDTH})`}>
          <MainNavigation>
            <ResponsiveSubMenuStepper
              current={current}
              endPath={pvInstallationId}
              baseRoute={`/devices_inscription/${customerId}`}
              previousRoute="/customers/list"
            >
              {children}
            </ResponsiveSubMenuStepper>
          </MainNavigation>
        </MediaQuery>
        <MediaQuery query={`(min-width: ${BREAK_POINT_SAFE_MIN_WIDTH})`}>
          <RegistrationStepper current={current}>
            {children}
          </RegistrationStepper>
        </MediaQuery>
      </React.Fragment>
    )
  )
);

export default withRouter(InscriptionNavigation);
