import React from 'react';
import { IDeviceIcon } from '../DeviceIcons';

const Enphase = (props: IDeviceIcon) => (
  <svg
    width="3rem"
    height="3rem"
    viewBox="0 0 20000 20000"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    color="black"
    {...props}
  >
    <g id="prefix__layer">
      <g id="prefix___1405073651760">
        <path
          style={{ fill: `${props.color}` }}
          d="M12995 15876H4961c-74 0-139-28-185-83-46-56-65-130-56-195L6629 4802c19-111 120-195 241-195h8025c74 0 139 28 185 83 46 56 65 130 56 195l-1900 10796c-28 111-130 195-241 195zm-7747-482h7534l1816-10314H7064L5248 15394z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M6611 15876h-46c-130-19-222-148-195-278L8279 4802c19-130 148-222 278-195 130 19 222 148 195 278L6852 15681c-19 111-120 195-241 195zM8195 15876h-46c-130-19-222-148-195-278L9863 4802c19-130 148-222 278-195 130 19 222 148 195 278L8436 15681c-19 111-120 195-241 195zM9798 15876h-46c-130-19-222-148-195-278l1900-10796c19-130 148-222 278-195 130 19 222 148 195 278l-1890 10796c-19 111-120 195-241 195zM11383 15876h-46c-130-19-222-148-195-278l1900-10796c19-130 148-222 278-195 130 19 222 148 195 278l-1900 10796c-9 111-111 195-232 195z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M14571 6906H6546c-130 0-241-111-241-241s111-241 241-241h8025c130 0 241 111 241 241 0 139-111 241-241 241zM14256 8685H6231c-130 0-241-111-241-241s111-241 241-241h8025c130 0 241 111 241 241s-102 241-241 241zM13941 10483H5916c-130 0-241-111-241-241s111-241 241-241h8025c130 0 241 111 241 241s-102 241-241 241zM13626 12281H5601c-130 0-241-111-241-241s111-241 241-241h8025c130 0 241 111 241 241s-111 241-241 241zM13310 14079H5285c-130 0-241-111-241-241s111-241 241-241h8025c130 0 241 111 241 241s-111 241-241 241z"
        />
      </g>
    </g>
  </svg>
);

export default Enphase;
