import './FormInput.scss';
import {
  FormFeedback,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row
} from 'reactstrap';
import { InputType } from 'reactstrap/lib/Input';
import React, { ReactNode } from 'react';
import classnames from 'classnames';
import { InputNumber } from '../InputNumber/InputNumber';

interface IProps<T> {
  value?: T;
  onChange?: (value: T) => void;
  type?: InputType;
  clearable?: boolean;
  required?: boolean;
  backgroundColor?: string;
  disabled?: boolean;
  helperText?: ReactNode | string;
  placeholder?: string;
  addon?: ReactNode;
  addonLeft?: ReactNode;
  addonRight?: ReactNode;
  ref?: (a: any) => any;
  error?: string;
  id: string;
  name: string;
  unit?: string;
  step?: number;
  round?: number;
  min?: number;
  max?: number;
  autoComplete?: boolean;
}

function hasError<T>({ error, value, disabled }: IProps<T>) {
  return (error && typeof value !== 'undefined' && !disabled) || undefined;
}

export const FormInput = <T extends number | string>(formProps: IProps<T>) => {
  const {
    onChange,
    value,
    error,
    name,
    id,
    type,
    helperText,
    placeholder,
    addon,
    addonRight,
    addonLeft,
    disabled,
    backgroundColor,
    clearable,
    required,
    unit,
    step,
    round,
    min,
    max,
    ref,
    autoComplete = true
  } = formProps;
  return (
    <div
      className={classnames(
        'input-field mb-3',
        {
          'with-error': hasError(formProps)
        },
        { 'with-unit': unit }
      )}
      data-cy={`${id}_field`}
    >
      <Label className="input-title" htmlFor={id}>
        {name} {required && ' *'}{' '}
        {addon && (
          <span style={{ float: 'right', marginRight: 10 }}>{addon}</span>
        )}
      </Label>
      <div className={classnames({ 'input-group': unit })}>
        <InputGroup>
          {addonLeft && (
            <InputGroupAddon addonType={'prepend'}>{addonLeft}</InputGroupAddon>
          )}
          {type && type === 'number' ? (
            <InputNumber
              value={value as number}
              onChange={onChange as (c: number) => void}
              min={min}
              max={max}
              step={step}
              round={round}
              unit={unit}
              disabled={disabled}
              autoComplete={autoComplete}
              placeholder={placeholder}
              className={classnames(
                'input-number',
                {
                  'with-error': hasError(formProps)
                },
                { 'with-unit': unit }
              )}
            />
          ) : (
            <Input
              id={id}
              type={type ? type : 'text'}
              name={name}
              value={value}
              ref={ref}
              className={classnames({
                clearable,
                'without-error-icon': !!addonRight
              })}
              style={{ backgroundColor }}
              onChange={event => {
                event.preventDefault();
                if (onChange) {
                  onChange(event.target.value as T);
                }
              }}
              disabled={disabled}
              invalid={hasError(formProps)}
              autoComplete={autoComplete ? 'on' : 'off'}
              placeholder={placeholder}
            />
          )}
          {addonRight && (
            <InputGroupAddon addonType={'append'}>{addonRight}</InputGroupAddon>
          )}
        </InputGroup>
      </div>

      {helperText &&
        !hasError(formProps) && (
          <div className="helper-text">
            <span>{helperText}</span>
          </div>
        )}

      {hasError(formProps) && (
        <div className="custom-error">
          <span>{error}</span>
        </div>
      )}
    </div>
  );
};
