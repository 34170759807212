import {
  Customer,
  ElectricInstallation
} from '../../../graphql/graphql.schema';
import { ConverterType, GridType, IInterval } from '@mylight/data-model';

export interface IExtractedInstallation {
  id?: string;
  panelCount: number;
  gridType: GridType;
  rackingPower: number;
  converterType: ConverterType;
  totalPower: number;
  panelProvider?: string;
  saleOfSurplus?: number;
  currency?: string;
  wattCreteByPanel: number;
  canWrite: boolean;
  powerContractType: {
    name?: string;
    powerProvider?: string;
    timeSlots?: IInterval[];
  };
  internetServiceProvider?: string;
  masterTypeInternetConnection?: string;
  internetBoxModel?: string;
  pdlNumber?: string;
  simSerialNumber?: string;
  simRouterReference?: string;
  electricInstallation: ElectricInstallation;
}

export const extractInstallation = (
  customer: Customer
): IExtractedInstallation => {
  const pvInstallation =
    customer.pvInstallations && customer.pvInstallations.length > 0
      ? customer.pvInstallations[0]
      : undefined;
  const electricInstallation =
    customer.pvInstallations &&
    customer.pvInstallations.length > 0 &&
    customer.pvInstallations[0] &&
    customer.pvInstallations[0].electricInstallations &&
    customer.pvInstallations[0].electricInstallations!.length > 0
      ? customer.pvInstallations[0].electricInstallations![0]
      : undefined;
  const panelCount =
    pvInstallation && pvInstallation.panelCount ? pvInstallation.panelCount : 0;
  const panelWattPeak = pvInstallation ? pvInstallation.panelWattPeak! : 0;
  return {
    panelCount,
    id: pvInstallation!.id,
    gridType: (electricInstallation!.gridType as GridType) || GridType.UNKNOWN,
    rackingPower: electricInstallation!.rackingPower || 0,
    converterType:
      (pvInstallation!.converterType as ConverterType) || ConverterType.OTHER,
    totalPower: panelCount * panelWattPeak,
    panelProvider: pvInstallation!.panelManufacturer,
    saleOfSurplus: electricInstallation!.sellingEnergyPrice,
    currency: pvInstallation!.currency,
    wattCreteByPanel: panelWattPeak,
    canWrite: pvInstallation!.canWrite || false,
    powerContractType: {
      name: electricInstallation!.powerContractType,
      // FIXME Timeslots is a number?
      powerProvider: electricInstallation!.powerProvider
    },
    internetServiceProvider: electricInstallation!.internetServiceProvider,
    masterTypeInternetConnection: electricInstallation!
      .masterTypeInternetConnection,
    internetBoxModel: electricInstallation!.internetBoxModel,
    pdlNumber: electricInstallation!.pdlNumber,
    simSerialNumber: electricInstallation!.simSerialNumber,
    simRouterReference: electricInstallation!.simRouterReference,
    electricInstallation: electricInstallation!
  };
};
