import { action, computed, observable } from 'mobx';
import { IStepperMenuItem } from '../../components/devices_inscription/DevicesInscription';
import { t } from 'i18next';
import inscription from './inscriptionStore';
import { StepStatus } from '../../ui/Stepper/Stepper';
import {
  MSBStatus,
  PPDeviceConfigFile,
  RegistrationSteps,
  SrsCustomer
} from '../../graphql/graphql.schema';
import editElectricalInstallationStore from '../installations/editElectricalInstallationStore';
import editPVInstallationStore from '../installations/editPVInstallationStore';
import battery from '../battery';
import { WithApolloClient } from 'react-apollo';
import { SAVE_INSCRIPTION_STEP_QUERY } from '../../components/devices_inscription/partnership/PartnershipsListView';
import snackbarStore from '../userFeedback/snackbarStore';

export type UCG = string | null;

interface IParams {
  customerAndInstallationInfo: SrsCustomer;
  msbStatus?: MSBStatus[];
  getRegistrationSteps?: RegistrationSteps;
}

export const InscriptionStep = {
  INSTALLATION: 'pv_installation',
  MSB: 'battery',
  PARTNERS: 'partnership',
  UCG: 'ucg',
  MASTER: 'master',
  DEVICES: 'devices',
  FUNCTIONS: 'functions',
  SUMMARY: 'finish'
};

export const disabledMenuItemsForNoMST = ['devices', 'functions', 'finish'];

export class InscriptionStepsStore {
  @observable public partnershipStep: StepStatus = 'todo';
  @observable public installationStepDone: string = 'todo';
  @observable public finishStep: string = 'todo';
  @observable public batteryStep: StepStatus = 'todo';
  @observable public registeredBatteryStep: StepStatus = 'todo';

  @computed
  get getInstallStepDone(): boolean {
    return this.installationStepDone === 'done';
  }

  @computed
  get getBatteryDone(): boolean {
    return this.batteryStep === 'done';
  }
  @computed
  get getPartnerShipDone(): boolean {
    return this.partnershipStep === 'done';
  }
  @computed
  get getFinishStepDone(): boolean {
    return this.finishStep === 'done';
  }
  @computed
  get getUCGDone(): boolean {
    // null mean NO UCG when undefined mean, user didn't specify yet
    return (
      inscription.ucgEdited === null ||
      (typeof inscription.ucg !== 'undefined' &&
        inscription.ucg !== '' &&
        inscription.ucg === inscription.ucgEdited)
    );
  }

  @computed
  get getMasterDone(): boolean {
    const isDone =
      inscription.isMSTConnected &&
      inscription.isMasterTypeInternetConnectionSavedIsValid &&
      !inscription.isMasterTypeInternetConnectionChanged;

    if (inscription.savedMasterTypeInternetConnection === 'gsm') {
      return (
        isDone &&
        !inscription.isSimRouterReferenceChanged &&
        inscription.isSimRouterReferenceSavedIsValid &&
        !inscription.isSimSerialNumberChanged
      );
    }

    return isDone;
  }

  @computed
  get getDevicesDone(): boolean {
    return inscription.canSaveDevices;
  }

  @computed
  get getFunctionsHasForbidden(): boolean {
    return inscription.activeDevices.some(e => e.isForbidden);
  }

  @computed
  get getHasCounter(): boolean {
    return (
      inscription.activeDevices.length > 0 &&
      inscription.activeDevices.some(
        e => e.isGlobalConsumption || e.isProductionCounter
      )
    );
  }

  @computed
  get getFunctionsDone(): boolean {
    return this.getHasCounter && !this.getFunctionsHasForbidden;
  }

  @computed
  get isInscriptionDone(): boolean {
    const batteryDone = battery.haveRightToAccessMSB
      ? this.getBatteryDone
      : true;
    return (
      this.getInstallStepDone &&
      batteryDone &&
      this.getPartnerShipDone &&
      this.getUCGDone &&
      this.getMasterDone &&
      this.getFunctionsDone &&
      this.getDevicesDone
    );
  }

  @computed
  get canFinishInscription(): boolean {
    return this.isInscriptionDone && this.getFinishStepDone;
  }

  @computed
  get visibleMenuItems(): IStepperMenuItem[] {
    return this.menuItems.filter(item => !item.hidden);
  }

  @computed
  get menuItems(): IStepperMenuItem[] {
    return [
      {
        id: 1,
        key: InscriptionStep.INSTALLATION,
        name: t('RegistrationMenu:pv_installation'),
        path: 'pv_installation',
        isDone:
          this.getInstallStepDone &&
          !editElectricalInstallationStore.edited &&
          !editPVInstallationStore.edited
      },
      {
        id: 2,
        key: InscriptionStep.MSB,
        name: t('RegistrationMenu:battery'),
        path: 'battery',
        isDone: this.getBatteryDone,
        hidden: !battery.haveRightToAccessMSB
      },
      {
        id: 3,
        key: InscriptionStep.PARTNERS,
        name: t('RegistrationMenu:partnership'),
        path: 'partnership',
        isDone: this.getPartnerShipDone
      },
      {
        id: 4,
        key: InscriptionStep.UCG,
        name: t('RegistrationMenu:ucg'),
        path: 'ucg',
        isDone: this.getUCGDone
      },
      {
        id: 5,
        key: InscriptionStep.MASTER,
        name: t('RegistrationMenu:master'),
        path: 'master',
        isDone: this.getMasterDone,
        disabled: !this.getInstallStepDone
      },
      {
        id: 6,
        key: InscriptionStep.DEVICES,
        name: t('RegistrationMenu:devices'),
        path: 'devices',
        isDone: this.getDevicesDone,
        disabled: !inscription.isMSTConnected || !inscription.isMSTRegistered
      },
      {
        id: 7,
        key: InscriptionStep.FUNCTIONS,
        name: t('RegistrationMenu:functions'),
        path: 'functions',
        isDone: this.getFunctionsDone,
        disabled: !inscription.isMSTConnected || !inscription.isMSTRegistered
      },
      {
        id: 8,
        key: InscriptionStep.SUMMARY,
        name: t('RegistrationMenu:summary'),
        path: 'finish',
        isDone: this.canFinishInscription,
        disabled: !inscription.isMSTConnected || !inscription.isMSTRegistered
      }
    ];
  }

  @action.bound
  public setPartnershipStepDone() {
    this.partnershipStep = 'done';
  }

  @action.bound
  public resetStore() {
    this.partnershipStep = 'todo';
    this.batteryStep = 'todo';
    this.installationStepDone = 'todo';
    this.finishStep = 'todo';
  }

  @action.bound
  public setStepFormInstallationInformation({
    customerAndInstallationInfo: {
      electricInstallation,
      pVInstallation,
      customer
    },
    getRegistrationSteps,
    msbStatus
  }: IParams) {
    if (electricInstallation && pVInstallation && customer) {
      const hasAtLeastOneModule: boolean = Boolean(
        pVInstallation.panelCount && pVInstallation.panelCount > 0
      );

      const isWattPeakAboveZero: boolean = Boolean(
        pVInstallation.panelWattPeak && pVInstallation.panelWattPeak > 0
      );
      const hasDefinedManufacturer: boolean = !!pVInstallation.panelManufacturer;

      const hasMSB: boolean = !!(msbStatus && msbStatus.length > 0);
      const hasPdlIfHasMSB: boolean =
        (hasMSB && !!electricInstallation.pdlNumber) || true;

      const isRackingPowerAboveZero: boolean = Boolean(
        electricInstallation.rackingPower &&
          electricInstallation.rackingPower > 0
      );
      const hasGrdType: boolean = !!electricInstallation.gridType;
      const hasInternetProvider: boolean = !!electricInstallation.internetServiceProvider;

      this.setInstallationStep(
        (hasAtLeastOneModule &&
          isWattPeakAboveZero &&
          hasDefinedManufacturer &&
          hasPdlIfHasMSB &&
          isRackingPowerAboveZero &&
          hasGrdType &&
          hasInternetProvider &&
          'done') ||
          'todo'
      );
    } else {
      this.setInstallationStep('todo');
    }
    if (getRegistrationSteps) {
      this.setBatteryStepStatus(getRegistrationSteps.msb);
      this.setPartnershipStep(getRegistrationSteps.partnership);
    }
  }

  @action.bound
  public setBatteryStepStatus(status?: string) {
    this.batteryStep =
      status === 'done' ? 'done' : status === 'pending' ? 'pending' : 'todo';
    this.registeredBatteryStep =
      status === 'done' ? 'done' : status === 'pending' ? 'pending' : 'todo';
  }

  @action.bound
  public setPartnershipStep(status?: string) {
    this.partnershipStep =
      status === 'done' ? 'done' : status === 'pending' ? 'pending' : 'todo';
  }
  @action.bound
  public setFinishStepDone(isDone: boolean) {
    this.finishStep = isDone ? 'done' : 'todo';
  }
  @action.bound
  public setInstallationStep(status: string) {
    this.installationStepDone = status;
  }

  @action.bound
  public async updateMSBRegistrationStatus(
    client: WithApolloClient<any>,
    pvInstallationId: string,
    status: 'done' | 'todo'
  ) {
    if (pvInstallationId) {
      await client
        .mutate({
          mutation: SAVE_INSCRIPTION_STEP_QUERY,
          variables: {
            pvInstallationId,
            registrationSteps: {
              msb: status
            }
          }
        })
        .then(() => this.setBatteryStepStatus(status))
        .catch(() => {
          snackbarStore.showSnackBar({
            text: t('StepError:unknown'),
            type: 'error'
          });
        });
    }
  }
}

const inscriptionStepStore: InscriptionStepsStore = new InscriptionStepsStore();
export default inscriptionStepStore;
