import { default as React, ReactNode, useEffect, useState } from 'react';
import './responsiveSubMenuStepper.scss';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import {
  BREAK_POINT_SAFE_MIN_WIDTH,
  BREAK_POINT_WIDTH
} from '../../constants/BREAK_POINT_WIDTH';
import MediaQuery from 'react-responsive';
import { IStepperMenuItem } from '../../components/devices_inscription/DevicesInscription';
import doneIcon from '../../assets/images/check-circle-outline.svg';
import todoIcon from '../../assets/images/check-circle-outline-blank.svg';
import Stepper from '../Stepper/Stepper';
import { translate, TranslationFunction } from 'react-i18next';
import { safeMobxInject } from '../../stores/storeInjectionHelpers';

interface IStoresProps {
  menuItems: IStepperMenuItem[];
  setTitle: (title: string) => void;
}

interface IProps {
  children?: ReactNode;
  baseRoute: string;
  endPath: string;
  current: string;
  previousRoute?: string;
  currentItem?: IStepperMenuItem;
  fixedHeaderContent?: ReactNode;
  t: TranslationFunction;
}

const Desktop = ({
  menuItems,
  children,
  baseRoute,
  previousRoute,
  currentItem,
  endPath
}: Partial<IProps & IStoresProps>) => {
  return (
    <MediaQuery query={`(min-width: ${BREAK_POINT_SAFE_MIN_WIDTH})`}>
      <div className="menu-content">
        <div className="content-wrapper">{children}</div>
      </div>
      <div className="menu-navigation steps-wrapper">
        {menuItems!.map(item => (
          <Link
            key={item.key}
            className={classnames('menu-item', {
              active: currentItem && currentItem.key === item.key
            })}
            to={`${baseRoute}/${item.path}/${endPath}`}
          >
            <img
              src={item.isDone ? doneIcon : todoIcon}
              className="item-step"
              alt="status"
            />
            {item.name} <i className="right-arrow" />
          </Link>
        ))}
      </div>
    </MediaQuery>
  );
};

const ResponsiveSubMenuStepper = safeMobxInject<IStoresProps, IProps>(
  stores => ({
    menuItems: stores.inscriptionSteps.visibleMenuItems,
    setTitle: stores.Store.mainMenu.setTitle
  }),
  ({
    children,
    baseRoute,
    current,
    fixedHeaderContent,
    endPath,
    t,
    menuItems,
    setTitle
  }: IProps & IStoresProps) => {
    const [active, setActive] = useState<boolean>(true);
    const currentItem = menuItems.find(m => m.path === current);
    const firstItem = menuItems[0];
    const currentIsFirst = currentItem && firstItem.key === currentItem.key;
    useEffect(
      () => {
        setTitle(
          active && currentItem
            ? currentItem.name
            : t('RegistrationMenu:mainTitle')
        );
        return () => {
          setTitle('');
        };
      },
      [currentItem, active]
    );
    return (
      <>
        <Stepper
          current={active && currentItem ? currentItem.path : ''}
          activeKey={active && currentItem ? currentItem.key : ''}
        />
        <div className="menu-stepper">
          <Desktop
            {...{
              fixedHeaderContent,
              currentIsFirst,
              children,
              menuItems,
              currentItem,
              baseRoute,
              endPath
            }}
          />

          <MediaQuery query={`(max-width: ${BREAK_POINT_WIDTH})`}>
            <div className="menu-content">
              <div className={'content-wrapper'} />
            </div>
            <div className={'menu-navigation'}>
              {menuItems.map((item, pos) => (
                <div key={item.key} id={`scroll-${item.key}`}>
                  {item.disabled ? (
                    <div key={item.key} className={'menu-item disabled'}>
                      <img src={todoIcon} className="item-step" alt="status" />
                      <span className={'item-name'}>
                        <span className={'step-number'}>{pos + 1}.</span>
                        {item.name}
                      </span>
                      <i className={'right-arrow'} />
                    </div>
                  ) : (
                    <Link
                      onClick={() => {
                        setActive(
                          (!active &&
                            !!currentItem &&
                            currentItem.key === item.key) ||
                            (!!currentItem && currentItem.key !== item.key)
                        );
                      }}
                      className={classnames('menu-item', {
                        active: currentItem && currentItem.key === item.key
                      })}
                      to={`${baseRoute}/${item.path}/${endPath}`}
                    >
                      <img
                        src={item.isDone ? doneIcon : todoIcon}
                        className="item-step"
                        alt="status"
                      />
                      <span className={'item-name'}>
                        <span className={'step-number'}>{pos + 1}.</span>
                        {item.name}
                      </span>
                      <i
                        className={
                          active && currentItem && currentItem.key === item.key
                            ? 'down-arrow'
                            : 'right-arrow'
                        }
                      />
                    </Link>
                  )}
                  {currentItem && currentItem.key === item.key ? (
                    <div
                      className={
                        active ? 'sub-menu-content' : 'sub-menu-content hidden'
                      }
                    >
                      {children}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              ))}
            </div>
          </MediaQuery>
        </div>
      </>
    );
  }
);

export default translate(['RegistrationMenu'])(ResponsiveSubMenuStepper);
