import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { translate, TranslationFunction } from 'react-i18next';
import { safeMobxInject } from '../../../../../../stores/storeInjectionHelpers';
import { withRouter } from 'react-router-dom';
import { ListTitle } from '../../../../../../ui/DataDisplay/List/ListHeader';
import moment from 'moment';
import { MySmartBattery } from '../../../../../../stores/my_smart_battery/mySmartBattery';
import { caeNumberValidator, strasbourgInjectionPdlValidator } from '../../../../../../utils/validators';
import { withApollo, WithApolloClient } from 'react-apollo';
import { StrasbourgElectricityAdministrativeProcedureStatus } from '../constants';
import { MsbInputText } from '../MsbInputText';
import { MsbInlineForm } from '../MsbInlineForm';
import StrasbourgElectricityAdministrativeProcedureStatusText from './StrasbourgElectricityAdministrativeProcedureStatusText';
import { ActivationMySmartBatteryStrasbourgElectricityAdministrativeProcedure } from '../../../../../../stores/my_smart_battery/activationMySmartBatteryStrasbourgElectricityAdministrativeProcedure';
import MsbActivationUploadFileArpe from '../MsbActivationUploadFileArpe';
import { IconDownload } from '../../../../../../ui/Icons/IconDownload';
import './StrasbourgElectricityAdministrativeProcedureView.scss';
interface IStoresProps {
  mySmartBattery: MySmartBattery;
  activationMsbStrasbourgElecAdminProc: ActivationMySmartBatteryStrasbourgElectricityAdministrativeProcedure;
}

interface IProps extends RouteComponentProps<IMatchParams> {
  t: TranslationFunction;
}

interface IMatchParams {
  id: string;
}

interface IHelperTexts {
  caeNumberText: string;
  strasbourgInjectionPdlNumberText: string;
}

const StrasbourgElectricityAdministrativeProcedureView = safeMobxInject<
  IStoresProps,
  IProps
>(
  stores => ({
    mySmartBattery: stores.mySmartBattery,
    activationMsbStrasbourgElecAdminProc:
      stores.activationMySmartBatteryStrasbourgElectricityAdministrativeProcedure
  }),

  ({
    t,
    mySmartBattery,
    activationMsbStrasbourgElecAdminProc,
    client,
    match: {
      params: { id: customerId }
    }
  }: WithApolloClient<IProps & IStoresProps>) => {
    useEffect(() => {
      activationMsbStrasbourgElecAdminProc
        .getStrasbourgElecARPEDownloadURL(client)
        .then((url: string) => {
          setStrasbourgElecARPEDownloadURL(url);
        });
    }, []);

    useEffect(
      () => {
        setCaeNumber(activationMsbStrasbourgElecAdminProc.caeNumber);
        setStrasbourgInjectionPdlNumber(activationMsbStrasbourgElecAdminProc.strasbourgInjectionPdlNumber);
      },
      [
        activationMsbStrasbourgElecAdminProc.caeNumber,
        activationMsbStrasbourgElecAdminProc.strasbourgInjectionPdlNumber
      ]
    );

    useEffect(() => {
      setHelperTexts({
        caeNumberText: getCaeNumberFieldHelperText(),
        strasbourgInjectionPdlNumberText: getStrasbourgInjectionPdlNumberFieldHelperText(),
      });
    },
    [
      activationMsbStrasbourgElecAdminProc.caeNumberSaved,
      activationMsbStrasbourgElecAdminProc.strasbourgInjectionPdlNumberSaved,
    ]);

    const DATE_FORMAT = t('MySmartBattery:enteredOnFormatDate');

    const defaultInfoHelperTexts = {
      CAE_NUMBER_DEFAULT_TEXT:
        t('MySmartBattery:strasbourgElectricityAdministrativeProcedureCaeNumberFieldDescription'),
      STRASBOURG_INJECTION_PDL_NUMBER_DEFAULT_TEXT:
        t('MySmartBattery:strasbourgElectricityAdministrativeProcedureInjectionPdlNumberFieldDescription')
    };

    const [caeNumber, setCaeNumber] = useState(
      activationMsbStrasbourgElecAdminProc.caeNumber
    );

    const [strasbourgInjectionPdlNumber, setStrasbourgInjectionPdlNumber] = useState(
      activationMsbStrasbourgElecAdminProc.strasbourgInjectionPdlNumber
    );

    const [
      strasbourgElecARPEDownloadURL,
      setStrasbourgElecARPEDownloadURL
    ] = useState('');
    const [isCaeNumberLoading, setIsCaeNumberLoading] = useState(false);

    const [caeNumberTextError, setCaeNumberTextError] = useState<
      string | undefined
    >(undefined);

    const [isStrasbourgInjectionPdlNumberLoading, setIsStrasbourgInjectionPdlNumberLoading] = useState(false);

    const [strasbourgInjectionPdlNumberTextError, setStrasbourgInjectionPdlNumberTextError] = useState<
      string | undefined
    >(undefined);

    const getDateStrasbourgElectricityAdministrativeProcedureRequestedStatus = () => {
      if (
        mySmartBattery.isMSBStrasbourgElectricityAdministrativeProcedureRequested
      ) {
        const validateAt =
          mySmartBattery.msbStrasbourgElectricityAdministrativeProcedureRequestedStatus &&
          mySmartBattery
            .msbStrasbourgElectricityAdministrativeProcedureRequestedStatus
            .validateAt;
        if (validateAt) {
          return moment(validateAt).format(DATE_FORMAT);
        }
      }
      return '';
    };

    const getTextStrasbourgElectricityAdministrativeProcedureRequestedStatus = () => {
      if (
        mySmartBattery.isMSBStrasbourgElectricityAdministrativeProcedureRequested
      ) {
        return StrasbourgElectricityAdministrativeProcedureStatus.FINISHED;
      }

      if (
        !activationMsbStrasbourgElecAdminProc.caeNumber &&
        !activationMsbStrasbourgElecAdminProc.strasbourgInjectionPdlNumber &&
        !activationMsbStrasbourgElecAdminProc.sendingARPEDate
      ) {
        return StrasbourgElectricityAdministrativeProcedureStatus.FILL;
      }

      if (
        !activationMsbStrasbourgElecAdminProc.caeNumber ||
        !activationMsbStrasbourgElecAdminProc.strasbourgInjectionPdlNumber ||
        !activationMsbStrasbourgElecAdminProc.sendingARPEDate
      ) {
        return StrasbourgElectricityAdministrativeProcedureStatus.IN_PROGRESS;
      }

      return StrasbourgElectricityAdministrativeProcedureStatus.TO_VALIDATE;
    };

    const getTextCaeNumberModifiedAt = () => {
      return activationMsbStrasbourgElecAdminProc.caeNumberModifiedAt === null
        ? ''
        : `${t('enteredOn')} ${moment(
            activationMsbStrasbourgElecAdminProc.caeNumberModifiedAt
          ).format(DATE_FORMAT)}`;
    };

    const onChangeCaeNumberField = (value: string) => {
      setCaeNumber(value);
      activationMsbStrasbourgElecAdminProc.caeNumberInputState = undefined;

      setHelperTexts({
        ...helperTexts,
        caeNumberText: defaultInfoHelperTexts.CAE_NUMBER_DEFAULT_TEXT,
      });
    };

    const onBlurCaeNumberField = () => {
      if (isCaeNumberChanged()) {
        const { isValid, error } = checkCaeNumberFormat();
        if (isValid) {
          updateCaeNumber();
        } else {
          activationMsbStrasbourgElecAdminProc.caeNumberInputState = 'error';
          setCaeNumberTextError(error);
        }
      }

      setHelperTexts({
        ...helperTexts,
        caeNumberText: getCaeNumberFieldHelperText(),
      });
    };

    const isCaeNumberChanged = () => {
      return caeNumber !== activationMsbStrasbourgElecAdminProc.caeNumberSaved;
    };

    const checkCaeNumberFormat = () => {
      const error: string | undefined = caeNumberValidator(caeNumber);
      const isValid = !error;
      return {
        isValid,
        error
      };
    };

    const updateCaeNumber = async () => {
      setIsCaeNumberLoading(true);
      try {
        await activationMsbStrasbourgElecAdminProc.updateCaeNumber(
          client,
          customerId,
          caeNumber
        );
      } catch (e) {
        setCaeNumberTextError(e);
      }
      setIsCaeNumberLoading(false);
    };

    const getCaeNumberFieldHelperText = (): string => {
      return activationMsbStrasbourgElecAdminProc.caeNumber
        ? getTextCaeNumberModifiedAt()
        : defaultInfoHelperTexts.CAE_NUMBER_DEFAULT_TEXT;
    };

    const getTextStrasbourgInjectionPdlNumberModifiedAt = () => {
      return activationMsbStrasbourgElecAdminProc.strasbourgInjectionPdlNumberModifiedAt === null
        ? ''
        : `${t('enteredOn')} ${moment(
            activationMsbStrasbourgElecAdminProc.strasbourgInjectionPdlNumberModifiedAt
          ).format(DATE_FORMAT)}`;
    };

    const onChangeStrasbourgInjectionPdlNumberField = (value: string) => {
      setStrasbourgInjectionPdlNumber(value);
      activationMsbStrasbourgElecAdminProc.strasbourgInjectionPdlNumberInputState = undefined;

      setHelperTexts({
        ...helperTexts,
        strasbourgInjectionPdlNumberText: defaultInfoHelperTexts.STRASBOURG_INJECTION_PDL_NUMBER_DEFAULT_TEXT,
      });
    };

    const onBlurStrasbourgInjectionPdlNumberField = () => {
      if (isStrasbourgInjectionPdlNumberChanged()) {
        const { isValid, error } = checkStrasbourgInjectionPdlNumberFormat();
        if (isValid) {
          updateStrasbourgInjectionPdlNumber();
        } else {
          activationMsbStrasbourgElecAdminProc.strasbourgInjectionPdlNumberInputState = 'error';
          setStrasbourgInjectionPdlNumberTextError(error);
        }
      }

      setHelperTexts({
        ...helperTexts,
        strasbourgInjectionPdlNumberText: getStrasbourgInjectionPdlNumberFieldHelperText(),
      });
    };

    const isStrasbourgInjectionPdlNumberChanged = () => {
      return strasbourgInjectionPdlNumber !== activationMsbStrasbourgElecAdminProc.strasbourgInjectionPdlNumberSaved;
    };

    const checkStrasbourgInjectionPdlNumberFormat = () => {
      const error: string | undefined = strasbourgInjectionPdlValidator(
        strasbourgInjectionPdlNumber
      );
      const isValid = !error;
      return {
        isValid,
        error
      };
    };

    const updateStrasbourgInjectionPdlNumber = async () => {
      setIsStrasbourgInjectionPdlNumberLoading(true);
      try {
        await activationMsbStrasbourgElecAdminProc.updateStrasbourgInjectionPdlNumber(
          client,
          customerId,
          strasbourgInjectionPdlNumber
        );
      } catch (e) {
        setStrasbourgInjectionPdlNumberTextError(e);
      }
      setIsStrasbourgInjectionPdlNumberLoading(false);
    };

    const getStrasbourgInjectionPdlNumberFieldHelperText = (): string => {
      return activationMsbStrasbourgElecAdminProc.strasbourgInjectionPdlNumber
        ? getTextStrasbourgInjectionPdlNumberModifiedAt()
        : defaultInfoHelperTexts.STRASBOURG_INJECTION_PDL_NUMBER_DEFAULT_TEXT;
    };

    const [helperTexts, setHelperTexts] = useState<IHelperTexts>({
      caeNumberText: getCaeNumberFieldHelperText(),
      strasbourgInjectionPdlNumberText: getStrasbourgInjectionPdlNumberFieldHelperText(),
    });

    return (
      <>
        <ListTitle
          text={t('administrativeProcedure')}
          children={
            !mySmartBattery.isMSBActivated && (
              <StrasbourgElectricityAdministrativeProcedureStatusText
                date={getDateStrasbourgElectricityAdministrativeProcedureRequestedStatus()}
                status={getTextStrasbourgElectricityAdministrativeProcedureRequestedStatus()}
              />
            )
          }
        />
        
        <p className="administrativeProcedureDescription">
          {t('forActivatedMySmartBattery')}
        </p>

        <div className="downloadArpeCard">
          <p>{t('downloadARPEModele')}</p>
          
          <div>
            <IconDownload />
            <a href={strasbourgElecARPEDownloadURL}>{t('arpeDownload')}</a>
          </div>
        </div>

        <MsbInlineForm htmlFor="strasbourgInjectionPdlNumber" label={t('strasbourgInjectionPdlNumber')}>
          <MsbInputText
            disabled={
              mySmartBattery.isMSBActivated ||
              mySmartBattery.isMSBStrasbourgElectricityAdministrativeProcedureRequested
            }
            id="strasbourgInjectionPdlNumber"
            loading={isStrasbourgInjectionPdlNumberLoading}
            inputState={
              activationMsbStrasbourgElecAdminProc.strasbourgInjectionPdlNumberInputState
            }
            name="strasbourgInjectionPdlNumber"
            value={strasbourgInjectionPdlNumber}
            onChange={value => onChangeStrasbourgInjectionPdlNumberField(value)}
            onBlur={() => onBlurStrasbourgInjectionPdlNumberField()}
            helperText={{
              info: helperTexts.strasbourgInjectionPdlNumberText,
              error: strasbourgInjectionPdlNumberTextError
            }}
          />
        </MsbInlineForm>

        <MsbInlineForm htmlFor="caeNumber" label={t('caeNumber')}>
          <MsbInputText
            disabled={
              mySmartBattery.isMSBActivated ||
              mySmartBattery.isMSBStrasbourgElectricityAdministrativeProcedureRequested
            }
            id="caeNumber"
            loading={isCaeNumberLoading}
            inputState={
              activationMsbStrasbourgElecAdminProc.caeNumberInputState
            }
            name="caeNumber"
            value={caeNumber}
            onChange={value => onChangeCaeNumberField(value)}
            onBlur={() => onBlurCaeNumberField()}
            helperText={{
              info: helperTexts.caeNumberText,
              error: caeNumberTextError
            }}
          />
        </MsbInlineForm>
        
        <MsbActivationUploadFileArpe />
      </>
    );
  }
);

export default withRouter(
  translate(['MySmartBattery', 'Installation', 'Common'])(
    withApollo(StrasbourgElectricityAdministrativeProcedureView)
  )
);
