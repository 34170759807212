import * as React from 'react';
import { translate, TranslationFunction } from 'react-i18next';
import LoadingButton from '../../ui/Button/LoadingButton/LoadingButton';
import history from '../../history';

import { Col, Container, Row } from 'reactstrap';

import './notFound.scss';

interface IProps {
  location: any;
  t: TranslationFunction;
}

const NotFound = ({ location, t }: IProps) => (
  <div className="not-found">
    <Container>
      <Row>
        <Col xs={12} className="text-center">
          <span className="not-found-code">{t('notFoundCode')}</span>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="text-center">
          <h3 className="not-found-title">
            {t('notFound')}
            <code> {location.pathname}</code>
          </h3>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="text-center">
          <span className="not-found-lost">{t('lostSentence')}</span>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="text-center">
          <LoadingButton
            onClick={() => {
              history.push('/dashboard/');
            }}
          >
            {t('backHome')}
          </LoadingButton>
        </Col>
      </Row>
    </Container>
  </div>
);

export default translate('404')(NotFound);
