import * as React from 'react';
import { TranslationFunction } from 'i18next';
import { translate } from 'react-i18next';
import { Mutation, MutationResult } from 'react-apollo';
import gql from 'graphql-tag';
import { get } from 'lodash';
import { Alert } from 'reactstrap';

import './ResendActivationEmailButton.scss';
import LoadingButton from '../../../ui/Button/LoadingButton/LoadingButton';

interface IProps {
  t: TranslationFunction;
  customerEmail: string;
}

const RESEND_ACTIVATION_EMAIL_BUTTON = gql`
  mutation resendActivationEmail($email: String) {
    resendActivationEmail(email: $email) {
      success
    }
  }
`;

interface IResendActivationEmail {
  success: boolean;
}

interface IResponse {
  resendActivationEmail: IResendActivationEmail;
}

const ResendActivationEmailButton = ({ t, customerEmail }: IProps) => {
  return (
    <Mutation mutation={RESEND_ACTIVATION_EMAIL_BUTTON}>
      {(
        resendActivationEmail,
        { called, loading, data, error }: MutationResult<IResponse>
      ) => {
        return (
          <div
            style={{
              padding: 0
            }}
          >
            <LoadingButton
              loading={loading}
              colorClass="secondary"
              done={called && get(data, 'resendActivationEmail.success')}
              className="email-activation-button "
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                event.stopPropagation();
                resendActivationEmail({
                  variables: { email: customerEmail }
                });
              }}
            >
              {t('resendActivationEmail')}
            </LoadingButton>
            {called &&
              !loading &&
              (get(data, 'resendActivationEmail.success') ? (
                <Alert color="success">
                  {t('resendActivationEmailSuccess')}
                </Alert>
              ) : (
                <Alert color="danger">
                  {t('resendActivationEmailFailure')}
                </Alert>
              ))}
          </div>
        );
      }}
    </Mutation>
  );
};
export default translate('Customer')(ResendActivationEmailButton);
