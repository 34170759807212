import * as React from 'react';
import { Query, QueryResult } from 'react-apollo';

import gql from 'graphql-tag';
import LastRegisteredCustomersListView from './LastRegisteredCustomersListView';

import { get } from 'lodash';
import { extractCustomersFromPV } from '../customers/customersList/customerExtractor';
import { PVInstallation } from '../../graphql/graphql.schema';

export const GET_CURRENT_PRO_USER_LAST_CUSTOMERS = gql`
  query pvInstallations {
    pvInstallationsRegisteredWithLimit(limit: 10) {
      id
      panelCount
      panelWattPeak
      srsRegistrationDate
      srsRegistrationStatus
      tenant {
        name
      }
      customers {
        id
        lastName
        email
        firstName
        lastName
        address
        city
        zipCode
        country
        creationDate
        latitude
        longitude
      }
    }
  }
`;

interface IResponse {
  pvInstallationsRegisteredWithLimit: [PVInstallation];
}

const CustomersListQuery = () => {
  return (
    <Query query={GET_CURRENT_PRO_USER_LAST_CUSTOMERS} fetchPolicy={'no-cache'}>
      {({ loading, data, error }: QueryResult<IResponse>) => {
        const customers =
          data && data.pvInstallationsRegisteredWithLimit
            ? extractCustomersFromPV(data.pvInstallationsRegisteredWithLimit)
            : [];
        return (
          <LastRegisteredCustomersListView
            customers={customers}
            loading={loading}
            error={get(error, 'message')}
          />
        );
      }}
    </Query>
  );
};

export default CustomersListQuery;
