import * as React from 'react';
import LoginForm from './LoginForm';
import { Mutation, MutationResult } from 'react-apollo';
import gql from 'graphql-tag';
import { withRouter } from 'react-router-dom';
import { Background } from '../../ui/Background/Background';
import { RouteComponentProps } from 'react-router';
import { AuthToken } from '../../graphql/graphql.schema';

import { Md5 } from 'ts-md5/dist/md5';

import { AppInsights } from 'applicationinsights-js';
import profile from '../../stores/profile';

const LOG_IN = gql`
  mutation login($credentials: AuthUser!) {
    login(input: $credentials) {
      authToken
    }
  }
`;

interface IResponse {
  login: AuthToken;
}

const LoginView = ({ history }: RouteComponentProps) => {
  return (
    <Mutation mutation={LOG_IN}>
      {(
        logUserIn,
        { loading, data, error, called, client }: MutationResult<IResponse>
      ) => {
        if (called && !error && !loading) {
          if (data && data.login.authToken) {
            localStorage.setItem('Auth-Token', data.login.authToken);
            profile.reset();
            if (client) {
              client.clearStore().then(() => history.push('/home'));
            }
          }
        }
        return (
          <Background>
            <LoginForm
              onSubmit={(credentials: { email: string; password: string }) => {
                if (credentials.email) {
                  AppInsights.setAuthenticatedUserContext(Md5.hashStr(
                    credentials.email
                  ) as string);
                }
                logUserIn({ variables: { credentials } });
              }}
              sending={loading}
              error={error!}
            />
          </Background>
        );
      }}
    </Mutation>
  );
};

export default withRouter(LoginView);
