import React from 'react';
import IconMylightMSB from './IconMylightMSB';
import IconPlaneteOuiMSB from './IconPlaneteOuiMSB';
import { MsbBoxTypes } from '../../graphql/graphql.schema';
import { getRandomCharactersString } from '../../utils/tools';
import CustomTooltip from '../../ui/Tooltip/CustomTooltip';
import { translate } from 'react-i18next';
import { TranslationFunction } from 'i18next';

interface IProps {
  t: TranslationFunction;
  isMSBActivated: boolean;
  isMSBPotentiallyStuck?: boolean;
  msbType: MsbBoxTypes | undefined;
}

interface IState {
  uniqueKey: string;
}

class IconCompanyMSB extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      uniqueKey: getRandomCharactersString()
    };
  }

  public render() {
    return (
      <div id={this.state.uniqueKey}>
        {this.props.msbType === MsbBoxTypes.MYLIGHT ? (
          <IconMylightMSB
            isMSBActivated={this.props.isMSBActivated}
            isMSBPotentiallyStuck={this.props.isMSBPotentiallyStuck}
          />
        ) : (
          <IconPlaneteOuiMSB
            isMSBActivated={this.props.isMSBActivated}
            isMSBPotentiallyStuck={this.props.isMSBPotentiallyStuck}
          />
        )}
        {this.props.isMSBPotentiallyStuck && (
          <CustomTooltip
            type={'warning'}
            errorMsg={{
              text: this.props.t(
                `MySmartBattery:serialNumbersEnteredMoreThan30DaysAgo_${
                  this.props.msbType
                }`
              )
            }}
            selector={this.state.uniqueKey}
          />
        )}
      </div>
    );
  }
}

export default translate(['MySmartBattery'])(IconCompanyMSB);
