import React from 'react';
import { safeMobxInject } from '../../../stores/storeInjectionHelpers';
import { translate, TranslationFunction } from 'react-i18next';
import {
  Alert,
  Button,
  Col,
  Container,
  ModalBody,
  ModalFooter,
  Row
} from 'reactstrap';
import Spinner from '../../../ui/Spinner/Spinner';
import { PPDevice } from '../../../graphql/graphql.schema';
import {
  CMP,
  HANDLED_DEVICES_TYPES,
  MST,
  SPLITABLE_DEVICES
} from '../../../stores/devices_inscription/contants';
import { CompositeDevice } from '../../../stores/inscription/compositeDevice';
import { Device } from '../../../stores/inscription/device';
import DeviceItem from './DeviceItem';
import { DeviceCT } from '../../../stores/inscription/deviceCT';
import DeviceFunctionItem from '../functions/DeviceFunctionItem';
import List from '../../../ui/DataDisplay/List/ListWrapper';
import './ucgPreconfiguration.scss';
import { IModbusDevices } from './UcgPreconfigurationQuery';
import LoadingButton from '../../../ui/Button/LoadingButton/LoadingButton';

// tslint:disable-next-line:no-empty-interface
interface IStoresProps {}

interface IProps {
  t: TranslationFunction;
  devices: PPDevice[];
  errors: string[];
  addedModbusDevices: IModbusDevices[];
  applyLoading: boolean;
  fetchLoading: boolean;
  refreshLoading: boolean;
  closeUcgPreconfiguration: () => void;
  applyPreconfiguration: any;
}

const UcgPreconfigurationView = safeMobxInject<IStoresProps, IProps>(
  stores => ({}),
  ({
    t,
    devices,
    errors,
    addedModbusDevices,
    applyLoading,
    fetchLoading,
    refreshLoading,
    closeUcgPreconfiguration,
    applyPreconfiguration
  }: IProps & IStoresProps) => {
    let ucgPreconfigurationDevices: Device[] = [];

    if (!fetchLoading && devices && devices.length > 0) {
      ucgPreconfigurationDevices = devices
        .filter(d => HANDLED_DEVICES_TYPES.includes(d.deviceType || ''))
        .filter(d => !!d.preConfig.controlledDeviceType)
        .reduce((head, current) => {
          const isSplitable = SPLITABLE_DEVICES.includes(
            current.deviceType || ''
          );
          if (isSplitable) {
            // retrieve composite children
            const children =
              (current.preConfig.compositeId &&
                devices.filter(
                  df =>
                    df.preConfig.compositeId ===
                      current.preConfig.compositeId &&
                    !SPLITABLE_DEVICES.includes(df.deviceType || '')
                )) ||
              [];
            return [...head, new CompositeDevice(current, children)];
          }
          if (current.deviceType !== CMP) {
            return [...head, new Device(current)];
          }
          return head;
        }, []);
    }

    const impossiblePreconfigurationErrors = [
      'MST_CONFIG_FILE_HAS_NO_GMD',
      'MST_CONFIG_FILE_HAS_NO_TPH'
    ];

    const getStringDeviceNotFound = (): string => {
      let devicesNotFound = '';
      if (errors.includes('MST_CONFIG_FILE_HAS_NO_GMD')) {
        devicesNotFound += 'GMD';
      }

      if (errors.includes('MST_CONFIG_FILE_HAS_NO_TPH')) {
        if (devicesNotFound !== '') {
          devicesNotFound += ' & ';
        }
        devicesNotFound += 'TPH';
      }

      return devicesNotFound;
    };

    const isAddedModbusDevices = addedModbusDevices.length > 0;

    return (
      <>
        <ModalBody
          className={'ucg-preconfiguration-view'}
          style={{
            paddingTop: '0',
            paddingBottom: '0',
            overflowY: 'auto',
            height:
              fetchLoading || impossiblePreconfigurationErrors
                ? 'auto'
                : 'calc(100vh - 300px)'
          }}
        >
          <Spinner loading={fetchLoading || applyLoading} transparent>
            <Row>
              <Col>
                <List fluid={true}>
                  {isAddedModbusDevices && (
                    <Alert color="success">
                      <Col>
                        <Row>{t('Devices:devicesRS485Added')}</Row>
                        <Col>
                          {addedModbusDevices.map(modbusDevice => {
                            return (
                              <Row className={'ml-2'}>
                                {`• ${t('Devices:reference')}: ${
                                  modbusDevice.reference
                                }
                               /  ${t('Devices:address')}: ${
                                  modbusDevice.address
                                }`}
                              </Row>
                            );
                          })}
                        </Col>
                      </Col>
                    </Alert>
                  )}
                  {errors.some(r =>
                    impossiblePreconfigurationErrors.includes(r)
                  ) ? (
                    <Alert color="danger">
                      {t('Devices:configurationAssistanceCantFinish').replace(
                        '%devicesName%',
                        getStringDeviceNotFound
                      )}
                    </Alert>
                  ) : (
                    <>
                      {ucgPreconfigurationDevices &&
                        ucgPreconfigurationDevices!.map(d => {
                          return (
                            <div
                              key={d.getKey}
                              className={'devices-and-functions'}
                            >
                              <DeviceItem
                                withoutStatusConnexion
                                withoutActions
                                device={d}
                              />
                              {d.isDone ? (
                                <div className={'functions'}>
                                  {d instanceof CompositeDevice &&
                                  d.editedAreCTSplit ? (
                                    d.cts.map(
                                      (ct: DeviceCT) =>
                                        ct.isDone && (
                                          <DeviceFunctionItem
                                            isUnused
                                            withoutActions
                                            withoutPower
                                            key={ct.getKey}
                                            device={ct}
                                            parent={d}
                                            useCTPrefixInFunctionName
                                          />
                                        )
                                    )
                                  ) : (
                                    <DeviceFunctionItem
                                      isUnused
                                      withoutActions
                                      withoutPower
                                      device={d}
                                      key={d.getKey}
                                    />
                                  )}
                                </div>
                              ) : (
                                <div className={'no-function'}>
                                  <span>{t('Devices:noFunctionLabel')}</span>
                                </div>
                              )}
                            </div>
                          );
                        })}
                    </>
                  )}
                </List>
              </Col>
            </Row>
          </Spinner>
        </ModalBody>
        <ModalFooter>
          <Container>
            {!fetchLoading && (
              <>
                {errors.some(r =>
                  impossiblePreconfigurationErrors.includes(r)
                ) ? (
                  <Row>
                    <Col xs="12" className="text-right">
                      <Button
                        color="primary"
                        onClick={(event: React.MouseEvent<HTMLElement>) => {
                          event.stopPropagation();
                          closeUcgPreconfiguration();
                        }}
                      >
                        {t('Common:close')}
                      </Button>
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col xs="12" className="text-right">
                      <Alert>{t('Devices:confirmAffectDevicesFunction')}</Alert>
                      <LoadingButton
                        className="btn-cancel-preconfiguration"
                        colorClass="secondary"
                        loading={refreshLoading}
                        onClick={(event: React.MouseEvent<HTMLElement>) => {
                          event.stopPropagation();
                          closeUcgPreconfiguration();
                        }}
                      >
                        {t('Common:no')}
                      </LoadingButton>
                      <LoadingButton
                        className="btn-apply-preconfiguration"
                        colorClass="primary"
                        loading={applyLoading}
                        onClick={(event: React.MouseEvent<HTMLElement>) => {
                          event.stopPropagation();
                          applyPreconfiguration();
                        }}
                      >
                        {t('Common:yes')}
                      </LoadingButton>
                    </Col>
                  </Row>
                )}
              </>
            )}
          </Container>
        </ModalFooter>
      </>
    );
  }
);

export default translate()(UcgPreconfigurationView);
