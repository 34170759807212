import React, { useState } from 'react';
import {
  Alert,
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import { translate, TranslationFunction } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import SendInscriptionSummaryByEmailButton from './SendInscriptionSummaryByEmailButton';
import { FormInput } from '../../../../ui/FormInput/FormInput';
import DevicesInscription from '../../DevicesInscription';

interface IProps extends RouteComponentProps {
  t: TranslationFunction;
  isModalOpened: boolean;
  toggle: () => void;
}

const SendInscriptionSummaryByEmailModal = ({
  isModalOpened,
  toggle,
  t
}: IProps) => {
  const [emailAddress, updateEmailAddress] = useState<string>('');
  const isEmailInvalid = emailAddress === '';

  const onToggle = () => {
    toggle();
    updateEmailAddress('');
  };

  return (
    <Modal centered isOpen={isModalOpened}>
      <ModalHeader>
        {t('DevicesInscription:sendInscriptionSummary')}
      </ModalHeader>
      <ModalBody>
        <FormInput
          id="email"
          name={t('Common:emailAddress')}
          type="email"
          value={emailAddress}
          onChange={value => updateEmailAddress(value)}
        />
        <div className="pt-3">
          <Alert color="warning">
            <Col>
              <Row>
                {t('DevicesInscription:sendInscriptionSummaryEmailInfo')}
              </Row>
              <Row>
                <strong>
                  {t('DevicesInscription:sendInscriptionSummaryEmailExample')}
                </strong>
              </Row>
            </Col>
          </Alert>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onToggle} color="secondary" className={'mr-2'}>
          {t('Common:cancel')}
        </Button>
        <SendInscriptionSummaryByEmailButton
          emailAddresses={emailAddress}
          disabled={isEmailInvalid}
          onComplete={onToggle}
        />
      </ModalFooter>
    </Modal>
  );
};

export default withRouter(
  translate(['Common', 'DevicesInscription'])(
    SendInscriptionSummaryByEmailModal
  )
);
