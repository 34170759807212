import React from 'react';
import { IDeviceIcon } from '../DeviceIcons';

const Phone = (props: IDeviceIcon) => (
  <svg
    width="3rem"
    height="3rem"
    viewBox="0 0 20000 20000"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    color="black"
    {...props}
  >
    <g id="prefix__Layer_x0020_1">
      <g id="prefix__phone">
        <path
          style={{ fill: `${props.color}` }}
          d="M12943 13636H7083V4756c0-700 540-1240 1240-1240h3360c700 0 1240 540 1240 1240v8880h20zm-5240-540h4680V4756c0-380-320-620-620-620H8323c-380 0-700 320-700 620l80 8340zm3680-4440H8723c-380 0-780-320-780-780V5996c0-400 320-780 780-780h2660c380 0 780 320 780 780v1880c-80 460-400 780-780 780zM8723 5836c-80 0-160 80-160 160v1880c0 80 80 160 160 160h2660c80 0 160-80 160-160V5996c0-80-80-160-160-160H8723zM13643 16776H6383c-780 0-1400-620-1400-1400v-1180c0-160 160-320 320-320h9440c160 0 320 160 320 320v1160c-20 780-640 1420-1420 1420zm-8040-2280v940c0 460 400 860 860 860h7180c460 0 860-380 860-860v-940H5603z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M8723 12636c-240 0-380-160-380-380 0-240 160-380 380-380 240 0 400 160 400 380-20 220-160 380-400 380zM8723 11296c-240 0-380-160-380-380 0-240 160-400 380-400 240 0 400 160 400 400-20 220-160 380-400 380zM8723 9976c-240 0-380-160-380-380 0-240 160-400 380-400 240 0 400 160 400 400-20 220-160 380-400 380zM10043 12636c-240 0-400-160-400-380 0-240 160-380 400-380s380 160 380 380c20 220-140 380-380 380zM10043 11296c-240 0-400-160-400-380 0-240 160-400 400-400s380 160 380 400c20 220-140 380-380 380zM10043 9976c-240 0-400-160-400-380 0-240 160-400 400-400s380 160 380 400c20 220-140 380-380 380zM11383 12636c-240 0-400-160-400-380 0-240 160-380 400-380s380 160 380 380-240 380-380 380zM11383 11296c-240 0-400-160-400-380 0-240 160-400 400-400s380 160 380 400c0 220-240 380-380 380zM11383 9976c-240 0-400-160-400-380 0-240 160-400 400-400s380 160 380 400c0 220-240 380-380 380z"
        />
      </g>
    </g>
  </svg>
);

export default Phone;
