import React, { useState } from 'react';
import { translate, TranslationFunction } from 'react-i18next';
import './MsbInput.scss';
import { safeMobxInject } from '../../../../../stores/storeInjectionHelpers';
import BatteryDocumentUploadModal from '../documents/BatteryDocumentUploadModal';
import { ActivationMySmartBatteryMylight } from '../../../../../stores/my_smart_battery/activationMySmartBatteryMylight';
import { IconUpload } from '../../../../../ui/Icons/IconUpload';
import { DocumentType } from '../../../../../graphql/graphql.schema';
import { MsbInlineForm } from './MsbInlineForm';
import { ActivationMySmartBatteryStrasbourgElectricityAdministrativeProcedure } from '../../../../../stores/my_smart_battery/activationMySmartBatteryStrasbourgElectricityAdministrativeProcedure';
import RowFile, { TypeFile } from '../info/RowFile';

interface IProps {
  t: TranslationFunction;
}

interface IStoresProps {
  activationMsbMylight: ActivationMySmartBatteryMylight;
  activationMsbStrasbourgElecAdminProc: ActivationMySmartBatteryStrasbourgElectricityAdministrativeProcedure;
}

const MsbActivationUploadFileArpe = safeMobxInject<IStoresProps, IProps>(
  stores => ({
    activationMsbMylight: stores.activationMySmartBatteryMylight,
    activationMsbStrasbourgElecAdminProc:
      stores.activationMySmartBatteryStrasbourgElectricityAdministrativeProcedure,
    blobStorageArpeUrl: stores.environment.blobStorageArpeUrl
  }),
  ({
    t,
    activationMsbMylight,
    activationMsbStrasbourgElecAdminProc
  }: IProps & IStoresProps) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const getArpeFileName = (): string => {
      return `arpe_${activationMsbMylight.pdlNumberSaved}.pdf`;
    };

    return (
      <>
        {!activationMsbStrasbourgElecAdminProc.sendingARPEDate ? (
          <MsbInlineForm label={t('arpeUpload')}>
            <button
              onClick={() => setIsModalOpen(true)}
              className="msb-activation-form-group__upload-button"
            >
              {t('uploadFile')} <IconUpload />
            </button>
            <div className="msb-activation-form-group__helper-text">
              <span className="msb-activation-form-group__helper-text--info">
                {t('MySmartBattery:importARPEPdfFile')}
              </span>
            </div>
          </MsbInlineForm>
        ) : (
          <RowFile typeFile={TypeFile.ARPE} fileName={getArpeFileName()} />
        )}
        <BatteryDocumentUploadModal
          documentType={DocumentType.ARPE}
          title={t('arpeUpload')}
          target={'upload_arpe'}
          isModalOpen={isModalOpen}
          toggle={() => setIsModalOpen(!isModalOpen)}
          onComplete={sendingARPEDate =>
            (activationMsbStrasbourgElecAdminProc.sendingARPEDate = sendingARPEDate)
          }
        />
      </>
    );
  }
);

export default translate(['MySmartBattery'])(MsbActivationUploadFileArpe);
