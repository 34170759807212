import * as i18n from 'i18next';
import * as LanguageDetector from 'i18next-browser-languagedetector';
import xx from '../assets/translations/xx';
import en from '../assets/translations/en';
import fr from '../assets/translations/fr';

const instance = i18n.use(LanguageDetector).init({
  debug: false,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false
  },
  resources: {
    en,
    fr,
    xx
  }
});

export default instance;
