import * as React from 'react';
import { Mutation, MutationResult } from 'react-apollo';

import gql from 'graphql-tag';
import { RouteComponentProps, withRouter } from 'react-router';
import MasterView from './MasterView';
import { PPDeviceConfigFile } from '../../../graphql/graphql.schema';

import { ApolloError } from 'apollo-client';
import { getDevicePath } from '../routingHelper';
import { ISnackBarParams } from '../../../stores/userFeedback/snackbarStore';
import { TranslationFunction } from 'i18next';
import { translate, getI18n } from 'react-i18next';
import { DEVICE_INSCRIPTION_FIELD_QUERY } from '../DevicesInscription';
import { safeMobxInject } from '../../../stores/storeInjectionHelpers';
import i18n from '../../../translations/i18n';
import { VALID_SIM_ROUTER_REFERENCE } from '../../../stores/devices_inscription/contants';

interface IMatchParams {
  pvInstallationId: string;
  customerId: string;
  current: string;
}

export interface IProps extends RouteComponentProps<IMatchParams> {
  t: TranslationFunction;
}

interface IStoresProps {
  resetMstError: () => void;
  initErrorsFromConfigFileResponse: (data: PPDeviceConfigFile) => boolean;
  initErrorsFromGQLError: (error: ApolloError) => void;
  showSnackBar: (params: ISnackBarParams) => void;
  masterTypeInternetConnection?: string;
  getMasterDone: boolean;
  isSimRouterReferenceValid: boolean;
  canUpdateMasterConnectionType: boolean;
}

// For apolloCache to work requested value should be the same as DeviceInscriptionQuery

const SAVE = gql`
    mutation editMaster(
        $activationCode: String
        $mac: String
        $pvInstallationId: String!
        $lang: String
    ) {
        updateDeviceMST(
            activationCode: $activationCode
            pvInstallationId: $pvInstallationId
            mac: $mac
            lang: $lang
        ) {
            ${DEVICE_INSCRIPTION_FIELD_QUERY}
        }
    }
`;

const MasterQuery = safeMobxInject<IStoresProps, IProps>(
  allStores => ({
    initErrorsFromConfigFileResponse:
      allStores.inscriptionError.initErrorsFromConfigFileResponse,
    initErrorsFromGQLError:
      allStores.inscriptionError.initErrorsFromApolloError,
    resetMstError: allStores.inscriptionError.reset,
    showSnackBar: allStores.snackBar.showSnackBar,
    getMasterDone: allStores.inscriptionSteps.getMasterDone,
    masterTypeInternetConnection:
      allStores.inscription.masterTypeInternetConnection,
    isSimRouterReferenceValid: allStores.inscription.isSimRouterReferenceValid,
    canUpdateMasterConnectionType:
      allStores.inscription.canUpdateMasterConnectionType
  }),
  ({
    match: {
      params: { customerId, pvInstallationId }
    },
    history,
    initErrorsFromConfigFileResponse,
    resetMstError,
    showSnackBar,
    isSimRouterReferenceValid,
    canUpdateMasterConnectionType,
    t
  }: IProps & IStoresProps) => {
    return (
      <Mutation
        mutation={SAVE}
        onCompleted={data => {
          if (!initErrorsFromConfigFileResponse(data.updateDeviceMST)) {
            showSnackBar({
              text: t('Success:updateMaster'),
              type: 'success'
            });
            if (isSimRouterReferenceValid && !canUpdateMasterConnectionType) {
              history.push(getDevicePath({ pvInstallationId, customerId }));
            }
          }
        }}
        onError={() => {
          showSnackBar({
            text: t('Errors:unknownError'),
            type: 'error'
          });
        }}
      >
        {(saveMaster, { loading }: MutationResult) => (
          <MasterView
            saveLoading={loading}
            save={(master: { mac: string } | { activationCode: string }) => {
              resetMstError();
              saveMaster({
                variables: {
                  pvInstallationId,
                  lang: i18n.language,
                  ...master
                }
              });
            }}
          />
        )}
      </Mutation>
    );
  }
);
export default withRouter(translate(['Success', 'Errors'])(MasterQuery));
