import 'dotenv/config';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles/main.scss';

import App from './App';
import registerServiceWorker from './registerServiceWorker';

import { AppInsights } from 'applicationinsights-js';

// dev
if (process.env.NODE_ENV === 'development') {
  ReactDOM.render(<App config={process.env} />, document.getElementById(
    'root'
  ) as HTMLElement);
  registerServiceWorker();
} else {
  // deployed
  fetch('/config.json')
    .then(resp =>
      resp.json().then(config => {
        AppInsights.downloadAndSetup!({
          instrumentationKey:
            config.REACT_APP_MY_LIGHT_APPINSIGHT_INSTRUMENTATION_KEY
        });

        ReactDOM.render(<App config={config} />, document.getElementById(
          'root'
        ) as HTMLElement);
        registerServiceWorker();
      })
    )
    .catch(() => {
      ReactDOM.render(<App config={process.env} />, document.getElementById(
        'root'
      ) as HTMLElement);
      registerServiceWorker();
    });
}
