import React, { useState } from 'react';
import { WithApolloClient } from 'react-apollo';
import { RouteComponentProps, withRouter } from 'react-router';
import { translate } from 'react-i18next';
import { TranslationFunction } from 'i18next';
import classnames from 'classnames';
import { getRandomCharactersString } from '../../../../../utils/tools';
import LoadingButton from '../../../../../ui/Button/LoadingButton/LoadingButton';
import MsbDeletionModal from './MsbDeletionModal';

export interface IProps extends RouteComponentProps {
    t: TranslationFunction;
    client: WithApolloClient<any>;
    customerId: string;
    disabled?: boolean;
    classList?: string;
}

const MsbDeletionModalOpenButton = ({
  t,
  client,
  customerId,
  disabled,
  classList,
}: IProps) => {
  const [buttonId] = useState<string>(getRandomCharactersString());
  const [isModalOpened, setModalOpened] = useState(false);
  
  return (
    <>
      <LoadingButton
        id={ `button${buttonId}` }
        disabled={ disabled || false }
        className={ classnames('remove', 'remove-msb-button', classList) }
        onClick={ () => setModalOpened(true) }
      >
        {t('MySmartBattery:deletionButton')}
      </LoadingButton>

      <MsbDeletionModal
        client={ client }
        customerId={ customerId }
        isModalOpen={ isModalOpened }
        target={ `button${buttonId}` }
        toggle={ () => setModalOpened(!isModalOpened) }
      />
    </>
  )
};

export default withRouter(
  translate(['MySmartBattery, Common'])(MsbDeletionModalOpenButton)
);