import * as React from 'react';
import CustomersMap from '../../../ui/CustomersMap/CustomersMap';
import { Query, QueryResult } from 'react-apollo';
import { Alert, Button, Col, Container, Row } from 'reactstrap';
import { GET_CURRENT_PRO_USER_CUSTOMERS } from '../customersList/CustomersListQuery';
import MediaQuery from 'react-responsive';
import { BREAK_POINT_SAFE_MIN_WIDTH } from '../../../constants/BREAK_POINT_WIDTH';
import './map.scss';
import { translate, TranslationFunction } from 'react-i18next';
import { pendingRegistration } from '../../../utils/tools';
import {
  CustomerListFilter,
  CustomerListItem,
  CustomerListPagination,
  CustomerListSorting
} from '../../../graphql/graphql.schema';
import Spinner from '../../../ui/Spinner/Spinner';
import { safeMobxInject } from '../../../stores/storeInjectionHelpers';
import { isEmpty } from 'lodash';

interface IResponse {
  getCustomerList: [CustomerListItem];
}

interface IIndicatorsProps {
  t: TranslationFunction;
  customers: CustomerListItem[];
  showAllCustomers: () => void;
  isShowAllCustomersButtonVisible: boolean;
}

const Indicators = translate(['Dashboard', 'Customer'])(
  ({
    customers,
    t,
    showAllCustomers,
    isShowAllCustomersButtonVisible
  }: IIndicatorsProps) => {
    const total = customers.length;
    const countPending = customers.filter(
      (c: any) => !pendingRegistration(c.status)
    ).length;
    const waitingActivation = total - countPending;
    return (
      <Container
        style={{
          position: 'relative',
          margin: 'auto',
          height: '100%',
          fontWeight: 'bold',
          marginTop: '20px'
        }}
      >
        <Row
          className="text-center"
          style={{
            fontSize: '46px',
            lineHeight: '40px',
            marginTop: '35px'
          }}
        >
          <Col>{total}</Col>
        </Row>
        <Row className="text-center" style={{ fontSize: '24px' }}>
          <Col>{t('numberOfInstallation')}</Col>
        </Row>
        <Row
          className="text-center"
          style={{
            fontSize: '24px',
            lineHeight: '17px',
            marginTop: '35px',
            color: '#009dbf'
          }}
        >
          <Col>{countPending}</Col>
        </Row>
        <Row
          className="text-center"
          style={{ fontSize: '18px', color: '#009dbf' }}
        >
          <Col>{t('operational')}</Col>
        </Row>
        <Row
          className="text-center"
          style={{
            fontSize: '24px',
            lineHeight: '17px',
            marginTop: '35px',
            color: '#009dbf'
          }}
        >
          <Col>{waitingActivation}</Col>
        </Row>
        <Row
          className="text-center"
          style={{ fontSize: '18px', color: '#009dbf' }}
        >
          <Col>{t('pending')}</Col>
        </Row>
        {isShowAllCustomersButtonVisible && (
          <Row style={{ justifyContent: 'center' }}>
            <Button
              className={'m-4'}
              color={'primary'}
              onClick={showAllCustomers}
            >
              {t('Customer:showAllCustomers')}
            </Button>
          </Row>
        )}
      </Container>
    );
  }
);

interface IProps {
  t: TranslationFunction;
}

interface IStoresProps {
  filterRule: CustomerListFilter[];
  sortingRule: CustomerListSorting;
  searchRule: string;
  offset: number;
  pageSize: number;
}

const Map = safeMobxInject<IStoresProps, IProps>(
  ({ Store: { customersList } }) => ({
    filterRule: customersList.filterRule,
    sortingRule: customersList.sortingRule,
    searchRule: customersList.searchRule,
    offset: customersList.offset,
    pageSize: customersList.pageSize
  }),
  ({
    t,
    pageSize,
    searchRule,
    sortingRule,
    filterRule,
    offset
  }: IProps & IStoresProps) => {
    const pagination: CustomerListPagination = {
      pageSize: offset ? offset : pageSize,
      search: searchRule,
      sorting: sortingRule,
      filters: filterRule
    };
    return (
      <Query
        query={GET_CURRENT_PRO_USER_CUSTOMERS}
        variables={{ pagination }}
        fetchPolicy={'cache-and-network'}
      >
        {({
          loading,
          data,
          error,
          refetch,
          variables
        }: QueryResult<IResponse>) => {
          if (loading) {
            return (
              <Spinner loading={true}>
                <div style={{ width: '100%', height: '100%' }}>
                  {t('Common:loading')}
                </div>
              </Spinner>
            );
          }
          if (data) {
            const customers = data.getCustomerList;
            const showAllCustomers = () => refetch({ pagination: {} });
            const isShowAllCustomersButtonVisible = !isEmpty(
              variables.pagination
            );
            return (
              <div>
                <MediaQuery
                  query={`(min-width: ${BREAK_POINT_SAFE_MIN_WIDTH})`}
                >
                  <div className="indicators-container">
                    <Indicators
                      customers={customers}
                      showAllCustomers={showAllCustomers}
                      isShowAllCustomersButtonVisible={
                        isShowAllCustomersButtonVisible
                      }
                    />
                  </div>
                </MediaQuery>
                <div className="map-container">
                  <CustomersMap
                    customers={customers.filter(c => c.latitude && c.longitude)}
                  />
                </div>
              </div>
            );
          }
          return (
            <Alert className="email-error" color="danger">
              {`Error! ${error ? error.message : ''}`}
            </Alert>
          );
        }}
      </Query>
    );
  }
);

export default translate()(Map);
