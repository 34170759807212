import * as React from 'react';
import { Query, QueryResult } from 'react-apollo';

import gql from 'graphql-tag';
import StartDiagnosticT48 from './StartDiagnosticT48';
import { ICustomerExtended } from '@mylight/data-model';
import { translate, TranslationFunction } from 'react-i18next';

export const GET_DIAGNOSTIC_DEVICES_STATUS = gql`
  query getDiagnosticAndCustomerGridType($customerEmail: String!) {
    customer(id: $customerEmail) {
      id
      address
      email
      firstName
      lastName
      zipCode
      loginAsAllowed
      city
      registrationDate
      status
      birthDate
      phoneNumber
      mobileNumber
      lastConnection
      lastConnections
      country
      gridType
      tenant {
        name
        color
      }
      installer {
        email
      }
      maintainer {
        email
      }
    }
  }
`;

export type Status = 'pending' | 'ok' | 'failure';

interface IResponse {
  customer?: ICustomerExtended;
}

interface IProps {
  id: string;
  status?: Status;
  t: TranslationFunction;
}

const SystemDiagnosticQuery = ({ id, t }: IProps) => {
  return (
    <Query
      query={GET_DIAGNOSTIC_DEVICES_STATUS}
      variables={{ customerEmail: id }}
    >
      {({ loading, data, error }: QueryResult<IResponse>) => {
        return !error &&
          !loading &&
          data &&
          (status !== 'ok' && status !== 'pending') ? (
          <StartDiagnosticT48
            id={id}
            gridType={data.customer && data.customer.gridType}
          />
        ) : status === 'pending' ? (
          <div>{t('diagnosticStatusPending')}</div>
        ) : (
          <div />
        );
      }}
    </Query>
  );
};

export default translate('Diagnostic')(SystemDiagnosticQuery);
