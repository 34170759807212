import { get, orderBy } from 'lodash';
import moment from 'moment';

import logoDomuneo from '../assets/images/domuneo/logo.svg';
import logoMyhome from '../assets/images/myhome/logo.svg';
import logoPlaneteoui from '../assets/images/planeteoui/logo.svg';
import logoEngie from '../assets/images/engie/logo.svg';

import smallLogoDomuneo from '../assets/images/domuneo/tenant_domuneo_in.svg';
import smallLogoMyhome from '../assets/images/myhome/tenant_mylight_in.svg';
import smallLogoPlaneteoui from '../assets/images/planeteoui/tenant_planete-oui_in.svg';
import smallLogoEngie from '../assets/images/engie/tenant_engie_in.svg';
import { IContact } from '@mylight/data-model/lib/customers/IContact';
import RegistrationStatus from '../constants/REGISTRATION_STATUS';
import React from 'react';
import { t } from 'i18next';
import { ISelectValue } from '../ui/Select/Select';
import { CustomerListItem } from '../graphql/graphql.schema';
const DATE_FORMAT = 'DD/MM/YYYY';

export interface ISortingRule {
  paramName: string;
  reverse: boolean;
}

const tools = {
  openNewTabfForURI(uri: string) {
    window.open(uri, '_blank');
  },

  openNewWindowWithParams(uri: string, params: string) {
    window.open(uri, '_blank', 'width=1280,height=768');
  },

  redirectToURI(uri: string) {
    window.location.replace(uri);
  },

  downloadFileFromUrl(url: string) {
    const a = document.createElement('a');
    a.href = url;
    a.download = '';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
};

export const pendingRegistration = (status: string): boolean =>
  ![
    RegistrationStatus.OK,
    RegistrationStatus.SETUP_OK,
    RegistrationStatus.USER_SETTINGS_SET,
    RegistrationStatus.USER_ACCEPTED_LICENCE,
    RegistrationStatus.USER_PASSWORD_SET
  ].includes(status);

export const dateFormatter = (
  dateValue: number | undefined,
  format?: string
) => {
  return dateValue
    ? moment(new Date(dateValue)).format(format || DATE_FORMAT)
    : '-';
};
export const addressFormatter = (contact?: CustomerListItem): string =>
  contact
    ? `${contact.zipCode ? contact.zipCode : ''} ${
        contact.city ? contact.city : ''
      } ${contact.country ? contact.country : ''}`
    : '-';
export const addressFormatterLong = (contact?: IContact): string =>
  contact
    ? `${contact.address} ${contact.zipCode} ${contact.city} ${contact.country}`
    : '-';

export const getTenantLogo = (tenantName: string | undefined) => {
  switch (tenantName) {
    case 'domuneo':
      return logoDomuneo;
    case 'planeteoui':
      return logoPlaneteoui;
    case 'mypower':
      return logoEngie;
    default:
      return logoMyhome;
  }
};

export const getSmallTenantLogo = (tenantName: string | undefined) => {
  switch (tenantName) {
    case 'domuneo':
      return smallLogoDomuneo;
    case 'planeteoui':
      return smallLogoPlaneteoui;
    case 'mypower':
      return smallLogoEngie;
    default:
      return smallLogoMyhome;
  }
};
export const energyValueFormatter = (
  obj: any,
  key: string,
  withUnit: boolean = true
) => {
  const value = get(obj, key, '');
  return value === '' ? (
    '-'
  ) : Math.abs(value) > 1000 ? (
    <span>
      {(Math.round(value) / 1000).toFixed(2)} {withUnit && <sup>kW</sup>}
    </span>
  ) : (
    <span>
      {Math.round(value)} {withUnit && <sup>W</sup>}
    </span>
  );
};

export const wattPeakFormatter = (value: number) => {
  return !!value
    ? value > 1000
      ? `${(value / 1000).toFixed(1)} kWc`
      : `${value} Wc`
    : undefined;
};

export const getAddressLink = (address: string) => {
  const iOS =
    !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

  if (iOS) {
    return `http://maps.apple.com/?q=${address.replace(/ /g, '+')}`;
  }

  const encode = address.replace(/ /g, '+').replace(/,/g, '%2C');

  return `https://www.google.com/maps/search/?api=1&query=${encode}`;
};

export const getRandomCharactersString = (length: number = 8) => {
  const chars = 'abcdefghiklmnopqrstuvwxyz';
  let randomString = '';

  for (let i = 0; i < length; i += 1) {
    const randomNumber = Math.floor(Math.random() * chars.length);
    randomString += chars.substring(randomNumber, randomNumber + 1);
  }

  return randomString;
};

export const retainsDefined = (obj: any) => {
  return Object.keys(obj).reduce(
    (acc, curr) => (!!obj[curr] ? { ...acc, [curr]: obj[curr] } : acc),
    {}
  );
};

export const isMobileDevice = () => {
  return (
    typeof window.orientation !== 'undefined' ||
    navigator.userAgent.indexOf('IEMobile') !== -1
  );
};

export const getDeviceFunctionOptions = (array: string[]) => {
  return array.map((e: string) => ({
    value: e,
    label: e ? t(`DevicesFunctions:${e}`) : ''
  }));
};

export const sortSelectOptionsAlphabetically = (options: ISelectValue[]) => {
  return options.sort((a, b) => {
    if (a.label.toLowerCase() < b.label.toLowerCase()) {
      return -1;
    }
    if (a.label.toLowerCase() > b.label.toLowerCase()) {
      return 1;
    }
    return 0;
  });
};

export const shouldDisplayContinueInstallation = (status: string): boolean => {
  return (
    status !== RegistrationStatus.SETUP_OK &&
    status !== RegistrationStatus.USER_PASSWORD_SET &&
    status !== RegistrationStatus.USER_SETTINGS_SET &&
    status !== RegistrationStatus.USER_ACCEPTED_LICENCE &&
    status !== RegistrationStatus.OK
  );
};

export default tools;
