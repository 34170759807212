import React from 'react';
import { ListTitle } from '../../ui/DataDisplay/List/ListHeader';
import { t } from 'i18next';
import { safeMobxInject } from '../../stores/storeInjectionHelpers';
import { IAllStores } from '../../stores/allStores.model';
import DevicePaired from './DevicePaired';
import { Device } from '../../stores/inscription/device';

interface IStoreProps {
  pairedDevices: Device[];
}

export const DevicePairedList = safeMobxInject<IStoreProps, {}>(
  ({ searchPairingStore }: IAllStores) => {
    return {
      pairedDevices: searchPairingStore.pairedDevices
    };
  },
  (props: IStoreProps) => {
    const { pairedDevices } = props;

    return (
      <>
        {pairedDevices &&
          pairedDevices.length > 0 && (
            <ListTitle text={t('ToolsPairing:completeSystem')} />
          )}
        {pairedDevices &&
          pairedDevices.map((pairedDevice: Device) => {
            return <DevicePaired device={pairedDevice} />;
          })}
      </>
    );
  }
);
