import { SignalQuality } from '../../../constants/SignalQuality';
import { safeMobxInject } from '../../../stores/storeInjectionHelpers';
import { default as React } from 'react';
import { translate, TranslationFunction } from 'react-i18next';
import './Master.scss';
import classnames from 'classnames';

// tslint:disable-next-line:no-empty-interface
interface IStoresProps {}

interface IProps {
  t: TranslationFunction;
  tx?: number;
  rx?: number;
  plcSignalQuality?: string;
}

const MasterConnectionTypePlcMylightAdditionalInfo = safeMobxInject<
  IStoresProps,
  IProps
>(
  stores => ({}),
  ({ t, tx = 0, rx = 0, plcSignalQuality }: IProps & IStoresProps) => {
    return (
      <span
        className={classnames(
          'badge',
          'badge-pill',
          plcSignalQuality === SignalQuality.BAD || !plcSignalQuality
            ? 'badge-warning'
            : 'badge-success'
        )}
      >
        {t('Devices:tx')}:
        <b>{` ${tx}`}</b>
        {` / `}
        <span>{t('Devices:rx')}:</span>
        <b>{` ${rx}`}</b>
      </span>
    );
  }
);

export default translate()(MasterConnectionTypePlcMylightAdditionalInfo);
