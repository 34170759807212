import React, { useState } from 'react';
import { translate, TranslationFunction } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import { Button, Col, Form, Row } from 'reactstrap';
import imgPositionUPM from '../../../../../assets/images/upm/position-upm.jpg';
import {
  IUPMSerialNumberRegexMatch,
  UPM_SERIAL_NUMBER_REGEX
} from '../../../../../constants/UPMSerialNumberRegex';
import CustomModal from '../../../../../ui/CustomModal/CustomModal';
import { FormInput } from '../../../../../ui/FormInput/FormInput';
import IsolatedComponent from '../../../../../ui/IsolatedComponent/IsolatedComponent';
import { IInscriptionNavigationRouterParamProps } from '../../../InscriptionNavigation';

import '../addDevice.scss';
import AddWModbusBoxDeviceButton from './AddWModbusBoxDeviceButton';

interface IProps
  extends RouteComponentProps<IInscriptionNavigationRouterParamProps> {
  t: TranslationFunction;
}

const AddWModbusBoxDevice = ({
  t,
  history,
  match: {
    params: { customerId, pvInstallationId }
  }
}: IProps) => {
  const goBack = () =>
    history.push(
      `/devices_inscription/${customerId}/devices/${pvInstallationId}/add/wmodbus`
    );

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [upmSerial, setUpmSerial] = React.useState<string>('');

  const handleDeviceSerialChange = (value: string) => {
    setUpmSerial(value.toUpperCase());
  };

  const isDeviceSerialValid = (): boolean => {
    const match = upmSerial.match(UPM_SERIAL_NUMBER_REGEX);
    if (!match || !match.groups) return false;

    const serial = (match.groups as any) as IUPMSerialNumberRegexMatch;

    // validate year number, must be between 2020 and current year
    const yearNumber = parseInt(serial.year, 10);
    const currentYearNumber = new Date().getFullYear() % 100;
    const isYearValid = yearNumber >= 20 && yearNumber <= currentYearNumber;

    // validate week number, must be between 1 and 52
    const weekNumber = parseInt(serial.week, 10);
    const isWeekValid = weekNumber >= 1 && weekNumber <= 52;

    return isYearValid && isWeekValid;
  };

  return (
    <IsolatedComponent
      title={t('DeviceType:modbus_box')}
      subtitle={t('Devices:addDeviceTitleWModbusBox')}
      sm={10}
      md={9}
      lg={6}
      xl={5}
      onGoBack={goBack}
    >
      <Col className={'add-device'}>
        <Form className={'form-wrapper'}>
          <Row>
            <FormInput
              id={'wmodbus-box'}
              name={t('Devices:addDeviceWModbusBoxSerial')}
              value={upmSerial}
              onChange={handleDeviceSerialChange}
              addon={
                <Button
                  className={'hint-button'}
                  size="sm"
                  onClick={() => setIsModalOpen(true)}
                >
                  {t('UPM:whereIsSerial')}
                </Button>
              }
              error={
                upmSerial &&
                !isDeviceSerialValid() &&
                t('Errors:wModbusBoxSerialError')
              }
              placeholder={'XXXXXXXXXXXXX'}
              required
            />
          </Row>

          <Row className={'add-device-button'}>
            <Button color={'secondary'} onClick={goBack}>
              {t('Common:cancel')}
            </Button>
            <AddWModbusBoxDeviceButton
              upmSerial={upmSerial}
              disabled={!upmSerial || !isDeviceSerialValid()}
            />
          </Row>
        </Form>
      </Col>

      <CustomModal
        isOpened={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        title={t('UPM:whereIsSerial')}
      >
        <Col className={'upm-image-modal'}>
          <Row> {t('UPM:positionSerial')}</Row>
          <Row className="pt-4">
            <img src={imgPositionUPM} alt="" />
          </Row>
        </Col>
      </CustomModal>
    </IsolatedComponent>
  );
};

export default withRouter(
  translate(['Devices', 'DeviceType', 'UPM', 'Common', 'Errors'])(
    AddWModbusBoxDevice
  )
);
