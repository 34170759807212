import { action, computed, observable } from 'mobx';
import { MasterTypeInternetConnection } from '../../constants/MasterTypeInternetConnection';
import { SignalQuality } from '../../constants/SignalQuality';
import { EPPDevice, PPDeviceConfigFile } from '../../graphql/graphql.schema';
import { Device } from './device';
import { CompositeDevice } from './compositeDevice';
import {
  CMP,
  COM1,
  HANDLED_DEVICES_TYPES,
  MST,
  PLC_HUB,
  VALID_SIM_ROUTER_REFERENCE,
  SPLITABLE_DEVICES
} from '../devices_inscription/contants';
import inscriptionStepStore, {
  disabledMenuItemsForNoMST
} from './inscriptionStepsStore';
import inscriptionError from './inscriptionErrorStore';
import batteryStore from '../battery';
import { GridType } from '@mylight/data-model';
import {
  numberValidator,
  simRouterReferenceValidator
} from '../../utils/validators';
import { getMasterPath } from '../../components/devices_inscription/routingHelper';
import { ApolloError } from 'apollo-client';
import { ICustomerAndDevicesResponse } from '../../components/devices_inscription/DevicesInscription';
import history from '../../history';
import React from 'react';
import BadPLCSignalQualityWarning from '../../components/devices_inscription/warnings/BadPLCSignalQualityWarning';
import BadGSMSignalQualityWarning from '../../components/devices_inscription/warnings/BadGSMSignalQualityWarning';
import { t } from 'i18next';
import RebootMasterWarning from '../../components/devices_inscription/warnings/RebootMasterWarning';
import PLCBestPracticeWarning from '../../components/devices_inscription/warnings/PLCBestPracticeWarning';
import { TenantName } from '../forms/createCustomer/createCustomerForm';

export type UCG = string | null;

export const DELAY_REFRESH_CUSTOMER_AND_DEVICE_CONFIG = 5000;

export interface IDeviceId {
  ethMac: string;
  plcMac: string;
}

export interface IThirdParty {
  id: string;
  name?: string;
  status: string;
  routerLink: string;
}

export class InscriptionStore {
  @observable public devices: Device[] = [];
  @observable public ucg?: UCG = undefined;
  @observable public ucgEdited?: UCG = undefined;
  @observable public customerCountry?: string = undefined;
  @observable public customerTenant?: string = undefined;
  @observable public id: string = '';
  @observable public storedPVInstallationId?: string = undefined;
  @observable public editedMST?: string = '';
  @observable public mstEthMac?: string = undefined;
  @observable public mstCode?: string = undefined;
  @observable public mstGeneration?: string = undefined;
  @observable public submittedMST?: string = undefined;
  @observable public masterHasSimNb: boolean = false;
  @observable public isDevicesConfigurationModeSelected: boolean = true;

  @observable public gridType?: string = undefined;

  @observable public masterTypeInternetConnection?: string = undefined;
  @observable public savedMasterTypeInternetConnection?: string = undefined;
  @observable public simRouterReference: string = '';
  @observable public savedSimRouterReference: string = '';
  @observable public simSerialNumber: string = '';
  @observable public savedSimSerialNumber: string = '';

  @observable
  public resetStore() {
    this.ucg = null;
    this.id = '';

    this.ucgEdited = null;
    this.mstCode = undefined;
    this.mstEthMac = undefined;
    this.editedMST = '';
    this.masterHasSimNb = false;
    this.isDevicesConfigurationModeSelected = true;
    this.resetMasterTypeInternetConnectionInfo();
    this.gridType = undefined;
    this.devices = [];
  }

  /**
   * Init devices from backend configuration
   * @param config PPDeviceConfigFile from backend
   * @param gridType
   * @param masterTypeInternetConnection
   * @param simSerialNumber
   * @param simRouterReference
   */
  @action.bound
  public initialise(
    config: PPDeviceConfigFile,
    gridType?: string,
    masterTypeInternetConnection?: string,
    simSerialNumber?: string,
    simRouterReference?: string
  ) {
    this.resetStore();
    const devices = config.devices;
    this.gridType = gridType;

    this.simSerialNumber = simSerialNumber || '';
    this.savedSimSerialNumber = simSerialNumber || '';
    this.setSimRouterReference(simRouterReference || '');
    this.savedSimRouterReference = simRouterReference || '';
    this.masterTypeInternetConnection = masterTypeInternetConnection;
    this.setSavedMasterTypeInternetConnection(masterTypeInternetConnection);
    this.setIsDevicesConfigurationModeSelected(
      config.isDevicesConfigurationModeSelected
    );
    if (config.ucg && config.ucg.noUcg) {
      this.ucg = null;
      this.ucgEdited = null;
    } else if (config.ucg && !config.ucg.noUcg) {
      this.ucg = config.ucg.ucgSerialNumber || '';
      this.ucgEdited = config.ucg.ucgSerialNumber || '';
    }
    if (!config.ucg) {
      this.ucg = undefined;
      this.ucgEdited = undefined;
    }
    this.mstCode = (config.mst && config.mst.activationCode) || '';
    this.mstEthMac = (config.mst && config.mst.ethMac) || '';
    this.mstGeneration = (config.mst && config.mst.generation) || undefined;

    this.id = config.id;
    if (devices) {
      const master = devices.find(d => d.deviceType === MST);
      this.setMasterHasSimNb(master && master.simNb ? master.simNb : null);
      this.devices = devices
        .filter(d => HANDLED_DEVICES_TYPES.includes(d.deviceType || ''))
        .reduce((head, current) => {
          const isSplitable = SPLITABLE_DEVICES.includes(
            current.deviceType || ''
          );
          if (isSplitable) {
            // retrieve composite children
            const children =
              (current.preConfig.compositeId &&
                devices.filter(
                  df =>
                    df.preConfig.compositeId ===
                      current.preConfig.compositeId &&
                    !SPLITABLE_DEVICES.includes(df.deviceType || '')
                )) ||
              [];
            return [...head, new CompositeDevice(current, children)];
          }
          if (current.deviceType !== CMP) {
            return [...head, new Device(current)];
          }
          return head;
        }, []);
    }
  }

  @action.bound
  public setMasterHasSimNb(simNb: string | null): void {
    if (simNb === null) {
      this.masterHasSimNb = false;
    } else {
      this.masterTypeInternetConnection = MasterTypeInternetConnection.GSM;
      this.simSerialNumber = simNb;
      if (this.simRouterReference && !this.isSimRouterReferenceValid) {
        this.simRouterReference = '';
      } else {
        this.simRouterReference = VALID_SIM_ROUTER_REFERENCE;
      }
      this.masterHasSimNb = true;
    }
  }

  @action.bound
  public setIsDevicesConfigurationModeSelected(
    isDevicesConfigurationModeSelected?: boolean
  ): void {
    this.isDevicesConfigurationModeSelected = Boolean(
      isDevicesConfigurationModeSelected
    );
  }

  @computed
  public get isTenantMylight(): boolean {
    return this.customerTenant === TenantName.MYHOME;
  }

  @computed
  public get isTenantDomuneo(): boolean {
    return this.customerTenant === TenantName.DOMUNEO;
  }

  /**
   * Format data for backend
   */
  @computed
  get toMutationPayload(): EPPDevice[] {
    return this.devices.reduce(
      (acc, curr) => [...acc, ...curr.toMutationPayload],
      []
    );
  }

  @computed
  get mstPayload(): { mac: string } | { activationCode: string } {
    if (this.editedMST) {
      if (this.editedMST && this.editedMST.match('^([A-Fa-f0-9]{12})$')) {
        return { mac: this.editedMST };
      }
      return { activationCode: this.editedMST || '' };
    }
    if (this.mstCode && this.mstCode.match('^([A-Fa-f0-9]{12})$')) {
      return { mac: this.mstCode };
    }
    return { activationCode: this.mstCode || '' };
  }

  @computed
  get devicesDone(): Device[] {
    return this.activeDevices.filter(d => d.isDone);
  }

  @computed
  get compositeDevicesDone(): CompositeDevice[] {
    return this.activeDevices.filter(d => {
      return d instanceof CompositeDevice && d.isDone;
    }) as CompositeDevice[];
  }

  @computed
  get devicesTodo(): Device[] {
    return this.activeDevices.filter(d => d.isTodo);
  }

  @computed
  get hasMC3D01RMCModbusConfiguredAsProductionCounter(): boolean {
    return this.activeDevices.some(
      d => d.isModbusMC3D01RMConfiguredAsProductionCounter
    );
  }

  @computed
  get isDevicesHasNotAssignFunction(): boolean {
    return this.devicesTodo.length > 0;
  }

  @computed
  get isMstG3(): boolean {
    return this.mstGeneration === 'G3';
  }

  @computed
  get mstName(): string {
    return this.isMstG3 ? 'DeviceType:mst_g3' : 'DeviceType:mst';
  }

  @computed
  get masterDevice(): Device | undefined {
    return this.devices && this.devices.find(device => device.isMst);
  }

  @computed
  get masterVersionFirmware(): number | undefined {
    return this.masterDevice && this.masterDevice.firmware;
  }

  @computed
  get isGSMConnected(): boolean {
    return !!this.masterDevice && !!this.masterDevice.isGSMConnected;
  }

  @computed
  get mstCodeInvalid(): boolean {
    if (this.mstCode) {
      if (
        this.mstCode.match('^([A-Fa-f0-9]{12})$') ||
        (this.mstCode &&
          this.mstCode.match('^([A-Za-z0-9]{4}[-]){2}[A-Za-z0-9]{4}$'))
      ) {
        return false;
      }
    }
    return true;
  }

  @computed
  get editedMSTInvalid(): boolean {
    if (this.editedMST) {
      if (
        this.editedMST.match('^([A-Fa-f0-9]{12})$') ||
        (this.editedMST &&
          this.editedMST.match('^([A-Za-z0-9]{4}[-]){2}[A-Za-z0-9]{4}$'))
      ) {
        return false;
      }
    }
    return true;
  }

  @computed
  get activeDevices(): Device[] {
    return this.devices.filter(
      d => !d.isDeleted && d.deviceType !== COM1 && d.deviceType !== PLC_HUB
    );
  }

  @computed
  get activeNotModbusDevices(): Device[] {
    return this.activeDevices.filter(d => d.plcMac && !d.plcMac.includes('RS'));
  }

  @computed
  get activeModbusDevices(): Device[] {
    return this.activeDevices.filter(d => d.plcMac && d.plcMac.includes('RS'));
  }

  @computed
  get deletedDevices(): Device[] {
    return this.devices.filter(d => d.isDeleted);
  }

  @computed
  get hasTooManyGlobalConsumptionSelected(): boolean {
    let nbDevicesWithGlobalConsumptionSelected = 0;
    this.activeDevices.forEach(device => {
      if (device instanceof CompositeDevice && device.editedAreCTSplit) {
        device.cts.forEach(ct => {
          if (ct.hasSelectedGlobalConsumption) {
            nbDevicesWithGlobalConsumptionSelected += 1;
          }
        });
      } else {
        if (device.hasSelectedGlobalConsumption) {
          nbDevicesWithGlobalConsumptionSelected += 1;
        }
      }
    });
    return nbDevicesWithGlobalConsumptionSelected > 1;
  }

  @computed
  get hasDisconnectedModbusDevice(): boolean {
    return this.activeModbusDevices.some(device => !device.isDeviceConnected);
  }

  @computed
  get masterWarnings(): [
    { type: 'danger' | 'info' | 'warning'; message: string | JSX.Element }
  ] {
    return [
      !this.isMSTConnected && {
        type: 'danger',
        message: t('Master:selectMasterDisconnect')
      },
      this.isRebootMasterWarningMessageDisplayed && {
        type: 'info',
        message: <RebootMasterWarning />
      },
      this.isGsmBadSignalQuality && {
        type: 'warning',
        message: <BadGSMSignalQualityWarning />
      },
      this.isGsmOffline && {
        type: 'warning',
        message: t('Master:warningGsmMaybeDisconnected')
      },
      this.isPlcOffline && {
        type: 'warning',
        message: t('Master:adapterCplNotDetected')
      },
      this.isPlcBestPracticeWarningDisplayed && {
        type: 'warning',
        message: <PLCBestPracticeWarning />
      },
      this.isPlcBadSignalQuality && {
        type: 'warning',
        message: <BadPLCSignalQualityWarning />
      },
      this.isMSTConnected &&
        !this.isMasterTypeInternetConnectionSavedIsValid && {
          type: 'warning',
          message: t('Master:selectTypeConnection')
        }
    ].filter(e => !!e) as [
      { type: 'danger' | 'info' | 'warning'; message: string | JSX.Element }
    ];
  }

  @computed
  get warnings(): [string | JSX.Element] {
    return [
      this.isPlcOffline && 'missingPLCHub',
      this.isGsmOffline && 'missingGSM',
      this.isPlcBadSignalQuality && <BadPLCSignalQualityWarning />,
      this.isGsmBadSignalQuality && <BadGSMSignalQualityWarning />
    ].filter(e => !!e) as [string | JSX.Element];
  }

  @computed
  get getGridTypeId(): string {
    return this.gridType
      ? this.gridType === GridType.SINGLE_PHASE
        ? 'mono'
        : 'tri'
      : '';
  }

  @computed
  get isMSTConnected(): boolean {
    return Boolean(this.masterDevice);
  }

  @computed
  get isMSTRegistered(): boolean {
    return Boolean(this.mstGeneration && this.mstEthMac && this.mstCode);
  }

  @computed
  get adapterCplMylight(): Device | undefined {
    const searchAdaptaterResult = this.devices.find(
      d => d.deviceType === PLC_HUB || d.deviceType === COM1
    );
    if (!!searchAdaptaterResult) {
      return new Device(searchAdaptaterResult);
    }
    return searchAdaptaterResult;
  }

  @computed
  get isPlcHub(): boolean {
    return !!this.adapterCplMylight;
  }

  @computed
  public get customerIsFromCH() {
    return this.customerCountry === 'CH';
  }

  @computed
  public get ucgInquire() {
    return !!this.ucg;
  }

  @computed
  get isRebootMasterWarningMessageDisplayed(): boolean {
    return Boolean(
      this.isMSTConnected && this.isMasterTypeInternetConnectionChanged
    );
  }

  @computed
  get isMasterConnectedViaGSM(): boolean {
    return Boolean(
      this.masterTypeInternetConnection === MasterTypeInternetConnection.GSM
    );
  }

  @computed
  get isMasterConnectedViaPLC(): boolean {
    return Boolean(
      this.masterTypeInternetConnection ===
        MasterTypeInternetConnection.PLC_MYLIGHT
    );
  }

  @computed
  get isGsmOffline(): boolean {
    return Boolean(
      this.isMSTConnected &&
        this.isMasterConnectedViaGSM &&
        !this.isGSMConnected &&
        this.masterVersionFirmware &&
        this.masterVersionFirmware >= 3.24
    );
  }

  @computed
  get isPlcOffline(): boolean {
    return Boolean(
      this.isMSTConnected && this.isMasterConnectedViaPLC && !this.isPlcHub
    );
  }

  @computed
  get isGsmOnline(): boolean {
    return Boolean(
      this.isMSTConnected && this.isMasterConnectedViaGSM && this.isGSMConnected
    );
  }

  @computed
  get isPlcOnline(): boolean {
    return Boolean(
      this.isMSTConnected && this.isMasterConnectedViaPLC && this.isPlcHub
    );
  }

  @computed
  get isGsmBadSignalQuality(): boolean {
    return Boolean(
      this.isGsmOnline &&
        this.masterDevice &&
        this.masterDevice.gsmSignalQuality !== SignalQuality.CORRECT &&
        this.masterVersionFirmware &&
        this.masterVersionFirmware >= 3.24
    );
  }

  @computed
  get isPlcBadSignalQuality(): boolean {
    return Boolean(
      this.isPlcOnline &&
        this.adapterCplMylight &&
        this.adapterCplMylight.signalQualityIsBad &&
        this.masterVersionFirmware &&
        this.masterVersionFirmware >= 3.25
    );
  }

  @computed
  get isGsmSignalInfoDisplayed(): boolean {
    return Boolean(
      this.isGsmOnline &&
        this.masterVersionFirmware &&
        this.masterVersionFirmware >= 3.24
    );
  }

  @computed
  get isPlcSignalInfoDisplayed(): boolean {
    return Boolean(
      this.isPlcOnline &&
        this.masterVersionFirmware &&
        this.masterVersionFirmware >= 3.25
    );
  }

  @computed
  get isPlcBestPracticeWarningDisplayed(): boolean {
    return Boolean(
      this.isPlcOnline &&
        this.masterVersionFirmware &&
        this.masterVersionFirmware === 3.24
    );
  }

  /**
   * return device that are not the one in parameter that is also a global consumption  counter
   * @param device
   */
  @action.bound
  public getPossibleOtherDeviceWithGlobalConsumption(
    device: Device
  ): Device | undefined {
    return this.activeDevices
      .filter(d => d !== device)
      .find(d => d.isGlobalConsumption);
  }

  @action.bound
  public setCustomerCountry(country: string): void {
    this.customerCountry = country;
  }

  @action.bound
  public setCustomerTenant(tenant: string): void {
    this.customerTenant = tenant;
  }

  @action.bound
  public setPVInstallationId(id: string): void {
    this.storedPVInstallationId = id;
  }

  @action.bound
  public setMstCode(mst: string) {
    this.mstCode = mst;
  }

  @action.bound
  public setEditedMST(mst: string) {
    this.editedMST = mst;
  }

  @action.bound
  public resetMasterTypeInternetConnectionInfo() {
    this.masterTypeInternetConnection = undefined;
    this.savedMasterTypeInternetConnection = undefined;
    this.simSerialNumber = '';
    this.savedSimSerialNumber = '';
    this.simRouterReference = '';
    this.savedSimRouterReference = '';
    this.masterHasSimNb = false;
  }

  @action.bound
  public setSubmittedMstCode(mstCode?: string) {
    this.submittedMST = mstCode;
  }

  @action.bound
  public setUcgEdited(ucg: UCG) {
    this.ucgEdited = ucg;
  }

  @action.bound
  public setMasterTypeInternetConnection(masterTypeInternetConnection: string) {
    this.masterTypeInternetConnection = masterTypeInternetConnection;
    if (this.isMasterConnectedViaGSM && !this.isSimRouterReferenceValid) {
      this.setSimRouterReference(VALID_SIM_ROUTER_REFERENCE);
    }
  }

  @action.bound
  public setSavedMasterTypeInternetConnection(
    savedMasterTypeInternetConnection?: string
  ) {
    this.savedMasterTypeInternetConnection = savedMasterTypeInternetConnection;
    if (savedMasterTypeInternetConnection !== 'gsm') {
      this.setSimSerialNumber('');
      this.setSimRouterReference('');
    }
  }

  @action.bound
  public setSimRouterReference(simRouterReference: string) {
    if (simRouterReference === VALID_SIM_ROUTER_REFERENCE) {
      this.simRouterReference = simRouterReference;
    } else {
      this.simRouterReference = '';
    }
  }

  @action.bound
  public setSimSerialNumber(simSerialNumber: string) {
    this.simSerialNumber = simSerialNumber;
  }

  @computed
  public get simSerialNumberError(): string | undefined {
    return numberValidator(Number(this.simSerialNumber));
  }

  @computed
  public get simRouterReferenceError(): string | undefined {
    return this.isMasterConnectedViaGSM
      ? simRouterReferenceValidator(this.simRouterReference)
      : undefined;
  }

  @computed
  public get isSimRouterReferenceValid(): boolean {
    return this.isMasterConnectedViaGSM
      ? this.simRouterReferenceError === undefined
      : Boolean(this.masterTypeInternetConnection);
  }

  @computed
  public get isMasterTypeInternetConnectionSavedIsValid(): boolean {
    return (
      !!this.savedMasterTypeInternetConnection &&
      this.savedMasterTypeInternetConnection !== ''
    );
  }

  @computed
  public get isMasterTypeInternetConnectionChanged(): boolean {
    return (
      this.masterTypeInternetConnection !==
      this.savedMasterTypeInternetConnection
    );
  }

  @computed
  public get isSimRouterReferenceChanged(): boolean {
    return this.simRouterReference !== this.savedSimRouterReference;
  }
  @computed
  public get isSimSerialNumberChanged(): boolean {
    return this.simSerialNumber !== this.savedSimSerialNumber;
  }

  @computed
  public get isSimRouterReferenceSavedIsValid(): boolean {
    return this.savedSimRouterReference === VALID_SIM_ROUTER_REFERENCE;
  }

  @computed
  get canUpdateMasterConnectionType(): boolean {
    const additionalInfoError =
      this.simSerialNumberError || this.simRouterReferenceError;
    const additionalGsmInfoIsChanged =
      (this.isSimRouterReferenceChanged || this.isSimSerialNumberChanged) &&
      !additionalInfoError;
    const additionalGsmInfoIsProvided =
      this.simRouterReference && this.simSerialNumber && !additionalInfoError;
    return Boolean(
      (this.isMasterTypeInternetConnectionChanged &&
        !this.isMasterConnectedViaGSM) ||
        (this.isMasterTypeInternetConnectionChanged &&
          this.isMasterConnectedViaGSM &&
          additionalGsmInfoIsProvided) ||
        (this.isMasterConnectedViaGSM && additionalGsmInfoIsChanged)
    );
  }

  @computed
  get canSaveDevices(): boolean {
    return (
      !this.hasDisconnectedModbusDevice &&
      this.activeDevices.length > 0 &&
      this.activeDevices.some(e => e.deviceType === MST)
    );
  }

  @computed
  get isEditedUCGInvalid(): boolean {
    return Boolean(
      !this.isEditedUCGEmpty && (this.ucgEdited && this.ucgEdited.length !== 14)
    );
  }
  @computed
  get isEditedUCGEmpty(): boolean {
    return Boolean(typeof this.ucgEdited === 'string' && this.ucgEdited === '');
  }

  public getEditedDevice = ({
    ethMac,
    plcMac
  }: IDeviceId): Device | undefined => {
    const actualEthMac = ethMac === 'none' ? undefined : ethMac;
    return this.devices.find(
      device => device.plcMac === plcMac && device.ethMac === actualEthMac
    );
  };

  public getMutationAndToggleVisibility = (device: Device) => {
    return this.toMutationPayload.map((current: EPPDevice) => {
      if (
        current.ethMac === device.ethMac &&
        current.plcMac === device.plcMac
      ) {
        return {
          ...current,
          preConfig: {
            ...current.preConfig,
            isDeleted: !current.preConfig.isDeleted
          }
        };
      }
      return current;
    });
  };

  @action.bound
  public resetInscriptionStore = () => {
    this.devices = [];
    this.ucg = undefined;
    this.ucgEdited = undefined;
    this.id = '';
    this.mstCode = undefined;
    this.editedMST = '';
    this.submittedMST = undefined;
    this.masterTypeInternetConnection = undefined;
    this.gridType = undefined;
    inscriptionStepStore.resetStore();
    inscriptionError.reset();
  };

  @action.bound
  public refreshDeviceList = (
    data: ICustomerAndDevicesResponse,
    customerId: string,
    pvInstallationId: string,
    currentStep?: string
  ) => {
    if (data.devicesConfig && data.getOneToOneCustomerData) {
      this.setCustomerCountry(data.getOneToOneCustomerData.customer.country!);
      this.setCustomerTenant(
        data.getOneToOneCustomerData.pVInstallation.tenant!.name!
      );
      const {
        gridType,
        masterTypeInternetConnection,
        simSerialNumber,
        simRouterReference
      } = data.getOneToOneCustomerData.electricInstallation;
      this.initialise(
        data.devicesConfig,
        gridType,
        masterTypeInternetConnection,
        simSerialNumber,
        simRouterReference
      );
      inscriptionError.initErrorsFromConfigFileResponse(data.devicesConfig);

      if (
        (!this.isMSTRegistered || inscriptionError.mstHasError) &&
        (!currentStep ||
          (currentStep && disabledMenuItemsForNoMST.includes(currentStep)))
      ) {
        history.push(getMasterPath({ customerId, pvInstallationId }));
      }

      if (inscriptionError.mstNotConnected) {
        if (currentStep && currentStep === 'master') {
          inscriptionError.hideModal(false);
        }
      } else {
        inscriptionError.hideModal(true);
      }

      inscriptionStepStore.setStepFormInstallationInformation({
        customerAndInstallationInfo: data.getOneToOneCustomerData,
        msbStatus: data.getMSBStatus,
        getRegistrationSteps: data.getRegistrationSteps
      });
      if (data && data.getMSBStatus) {
        batteryStore.setMSBStatus(data.getMSBStatus);
      }
    } else {
      inscriptionError.initErrorsFromApolloError(
        new ApolloError({ errorMessage: 'unknown' })
      );
    }
  };
}

const inscription: InscriptionStore = new InscriptionStore();
export default inscription;
