import React from 'react';
import { translate, TranslationFunction } from 'react-i18next';
import { EnedisGridInjectionStatus } from '../constants';
import './EnedisGridInjectionStatusText.scss';

interface IProps {
  date: string;
  status: EnedisGridInjectionStatus;
  t: TranslationFunction;
}

const EnedisGridInjectionStatusText = ({ t, date, status }: IProps) => {
  const getText = `${t(`enedisGridInjection.${status}`)} ${date}`;
  return (
    <span
      className={`enedis-grid-injection-text enedis-grid-injection-text--${status}`}
    >
      {getText}
    </span>
  );
};
export default translate(['MySmartBattery'])(EnedisGridInjectionStatusText);
