import { Col, FormGroup } from 'reactstrap';
import React from 'react';
import { safeMobxInject } from '../../../../../../stores/storeInjectionHelpers';
import { withApollo, WithApolloClient } from 'react-apollo';
import { translate, TranslationFunction } from 'react-i18next';
import { ActivationMySmartBatteryEnedisGridInjection } from '../../../../../../stores/my_smart_battery/activationMySmartBatteryEnedisGridInjection';
import { ActivationMySmartBatteryMylight } from '../../../../../../stores/my_smart_battery/activationMySmartBatteryMylight';
import { RouteComponentProps, withRouter } from 'react-router';
import LoadingButton from '../../../../../../ui/Button/LoadingButton/LoadingButton';

interface IStoresProps {
  activationMsbMylight: ActivationMySmartBatteryMylight;
  activationMsbEnedisGridInjection: ActivationMySmartBatteryEnedisGridInjection;
}

interface IProps extends RouteComponentProps<IMatchParams> {
  t: TranslationFunction;
}

interface IMatchParams {
  id: string;
}

const EnedisGridInjectionRequestedButton = safeMobxInject<IStoresProps, IProps>(
  stores => ({
    activationMsbMylight: stores.activationMySmartBatteryMylight,
    activationMsbEnedisGridInjection:
      stores.activationMySmartBatteryEnedisGridInjection
  }),
  ({
    t,
    activationMsbMylight,
    activationMsbEnedisGridInjection,
    client,
    match: {
      params: { id: customerId }
    }
  }: WithApolloClient<IStoresProps & IProps>) => {
    const canRequestGridInjection =
      activationMsbMylight.isMsbMylightFormValid &&
      activationMsbEnedisGridInjection.isEnedisGridInjectionFormValid;

    const requestGridInjection = async () => {
      await activationMsbEnedisGridInjection.requestEnedisGridInjection(
        client,
        customerId
      );
    };

    return (
      <FormGroup row style={{ paddingLeft: '15px' }}>
        <Col sm={12} md={6} />
        <Col
          sm={12}
          md={6}
          style={{ display: 'flex', flexDirection: 'row-reverse' }}
        >
          <LoadingButton
            loading={
              activationMsbEnedisGridInjection.isLoadingrequestEnedisGridInjection
            }
            disabled={!canRequestGridInjection}
            onClick={() => requestGridInjection()}
          >
            {t('validate')}
          </LoadingButton>
        </Col>
      </FormGroup>
    );
  }
);

export default translate(['MySmartBattery'])(
  withRouter(withApollo(EnedisGridInjectionRequestedButton))
);
