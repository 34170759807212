import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { IAllStores } from '../../../stores/allStores.model';
import { RouteComponentProps, withRouter } from 'react-router';
import { IDeviceId } from '../../../stores/inscription/inscriptionStore';
import { Device } from '../../../stores/inscription/device';
import { getDevicePath } from '../routingHelper';
import IsolatedComponent from '../../../ui/IsolatedComponent/IsolatedComponent';
import { TranslationFunction } from 'i18next';

import DeviceDetails from './DeviceDetails';
import { Row } from 'reactstrap';
import DeviceHelp from './DeviceHelp';

export interface IDeviceDetailsProps extends RouteComponentProps<IMatchParams> {
  roomTypes?: string[];
  gridType?: string;
  getEditedDevice?: ({  }: IDeviceId) => Device | undefined;
  t?: TranslationFunction;
}

export interface IMatchParams {
  customerId: string;
  pvInstallationId: string;
  plcMac: string;
  ethMac: string;
}

@inject(({ inscription: inscriptionStore }: IAllStores) => {
  return {
    getEditedDevice: inscriptionStore.getEditedDevice,
    gridType: inscriptionStore.getGridTypeId
  };
})
@observer
class DeviceDetail extends React.Component<IDeviceDetailsProps> {
  public render() {
    const {
      match: {
        params: {
          ethMac: ethMacFromRouter,
          plcMac: plcMacFromRouter,
          pvInstallationId,
          customerId
        }
      },
      history,
      getEditedDevice,
      gridType
    } = this.props;
    const editedDevice: Device | undefined = getEditedDevice!({
      ethMac: ethMacFromRouter,
      plcMac: plcMacFromRouter
    });
    if (!editedDevice) {
      history.push(
        getDevicePath({
          pvInstallationId,
          customerId
        })
      );
      return <div />;
    }
    const {
      getDeviceFullName,
      getDeviceFullRoomName,
      isDeviceHasDescription
    } = editedDevice;
    const properties = { gridType };

    return (
      <IsolatedComponent
        title={getDeviceFullName}
        subtitle={getDeviceFullRoomName}
        xl={11}
        lg={11}
      >
        <Row className={'device-details-container'}>
          <DeviceDetails />
          {isDeviceHasDescription && <DeviceHelp {...properties} />}
        </Row>
      </IsolatedComponent>
    );
  }
}

export default withRouter(DeviceDetail);
