import * as React from 'react';
import { translate, TranslationFunction } from 'react-i18next';
import BadgeStatus from '../BadgeStatus/BadgeStatus';
import { HelpButton } from '../HelpButton/HelpButton';
import SmoothList from '../SmoothList/SmoothList';
import ContinueSubscriptionButton from '../../components/redirect/ContinueSubscriptionButton';
import {
  addressFormatter,
  dateFormatter,
  shouldDisplayContinueInstallation
} from '../../utils/tools';
import TenantLogo from '../../assets/images/tenant/TenantLogo';
import { get } from 'lodash';
import { RouteComponentProps, withRouter } from 'react-router';
import './customerList.scss';
import classnames from 'classnames';
import {
  MSB_FINISHED,
  MSB_PENDING,
  NO_MSB
} from '../../constants/BATTERY_STATUS';
import {
  CustomerListItem,
  CustomerListSortableColumn
} from '../../graphql/graphql.schema';

interface IProps extends RouteComponentProps<any> {
  customers: CustomerListItem[];
  t: TranslationFunction;
  Store?: any;
  canFetchMore: boolean;
  fetchMore: () => void;
}

const CustomerList = ({
  t,
  customers,
  history,
  fetchMore,
  canFetchMore
}: IProps) => {
  return (
    <div className="full-page-list">
      <SmoothList
        placeholder={t('customerEmptyList')}
        rowHeight={63}
        canFetchMore={canFetchMore}
        fetchMore={fetchMore}
        onRowClick={item => {
          history.push(`/customers/${item.id}/identity`);
        }}
        columns={[
          {
            sortingKey: CustomerListSortableColumn.tenantName,
            title: t('tenant'),
            columnKey: 'tenant',
            bootstrapOption: { xs: 6, md: 1 },
            render: item => (
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  minWidth: '30px'
                }}
              >
                <TenantLogo tenant={get(item, 'tenant')} />
              </span>
            )
          },
          {
            sortingKey: CustomerListSortableColumn.lastName,
            title: t('name'),
            columnKey: 'Name',
            bootstrapOption: { xs: 6, md: 3 },
            render: item => (
              <div className="font-weight-bold">
                {item.lastName &&
                  item.lastName !== null &&
                  get(item, 'lastName', '').toUpperCase()}{' '}
                {item.firstName}
              </div>
            )
          },
          {
            title: t('address'),
            columnKey: 'Address',

            bootstrapOption: { xs: 6, md: 2 },
            render: item => addressFormatter(item)
          },
          {
            sortingKey: CustomerListSortableColumn.msbStatus,
            title: t('MySmartBattery:battery'),
            columnKey: 'msbStatus',
            bootstrapOption: { xs: 6, md: 2 },
            render: item => (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <span
                  className={classnames(
                    'badge-status',
                    item.msbStatus === NO_MSB && 'secondary',
                    item.msbStatus === MSB_PENDING && 'warning',
                    item.msbStatus === MSB_FINISHED && 'success'
                  )}
                >
                  <label>{t(`msbStatus_${item.msbStatus}`)}</label>
                </span>
              </div>
            )
          },
          {
            sortingKey: CustomerListSortableColumn.status,
            title: t('registrationStatus'),
            columnKey: 'status',
            bootstrapOption: { xs: 6, md: 2 },
            render: (item, index) => (
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <BadgeStatus status={item.status} />
                <HelpButton
                  message={t(`RegistrationStatusToolTips:${item.status}`, {
                    link: `/customers/${item.id}/systems`
                  })}
                  uniqueKey={`customer-list-${index}`}
                />
              </span>
            )
          },
          {
            sortingKey: CustomerListSortableColumn.registrationDate,
            title: t('registrationDate'),
            columnKey: 'registrationDate',
            bootstrapOption: { xs: 6, md: 2 },
            render: item => (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                {shouldDisplayContinueInstallation(item.status) ? (
                  <ContinueSubscriptionButton
                    customerId={item.id}
                    pvInstallationId={item.pvInstallationId}
                    srsRegistrationStatus={item.status}
                  />
                ) : (
                  dateFormatter(item.registrationDate)
                )}
              </div>
            )
          }
        ]}
        dataSource={customers}
      />
    </div>
  );
};

export default translate([
  'Customer',
  'RegistrationStatusToolTips',
  'MySmartBattery'
])(withRouter(CustomerList));
