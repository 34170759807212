import React from 'react';
import { translate, TranslationFunction } from 'react-i18next';

interface IProps {
  t: TranslationFunction;
}

const BadGSMSignalQualityWarning = ({ t }: IProps) => {
  return (
    <>
      {t('Master:receptionSignalNotOpti')}
      <ul>
        <li>{t('Master:extendingAntenna')}</li>
        <li>{t('Master:extendingModem')}</li>
        <li>{t('Master:reorientAntenna')}</li>
        <li>{t('Master:contactSupport')}</li>
      </ul>
      {t('Master:warningIntermittentConnexion')}
    </>
  );
};

export default translate('Master')(BadGSMSignalQualityWarning);
