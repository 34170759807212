import React from 'react';
import { IDeviceIcon } from '../DeviceIcons';

const DeepFryer = (props: IDeviceIcon) => (
  <svg
    width="3rem"
    height="3rem"
    viewBox="0 0 20000 20000"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    color="black"
    {...props}
  >
    <path
      d="M12930 5803H6996c-150 0-249 100-249 249v5436c0 150 100 249 249 249h2568v885h-249c-50 0-100 50-100 100v1384c0 50 50 100 100 100h1135c50 0 100-50 100-100v-1384c0-50-50-100-100-100h-249v-885h2718c150 0 249-100 249-249V6052c0-100-87-249-237-249zm-2182 5436v-885h935l-199 885h-736zm-2369 0l-199-885h835v885h-636zM7195 6838l150 698h-150v-698zm199 898l249 1085h-449V7736h199zm5286-199h-150l150-698v698zm0 199v1085h-449l249-1085h199zm-648 1085h-1284V7736h1533l-249 1085zm-1484 0H9214V7736h1334v1085zm0 249v1085H9214V9070h1334zm-1533-249H7831l-249-1085h1434v1085zm-1820 249h499l249 1085h-748V9070zm686 0h1135v1085h-885l-249-1085zm1334 1284h1334v885H9215v-885zm1533-199V9070h1234l-249 1085h-985zm1434-1085h499v1085h-748l249-1085zm399-2768c0 50 0 50 0 0l-249 1184h-1533V6302h1783zm-2032 0v1184H9215V6302h1334zM9016 7536H7582l-249-1184h1733v1184h-50zm-1820 2818h785l199 885h-985v-885zm4501 885l199-885h785v885h-985z"
      fill={props.color}
    />
  </svg>
);

export default DeepFryer;
