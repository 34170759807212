import React from 'react';
import MediaQuery from 'react-responsive';
import {
  Button,
  Col,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import {
  BREAK_POINT_SAFE_MIN_WIDTH,
  BREAK_POINT_WIDTH
} from '../../../../constants/BREAK_POINT_WIDTH';
import { safeMobxInject } from '../../../../stores/storeInjectionHelpers';
import ListItem from '../../../../ui/DataDisplay/List/ListItem';
import { FormInput } from '../../../../ui/FormInput/FormInput';
import LoadingButton from '../../../../ui/Button/LoadingButton/LoadingButton';
import { translate, TranslationFunction } from 'react-i18next';
import { EnphaseStore } from '../../../../stores/partnerships/enphaseStore';

import enphaseScreenshot1Desktop from '../../../../assets/images/devices/enphase/installer-id-screenshot-1-desktop.png';
import enphaseScreenshot2Desktop from '../../../../assets/images/devices/enphase/installer-id-screenshot-2-desktop.png';
import enphaseScreenshot1Mobile from '../../../../assets/images/devices/enphase/installer-id-screenshot-1-mobile.png';
import enphaseScreenshot2Mobile from '../../../../assets/images/devices/enphase/installer-id-screenshot-2-mobile.png';

const enphaseScreenshotDesktop = [
  enphaseScreenshot1Desktop,
  enphaseScreenshot2Desktop
];
const enphaseScreenshotMobile = [
  enphaseScreenshot1Mobile,
  enphaseScreenshot2Mobile
];

interface IStoreProps {
  enphaseStore: EnphaseStore;
}

interface IProps {
  t: TranslationFunction;
  loading?: boolean;
  registerEnphaseDevice: () => Promise<any>;
}

const EnphaseEnvoyView = safeMobxInject<IStoreProps, IProps>(
  allStores => ({
    enphaseStore: allStores.enphaseStore
  }),
  ({ enphaseStore, t, loading, registerEnphaseDevice }) => {
    const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
    const [modalStep, setModalStep] = React.useState<1 | 2>(1);

    const openModal = () => setIsModalOpen(true);

    const closeModal = () => {
      setIsModalOpen(false);
      setModalStep(1);
    };

    return (
      <ListItem className="custom-list-item envoy-container">
        <Col>
          <div className="envoy-title">
            <span>{t('EnphasePartnership:envoyRegistered')}</span>
          </div>
          <Col className="envoy-buttons">
            <Button
              color="secondary"
              active={enphaseStore.envoyRegistered}
              onClick={() => enphaseStore.updateEnvoyChoice(true)}
            >
              {t('Common:yes')}
            </Button>
            <Button
              color="secondary"
              active={!enphaseStore.envoyRegistered}
              onClick={() => enphaseStore.updateEnvoyChoice(false)}
            >
              {t('Common:no')}
            </Button>
          </Col>
        </Col>
        <Form
          className="form-wrapper"
          onSubmit={e => {
            e.preventDefault();
          }}
        >
          <FormInput
            id="envoy"
            name={t('EnphasePartnership:envoySerialNumber')}
            type="text"
            value={enphaseStore.envoySerialNumber}
            error={
              enphaseStore.envoySerialNumber &&
              enphaseStore.validateEnvoySerialNumber
            }
            onChange={value => enphaseStore.updateEnvoySerialNumber(value)}
            required
          />
          <div className="input-top-interval">
            {enphaseStore.envoyRegistered ? (
              <FormInput
                id="system-id"
                name={t('EnphasePartnership:enphaseSystemId')}
                type="text"
                value={enphaseStore.enphaseSystemId}
                error={
                  enphaseStore.enphaseSystemId &&
                  enphaseStore.validateEnphaseSystemId
                }
                onChange={value => enphaseStore.updateEnphaseSystemId(value)}
                required
              />
            ) : (
              <>
                <FormInput
                  id="company-id"
                  name={t('EnphasePartnership:enphaseInstallerId')}
                  type="text"
                  value={`${enphaseStore.enphaseInstallerId || ''}`}
                  error={
                    enphaseStore.enphaseInstallerId &&
                    enphaseStore.validateEnphaseInstallerId
                  }
                  onChange={value => {
                    enphaseStore.updateEnphaseInstallerId(parseInt(value, 10));
                  }}
                  addon={
                    <Button
                      className={'enphase-hint-button'}
                      size="sm"
                      onClick={openModal}
                    >
                      {t('EnphasePartnership:whereIsInstallerID.title')}
                    </Button>
                  }
                />
                <p className={'text-primary'}>
                  {t('EnphasePartnership:enphaseInstallerIdDescription')}
                </p>
              </>
            )}
          </div>
          <Row className="form-buttons">
            <Col>
              <LoadingButton
                loading={loading}
                onClick={registerEnphaseDevice}
                disabled={
                  !enphaseStore.validateForm || !enphaseStore.useOldEmail
                }
              >
                {t('Partnerships:connect')}
              </LoadingButton>
            </Col>
          </Row>
          <span className="header-error-container bottom">
            {enphaseStore.envoyErrors.map(e => (
              <span className="header-error" key={e}>
                {e}
              </span>
            ))}
          </span>

          <Modal
            isOpen={isModalOpen}
            toggle={closeModal}
            centered={true}
            size={'lg'}
          >
            <ModalHeader toggle={closeModal}>
              {t('EnphasePartnership:whereIsInstallerID.title')}
            </ModalHeader>

            <ModalBody>
              <h6 className="mb-4 font-weight-bold text-primary">
                {t('Common:step')} {modalStep}
              </h6>

              <p
                dangerouslySetInnerHTML={{
                  __html: t(
                    `EnphasePartnership:whereIsInstallerID.step${modalStep}`
                  )
                }}
              />

              <MediaQuery
                className="enphase-info-mobile img-fluid"
                query={`(max-width: ${BREAK_POINT_WIDTH})`}
              >
                <img src={enphaseScreenshotMobile[modalStep - 1]} alt="" />
              </MediaQuery>
              <MediaQuery
                className="enphase-info-desktop img-fluid"
                query={`(min-width: ${BREAK_POINT_SAFE_MIN_WIDTH})`}
              >
                <img src={enphaseScreenshotDesktop[modalStep - 1]} alt="" />
              </MediaQuery>
            </ModalBody>

            <ModalFooter>
              {modalStep === 1 && (
                <Button color="primary" onClick={() => setModalStep(2)}>
                  {t('Common:nextStep')}
                </Button>
              )}

              {modalStep === 2 && (
                <>
                  <Button color="secondary" onClick={() => setModalStep(1)}>
                    {t('Common:prevStep')}
                  </Button>
                  <Button color="primary" onClick={closeModal}>
                    {t('Common:agree')}
                  </Button>
                </>
              )}
            </ModalFooter>
          </Modal>
        </Form>
      </ListItem>
    );
  }
);

export default translate()(EnphaseEnvoyView);
