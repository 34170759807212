import React from 'react';
import {
  CustomField,
  Field
} from '../../../../../ui/DataDisplay/FieldList/FieldList';
import { translate, TranslationFunction } from 'react-i18next';

import '../battery.scss';
import { Battery } from '../../../../../stores/battery';
import { safeMobxInject } from '../../../../../stores/storeInjectionHelpers';
import MediaQuery from 'react-responsive';
import {
  BREAK_POINT_SAFE_MIN_WIDTH,
  BREAK_POINT_WIDTH
} from '../../../../../constants/BREAK_POINT_WIDTH';
import DesktopMsbStatusList from './DesktopMsbStatusList';
import MobileMsbStatusList from './MobileMsbStatusList';
import moment from 'moment';
import { HelpButton } from '../../../../../ui/HelpButton/HelpButton';

interface IProps {
  t: TranslationFunction;
  inscriptionSummary?: boolean;
}

interface IStoresProps {
  battery: Battery;
}

const DATE_FORMAT = 'DD/MM/YYYY';

const BatterySummaryPlaneteOui = safeMobxInject<IStoresProps, IProps>(
  stores => ({
    battery: stores.battery
  }),
  ({ t, battery, inscriptionSummary }: IProps & IStoresProps) => {
    const {
      getMSBStatuses,
      pdlNumber,
      caeNumber,
      enedisConnectNumber,
      consuelNumber,
      consuelDeclaredPower,
      registeredSerialNumbers,
      volume,
      sendingARPEDate,
      sendingCONSUELDate,
      isMsbMylight,
      msbAcFilledStatus,
      getMsbType
    } = battery;

    const validateDateOfAcFilledStatus = msbAcFilledStatus
      ? msbAcFilledStatus.validateAt
      : undefined;

    return (
      <React.Fragment>
        {!inscriptionSummary && (
          <>
            <MediaQuery query={`(max-width: ${BREAK_POINT_WIDTH})`}>
              <CustomField name={t('status')}>
                <MobileMsbStatusList
                  msbStatuses={getMSBStatuses}
                  msbType={getMsbType}
                />
              </CustomField>
            </MediaQuery>
            <MediaQuery query={`(min-width: ${BREAK_POINT_SAFE_MIN_WIDTH})`}>
              <CustomField name={t('status')} />
              <DesktopMsbStatusList
                msbStatuses={getMSBStatuses}
                msbType={getMsbType}
              />
            </MediaQuery>
          </>
        )}
        <Field name={t('Installation:pdl')} text={pdlNumber} />
        {!isMsbMylight && <Field name={t('caeNumber')} text={caeNumber} />}
        {isMsbMylight && (
          <>
            <Field name={t('enedisConnect')} text={enedisConnectNumber} />
            <Field name={t('consuelNumber')} text={consuelNumber} />
            <Field
              name={t('consuelDeclaredPower')}
              text={consuelDeclaredPower}
            />
            <Field
              name={t('sendDateCONSUEL')}
              text={
                sendingCONSUELDate
                  ? `${moment(sendingCONSUELDate).format(DATE_FORMAT)}`
                  : t('notSending')
              }
            />
          </>
        )}
        <CustomField name={t(`serialNumbers_${getMsbType}`)}>
          {registeredSerialNumbers.map((serialNumber: string) => (
            <div
              style={{
                paddingRight: 0,
                paddingLeft: 0,
                marginBottom: registeredSerialNumbers.length > 1 ? '10px' : 0
              }}
              key={serialNumber}
            >
              <span className="field text">{serialNumber}</span>
            </div>
          ))}
        </CustomField>
        <Field name={t('volume')} text={volume && `${volume} kWh`} />
        {!isMsbMylight && (
          <>
            <Field
              name={t('sendDateARPE')}
              text={
                sendingARPEDate
                  ? `${moment(sendingARPEDate).format(DATE_FORMAT)}`
                  : t('notSending')
              }
            />
            <Field
              name={t('sendDateEnergyContract')}
              text={
                validateDateOfAcFilledStatus
                  ? `${moment(validateDateOfAcFilledStatus).format(
                      DATE_FORMAT
                    )}`
                  : t('contractNotSending')
              }
              addon={
                validateDateOfAcFilledStatus ? (
                  ''
                ) : (
                  <HelpButton
                    message={t(`contractNotSendingInformationMessage`)}
                  />
                )
              }
            />
          </>
        )}
      </React.Fragment>
    );
  }
);

export default translate(['MySmartBattery'])(BatterySummaryPlaneteOui);
