import React from 'react';
import { IDeviceIcon } from '../DeviceIcons';

const Refrigerator = (props: IDeviceIcon) => (
  <svg
    width="3rem"
    height="3rem"
    viewBox="0 0 20000 20000"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    color="black"
    {...props}
  >
    <g id="prefix__Layer_x0020_1">
      <g id="prefix__refrigerator">
        <path
          style={{ fill: `${props.color}` }}
          d="M13735 16986H6450c-135 0-337-135-337-337V4323c0-135 135-337 337-337h7301c135 0 337 135 337 337v12394c-84 135-219 270-354 270zm-7032-590h6694V4592H6703v11804z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M13668 9162H6383c-135 0-337-135-337-337 0-135 135-337 337-337h7285c135 0 337 135 337 337s-135 337-337 337zM12353 7577c-135 0-337-135-337-337V5722c0-135 135-337 337-337s337 135 337 337v1518c-84 202-219 337-337 337zM12353 12012c-135 0-337-135-337-337v-1518c0-135 135-337 337-337s337 135 337 337v1518c-84 202-219 337-337 337z"
        />
      </g>
    </g>
  </svg>
);

export default Refrigerator;
