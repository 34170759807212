import { AppInsights } from 'applicationinsights-js';
import { onError } from 'apollo-link-error';
import { setContext } from 'apollo-link-context';
import history from './history';
import * as jwt from 'jsonwebtoken';
import moment from 'moment';
import gql from 'graphql-tag';
import ApolloClient from '../node_modules/apollo-client/ApolloClient';
import { ApolloQueryResult } from 'apollo-client';
import { createUploadLink } from 'apollo-upload-client';

function parseJwt(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
}

export const authLink = setContext((_, { headers }: { headers: any }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('Auth-Token');
  try {
    AppInsights.setAuthenticatedUserContext(parseJwt(token || '').data.id);
  } catch {
    // tslint:disable:no-console
    console.warn('No token');
  }
  // return the headers to the context so httpLink can read them
  return {
    headers: token
      ? {
          ...headers,
          'Auth-Token': token ? `${token}` : ''
        }
      : { ...headers }
  };
});

const httpLink = (url: string) => {
  return createUploadLink({
    uri: url
  });
};

export const errorReporterLink = onError(({ networkError, graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path, extensions }) => {
      if (message.errorCode === 'invalidToken') {
        console.warn('Token expired or token error, redirecting to login');
        history.push('/login');
      }
      AppInsights.trackException(
        new Error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
    });
  }
  if (networkError) {
    AppInsights.trackException(new Error(`[Network error]: ${networkError}`));
  }
});
export const createApolloLink = (url: string) =>
  errorReporterLink.concat(authLink.concat(httpLink(url)));

/**
 * Renew jwt token if it is more than a day old
 * @param apolloClient
 */
export const handleTokenRenew = async (apolloClient: ApolloClient<any>) => {
  const token = localStorage.getItem('Auth-Token');

  if (token) {
    const decode = jwt.decode(token);
    if (decode && typeof decode === 'object' && decode.iat) {
      if (
        token &&
        moment(decode.iat * 1000).isBefore(moment().startOf('day'))
      ) {
        const { data }: ApolloQueryResult<any> = await apolloClient.query({
          query: gql`
            query renew {
              renew {
                authToken
              }
            }
          `
        });

        if (data && data.renew && data.renew.authToken) {
          localStorage.setItem('Auth-Token', data.renew.authToken);
        }
      }
    }
  }
};
