import React, { useState } from 'react';
import { Col, Modal, ModalHeader, Row } from 'reactstrap';
import './deviceItem.scss';
import { translate, TranslationFunction } from 'react-i18next';
import { ListTitle } from '../../../ui/DataDisplay/List/ListHeader';
import List from '../../../ui/DataDisplay/List/ListWrapper';
import ListPlaceHolder from '../../../ui/DataDisplay/List/ListPlaceHolder';
import { RouteComponentProps, withRouter } from 'react-router';
import DeviceItem from './DeviceItem';
import RefreshDeviceConfig from './RefreshDeviceConfig';
import { Device } from '../../../stores/inscription/device';
import { getFunctionPath } from '../routingHelper';
import { ISnackBarParams } from '../../../stores/userFeedback/snackbarStore';
import SaveInscriptionQueryButton from '../SaveInscriptionButton/SaveInscriptionQueryButton';
import ListItem from '../../../ui/DataDisplay/List/ListItem';
import ShowAlert from '../../../ui/ShowAlert/ShowAlert';
import { safeMobxInject } from '../../../stores/storeInjectionHelpers';
import { useScrollToInscriptionStep } from '../../../utils/hooks';
import { InscriptionStep } from '../../../stores/inscription/inscriptionStepsStore';
import UcgPreconfigurationQuery from './UcgPreconfigurationQuery';
import { UCG } from '../../../stores/inscription/inscriptionStore';
import UcgPreconfigurationWizardFeatureInfo from '../ucg/UcgPreconfigurationWizardFeatureInfo';
import RefreshDevicesInscriptionQuery from '../RefreshDevicesInscriptionQuery';
import SelectDevicesConfigurationModeModal from './SelectDevicesConfigurationModeModal/SelectDevicesConfigurationModeModalQuery';

interface IMatchParams {
  customerId: string;
  pvInstallationId: string;
}

interface IProps extends RouteComponentProps<IMatchParams> {
  t: TranslationFunction;
}

interface IStoresProps {
  showSnackBar: (params: ISnackBarParams) => void;
  hasDisconnectedModbusDevice: boolean;
  canSaveDevices: boolean;
  activeNotModbusDevices: Device[];
  deletedDevices: Device[];
  activeModbusDevices: Device[];
  ucgInquire: boolean;
  getUCGDone: boolean;
  isMstG3: boolean;
}

const DevicesView = safeMobxInject<IStoresProps, IProps>(
  stores => ({
    activeNotModbusDevices: stores.inscription.activeNotModbusDevices,
    deletedDevices: stores.inscription.deletedDevices,
    activeModbusDevices: stores.inscription.activeModbusDevices,
    hasDisconnectedModbusDevice: stores.inscription.hasDisconnectedModbusDevice,
    canSaveDevices: stores.inscription.canSaveDevices,
    ucgInquire: stores.inscription.ucgInquire,
    getUCGDone: stores.inscriptionSteps.getUCGDone,
    isMstG3: stores.inscription.isMstG3,
    showSnackBar: stores.snackBar.showSnackBar
  }),
  ({
    t,
    history,
    activeNotModbusDevices,
    deletedDevices,
    activeModbusDevices,
    isMstG3,
    showSnackBar,
    hasDisconnectedModbusDevice,
    canSaveDevices,
    ucgInquire,
    getUCGDone,
    match: {
      params: { customerId, pvInstallationId }
    }
  }: IProps & IStoresProps) => {
    useScrollToInscriptionStep(InscriptionStep.DEVICES);

    const [
      isUcgPreconfigurationToolModalOpened,
      toggleUcgPreconfigurationToolModal
    ] = useState(false);

    const activeWiredModbusDevices = activeModbusDevices.filter(
      device => !device.isWirelessModbus
    );

    const activeWirelessModbusDevices = activeModbusDevices.filter(
      device => device.isWirelessModbus
    );

    const closeUcgPreconfigurationToolModal = (): void => {
      toggleUcgPreconfigurationToolModal(false);
    };
    const openUcgPreconfigurationToolModal = (): void => {
      toggleUcgPreconfigurationToolModal(true);
    };
    const isUcgPreconfigurationToolCanBeUsed = Boolean(
      activeNotModbusDevices.length !== 0 && ucgInquire && getUCGDone
    );
    return (
      <RefreshDevicesInscriptionQuery>
        <Col>
          <Row>
            <Col lg={6} className={'mobile-helper'}>
              <List fluid>
                <ListTitle
                  text={t('Devices:listTitle')}
                  children={<RefreshDeviceConfig />}
                />
                {activeNotModbusDevices.length === 0 ? (
                  <ListPlaceHolder text={t('Devices:emptyDoneList')} />
                ) : (
                  <>
                    {isUcgPreconfigurationToolCanBeUsed && (
                      <ListItem className={'start-configuration-assistance'}>
                        <UcgPreconfigurationWizardFeatureInfo additionalInfo />
                        <div
                          className={'start-configuration-assistance-container'}
                          onClick={openUcgPreconfigurationToolModal}
                        >
                          <span>
                            {t('Devices:startConfigurationAssistance')}
                          </span>
                        </div>
                      </ListItem>
                    )}
                    <ListItem
                      className={'registration-device-item add-new-device'}
                    >
                      <div
                        className={'add-device-button-container'}
                        onClick={() => {
                          if (isMstG3) {
                            history.push(
                              `/devices_inscription/${customerId}/devices/${pvInstallationId}/add`
                            );
                          } else {
                            history.push(
                              `/devices_inscription/${customerId}/devices/${pvInstallationId}/add/plc`
                            );
                          }
                        }}
                      >
                        <i className={'icon-add add-device'} />
                        <span>{t('Devices:addDeviceTitle')}</span>
                      </div>
                    </ListItem>
                    {activeNotModbusDevices.map((d: Device) => {
                      if (!d.plcMac!.includes('RS')) {
                        return <DeviceItem device={d} key={d.getKey} />;
                      }
                    })}
                  </>
                )}
              </List>

              {activeWiredModbusDevices.length > 0 && (
                <List fluid>
                  <ListTitle text={t('Devices:modbusDevicesTitle')} />
                  {activeWiredModbusDevices.map(device => (
                    <DeviceItem device={device} key={device.getKey} />
                  ))}
                </List>
              )}

              {activeWirelessModbusDevices.length > 0 && (
                <List fluid>
                  <ListTitle text={t('Devices:wmodbusDevicesTitle')} />
                  {activeWirelessModbusDevices.map(device => (
                    <DeviceItem device={device} key={device.getKey} />
                  ))}
                </List>
              )}

              <List fluid>
                {hasDisconnectedModbusDevice && (
                  <ShowAlert message={t('Devices:disconnectedModbusDevice')} />
                )}
                <ListItem className={'save-button'}>
                  <SaveInscriptionQueryButton
                    disabled={!canSaveDevices}
                    onCompleted={() => {
                      showSnackBar({
                        text: t('Success:updateDevices'),
                        type: 'success'
                      });
                      history.push(
                        getFunctionPath({
                          pvInstallationId,
                          customerId
                        })
                      );
                    }}
                  />
                </ListItem>
              </List>
            </Col>
            {deletedDevices &&
              deletedDevices.length > 0 && (
                <Col lg={6} className={'mobile-helper'}>
                  <List fluid>
                    <ListTitle text={t('Devices:todoListTitle')} />
                    {deletedDevices.map((d: Device) => {
                      return <DeviceItem isDeleted device={d} key={d.getKey} />;
                    })}
                  </List>
                </Col>
              )}
          </Row>
        </Col>
        {isUcgPreconfigurationToolCanBeUsed && (
          <SelectDevicesConfigurationModeModal
            openUcgPreconfigurationToolModal={openUcgPreconfigurationToolModal}
          />
        )}
        <Modal
          className="ucg-preconfiguration-modal"
          size="xl"
          isOpen={isUcgPreconfigurationToolModalOpened}
          centered
        >
          <ModalHeader>{t('Devices:configurationAssistance')}</ModalHeader>
          {isUcgPreconfigurationToolModalOpened && (
            <UcgPreconfigurationQuery
              closeModal={closeUcgPreconfigurationToolModal}
            />
          )}
        </Modal>
      </RefreshDevicesInscriptionQuery>
    );
  }
);

export default translate(['Common', 'Devices', 'RoomName', 'UCG'])(
  withRouter(DevicesView)
);
