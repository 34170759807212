import React, { useEffect } from 'react';
import { Mutation, MutationResult, Query, QueryResult } from 'react-apollo';

import gql from 'graphql-tag';
import { PPDeviceConfigFile } from '../../../graphql/graphql.schema';
import { translate, TranslationFunction } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ISnackBarParams } from '../../../stores/userFeedback/snackbarStore';
import { IInscriptionNavigationRouterParamProps } from '../InscriptionNavigation';
import './finishInstallation.scss';
import {
  DEVICE_INSCRIPTION_FIELD_QUERY,
  GET_CUSTOMER_AND_DEVICE_CONFIG
} from '../DevicesInscription';
import { safeMobxInject } from '../../../stores/storeInjectionHelpers';
import { useScrollToInscriptionStep } from '../../../utils/hooks';
import { InscriptionStep } from '../../../stores/inscription/inscriptionStepsStore';
import FinishInstallationView from './FinishInstallationView';
import { DELAY_REFRESH_CUSTOMER_AND_DEVICE_CONFIG } from '../../../stores/inscription/inscriptionStore';
import i18n from '../../../translations/i18n';

interface IMutationResponse {
  completeRegistrationOfDevices: PPDeviceConfigFile;
}

export interface IProps
  extends RouteComponentProps<IInscriptionNavigationRouterParamProps> {
  t: TranslationFunction;
}

interface IStoresProps {
  isDevicesHasNotAssignFunction: boolean;
  showSnackBar: (params: ISnackBarParams) => void;
  haveRightToAccessMSB: boolean;
}

const FINALIZE_INSTALLATION = gql`
    mutation completeRegistrationOfDevices($pvInstallationId: String!, $lang: String!) {
        completeRegistrationOfDevices(id: $pvInstallationId, lang: $lang) {
            ${DEVICE_INSCRIPTION_FIELD_QUERY}
        }
    }
`;

const FinishInstallationQuery = safeMobxInject<IStoresProps, IProps>(
  stores => ({
    showSnackBar: stores.snackBar.showSnackBar,
    isDevicesHasNotAssignFunction:
      stores.inscription.isDevicesHasNotAssignFunction,
    haveRightToAccessMSB: stores.battery.haveRightToAccessMSB
  }),
  ({
    t,
    match: {
      params: { pvInstallationId, customerId }
    },
    showSnackBar,
    history
  }: IProps & IStoresProps) => {
    useScrollToInscriptionStep(InscriptionStep.SUMMARY);

    return (
      <Query
        query={GET_CUSTOMER_AND_DEVICE_CONFIG}
        variables={{ customerId, pvInstallationId, lang: i18n.language }}
        fetchPolicy={'no-cache'}
        pollInterval={DELAY_REFRESH_CUSTOMER_AND_DEVICE_CONFIG}
      >
        {({ stopPolling, startPolling, refetch }: QueryResult) => {
          return (
            <Mutation
              mutation={FINALIZE_INSTALLATION}
              onError={() => {
                refetch({ customerId, pvInstallationId });
                startPolling(DELAY_REFRESH_CUSTOMER_AND_DEVICE_CONFIG);
                showSnackBar({
                  text: t('Errors:finishInscription'),
                  type: 'error'
                });
              }}
              onCompleted={(data: IMutationResponse) => {
                const success =
                  data &&
                  data.completeRegistrationOfDevices &&
                  !data.completeRegistrationOfDevices.error;

                if (success) {
                  showSnackBar({
                    text: t('Success:finishInscription'),
                    type: 'success'
                  });
                  history.push(`/customers/${customerId}/systems`);
                } else {
                  startPolling(DELAY_REFRESH_CUSTOMER_AND_DEVICE_CONFIG);
                  showSnackBar({
                    text: t('Errors:finishInscription'),
                    type: 'error'
                  });
                }
              }}
            >
              {(
                finalizeInstallation,
                { called, data, loading }: MutationResult<IMutationResponse>
              ) => {
                return (
                  <FinishInstallationView
                    data={data}
                    finalizeInstallation={finalizeInstallation}
                    loading={loading}
                    stopPolling={stopPolling}
                  />
                );
              }}
            </Mutation>
          );
        }}
      </Query>
    );
  }
);

export default translate(['StepError', 'DevicesInscription, RegistrationMenu'])(
  withRouter(FinishInstallationQuery)
);
