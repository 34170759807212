import React, { ReactNode } from 'react';
import AlarmClock from './icons/AlarmClock';
import AsokaElectricCounter from './icons/AsokaElectricCounter';
import AsokaRedPlug from './icons/AsokaRedPlug';
import Aspirator from './icons/Aspirator';
import Battery from './icons/Battery';
import BBQHotStone from './icons/BBQHotStone';
import CoffeeMachine from './icons/CoffeeMachine';
import Computer from './icons/Computer';
import CompositeDevice from './icons/CompositeDevice';
import DeepFryer from './icons/DeepFryer';
import Dishwasher from './icons/Dishwasher';
import Dryer from './icons/Dryer';
import DVDPlayer from './icons/DVDPlayer';
import ElectricCar from './icons/ElectricCar';
import Enphase from './icons/Enphase';
import FoodProcessor from './icons/FoodProcessor';
import Freezer from './icons/Freezer';
import GameConsole from './icons/GameConsole';
import HairDryer from './icons/HairDryer';
import Heater from './icons/Heater';
import HomeCinemaSystem from './icons/HomeCinemaSystem';
import Iron from './icons/Iron';
import Kettle from './icons/Kettle';
import Lamp from './icons/Lamp';
import MicrowaveOven from './icons/MicrowaveOven';
import OtherDeviceType from './icons/OtherDeviceType';
import Oven from './icons/Oven';
import Phone from './icons/Phone';
import Printer from './icons/Printer';
import ProductionCounter from './icons/ProductionCounter';
import Pump from './icons/Pump';
import Radio from './icons/Radio';
import Razor from './icons/Razor';
import Refrigerator from './icons/Refrigerator';
import RefrigeratorFreezer from './icons/RefrigeratorFreezer';
import Speaker from './icons/Speaker';
import StereoSystem from './icons/StereoSystem';
import TadoAC from './icons/TadoAC';
import TadoHeater from './icons/TadoHeater';
import Toaster from './icons/Toaster';
import TV from './icons/TV';
import Ventillator from './icons/Ventillator';
import VideoProjector from './icons/VideoProjector';
import WashingDryingMachine from './icons/WashingDryingMachine';
import WashingMachine from './icons/WashingMachine';
import WaterHeater from './icons/WaterHeater';
import WineCellar from './icons/WineCellar';
import WiserHrelay from './icons/WiserHrelay';
import WiserThermostat from './icons/WiserThermostat';
import WiserValve from './icons/WiserValve';

export interface IDeviceIcon {
  type: string;
  color?: string;
  height?: string;
  width?: string;
}

export default class DeviceIcon extends React.Component<IDeviceIcon> {
  private static defaultProps = {
    color: '#009dbf',
    type: 'other_device_type'
  };
  public render() {
    const DeviceIcons: { [key: string]: ReactNode } = {
      alarm_clock: <AlarmClock {...this.props} />,
      asoka_electric_counter: <AsokaElectricCounter {...this.props} />,
      asoka_red_plug: <AsokaRedPlug {...this.props} />,
      aspirator: <Aspirator {...this.props} />,
      battery: <Battery {...this.props} />,
      bbq_hot_stone: <BBQHotStone {...this.props} />,
      coffee_machine: <CoffeeMachine {...this.props} />,
      composite_device: <CompositeDevice {...this.props} />,
      computer: <Computer {...this.props} />,
      deep_fryer: <DeepFryer {...this.props} />,
      dishwasher: <Dishwasher {...this.props} />,
      dryer: <Dryer {...this.props} />,
      dvd_player: <DVDPlayer {...this.props} />,
      electric_car: <ElectricCar {...this.props} />,
      enphase: <Enphase {...this.props} />,
      food_processor: <FoodProcessor {...this.props} />,
      freezer: <Freezer {...this.props} />,
      game_console: <GameConsole {...this.props} />,
      hair_dryer: <HairDryer {...this.props} />,
      heater: <Heater {...this.props} />,
      home_cinema_system: <HomeCinemaSystem {...this.props} />,
      iron: <Iron {...this.props} />,
      kettle: <Kettle {...this.props} />,
      lamp: <Lamp {...this.props} />,
      microwave_oven: <MicrowaveOven {...this.props} />,
      other_device_type: <OtherDeviceType {...this.props} />,
      production_counter_ignored: <OtherDeviceType {...this.props} />,
      oven: <Oven {...this.props} />,
      phone: <Phone {...this.props} />,
      printer: <Printer {...this.props} />,
      production_counter: <ProductionCounter {...this.props} />,
      pump: <Pump {...this.props} />,
      radio: <Radio {...this.props} />,
      razor: <Razor {...this.props} />,
      refrigerator: <Refrigerator {...this.props} />,
      refrigerator_freezer: <RefrigeratorFreezer {...this.props} />,
      speaker: <Speaker {...this.props} />,
      stereo_system: <StereoSystem {...this.props} />,
      tado_ac: <TadoAC {...this.props} />,
      tado_heater: <TadoHeater {...this.props} />,
      toaster: <Toaster {...this.props} />,
      tv: <TV {...this.props} />,
      ventilator: <Ventillator {...this.props} />,
      video_projector: <VideoProjector {...this.props} />,
      washing_drying_machine: <WashingDryingMachine {...this.props} />,
      washing_machine: <WashingMachine {...this.props} />,
      water_heater: <WaterHeater {...this.props} />,
      wine_cellar: <WineCellar {...this.props} />,
      wiser_hrelay: <WiserHrelay {...this.props} />,
      wiser_thermostat: <WiserThermostat {...this.props} />,
      wiser_valve: <WiserValve {...this.props} />
    };
    return DeviceIcons[this.props.type] || DeviceIcons.other_device_type;
  }
}
