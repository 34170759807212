import React, { ReactNode } from 'react';
import MySmartBatteryHeader from './my_smart_battery_header/MySmartBatteryHeader';
import './mySmartBatteryListPage.scss';
import classnames from 'classnames';

interface IProps {
  children?: ReactNode;
  isReadOnly?: boolean;
}

const MySmartBatteryListPage = ({ children, isReadOnly }: IProps) => {
  return (
    <div className={'my-smart-battery-container'}>
      <MySmartBatteryHeader isReadOnly={isReadOnly} />
      <div
        className={classnames('my-smart-battery-inner-container', {
          'read-only': isReadOnly
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default MySmartBatteryListPage;
