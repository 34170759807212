import { Mutation, MutationResult } from 'react-apollo';

import React from 'react';
import { inject, observer } from 'mobx-react';
import { translate, TranslationFunction } from 'react-i18next';
import LoadingButton from '../../../ui/Button/LoadingButton/LoadingButton';
import gql from 'graphql-tag';
import { EPPDevice, PPDeviceConfigFile } from '../../../graphql/graphql.schema';
import { IAllStores } from '../../../stores/allStores.model';
import { ApolloError } from 'apollo-client';
import { DEVICE_INSCRIPTION_FIELD_QUERY } from '../DevicesInscription';
import i18n from '../../../translations/i18n';

const SAVE_INSCRIPTION = gql`
  mutation saveConfig($devices: [EPPDevice], $pvInstallationId: String!, $lang: String) {
    updateDeviceConfig(devices: $devices, pvInstallationId: $pvInstallationId, lang: $lang) {
     ${DEVICE_INSCRIPTION_FIELD_QUERY}
    }
  }
`;

interface IProps {
  t: TranslationFunction;
  disabled?: boolean;
  onCompleted?: () => void;
  pvInstallationId?: string;
  devices?: EPPDevice[];
  initErrorsFromGQLError?: (error: ApolloError) => void;
}

interface IResponse {
  saveConfig: PPDeviceConfigFile;
}

interface IParams {
  devices: EPPDevice[];
  pvInstallationId: string;
  lang: string;
}

const SaveInscriptionQueryButton = inject(
  ({ inscription, inscriptionError }: IAllStores) => {
    return {
      pvInstallationId: inscription.id,
      devices: inscription.toMutationPayload,
      initErrorsFromGQLError: inscriptionError.initErrorsFromApolloError
    };
  }
)(
  observer(
    ({
      disabled,
      onCompleted,
      t,
      pvInstallationId,
      devices,
      initErrorsFromGQLError
    }: IProps) => {
      return (
        <Mutation<IResponse, IParams>
          mutation={SAVE_INSCRIPTION}
          onCompleted={() => onCompleted && onCompleted()}
          onError={e => initErrorsFromGQLError!(e)}
        >
          {(save, { called, error, loading }: MutationResult<IResponse>) => {
            return (
              <LoadingButton
                disabled={disabled}
                onClick={() => {
                  save({
                    variables: {
                      pvInstallationId: pvInstallationId!,
                      devices: devices!,
                      lang: i18n.language
                    }
                  });
                }}
                done={called && !error && !loading}
                loading={loading}
              >
                {t('save')}
              </LoadingButton>
            );
          }}
        </Mutation>
      );
    }
  )
);

export default translate(['Common'])(SaveInscriptionQueryButton);
