import gql from 'graphql-tag';
import { inject, observer } from 'mobx-react';
import { Button } from 'reactstrap';
import { Mutation } from 'react-apollo';
import { RouteComponentProps, withRouter } from 'react-router';
import { translate } from 'react-i18next';
import React from 'react';
import { TranslationFunction } from 'i18next';
import { IAllStores } from '../../../../stores/allStores.model';
import { EnphaseStore } from '../../../../stores/partnerships/enphaseStore';

export const REMOVE_DEVICE = gql`
  mutation removeDevice($customerId: String!, $deviceId: String!) {
    removeDevice(customerId: $customerId, deviceId: $deviceId)
  }
`;

export interface IProps extends RouteComponentProps<IMatchParams> {
  enphaseStore?: EnphaseStore;
  t: TranslationFunction;
  deviceId: string;
  target?: string;
}

interface IMatchParams {
  customerId: string;
  pvInstallationId: string;
}

const EnphaseDisconnectButton = inject((allStores: IAllStores) => {
  return {
    enphaseStore: allStores.enphaseStore
  };
})(
  observer(
    ({
      t,
      enphaseStore,
      deviceId,
      target,
      history,
      match: {
        params: { customerId, pvInstallationId }
      }
    }: IProps) => {
      return (
        <Mutation
          mutation={REMOVE_DEVICE}
          onCompleted={async () => {
            await enphaseStore!.resetEnphaseStore();
            await history.push(
              `/devices_inscription/${customerId}/partnership/${pvInstallationId}`
            );
          }}
        >
          {removeDevice => {
            return (
              <Button
                color={'danger'}
                className={'disconnect'}
                onClick={async () => {
                  await removeDevice({
                    variables: { customerId, deviceId }
                  });
                }}
              >
                {t('Common:disconnect')}
              </Button>
            );
          }}
        </Mutation>
      );
    }
  )
);

export default withRouter(
  translate(['Common', 'Partnerships'])(EnphaseDisconnectButton)
);
