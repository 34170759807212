import { default as React } from 'react';

import { translate } from 'react-i18next';
import { TranslationFunction } from 'i18next';
import { inject, observer } from 'mobx-react';
import { IAllStores } from '../../../../stores/allStores.model';
import { EditCustomer } from '../../../../stores/editCustomer/editCustomer';
import { ECustomer, Tenant } from '../../../../graphql/graphql.schema';
import { Col, Container, Form, FormGroup, Input, Row } from 'reactstrap';
import LoadingButton from '../../../../ui/Button/LoadingButton/LoadingButton';
import Spinner from '../../../../ui/Spinner/Spinner';
import { FormInput } from '../../../../ui/FormInput/FormInput';
import { CustomForm } from '../../../../ui/FormInput/CustomForm';
import LoginActionWrapper from '../../../../ui/Wrapper/LoginActionWrapper';
import AddressPicker from '../../../../ui/Inputs/AddressForm/AddressForm';
import BirthDatePicker from '../../../createCustomer/BirthDatePicker';
import Toggle from '../../../../ui/Button/Toggle/Toggle';
import IsolatedComponent from '../../../../ui/IsolatedComponent/IsolatedComponent';
import { FieldList } from '../../../../ui/DataDisplay/FieldList/FieldList';
import { ListTitle } from '../../../../ui/DataDisplay/List/ListHeader';
import ShowAlert from '../../../../ui/ShowAlert/ShowAlert';
import MissingFieldsAlert from '../../../../ui/MissingFormFieldsAlert/MissingFormFieldsAlert';

interface IProps {
  t: TranslationFunction;
  editCustomer?: EditCustomer;
  saveCustomer: (customer: ECustomer) => void;
  saveLoading: boolean;
  loading: boolean;
  done: boolean;
}

@inject((store: IAllStores) => {
  return {
    editCustomer: store.editCustomer
  };
})
@observer
class EditIdentityView extends React.Component<IProps> {
  public render() {
    const editCustomer = this.props.editCustomer!;
    const { saveCustomer, saveLoading, loading, t, done } = this.props;
    return (
      <IsolatedComponent
        title={t('CustomerEdit:title')}
        sm={10}
        md={9}
        lg={7}
        xl={6}
      >
        <Spinner loading={loading}>
          <Container fluid>
            <Form
              className="pt-5 pb-5"
              style={{
                width: '100%'
              }}
              onSubmit={event => {
                event.preventDefault();
              }}
            >
              <FieldList>
                <ListTitle text={t('identity')} />
                <FormInput
                  required
                  id="customerLastName"
                  type="text"
                  name={t('name')}
                  value={editCustomer.lastName || ''}
                  onChange={editCustomer.setLastName}
                  error={
                    editCustomer.lastName &&
                    editCustomer.lastNameError &&
                    t('Common:lastNameError')
                  }
                />
                <FormInput
                  required
                  id="customerFirstName"
                  type="text"
                  name={t('firstName')}
                  value={editCustomer.firstName || ''}
                  onChange={editCustomer.setFirstName}
                  error={
                    editCustomer.firstName &&
                    editCustomer.firstNameError &&
                    t('Common:firstNameError')
                  }
                />
                <CustomForm required name={t('birthDate')}>
                  <BirthDatePicker
                    birthDate={editCustomer.birthDate || new Date()}
                    onChange={a => {
                      editCustomer.setBirthDate(a || undefined);
                    }}
                  />
                </CustomForm>
              </FieldList>
              <FieldList>
                <AddressPicker
                  initToCurrentPos={true}
                  cityError={editCustomer.cityError}
                  addressError={editCustomer.addressError}
                  zipCodeError={editCustomer.postalCodeError}
                  geolocationError={editCustomer.geolocationError}
                  id={editCustomer.id}
                  zipCode={editCustomer.zipCode || ''}
                  address={editCustomer.address || ''}
                  city={editCustomer.city || ''}
                  country={{
                    value: editCustomer.country || 'FR',
                    label: editCustomer.country || 'FR'
                  }}
                  additionalAddress={editCustomer.addressSup || ''}
                  latitude={editCustomer.latitude}
                  longitude={editCustomer.longitude}
                  onAddressUpdate={editCustomer.setAddress}
                  onZipCodeUpdate={editCustomer.setZipCode}
                  onCityUpdate={editCustomer.setCity}
                  onCountryUpdate={editCustomer.setCountry}
                  onLatitudeUpdate={editCustomer.setLatitude}
                  onLongitudeUpdate={editCustomer.setLongitude}
                  onAdditionalAddressUpdate={editCustomer.setAddressSup}
                />
              </FieldList>
              <FieldList>
                <ListTitle text={t('contact')} />
                <CustomForm
                  name={t('Common:phoneRequired')}
                  labelStyle={{ fontSize: '0.95rem' }}
                >
                  <Row>
                    <Col xs={12} sm={6}>
                      <FormInput
                        id="customerMobilePhone"
                        type="tel"
                        name={t('CustomerEdit:mobileNumber')}
                        value={editCustomer.mobileNumber || ''}
                        onChange={editCustomer.setMobileNumber}
                        error={
                          editCustomer.mobileNumber &&
                          editCustomer.mobilePhoneError &&
                          t('Common:phoneError')
                        }
                      />
                    </Col>
                    <Col xs={12} sm={6}>
                      <FormInput
                        id="customerPhone"
                        type="tel"
                        name={t('CustomerEdit:phoneNumber')}
                        value={editCustomer.phoneNumber || ''}
                        onChange={editCustomer.setPhoneNumber}
                        error={
                          editCustomer.phoneNumber &&
                          editCustomer.phoneNumberError &&
                          t('Common:phoneError')
                        }
                      />
                    </Col>
                  </Row>
                </CustomForm>
                <FormInput
                  required
                  id="customerEmail"
                  type="email"
                  name={t('email')}
                  value={editCustomer.email || ''}
                  onChange={editCustomer.setEmail}
                  error={
                    editCustomer.email
                      ? editCustomer.emailTakenError
                        ? t('Common:emailTaken')
                        : editCustomer.emailAddressError
                          ? t('Common:emailAddressError')
                          : undefined
                      : undefined
                  }
                />
                <CustomForm required name={t('emailAlert')}>
                  <Toggle
                    labelTrue={t('Common:yes')}
                    labelFalse={t('Common:no')}
                    onCheckChange={a => {
                      editCustomer.setEmailAlertsEnabled(a);
                    }}
                    checked={editCustomer.emailAlertsEnabled}
                  />
                </CustomForm>
              </FieldList>
              {editCustomer.formIsInvalid && (
                <ShowAlert message={t('StepTodo:installation')} />
              )}
              <MissingFieldsAlert formType={'editCustomer'} />
              <LoginActionWrapper>
                <LoadingButton
                  loading={saveLoading}
                  done={done}
                  onClick={() => {
                    saveCustomer(editCustomer.toCustomerMutation);
                  }}
                  disabled={editCustomer.formIsInvalid}
                >
                  {t('Common:save')}
                </LoadingButton>
              </LoginActionWrapper>
            </Form>
          </Container>
        </Spinner>
      </IsolatedComponent>
    );
  }
}

export default translate(['Customer', 'CustomerEdit', 'Common'])(
  EditIdentityView
);
