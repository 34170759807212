import { default as React } from 'react';

import './IconSpinner.scss';

interface IProps {
  size?: number;
}

export const IconSpinner = ({ size = 20 }: IProps) => (
  <svg
    className={'icon-spinner'}
    width={size}
    height={size}
    viewBox="0 0 512 512"
    data-cy={'loader'}
  >
    <path d="M401.664,110.336C321.27,29.942 190.73,29.942 110.336,110.336L74.981,74.981C174.888,-24.927 337.112,-24.927 437.019,74.981L401.664,110.336Z" />
  </svg>
);
