import gql from 'graphql-tag';
import { Mutation, WithApolloClient } from 'react-apollo';
import React, { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { translate } from 'react-i18next';
import { TranslationFunction } from 'i18next';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import classnames from 'classnames';
import ShowAlert from './../../../../../ui/ShowAlert/ShowAlert';
import { getRandomCharactersString } from './../../../../../utils/tools';
import LoadingButton from './../../../../../ui/Button/LoadingButton/LoadingButton';
import { safeMobxInject } from './../../../../../stores/storeInjectionHelpers';
import { ISnackBarParams } from './../../../../../stores/userFeedback/snackbarStore';
import { MySmartBattery } from './../../../../../stores/my_smart_battery/mySmartBattery';
import { CreationMySmartBattery } from './../../../../../stores/my_smart_battery/creationMySmartBattery';

interface IModalProps extends RouteComponentProps {
  t: TranslationFunction;
  isModalOpen: boolean;
  target: string | HTMLElement;
  toggle: any;
  client: WithApolloClient<any>;
  customerId: string;
}

interface IStoresProps {
  mySmartBattery: MySmartBattery;
  creationMySmartBatteryStore: CreationMySmartBattery;
  showSnackBar: (params: ISnackBarParams) => void;
}

interface IMutationParameters {
  variables: { 
    id: string,
  };
}

export const DELETE_MYLIGHT_MSB = gql`
  mutation deleteMyLightMsb($id: String) {
    deleteMyLightMsb (
      customerId: $id
    )
  }
`;

const MsbDeletionModal = safeMobxInject<IStoresProps, IModalProps>(
  stores => ({
    mySmartBattery: stores.mySmartBattery,
    creationMySmartBatteryStore: stores.creationMySmartBattery,
    showSnackBar: stores.snackBar.showSnackBar,
  }),({
    t,
    isModalOpen, 
    target,
    toggle,
    client,
    customerId,
    mySmartBattery,
    creationMySmartBatteryStore,
    showSnackBar,
  }: IModalProps & IStoresProps) => {
  const [buttonId] = useState<string>(getRandomCharactersString());

  const onDeletionMsbButtonClick = (
    deleteMyLightMsb: (mutationParameters: IMutationParameters) => void
  ) => {
    deleteMyLightMsb({
      variables: {
        id: customerId,
      }
    });
  };

  const AlertMessage: React.ReactNode = <>
    <strong>{ t('MySmartBattery:deletionModalBodyTitle') }</strong>
    <div className={'mt-1'}>
      { t('MySmartBattery:deletionModalBodyContent') }
    </div>
  </>;

  return (
    <Mutation
      mutation={ DELETE_MYLIGHT_MSB }
      onCompleted={() => {
        mySmartBattery.loadMsb(client, customerId);
        creationMySmartBatteryStore.clearCreationMsbMylight();
        showSnackBar({ text: t('MySmartBattery:deletionSuccess'), type: 'success' });
        toggle();
      }}
      onError={ () => {
        showSnackBar({ text: t('Errors:deletionMyLightMsb'), type: 'error' });
      }}
    >
      {(deleteMyLightMsb, { loading }) => {
        return (
          <Modal
            centered
            isOpen={ isModalOpen }
            target={ target }
            className={ 'battery-document-modal' }
            size='md'
          >
            <ModalHeader className={ classnames('p-4') }>
              {t('MySmartBattery:deletionModalTitle')}
            </ModalHeader>

            <ModalBody className={ classnames('p-4') }>
              <ShowAlert 
                type={ 'warning' } 
                message={ AlertMessage } 
                margin={ 0 }
                padding={ 0 } />
            </ModalBody>

            <ModalFooter className={ classnames('p-4') }>
              <Button
                color="secondary"
                className={ 'mr-2' }
                onClick={ toggle }
              >
                {t('MySmartBattery:deletionCancelButton')}
              </Button>

              <LoadingButton
                id={ `button${ buttonId }` }
                loading={ loading }
                className={ classnames(
                  'remove',
                  'remove-msb-button'
                ) }
                onClick={ () => onDeletionMsbButtonClick(deleteMyLightMsb) }
              >
                {t('MySmartBattery:deletionButton')}
              </LoadingButton>
            </ModalFooter>
          </Modal>
        )
      }}
    </Mutation>
  );
});
export default withRouter(
  translate(['MySmartBattery, Common, Errors'])(MsbDeletionModal)
);
