import React from 'react';
import { IDeviceIcon } from '../DeviceIcons';

const WaterHeater = (props: IDeviceIcon) => (
  <svg
    width="3rem"
    height="3rem"
    viewBox="0 0 20000 20000"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    color="black"
    {...props}
  >
    <g id="prefix__layer">
      <g id="prefix__water_heater">
        <path
          style={{ fill: `${props.color}` }}
          d="M13203 5367l-563-164c-1999-800-3623-715-5532 84l-473 164 73-563c164-1291 1566-1808 3166-1808 1527 0 3002 451 3257 1723l73 563zM9873 3716c-963 0-1927 236-2399 727 1600-473 3199-473 4817 0-491-491-1454-727-2418-727z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M11459 16201l-73-236s-343-2021-489-3057l-300 441-199-519c-14-44-235-633-252-656-170 339-376 1137-557 1587l-320-492c-73-164-196-204-268-368-236 1127-473 3103-473 3103l-73 236h-236s-327 0-563-73c-800-236-1127-800-1127-1690l55-7871 30-623 460 422c491 541 726-526 1128-1016 541 1373 875 1851 1653 43l236 676c503 842 575 703 1055-83l295-585 268 585c396 1343 848 395 1418-313l73 9220c0 728-934 1342-1632 1342l-109-73zm-295-4663l164 1176c164 800 408 2526 572 3072 335-149 864-652 864-1072l-20-7855c-552 499-877 413-1252-252-327 400-636 563-963 563-400 0-492-244-656-481-164 236-617 481-944 481s-636-236-800-563c-164 236-400 563-800 563-73 0-228-16-228-89l-73 7289c0 727 349 1051 839 1196h73c164-636 352-2756 588-3647l164-636 400 563c164 164 164 236 236 400 73-236 164-473 236-800l473-1436 327 800c164 473 236 800 400 1127v73l400-473z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M9793 16303c-327 0-636-73-636-400l812-2255 164 800 73-473 400 1127c220 310 267 396 396 648 294 660-627 553-1044 553h-164zm335-1189l-91 473h164c73-164 0-327-73-473z"
        />
      </g>
    </g>
  </svg>
);

export default WaterHeater;
