import * as React from 'react';
import { translate, TranslationFunction } from 'react-i18next';
import { ListTitle } from '../../../ui/DataDisplay/List/ListHeader';
import { RouteComponentProps, withRouter } from 'react-router';
import { FieldList } from '../../../ui/DataDisplay/FieldList/FieldList';
import BatterySummary from '../../customers/installation/battery/info/BatterySummaryPlaneteOui';
import { Col, Row } from 'reactstrap';
import CardBatterySummary from './CardBatterySummary';

interface IProps extends RouteComponentProps<IMatchParams> {
  battery?: any;
  t: TranslationFunction;
}

interface IMatchParams {
  customerId: string;
}

const SummaryBattery = ({ t }: IProps) => (
  <Row>
    <Col>
      <FieldList>
        <ListTitle text={t('battery')} />
        <CardBatterySummary />
      </FieldList>
    </Col>
  </Row>
);

export default withRouter(translate(['MySmartBattery'])(SummaryBattery));
