import { InfoCard } from '../../../../../ui/InfoCard/InfoCard';
import React from 'react';
import { translate, TranslationFunction } from 'react-i18next';

interface IProps {
  t: TranslationFunction;
}

const CardDescriptionInscriptionMySmartBattery = ({ t }: IProps) => {
  return (
    <>
      <InfoCard title={t('addBattery')} stepNumber={1}>
        <div dangerouslySetInnerHTML={{ __html: t('addBatteryInfo') }} />
      </InfoCard>

      <InfoCard title={t('addBatteryELD')} stepNumber={2}>
        <p className="m-0">{t('addBatteryELDInfo')}</p>
      </InfoCard>
    </>
  );
};

export default translate(['MySmartBattery'])(
  CardDescriptionInscriptionMySmartBattery
);
