import './FormInput.scss';
import { Label } from 'reactstrap';
import React, { CSSProperties } from 'react';

interface IProps {
  name?: string;
  id?: string;
  error?: string;
  required?: boolean;
  children: React.ReactNode;
  ref?: (a: any) => any;
  labelStyle?: CSSProperties;
}

export const CustomForm = (formProps: IProps) => {
  const { children, error, name, required, id, labelStyle } = formProps;
  return (
    <div className="input-field custom">
      <Label htmlFor={id} className="input-title" style={labelStyle}>
        {name} {required && '*'}
      </Label>
      <div>{children}</div>
      {error && <label className="error-message">{error}</label>}
    </div>
  );
};
