import { action, computed, observable } from 'mobx';
import {
  EPPDevice,
  EPPDevicePreConfig,
  PPDevice
} from '../../graphql/graphql.schema';
import { CompositeDevice } from './compositeDevice';
import { CMP, FUNCTION_TYPE } from '../devices_inscription/contants';
import { Device } from './device';
import { ISelectValue } from '../../ui/Select/Select';
import {
  getDeviceFunctionOptions,
  sortSelectOptionsAlphabetically
} from '../../utils/tools';
import { t } from 'i18next';

const {
  ASOKA_ELECTRIC_COUNTER,
  COMPOSITE_DEVICE,
  ALARM_CLOCK,
  ASOKA_RED_PLUG,
  ASPIRATOR,
  BATTERY,
  BBQ_HOT_STONE,
  COFFEE_MACHINE,
  COMPUTER,
  DEEP_FRYER,
  DISHWASHER,
  DRYER,
  DVD_PLAYER,
  ELECTRIC_CAR,
  FOOD_PROCESSOR,
  FREEZER,
  GAME_CONSOLE,
  HAIR_DRYER,
  HEATER,
  HOME_CINEMA_SYSTEM,
  IRON,
  KETTLE,
  LAMP,
  MICROWAVE_OVEN,
  OTHER_DEVICE_TYPE,
  OVEN,
  PHONE,
  PRINTER,
  PRODUCTION_COUNTER,
  PRODUCTION_COUNTER_IGNORED,
  PUMP,
  RADIO,
  RAZOR,
  REFRIGERATOR,
  REFRIGERATOR_FREEZER,
  SPEAKER,
  STEREO_SYSTEM,
  TOASTER,
  TV,
  VENTILATOR,
  VIDEO_PROJECTOR,
  WASHING_DRYING_MACHINE,
  WASHING_MACHINE,
  WATER_HEATER,
  WINE_CELLAR
} = FUNCTION_TYPE;

const ctPrioritiedFunctionOptions: string[] = [
  PRODUCTION_COUNTER_IGNORED,
  PRODUCTION_COUNTER,
  ASOKA_ELECTRIC_COUNTER,
  OTHER_DEVICE_TYPE
];
const ctOtherFunctionOptions: string[] = [
  ALARM_CLOCK,
  ASPIRATOR,
  BATTERY,
  BBQ_HOT_STONE,
  COFFEE_MACHINE,
  COMPUTER,
  DEEP_FRYER,
  DISHWASHER,
  DRYER,
  DVD_PLAYER,
  ELECTRIC_CAR,
  FOOD_PROCESSOR,
  FREEZER,
  GAME_CONSOLE,
  HAIR_DRYER,
  HEATER,
  HOME_CINEMA_SYSTEM,
  IRON,
  KETTLE,
  LAMP,
  MICROWAVE_OVEN,
  OVEN,
  PHONE,
  PRINTER,
  PUMP,
  RADIO,
  RAZOR,
  REFRIGERATOR,
  REFRIGERATOR_FREEZER,
  SPEAKER,
  STEREO_SYSTEM,
  TOASTER,
  TV,
  VENTILATOR,
  VIDEO_PROJECTOR,
  WASHING_DRYING_MACHINE,
  WASHING_MACHINE,
  WATER_HEATER,
  WINE_CELLAR
];

export class DeviceCT extends Device {
  @observable public phase?: number = 1;
  @observable public ctPower: number = 0;
  @observable public name?: string = undefined;
  @observable public id?: string = undefined;
  @observable public controlledDeviceType?: string = undefined;
  @observable public controlledDeviceName?: string = undefined;
  @observable public greenPlayEnabled?: boolean = undefined;
  @observable public editedGreenPlayEnabled?: boolean = undefined;

  @computed
  get preConfig(): Partial<EPPDevicePreConfig> {
    return {
      controlledDeviceType:
        this.editedControlledDeviceType || PRODUCTION_COUNTER_IGNORED,
      controlledDeviceName: this.editedControlledDeviceName,
      isDeleted: this.isDeleted,
      compositeId: this.compositeId,
      greenPlayEnabled: this.editedGreenPlayEnabled,
      phase: this.phase
    };
  }

  constructor(device: PPDevice) {
    super(device);
    this.setDeviceType(CMP);
    const phase = device.preConfig.phase;
    this.phase = phase;
    this.ctPower = Number(
      (phase && device.power && device.power[phase - 1]) || 0
    );
  }

  public getMutationPayload(parent: CompositeDevice): EPPDevice {
    return {
      ...parent.payLoadData,
      ...this.payLoadData,
      preConfig: {
        ...parent.preConfig,
        ...this.preConfig
      }
    };
  }

  @computed
  get getDevicePower(): number {
    return Number(this.ctPower);
  }

  @computed
  get getKey(): string {
    return `${this.ethMac}|${this.plcMac}|${this.phase}`;
  }

  @computed
  get getFunctionOptions(): ISelectValue[] {
    const prioritiedOptions = getDeviceFunctionOptions(
      ctPrioritiedFunctionOptions
    );
    const otherOptions = sortSelectOptionsAlphabetically(
      getDeviceFunctionOptions(ctOtherFunctionOptions)
    );

    return [...prioritiedOptions, ...otherOptions];
  }

  @action.bound
  public setEditedGreenPlayEnabled(value: boolean) {
    this.editedGreenPlayEnabled = value;
  }

  @computed
  public get getDeviceFunctionNamePrefix(): string {
    return t('Devices:measureOfPrefix');
  }
}
