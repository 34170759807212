import React, { ReactNode } from 'react';
import CustomersHeader from '../../ui/CustomerHeader/CustomersHeader';
import { RouteComponentProps, withRouter } from 'react-router';
import { inject, observer } from 'mobx-react';
import { IAllStores } from '../../stores/allStores.model';
import { CustomersMode } from '../../stores/customersMode';
import SearchCustomer from '../../stores/search/customers';

interface IProps extends RouteComponentProps<IMatchParams> {
  children: ReactNode;
  searchCustomer?: SearchCustomer;
  customersMode?: CustomersMode;
}

interface IMatchParams {
  current: string;
}

const Customers = inject((allStores: IAllStores) => {
  return {
    searchCustomer: allStores.Store.searchCustomer,
    customersMode: allStores.customersMode
  };
})(
  observer(
    ({
      children,
      history,
      match: {
        params: { current }
      },
      searchCustomer,
      customersMode
    }: IProps) => {
      const { searchQuery, setCustomerSearchQuery } = searchCustomer!;
      const { setMode } = customersMode!;
      return (
        <div style={{ height: '100%' }}>
          <CustomersHeader
            searchQuery={searchQuery}
            searchInputChange={setCustomerSearchQuery}
            toggleView={view => {
              history.push(`/customers/${view}`);
              setMode(view);
            }}
            selected={current === 'map' ? 'map' : 'list'}
          />
          {children}
        </div>
      );
    }
  )
);

export default withRouter(Customers);
