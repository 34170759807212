import { Col, Row, Alert } from 'reactstrap';
import React, { CSSProperties } from 'react';

interface IMessage {
  isShown: boolean;
  text: any;
  type?: 'warning' | 'danger' | 'info';
}

interface IProps {
  messages: IMessage[];
  style?: CSSProperties;
}

const ShowAlerts = ({ messages, style }: IProps) => {
  const visibleMessages = messages.filter(m => m.isShown);
  return (
    (visibleMessages.length > 0 && (
      <Row style={style} className="pt-3">
        {visibleMessages.map(m => (
          <Col lg={12} key={m.text}>
            <Alert color={m.type || 'warning'}>{m.text}</Alert>
          </Col>
        ))}
      </Row>
    )) || <></>
  );
};
export default ShowAlerts;
