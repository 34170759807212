import React from 'react';
import { IDeviceIcon } from '../DeviceIcons';

const CoffeeMachine = (props: IDeviceIcon) => (
  <svg
    width="3rem"
    height="3rem"
    viewBox="0 0 20000 20000"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    color="black"
    {...props}
  >
    <defs>
      <style>{`.prefix__fil0{fill:${props.color}`}</style>
    </defs>
    <g id="prefix__layer">
      <g id="prefix___2098519128160">
        <path
          style={{ fill: `${props.color}` }}
          d="M12627 14817H7594c-357 0-715-300-715-715V5708c0-658 543-1201 1201-1201h4018c658 0 1201 543 1201 1201v8380c-14 429-315 729-672 729zm-5391-1616v901c0 186 114 300 300 300h5033c172 0 300-114 300-300v-901H7235zm5577-429h114V5708c0-415-357-772-829-772H8080c-415 0-844 358-844 772v7064h114V6423c0-114 57-186 172-186h486c57 0 57 0 114 57l1916 1258 1930-1244c57 0 57-57 114-57h486c114 0 172 57 172 186v6349h72v-14zm-5033 0h4676V6609h-243l-1973 1258c-57 57-186 57-243 0L8024 6609h-243v6163z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M10110 13073h-243c-415-57-772-243-1015-601-243-357-358-772-300-1258v-243c0-57 57-114 57-243 57-300-186-415-243-415-57-57-114-114-114-243s114-172 172-172h2817c57 0 114 57 172 114 57 57 0 114 0 172v57c486 114 844 543 844 1087 0 601-543 1144-1144 1144-243 243-601 415-958 486 14 114 14 114-43 114zm-1201-1859c-57 357 0 715 186 1015 186 243 543 415 901 486 357 0 658-186 901-415 57-57 114-57 186-57 415 57 829-300 829-715s-300-715-715-715c-114 0-172-114-172-186v-114H8980c57 114 114 300 57 486v57c-57-14-129 43-129 157zM10239 8940c0 100-86 186-186 186s-186-86-186-186 86-186 186-186 186 86 186 186zM10239 9598c0 100-86 186-186 186s-186-86-186-186 86-172 186-172 186 71 186 172z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M11254 11943c-114 0-172-57-172-186v-601c0-114 57-186 172-186 114 0 172 57 172 186v601c57 114-57 186-172 186z"
        />
      </g>
    </g>
  </svg>
);

export default CoffeeMachine;
