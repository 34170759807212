import React from 'react';

interface IProps {
  height?: number;
  width?: number;
  fill?: string;
  isActive?: boolean;
}

const ACTIVE_MSB_COLOR = '#018fa7';
const INACTIVE_MSB_COLOR = '#afafb7';

export const IconMSB = (props: IProps) => {
  const isMSBActive = props.isActive !== false;
  const style = {
    fill: props.fill
      ? props.fill
      : isMSBActive
        ? ACTIVE_MSB_COLOR
        : INACTIVE_MSB_COLOR
  };
  return (
    <svg
      width={props.width || 45}
      height={props.height || 32}
      x="0px"
      y="0px"
      viewBox="0 0 81.940002 79.639999"
    >
      <g id="g67" transform="translate(-68.91,-32.3)">
        <path
          style={style}
          d="m 101.77,36.12 c -9.82,0 -17.81,7.98 -17.81,17.8 v 0.18 c -3.18,0.92 -6.04,2.81 -8.14,5.4 -2.35,2.9 -3.65,6.55 -3.65,10.27 0,8.7 6.85,15.83 15.45,16.29 0.84,2.18 2.92,5.1 6.56,5.1 0.12,0 0.25,0 0.38,-0.01 0.1,-0.01 0.2,-0.02 0.29,-0.05 v -2.5 c -0.12,-0.03 -0.24,-0.05 -0.36,-0.05 -0.02,0 -0.05,0 -0.07,0 -0.08,0 -0.15,0.01 -0.22,0.01 -3.25,0 -4.3,-3.92 -4.35,-4.09 L 89.6,83.48 h -1.1 c -7.57,0 -13.72,-6.15 -13.72,-13.71 0,-6.41 4.54,-12.04 10.81,-13.39 L 86.71,56.14 86.61,55 c -0.03,-0.38 -0.05,-0.73 -0.05,-1.08 0,-8.37 6.82,-15.19 15.2,-15.19 5.14,0 9.89,2.56 12.71,6.86 l 0.7,1.07 1.08,-0.68 c 1.78,-1.11 3.84,-1.7 5.96,-1.7 2.96,0 5.75,1.14 7.87,3.21 2.12,2.07 3.32,4.83 3.4,7.78 l 0.03,1.11 1.1,0.15 c 5.59,0.74 9.8,5.55 9.8,11.18 0,6.22 -5.06,11.27 -11.27,11.27 l -1.38,0.02 0.09,1.39 c 0.03,0.45 0.16,4.43 -4.17,4.71 -0.22,0.01 -0.42,0.08 -0.59,0.19 v 2.23 c 0.2,0.12 0.43,0.19 0.68,0.19 0.03,0 0.06,0 0.08,0 2.09,-0.13 3.74,-0.88 4.91,-2.22 1.12,-1.29 1.52,-2.85 1.65,-3.93 7.07,-0.63 12.64,-6.59 12.64,-13.83 0,-6.57 -4.66,-12.23 -11,-13.58 -0.33,-3.22 -1.77,-6.21 -4.11,-8.5 -2.61,-2.55 -6.06,-3.96 -9.7,-3.96 -2.2,0 -4.34,0.51 -6.27,1.49 -3.38,-4.45 -8.59,-7.06 -14.2,-7.06"
          id="path65"
        />
        <path
          style={style}
          d="m 99.9,106.78 c -0.43,0 -0.78,-0.35 -0.78,-0.78 V 64.78 C 99.12,64.35 99.47,64 99.9,64 h 21.31 c 0.43,0 0.78,0.35 0.78,0.78 V 106 c 0,0.43 -0.35,0.78 -0.78,0.78 H 99.9 M 113.4,57.8 h -5.67 c -2.07,0 -2.24,3.78 -2.24,3.78 h -5.86 c -1.33,0 -2.42,1.07 -2.42,2.41 v 42.26 c 0,1.34 1.08,2.42 2.42,2.42 h 21.85 c 1.33,0 2.42,-1.08 2.42,-2.42 V 63.99 c 0,-1.34 -1.08,-2.41 -2.42,-2.41 h -6.09 C 115.4,58.16 113.4,57.8 113.4,57.8"
          id="path61"
        />
        {isMSBActive && (
          <path
            style={style}
            d="m 118.06,67.13 h -15.04 c -1.04,0 -1.88,0.84 -1.88,1.88 v 3.1 c 0,1.04 0.84,1.88 1.88,1.88 h 15.04 c 1.04,0 1.88,-0.84 1.88,-1.88 v -3.1 c 0.01,-1.04 -0.84,-1.88 -1.88,-1.88"
            id="path63"
          />
        )}
        <path
          style={style}
          d="m 118.06,77.05 h -15.04 c -1.04,0 -1.88,0.84 -1.88,1.88 v 3.1 c 0,1.04 0.84,1.88 1.88,1.88 h 15.04 c 1.04,0 1.88,-0.84 1.88,-1.88 v -3.1 c 0.01,-1.03 -0.84,-1.88 -1.88,-1.88"
          id="path59"
        />
        <path
          style={style}
          d="m 118.06,86.98 h -15.04 c -1.04,0 -1.88,0.84 -1.88,1.88 v 3.1 c 0,1.04 0.84,1.88 1.88,1.88 h 15.04 c 1.04,0 1.88,-0.84 1.88,-1.88 v -3.1 c 0.01,-1.04 -0.84,-1.88 -1.88,-1.88"
          id="path55"
        />
        <path
          style={style}
          d="m 118.06,96.9 h -15.04 c -1.04,0 -1.88,0.84 -1.88,1.88 v 3.1 c 0,1.04 0.84,1.88 1.88,1.88 h 15.04 c 1.04,0 1.88,-0.84 1.88,-1.88 v -3.1 c 0.01,-1.04 -0.84,-1.88 -1.88,-1.88"
          id="path57"
        />
      </g>
    </svg>
  );
};

export default IconMSB;
