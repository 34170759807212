import React from 'react';

import { inject, observer } from 'mobx-react';
import { Col, Collapse, Container, Row } from 'reactstrap';
import { TranslationFunction } from 'i18next';
import { translate } from 'react-i18next';
import Spinner from '../../../../ui/Spinner/Spinner';
import ListPlaceHolder from '../../../../ui/DataDisplay/List/ListPlaceHolder';
import List from '../../../../ui/DataDisplay/List/ListWrapper';
import { Store } from '../../../../stores';
import classnames from 'classnames';
import './collapse.scss';
import { Diagnostic } from '../../../../graphql/graphql.schema';

export interface IProps {
  t: TranslationFunction;
  diagnostics: Diagnostic[];
  loading: boolean;
  error?: string;
  Store?: Store;
}

interface IState {
  opened: string[];
}

@inject('Store')
@observer
class DiagnosticDetail extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { opened: [] };
  }

  public render() {
    const { t, diagnostics, loading, error, Store: mobix } = this.props;

    mobix!.mainMenu.setTitle(t('menuTitle'));
    return (
      <Spinner loading={loading} error={error}>
        <List fluid={true}>
          {diagnostics.length === 0 ? (
            <ListPlaceHolder text={t('emptyTestDetailsList')} />
          ) : (
            diagnostics.map((diagnostic: Diagnostic) => {
              const hasError =
                diagnostic.codeError && diagnostic.codeError.length > 0;
              const id: string = diagnostic.id || '';
              const opened = this.state.opened.includes(id);
              return (
                <div key={diagnostic.id}>
                  <Row
                    className={classnames('collapse-header', {
                      'collapsible-header': hasError
                    })}
                    key={diagnostic.id}
                    onClick={() => {
                      if (hasError) {
                        const open = this.state.opened;
                        if (open.includes(id)) {
                          this.setState({
                            opened: [...open.filter(u => u !== id)]
                          });
                        } else {
                          this.setState({ opened: [...open, id] });
                        }
                      }
                    }}
                  >
                    <Col xs={2} md={1}>
                      <Row>
                        <Col className="text-center" xs={12} md={12}>
                          {hasError ? (
                            <span className="badge badge-pill badge-error">
                              {t('testErrorLabel')}
                            </span>
                          ) : (
                            <span className="badge badge-pill badge-success">
                              {t('testOkLabel')}
                            </span>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={9} md={10}>
                      <span>{t(`TestTitle:${diagnostic.testId}`)}</span>
                    </Col>
                    <Col xs={1} md={1}>
                      {hasError && (
                        <i className={opened ? 'down-arrow' : 'right-arrow'} />
                      )}
                    </Col>
                  </Row>
                  {hasError && (
                    <Collapse className="collapsible" isOpen={opened}>
                      {diagnostic.codeError &&
                        diagnostic.codeError.map(eCode => (
                          <Container
                            fluid
                            className="slot"
                            key={`${diagnostic.id}${eCode}`}
                          >
                            <Row className="frag">
                              <Col
                                className="text-center error"
                                style={{ fontWeight: 'bold' }}
                                key={`${diagnostic.id}${eCode}`}
                                xs={2}
                              >
                                <div style={{ fontSize: '12px' }}>
                                  {t('DiagnosticSummary:Error')}
                                </div>
                                <div>{eCode.split('_')[0]}</div>
                              </Col>
                              <Col
                                xs={10}
                                style={{
                                  paddingTop: '8px'
                                }}
                              >
                                <div>
                                  {t(`TestErrorMessage:${eCode}`).replace(
                                    '%MAC%',
                                    diagnostic.mac
                                  )}
                                </div>
                              </Col>
                            </Row>
                            <Row className="frag">
                              <Col xs={{ offset: 2, size: 10 }}>
                                <div className="slotTitle">
                                  {t('DiagnosticSummary:Causes')}
                                </div>
                                <div>{t(`TestErrorCauses:${eCode}`)}</div>
                                <div className="slotTitle">
                                  {t('DiagnosticSummary:Solutions')}
                                </div>
                                <div>
                                  {t(`TestErrorSolutions:${eCode}`)
                                    .split('\n')
                                    .map((frag: string) => <div>{frag}</div>)}
                                </div>
                              </Col>
                            </Row>
                          </Container>
                        ))}
                    </Collapse>
                  )}
                </div>
              );
            })
          )}
        </List>
      </Spinner>
    );
  }
}

export default translate([
  'DiagnosticSummary',
  'TestErrorMessage',
  'TestErrorSolutions'
])(DiagnosticDetail);
