import React from 'react';
import { Col, Row } from 'reactstrap';
import { translate, TranslationFunction } from 'react-i18next';
import classnames from 'classnames';

import { RouteComponentProps, withRouter } from 'react-router';
import ListItem from '../../../../ui/DataDisplay/List/ListItem';
import AlertNotificationIcon from '../../../../ui/Notifications/AlertNotificationIcon';
import PlcIcon from '../../../../assets/images/devices/icons/connection/PlcIcon';
import ModBusIcon from '../../../../assets/images/devices/icons/connection/ModBusIcon';
import TenantLogo from '../../../../assets/images/tenant/TenantLogo';
import { Device } from '../../../../graphql/graphql.schema';
import { getDeviceType } from '../../../../utils/deviceHelpers';
import { RS485 } from '../../../../stores/devices_inscription/contants';
import tadoIcon from '../../../../assets/images/tado/logo.svg';
import wiserIcon from '../../../../assets/images/wiser/logo.svg';

interface IProps extends RouteComponentProps {
  device: Device;
  t: TranslationFunction;
}

const SystemDevice = ({ device, t }: IProps) => {
  const isDeviceWithAlert =
    device &&
    device.alerts &&
    device.alerts.length > 0 &&
    device.alerts[0].label &&
    (device.alerts[0].description || device.alerts[0].extendedDescription);

  const isMst = device.type === 'mst';
  const isModbus = getDeviceType(device) === RS485;
  const isEmulatedFromG3 = device.emulatedFromG3 && !isModbus;
  const isDeviceConnected =
    device.status === 'off' ||
    device.status === 'on' ||
    (device.deviceTypeId && device.deviceTypeId.includes('ignored'));

  const getDeviceStatus = () => {
    if (device.deviceTypeId && device.deviceTypeId.includes('ignored')) {
      return t('Customer:ignored');
    }

    if (!device.status || device.status === 'offline') {
      return t('Devices:offline');
    }

    return t('Devices:on');
  };

  const getDeviceIcon = () => {
    const isTado =
      device.deviceTypeId && device.deviceTypeId.toLowerCase().includes('tado');
    const isWiser =
      device.deviceTypeId &&
      device.deviceTypeId.toLowerCase().includes('wiser');

    if (isTado) {
      return <img src={tadoIcon} />;
    }
    if (isWiser) {
      return <img src={wiserIcon} />;
    }
    if (isMst || isEmulatedFromG3) {
      return <TenantLogo />;
    }
    if (isModbus) {
      <ModBusIcon />;
    }
    return <PlcIcon />;
  };

  const getDeviceName = () => {
    const deviceName = t(`DeviceType:${getDeviceType(device)}`);
    if (isModbus && device.modbusReference && device.modbusAddress) {
      return `${deviceName} - ${device.modbusReference} - ${
        device.modbusAddress
      }`;
    }
    return deviceName;
  };

  return (
    <ListItem
      className={classnames('system-device-item')}
      key={device.macSerialNumber}
    >
      <div className={'device-container'}>
        <Col className={classnames('device-info', { modbus: isModbus })}>
          <Col>
            <Row className={'device-title'}>
              <div className={'tenant-logo'}>{getDeviceIcon()}</div>
              <Col>
                <div className="device-name">{getDeviceName()}</div>
                <div>
                  <span
                    className={classnames(
                      'badge',
                      'badge-pill',
                      isDeviceConnected ? 'badge-success' : 'badge-secondary'
                    )}
                  >
                    {getDeviceStatus()}
                  </span>
                </div>
              </Col>
            </Row>
          </Col>
          <Row>
            <Col className={'device-details'}>
              <div>
                <span className="mac">{t('Devices:mac')}:</span>
                <span>{device.macSerialNumber}</span>
              </div>

              {device.activationCode && (
                <div>
                  <span className="activation-code">
                    {t('Devices:activationCode')}:
                  </span>
                  <span>{device.activationCode}</span>
                </div>
              )}

              <div>
                <span className="mac">{t('Devices:room')}:</span>
                <span>{device.roomName}</span>
              </div>
            </Col>
          </Row>
        </Col>
        {isDeviceWithAlert && (
          <span className={'notification-icon alert'}>
            <AlertNotificationIcon
              rightMargin={5}
              icon={'exclamation-circle'}
              alert={device.alerts![0]!}
            />
          </span>
        )}
      </div>
    </ListItem>
  );
};

export default translate(['Common', 'Devices', 'RoomName'])(
  withRouter(SystemDevice)
);
