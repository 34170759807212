import * as React from 'react';
import { inject, observer } from 'mobx-react';
import * as L from 'leaflet';
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet/dist/leaflet.css';
import './CustomersMap.scss';
import RegistrationStatus from '../../constants/REGISTRATION_STATUS';

import history from '../../history';
import { translate } from 'react-i18next';
import { TranslationFunction } from 'i18next';
import { addressFormatter, getAddressLink } from '../../utils/tools';
import { CustomerListItem } from '../../graphql/graphql.schema';

export const getCustomIcon = (color?: string) => {
  const svg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 70">
                    <title>carte_in</title>
                        <path d="M34.92,13.74a17.84,17.84,0,0,0-18,17.63A17.24,17.24,0,0,0,20.48,41.9h0L33.32,59s0,0,0,0a2.18,2.18,0,0,0,.21.22l.11.1,0,0,.08,0a2.1,2.1,0,0,0,.42.22l.15,0a1.71,1.71,0,0,0,.55.09h0a1.7,1.7,0,0,0,.54-.09l.15,0a2.49,2.49,0,0,0,.43-.22l.07,0,0,0,.11-.1a1.45,1.45,0,0,0,.21-.22s0,0,0,0l12.84-17h0a17.25,17.25,0,0,0,3.57-10.53A17.84,17.84,0,0,0,34.92,13.74Zm0,20.63a3,3,0,1,1,3-3A3,3,0,0,1,34.92,34.37Z" style="fill:${color}"/>
  </svg>`;

  const myIconUrl = encodeURI(`data:image/svg+xml,${svg}`).replace('#', '%23');
  const CustomIcon = L.Icon.extend({
    options: {
      iconSize: [40, 40],
      iconUrl: myIconUrl
    }
  });
  return new CustomIcon();
};

interface IPropsType {
  Store?: any;
  customers: CustomerListItem[];
  t: TranslationFunction;
}

@inject('Store')
@observer
class CustomersMap extends React.Component<IPropsType, {}> {
  constructor(props: IPropsType) {
    super(props);
  }

  public componentDidMount() {
    this.extracted();
  }

  public componentWillUnmount() {
    this.props.Store.customersMap.deleteMap();
  }

  public render() {
    return (
      <div id="map-wrapper">
        <div id="customersMap" />
      </div>
    );
  }

  private async extracted() {
    const map = L.map('customersMap').setView([45.696499, 5.015391], 6);
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);
    const markersCluster = L.markerClusterGroup();

    this.props.customers.forEach(c => {
      const {
        id,
        email,
        firstName,
        lastName,
        address,
        phoneNumber,
        mobileNumber,
        status,
        zipCode,
        city
      } = c;
      const customPopup = `
    <div class="customer-pin">
    <div class="customer-pin-header">
    <div class="user-name">${lastName} ${firstName} </div>
    </div>
    <div class="customer-pin-body">
    <div><a href="${`mailto:${email}`}">${email}</a></div>
    ${
      phoneNumber && phoneNumber !== null
        ? `<div><a href="${`tel:${phoneNumber}`}">${phoneNumber}</a></div>`
        : ''
    }
    ${
      mobileNumber && mobileNumber !== null
        ? `<div><a href="${`tel:${mobileNumber}`}">${mobileNumber}</a></div>`
        : ''
    }
    <div><a target="_blank" href=${getAddressLink(
      `${address} ${zipCode} ${city}`
    )}
          >
            ${addressFormatter(c)}
          </a></div>
    <div class="badge-status ${
      status === RegistrationStatus.OK ? 'success' : 'warning'
    }">${this.props.t(status!)}</div>
    </div>
    <div class="customer-pin-footer">
    <button id="${id}" class="btn btn-info customer-pin-btn">${this.props.t(
        'Customer:goToCustomer'
      )}</button>
    </div>
    </div>
    `;

      const customOptions = {
        maxWidth: 500,
        minWidth: 200,
        className: 'customer-pin-popup'
      };
      const latLng = new L.LatLng(c.latitude!, c.longitude!);
      const markerColor = (c.tenant && c.tenant.color) || '#008dbf';
      const marker = new L.Marker(latLng, {
        icon: getCustomIcon(markerColor),
        title: c.firstName
      })
        .bindPopup(customPopup, customOptions)
        .on('click', () => {
          document.getElementById(`${id}`)!.onclick = () => {
            history.push(`/customers/${id}/identity`);
          };
        })
        .on('close', () => {
          document.getElementById(`${id}`)!.onclick = null;
        });
      markersCluster.addLayer(marker);
    });
    map.addLayer(markersCluster);
    this.props.Store.customersMap.setMap(map);

    if (this.props.customers.length > 0) {
      const latLngBounds = this.props.customers.map(customer => {
        return [customer.latitude, customer.longitude];
      });
      const mapBounds = new L.LatLngBounds(latLngBounds as any);
      map.fitBounds(mapBounds);
    }
  }
}

export default translate(['RegistrationStatus', 'Customer'])(CustomersMap);
