import React from 'react';
import { IDeviceIcon } from '../DeviceIcons';

const Ventillator = (props: IDeviceIcon) => (
  <svg
    width="3rem"
    height="3rem"
    viewBox="0 0 20000 20000"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    color="black"
    {...props}
  >
    <g id="prefix__Layer_x0020_1">
      <g id="prefix___1440856476048">
        <path
          style={{ fill: `${props.color}` }}
          d="M10721 15470c1446-162 2711-783 3720-1792 1184-1183 1846-2757 1846-4431s-647-3248-1830-4432c-1184-1184-2755-1836-4429-1836s-3247 652-4431 1836-1835 2757-1835 4431 641 3248 1824 4431c1010 1009 2242 1630 3688 1792v1104H6238v-434h-723v434h-578v578h10121v-578h-4338v-1104zM4142 9247c0-1573 612-3052 1725-4163 1112-1112 2591-1725 4164-1725s3052 612 4164 1725c1112 1112 1725 2591 1725 4164s-612 3052-1725 4163c-1112 1113-2590 1726-4163 1726s-3052-613-4164-1726c-1112-1112-1725-2590-1725-4163z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M9503 9774c141 141 328 218 527 218s386-77 527-218 219-328 219-527-78-386-219-527-328-218-527-218-386 78-527 218c-290 290-291 763 0 1054zM12387 13664c-716 383-1521 587-2357 587s-1640-203-2357-587c-80 28-160 52-242 67 781 454 1671 697 2599 697s1818-243 2599-697c-82-16-163-39-242-67zM14447 11599c29 80 53 161 68 243 925-1600 925-3593 0-5192-16 82-40 163-68 243 780 1465 780 3240 0 4706zM5613 6893c-28-80-52-161-68-243-925 1600-925 3592 0 5192 16-82 40-164 68-243-779-1466-779-3240 0-4706zM10030 4242c836 0 1640 203 2356 587 79-28 160-52 242-67-781-454-1671-697-2598-697s-1817 243-2598 697c82 15 163 39 242 67 716-383 1521-586 2356-586zM10030 5292c520 0 1023 101 1490 290 32-55 64-107 96-154-496-206-1033-314-1586-314s-1090 108-1586 314c31 47 63 99 95 154 467-190 971-290 1490-290zM6367 7757c-55-32-106-63-153-94-418 1011-418 2156 0 3168 47-31 98-62 152-94-386-953-386-2027 0-2979zM13848 7660c-47 31-99 63-154 96 190 467 291 971 291 1491 0 519-100 1023-290 1490 56 33 107 64 154 95 206-496 314-1033 314-1586s-108-1090-314-1586zM10030 13201c-519 0-1023-100-1490-290-32 56-64 107-96 154 496 206 1033 314 1586 314s1090-108 1586-314c-31-47-63-99-96-154-467 190-971 290-1490 290zM10030 12086c-347 0-683-65-998-183-24 55-48 110-71 163 337 128 698 198 1070 198s732-70 1070-198c-24-54-48-108-71-163-315 118-652 183-998 183zM12685 10244c55 24 109 47 163 71 260-688 260-1451 0-2139-54 24-108 48-163 71 240 642 240 1353 0 1996zM8960 6426c24 54 48 108 71 163 315-118 651-183 998-183s683 65 999 183c24-55 47-110 71-163-338-128-698-198-1070-198s-732 70-1069 198zM7375 8248c-55-24-110-48-163-71-260 688-260 1451 0 2139 54-24 108-48 163-71-240-642-240-1353 0-1996z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M7896 11380c-312-312-538-676-685-1064-323 143-616 286-845 420 193 476 482 922 867 1307 380 380 824 671 1306 868 134-229 277-522 421-845-394-149-757-379-1064-686z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M8444 13065c-494-205-947-507-1337-896-395-395-692-852-893-1339-93 61-171 120-226 175-173 173-297 377-375 594 227 427 519 827 878 1186 355 354 753 649 1183 879 218-78 423-202 597-377 54-54 113-131 173-223zM5545 11842c-97 508 51 1052 444 1445 392 392 936 540 1443 444-384-224-743-498-1066-820-327-327-600-687-821-1069zM9172 10104c-133-133-227-290-285-456-468 172-1015 382-1513 597 138 368 352 714 647 1010 291 291 635 508 1010 648 215-498 425-1045 596-1513-169-59-325-155-456-286zM11616 5428c493 205 947 507 1336 896s691 843 896 1336c92-61 168-119 223-173 173-173 298-377 376-594-227-426-519-827-877-1185-355-355-753-649-1183-879-218 78-423 202-597 377-55 54-113 131-173 223zM10887 8389c131 131 227 286 286 455 467-171 1014-382 1512-596-138-368-352-714-647-1010-291-291-635-508-1010-649-215 498-425 1046-597 1513 170 59 325 155 456 286z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M11520 5582c-134 229-277 522-421 844 394 150 757 379 1064 686 312 312 538 676 685 1064 323-144 617-287 846-421-196-483-488-926-868-1306-379-380-823-671-1306-868zM14515 6650c97-507-51-1052-444-1445-392-392-936-540-1443-444 385 224 744 498 1067 821 326 326 599 686 820 1068zM7375 8248c498 215 1045 425 1513 596 58-166 152-323 285-456 130-131 286-227 455-286-172-468-382-1015-597-1513-374 140-719 358-1010 649-296 296-510 642-647 1010zM6214 7663c201-487 498-943 893-1338 389-390 843-691 1337-897-61-92-119-169-174-223-174-175-379-299-597-377-430 230-828 525-1183 879-359 359-651 759-878 1186 78 216 202 420 375 594 55 55 133 114 226 176zM7432 4761c-507-96-1051 52-1443 444-393 393-541 937-444 1445 221-382 494-742 820-1069 323-323 682-597 1066-821z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M7896 7113c307-307 670-537 1064-686-143-323-286-616-420-844-483 196-927 488-1307 868-385 385-674 831-866 1306 229 134 522 277 845 420 146-388 373-752 685-1064zM12629 13732c507 96 1050-52 1442-444 393-393 541-938 444-1445-221 382-494 742-820 1069-323 322-681 596-1066 820zM10888 10104c-131 130-287 226-456 286 172 468 382 1015 597 1513 374-140 718-358 1010-648 296-296 510-642 647-1010-498-215-1044-425-1512-596-59 169-155 325-286 456z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M12164 11380c-307 307-670 537-1064 686 143 323 286 616 420 845 483-196 927-488 1306-868 380-380 672-824 868-1306-229-134-522-278-846-421-146 388-373 752-685 1064z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M14447 11599c-78-217-202-420-375-594-54-55-131-113-223-173-205 494-507 947-896 1337-390 389-843 691-1337 896 61 92 119 168 173 223 175 175 380 299 598 377 430-230 828-525 1182-879 359-359 651-760 878-1186z"
        />
      </g>
    </g>
  </svg>
);

export default Ventillator;
