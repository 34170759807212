export enum MsbStatus {
  ACTIVATED = 'activated',
  CREATED = 'created'
}

export enum EnedisGridInjectionStatus {
  FILL = 'fill',
  IN_PROGRESS = 'inProgress',
  TO_VALIDATE = 'toValidate',
  REQUESTED = 'requested',
  ENABLED = 'enabled'
}
export enum StrasbourgElectricityAdministrativeProcedureStatus {
  FILL = 'fill',
  IN_PROGRESS = 'inProgress',
  TO_VALIDATE = 'toValidate',
  FINISHED = 'finished'
}

export enum EnergyDistributorName {
  ENEDIS = 'ENEDIS',
  ELECTRICITE_STRASBOURG = 'ELECTRICITE_STRASBOURG'
}
