import * as React from 'react';

import gql from 'graphql-tag';
import { RouteComponentProps, withRouter } from 'react-router';
import { observer, inject } from 'mobx-react';
import { Query, QueryResult } from 'react-apollo';
import TadoAuthenticationView from './TadoAuthenticationView';
import { IAllStores } from '../../../../stores/allStores.model';
import { TadoStore } from '../../../../stores/partnerships/tadoStore';

export const GET_TADO_AUTH_URI = gql`
  query getTadoAuthUri($customerId: String!) {
    getTadoAuthUri(customerId: $customerId)
  }
`;

export interface IProps extends RouteComponentProps<IMatchParams> {
  tadoStore?: TadoStore;
}

interface IMatchParams {
  customerId: string;
}

interface IResponse {
  getTadoAuthUri?: string;
}

const TadoAuthenticationQuery = inject(({ tadoStore }: IAllStores) => {
  return {
    tadoStore
  };
})(
  observer(({ tadoStore, match: { params: { customerId } } }: IProps) => {
    return (
      <Query
        query={GET_TADO_AUTH_URI}
        variables={{ customerId }}
        fetchPolicy={'network-only'}
      >
        {({ loading, data, error }: QueryResult<IResponse>) => {
          const tadoUri = data && data.getTadoAuthUri;

          return (
            <TadoAuthenticationView
              loading={loading}
              error={error}
              tadoUri={tadoUri || ''}
            />
          );
        }}
      </Query>
    );
  })
);

export default withRouter(TadoAuthenticationQuery);
