import React from 'react';
import { inject, observer } from 'mobx-react';

import '../partnerships.scss';
import { Col, Row } from 'reactstrap';
import Spinner from '../../../../ui/Spinner/Spinner';
import { PPTadoHome } from '../../../../graphql/graphql.schema';
import { translate, TranslationFunction } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import SelectInput from '../../../../ui/Select/Select';
import List from '../../../../ui/DataDisplay/List/ListWrapper';
import ListItem from '../../../../ui/DataDisplay/List/ListItem';
import TadoDisconnectButton from './TadoDisconnectButton';
import TadoSetHomeButton from './TadoSetHomeButton';
import { IAllStores } from '../../../../stores/allStores.model';
import { TadoStore } from '../../../../stores/partnerships/tadoStore';

export interface IProps extends RouteComponentProps<IMatchParams> {
  tadoStore?: TadoStore;
  t: TranslationFunction;
  tadoHomes: PPTadoHome[];
  loading?: boolean;
}

interface IMatchParams {
  customerId: string;
  pvInstallationId: string;
}

const TadoHomeView = inject(({ tadoStore }: IAllStores) => {
  return {
    tadoStore
  };
})(
  observer(
    ({
      tadoStore,
      match: {
        params: { customerId, pvInstallationId }
      },
      loading,
      tadoHomes,
      t
    }: IProps) => {
      const { updateTadoHome, selectedTadoHome, homeId } = tadoStore!;
      return (
        <Spinner loading={loading}>
          <List fluid={true}>
            <ListItem className="tado-container">
              <Col className="tado-content">
                <Row>
                  <span className="tado-title">
                    {t('TadoPartnership:selectTadoHome')}
                  </span>
                </Row>
                <Row className="tado-home-select">
                  <SelectInput
                    id="tadoHome"
                    name=""
                    options={tadoHomes.map((tadoHome: PPTadoHome) => {
                      return { value: tadoHome.id, label: tadoHome.name };
                    })}
                    value={
                      selectedTadoHome.id !== ''
                        ? {
                            value: selectedTadoHome.id,
                            label: selectedTadoHome.name
                          }
                        : null
                    }
                    placeholder={t('TadoPartnership:tadoHome')}
                    onChange={e => {
                      updateTadoHome({ id: e.value, name: e.label });
                    }}
                  />
                </Row>
                <Row className="tado-button">
                  <TadoSetHomeButton />
                </Row>
                <Row className="tado-button">
                  <TadoDisconnectButton />
                </Row>
              </Col>
            </ListItem>
          </List>
        </Spinner>
      );
    }
  )
);

export default withRouter(
  translate(['TadoPartnership, Partnerships'])(TadoHomeView)
);
