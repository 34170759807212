import React from 'react';

const LogoSVG = () => {
    return (
        <svg width="230" height="119" viewBox="0 0 124 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_12982_61320)">
            <path d="M78.9651 27.9205H76.8534V26.1924H80.8978V36.7521H78.9651V27.9205Z" fill="#17353A"/>
            <path d="M82.2787 33.7184H84.2615C84.423 34.5748 85.0903 35.1712 86.0481 35.1712C87.1847 35.1712 87.8847 34.4276 87.8847 33.1525C87.8847 32.0381 87.1866 31.2792 86.0481 31.2792C85.2519 31.2792 84.6826 31.6825 84.3903 32.2808L82.6691 31.7647L83.3999 26.1943H88.9886V27.9224H84.8942L84.5365 30.4878C84.975 29.9219 85.6404 29.6314 86.4693 29.6314C88.3059 29.6314 89.8329 31.0039 89.8329 33.1506C89.8329 35.4598 88.2732 36.9604 86.0481 36.9604C83.9845 36.9623 82.4575 35.6223 82.2787 33.7184Z" fill="#17353A"/>
            <path d="M91.0675 33.185V29.7614C91.0675 27.4693 92.6272 25.9821 94.8523 25.9821C97.0774 25.9821 98.6371 27.4674 98.6371 29.7614V33.185C98.6371 35.477 97.0774 36.9643 94.8523 36.9643C92.6272 36.9623 91.0675 35.477 91.0675 33.185ZM96.7043 33.185V29.7614C96.7043 28.5188 96.0062 27.7752 94.8523 27.7752C93.6984 27.7752 93.0003 28.5169 93.0003 29.7614V33.185C93.0003 34.4276 93.6984 35.1712 94.8523 35.1712C96.0062 35.1712 96.7043 34.4276 96.7043 33.185Z" fill="#17353A"/>
            <path d="M74.3384 44.302V26.3706H72.3465V45.1357L73.4913 46.2644H88.5274V44.302H74.3384Z" fill="#17353A"/>
            <path d="M103.759 43.1943V34.5759H101.792V43.1943L100.647 44.3192H91.8904V46.2549H100.647L101.792 47.3816V56H103.759V47.3816L104.904 46.2549H113.66V44.3192H104.904L103.759 43.1943Z" fill="#17353A"/>
            <path d="M88.3475 20.3389L82.923 15H73.4913L72.3465 16.1287V22.956H74.3384V16.9624H82.3776L88.3456 22.8361L94.3233 16.9624H101.806V31.2641H103.799V16.1287L102.655 15H93.7799L88.3475 20.3389Z" fill="#17353A"/>
            <path d="M19.0078 28.6804C17.7767 28.6804 16.7767 29.1871 16.202 30.1346C15.6117 29.1871 14.6117 28.6804 13.3961 28.6804C12.6233 28.6804 11.9243 28.8699 11.3612 29.2567C11.0097 28.9144 10.5301 28.7036 10 28.7036V36.9625H11.9515V32.3506C11.9534 31.0318 12.6408 30.4942 13.5923 30.4942C14.5282 30.4942 15.233 31.0492 15.233 32.3564V36.9625H17.1865V32.3564C17.1865 31.0492 17.8758 30.4942 18.8098 30.4942C19.7612 30.4942 20.4505 31.0338 20.4505 32.3564V36.9625H22.402V32.3564C22.404 29.8252 20.9263 28.6804 19.0078 28.6804Z" fill="#17353A"/>
            <path d="M30.0623 32.9945C30.0623 34.497 29.2098 35.2647 28.0118 35.2647C26.7807 35.2647 25.9613 34.5299 25.9613 32.9945V28.8951H24.0098V32.9945C24.0098 35.87 25.7807 37.0786 27.6506 37.0786C28.7166 37.0786 29.569 36.6048 30.0623 35.723V36.7363C30.0623 38.3045 29.3069 38.9427 27.9787 38.9427C26.9943 38.9427 26.3554 38.6004 26.0273 37.8482H24.0758C24.5341 39.7587 26.0933 40.625 27.9787 40.625C30.2254 40.625 32.0137 39.4976 32.0137 36.6879V28.8951H30.0623V32.9945Z" fill="#17353A"/>
            <path d="M36.1147 25.366H34.1632V36.9645H36.1147V25.366Z" fill="#17353A"/>
            <path d="M40.2312 28.8931H38.2798V36.9625H40.2312V28.8931Z" fill="#17353A"/>
            <path d="M39.2623 25.25C38.507 25.25 37.9672 25.805 37.9672 26.5572C37.9672 27.3094 38.5089 27.847 39.2623 27.847C40.0177 27.847 40.5575 27.3075 40.5575 26.5572C40.5594 25.8069 40.0177 25.25 39.2623 25.25Z" fill="#17353A"/>
            <path d="M56.7148 28.6804C55.6818 28.6804 54.8449 29.1542 54.3032 30.0205V25.3641H52.3517V36.9625H54.3032V32.7644C54.3032 31.2619 55.1556 30.4942 56.3537 30.4942C57.5848 30.4942 58.4042 31.2291 58.4042 32.7644V36.9625H60.3556V32.7644C60.3576 29.8909 58.5848 28.6804 56.7148 28.6804Z" fill="#17353A"/>
            <path d="M66.6372 33.6481V33.8608C66.6372 34.9553 66.08 35.3633 65.2761 35.3633C64.4722 35.3633 63.9149 34.9379 63.9149 33.8608V30.6586H68.0974V28.8951H63.9149V26.037C62.8372 26.037 61.9634 26.9072 61.9634 27.9804V33.8608C61.9634 36.0982 63.3091 37.1772 65.278 37.1772C67.247 37.1772 68.5906 36.0982 68.5906 33.8608V33.6481H66.6372Z" fill="#17353A"/>
            <path d="M48.8896 29.5293C48.1653 28.9859 47.2294 28.6804 46.1517 28.6804C43.5924 28.6804 41.7885 30.4768 41.7885 32.9288C41.7885 35.7868 43.8546 37.0786 45.7905 37.0786C47.0041 37.0786 47.973 36.5893 48.5303 35.6901V36.3921C48.5303 38.2697 47.6274 38.9407 46.1847 38.9407C45.1517 38.9407 44.3633 38.5985 44.0352 37.8462H42.0662C42.575 39.7568 44.1653 40.6231 46.1827 40.6231C48.5614 40.6231 50.4799 39.4145 50.4799 36.3263V28.7056C49.8235 28.7056 49.243 29.0324 48.8896 29.5293ZM46.1517 35.2647C44.7245 35.2647 43.7575 34.3501 43.7575 32.9288C43.7575 31.5075 44.7245 30.4942 46.1517 30.4942C47.575 30.4942 48.5264 31.5036 48.5303 32.9191V32.9365C48.5264 34.3539 47.5769 35.2647 46.1517 35.2647Z" fill="#17353A"/>
            </g>
            <defs>
            <clipPath id="clip0_12982_61320">
            <rect width="104" height="41" fill="white" transform="translate(10 15)"/>
            </clipPath>
            </defs>
        </svg>
    );
};

export default LogoSVG;