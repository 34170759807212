import React from 'react';
import { IDeviceIcon } from '../DeviceIcons';

const StereoSystem = (props: IDeviceIcon) => (
  <svg
    width="3rem"
    height="3rem"
    viewBox="0 0 20000 20000"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    color="black"
    {...props}
  >
    <g id="prefix__Layer_x0020_1">
      <g id="prefix__stereo_system">
        <path
          style={{ fill: `${props.color}` }}
          d="M7697 14666H3032c-126 0-273-126-273-273V5778c0-126 126-273 273-273h4665c126 0 273 126 273 273v8615c-21 210-147 273-273 273zm-4412-462h4202V6136H3285v8068z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M5364 10064c-861 0-1534-735-1534-1534 0-861 735-1534 1534-1534 861 0 1534 735 1534 1534 63 861-672 1534-1534 1534zm0-2605c-609 0-1072 462-1072 1072 0 609 462 1072 1072 1072 609 0 1072-462 1072-1072 63-588-483-1072-1072-1072zM5364 13195c-672 0-1198-525-1198-1198 0-672 525-1198 1198-1198 672 0 1198 525 1198 1198 0 672-525 1198-1198 1198zm0-1933c-399 0-735 336-735 735s336 735 735 735 735-336 735-735-336-735-735-735zM17026 14666h-4665c-126 0-273-126-273-273V5778c0-126 126-273 273-273h4665c126 0 273 126 273 273v8615c-63 210-126 273-273 273zm-4391-462h4202V6136h-4202v8068z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M14694 10064c-861 0-1534-735-1534-1534 0-861 735-1534 1534-1534 861 0 1534 735 1534 1534 0 861-672 1534-1534 1534zm0-2605c-609 0-1072 462-1072 1072 0 609 462 1072 1072 1072 609 0 1072-462 1072-1072 0-588-462-1072-1072-1072zM14694 13195c-672 0-1198-525-1198-1198 0-672 525-1198 1198-1198 672 0 1198 525 1198 1198 0 672-525 1198-1198 1198zm0-1933c-399 0-735 336-735 735s336 735 735 735 735-336 735-735-336-735-735-735zM12361 14666H7696c-126 0-273-126-273-273V8930c0-126 126-273 273-273h4665c126 0 273 126 273 273v5463c0 210-147 273-273 273zm-4412-462h4202V9203H7949v5001z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M11437 10338H8621c-126 0-273-126-273-273 0-126 126-273 273-273h2795c126 0 273 126 273 273 0 126-126 273-252 273zM8621 13741c-63 0-126 0-189-63s-63-126-63-189v-1471c0-126 126-273 273-273h2795c126 0 273 126 273 273v1471c0 126-126 273-273 273H8621v-21zm273-1471v925h2269v-925H8894zM8894 11010c0 147-126 273-273 273s-273-126-273-273 126-273 273-273 273 126 273 273zM9819 11010c0 147-126 273-273 273s-273-126-273-273 126-273 273-273c168 0 273 126 273 273zM10764 11010c0 147-126 273-273 273s-273-126-273-273 126-273 273-273 273 126 273 273zM11689 11010c0 147-126 273-273 273s-273-126-273-273 126-273 273-273c168 0 273 126 273 273z"
        />
      </g>
    </g>
  </svg>
);

export default StereoSystem;
