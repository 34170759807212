import * as React from 'react';
import { translate, TranslationFunction } from 'react-i18next';
import { Button, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import LoadingButton from '../Button/LoadingButton/LoadingButton';

export interface IProps {
  t: TranslationFunction;
  isOpen: boolean;
  target: string | HTMLElement;
  message?: string;
  loading?: boolean;
  onAccept?: (event: React.MouseEvent<HTMLElement>) => void;
  customConfirmButton?: React.ReactNode;
  toggle: () => void;
  buttonAlign?: 'center' | 'right';
}

const ConfirmPopover = ({
  isOpen,
  onAccept,
  target,
  toggle,
  t,
  message,
  loading,
  customConfirmButton,
  buttonAlign = 'right'
}: IProps) => (
  <Popover
    placement="bottom"
    isOpen={isOpen}
    target={target}
    toggle={toggle}
    trigger={'legacy'}
  >
    <PopoverHeader>{message || t('Common:sure')}</PopoverHeader>
    <PopoverBody className={`text-${buttonAlign}`}>
      <Button
        onClick={e => {
          e.stopPropagation();
          toggle();
        }}
        color="secondary"
        className={'mr-2'}
      >
        {t('Common:no')}
      </Button>
      {customConfirmButton ? (
        customConfirmButton
      ) : (
        <LoadingButton loading={loading} onClick={onAccept} autoFocus={true}>
          {t('Common:yes')}
        </LoadingButton>
      )}
    </PopoverBody>
  </Popover>
);

export default translate('Common')(ConfirmPopover);
