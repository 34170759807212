import * as React from 'react';
import { Query, QueryResult } from 'react-apollo';

import gql from 'graphql-tag';
import PendingCustomersListView from './PendingCustomersListView';
import { PVInstallation } from '../../graphql/graphql.schema';
import { extractCustomersFromPV } from '../customers/customersList/customerExtractor';

export const GET_CURRENT_PRO_USER_PENDING_CUSTOMERS = gql`
  query pvInstallations {
    pvInstallationsPending {
      id
      panelCount
      panelWattPeak
      srsRegistrationDate
      srsRegistrationStatus
      tenant {
        name
      }
      customers {
        id
        lastName
        email
        firstName
        lastName
        address
        city
        zipCode
        country
        creationDate
        latitude
        longitude
      }
    }
  }
`;

interface IResponse {
  pvInstallationsPending: [PVInstallation];
}

const PendingCustomersListQuery = () => {
  return (
    <Query
      query={GET_CURRENT_PRO_USER_PENDING_CUSTOMERS}
      fetchPolicy={'no-cache'}
    >
      {({ loading, data, error }: QueryResult<IResponse>) => {
        const customers =
          data && data.pvInstallationsPending
            ? extractCustomersFromPV(data.pvInstallationsPending)
            : [];
        return (
          <PendingCustomersListView
            customers={customers}
            loading={loading}
            error={error ? error.message : undefined}
          />
        );
      }}
    </Query>
  );
};

export default PendingCustomersListQuery;
