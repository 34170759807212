import React, { CSSProperties, useEffect, useState } from 'react';

interface IProps {
  min?: number;
  max?: number;
  step?: number;
  round?: number;
  value: number;
  disabled?: boolean;
  unit?: string;
  placeholder?: string;
  className?: string;
  onChange: (a: number | undefined) => void;
  style?: CSSProperties;
  autoComplete?: boolean;
}

export const InputNumber = ({
  disabled,
  onChange,
  min,
  max,
  step,
  style,
  placeholder,
  className,
  round,
  unit,
  value,
  autoComplete
}: IProps) => {
  const [inputValue, setInputValue] = useState<number | undefined>(value);
  useEffect(
    () => {
      setInputValue(value);
    },
    [value]
  );

  const handleOnChange = (newValue: string | undefined) => {
    if (!newValue) {
      onChange!(undefined);
      setInputValue(undefined);
    } else if (Number(newValue) >= 0) {
      setInputValue(Number(newValue));
      let inboundValue: number = min || 0;
      if (!isNaN(Number(newValue))) {
        inboundValue = Number(newValue);
      }
      if (round !== undefined) {
        inboundValue = Math.round(inboundValue / round) * round;
        inboundValue = Number(inboundValue.toFixed(2));
      }
      if (max !== undefined) {
        inboundValue = Math.min(max, inboundValue);
      }
      if (min !== undefined) {
        inboundValue = Math.max(min, inboundValue);
      }
      if (String(inboundValue) === newValue) {
        if (onChange) {
          onChange(inboundValue);
        }
      }
    } else {
      onChange!(0);
    }
  };

  const getRoundedValue = (newValue: string | undefined): number => {
    {
      let roundedValue: number = min || 0;
      if (!isNaN(Number(newValue))) {
        roundedValue = Number(newValue);
      }
      if (round !== undefined) {
        roundedValue = Math.round(roundedValue / round) * round;
        roundedValue = Number(roundedValue.toFixed(2));
      }
      if (max !== undefined) {
        roundedValue = Math.min(max, roundedValue);
      }
      if (min !== undefined) {
        roundedValue = Math.max(min, roundedValue);
      }
      return roundedValue;
    }
  };
  return (
    <>
      <input
        className={`form-control ${className}`}
        pattern="[0-9]*"
        type="number"
        disabled={disabled}
        style={{ maxWidth: '100%', marginRight: '1px', ...style }}
        value={typeof inputValue === 'number' ? inputValue : ''}
        step={step}
        min={min || 0}
        max={max}
        onFocus={(event: any) => event.target.select()}
        autoComplete={autoComplete ? 'on' : 'off'}
        placeholder={placeholder}
        onChange={({ target: { value: newValue } }) => {
          const roundedValue = getRoundedValue(newValue);
          setInputValue(
            typeof newValue !== 'undefined' && newValue !== ''
              ? Number(newValue)
              : undefined
          );
          if (typeof newValue === 'undefined' || newValue === '') {
            onChange(undefined);
          }
          if (String(roundedValue) === newValue) {
            onChange(roundedValue);
          }
        }}
        onBlur={() => {
          if (typeof inputValue === 'undefined') {
            onChange(undefined);
          } else {
            const roundedValue = getRoundedValue(String(inputValue));
            setInputValue(roundedValue);
            onChange(roundedValue);
          }
        }}
      />
      {unit && (
        <div className="input-group-append">
          <span className="input-group-text">{unit}</span>
        </div>
      )}
    </>
  );
};
