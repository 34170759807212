import React from 'react';
import { IDeviceIcon } from '../DeviceIcons';

const Oven = (props: IDeviceIcon) => (
  <svg
    width="3rem"
    height="3rem"
    viewBox="0 0 20000 20000"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    color="black"
    {...props}
  >
    <g id="prefix__Layer_x0020_1">
      <g id="prefix__oven">
        <path
          style={{ fill: `${props.color}` }}
          d="M14583 14851H5872c-182 0-294-112-294-294V5901c0-182 112-294 294-294h8656c182 0 294 112 294 294v8656c0 56-56 182-112 238 0 56-56 56-126 56zm-8348-657h8068V6196H6235v7998z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M13241 13677H7228c-182 0-294-112-294-294V8908c0-182 112-294 294-294h6013c182 0 294 112 294 294v4475c0 56-56 182-112 238-14-14-70 56-182 56zm-5719-657h5411V9133H7522v3887z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M12346 10307H8109c-182 0-294-112-294-294s112-294 294-294h4237c182 0 294 112 294 294 14 182-112 294-294 294zM7522 7315c0 126-112 238-238 238s-238-112-238-238 112-238 238-238c140 0 238 112 238 238zM9004 7315c0 126-112 238-238 238s-238-112-238-238 112-238 238-238 238 112 238 238zM10528 7315c0 126-98 238-238 238-126 0-238-112-238-238s112-238 238-238c140 0 238 112 238 238zM11996 7315c0 126-98 238-238 238-126 0-238-112-238-238s112-238 238-238c140 0 238 112 238 238zM13534 7315c0 126-112 238-238 238s-238-112-238-238 112-238 238-238 238 112 238 238z"
        />
      </g>
    </g>
  </svg>
);

export default Oven;
