import gql from 'graphql-tag';
import { inject, observer } from 'mobx-react';
import { Mutation, MutationResult } from 'react-apollo';
import { RouteComponentProps, withRouter } from 'react-router';
import { translate } from 'react-i18next';
import React from 'react';
import { TranslationFunction } from 'i18next';
import LoadingButton from '../../../../../ui/Button/LoadingButton/LoadingButton';
import { IAllStores } from '../../../../../stores/allStores.model';
import { ApolloError } from 'apollo-client';
import { IInscriptionNavigationRouterParamProps } from '../../../InscriptionNavigation';
import { Alert } from 'reactstrap';

export const ADD_PLC_DEVICE = gql`
  mutation addDeviceForPLCPairing(
    $pvInstallationId: String!
    $newDeviceActivationCodeOrMacAddress: String!
  ) {
    addDeviceForPLCPairing(
      pvInstallationId: $pvInstallationId
      newDeviceActivationCodeOrMacAddress: $newDeviceActivationCodeOrMacAddress
    )
  }
`;

export interface IProps
  extends RouteComponentProps<IInscriptionNavigationRouterParamProps> {
  t: TranslationFunction;
  disabled: boolean;
  showConfirmModal?: () => void;
  initErrorsFromGQLError?: (e: ApolloError) => void;
  macOrAC?: string;
}

interface IMutationResponse {
  addDeviceForPLCPairing: any;
}

const AddPLCDeviceButton = inject(
  ({ addDevice, inscriptionError }: IAllStores) => {
    return {
      macOrAC: addDevice.macOrAC,
      initErrorsFromGQLError: inscriptionError.initErrorsFromApolloError
    };
  }
)(
  observer(
    ({
      match: {
        params: { customerId, pvInstallationId, current }
      },
      t,
      disabled,
      showConfirmModal,
      macOrAC,
      initErrorsFromGQLError
    }: IProps) => {
      return (
        <Mutation
          mutation={ADD_PLC_DEVICE}
          onCompleted={data => {
            if (data.addDeviceForPLCPairing === 'OK') {
              showConfirmModal!();
            } else {
              initErrorsFromGQLError!(data.addDeviceForPLCPairing.error);
            }
          }}
          onError={e => initErrorsFromGQLError!(e)}
        >
          {(
            addDeviceForPLCPairing,
            { loading, data }: MutationResult<IMutationResponse>
          ) => {
            const plcPairingError =
              data && data.addDeviceForPLCPairing !== 'OK';
            return (
              <>
                <LoadingButton
                  className="update"
                  onClick={event => {
                    event.preventDefault();
                    addDeviceForPLCPairing({
                      variables: {
                        pvInstallationId,
                        newDeviceActivationCodeOrMacAddress: macOrAC
                      }
                    });
                  }}
                  disabled={disabled}
                  loading={loading}
                >
                  {t('Common:add')}
                </LoadingButton>
                {plcPairingError && (
                  <div className="pt-4">
                    <Alert color="warning">{t('Errors:plcError')}</Alert>
                  </div>
                )}
              </>
            );
          }}
        </Mutation>
      );
    }
  )
);

export default withRouter(translate(['Common', 'Errors'])(AddPLCDeviceButton));
