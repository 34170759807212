import { observable, action, computed } from 'mobx';
import {
  emailConfirmationValidator,
  emailValidator,
  enphaseInstallerIdValidator,
  enphaseSystemIdValidator,
  envoySerialNumberValidator
} from '../../utils/validators';
import { Device } from '../../graphql/graphql.schema';
import { isEnphaseDevice } from '../../utils/deviceHelpers';

export class EnphaseStore {
  @observable public allDevices: Device[] = [];

  @observable public emailErrors: string[] = [];
  @observable public customerEmail: string | undefined = undefined;
  @observable public newEmail: string = '';
  @observable public emailConfirmation: string = '';
  @observable public useOldEmail: boolean = true;

  @observable public envoyErrors: string[] = [];
  @observable public envoyRegistered: boolean = false;
  @observable public envoySerialNumber: string = '';
  @observable public enphaseSystemId: string = '';
  @observable public enphaseInstallerId?: number;

  @observable public registrationErrors: string[] = [];

  @computed
  get status() {
    const enphaseDevice = this.allDevices.filter(device =>
      isEnphaseDevice(device)
    );
    if (enphaseDevice.length > 0) {
      return 'connected';
    }
    return 'disconnected';
  }

  @computed
  get customerEnphaseDevice() {
    const enphaseDevice = this.allDevices.filter(device =>
      isEnphaseDevice(device)
    );
    if (enphaseDevice.length > 0) {
      return enphaseDevice[0];
    }
    return null;
  }

  @action.bound
  public setCustomerEmail(email: string) {
    this.customerEmail = email;
  }

  @action.bound
  public updateNewEmail(newEmail: string) {
    this.newEmail = newEmail;
  }

  @action.bound
  public updateEmailConfirmation(emailConfirmation: string) {
    this.emailConfirmation = emailConfirmation;
  }

  @action.bound
  public updateEmailChoice(choice: boolean) {
    if (choice) {
      this.updateNewEmail('');
      this.updateEmailConfirmation('');
    }
    this.useOldEmail = choice;
  }

  @action.bound
  public updateEnvoyChoice(choice: boolean) {
    this.envoyRegistered = choice;
  }

  @action.bound
  public updateEnvoySerialNumber(envoySerialNumber: string) {
    this.envoySerialNumber = envoySerialNumber;
  }

  @action.bound
  public updateEnphaseSystemId(enphaseSystemId: string) {
    this.enphaseSystemId = enphaseSystemId;
  }

  @action.bound
  public updateEnphaseInstallerId(enphaseInstallerId?: number) {
    this.enphaseInstallerId = enphaseInstallerId;
  }

  @action.bound
  public addRegistrationError(newError: string) {
    if (!this.registrationErrors.includes(newError)) {
      this.registrationErrors.push(newError);
    }
  }
  @action.bound
  public clearRegistrationErrors() {
    this.registrationErrors = [];
  }

  @action.bound
  public addEmailError(newError: string) {
    if (!this.emailErrors.includes(newError)) {
      this.emailErrors.push(newError);
    }
  }
  @action.bound
  public clearEmailErrors() {
    this.emailErrors = [];
  }

  @action.bound
  public addEnvoyError(newError: string) {
    if (!this.envoyErrors.includes(newError)) {
      this.envoyErrors.push(newError);
    }
  }
  @action.bound
  public clearEnvoyErrors() {
    this.envoyErrors = [];
  }

  @computed
  get validateNewEmail() {
    return emailValidator(this.newEmail);
  }

  @computed
  get validateEmailConfirmation() {
    return emailConfirmationValidator(this.newEmail, this.emailConfirmation);
  }

  @computed
  get validateEnvoySerialNumber() {
    return envoySerialNumberValidator(this.envoySerialNumber);
  }

  @computed
  get validateEnphaseSystemId() {
    return enphaseSystemIdValidator(this.enphaseSystemId);
  }

  @computed
  get validateEnphaseInstallerId() {
    return enphaseInstallerIdValidator(this.enphaseInstallerId);
  }

  @computed
  get validateForm() {
    if (
      !this.useOldEmail &&
      (this.validateNewEmail || this.validateEmailConfirmation)
    ) {
      return false;
    }

    if (
      this.validateEnvoySerialNumber ||
      (this.envoyRegistered && this.validateEnphaseSystemId) ||
      (!this.envoyRegistered && this.validateEnphaseInstallerId)
    ) {
      return false;
    }

    return true;
  }

  @action.bound
  public setAllDevices(devices: Device[]) {
    this.allDevices = devices;
  }

  @action.bound
  public resetEnphaseStore() {
    this.clearRegistrationErrors();
    this.clearEmailErrors();
    this.clearEnvoyErrors();
    this.updateEmailChoice(true);
    this.updateNewEmail('');
    this.updateEmailConfirmation('');
    this.updateEnvoyChoice(false);
    this.updateEnvoySerialNumber('');
    this.updateEnphaseSystemId('');
    this.updateEnphaseInstallerId(undefined);
  }
}

const enphaseStore: EnphaseStore = new EnphaseStore();
export default enphaseStore;
