import { Alert, Col, Row } from 'reactstrap';
import React from 'react';
import { safeMobxInject } from '../../stores/storeInjectionHelpers';
import { TranslationFunction } from 'i18next';
import { translate } from 'react-i18next';

interface IStoresProps {
  getMissingFieldsCreateCustomerMaintainer: string[] | undefined;
  getMissingFieldsCreateCustomerInstaller: string[] | undefined;
  getMissingFieldsEditCustomer: string[] | undefined;
  getMissingFieldsEditPVInstallation: string[] | undefined;
  getMissingFieldsEditElectricalInstallation: string[] | undefined;
}

interface IProps {
  formType:
    | 'createCustomerByInstaller'
    | 'createCustomerByMaintainer'
    | 'editCustomer'
    | 'editInstallation';
  t: TranslationFunction;
}
const MissingFormFieldsAlert = safeMobxInject<IStoresProps, IProps>(
  stores => ({
    getMissingFieldsCreateCustomerMaintainer:
      stores.Store.createCustomer.getMissingFieldsMaintainer,
    getMissingFieldsCreateCustomerInstaller:
      stores.Store.createCustomer.getMissingFieldsInstaller,
    getMissingFieldsEditCustomer: stores.editCustomer.getMissingFields,
    getMissingFieldsEditPVInstallation:
      stores.editPVInstallation.getMissingFieldsPVInstallation,
    getMissingFieldsEditElectricalInstallation:
      stores.editElectricalInstallation.getMissingFieldsElectricalInstallation
  }),
  ({
    t,
    formType,
    getMissingFieldsCreateCustomerMaintainer,
    getMissingFieldsCreateCustomerInstaller,
    getMissingFieldsEditCustomer,
    getMissingFieldsEditPVInstallation,
    getMissingFieldsEditElectricalInstallation
  }: IStoresProps & IProps) => {
    const showMissingFieldsAlert = (fields: string[] | undefined) => {
      if (fields && fields.length > 0) {
        return (
          <Alert color={'warning'}>
            <Col>
              <Row>{t('fillMissingFields')}</Row>
              {fields.map(field => field && <Row key={field}>- {field}</Row>)}
            </Col>
          </Alert>
        );
      }
      return null;
    };
    if (formType === 'createCustomerByInstaller') {
      return showMissingFieldsAlert(getMissingFieldsCreateCustomerInstaller);
    }
    if (formType === 'createCustomerByMaintainer') {
      return showMissingFieldsAlert(getMissingFieldsCreateCustomerMaintainer);
    }
    if (formType === 'editCustomer') {
      return showMissingFieldsAlert(getMissingFieldsEditCustomer);
    }
    if (formType === 'editInstallation') {
      let missingFields: string[] = [];
      if (getMissingFieldsEditElectricalInstallation) {
        missingFields = [
          ...missingFields,
          ...getMissingFieldsEditElectricalInstallation
        ];
      }
      if (getMissingFieldsEditPVInstallation) {
        missingFields = [
          ...missingFields,
          ...getMissingFieldsEditPVInstallation
        ];
      }
      return showMissingFieldsAlert(missingFields);
    }
    return null;
  }
);

export default translate('Customer')(MissingFormFieldsAlert);
