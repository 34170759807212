import React from 'react';
import { MsbBoxTypes, MSBStatus } from '../../../../../graphql/graphql.schema';
import doneIcon from '../../../../../assets/images/check-circle-outline.svg';
import todoIcon from '../../../../../assets/images/check-circle-outline-blank.svg';
import { translate, TranslationFunction } from 'react-i18next';
import moment from 'moment';
import { Col, Container, Row } from 'reactstrap';

interface IProps {
  msbStatuses: MSBStatus[];
  t: TranslationFunction;
  msbType: MsbBoxTypes | null;
}

const DATE_FORMAT = 'DD/MM/YYYY';

const MobileMsbStatusList = ({ msbStatuses, msbType, t }: IProps) => {
  return (
    <>
      {msbStatuses.map((msbStatus: MSBStatus) => (
        <Row
          style={{
            paddingBottom: '0.75rem',
            minHeight: '40px'
          }}
          key={msbStatus.status}
        >
          <Col xs={8}>
            <div
              style={{
                paddingBottom: '0.25rem'
              }}
            >
              <span className="field big-text">
                {msbStatus.validateAt ? (
                  moment(msbStatus.validateAt).format(DATE_FORMAT)
                ) : (
                  <span className="grey-text" />
                )}
              </span>
            </div>
            <div>
              <span className="field text">
                {t(
                  `MySmartBattery:${msbStatus.status}_${
                    msbStatus.isValidate
                  }_${msbType}`
                )}
              </span>
            </div>
          </Col>
          <Col xs={4}>
            <img
              style={{
                marginRight: '0.75rem',
                height: msbStatus.isValidate ? 20 : 19,
                marginLeft: msbStatus.isValidate ? -1 : 0
              }}
              src={msbStatus.isValidate ? doneIcon : todoIcon}
              className="item-step"
              alt="status"
            />
          </Col>
        </Row>
      ))}
    </>
  );
};

export default translate()(MobileMsbStatusList);
