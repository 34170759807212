import { FunctionComponent } from 'react';
import { inject, observer } from 'mobx-react';
import { IAllStores } from './allStores.model';

/**
 * Type safe mobx injection
 * @param mobxInjectFunction, inject props from mobx store
 * @param functionComponent, function component rendered
 */
export function safeMobxInject<StoresProps, ParentProps>(
  mobxInjectFunction: (allStores: IAllStores) => StoresProps,
  functionComponent: FunctionComponent<StoresProps & ParentProps>
): FunctionComponent<ParentProps> {
  return inject(mobxInjectFunction)(observer(functionComponent));
}
