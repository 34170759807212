import * as React from 'react';

import gql from 'graphql-tag';
import { RouteComponentProps, withRouter } from 'react-router';
import { observer, inject } from 'mobx-react';
import { Query, QueryResult } from 'react-apollo';
import TadoAuthenticationQuery from './TadoAuthenticationQuery';
import history from '../../../../history';
import { Device } from '../../../../graphql/graphql.schema';
import Spinner from '../../../../ui/Spinner/Spinner';
import { IAllStores } from '../../../../stores/allStores.model';
import { TadoStore } from '../../../../stores/partnerships/tadoStore';

export const CHECK_TADO_ACCESS = gql`
  query isTadoAccessGranted($customerId: String!) {
    devices(customerId: $customerId) {
      macSerialNumber
      deviceTypeId
      type
    }
    getTadoHomeIdByCustomerId(customerId: $customerId)
    isTadoAccessGranted(customerId: $customerId)
  }
`;

export interface IProps extends RouteComponentProps<IMatchParams> {
  tadoStore?: TadoStore;
}

interface IMatchParams {
  customerId: string;
  pvInstallationId: string;
}

interface IResponse {
  isTadoAccessGranted?: boolean;
  devices?: Device[];
  getTadoHomeIdByCustomerId?: string;
}
const TadoAccessQuery = inject(({ tadoStore }: IAllStores) => {
  return {
    tadoStore
  };
})(
  observer(
    ({
      tadoStore,
      match: {
        params: { customerId, pvInstallationId }
      }
    }: IProps) => {
      return (
        <Query
          query={CHECK_TADO_ACCESS}
          variables={{ customerId }}
          fetchPolicy={'network-only'}
          errorPolicy={'all'}
          onCompleted={(data: IResponse) => {
            const isTadoAccessGranted = data && data.isTadoAccessGranted;
            const devices = data && data.devices;
            const tadoHomeId = data && data.getTadoHomeIdByCustomerId;
            const {
              grantTadoAccess,
              setTadoConnectInProgress,
              tadoDevicesPresent,
              setAllDevices
            } = tadoStore!;
            grantTadoAccess(isTadoAccessGranted === true);
            setAllDevices(devices || []);
            if (isTadoAccessGranted) {
              setTadoConnectInProgress(false);
            }
            if (tadoDevicesPresent || tadoHomeId) {
              history.push(
                `/devices_inscription/${customerId}/partnership/${pvInstallationId}/tado/devices`
              );
            } else if (isTadoAccessGranted && !tadoHomeId) {
              history.push(
                `/devices_inscription/${customerId}/partnership/${pvInstallationId}/tado/home`
              );
            }
          }}
          pollInterval={tadoStore!.tadoConnectInProgress ? 2000 : 0}
        >
          {({ loading, data, error }: QueryResult<IResponse>) => {
            return (
              <Spinner loading={loading}>
                <TadoAuthenticationQuery />
              </Spinner>
            );
          }}
        </Query>
      );
    }
  )
);

export default withRouter(TadoAccessQuery);
