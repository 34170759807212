import React from 'react';
import { IDeviceIcon } from '../DeviceIcons';

const MicrowaveOven = (props: IDeviceIcon) => (
  <svg
    xmlns="http://www.openswatchbook.org/uri/2009/osb"
    xmlSpace="preserve"
    width="3rem"
    height="3rem"
    viewBox="0 0 20000 20000"
    id="prefix__svg4438"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    color="black"
    {...props}
  >
    <defs id="prefix__defs4440">
      <linearGradient id="prefix__linearGradient5116">
        <stop
          offset={0}
          id="prefix__stop5118"
          stopColor={props.color}
          stopOpacity={1}
        />
      </linearGradient>
      <linearGradient id="prefix__linearGradient5110">
        <stop
          offset={0}
          id="prefix__stop5112"
          stopColor={props.color}
          stopOpacity={1}
        />
      </linearGradient>
      <linearGradient id="prefix__linearGradient5023">
        <stop
          offset={0}
          id="prefix__stop5025"
          stopColor={props.color}
          stopOpacity={1}
        />
      </linearGradient>
      <linearGradient id="prefix__linearGradient5017">
        <stop
          offset={0}
          id="prefix__stop5019"
          stopColor={props.color}
          stopOpacity={1}
        />
      </linearGradient>
      <style type="text/css" id="style4442">
        <style>{`.prefix__fil0{fill:${props.color}}`}</style>
      </style>
    </defs>
    <g id="prefix__layer">
      <g id="prefix__g5029" fill={props.color} fillOpacity={1}>
        <path
          style={{ fill: `${props.color}` }}
          d="M15787 6544H4266c-446 0-754 309-754 754v5332c0 446 309 754 754 754h11521c446 0 754-309 754-754V7281c69-360-309-737-754-737zM4009 12613V7281c0-120 120-257 257-257h8709v5829H4266c-137 17-257-51-257-240zm12035 0c0 120-120 257-257 257h-2246V7041h2246c120 0 257 120 257 257v5315z"
          id="prefix__path4447"
          fill={props.color}
          fillOpacity={1}
        />
        <path
          d="M13918 7539h1886v754h-1886z"
          id="prefix__rect4449"
          fill={props.color}
          fillOpacity={1}
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M15290 11550h-1011c-309 0-566 257-566 566s240 566 566 566h1011c309 0 566-257 566-566 51-326-257-566-566-566zm0 617h-1011c-69 0-69 0-69-69s0-69 69-69h1011c69 17 69 17 0 137 69 0 69 0 0 0z"
          id="prefix__path4451"
          fill={props.color}
          fillOpacity={1}
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M11844 7539H5398c-446 0-754 309-754 754v3326c0 429 309 754 754 754h6446c429 0 754-309 754-754V8293c51-446-326-754-754-754zm257 4132c0 120-120 257-257 257H5398c-120 0-257-120-257-257V8362c0-120 120-257 257-257h6446c120 0 257 120 257 257v3309z"
          id="prefix__path4453"
          fill={props.color}
          fillOpacity={1}
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M15856 9047c0 171-137 309-309 309-171 0-309-137-309-309 0-171 137-309 309-309 171-17 309 137 309 309z"
          id="prefix__path4455"
          fill={props.color}
          fillOpacity={1}
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M15170 9047c0 171-137 309-309 309-171 0-309-137-309-309 0-171 137-309 309-309 171-17 309 137 309 309z"
          id="prefix__path4457"
          fill={props.color}
          fillOpacity={1}
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M14090 8722c189 0 309 120 309 309s-120 309-309 309-309-120-309-309c0-171 137-309 309-309z"
          id="prefix__path4459"
          fill={props.color}
          fillOpacity={1}
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M15856 9922c0 171-137 309-309 309-171 0-309-137-309-309 0-171 137-309 309-309 171 0 309 137 309 309z"
          id="prefix__path4461"
          fill={props.color}
          fillOpacity={1}
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M15170 9922c0 171-137 309-309 309-171 0-309-137-309-309 0-171 137-309 309-309 171 0 309 137 309 309z"
          id="prefix__path4463"
          fill={props.color}
          fillOpacity={1}
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M14090 9613c189 0 309 120 309 309s-120 309-309 309-309-120-309-309 137-309 309-309z"
          id="prefix__path4465"
          fill={props.color}
          fillOpacity={1}
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M15856 10796c0 171-137 309-309 309-171 0-309-137-309-309 0-171 137-309 309-309 171 0 309 137 309 309z"
          id="prefix__path4467"
          fill={props.color}
          fillOpacity={1}
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M15170 10796c0 171-137 309-309 309-171 0-309-137-309-309 0-171 137-309 309-309 171 0 309 137 309 309z"
          id="prefix__path4469"
          fill={props.color}
          fillOpacity={1}
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M14090 10488c189 0 309 120 309 309s-120 309-309 309-309-120-309-309 137-309 309-309z"
          id="prefix__path4471"
          fill={props.color}
          fillOpacity={1}
        />
      </g>
    </g>
  </svg>
);

export default MicrowaveOven;
