import { action, computed, observable } from 'mobx';
import {
  caeNumberValidator,
  checkIfSerialNumberIsMyLightFormat,
  checkIfSerialNumberIsMyLightModemFormat,
  checkIfSerialNumberIsPlaneteOuiFormat,
  consuelNumberValidator,
  enedisConnectNumberValidator,
  numberWithoutZeroValidator,
  pdlValidator
} from '../utils/validators';
import { orderBy } from 'lodash';
import {
  BatteryStatusType,
  EMPTY_SERIAL_NUMBER,
  MAX_PLANETE_OUI_MSB_BOXES_COUNT,
  MYLIGHT_MSB_STATUSES
} from './battery.constants';
import { IValidatedSerialNumber } from './battery.types';
import inscription from './inscription/inscriptionStore';

import {
  DocumentType,
  EnergyDistributor,
  MSB,
  MsbBoxTypes,
  MSBStatus
} from '../graphql/graphql.schema';
import { SAVE_INSCRIPTION_STEP_QUERY } from '../components/devices_inscription/partnership/PartnershipsListView';
import inscriptionStepStore from './inscription/inscriptionStepsStore';
import { WithApolloClient } from 'react-apollo';
import snackbarStore from './userFeedback/snackbarStore';
import { t } from 'i18next';

export class Battery {
  @observable public confirmationWindowTarget: string | undefined;
  @observable public customerStatus: string;
  @observable public status: MSBStatus[] = [];
  @observable public isEditSerialNumberModalOpened: boolean = false;
  @observable
  public editedSerialNumber: IValidatedSerialNumber = EMPTY_SERIAL_NUMBER;
  @observable
  public msbFormSerialNumbers: { [key: string]: IValidatedSerialNumber } = {};
  @observable public registeredSerialNumbers: string[] = [];
  @observable public serialNumberModifiedAt: Date | null = null;
  @observable public volume: number = 0;
  @observable public pdlNumber: string = '';
  @observable public pdlNumberModifiedAt: Date | null = null;
  @observable public invalidPdlNumber: string | null = null;
  @observable public isPdlNumberChanged: boolean = false;
  @observable public registeredPdlNumber: string = '';
  @observable public caeNumber: string = '';
  @observable public registeredCaeNumber: string = '';
  @observable public enedisConnectNumber: string = '';
  @observable public enedisConnectNumberModifiedAt: Date | null = null;
  @observable public registeredEnedisConnectNumber: string = '';
  @observable public consuelNumber: string = '';
  @observable public consuelNumberModifiedAt: Date | null = null;
  @observable public registeredConsuelNumber: string = '';
  @observable public consuelDeclaredPower: number | null = null;
  @observable public consuelDeclaredPowerModifiedAt: Date | null = null;
  @observable public registeredConsuelDeclaredPower: number | null = null;
  @observable public connectionPower: number | null = null;
  @observable public connectionPowerModifiedAt: Date | null = null;
  @observable public registeredConnectionPower: number | null = null;
  @observable public sendingCAEDate?: number;
  @observable public sendingARPEDate?: number;
  @observable public sendingCONSUELDate?: number;
  @observable public type: MsbBoxTypes | null = null;

  @action.bound
  public startEditSerialNumberProcess(serialNumber: IValidatedSerialNumber) {
    this.isEditSerialNumberModalOpened = true;
    this.editedSerialNumber = {
      ...EMPTY_SERIAL_NUMBER,
      serialNumber: serialNumber.serialNumber
    };
  }
  @action.bound
  public stopEditSerialNumberProcess() {
    this.isEditSerialNumberModalOpened = false;
    this.editedSerialNumber = {
      ...EMPTY_SERIAL_NUMBER
    };
  }

  @action.bound
  public setSendingDocumentDate(documentType: DocumentType) {
    const documentSendingDate = Date.now();
    switch (documentType) {
      case DocumentType.ARPE:
        return (this.sendingARPEDate = documentSendingDate);
      case DocumentType.CAE:
        return (this.sendingCAEDate = documentSendingDate);
      case DocumentType.CONSUEL:
        return (this.sendingCONSUELDate = documentSendingDate);
    }
  }

  @action.bound
  public resetSendingDocumentsDate() {
    this.sendingARPEDate = undefined;
    this.sendingCAEDate = undefined;
    this.sendingCONSUELDate = undefined;
  }

  @action.bound
  public setMSBStatus(status: MSBStatus[]): void {
    this.status = status;
  }

  @action.bound
  public setInvalidPdl(invalidPdlNumber: string | null): void {
    this.invalidPdlNumber = invalidPdlNumber;
  }

  @action.bound
  public setMSBInfo(MSBInfo: MSB): void {
    this.clearMSBInfo();
    if (MSBInfo.status) {
      this.setMSBStatus(MSBInfo.status);
    }
    if (MSBInfo.boxesSerialNumber) {
      this.msbFormSerialNumbers = MSBInfo.boxesSerialNumber.reduce(
        (acc: object, serialNumber: string) => {
          return {
            ...acc,
            [serialNumber]: {
              serialNumber,
              isValid: true,
              type: MSBInfo.type || null
            }
          };
        },
        {}
      );

      this.registeredSerialNumbers = MSBInfo.boxesSerialNumber;
    }
    if (MSBInfo.volume) {
      this.volume = MSBInfo.volume;
    }
    if (MSBInfo.caeNumber) {
      this.caeNumber = MSBInfo.caeNumber;
      this.registeredCaeNumber = MSBInfo.caeNumber;
    }
    if (MSBInfo.enedisConnectNumber) {
      this.enedisConnectNumber = MSBInfo.enedisConnectNumber;
      this.enedisConnectNumberModifiedAt =
        MSBInfo.enedisConnectNumberModifiedAt;
      this.registeredEnedisConnectNumber = MSBInfo.enedisConnectNumber;
    }
    if (MSBInfo.consuelNumber) {
      this.consuelNumber = MSBInfo.consuelNumber;
      this.consuelNumberModifiedAt = MSBInfo.consuelNumberModifiedAt;
      this.registeredConsuelNumber = MSBInfo.consuelNumber;
    }
    if (typeof MSBInfo.consuelDeclaredPower !== 'undefined') {
      this.consuelDeclaredPower = MSBInfo.consuelDeclaredPower;
      this.consuelDeclaredPowerModifiedAt =
        MSBInfo.consuelDeclaredPowerModifiedAt;
      this.registeredConsuelDeclaredPower = MSBInfo.consuelDeclaredPower;
    }
    if (typeof MSBInfo.connectionPower !== 'undefined') {
      this.connectionPower = MSBInfo.connectionPower;
      this.connectionPowerModifiedAt =
        MSBInfo.connectionPowerModifiedAt;
      this.registeredConnectionPower = MSBInfo.connectionPower;
    }
    if (MSBInfo.pdlNumber) {
      this.pdlNumber = MSBInfo.pdlNumber;
      this.pdlNumberModifiedAt = MSBInfo.pdlNumberModifiedAt;
      this.invalidPdlNumber = null;
      this.registeredPdlNumber = MSBInfo.pdlNumber;
    }
    this.sendingCAEDate = MSBInfo.sendingCAEDate;
    this.sendingARPEDate = MSBInfo.sendingARPEDate;
    this.sendingCONSUELDate = MSBInfo.sendingCONSUELDate;
    this.type = MSBInfo.type || null;
  }

  @action.bound
  public clearMSBInfo(): void {
    this.setConfirmationWindowTarget(undefined);
    this.status = [];
    this.resetMsbFormSerialNumbers();
    this.registeredSerialNumbers = [];
    this.serialNumberModifiedAt = null;
    this.volume = 0;
    this.caeNumber = '';
    this.pdlNumber = '';
    this.pdlNumberModifiedAt = null;
    this.invalidPdlNumber = null;
    this.registeredPdlNumber = '';
    this.registeredCaeNumber = '';
    this.enedisConnectNumber = '';
    this.enedisConnectNumberModifiedAt = null;
    this.registeredEnedisConnectNumber = '';
    this.consuelNumber = '';
    this.consuelNumberModifiedAt = null;
    this.registeredConsuelNumber = '';
    this.consuelDeclaredPower = null;
    this.consuelDeclaredPowerModifiedAt = null;
    this.registeredConsuelDeclaredPower = null;
    this.connectionPower = null;
    this.connectionPowerModifiedAt = null;
    this.registeredConnectionPower = null;
    this.resetSendingDocumentsDate();
    this.type = null;
  }

  @action.bound
  public updateMSBRegistrationStatus(
    client: WithApolloClient<any>,
    pvInstallationId: string,
    status: 'done' | 'todo'
  ): void {
    if (pvInstallationId) {
      client
        .mutate({
          mutation: SAVE_INSCRIPTION_STEP_QUERY,
          variables: {
            pvInstallationId,
            registrationSteps: {
              msb: status
            }
          }
        })
        .then(() => inscriptionStepStore.setBatteryStepStatus(status))
        .catch(() => {
          snackbarStore.showSnackBar({
            text: t('StepError:unknown'),
            type: 'error'
          });
        });
    }
  }

  @action.bound
  public setCustomerStatus(customer: string): void {
    this.customerStatus = customer;
  }

  @action.bound
  public updateCaeNumber(caeNumber: string): void {
    this.caeNumber = caeNumber;
  }

  @action.bound
  public updateEnedisConnectNumber(enedisConnectNumber: string): void {
    this.enedisConnectNumber = enedisConnectNumber;
  }

  @action.bound
  public updateConsuelNumber(consuelNumber: string): void {
    this.consuelNumber = consuelNumber;
    if (!this.consuelNumber) {
      this.updateConsuelDeclaredPower(null);
    }
  }

  @action.bound
  public updateConsuelDeclaredPower(consuelDeclaredPower: any): void {
    this.consuelDeclaredPower =
      typeof consuelDeclaredPower === 'number' ? consuelDeclaredPower : null;
  }

  @action.bound
  public updateConnectionPower(connectionPower: any): void {
    this.connectionPower =
      typeof connectionPower === 'number' ? connectionPower : null;
  }

  @action.bound
  public isAllSerialNumbersAreValid(): boolean {
    return Object.values(this.msbFormSerialNumbers).some(
      sn => !sn.isValid || !sn.type
    );
  }

  @action.bound
  public resetMsbFormSerialNumbers(): void {
    this.msbFormSerialNumbers = {
      [EMPTY_SERIAL_NUMBER.serialNumber]: { ...EMPTY_SERIAL_NUMBER }
    };
  }

  @action.bound
  public addEmptySerialNumber(): void {
    if (this.canAddNewSN) {
      this.msbFormSerialNumbers[EMPTY_SERIAL_NUMBER.serialNumber] = {
        ...EMPTY_SERIAL_NUMBER
      };
    }
  }

  @action.bound
  public addScannedSerialNumber(scannedSerialNumber: string) {
    if (this.isEditSerialNumberModalOpened) {
      this.editedSerialNumber = {
        ...EMPTY_SERIAL_NUMBER,
        serialNumber: scannedSerialNumber
      };
    } else {
      this.msbFormSerialNumbers[EMPTY_SERIAL_NUMBER.serialNumber] = {
        ...EMPTY_SERIAL_NUMBER,
        serialNumber: scannedSerialNumber
      };
    }
  }

  @computed
  public get haveRightToAccessMSB(): boolean {
    return !inscription.customerIsFromCH;
  }

  @computed
  public get isNewSerialNumberAddingInProgress(): boolean {
    return !!this.msbFormSerialNumbers[EMPTY_SERIAL_NUMBER.serialNumber];
  }

  @computed
  public get hasNoSerialNumbers(): boolean {
    return (
      Object.keys(this.msbFormSerialNumbers).length === 0 ||
      (Object.keys(this.msbFormSerialNumbers).length === 1 &&
        Object.keys(this.msbFormSerialNumbers)[0] ===
          EMPTY_SERIAL_NUMBER.serialNumber)
    );
  }

  @computed
  public get getEnergyProviderName(): string {
    if (this.isMyLightBoxExists) {
      return inscription.isTenantDomuneo
        ? t('MySmartBattery:domuneoEnergyProvider')
        : t('MySmartBattery:mylightEnergyProvider');
    }
    return t('MySmartBattery:planeteOuiEnergyProvider');
  }

  @computed
  public get getMsbFormSerialNumbers(): string[] {
    return Object.values(this.msbFormSerialNumbers)
      .filter(sn => this.checkIfSerialNumberCanBeSaved(sn))
      .map(sn => sn.serialNumber);
  }

  @computed
  public get isMyLightBoxExists(): boolean {
    const hasRegisteredMylightBox = this.registeredSerialNumbers.some(
      serialNumber =>
        this.getSerialNumberTypeFromString(serialNumber) === MsbBoxTypes.MYLIGHT
    );
    return (
      hasRegisteredMylightBox ||
      (!this.hasNoSerialNumbers &&
        Object.values(this.msbFormSerialNumbers).some(
          serialNumber =>
            serialNumber.type === MsbBoxTypes.MYLIGHT ||
            serialNumber.type === MsbBoxTypes.MYLIGHT_MODEM
        ))
    );
  }

  @computed
  public get isPlaneteOuiBoxExists(): boolean {
    const hasRegisteredPlaneteOuiBox = this.registeredSerialNumbers.some(
      serialNumber =>
        this.getSerialNumberTypeFromString(serialNumber) ===
        MsbBoxTypes.PLANETE_OUI
    );
    return (
      hasRegisteredPlaneteOuiBox ||
      (!this.hasNoSerialNumbers &&
        Object.values(this.msbFormSerialNumbers).some(
          serialNumber => serialNumber.type === MsbBoxTypes.PLANETE_OUI
        ))
    );
  }

  @computed
  public get getMsbType(): MsbBoxTypes | null {
    if (this.type) {
      return this.type;
    }
    if (this.isMyLightBoxExists) {
      return MsbBoxTypes.MYLIGHT;
    }
    if (this.isPlaneteOuiBoxExists) {
      return MsbBoxTypes.PLANETE_OUI;
    }
    return null;
  }

  @computed
  public get isMsbMylight(): boolean {
    return this.type === MsbBoxTypes.MYLIGHT;
  }

  @action.bound
  public checkIfSerialNumberIsAdded(serialNumber: string): boolean {
    return !!this.msbFormSerialNumbers[serialNumber];
  }

  @action.bound
  public getSerialNumberTypeFromString = (
    serialNumber: string
  ): MsbBoxTypes | null => {
    const isPlaneteOuiSerialNumber = checkIfSerialNumberIsPlaneteOuiFormat(
      serialNumber
    );
    const isMylightSerialNumber =
      checkIfSerialNumberIsMyLightFormat(serialNumber) ||
      checkIfSerialNumberIsMyLightModemFormat(serialNumber);

    if (
      (!isPlaneteOuiSerialNumber && !isMylightSerialNumber) ||
      (isPlaneteOuiSerialNumber && isMylightSerialNumber)
    ) {
      return null;
    }

    return isMylightSerialNumber
      ? MsbBoxTypes.MYLIGHT
      : MsbBoxTypes.PLANETE_OUI;
  };

  @computed
  public get isMaxNumberOfMsbBoxesReached(): boolean {
    return (
      this.isMyLightBoxExists ||
      Object.keys(this.msbFormSerialNumbers).filter(
        sn => sn !== EMPTY_SERIAL_NUMBER.serialNumber
      ).length === MAX_PLANETE_OUI_MSB_BOXES_COUNT
    );
  }

  @action.bound
  public checkIfSerialNumberCanBeValidated(serialNumber: string): boolean {
    const serialNumberTypeFromString = this.getSerialNumberTypeFromString(
      serialNumber
    );

    return (
      !this.checkIfSerialNumberIsAdded(serialNumber) &&
      !this.isMaxNumberOfMsbBoxesReached &&
      this.isNewSerialNumberAddingInProgress &&
      this.checkIfSerialNumberTypeIsOk(serialNumberTypeFromString)
    );
  }

  @action.bound
  public checkIfEditedSerialNumberCanBeValidated(
    serialNumber: string
  ): boolean {
    const serialNumberTypeFromString = this.getSerialNumberTypeFromString(
      serialNumber
    );

    return (
      this.isEditSerialNumberModalOpened &&
      !this.checkIfSerialNumberIsAdded(serialNumber) &&
      this.checkIfSerialNumberTypeIsOk(serialNumberTypeFromString)
    );
  }

  @action.bound
  public checkIfSerialNumberTypeIsOk(
    serialNumberType: MsbBoxTypes | null
  ): boolean {
    return !(
      serialNumberType === null ||
      (this.isPlaneteOuiBoxExists &&
        serialNumberType === MsbBoxTypes.MYLIGHT) ||
      (this.isMyLightBoxExists && serialNumberType === MsbBoxTypes.PLANETE_OUI)
    );
  }

  @action.bound
  public checkIfSerialNumberIsNotAlreadyAdded(
    serialNumber: IValidatedSerialNumber
  ): boolean {
    return !this.msbFormSerialNumbers[serialNumber.serialNumber];
  }
  @action.bound
  public checkIfSerialNumberIsNotAlreadyRegistered(
    serialNumber: string
  ): boolean {
    return !this.registeredSerialNumbers.includes(serialNumber);
  }

  @action.bound
  public checkIfSerialNumberCanBeAdded(
    serialNumber: IValidatedSerialNumber
  ): boolean {
    return (
      !this.isMaxNumberOfMsbBoxesReached &&
      !!serialNumber.isValid &&
      this.checkIfSerialNumberIsNotAlreadyAdded(serialNumber) &&
      this.checkIfSerialNumberTypeIsOk(serialNumber.type)
    );
  }

  @action.bound
  public checkIfEditedSerialNumberCanBeAdded(
    serialNumber: IValidatedSerialNumber
  ): boolean {
    return (
      this.getMsbFormSerialNumbers.length === 1 &&
      !!serialNumber.isValid &&
      this.checkIfSerialNumberIsNotAlreadyAdded(serialNumber) &&
      this.checkIfSerialNumberTypeIsOk(serialNumber.type)
    );
  }

  @action.bound
  public checkIfSerialNumberCanBeSaved(
    serialNumber: IValidatedSerialNumber
  ): boolean {
    return (
      serialNumber.isValid === true &&
      this.getSerialNumberTypeFromString(serialNumber.serialNumber) !== null &&
      this.checkIfSerialNumberTypeIsOk(serialNumber.type)
    );
  }
  @action.bound
  public addValidatedSerialNumber(serialNumber: IValidatedSerialNumber): void {
    if (this.isEditSerialNumberModalOpened) {
      this.addEditedValidatedSerialNumber(serialNumber);
    } else {
      if (this.checkIfSerialNumberCanBeAdded(serialNumber)) {
        delete this.msbFormSerialNumbers[EMPTY_SERIAL_NUMBER.serialNumber];
        this.msbFormSerialNumbers[serialNumber.serialNumber] = serialNumber;
      } else {
        this.msbFormSerialNumbers[
          EMPTY_SERIAL_NUMBER.serialNumber
        ] = serialNumber;
      }
    }
  }

  @action.bound
  public addEditedValidatedSerialNumber(
    serialNumber: IValidatedSerialNumber
  ): void {
    if (this.checkIfEditedSerialNumberCanBeAdded(serialNumber)) {
      this.removeSerialNumber(Object.values(this.msbFormSerialNumbers)[0]);
      this.msbFormSerialNumbers = {
        [serialNumber.serialNumber]: serialNumber
      };
      this.stopEditSerialNumberProcess();
    } else {
      this.editedSerialNumber = {
        ...serialNumber
      };
    }
  }

  @computed
  public get canRemoveSerialNumber(): boolean {
    return (
      Object.keys(this.msbFormSerialNumbers).filter(
        serialNumber => serialNumber !== EMPTY_SERIAL_NUMBER.serialNumber
      ).length > 1
    );
  }

  @computed
  public get canEditSerialNumber(): boolean {
    return (
      Object.keys(this.msbFormSerialNumbers).length === 1 &&
      (this.getMsbType === MsbBoxTypes.PLANETE_OUI || !this.isMSBAdded)
    );
  }

  @action.bound
  public removeSerialNumber(serialNumber: IValidatedSerialNumber): void {
    this.setConfirmationWindowTarget(undefined);
    if (this.checkIfSerialNumberIsAdded(serialNumber.serialNumber)) {
      delete this.msbFormSerialNumbers[serialNumber.serialNumber];
    }
  }

  @action.bound
  public addRegisteredSN(serialNumber: string): void {
    const registeredSerialNumber: IValidatedSerialNumber = {
      serialNumber,
      isValid: true,
      type: this.getMsbType
    };
    if (!this.checkIfSerialNumberIsAdded(registeredSerialNumber.serialNumber)) {
      delete this.msbFormSerialNumbers[EMPTY_SERIAL_NUMBER.serialNumber];
      this.msbFormSerialNumbers[
        registeredSerialNumber.serialNumber
      ] = registeredSerialNumber;
    }
    if (this.checkIfSerialNumberIsNotAlreadyRegistered(serialNumber)) {
      this.registeredSerialNumbers.push(serialNumber);
    }
  }
  @action.bound
  public addEditedRegisteredSN(serialNumber: string): void {
    const registeredSerialNumber: IValidatedSerialNumber = {
      serialNumber,
      isValid: true,
      type: this.getMsbType
    };
    this.addValidatedSerialNumber(registeredSerialNumber);
  }

  @action.bound
  public setConfirmationWindowTarget(target: string | undefined): void {
    this.confirmationWindowTarget = target;
  }

  @action.bound
  public setPdlNumber(pdlNumber: string): void {
    if (this.pdlNumber !== pdlNumber) {
      this.pdlNumber = pdlNumber;
      this.isPdlNumberChanged = true;
    }
  }

  @computed
  public get getPdlNumber(): string {
    return this.pdlNumber;
  }

  @computed
  public get msbOkStatus(): MSBStatus | undefined {
    return this.status.find(s => s.status === BatteryStatusType.OK);
  }

  @computed
  public get getMSBStatuses(): MSBStatus[] {
    let msbStatus = this.isMsbMylight
      ? this.status.filter(
          status =>
            status.status && MYLIGHT_MSB_STATUSES.includes(status.status)
        )
      : [...this.status];

    if (!this.msbOkStatus) {
      return orderBy(msbStatus, ['validateAt'], ['desc']);
    }

    msbStatus.splice(msbStatus.indexOf(this.msbOkStatus), 1);
    msbStatus = orderBy(msbStatus, ['validateAt'], ['desc']);
    if (this.msbOkStatus.isValidate) {
      msbStatus.unshift(this.msbOkStatus);
    }
    return msbStatus;
  }

  @computed
  public get msbAcFilledStatus(): MSBStatus | undefined {
    const msbStatus = [...this.status];
    return msbStatus.find(s => s.status === BatteryStatusType.AC_FILLED);
  }

  @computed
  public get canAddNewSN(): boolean {
    if (
      this.isAllSerialNumbersAreValid &&
      !this.isMaxNumberOfMsbBoxesReached &&
      !this.isNewSerialNumberAddingInProgress
    ) {
      return true;
    }

    return false;
  }

  @computed
  public get canAddMSB(): boolean {
    return !this.isMSBAdded;
  }

  @computed
  public get planeteOuiMSBStatusesArentValidatedYet(): boolean {
    return (
      this.isMSBAdded &&
      !this.getMSBStatuses.some(
        s =>
          (s.status &&
            s.status !== BatteryStatusType.SN_FILLED &&
            s.status !== BatteryStatusType.AC_FILLED &&
            s.isValidate) ||
          false
      )
    );
  }

  @computed
  public get mylightMSBStatusesArentValidatedYet(): boolean {
    return !this.isMSBActivated;
  }

  @computed
  public get statusesArentValidatedYet(): boolean {
    return this.isMsbMylight
      ? this.mylightMSBStatusesArentValidatedYet
      : this.planeteOuiMSBStatusesArentValidatedYet;
  }

  @computed
  public get canEditMSB(): boolean {
    return this.isMSBAdded && this.statusesArentValidatedYet;
  }

  @computed
  public get canEditOnlyCAE(): boolean {
    return this.isMSBAdded && !this.canEditMSB && !this.isMSBActivated;
  }

  @computed
  public get isBatteryFormEdited(): boolean {
    return (
      this.pdlNumber !== this.registeredPdlNumber ||
      this.caeNumber !== this.registeredCaeNumber ||
      this.enedisConnectNumber !== this.registeredEnedisConnectNumber ||
      this.consuelNumber !== this.registeredConsuelNumber ||
      this.consuelDeclaredPower !== this.registeredConsuelDeclaredPower ||
      this.connectionPower !== this.registeredConnectionPower ||
      this.getMsbFormSerialNumbers.toString() !==
        this.registeredSerialNumbers.toString()
    );
  }

  @computed
  public get isBatteryFormValid(): boolean {
    return (
      !this.errorPdlNumber &&
      !this.errorCaeNumber &&
      !this.errorEnedisConnectNumber &&
      !this.errorConsuelNumber &&
      !this.errorConsuelDeclaredPower &&
      !this.isNewSerialNumberAddingInProgress
    );
  }

  @computed
  public get canUpdateMSB(): boolean {
    return this.isBatteryFormEdited && this.isBatteryFormValid;
  }

  /*@computed
  public get canDeleteMSB(): boolean {
    return this.haveRightToAccessMSB;
  }*/

  @computed
  public get canSaveForm(): boolean {
    return this.isBatteryFormValid && this.serialNumbersToSave.length > 0;
  }

  @computed
  public get errorPdlNumber(): string | undefined {
    return this.pdlNumber === this.invalidPdlNumber
      ? t('Installation:pdlIsNotUnique')
      : pdlValidator(this.pdlNumber, true);
  }

  @computed
  public get errorCaeNumber(): string | undefined {
    return this.caeNumber.length > 0
      ? caeNumberValidator(this.caeNumber)
      : undefined;
  }

  @computed
  public get errorEnedisConnectNumber(): string | undefined {
    return this.enedisConnectNumber.length > 0
      ? enedisConnectNumberValidator(this.enedisConnectNumber)
      : undefined;
  }

  @computed
  public get errorConsuelNumber(): string | undefined {
    return this.consuelNumber.length > 0
      ? consuelNumberValidator(this.consuelNumber)
      : undefined;
  }

  @computed
  public get errorConsuelDeclaredPower(): string | undefined {
    return typeof this.consuelDeclaredPower === 'number'
      ? numberWithoutZeroValidator(this.consuelDeclaredPower)
      : undefined;
  }

  @computed
  public get serialNumbersToSave(): string[] {
    return this.getMsbFormSerialNumbers.filter(
      serialNumber => !this.registeredSerialNumbers.includes(serialNumber)
    );
  }
  @computed
  public get serialNumbersToDelete(): string[] {
    return this.registeredSerialNumbers.filter(
      sn => !this.getMsbFormSerialNumbers.includes(sn)
    );
  }

  @computed
  public get isMSBAdded(): boolean {
    return !!(this.getMSBStatuses && this.getMSBStatuses.length > 0);
  }

  @computed
  public get isMSBActivated(): boolean {
    return !!(this.msbOkStatus && this.msbOkStatus.isValidate);
  }
}

const battery: Battery = new Battery();
export default battery;
