import SelectInput from '../../../ui/Select/Select';
import * as React from 'react';
import { translate, TranslationFunction } from 'react-i18next';
import { VALID_SIM_ROUTER_REFERENCE } from '../../../stores/devices_inscription/contants';

interface IProps {
  t: TranslationFunction;
  disabled?: boolean;
  setSimRouterReference: (simRouterReference: string) => void;
  simRouterReference: string;
  isValid: boolean;
}

const SelectRouterReference = ({
  t,
  simRouterReference,
  setSimRouterReference,
  disabled,
  isValid
}: IProps) => {
  return (
    <SelectInput
      required
      id={'simRouterReference'}
      name={t('Installation:simRouterReference')}
      options={[VALID_SIM_ROUTER_REFERENCE].map(reference => ({
        value: reference,
        label: reference
      }))}
      disabled={disabled || isValid}
      onChange={selectedOption => {
        setSimRouterReference(selectedOption.value);
      }}
      value={
        isValid
          ? {
              value: simRouterReference,
              label: simRouterReference
            }
          : {
              value: '',
              label: ''
            }
      }
    />
  );
};

export default translate()(SelectRouterReference);
