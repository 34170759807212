import gql from 'graphql-tag';
import { inject, observer } from 'mobx-react';
import { Mutation, MutationResult } from 'react-apollo';
import history from '../../../../history';
import { RouteComponentProps, withRouter } from 'react-router';
import { translate } from 'react-i18next';
import React from 'react';
import { TranslationFunction } from 'i18next';
import LoadingButton from '../../../../ui/Button/LoadingButton/LoadingButton';
import { IAllStores } from '../../../../stores/allStores.model';
import { TadoStore } from '../../../../stores/partnerships/tadoStore';

export const SET_TADO_HOME = gql`
  mutation setTadoHome($customerId: String!, $homeId: String!) {
    setTadoHome(customerId: $customerId, homeId: $homeId)
  }
`;

export interface IProps extends RouteComponentProps<IMatchParams> {
  tadoStore?: TadoStore;
  t: TranslationFunction;
}

interface IMatchParams {
  customerId: string;
  pvInstallationId: string;
}

interface IMutation {
  mutationResponse?: any;
  setTadoHome?: any;
}

const TadoSetHomeButton = inject(({ tadoStore }: IAllStores) => {
  return {
    tadoStore
  };
})(
  observer(
    ({
      match: {
        params: { customerId, pvInstallationId }
      },
      t,
      tadoStore
    }: IProps) => {
      const { tadoAccessGranted, selectedTadoHome, setHomeId } = tadoStore!;

      return (
        <Mutation
          mutation={SET_TADO_HOME}
          onCompleted={() => {
            setHomeId(selectedTadoHome.id);
            history.push(
              `/devices_inscription/${customerId}/partnership/${pvInstallationId}/tado/devices`
            );
          }}
        >
          {(setTadoHome, { loading }: MutationResult<IMutation>) => {
            return (
              <LoadingButton
                className="update"
                onClick={() => {
                  setTadoHome({
                    variables: {
                      customerId,
                      homeId: selectedTadoHome.id
                    }
                  });
                }}
                disabled={selectedTadoHome.id === '' || !tadoAccessGranted}
                loading={loading}
              >
                {t('TadoPartnership:connectTadoHome')}
              </LoadingButton>
            );
          }}
        </Mutation>
      );
    }
  )
);

export default withRouter(translate(['TadoPartnership'])(TadoSetHomeButton));
