import { action, observable } from 'mobx';
import {
  MSBListFilter,
  MSBListFilterableColumn,
  MSBListFilterRule,
  MSBListSortableColumn,
  MSBListSorting,
  MSBListSortingRule
} from '../../graphql/graphql.schema';

export const possibleMSBFilterOptions: MSBListFilterRule[] = [
  MSBListFilterRule.isNull,
  MSBListFilterRule.isNotNull
];

export const MYLIGHT_ENERGY_MSB_FILTER_RULE: MSBListFilter = {
  column: MSBListFilterableColumn.msbType,
  rule: MSBListFilterRule.isNotNull
};
export const PLANETE_OUI_MSB_FILTER_RULE: MSBListFilter = {
  column: MSBListFilterableColumn.msbType,
  rule: MSBListFilterRule.isNull
};

export class MySmartBatteryListStore {
  @observable
  public sortingRule: MSBListSorting = {
    column: MSBListSortableColumn.msbOk,
    rule: MSBListSortingRule.DESC
  };
  @observable
  public filterRule: MSBListFilter[] = [MYLIGHT_ENERGY_MSB_FILTER_RULE];
  @observable public searchRule: string = '';

  @action.bound
  public setSearchRule(searchRule: string) {
    this.searchRule = searchRule;
  }

  @action.bound
  public resetFilterRule(isReadOnly?: boolean): void {
    if (isReadOnly) {
      this.resetFiltersForPlaneteOuiMsbList();
    } else {
      this.resetFiltersForMylightEnergyMsbList();
    }
  }

  @action.bound
  public resetFiltersForMylightEnergyMsbList(): void {
    this.filterRule = [MYLIGHT_ENERGY_MSB_FILTER_RULE];
  }

  @action.bound
  public resetFiltersForPlaneteOuiMsbList(): void {
    this.filterRule = [PLANETE_OUI_MSB_FILTER_RULE];
  }

  @action.bound
  public toggleFilterRule(
    columnName: MSBListFilterableColumn,
    rule: MSBListFilterRule
  ): void {
    const indexOfColumnFilter = this.filterRule.findIndex(
      ({ column }) => column === columnName
    );
    if (indexOfColumnFilter > -1) {
      const isTheSameFilter =
        this.filterRule[indexOfColumnFilter].rule === rule;
      const canBeRemoved = columnName !== MSBListFilterableColumn.msbType;
      if (isTheSameFilter) {
        if (canBeRemoved) {
          this.filterRule = this.filterRule.filter(
            ({ column }) => column !== columnName
          );
        }
      } else {
        this.filterRule = [
          ...this.filterRule.slice(0, indexOfColumnFilter),
          ...this.filterRule.slice(indexOfColumnFilter + 1),
          { rule, column: columnName }
        ];
      }
    } else {
      this.filterRule = [...this.filterRule, { rule, column: columnName }];
    }
  }

  @action.bound
  public setSortingRule(column: MSBListSortableColumn) {
    this.sortingRule = {
      column,
      rule:
        this.sortingRule.column === column &&
        this.sortingRule.rule === MSBListSortingRule.ASC
          ? MSBListSortingRule.DESC
          : MSBListSortingRule.ASC
    };
  }
}

const mySmartBatteryListStore: MySmartBatteryListStore = new MySmartBatteryListStore();
export default mySmartBatteryListStore;
