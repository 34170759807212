import React from 'react';
import { Alert } from 'reactstrap';
import { translate, TranslationFunction } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { IAllStores } from '../../../stores/allStores.model';

interface IProps {
  mstAlreadyRegistered?: boolean;
  unableToJoinMST?: boolean;
  submittedMST?: string;
  invalidAC?: boolean;
  unknownError?: boolean;
  t: TranslationFunction;
}

const MasterErrors = inject(
  ({ inscriptionError, inscription }: IAllStores) => ({
    mstAlreadyRegistered: inscriptionError.mstAlreadyRegistered,
    unableToJoinMST: inscriptionError.unableToJoinMST,
    submittedMST: inscription.submittedMST,
    invalidAC: inscriptionError.invalidAC,
    unknownError: inscriptionError.unknownError
  })
)(
  observer(
    ({
      mstAlreadyRegistered,
      t,
      unableToJoinMST,
      submittedMST,
      invalidAC,
      unknownError
    }: IProps) => {
      if (mstAlreadyRegistered!) {
        return (
          <div className="pt-4">
            <Alert color="danger">
              {submittedMST}: {t('masterAlreadyUsed')}
            </Alert>
          </div>
        );
      }
      if (unableToJoinMST!) {
        return (
          <div className="pt-4">
            <Alert color="danger">
              {submittedMST}: {t('mstNotConnected')}
            </Alert>
          </div>
        );
      }
      if (invalidAC!) {
        return (
          <div className="pt-4">
            <Alert color="danger">
              {submittedMST}: {t('mstInvalidAC')}
            </Alert>
          </div>
        );
      }
      if (unknownError!) {
        return (
          <div className="pt-4">
            <Alert color="danger">{t('unknownError')}</Alert>
          </div>
        );
      }
      return <span />;
    }
  )
);

export default translate(['Errors'])(MasterErrors);
