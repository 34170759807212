import React from 'react';
import { Col, Collapse, Form, Row } from 'reactstrap';
import { FormInput } from '../../../ui/FormInput/FormInput';
import LoginActionWrapper from '../../../ui/Wrapper/LoginActionWrapper';
import { TranslationFunction } from 'i18next';
import { translate } from 'react-i18next';
import LoadingButton from '../../../ui/Button/LoadingButton/LoadingButton';
import {
  default as SelectInput,
  ISelectValue
} from '../../../ui/Select/Select';
import {
  FCustomer,
  InstallerFormCustomer,
  Maintainer
} from '../../../graphql/graphql.schema';
import 'react-datepicker/dist/react-datepicker.css';
import { ApolloError } from 'apollo-client';
import AddressPicker from '../../../ui/Inputs/AddressForm/AddressForm';
import { CustomForm } from '../../../ui/FormInput/CustomForm';
import BirthDatePicker from '../BirthDatePicker';
import { FieldList } from '../../../ui/DataDisplay/FieldList/FieldList';
import { ListTitle } from '../../../ui/DataDisplay/List/ListHeader';
import ShowAlert from '../../../ui/ShowAlert/ShowAlert';
import { safeMobxInject } from '../../../stores/storeInjectionHelpers';
import CreateCustomerForm from '../../../stores/forms/createCustomer/createCustomerForm';
import Spinner from '../../../ui/Spinner/Spinner';
import { basicSelectValidator } from '../../../utils/validators';
import MissingFieldsAlert from '../../../ui/MissingFormFieldsAlert/MissingFormFieldsAlert';

interface IProps {
  t: TranslationFunction;
  success?: boolean;
  saveLoading?: boolean;
  done?: boolean;
  isColleaguesLoading?: boolean;
  saveError?: ApolloError;
  maintainers: Maintainer[];
  saveFromInstaller: (f: InstallerFormCustomer) => void;
}

interface IStoresProps {
  createCustomer: CreateCustomerForm;
}

const CreateCustomerByInstallerForm = safeMobxInject<IStoresProps, IProps>(
  stores => ({
    createCustomer: stores.Store.createCustomer
  }),
  ({
    t,
    saveFromInstaller,
    saveLoading,
    done,
    maintainers,
    createCustomer,
    isColleaguesLoading
  }: IProps & IStoresProps) => {
    const possibleMaintainers = maintainers
      .filter(m => !!m.companyName)
      .map(item => ({
        value: (item.maintainer && item.maintainer.id) || '',
        label: `${item.companyName} - ${item.companyCode}`
      }));

    const chosenMaintainer = createCustomer.maintainer;

    const handleSave = () => {
      const customer: FCustomer = {
        email: createCustomer.emailAddress || '',
        firstName: createCustomer.firstName || '',
        address: createCustomer.address || '',
        addressSup: createCustomer.additionalAddress || '',
        city: createCustomer.city || '',
        country: createCustomer.country.value || '',
        latitude: createCustomer.latitude || 0,
        longitude: createCustomer.longitude || 0,
        lastName: createCustomer.lastName || '',
        zipCode: createCustomer.postalCode || '',
        phoneNumber: createCustomer.phone || '',
        mobileNumber: createCustomer.mobilePhone || '',
        birthDate: createCustomer.birthDate || new Date()
      };

      const tenant = createCustomer.tenant;
      if (tenant && chosenMaintainer) {
        saveFromInstaller({
          customer,
          tenant: { name: tenant.name, id: tenant.id },
          maintainerCode: chosenMaintainer.companyCode,
          maintainerId:
            chosenMaintainer.maintainer && chosenMaintainer.maintainer.id
        });
      }
    };

    return (
      <Form
        className="pt-5 pb-5"
        style={{
          width: '100%'
        }}
        onSubmit={event => {
          event.preventDefault();
        }}
      >
        <FieldList>
          <Spinner loading={isColleaguesLoading}>
            <SelectInput
              required
              loading={isColleaguesLoading}
              name={t('maintainer')}
              id="assignMaintainer"
              value={createCustomer.getSelectMaintainerValue}
              onChange={(value: ISelectValue) => {
                const maintainer = maintainers.find(
                  m => m.maintainer.id === value.value
                );
                createCustomer.updateMaintainer(maintainer);
              }}
              options={possibleMaintainers}
              error={
                createCustomer.getSelectMaintainerValue &&
                basicSelectValidator(
                  createCustomer.getSelectMaintainerValue.value
                ) &&
                t('Common:maintainerError')
              }
            />
          </Spinner>
          <Collapse
            style={{ paddingTop: '10px' }}
            isOpen={createCustomer.getTenants.length > 1}
          >
            <SelectInput
              id="tenant"
              required
              name={t('tenant')}
              value={createCustomer.getSelectTenantValue}
              options={createCustomer.getSelectTenantOptions}
              error={
                createCustomer.getSelectTenantValue &&
                basicSelectValidator(
                  createCustomer.getSelectTenantValue.value
                ) &&
                t('Common:tenantError')
              }
              onChange={(ten: ISelectValue) => {
                createCustomer.setTenant(ten);
              }}
            />
          </Collapse>
        </FieldList>
        <FieldList>
          <ListTitle text={t('identity')} />
          <FormInput
            required
            id="customerFirstName"
            type="text"
            name={t('firstName')}
            value={createCustomer.firstName}
            onChange={createCustomer.updateFirstName}
            error={
              createCustomer.firstName &&
              createCustomer.firstNameError &&
              t('Common:firstNameError')
            }
          />
          <FormInput
            required
            id="customerLastName"
            type="text"
            name={t('name')}
            value={createCustomer.lastName}
            onChange={createCustomer.updateLastName}
            error={
              createCustomer.lastName &&
              createCustomer.lastNameError &&
              t('Common:lastNameError')
            }
          />
          <CustomForm required name={t('birthDate')}>
            <BirthDatePicker
              birthDate={createCustomer.birthDate}
              onChange={a => {
                createCustomer.updateBirthDate(a || undefined);
              }}
            />
          </CustomForm>
        </FieldList>
        <FieldList>
          <AddressPicker
            initToCurrentPos={true}
            cityError={createCustomer.cityError}
            addressError={createCustomer.addressError}
            zipCodeError={createCustomer.postalCodeError}
            geolocationError={createCustomer.geolocationError}
            zipCode={createCustomer.postalCode}
            address={createCustomer.address}
            city={createCustomer.city}
            country={createCustomer.country}
            additionalAddress={createCustomer.additionalAddress}
            latitude={createCustomer.latitude}
            longitude={createCustomer.longitude}
            onAddressUpdate={createCustomer.updateAddress}
            onZipCodeUpdate={createCustomer.updatePostalCode}
            onCityUpdate={createCustomer.updateCity}
            onCountryUpdate={createCustomer.updateCountry}
            onLatitudeUpdate={createCustomer.updateLatitude}
            onLongitudeUpdate={createCustomer.updateLongitude}
            onAdditionalAddressUpdate={createCustomer.updateAdditionalAddress}
          />
        </FieldList>
        <FieldList>
          <ListTitle text={t('contact')} />
          <CustomForm
            name={t('Common:phoneRequired')}
            labelStyle={{ fontSize: '0.95rem' }}
          >
            <Row>
              <Col xs={12} sm={6}>
                <FormInput
                  id="customerMobilePhone"
                  type="tel"
                  name={t('mobile')}
                  value={createCustomer.mobilePhone}
                  onChange={createCustomer.updateMobilePhone}
                  error={
                    createCustomer.mobilePhone &&
                    createCustomer.mobilePhoneError &&
                    t('Common:phoneError')
                  }
                />
              </Col>
              <Col xs={12} sm={6}>
                <FormInput
                  id="customerPhone"
                  type="tel"
                  name={t('CustomerEdit:phoneNumber')}
                  value={createCustomer.phone}
                  onChange={createCustomer.updatePhone}
                  error={
                    createCustomer.phone &&
                    createCustomer.phoneNumberError &&
                    t('Common:phoneError')
                  }
                />
              </Col>
            </Row>
          </CustomForm>
          <FormInput
            required
            id="customerEmail"
            type="email"
            name={t('email')}
            value={createCustomer.emailAddress}
            onChange={createCustomer.updateEmailAddress}
            error={
              createCustomer.emailAddress
                ? createCustomer.emailTakenError
                  ? t('Common:emailTaken')
                  : createCustomer.emailAddressError
                    ? t('Common:emailAddressError')
                    : undefined
                : undefined
            }
          />
        </FieldList>
        {createCustomer.installerCreateCustomerFormIsInvalid && (
          <ShowAlert message={t('StepTodo:installation')} />
        )}
        <MissingFieldsAlert formType={'createCustomerByInstaller'} />
        <LoginActionWrapper>
          <LoadingButton
            loading={saveLoading}
            done={done}
            onClick={handleSave}
            disabled={createCustomer.installerCreateCustomerFormIsInvalid}
          >
            {t('Common:save')}
          </LoadingButton>
        </LoginActionWrapper>
      </Form>
    );
  }
);

export default translate(['Customer', ' Installation', ' Common'])(
  CreateCustomerByInstallerForm
);
