import * as React from 'react';
import SearchInput from '../../../ui/Inputs/SearchInput';
import { safeMobxInject } from '../../../stores/storeInjectionHelpers';
import classnames from 'classnames';
import { getTenantLogo } from '../../../utils/tools';
import { IconLogout } from '../../../ui/Icons/IconLogout';
import { Link } from 'react-router-dom';
import { translate, TranslationFunction } from 'react-i18next';
import { Button } from 'reactstrap';
import {
  MSBListFilter,
  MSBListFilterableColumn,
  MSBListFilterRule
} from '../../../graphql/graphql.schema';
import IconMylightMSB from '../../svg/IconMylightMSB';
import IconPlaneteOuiMSB from '../../svg/IconPlaneteOuiMSB';

interface IStoresProps {
  searchRule: string;
  setSearchRule: (rule: string) => void;
  filterRule: MSBListFilter[];
  resetFiltersForMylightEnergyMsbList: () => void;
  resetFiltersForPlaneteOuiMsbList: () => void;
  tenant: string;
}

interface IProps {
  isReadOnly?: boolean;
  t: TranslationFunction;
}

const MySmartBatteryHeader = safeMobxInject<IStoresProps, IProps>(
  ({ Store: { profile }, mySmartBatteryList }) => ({
    searchRule: mySmartBatteryList.searchRule,
    setSearchRule: mySmartBatteryList.setSearchRule,
    filterRule: mySmartBatteryList.filterRule,
    resetFiltersForMylightEnergyMsbList:
      mySmartBatteryList.resetFiltersForMylightEnergyMsbList,
    resetFiltersForPlaneteOuiMsbList:
      mySmartBatteryList.resetFiltersForPlaneteOuiMsbList,
    tenant: profile.tenant
  }),
  ({
    searchRule,
    setSearchRule,
    filterRule,
    resetFiltersForMylightEnergyMsbList,
    resetFiltersForPlaneteOuiMsbList,
    isReadOnly,
    tenant,
    t
  }: IProps & IStoresProps) => {
    const onLogoutIconClick = () => {
      localStorage.removeItem('Auth-Token');
    };

    const isMyLightEnergyMSBFilterEnabled = filterRule.some(
      filter =>
        filter.column === MSBListFilterableColumn.msbType &&
        filter.rule === MSBListFilterRule.isNotNull
    );
    const isPlaneteOuiMSBFilterEnabled = filterRule.some(
      filter =>
        filter.column === MSBListFilterableColumn.msbType &&
        filter.rule === MSBListFilterRule.isNull
    );

    return (
      <>
        {isReadOnly && (
          <div className={'my-smart-battery-page-header'}>
            <div className={'my-smart-battery-tenant-logo'}>
              <img src={getTenantLogo(tenant)} />
            </div>
            <Link
              className={'my-smart-battery-logout-icon'}
              to={`/login`}
              onClick={onLogoutIconClick}
            >
              <IconLogout />
            </Link>
          </div>
        )}
        <div
          className={classnames('my-smart-battery-header', {
            'read-only': isReadOnly
          })}
        >
          {isReadOnly && (
            <div className={'my-smart-battery-header-title'}>
              {t('Menu:mySmartBattery')}
            </div>
          )}
          {!isReadOnly && (
            <div className={'my-smart-battery-type-toggle'}>
              <Button
                onClick={() => {
                  resetFiltersForMylightEnergyMsbList();
                }}
                className={classnames('type-toggle-container mylight-energy', {
                  selected: isMyLightEnergyMSBFilterEnabled
                })}
              >
                <div className={'type-toggle-inner-container'}>
                  <IconMylightMSB
                    isMSBActivated={isMyLightEnergyMSBFilterEnabled}
                  />
                  {t('MySmartBattery:mylightEnergyProvider')}
                </div>
              </Button>
              <Button
                onClick={() => {
                  resetFiltersForPlaneteOuiMsbList();
                }}
                className={classnames('type-toggle-container planete-oui', {
                  selected: isPlaneteOuiMSBFilterEnabled
                })}
              >
                <div className={'type-toggle-inner-container'}>
                  <IconPlaneteOuiMSB
                    isMSBActivated={isPlaneteOuiMSBFilterEnabled}
                  />
                  {t('MySmartBattery:planeteOuiEnergyProvider')}
                </div>
              </Button>
            </div>
          )}
          <div className="my-smart-battery-header-search">
            <SearchInput
              searchInputChange={setSearchRule}
              searchQuery={searchRule}
            />
          </div>
        </div>
      </>
    );
  }
);
export default translate('Menu')(MySmartBatteryHeader);
