import React, { Component } from 'react';
import { Button, Col, Form, Row } from 'reactstrap';

import { inject, observer } from 'mobx-react';
import ListItem from '../../../../ui/DataDisplay/List/ListItem';
import { FormInput } from '../../../../ui/FormInput/FormInput';
import LoadingButton from '../../../../ui/Button/LoadingButton/LoadingButton';
import { translate, TranslationFunction } from 'react-i18next';
import { IAllStores } from '../../../../stores/allStores.model';
import { EnphaseStore } from '../../../../stores/partnerships/enphaseStore';

interface IProps {
  enphaseStore?: EnphaseStore;
  t: TranslationFunction;
  data?: any;
  loading?: boolean;
  updateCustomerEmail?: any;
  createEnphaseAccount: () => void;
}

@inject((allStores: IAllStores) => {
  return {
    enphaseStore: allStores.enphaseStore
  };
})
@observer
export class EnphaseEmailView extends Component<IProps> {
  public render() {
    const {
      enphaseStore,
      loading,
      updateCustomerEmail,
      data,
      t,
      createEnphaseAccount
    } = this.props;
    const {
      useOldEmail,
      updateEmailChoice,
      newEmail,
      updateNewEmail,
      validateNewEmail,
      emailConfirmation,
      updateEmailConfirmation,
      validateEmailConfirmation,
      emailErrors,
      customerEmail
    } = enphaseStore!;

    return (
      <ListItem className="custom-list-item email-container">
        <Col>
          <div className="email-title">
            <span>{t('EnphasePartnership:emailExists')}</span>
          </div>
          <Row>
            <div className="email">
              <span>{data ? data.updateCustomer.email : customerEmail}</span>
            </div>
          </Row>
          <Col className="email-buttons">
            <Button
              color="secondary"
              active={useOldEmail}
              onClick={() => updateEmailChoice(true)}
            >
              {t('Common:yes')}
            </Button>
            <Button
              color="secondary"
              active={!useOldEmail}
              onClick={() => updateEmailChoice(false)}
            >
              {t('Common:no')}
            </Button>
          </Col>
        </Col>
        {!useOldEmail && (
          <Form
            className="form-wrapper"
            onSubmit={e => {
              e.preventDefault();
            }}
          >
            <FormInput
              id="email"
              name={t('EnphasePartnership:email')}
              type="email"
              value={newEmail}
              error={newEmail && validateNewEmail}
              onChange={value => updateNewEmail(value)}
            />
            <div className="input-top-interval">
              <FormInput
                id="email-confirmation"
                name={t('EnphasePartnership:emailConfirmation')}
                type="email"
                value={emailConfirmation}
                error={emailConfirmation && validateEmailConfirmation}
                onChange={value => updateEmailConfirmation(value)}
                disabled={!!validateNewEmail}
              />
            </div>
            <Col className="email-warning">
              <span>{t('EnphasePartnership:emailWarning')}</span>
            </Col>
            <Row className="form-buttons">
              <Col>
                <LoadingButton
                  loading={loading}
                  onClick={() =>
                    updateCustomerEmail(emailConfirmation).then(() =>
                      createEnphaseAccount()
                    )
                  }
                  done={data}
                  fail={emailErrors.length !== 0}
                  disabled={
                    emailConfirmation === '' || !!validateEmailConfirmation
                  }
                  className={'update-email'}
                >
                  {t('EnphasePartnership:updateEmail')}
                </LoadingButton>
              </Col>
            </Row>
            {emailErrors.length > 0 && (
              <span className="header-error-container bottom">
                {emailErrors.map(e => (
                  <span className="header-error" key={e}>
                    {e}
                  </span>
                ))}
              </span>
            )}
          </Form>
        )}
      </ListItem>
    );
  }
}

export default translate(['Common', 'EnphasePartnership'])(EnphaseEmailView);
