import * as React from 'react';
import { Col, Container, Row } from 'reactstrap';

import { Mutation, MutationResult } from 'react-apollo';
import gql from 'graphql-tag';
import {
  Customer,
  InstallerFormCustomer,
  MaintainerFormCustomer
} from '../../../graphql/graphql.schema';
import { inject, observer } from 'mobx-react';
import { Store } from '../../../stores';
import { translate, TranslationFunction } from 'react-i18next';
import AfterCreateCustomerModal from '../AfterCreateCustomerModal';
import CreateCustomerByMaintainerForm from './CreateCustomerByMaintainerForm';
import { IAllStores } from '../../../stores/allStores.model';
import { ISnackBarParams } from '../../../stores/userFeedback/snackbarStore';
import IsolatedComponent from '../../../ui/IsolatedComponent/IsolatedComponent';
import {
  getErrorsMessageGraphQl,
  IErrorMessageGraphql
} from '../../../utils/graphQl';

const LINK_INSTALLER_TO_CUSTOMER = gql`
  mutation assignInstaller($idCustomer: String!, $idInstaller: String) {
    assignInstaller(idCustomer: $idCustomer, idInstaller: $idInstaller) {
      id
    }
  }
`;

const CREATE_CUSTOMER = gql`
  mutation createCustomerFromMaintainer($customer: MaintainerFormCustomer!) {
    createCustomerFromMaintainer(input: $customer) {
      id
      pvInstallations {
        id
      }
    }
  }
`;

interface IState {
  installerId?: string;
  pvInstallationId?: string;
  showModal: boolean;
  customerId?: string;
}

interface IProps {
  Store?: Store;
  t: TranslationFunction;
  showErrorSnackBar?: (params: ISnackBarParams) => void;
}

@inject((allStores: IAllStores) => ({
  showErrorSnackBar: allStores.snackBar.showErrorSnackBar,
  Store: allStores.Store
}))
@observer
class CreateCustomerByMaintainerQuery extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      installerId: undefined,
      showModal: false,
      customerId: undefined
    };
  }

  public render() {
    const showErrorSnackBar = this.props.showErrorSnackBar!;
    return (
      <IsolatedComponent
        title={this.props.t('Common:createCustomerTitle')}
        sm={10}
        md={9}
        lg={7}
        xl={6}
      >
        <Container fluid>
          <Mutation
            mutation={LINK_INSTALLER_TO_CUSTOMER}
            onError={() => {
              showErrorSnackBar({
                text: this.props.t('assignError')
              });
            }}
            onCompleted={() => {
              this.setState({
                showModal: true
              });
            }}
          >
            {(linkInstaller, { loading: linkLoading, called: linkCalled }) => (
              <Mutation
                mutation={CREATE_CUSTOMER}
                onError={e => {
                  const errorsMessage = getErrorsMessageGraphQl(
                    e.graphQLErrors
                  );
                  const isEmailTaken = errorsMessage.some(
                    (error: IErrorMessageGraphql) => {
                      return error.message
                        ? error.errorCode.includes('customerMailAlreadyExist')
                        : false;
                    }
                  );
                  this.props.Store!.createCustomer.emailIsTaken(isEmailTaken);

                  if (!isEmailTaken) {
                    const isSrsError = errorsMessage.some(
                      (error: IErrorMessageGraphql) => {
                        return error.message
                          ? error.errorCode.includes('createCustomerSrsFailed')
                          : false;
                      }
                    );

                    if (isSrsError) {
                      showErrorSnackBar({
                        text: this.props.t('Errors:saveCustomerSrs'),
                        duration: 5000
                      });
                    } else {
                      showErrorSnackBar({
                        text: this.props.t('Errors:saveCustomer')
                      });
                    }
                  }
                }}
                onCompleted={response => {
                  const customerId = response.createCustomerFromMaintainer.id;
                  const pvInstallationId =
                    response.createCustomerFromMaintainer.pvInstallations[0].id;
                  if (this.state.installerId) {
                    linkInstaller({
                      variables: {
                        idCustomer: customerId,
                        idInstaller: this.state.installerId
                      }
                    });
                    this.setState({
                      customerId,
                      pvInstallationId
                    });
                  } else {
                    this.setState({
                      customerId,
                      pvInstallationId,
                      showModal: true
                    });
                  }
                }}
              >
                {(
                  createCustomer,
                  {
                    called,
                    error: saveError,
                    loading: saveLoading
                  }: MutationResult<Customer>
                ) => {
                  return (
                    <CreateCustomerByMaintainerForm
                      saveLoading={saveLoading || linkLoading}
                      saveError={saveError}
                      done={!saveLoading && called && !saveError && linkCalled}
                      saveFromMaintainer={(f: MaintainerFormCustomer) => {
                        createCustomer({
                          variables: { customer: f }
                        });
                        if (f.installerId) {
                          this.setState({
                            installerId: f.installerId
                          });
                        }
                      }}
                      saveFromInstaller={(f: InstallerFormCustomer) => {
                        createCustomer({
                          variables: { customer: f }
                        });
                      }}
                    />
                  );
                }}
              </Mutation>
            )}
          </Mutation>
          <AfterCreateCustomerModal
            customerId={this.state.customerId}
            pvInstallationId={this.state.pvInstallationId}
            toggleModal={() => this.setState({ showModal: false })}
            showModal={this.state.showModal}
          />
        </Container>
      </IsolatedComponent>
    );
  }
}

export default translate(['Errors, Common'])(CreateCustomerByMaintainerQuery);
