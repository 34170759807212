import scroll from 'react-scroll';
import { isMobileDevice } from './tools';
import { useEffect } from 'react';
import inscriptionStepsStore from '../stores/inscription/inscriptionStepsStore';

export const useScrollToInscriptionStep = (step: string) => {
  useEffect(() => {
    if (isMobileDevice()) {
      const headerOffset = -62;
      const stepItem = inscriptionStepsStore.menuItems.find(
        s => s.key === step
      );
      const stepItemOffset = stepItem ? stepItem.id * 65 : 0;
      const offset = headerOffset + stepItemOffset;
      scroll.scroller.scrollTo(`scroll-${step}`, {
        offset,
        smooth: true,
        containerId: `main-menu-scroll-container`,
        isDynamic: true,
        ignoreCancelEvents: true
      });
    } else {
      scroll.animateScroll.scrollToTop({
        containerId: `registration-stepper-content`,
        duration: 0,
        isDynamic: true,
        ignoreCancelEvents: true
      });
    }
  }, []);
};
