import * as React from 'react';
import ForgotPasswordForm from './ForgotPasswordForm';
import { Background } from '../../ui/Background/Background';
import { LoginBox } from '../../ui/LoginBox/LoginBox';
import { get } from 'lodash';
import gql from 'graphql-tag';
import { Mutation, MutationResult } from 'react-apollo';

const RESET_PASSWORD = gql`
  mutation passwordForgot($email: String) {
    passwordForgot(email: $email) {
      success
    }
  }
`;

interface IPasswordForgot {
  success: boolean;
}

interface IResponse {
  passwordForgot: IPasswordForgot;
}

const ForgotPasswordView = () => {
  return (
    <Mutation mutation={RESET_PASSWORD}>
      {(
        passwordForgot,
        { loading, data, called }: MutationResult<IResponse>
      ) => {
        return (
          <Background>
            <LoginBox>
              <ForgotPasswordForm
                onSubmit={(email: string) =>
                  passwordForgot({ variables: { email } })
                }
                loading={loading}
                called={called}
                success={
                  data ? get(data, 'passwordForgot.success', false) : null
                }
              />
            </LoginBox>
          </Background>
        );
      }}
    </Mutation>
  );
};

export default ForgotPasswordView;
