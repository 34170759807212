import React from 'react';
import { translate, TranslationFunction } from 'react-i18next';
import { MsbStatus } from './constants';
import './MsbStatusText.scss';

interface IProps {
  date: string;
  status: MsbStatus;
  t: TranslationFunction;
}

const MsbStatusText = ({ t, date, status }: IProps) => {
  const getText = `${t(`msbStatus.${status}`)} ${date}`;
  return <span className={`msb-status-text msb-status-text--${status}`}>{getText}</span>;
};
export default translate(['MySmartBattery'])(MsbStatusText);
