import * as React from 'react';
import { Query, QueryResult } from 'react-apollo';

import gql from 'graphql-tag';
import { RouteComponentProps, withRouter } from 'react-router';
import IdentityView from './IdentityView';
import { extractCustomer } from '../customersList/customerExtractor';
import { Customer } from '../../../graphql/graphql.schema';

export const GET_CUSTOMER = gql`
  query getCustomer($id: String!) {
    customer(id: $id) {
      id
      address
      addressSup
      email
      firstName
      lastName
      zipCode
      city
      birthDate
      birthDate
      phoneNumber
      mobileNumber
      country
      smsAlertsEnabled
      emailAlertsEnabled
      canWrite
      smsAlertsEnabled
      latitude
      longitude
      emailAlertsEnabled
      loginAsByMaintainerEnabled
      pvInstallations {
        id
        srsRegistrationDate
        srsRegistrationStatus
        canWrite
        currency
        panelCount
        canWrite
        panelWattPeak
        panelManufacturer
        srsLicenceStatus
        tenant {
          id
          name
        }
        electricInstallations {
          id
          gridType
          rackingPower
          sellingPower
          sellingEnergyPrice
          powerContractType
          powerProvider
          internetServiceProvider
          masterTypeInternetConnection
          internetBoxModel
          pdlNumber
          simSerialNumber
          simRouterReference
        }
      }
    }
  }
`;

interface IResponse {
  customer: Customer;
}

interface IMatchParams {
  id: string;
}

export interface IProps extends RouteComponentProps<IMatchParams> {}

const IdentityQuery = ({
  match: {
    params: { id }
  },
  history
}: IProps) => {
  return (
    <Query query={GET_CUSTOMER} variables={{ id }} fetchPolicy={'no-cache'}>
      {({ loading, data, error }: QueryResult<IResponse>) => {
        const d =
          data && data.customer ? extractCustomer(data.customer) : undefined;
        return (
          <IdentityView
            goToEdit={() => {
              history.push(`/customers/${id}/edit/identity`);
            }}
            customer={d}
            pvInstallationId={
              data &&
              data.customer &&
              data.customer.pvInstallations &&
              data.customer.pvInstallations[0].id
            }
            loading={loading}
            error={error ? error.message : undefined}
          />
        );
      }}
    </Query>
  );
};

export default withRouter(IdentityQuery);
