export interface IErrorMessageGraphql {
  message?: string;
  error: string;
  errorCode: string;
  status?: number;
}

export interface IErrorExtensionsGraphql {
  code: string;
  exception: {
    message: IErrorMessageGraphql;
    response: IErrorMessageGraphql;
    status: number;
  };
}

export interface IErrorGraphql {
  extensions: IErrorExtensionsGraphql;
  locations: object[];
  message: IErrorMessageGraphql;
  path: string[];
}

export function getErrorsMessageGraphQl(
  graphQLErrors: ReadonlyArray<IErrorGraphql>
): IErrorMessageGraphql[] {
  return graphQLErrors.map((graphQLError: IErrorGraphql) => {
    return graphQLError.message;
  });
}
