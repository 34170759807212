import React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { translate, TranslationFunction } from 'react-i18next';
import { Row } from 'reactstrap';
import { getAddressLink } from '../../utils/tools';
import { Query, QueryResult } from 'react-apollo';
import { GET_CUSTOMER } from '../../components/customers/identity/IdentityQuery';
import { Customer } from '../../graphql/graphql.schema';

interface IResponse {
  customer: Customer;
}

interface IMatchParams {
  customerId: string;
}

interface IProps extends RouteComponentProps<IMatchParams> {
  Store?: any;
  t: TranslationFunction;
}

const RegistrationStepperHeader = ({
  match: {
    params: { customerId }
  },
  t
}: IProps) => {
  return (
    <Query query={GET_CUSTOMER} variables={{ id: customerId }}>
      {({ loading, data, error }: QueryResult<IResponse>) => {
        const lastName =
          (data && data.customer && data.customer.lastName) || '-';
        const firstName =
          (data && data.customer && data.customer.firstName) || '-';

        const zipCode = (data && data.customer && data.customer.zipCode) || '';
        const city = (data && data.customer && data.customer.city) || '';
        const country = (data && data.customer && data.customer.country) || '';

        const address = (data && data.customer && data.customer.address) || '';

        const adressStr = `${zipCode} ${city} ${country}`;
        return (
          <Row className={'registration-stepper-header'}>
            <div className={'header-content'}>
              <div className={'arrow-back'}>
                <Link to={`/customers/${customerId}/identity`}>
                  <i className="icon-full-arrow-right" />
                </Link>
              </div>
              <div className={'customer-info'}>
                <div>
                  <span className={'customer-surname'}>{lastName}</span>
                  <span className={'customer-name'}>{firstName || '-'}</span>
                </div>
                <div>
                  <span className={'customer-address'}>
                    <a
                      target="_blank"
                      href={getAddressLink(`${address}, ${zipCode} ${city}`)}
                    >
                      {adressStr}
                    </a>
                  </span>
                </div>
              </div>
            </div>
            <div className={'header-title'}>
              <span>{t(`DevicesInscription:inscriptionInProgress`)}</span>
            </div>
          </Row>
        );
      }}
    </Query>
  );
};

export default translate('DevicesInscription')(
  withRouter(RegistrationStepperHeader)
);
