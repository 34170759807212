import * as React from 'react';
import { Mutation, MutationResult, Query } from 'react-apollo';

import gql from 'graphql-tag';
import { RouteComponentProps, withRouter } from 'react-router';
import { observer, inject } from 'mobx-react';
import EnphaseRegistrationView from './EnphaseRegistrationView';
import { TranslationFunction } from 'i18next';
import { translate } from 'react-i18next';
import { Device } from '../../../../graphql/graphql.schema';
import { IAllStores } from '../../../../stores/allStores.model';
import { ISnackBarParams } from '../../../../stores/userFeedback/snackbarStore';
import { EnphaseStore } from '../../../../stores/partnerships/enphaseStore';

export const CREATE_ENPHASE_CUSTOMER = gql`
  mutation enphaseCreateAccount($id: String!) {
    enphaseCreateAccount(id: $id)
  }
`;
export const CHECK_ENPHASE_CONNECTION = gql`
  query checkEnphaseConnection($customerId: String!) {
    devices(customerId: $customerId) {
      macSerialNumber
      deviceTypeId
      type
    }
    getOneToOneCustomerData(customerId: $customerId) {
      customer {
        email
      }
    }
  }
`;

export interface IProps extends RouteComponentProps<IMatchParams> {
  showSnackBar?: (params: ISnackBarParams) => void;
  enphaseStore?: EnphaseStore;
  t: TranslationFunction;
}

interface IMatchParams {
  customerId: string;
}

interface IMutation {
  mutationResponse?: any;
}
interface IResponse {
  devices?: Device[];
  getOneToOneCustomerData: any;
}

const EnphaseRegistrationQuery = inject(
  ({ snackBar: { showSnackBar }, enphaseStore }: IAllStores) => ({
    showSnackBar,
    enphaseStore
  })
)(
  observer(
    ({
      showSnackBar,
      enphaseStore,
      t,
      history,
      match: {
        params: { customerId }
      }
    }: IProps) => {
      return (
        <Mutation
          mutation={CREATE_ENPHASE_CUSTOMER}
          onError={error => {
            const {
              addRegistrationError,
              clearRegistrationErrors,
              status
            } = enphaseStore!;
            clearRegistrationErrors();
            error.graphQLErrors.map(e => {
              if (e.message.error === 'enpahse.user.already.registered') {
                addRegistrationError(`${t('email')}`);
                if (status !== 'connected') {
                  showSnackBar!({ text: t('email') });
                }
              } else {
                addRegistrationError(`${t('registration')}`);
                if (status !== 'connected') {
                  showSnackBar!({ text: t('registration') });
                }
              }
            });
          }}
        >
          {(enphaseCreateAccount, { loading }: MutationResult<IMutation>) => {
            const { setAllDevices } = enphaseStore!;
            return (
              <Query
                query={CHECK_ENPHASE_CONNECTION}
                variables={{ customerId }}
                fetchPolicy={'no-cache'}
                onCompleted={(response: IResponse) => {
                  const devices = response && response.devices;
                  const email =
                    response && response.getOneToOneCustomerData.customer.email;
                  enphaseStore!.setCustomerEmail(email);
                  setAllDevices(devices || []);
                }}
              >
                {() => {
                  return (
                    <EnphaseRegistrationView
                      loading={loading}
                      createEnphaseAccount={async () => {
                        await enphaseCreateAccount({
                          variables: {
                            id: customerId
                          }
                        });
                      }}
                    />
                  );
                }}
              </Query>
            );
          }}
        </Mutation>
      );
    }
  )
);

export default withRouter(
  translate(['EnphaseErrors'])(EnphaseRegistrationQuery)
);
