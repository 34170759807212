import * as React from 'react';
import { Mutation, MutationResult, Query, QueryResult } from 'react-apollo';

import gql from 'graphql-tag';
import { RouteComponentProps, withRouter } from 'react-router';
import EditIdentityView from './EditIdentityView';
import { Customer, ECustomer } from '../../../../graphql/graphql.schema';
import { inject } from 'mobx-react';
import { IAllStores } from '../../../../stores/allStores.model';
import { EditCustomer } from '../../../../stores/editCustomer/editCustomer';
import { translate, TranslationFunction } from 'react-i18next';
import { ISnackBarParams } from '../../../../stores/userFeedback/snackbarStore';
import {
  getErrorsMessageGraphQl,
  IErrorMessageGraphql
} from '../../../../utils/graphQl';

export const GET_CUSTOMER = gql`
  query getCustomer($customerId: String!) {
    customer(id: $customerId) {
      id
      address
      email
      firstName
      lastName
      zipCode
      city
      addressSup
      birthDate
      birthDate
      phoneNumber
      mobileNumber
      country
      smsAlertsEnabled
      emailAlertsEnabled
      canWrite
      smsAlertsEnabled
      latitude
      longitude
      emailAlertsEnabled
    }
  }
`;

export const EDIT_CUSTOMER = gql`
  mutation editCustomer($customer: ECustomer!) {
    updateCustomer(customer: $customer) {
      id
      address
      addressSup
      email
      firstName
      lastName
      zipCode
      city
      birthDate
      phoneNumber
      mobileNumber
      country
      canWrite
      smsAlertsEnabled
      latitude
      longitude
      emailAlertsEnabled
    }
  }
`;

interface IResponse {
  customer: Customer;
}

interface IMutationInput {
  customer: ECustomer;
}

interface IMatchParams {
  id: string;
}

export interface IProps extends RouteComponentProps<IMatchParams> {
  editCustomer: EditCustomer;
  t: TranslationFunction;
  showSnackBar?: (params: ISnackBarParams) => void;
}

const EditIdentityQuery = inject((allStores: IAllStores) => {
  return {
    editCustomer: allStores.editCustomer,
    showSnackBar: allStores.snackBar.showSnackBar
  };
})(
  ({
    history,
    match: {
      params: { id: customerId }
    },
    t,
    showSnackBar,
    editCustomer
  }: IProps) => {
    return (
      <Query
        query={GET_CUSTOMER}
        variables={{ customerId }}
        fetchPolicy="cache-and-network"
        onCompleted={(data: IResponse) => {
          editCustomer!.init(data.customer);
        }}
      >
        {({ loading }: QueryResult<IResponse>) => {
          return (
            <Mutation
              mutation={EDIT_CUSTOMER}
              onError={e => {
                const errorsMessage = getErrorsMessageGraphQl(e.graphQLErrors);

                const isCustomerNotFound = errorsMessage.some(
                  (error: IErrorMessageGraphql) => {
                    return error.message
                      ? error.errorCode.includes('dbUpdateCustomer')
                      : false;
                  }
                );

                if (isCustomerNotFound) {
                  showSnackBar!({
                    text: t('Errors:customerNotFound'),
                    type: 'error'
                  });
                }

                const isEmailTaken = errorsMessage.some(
                  (error: IErrorMessageGraphql) => {
                    return error.message
                      ? error.errorCode.includes('customerMailAlreadyExist')
                      : false;
                  }
                );
                editCustomer!.emailIsTaken(isEmailTaken);
              }}
              onCompleted={() => {
                showSnackBar!({
                  text: t('Success:updateCustomer'),
                  type: 'success'
                });
                history.push(`/customers/${customerId}/identity`);
              }}
            >
              {(
                saveCustomer,
                { called, loading: saveLoading }: MutationResult<IMutationInput>
              ) => (
                <EditIdentityView
                  saveCustomer={(customer: ECustomer) =>
                    saveCustomer({ variables: { customer } })
                  }
                  saveLoading={saveLoading}
                  loading={loading}
                  done={called && !saveLoading}
                />
              )}
            </Mutation>
          );
        }}
      </Query>
    );
  }
);

export default translate(['Success'])(withRouter(EditIdentityQuery));
