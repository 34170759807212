import * as React from 'react';
import { Query, QueryResult } from 'react-apollo';

import gql from 'graphql-tag';
import { translate, TranslationFunction } from 'react-i18next';
import { GridType } from '@mylight/data-model';
import LoadingButton from '../../../ui/Button/LoadingButton/LoadingButton';

export const LAUNCH_DIAGNOSTIC_TESTS = gql`
  query launchDiagnosticTest($email: String, $gridType: String) {
    launchDiagnosticTest(idCustomer: $email, gridType: $gridType)
  }
`;
interface IDiagnosticStatus {
  customer: string;
  id: string;
  statusT48: 'pending' | 'ok' | 'failure';
}
interface IResponse {
  diagnosticStatusByCustomerEmail: IDiagnosticStatus;
}

interface IProps {
  id: string;
  t: TranslationFunction;
  gridType?: string;
}

interface IState {
  status: 'todo' | 'loading' | 'done';
}

class StartDiagnosticT48 extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = { status: 'todo' };
  }
  public render() {
    const { gridType, id, t } = this.props;
    return (
      <Query query={LAUNCH_DIAGNOSTIC_TESTS} skip={true}>
        {({ loading, data, error, client }: QueryResult<IResponse>) => {
          return (
            <LoadingButton
              title={
                gridType !== GridType.SINGLE_PHASE &&
                gridType !== GridType.THREE_PHASE &&
                t('incorrectPhase')
              }
              disabled={
                gridType !== GridType.SINGLE_PHASE &&
                gridType !== GridType.THREE_PHASE
              }
              onClick={async () => {
                this.setState({ status: 'loading' });
                const result = await client.query({
                  query: LAUNCH_DIAGNOSTIC_TESTS,
                  variables: { gridType, email: id }
                });

                if (result.errors) {
                  this.setState({ status: 'todo' });
                } else {
                  this.setState({ status: 'done' });
                }
              }}
              loading={this.state.status === 'loading'}
              done={this.state.status === 'done'}
            >
              {t('startButton')}
            </LoadingButton>
          );
        }}
      </Query>
    );
  }
}

export default translate('Diagnostic')(StartDiagnosticT48);
