import React from 'react';

interface IProps {
  isMSBActivated?: boolean;
  isMSBPotentiallyStuck?: boolean;
}

export const IconMylightMSB = (props: IProps) => {
  const fillColor = props.isMSBActivated
    ? '#0390a7'
    : props.isMSBPotentiallyStuck
      ? '#ffc107'
      : '#afafb7';
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" version="1.1" fill="none" xmlns="http://www.w3.org/2000/svg" >
      <g transform="matrix(1,0,0,1,-10,-15)">
          <g transform="matrix(0.769231,0,0,0.769231,2.30769,3.46154)">
              <path d="M16.914,28.558L14.759,28.558L14.759,26.774L18.886,26.774L18.886,37.673L16.914,37.673L16.914,28.558Z"  fill={ fillColor }/>
          </g>
          <g transform="matrix(0.769231,0,0,0.769231,2.30769,3.46154)">
              <path d="M20.295,34.542L22.319,34.542C22.484,35.425 23.165,36.041 24.142,36.041C25.302,36.041 26.016,35.273 26.016,33.958C26.016,32.807 25.304,32.024 24.142,32.024C23.329,32.024 22.748,32.44 22.45,33.058L20.694,32.525L21.439,26.776L27.142,26.776L27.142,28.56L22.964,28.56L22.599,31.207C23.047,30.623 23.726,30.323 24.572,30.323C26.446,30.323 28.004,31.74 28.004,33.956C28.004,36.339 26.412,37.888 24.142,37.888C22.036,37.89 20.478,36.507 20.295,34.542Z"  fill={ fillColor }/>
          </g>
          <g transform="matrix(0.769231,0,0,0.769231,2.30769,3.46154)">
              <path d="M29.264,33.991L29.264,30.458C29.264,28.092 30.856,26.557 33.126,26.557C35.397,26.557 36.988,28.09 36.988,30.458L36.988,33.991C36.988,36.357 35.397,37.892 33.126,37.892C30.856,37.89 29.264,36.357 29.264,33.991ZM35.016,33.991L35.016,30.458C35.016,29.175 34.304,28.408 33.126,28.408C31.949,28.408 31.236,29.173 31.236,30.458L31.236,33.991C31.236,35.273 31.949,36.041 33.126,36.041C34.304,36.041 35.016,35.273 35.016,33.991Z"  fill={ fillColor }/>
          </g>
          <g transform="matrix(0.769231,0,0,0.769231,2.30769,3.46154)">
              <path d="M12.021,45.373L12.021,26.786L10,26.786L10,46.237L11.162,47.407L26.42,47.407L26.42,45.373L12.021,45.373Z"  fill={ fillColor }/>
          </g>
          <g transform="matrix(0.769231,0,0,0.769231,2.30769,3.46154)">
              <path d="M41.877,44.225L41.877,35.292L39.881,35.292L39.881,44.225L38.719,45.391L29.833,45.391L29.833,47.398L38.719,47.398L39.881,48.565L39.881,54L41.877,54L41.877,48.565L43.039,47.398L48,47.398L48,45.391L43.039,45.391L41.877,44.225Z"  fill={ fillColor }/>
          </g>
          <g transform="matrix(0.769231,0,0,0.769231,2.30769,3.46154)">
              <path d="M26.238,20.534L20.733,15L11.162,15L10,16.17L10,23.247L12.021,23.247L12.021,17.034L20.18,17.034L26.236,23.123L32.302,17.034L39.895,17.034L39.895,31.859L41.918,31.859L41.918,16.17L40.756,15L31.75,15L26.238,20.534Z"  fill={ fillColor }/>
          </g>
      </g>
    </svg>
  );
};

export default IconMylightMSB;
