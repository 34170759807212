import * as React from 'react';
import { Mutation, MutationResult, Query, QueryResult } from 'react-apollo';

import gql from 'graphql-tag';
import { RouteComponentProps, withRouter } from 'react-router';
import {
  Customer,
  EElectricInstallation,
  ElectricInstallation,
  EnergyDistributor,
  EPVInstallation,
  PVInstallation
} from '../../../../graphql/graphql.schema';
import { translate, TranslationFunction } from 'react-i18next';
import { ISnackBarParams } from '../../../../stores/userFeedback/snackbarStore';
import EditInstallationForm from './EditInstallationForm';
import { safeMobxInject } from '../../../../stores/storeInjectionHelpers';
import { ApolloError } from 'apollo-client';

export const GET_INSTALLATION = gql`
  query getInstallation($pVInstallationId: String!, $customerId: String!) {
    pvInstallationById(id: $pVInstallationId) {
      id
      canWrite
      panelCount
      panelWattPeak
      panelManufacturer
      srsRegistrationDate
      srsRegistrationStatus
      currency
      srsLicenceStatus
      hasMultipleProductionCounter
      electricInstallations {
        id
        sellingPower
        sellingEnergyPrice
        powerProvider
        powerContractType
        powerContractOption
        rackingPower
        internetBoxModel
        gridType
        internetServiceProvider
        masterTypeInternetConnection
        timeSlots
        pdlNumber
        simSerialNumber
        simRouterReference
        energyDistributor {
          id
          name
          pdlPattern
        }
      }
      tenant {
        id
        name
      }
    }
    customer(id: $customerId) {
      id
      country
      msbStatus
    }
  }
`;

export const GET_ENERGY_DISTRIBUTOR = gql`
  query getEnergyDistributorByElectricInstallationId(
    $electricInstallationId: String!
  ) {
    energyDistributorByElectricInstallationId(electricInstallationId: $electricInstallationId) {
      id
      name
      pdlPattern
    }
  }
`;

export const UPDATE_INSTALLATIONS = gql`
  mutation editInstallation(
    $pVInstallation: EPVInstallation!
    $electricalInstallation: EElectricInstallation!
  ) {
    updatePVInstallation(pvInstallation: $pVInstallation) {
      id
      canWrite
      panelCount
      panelWattPeak
      panelManufacturer
      srsRegistrationDate
      srsRegistrationStatus
      currency
      srsLicenceStatus
    }
    updateElectricInstallation(electricInstallation: $electricalInstallation) {
      id
      gridType
      rackingPower
      powerContractType
      powerContractOption
      timeSlots
      powerProvider
      sellingPower
      sellingEnergyPrice
      internetServiceProvider
      internetBoxModel
      pdlNumber
      masterTypeInternetConnection
      simSerialNumber
      simRouterReference
    }
  }
`;

export interface IUpdateInstallation {
  pVInstallation: EPVInstallation;
  electricalInstallation: EElectricInstallation;
}

interface IResponse {
  pvInstallationById: PVInstallation;
  customer: Customer;
}

interface IEnergyDistributorResponse {
  energyDistributorByElectricInstallationId: EnergyDistributor;
}

interface IMatchParams {
  customerId: string;
  pVInstallationId: string;
}

export interface IProps extends RouteComponentProps<IMatchParams> {
  t: TranslationFunction;
}

interface IStoresProps {
  electricInstallationId?: string;
  setElectricalInstallation: (
    electricInstallation: ElectricInstallation
  ) => void;
  setEnergyDistributor: (energyDistributor: EnergyDistributor) => void;
  setPvInstallation: (pvE: PVInstallation) => void;
  showSnackBar: (params: ISnackBarParams) => void;
  setCustomerCountry: (country: string) => void;
  setCustomerTenant: (tenant: string) => void;
}

const EditInstallationQuery = safeMobxInject<IStoresProps, IProps>(
  allStores => ({
    electricInstallationId: allStores.editElectricalInstallation.id,
    setElectricalInstallation:
      allStores.editElectricalInstallation.setElectricalInstallation,
    setEnergyDistributor:
      allStores.editElectricalInstallation.setEnergyDistributor,
    setPvInstallation: allStores.editPVInstallation.setPvInstallation,
    showSnackBar: allStores.snackBar.showSnackBar,
    setCustomerCountry: allStores.inscription.setCustomerCountry,
    setCustomerTenant: allStores.inscription.setCustomerTenant
  }),
  ({
    electricInstallationId,
    setElectricalInstallation,
    setPvInstallation,
    setEnergyDistributor,
    t,
    showSnackBar,
    history,
    match: {
      params: { pVInstallationId, customerId }
    },
    setCustomerCountry,
    setCustomerTenant
  }: IProps & IStoresProps) => {
    return (
      <Query
        query={GET_INSTALLATION}
        variables={{ pVInstallationId, customerId }}
        fetchPolicy={'cache-and-network'}
        onCompleted={(data: IResponse) => {
          const pvInstallation = data.pvInstallationById;
          setPvInstallation(pvInstallation);
          const [electricalInstall]: ElectricInstallation[] =
            pvInstallation.electricInstallations || [];
          if (electricalInstall) {
            setElectricalInstallation(electricalInstall);
          }
          if (pvInstallation && pvInstallation.tenant) {
            setCustomerTenant(pvInstallation.tenant.name!);
          }
          if (data.customer && data.customer.country) {
            setCustomerCountry(data.customer.country);
          }
        }}
        onError={() => {
          showSnackBar({
            text: t('Common:loadError'),
            type: 'error'
          });
          setTimeout(
            () => history.push(`/customers/${customerId}/installation`),
            500
          );
        }}
      >
        {({ loading: loadingGetInstallation, data: dataEditInstallation}: QueryResult<IResponse>) => {
          if (!electricInstallationId) {
            return '<></>';
          }
          return (
            <Query
              query={GET_ENERGY_DISTRIBUTOR}
              variables={{ electricInstallationId }}
              fetchPolicy={'no-cache'}
              onCompleted={(data: IEnergyDistributorResponse) => {
                if (data.energyDistributorByElectricInstallationId) {
                  const energyDistributor =
                    data.energyDistributorByElectricInstallationId;
                  setEnergyDistributor(energyDistributor);
                }
              }}
              onError={() => {
                showSnackBar({
                  text: t('Common:loadError'),
                  type: 'error'
                });
                setTimeout(
                  () => history.push(`/customers/${customerId}/installation`),
                  500
                );
              }}
            >
              {({ loading: loadingGetEnergyDistributor }: QueryResult<IEnergyDistributorResponse>) => {
                return (
                  <Mutation
                    mutation={UPDATE_INSTALLATIONS}
                    onCompleted={() => {
                      showSnackBar({
                        text: t('Success:updateInstallation'),
                        type: 'success'
                      });
                      history.push(`/customers/${customerId}/installation`);
                    }}
                    onError={(error: ApolloError) => {
                      let errorMessage = t('Errors:serverError');

                      if (error.graphQLErrors[0].message.message) {
                        errorMessage =
                          error.graphQLErrors[0].message.message.message ||
                          error.graphQLErrors[0].message.message ||
                          errorMessage;
                        if (
                          error.graphQLErrors[0].message.message.type ===
                          'PdlIsNotUniqueException'
                        ) {
                          errorMessage = t('Installation:pdlIsNotUnique');
                        }
                      }

                      showSnackBar({
                        text: errorMessage,
                        type: 'error'
                      });
                    }}
                  >
                    {(
                      updateInstallation,
                      {
                        called,
                        data: saveResult,
                        loading: saveLoading
                      }: MutationResult<IUpdateInstallation>
                    ) => {
                      return (
                        <EditInstallationForm
                          customer={dataEditInstallation && dataEditInstallation.customer}
                          loading={loadingGetInstallation || loadingGetEnergyDistributor}
                          done={called && !!saveResult}
                          saveLoading={saveLoading}
                          save={({
                            pVInstallation,
                            electricalInstallation
                          }: IUpdateInstallation) => {
                            updateInstallation({
                              variables: {
                                pVInstallation,
                                electricalInstallation
                              }
                            });
                          }}
                        />
                      );
                    }}
                  </Mutation>
                );
              }}
            </Query>
          );
        }}
      </Query>
    );
  }
);

export default translate('InstallationEdit')(withRouter(EditInstallationQuery));
