import { Col, Row } from 'reactstrap';
import { convertTranslation } from '../../../utils/convertTranslations';
import React from 'react';
import { translate } from 'react-i18next';
import { GMD, MST_G3 } from '../../../stores/devices_inscription/contants';
import { inject, observer } from 'mobx-react';
import { IAllStores } from '../../../stores/allStores.model';
import { IDeviceDetailsProps } from './DeviceDetail';
import { Device } from '../../../stores/inscription/device';
import { withRouter } from 'react-router';
import classnames from 'classnames';
import { CompositeDevice } from '../../../stores/inscription/compositeDevice';
import ETH1Image from '../../../assets/images/devices/device-detail/eth1.png';
import GMDMono1Image from '../../../assets/images/devices/device-detail/gmdmono1.png';
import GMDTri1Image from '../../../assets/images/devices/device-detail/gmdtri1.png';
import GMD2Image from '../../../assets/images/devices/device-detail/gmd2.png';
import SM1Image from '../../../assets/images/devices/device-detail/sm1.png';
import SPImage from '../../../assets/images/devices/device-detail/sp.png';
import MST1Image from '../../../assets/images/devices/device-detail/mst1.png';
import PlugG3Image from '../../../assets/images/devices/device-detail/plugG3.png';
import ZoomG3Image from '../../../assets/images/devices/device-detail/ZoomG3.png';
import { GridType } from '@mylight/data-model';

const DeviceHelp = inject(({ inscription: inscriptionStore }: IAllStores) => {
  return {
    getEditedDevice: inscriptionStore.getEditedDevice
  };
})(
  observer(
    ({
      gridType,
      getEditedDevice,
      t,
      match: {
        params: { ethMac: ethMacFromRouter, plcMac: plcMacFromRouter }
      }
    }: IDeviceDetailsProps) => {
      const editedDevice: Device | undefined = getEditedDevice!({
        ethMac: ethMacFromRouter,
        plcMac: plcMacFromRouter
      });
      const Image: { [key: string]: string } = {
        mst1: MST1Image,
        mst2: SPImage,
        mst_g3tri2: ZoomG3Image,
        mst_g3tri3: GMDTri1Image,
        mst_g3tri4: PlugG3Image,
        mst_g3mono2: ZoomG3Image,
        mst_g3mono3: GMDTri1Image,
        mst_g3mono4: PlugG3Image,
        sw1: SM1Image,
        sw2: SPImage,
        eth1: ETH1Image,
        eth2: SPImage,
        gmdmono1: GMDMono1Image,
        gmdmono2: GMD2Image,
        gmdtri1: GMDTri1Image,
        gmdtri2: GMD2Image
      };
      if (editedDevice) {
        const { getDeviceType, isDeviceHasExtendedDescription } = editedDevice;
        let deviceType = getDeviceType;

        if (editedDevice instanceof CompositeDevice) {
          const { isModbus, getModbusReferenceId } = editedDevice;
          if (isModbus && getModbusReferenceId) {
            deviceType = getModbusReferenceId;
          }
        }
        const grid =
          deviceType === GMD || deviceType === MST_G3
            ? gridType && gridType !== ''
              ? gridType
              : 'mono'
            : '';
        const imageType = `${deviceType.toLowerCase()}${grid!.toLowerCase()}`;

        const descriptionBlocks = isDeviceHasExtendedDescription
          ? [1, 2, 3, 4, 5]
          : [1, 2];

        return (
          <Col sm={12} lg={6} className={'device-help'}>
            {descriptionBlocks.map(block => (
              <Col key={block}>
                <Row>
                  <Col>
                    {convertTranslation(
                      t!(`DeviceDetail:${deviceType}${grid}${block}`)
                    )}
                  </Col>
                </Row>
                <Row>
                  <img
                    src={Image[`${imageType}${block}`]}
                    className={classnames(
                      'device-image',
                      'device-description',
                      `${imageType}${block}`
                    )}
                  />
                </Row>
              </Col>
            ))}
          </Col>
        );
      }
      return <div />;
    }
  )
);

export default withRouter(translate(['DeviceDetail'])(DeviceHelp));
