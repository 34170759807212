import React from 'react';
import { IDeviceIcon } from '../DeviceIcons';

const OtherDeviceType = (props: IDeviceIcon) => (
  <svg
    width="3rem"
    height="3rem"
    viewBox="0 0 20000 20000"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    color="black"
    {...props}
  >
    <g id="prefix__other_device">
      <path
        style={{ fill: `${props.color}` }}
        d="M6216 8632c687 0 1375 687 1375 1375 0 687-687 1375-1375 1375-687 0-1375-687-1375-1375 0-745 458-1375 1375-1375zM13872 8632c687 0 1375 687 1375 1375 0 687-687 1375-1375 1375-687 0-1375-687-1375-1375 0-745 458-1375 1375-1375zM10132 8632c687 0 1375 687 1375 1375 0 687-687 1375-1375 1375-687 0-1375-687-1375-1375 0-745 458-1375 1375-1375z"
      />
    </g>
  </svg>
);

export default OtherDeviceType;
