export const getInstallationPath = ({
  pvInstallationId,
  customerId
}: {
  pvInstallationId: string;
  customerId: string;
}) => {
  return `/devices_inscription/${customerId}/pv_installation/${pvInstallationId}`;
};
export const getFinishPath = ({
  pvInstallationId,
  customerId
}: {
  pvInstallationId: string;
  customerId: string;
}) => {
  return `/devices_inscription/${customerId}/finish/${pvInstallationId}`;
};
export const getFunctionPath = ({
  pvInstallationId,
  customerId
}: {
  pvInstallationId: string;
  customerId: string;
}): string => {
  return `/devices_inscription/${customerId}/functions/${pvInstallationId}`;
};

export const getDevicePath = ({
  pvInstallationId,
  customerId
}: {
  pvInstallationId: string;
  customerId: string;
}): string => {
  return `/devices_inscription/${customerId}/devices/${pvInstallationId}`;
};
export const getMasterPath = ({
  pvInstallationId,
  customerId
}: {
  pvInstallationId: string;
  customerId: string;
}): string => {
  return `/devices_inscription/${customerId}/master/${pvInstallationId}`;
};

export const getUCGPath = ({
  pvInstallationId,
  customerId
}: {
  pvInstallationId: string;
  customerId: string;
}): string => {
  return `/devices_inscription/${customerId}/ucg/${pvInstallationId}`;
};

export const getEditDeviceFunctionPath = ({
  pvInstallationId,
  customerId,
  plcMac = '',
  ethMac = 'none'
}: {
  pvInstallationId: string;
  customerId: string;
  plcMac?: string;
  ethMac?: string;
}): string => {
  return `/devices_inscription/${customerId}/functions/${pvInstallationId}/${plcMac}/${ethMac}`;
};

export const getPartnerPath = ({
  pvInstallationId,
  customerId
}: {
  pvInstallationId: string;
  customerId: string;
}): string => {
  return `/devices_inscription/${customerId}/partnership/${pvInstallationId}`;
};

export const getMsbPath = ({
  pvInstallationId,
  customerId
}: {
  pvInstallationId: string;
  customerId: string;
}): string => {
  return `/devices_inscription/${customerId}/battery/${pvInstallationId}`;
};
