import { IValidatedSerialNumber } from './battery.types';

export enum BatteryStatusType {
  SN_FILLED = 'msb_SN_filled',
  AC_FILLED = 'msb_AC_filled',
  PROVIDER_REGISTRATION_OK = 'msb_provider_registration_ok',
  ENEDIS_GRID_INJECTION_REQUESTED = 'msb_enedis_grid_injection_requested',
  STRASBOURG_ELECTRICITY_ADMINISTRATIVE_PROCEDURE_REQUESTED = 'msb_strasbourg_electricity_administrative_procedure_requested',
  ENEDIS_OK = 'msb_enedis_ok',
  OK = 'msb_ok'
}

export const MYLIGHT_MSB_STATUSES: string[] = [
  BatteryStatusType.SN_FILLED,
  BatteryStatusType.ENEDIS_GRID_INJECTION_REQUESTED,
  BatteryStatusType.STRASBOURG_ELECTRICITY_ADMINISTRATIVE_PROCEDURE_REQUESTED,
  BatteryStatusType.OK
];

export const EMPTY_SERIAL_NUMBER: IValidatedSerialNumber = {
  serialNumber: '',
  isValid: null,
  type: null
};

export const MAX_PLANETE_OUI_MSB_BOXES_COUNT = 5;
