import List from '../../../ui/DataDisplay/List/ListWrapper';
import { ListTitle } from '../../../ui/DataDisplay/List/ListHeader';
import ScanBarcodeButton from '../barcode/ScanBarcodeButton';
import ListItem from '../../../ui/DataDisplay/List/ListItem';
import { Col, Form, Row } from 'reactstrap';
import { FormInput } from '../../../ui/FormInput/FormInput';
import { HelpButton } from '../../../ui/HelpButton/HelpButton';
import { convertTranslation } from '../../../utils/convertTranslations';
import { isMobileDevice } from '../../../utils/tools';
import LoadingButton from '../../../ui/Button/LoadingButton/LoadingButton';
import MasterErrors from './MasterErrors';
import React from 'react';
import { translate, TranslationFunction } from 'react-i18next';
import { safeMobxInject } from '../../../stores/storeInjectionHelpers';
import masterActivationCodeLocationImage from '../../../assets/images/master/master-ac-location.png';

interface IStoresProps {
  setMstCode: (mstCode: string) => void;
  mstCode?: string;
  mstCodeInvalid?: boolean;
}

interface IProps {
  t: TranslationFunction;
  saveLoading: boolean;
  registerMaster: () => void;
}

const MasterUnregistered = safeMobxInject<IStoresProps, IProps>(
  stores => ({
    setMstCode: stores.inscription.setMstCode,
    mstCode: stores.inscription.mstCode,
    mstCodeInvalid: stores.inscription.mstCodeInvalid
  }),
  ({
    t,
    saveLoading,
    registerMaster,
    setMstCode,
    mstCode,
    mstCodeInvalid
  }: IProps & IStoresProps) => {
    const masterHelpButtonMessage = (
      <Col>
        {convertTranslation(
          isMobileDevice()
            ? t('Master:masterHelpMobile')
            : t('Master:masterHelp')
        )}
        <Row className="pt-3 pb-2">
          <img
            style={{
              margin: 'auto',
              width: '20rem',
              height: '20rem'
            }}
            src={masterActivationCodeLocationImage}
            alt={t('Master:masterHelp')}
          />
        </Row>
      </Col>
    );
    return (
      <List fluid>
        <Row>
          <Col lg={9} xl={6}>
            <ListTitle
              text={t('RegistrationMenu:master')}
              children={<ScanBarcodeButton codeType={'MST'} />}
            />
            <ListItem>
              <Form
                className="form-wrapper"
                onSubmit={e => {
                  e.preventDefault();
                }}
              >
                <FormInput
                  id="master"
                  name={t('Master:master')}
                  onChange={value => setMstCode(value)}
                  error={mstCode && mstCodeInvalid && t(`Master:masterError`)}
                  addon={
                    <HelpButton
                      uniqueKey="masterHelp"
                      message={masterHelpButtonMessage}
                    />
                  }
                  value={mstCode || ''}
                />

                <div className="text-right mt-3">
                  <LoadingButton
                    id="sendMST"
                    loading={saveLoading}
                    disabled={mstCodeInvalid}
                    onClick={() => {
                      registerMaster();
                    }}
                  >
                    {t('Common:connect')}
                  </LoadingButton>
                </div>
                <MasterErrors />
              </Form>
            </ListItem>
          </Col>
        </Row>
      </List>
    );
  }
);

export default translate()(MasterUnregistered);
