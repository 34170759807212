/**
 * UPM serial number regex
 *
 * Format:
 *    | maker | code | country | year | week | id  |
 *    |   -   |  -   |    -    |  -   |  -   |  -  |
 *    |   E   | 7111 |    F    |  22  |  01  | 001 |
 *
 * - maker: 1 letter
 * - code: 4 digits
 * - country: 1 letter
 * - year: 2 digits
 * - week: 2 digits
 * - box id: 3 digits
 */
export const UPM_SERIAL_NUMBER_REGEX = /^(?<maker>[A-Z])(?<code>\d{4})(?<country>[A-Z])(?<year>\d{2})(?<week>\d{2})(?<id>\d{3})$/;

export interface IUPMSerialNumberRegexMatch {
  maker: string;
  code: string;
  country: string;
  year: string;
  week: string;
  id: string;
}
