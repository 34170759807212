import { safeMobxInject } from '../../stores/storeInjectionHelpers';
import { IAllStores } from '../../stores/allStores.model';
import { translate } from 'react-i18next';
import { Alert, Col, Container } from 'reactstrap';
import React, { useEffect } from 'react';
import { t } from 'i18next';
import { DevicePairedList } from './DevicePairedList';
import SearchPairingForm from './SearchPairingForm';
import { FieldList } from '../../ui/DataDisplay/FieldList/FieldList';
import SearchPairingErrors from './SearchPairingErrors';
import './toolsTestPairingView.scss';

interface IPropsType {
  getDeviceConfig: () => void;
}

interface IStoreProps {
  setTitle: (title?: string) => void;
  hasRightPairingToolkit: boolean;
}

const ToolsTestPairingView = safeMobxInject<IStoreProps, IPropsType>(
  (allStores: IAllStores) => {
    return {
      setTitle: allStores.Store.mainMenu.setTitle,
      hasRightPairingToolkit: allStores.Store.profile.hasRightPairingToolkit
    };
  },

  (props: IPropsType & IStoreProps) => {
    const { setTitle, hasRightPairingToolkit } = props;
    useEffect(() => {
      setTitle(t('ToolsPairing:verifPairing'));
    }, []);
    return (
      <Container fluid>
        <Col
          className={hasRightPairingToolkit ? 'container__tools-pairing' : ''}
        >
          <FieldList>
            {hasRightPairingToolkit ? (
              <>
                <SearchPairingForm />
                <SearchPairingErrors />
                <DevicePairedList />
              </>
            ) : (
              <Alert color="primary">
                {t('ToolsPairing:notPermissionToAccessPairingToolkit')}
              </Alert>
            )}
          </FieldList>
        </Col>
      </Container>
    );
  }
);
export default translate()(ToolsTestPairingView);
