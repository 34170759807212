import React from 'react';
import { Alert } from 'reactstrap';
import { translate, TranslationFunction } from 'react-i18next';
import { IAllStores } from '../../stores/allStores.model';
import { safeMobxInject } from '../../stores/storeInjectionHelpers';

interface IProps {
  t: TranslationFunction;
}

interface IStoreProps {
  lastMasterKeySearch: string;
  masterNotConnected: boolean;
  invalidAC: boolean;
  invalidMAC: boolean;
  unknownError: boolean;
}

const SearchPairingErrors = safeMobxInject<IStoreProps, IProps>(
  ({ searchPairingStore }: IAllStores) => ({
    lastMasterKeySearch: searchPairingStore.lastMasterKeySearch,
    masterNotConnected: searchPairingStore.masterNotConnected,
    invalidAC: searchPairingStore.invalidAC,
    invalidMAC: searchPairingStore.invalidMAC,
    unknownError: searchPairingStore.unknownError
  }),

  ({
    t,
    masterNotConnected,
    invalidAC,
    invalidMAC,
    unknownError,
    lastMasterKeySearch
  }: IStoreProps & IProps) => {
    if (masterNotConnected!) {
      return (
        <div className="pt-4">
          <Alert color="danger">
            {lastMasterKeySearch}: {t('mstNotConnected')}
          </Alert>
        </div>
      );
    }
    if (invalidAC!) {
      return (
        <div className="pt-4">
          <Alert color="danger">
            {lastMasterKeySearch}: {t('mstInvalidAC')}
          </Alert>
        </div>
      );
    }
    if (invalidMAC!) {
      return (
        <div className="pt-4">
          <Alert color="danger">
            {lastMasterKeySearch}: {t('mstInvalidMAC')}
          </Alert>
        </div>
      );
    }
    if (unknownError!) {
      return (
        <div className="pt-4">
          <Alert color="danger">{t('unknownError')}</Alert>
        </div>
      );
    }
    return <span />;
  }
);

export default translate(['Errors'])(SearchPairingErrors);
