import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { default as React, useContext } from 'react';
import { translate, TranslationFunction } from 'react-i18next';
import { MasterTypeInternetConnection } from '../../../../constants/MasterTypeInternetConnection';
import { PPDevice } from '../../../../graphql/graphql.schema';
import { Field } from '../../../../ui/DataDisplay/FieldList/FieldList';
import { IconSpinner } from '../../../../ui/Icons/IconSpinner';
import ShowAlert from '../../../../ui/ShowAlert/ShowAlert';
import GsmQualitySignal from '../../../devices_inscription/master/GsmQualitySignal';
import MasterConnectionTypePlcMylightAdditionalInfo from '../../../devices_inscription/master/MasterConnectionTypePlcMylightAdditionalInfo';
import { InstallationContext } from '../Installation.context';

interface IFieldsProps {
  loading?: boolean;
  masterConfig?: Pick<
    PPDevice,
    'simNb' | 'rssi' | 'rscp' | 'gsmSignalQuality' | 'isGSMConnected'
  >;
  plcConfig?: Pick<PPDevice, 'rx' | 'tx' | 'plcSignalQuality'>;
  t: TranslationFunction;
}

const MasterConfigFieldsView = ({
  loading,
  masterConfig,
  plcConfig,
  t
}: IFieldsProps) => {
  const { installation } = useContext(InstallationContext);

  if (!installation || !installation.masterTypeInternetConnection) {
    return null;
  }

  const LoadingEl = loading && <IconSpinner size={14} />;

  const ErrorEl = (
    <>
      <FontAwesomeIcon icon={'exclamation-circle'} color={'red'} />
      <span className={'ml-1'}>{t('Errors:noSignalDetected')}</span>
    </>
  );

  const connectionTypeEl: JSX.Element = (
    <p className={'m-0'}>
      {t(
        `Installation:${
          installation.masterTypeInternetConnection
        }ConnectionType`
      )}
    </p>
  );

  const connectionTypeErrorEl = (
    message: string,
    dataCy: string
  ): JSX.Element => (
    <>
      {connectionTypeTextEl}
      <p className={'text-warning m-0 mt-1'} data-cy={dataCy}>
        <FontAwesomeIcon icon={'exclamation-circle'} color={'orange'} />
        <span className={'ml-1'}>{message}</span>
      </p>
    </>
  );

  let connectionTypeTextEl = connectionTypeEl;

  if (masterConfig && masterConfig.isGSMConnected) {
    if (
      installation.masterTypeInternetConnection !==
      MasterTypeInternetConnection.GSM
    ) {
      connectionTypeTextEl = connectionTypeErrorEl(
        t('Errors:mstConnectedWithGSM'),
        'isGSMConnectedAlert'
      );
    }
  } else if (plcConfig) {
    if (
      installation.masterTypeInternetConnection !==
      MasterTypeInternetConnection.PLC_MYLIGHT
    ) {
      connectionTypeTextEl = connectionTypeErrorEl(
        t('Errors:mstConnectedWithPLC'),
        'isPLCConnectedAlert'
      );
    }
  }

  return (
    <>
      <Field
        name={t('Installation:masterTypeInternetConnection')}
        text={connectionTypeTextEl}
        dataCy={'masterTypeInternetConnection'}
      />

      {installation.masterTypeInternetConnection ===
        MasterTypeInternetConnection.GSM && (
        <>
          <Field
            name={t('Installation:simRouterReference')}
            text={installation && installation.simRouterReference}
            dataCy={'simRouterReference'}
          />
          <Field
            name={t('Installation:simSerialNumber')}
            text={
              (masterConfig && masterConfig.simNb) ||
              (installation && installation.simSerialNumber)
            }
            dataCy={'simSerialNumber'}
          />
          <Field
            name={t('Devices:signalReceptionGSM')}
            text={
              LoadingEl ||
              (masterConfig &&
                masterConfig.isGSMConnected && (
                  <GsmQualitySignal
                    rssi={masterConfig.rssi}
                    rscp={masterConfig.rscp}
                    gsmSignalQuality={masterConfig.gsmSignalQuality}
                  />
                )) ||
              ErrorEl
            }
            dataCy={'signalReceptionGSM'}
          />
        </>
      )}

      {installation.masterTypeInternetConnection ===
        MasterTypeInternetConnection.PLC_MYLIGHT && (
        <Field
          name={t('Devices:pclRateBetweenMasterAndComOne')}
          text={
            LoadingEl ||
            (plcConfig && (
              <MasterConnectionTypePlcMylightAdditionalInfo
                tx={plcConfig.tx}
                rx={plcConfig.rx}
                plcSignalQuality={plcConfig.plcSignalQuality}
              />
            )) ||
            ErrorEl
          }
          dataCy={'signalReceptionPLC'}
        />
      )}
    </>
  );
};

export default translate(['Errors', 'Devices', 'Installation'])(
  MasterConfigFieldsView
);
