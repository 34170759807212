import React from 'react';
import { translate, TranslationFunction } from 'react-i18next';
import { Alert, Col } from 'reactstrap';
import ListItem from '../../../ui/DataDisplay/List/ListItem';
import { safeMobxInject } from '../../../stores/storeInjectionHelpers';

export interface IProps {
  t: TranslationFunction;
}

interface IStoresProps {
  warnings: [string | JSX.Element];
}

const PossibleSummaryWarnings = safeMobxInject<IStoresProps, IProps>(
  stores => ({
    warnings: stores.inscription.warnings
  }),
  ({ t, warnings }: IProps & IStoresProps) => {
    return warnings && warnings.length > 0 ? (
      <ListItem>
        <Col md={12}>
          {warnings.map((w, index) => {
            return (
              <Alert key={index} color="warning" style={{ margin: '1rem 0' }}>
                {typeof w === 'string' ? t(w) : w}
              </Alert>
            );
          })}
        </Col>
      </ListItem>
    ) : (
      <></>
    );
  }
);

export default translate('SummaryWarnings')(PossibleSummaryWarnings);
