import React, { useEffect } from 'react';
import { Col } from 'reactstrap';
import { translate, TranslationFunction } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import { safeMobxInject } from '../../../stores/storeInjectionHelpers';
import { useScrollToInscriptionStep } from '../../../utils/hooks';
import { InscriptionStep } from '../../../stores/inscription/inscriptionStepsStore';
import { InscriptionStore } from '../../../stores/inscription/inscriptionStore';
import MasterRegistered from './MasterRegistered';
import MasterUnregistered from './MasterUnregistered';

interface IProps extends RouteComponentProps {
  saveLoading: boolean;
  save: (master: { mac: string } | { activationCode: string }) => void;
  t: TranslationFunction;
}

interface IStoresProps {
  inscriptionStore: InscriptionStore;
  mstNotConnected: boolean;
  hideMSTWarningModal: (hide: boolean) => void;
  isMSTRegistered: boolean;
  isDone: boolean;
}

const MasterView = safeMobxInject<IStoresProps, IProps>(
  stores => ({
    inscriptionStore: stores.inscription,
    hideMSTWarningModal: stores.inscriptionError.hideModal,
    mstNotConnected: stores.inscriptionError.mstNotConnected,
    isMSTRegistered: stores.inscription.isMSTRegistered,
    isDone: stores.inscriptionSteps.getMasterDone
  }),
  ({
    saveLoading,
    save,
    mstNotConnected,
    hideMSTWarningModal,
    isMSTRegistered,
    inscriptionStore
  }: IProps & IStoresProps) => {
    useScrollToInscriptionStep(InscriptionStep.MASTER);

    useEffect(
      () => {
        if (mstNotConnected) {
          hideMSTWarningModal(false);
        }
      },
      [mstNotConnected]
    );
    const { mstPayload, setSubmittedMstCode, mstCode } = inscriptionStore;

    const registerMaster = () => {
      setSubmittedMstCode(mstCode);
      save(mstPayload);
    };

    return (
      <Col md={12} lg={12} className={'mobile-helper'}>
        {isMSTRegistered ? (
          <MasterRegistered
            registerMaster={registerMaster}
            saveLoading={saveLoading}
          />
        ) : (
          <MasterUnregistered
            registerMaster={registerMaster}
            saveLoading={saveLoading}
          />
        )}
      </Col>
    );
  }
);

export default withRouter(
  translate(['Common', 'Master', 'Errors'])(MasterView)
);
