import React, { useEffect, useState } from 'react';
import { Label } from 'reactstrap';

import './select.scss';
import { translate, TranslationFunction } from 'react-i18next';
import classnames from 'classnames';
import Input from 'reactstrap/lib/Input';
import Spinner from '../Spinner/Spinner';

export interface ISelectValue {
  value: string;
  label: string;
}

interface IProps {
  value?: ISelectValue | null;
  name: string;
  inputValue?: string;
  id: string;
  onChange?: (value: ISelectValue) => void;
  onInputChange?: (value: string) => void;
  options: ISelectValue[];
  noFilter?: boolean;
  required?: boolean;
  isClearable?: boolean;
  error?: string;
  loading?: boolean;
  placeholder?: string;
  disabled?: boolean;
  unit?: string;
  t?: TranslationFunction;
}

function hasError({ error, value, disabled }: IProps) {
  return (error && !!value && !disabled) || undefined;
}

const SelectInput = (props: IProps) => {
  const {
    name,
    id,
    onChange,
    options,
    value,
    required,
    loading,
    placeholder = props.t!('Common:select'),
    disabled,
    unit
  } = props;
  const [inputValue, setInputValue] = useState('');
  useEffect(
    () => {
      setInputValue(value ? value.value : '');
    },
    [value]
  );

  function findOptionByValue(val: string | number | undefined) {
    const option = options.find(opt => opt.value === val);
    if (option === undefined) {
      return { value: '', label: '' };
    }
    return { value: option.value, label: option.label };
  }

  return (
    <div
      className={classnames(
        'input-field select-input mb-3',
        {
          'with-error': hasError(props)
        },
        { 'with-unit': unit }
      )}
    >
      <Label className="input-title" id={id}>
        {name} {required && ' *'}
      </Label>
      <Spinner loading={loading}>
        <div className={`${unit && unit !== '' ? 'input-group ' : ''}`}>
          <Input
            disabled={disabled}
            type="select"
            value={inputValue}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              if (!!onChange) {
                onChange(findOptionByValue(e.currentTarget.value));
              }
            }}
            placeholder={placeholder}
          >
            {options.length > 0 && (
              <>
                <option disabled={true} value="">
                  {placeholder}
                </option>
                {options.map((option, index) => {
                  return (
                    <option key={index} value={option.value}>
                      {option.label}
                    </option>
                  );
                })}
              </>
            )}
            {options.length === 0 && (
              <option disabled={true} value="">
                {props.t!('none_result')}
              </option>
            )}
          </Input>
          {unit && (
            <div className="input-group-append">
              <span className="input-group-text">{unit}</span>
            </div>
          )}
        </div>
      </Spinner>
      {hasError(props) && (
        <div className="custom-error">
          <span>{props.t!('Common:selectError')}</span>
        </div>
      )}
    </div>
  );
};

export default translate('Common')(SelectInput);
