import React from 'react';
import { IDeviceIcon } from '../DeviceIcons';

const TadoHeater = (props: IDeviceIcon) => (
  <svg
    xmlSpace="preserve"
    width="3rem"
    height="3rem"
    viewBox="0 0 20000 20000"
    id="prefix__svg2"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    {...props}
  >
    <defs id="prefix__defs4">
      <style type="text/css" id="style6">
        {`.prefix__fil0{fill:${props.color}`}
      </style>
    </defs>
    <g id="prefix__Layer_x0020_1" fill={props.color} fillOpacity={1}>
      <g id="prefix___1405066041440" fill={props.color} fillOpacity={1}>
        <path
          style={{ fill: `${props.color}` }}
          d="M3047 15638c-232 0-441-232-441-441V6460c0-232 232-441 441-441 232 0 441 232 441 441v8713c-23 232-116 465-441 465z"
          id="prefix__path11"
          fill={props.color}
          fillOpacity={1}
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M5231 15638c-232 0-441-232-441-441V6460c0-232 232-441 441-441 232 0 441 232 441 441v8713c23 232-209 465-441 465z"
          id="prefix__path13"
          fill={props.color}
          fillOpacity={1}
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M7346 15638c-232 0-441-232-441-441V6460c0-232 232-441 441-441 232 0 441 232 441 441v8713c0 232-232 465-441 465z"
          id="prefix__path15"
          fill={props.color}
          fillOpacity={1}
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M9553 15638c-232 0-441-232-441-441V6460c0-232 232-441 441-441 232 0 441 232 441 441v8713c-116 232-232 465-441 465z"
          id="prefix__path17"
          fill={props.color}
          fillOpacity={1}
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M11644 15638c-232 0-441-232-441-441V6460c0-232 232-441 441-441s441 232 441 441v8713c0 232-209 465-441 465z"
          id="prefix__path19"
          fill={props.color}
          fillOpacity={1}
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M13736 15638c-232 0-441-232-441-441V6460c0-232 232-441 441-441 232 0 441 232 441 441v8713c0 232-209 465-441 465z"
          id="prefix__path21"
          fill={props.color}
          fillOpacity={1}
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M15943 15638c-232 0-441-232-441-441V6460c0-232 232-441 441-441 232 0 441 232 441 441v8713c-116 232-209 465-441 465z"
          id="prefix__path23"
          fill={props.color}
          fillOpacity={1}
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M9553 4694c0 116 0 116 116 209v232h116c116 0 232-116 232-232v-674l116-116h-232s-116 116-116 232c-349 256-349 256-232 349z"
          id="prefix__path25"
          fill={props.color}
          fillOpacity={1}
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M10878 4694c0 116 0 116 116 209v232h116c116 0 232-116 232-232v-674l116-116h-232s-116 116-116 232c-349 256-349 256-232 349z"
          id="prefix__path27"
          fill={props.color}
          fillOpacity={1}
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M8112 4694c0 116 0 116 116 209v232h116c116 0 232-116 232-232v-674l116-116h-558s-116 116-116 232c-23 256-23 256 93 349z"
          id="prefix__path29"
          fill={props.color}
          fillOpacity={1}
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M18057 6693c0 302-256 558-558 558s-558-256-558-558 256-558 558-558 558 256 558 558z"
          id="prefix__path31"
          fill={props.color}
          fillOpacity={1}
        />
      </g>
    </g>
  </svg>
);

export default TadoHeater;
