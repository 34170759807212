import React from 'react';
import { IDeviceIcon } from '../DeviceIcons';

const FoodProcessor = (props: IDeviceIcon) => (
  <svg
    width="3rem"
    height="3rem"
    viewBox="0 0 20000 20000"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    color="black"
    {...props}
  >
    <g id="prefix__layer">
      <g id="prefix__food_processor">
        <path
          style={{ fill: `${props.color}` }}
          d="M8971 13074c-97-511 354-1076 837-1115 579-47 1049 287 1111 841 144 1273-1711 1527-1949 274zm3135 1900c44-287-266-2862-337-3542-39-375 4-157-199-359-280-279-138-270-846-270H9609c-258 0-877-31-1079 37-197 66-173 154-304 254-218 166-188 35-213 385-71 1003-251 2123-315 3031-32 452-143 518 399 634 827 177 3505 292 4009-170zM11680 9201c80-147 139-1090 166-1340l103-1451c108-42 366-62 471-11 170 83 98 1183 98 1496 0 375 40 642-130 946-114 204-484 376-707 360zM7905 5424l3815 1c0 994-37 1410-80 2310-7 152-30 167-38 320-23 418-91 845-149 1246-57 397-121 806-211 1183-414 0-2364 48-2604-25-168-571-468-3105-464-3838 1-231 15-466-33-684-54-246-174-324-235-513zm548 5260h2988l221-1212c1324-344 1037-836 1055-2773 6-668-265-498-747-568l-16-899-4337-6c22 253 101 339 223 534 126 203 88 453 95 702 5 170 33 198 41 357 7 129-5 270-2 400 21 725 331 2841 479 3465zM9571 4646c-367 12-1621-78-1532 435l3872 16c40-525-1078-407-1537-447 80-131 173-196 124-357l-1037 1c-35 264 3 183 110 353z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M9801 6972c-238 19-683-92-659 159 157 108 466 39 659 54l6 424c-244 19-690-92-665 159 157 108 466 39 659 54l6 424c-244 19-690-92-665 159 157 108 466 39 659 54l6 424c-244 19-690-92-665 159 157 108 466 39 659 54l13 704c44 166 32 96 114 126 172-125 69-620 86-830 163-13 696 77 685-135-49-137-372-54-685-78l-6-424c169-13 703 77 692-135-49-137-372-54-685-78l-6-424c169-13 703 77 692-135-49-137-372-54-685-78l-6-424c169-13 703 77 692-135-82-113-115-76-291-74-135 2-262 6-394-4-16-205 52-628-56-780-267 8-112 216-156 780zM9894 12296l-12 635c163-2 48-1 195-3l10-634c587 102 653 1265-199 1222-300-15-535-281-544-566-13-398 237-580 551-654zm-566 139c-475 669 115 1386 790 1269 407-71 672-517 609-910-75-470-348-639-731-669-204-16-542 131-669 310z"
        />
      </g>
    </g>
  </svg>
);

export default FoodProcessor;
