import { action, computed, observable } from 'mobx';
import snackbarStore from '../userFeedback/snackbarStore';
import { t } from 'i18next';
import { ApolloError } from 'apollo-client';

import { PPDeviceConfigFile } from '../../graphql/graphql.schema';

export class InscriptionErrorStore {
  @observable public mstAlreadyRegistered: boolean = false;
  @observable public mstNotConnected: boolean = false;
  @observable public unableToJoinMST: boolean = false;
  @observable public modalHidden: boolean = true;
  @observable public invalidAC: boolean = false;
  @observable public unknownError: boolean = false;

  @computed
  public get mstHasError(): boolean {
    return Boolean(
      this.mstAlreadyRegistered ||
        this.mstNotConnected ||
        this.unableToJoinMST ||
        this.invalidAC ||
        this.unknownError
    );
  }

  @action.bound
  public setInvalidAC(invalid: boolean) {
    this.invalidAC = invalid;
  }

  @action.bound
  public hideModal(hide: boolean) {
    this.modalHidden = hide;
  }
  @action.bound
  public setMstAlreadyRegistered(hasError: boolean) {
    this.mstAlreadyRegistered = hasError;
  }

  @action.bound
  public setMstNotConnected(hasError: boolean) {
    if (hasError) {
      snackbarStore.showSnackBar({
        text: t('Errors:mstNotConnected'),
        type: 'error'
      });
    }
    this.mstNotConnected = hasError;
  }

  @action.bound
  public setUnableToJoinMST(unable: boolean) {
    this.unableToJoinMST = unable;
  }
  @action.bound
  public setUnknownError(error: boolean) {
    this.unknownError = error;
  }

  @action.bound
  public reset() {
    this.mstAlreadyRegistered = false;
    this.unableToJoinMST = false;
    this.mstNotConnected = false;
    this.invalidAC = false;
    this.unknownError = false;
  }

  @action.bound
  public initErrorsFromConfigFileResponse(
    config?: PPDeviceConfigFile
  ): boolean {
    this.reset();
    const mstAlreadyRegistered =
      (config && config.mstError === 'MST_ALREADY_REGISTERED') || false;
    const unableToJoinMST =
      (config && config.mstError === 'UNABLE_TO_JOIN_MST') || false;
    const mstNotConnected =
      (config && config.mstError === 'MST_NOT_CONNECTED') || false;
    const invalidAC =
      (config && config.mstError === 'ACTIVATION_CODE_INVALID') || false;
    this.setMstAlreadyRegistered(mstAlreadyRegistered);
    this.setMstNotConnected(mstNotConnected);
    this.setUnableToJoinMST(unableToJoinMST);
    this.setInvalidAC(invalidAC);
    const unknownMstError = Boolean(
      !mstAlreadyRegistered &&
        !unableToJoinMST &&
        !invalidAC &&
        !mstNotConnected &&
        config &&
        !!config.mstError
    );
    this.setUnknownError(unknownMstError);
    Boolean(config && config.mstError);
    return (
      this.mstAlreadyRegistered ||
      this.mstNotConnected ||
      this.unableToJoinMST ||
      this.invalidAC ||
      this.unknownError
    );
  }

  @action.bound
  public initErrorsFromApolloError(e: ApolloError) {
    snackbarStore.showSnackBar({
      text: t('Errors:serverError'),
      type: 'error'
    });
  }
}

const inscriptionError: InscriptionErrorStore = new InscriptionErrorStore();
export default inscriptionError;
