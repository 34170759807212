import classnames from 'classnames';
import React, { FunctionComponent, useRef, useState } from 'react';
import './searchInput.scss';

interface IProps {
  searchQuery: string;
  searchInputChange: (query: string) => void;
}

interface IState {
  expended: boolean;
}

const SearchInput: FunctionComponent<IProps> = ({
  searchQuery,
  searchInputChange
}: IProps) => {
  const [expended, setExpended] = useState<IState['expended']>(!!searchQuery);
  const textInput = useRef<HTMLInputElement>(null);

  return (
    <div
      className={classnames('search-input', 'right', 'search', { expended })}
    >
      <input
        value={searchQuery}
        ref={textInput}
        onChange={({ target: { value } }) => searchInputChange(value)}
      />
      <i
        className={expended ? 'icon-cross' : 'icon-search'}
        onClick={() => {
          setExpended(!expended);
          if (textInput && textInput.current && !expended) {
            textInput.current.focus();
          } else {
            searchInputChange('');
          }
        }}
      />
    </div>
  );
};

export default SearchInput;
