import * as React from 'react';
import { Query, QueryResult } from 'react-apollo';

import gql from 'graphql-tag';
import DiagnosticSummaryList from './DiagnosticSummaryList';
import { DiagnosticSet } from '../../../../graphql/graphql.schema';

export const GET_DIAGNOSTICS_FOR_CUSTOMER = gql`
  query diagnosticSetByCustomerId($customerId: String) {
    diagnosticSetByCustomerId(idCustomer: $customerId) {
      id
      name
      createdAt
      status
      diagnostics {
        codeError
        createdAt
        id
        mac
        result
        status
        testId
      }
    }
  }
`;

interface IResponse {
  diagnosticSetByCustomerId: DiagnosticSet[];
}

interface IProps {
  id: string;
}
const DiagnosticSummaryQuery = ({ id }: IProps) => {
  return (
    <Query query={GET_DIAGNOSTICS_FOR_CUSTOMER} variables={{ customerId: id }}>
      {({ loading, data, error }: QueryResult<IResponse>) => {
        return (
          <DiagnosticSummaryList
            diagnosticSummary={
              data && data.diagnosticSetByCustomerId
                ? data.diagnosticSetByCustomerId
                : []
            }
            customerId={id}
            loading={loading}
            error={error ? error.message : undefined}
          />
        );
      }}
    </Query>
  );
};

export default DiagnosticSummaryQuery;
