import * as React from 'react';
import { Col } from 'reactstrap';
import { TranslationFunction } from 'i18next';
import {
  FieldEmail,
  FieldPhone,
  FieldList,
  Title
} from '../../ui/DataDisplay/FieldList/FieldList';

import { translate } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { Store } from '../../stores';
interface IProps {
  t: TranslationFunction;
  Store?: Store;
}

const CustomerSupport = inject('Store')(
  observer(({ t, Store: mobix }: IProps) => {
    const { country } = mobix!.profile;
    return (
      <Col className="customer-support mobile-helper" xs={12}>
        <FieldList>
          <Title text={t('customerTitle')} />
          {country !== 'CH' && (
            <FieldPhone
              name={t('france')}
              phone={t('franceCustomerSupportNumber')}
            />
          )}
          {country !== 'FR' && (
            <FieldPhone
              name={t('switzerland')}
              phone={t('switzerlandCustomerSupportNumber')}
            />
          )}
          <FieldEmail name={t('email')} email={t('supportCustomerEmail')} />
        </FieldList>
      </Col>
    );
  })
);

export default translate('Support')(CustomerSupport);
