import React, { useState } from 'react';
import { Container } from 'reactstrap';

import CreateCustomerByInstallerForm from './CreateCustomerByInstallerForm';
import { Mutation, MutationResult, Query } from 'react-apollo';
import gql from 'graphql-tag';
import {
  Customer,
  InstallerFormCustomer
} from '../../../graphql/graphql.schema';
import { translate, TranslationFunction } from 'react-i18next';
import AfterCreateCustomerModal from '../AfterCreateCustomerModal';
import IsolatedComponent from '../../../ui/IsolatedComponent/IsolatedComponent';
import { ISnackBarParams } from '../../../stores/userFeedback/snackbarStore';
import {
  getErrorsMessageGraphQl,
  IErrorMessageGraphql
} from '../../../utils/graphQl';
import { safeMobxInject } from '../../../stores/storeInjectionHelpers';

const GET_COLLEAGUES = gql`
  query maintainers {
    maintainers {
      companyName
      companyCode
      maintainer {
        id
        firstName
        email
        lastName
        company {
          tenants {
            id
            name
          }
        }
      }
    }
  }
`;

const CREATE_CUSTOMER = gql`
  mutation createCustomerFromInstaller($customer: InstallerFormCustomer!) {
    createCustomerFromInstaller(input: $customer) {
      id
      pvInstallations {
        id
      }
    }
  }
`;

interface IProps {
  t: TranslationFunction;
}

interface IStoresProps {
  emailIsTaken: (isTaken: boolean) => void;
  showErrorSnackBar: (params: ISnackBarParams) => void;
}

const CreateCustomerForInstallerQuery = safeMobxInject<IStoresProps, IProps>(
  stores => ({
    showErrorSnackBar: stores.snackBar.showErrorSnackBar,
    emailIsTaken: stores.Store.createCustomer.emailIsTaken
  }),
  ({ emailIsTaken, showErrorSnackBar, t }: IProps & IStoresProps) => {
    const [customerId, setCustomerId] = useState<string>();
    const [pvInstallationId, setPVInstallationId] = useState<string>();
    const [isModalOpen, toggleModal] = useState<boolean>(false);
    return (
      <IsolatedComponent
        title={t('Common:createCustomerTitle')}
        sm={10}
        md={9}
        lg={7}
        xl={6}
      >
        <Container fluid>
          <Query query={GET_COLLEAGUES}>
            {({ data, loading: isColleaguesLoading }) => {
              const maintainers = (data && data.maintainers) || [];
              return (
                <Mutation
                  mutation={CREATE_CUSTOMER}
                  onError={e => {
                    const errorsMessage = getErrorsMessageGraphQl(
                      e.graphQLErrors
                    );
                    const isEmailTaken = errorsMessage.some(
                      (error: IErrorMessageGraphql) => {
                        return error.message
                          ? error.errorCode.includes('customerMailAlreadyExist')
                          : false;
                      }
                    );
                    emailIsTaken(isEmailTaken);

                    if (!isEmailTaken) {
                      const isSrsError = errorsMessage.some(
                        (error: IErrorMessageGraphql) => {
                          return error.message
                            ? error.errorCode.includes(
                                'createCustomerSrsFailed'
                              )
                            : false;
                        }
                      );

                      if (isSrsError) {
                        showErrorSnackBar({
                          text: t('Errors:saveCustomerSrs'),
                          duration: 5000
                        });
                      } else {
                        showErrorSnackBar({
                          text: t('Errors:saveCustomer')
                        });
                      }
                    }
                  }}
                  onCompleted={response => {
                    setCustomerId(response.createCustomerFromInstaller.id);
                    setPVInstallationId(
                      response.createCustomerFromInstaller.pvInstallations![0]
                        .id
                    );
                    toggleModal(true);
                  }}
                >
                  {(
                    createCustomer,
                    {
                      called,
                      error: saveError,
                      loading: saveLoading
                    }: MutationResult<Customer>
                  ) => {
                    return (
                      <CreateCustomerByInstallerForm
                        maintainers={maintainers}
                        saveLoading={saveLoading}
                        saveError={saveError}
                        isColleaguesLoading={isColleaguesLoading}
                        done={!saveLoading && called && !saveError}
                        saveFromInstaller={(f: InstallerFormCustomer) => {
                          createCustomer({
                            variables: { customer: f }
                          });
                        }}
                      />
                    );
                  }}
                </Mutation>
              );
            }}
          </Query>
          <AfterCreateCustomerModal
            customerId={customerId}
            pvInstallationId={pvInstallationId}
            toggleModal={() => toggleModal(false)}
            showModal={isModalOpen}
          />
        </Container>
      </IsolatedComponent>
    );
  }
);

export default translate(['Errors', 'Common'])(CreateCustomerForInstallerQuery);
