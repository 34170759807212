import { action, computed, observable } from 'mobx';
import { Device } from './device';
import { DeviceCT } from './deviceCT';
import { EPPDevice, PPDevice } from '../../graphql/graphql.schema';
import {
  FUNCTION_TYPE,
  GMD,
  GMD_G3,
  RS485,
  TPH
} from '../devices_inscription/contants';
import uuid from 'uuid';
import { t } from 'i18next';
import inscription from './inscriptionStore';
import { GridType } from '@mylight/data-model';
import { ISelectValue } from '../../ui/Select/Select';
import {
  getDeviceFunctionOptions,
  sortSelectOptionsAlphabetically
} from '../../utils/tools';

const {
  ASOKA_ELECTRIC_COUNTER,
  COMPOSITE_DEVICE,
  ALARM_CLOCK,
  ASOKA_RED_PLUG,
  ASPIRATOR,
  BATTERY,
  BBQ_HOT_STONE,
  COFFEE_MACHINE,
  COMPUTER,
  DEEP_FRYER,
  DISHWASHER,
  DRYER,
  DVD_PLAYER,
  ELECTRIC_CAR,
  FOOD_PROCESSOR,
  FREEZER,
  GAME_CONSOLE,
  HAIR_DRYER,
  HEATER,
  HOME_CINEMA_SYSTEM,
  IRON,
  KETTLE,
  LAMP,
  MICROWAVE_OVEN,
  OTHER_DEVICE_TYPE,
  OVEN,
  PHONE,
  PRINTER,
  PRODUCTION_COUNTER,
  PRODUCTION_COUNTER_IGNORED,
  PUMP,
  RADIO,
  RAZOR,
  REFRIGERATOR,
  REFRIGERATOR_FREEZER,
  SPEAKER,
  STEREO_SYSTEM,
  TOASTER,
  TV,
  VENTILATOR,
  VIDEO_PROJECTOR,
  WASHING_DRYING_MACHINE,
  WASHING_MACHINE,
  WATER_HEATER,
  WINE_CELLAR
} = FUNCTION_TYPE;

const modbusFunctionOptions = [
  PRODUCTION_COUNTER,
  ASOKA_ELECTRIC_COUNTER,
  WATER_HEATER,
  OTHER_DEVICE_TYPE
];
const compositePrioritiedFunctionOptions: string[] = [
  PRODUCTION_COUNTER_IGNORED,
  PRODUCTION_COUNTER,
  ASOKA_ELECTRIC_COUNTER,
  OTHER_DEVICE_TYPE
];
const compositeOtherFunctionOptions: string[] = [
  ALARM_CLOCK,
  ASPIRATOR,
  BATTERY,
  BBQ_HOT_STONE,
  COFFEE_MACHINE,
  COMPUTER,
  DEEP_FRYER,
  DISHWASHER,
  DRYER,
  DVD_PLAYER,
  ELECTRIC_CAR,
  FOOD_PROCESSOR,
  FREEZER,
  GAME_CONSOLE,
  HAIR_DRYER,
  HEATER,
  HOME_CINEMA_SYSTEM,
  IRON,
  KETTLE,
  LAMP,
  MICROWAVE_OVEN,
  OVEN,
  PHONE,
  PRINTER,
  PUMP,
  RADIO,
  RAZOR,
  REFRIGERATOR,
  REFRIGERATOR_FREEZER,
  SPEAKER,
  STEREO_SYSTEM,
  TOASTER,
  TV,
  VENTILATOR,
  VIDEO_PROJECTOR,
  WASHING_DRYING_MACHINE,
  WASHING_MACHINE,
  WATER_HEATER,
  WINE_CELLAR
];

// TODO find better nam for device that can have more than one function (suggestion : MultiplePhaseDevice)
export class CompositeDevice extends Device {
  @observable public areCTSplit: boolean = false;
  @observable public isModbus: boolean = false;
  @observable public modbusAddress?: string = undefined;
  @observable public modbusReference?: string = undefined;
  @observable public editedAreCTSplit: boolean = false;
  @observable public power?: [number, number, number] = undefined;
  @observable public cts: [DeviceCT, DeviceCT, DeviceCT] | DeviceCT[] = [];
  @observable public savedCTS: [DeviceCT, DeviceCT, DeviceCT] | DeviceCT[] = [];
  @observable public deviceType: GMD | TPH | RS485 = GMD;

  constructor(device: PPDevice, children?: PPDevice[]) {
    super(device);
    this.deviceType = device.deviceType as GMD | TPH;

    this.isModbus = (device.plcMac && device.plcMac.includes('_RS')) || false;
    if (this.isModbus) {
      this.modbusAddress =
        device.plcMac &&
        Number(`0x${device.plcMac.split('RS_')[1]}`).toString();
      this.modbusReference = this.deviceTypeOverride;
    }
    if (children && children.length > 0) {
      this.areCTSplit = true;
      this.editedAreCTSplit = true;
      this.cts = children
        .sort((a, b) => (a.preConfig.phase || 0) - (b.preConfig.phase || 0))
        .map(c => new DeviceCT(c)) as [DeviceCT, DeviceCT, DeviceCT];
      this.savedCTS = [...this.cts];
    }
  }

  @computed
  get toMutationPayload(): EPPDevice[] {
    const self = {
      ...this.payLoadData,
      preConfig: {
        ...this.preConfig
      }
    };
    return !(this.cts && this.editedAreCTSplit)
      ? [self]
      : [self, ...this.cts.map(ct => ct.getMutationPayload(this))];
  }

  @computed
  get isSplitable(): boolean {
    return !this.isModbus;
  }

  @computed
  get getIsModbus() {
    return this.isModbus;
  }

  @computed
  get getCanHaveGreenPlay() {
    return (
      !this.getIsModbus &&
      this.getDeviceType !== GMD &&
      this.getDeviceType !== GMD_G3
    );
  }

  @computed
  get isTodo() {
    return this.editedAreCTSplit && this.cts
      ? this.cts.some(c => {
          return c.isTodo;
        })
      : !this.controlledDeviceType;
  }

  @computed
  get getCanMerge(): boolean {
    return (
      inscription.gridType === GridType.THREE_PHASE || this.deviceType === TPH
    );
  }

  @computed
  get getCanSplit(): boolean {
    return (
      inscription.gridType === GridType.THREE_PHASE ||
      inscription.gridType === GridType.SINGLE_PHASE
    );
  }

  @computed
  get isDone() {
    return this.editedAreCTSplit && this.cts
      ? this.cts.some(c => c.isDone)
      : !!this.controlledDeviceType;
  }

  @action.bound
  public split() {
    if (!this.areCTSplit || !this.editedAreCTSplit) {
      const compositeId = uuid();
      this.cts = [1, 2, 3].map(e => {
        return new DeviceCT({
          ...this.toMutationPayload[0],
          power: this.power,
          id: undefined,
          preConfig: {
            ...this.toMutationPayload[0].preConfig,
            compositeId,
            phase: e,
            controlledDeviceType: undefined,
            controlledDeviceName: `${t('Devices:counter')} ${e}`,
            greenPlayEnabled: false
          }
        });
      });
      this.editedAreCTSplit = true;
      this.compositeId = compositeId;
      this.editedControlledDeviceType = COMPOSITE_DEVICE;
    }
  }

  @computed
  get getFunctionOptions(): ISelectValue[] {
    let functionOptions: ISelectValue[] = [];

    if (this.isModbus) {
      functionOptions = getDeviceFunctionOptions(modbusFunctionOptions);
    } else {
      const prioritiedOptions = getDeviceFunctionOptions(
        compositePrioritiedFunctionOptions
      );
      const otherOptions = sortSelectOptionsAlphabetically(
        getDeviceFunctionOptions(compositeOtherFunctionOptions)
      );

      switch (this.getDeviceType) {
        case GMD:
        case GMD_G3:
        case TPH:
          functionOptions = [...prioritiedOptions, ...otherOptions];
          break;
        default:
          functionOptions = [];
      }
    }

    return functionOptions;
  }

  @action.bound
  public merge() {
    this.cts = [];
    this.editedAreCTSplit = false;
    this.editedControlledDeviceType = undefined;
  }

  @computed
  get canSaveFunction(): boolean {
    return (
      (this.getCanMerge || this.getCanSplit) &&
      !this.isDeviceForbiddenToMergeOnInstallationSinglePhase &&
      !!this.editedControlledDeviceType &&
      (this.editedAreCTSplit
        ? this.cts && this.cts.some(c => c.canSaveFunction)
        : !this.editedControlledDeviceNameError &&
          (this.greenPlayChanged ||
            this.controlledDeviceTypeChanged ||
            this.controlledDeviceNameChanged))
    );
  }

  @action.bound
  public cancelChanges() {
    this.editedAreCTSplit = this.areCTSplit;
    if (this.editedAreCTSplit) {
      this.cts.forEach(c => c.cancelChanges());
    }
    this.cts = this.savedCTS;
    this.editedControlledDeviceType = this.controlledDeviceType;
    this.editedControlledDeviceName = this.controlledDeviceName;
    this.editedRoomName = this.roomName;
    this.editedRoomType = this.roomType;
    this.editedGreenPlayEnabled = this.greenPlayEnabled;
  }

  @computed
  get isGlobalConsumption() {
    if (this.areCTSplit) {
      return this.cts.some(
        ct => ct.controlledDeviceType === ASOKA_ELECTRIC_COUNTER
      );
    }
    return this.controlledDeviceType === ASOKA_ELECTRIC_COUNTER;
  }

  @computed
  get isProductionCounter() {
    if (this.areCTSplit) {
      return this.cts.some(
        ct => ct.controlledDeviceType === PRODUCTION_COUNTER
      );
    }
    return this.controlledDeviceType === PRODUCTION_COUNTER;
  }

  @computed
  get isCounter(): boolean {
    if (!this.areCTSplit) {
      return (
        this.controlledDeviceType === ASOKA_ELECTRIC_COUNTER ||
        this.controlledDeviceType === 'production_counter'
      );
    }
    return this.cts && this.cts.some(c => c.isCounter);
  }

  @computed
  get isNegativePower(): boolean {
    if (this.editedAreCTSplit) {
      return Boolean(
        this.isDone &&
          this.cts.some((ct: DeviceCT) => {
            return (
              (this.controlledDeviceType === WATER_HEATER ||
                this.controlledDeviceType === PRODUCTION_COUNTER) &&
              ct.getDevicePower < 0
            );
          })
      );
    }

    return Boolean(
      this.isDone &&
        (this.controlledDeviceType === WATER_HEATER ||
          this.controlledDeviceType === PRODUCTION_COUNTER) &&
        this.getDevicePower < 0
    );
  }

  @computed
  get getDeviceType(): string {
    if (this.deviceType === TPH) {
      return TPH;
    }
    if (this.deviceType === GMD && !this.isEmulatedFromG3) {
      return GMD;
    }
    if (this.deviceType === GMD && this.isEmulatedFromG3) {
      return GMD_G3;
    }
    if (this.deviceType === RS485) {
      return RS485;
    }
    return this.deviceType || 'unknown_device';
  }

  @computed
  get getDeviceFullName(): string {
    if (this.isModbus) {
      return `${t(`DeviceType:rs485`)} - ${this.modbusReference} - ${
        this.modbusAddress
      }`;
    }
    return t(`DeviceType:${this.getDeviceType}`);
  }

  @computed
  get hasTooManyGlobalConsumptionSelected(): boolean {
    const devicesWithGlobalConsumptionSelected = this.cts.filter(
      ct => ct.hasSelectedGlobalConsumption
    );
    return devicesWithGlobalConsumptionSelected.length > 1;
  }

  @computed
  get hasSelectedGlobalConsumption() {
    if (this.editedAreCTSplit) {
      return this.cts.some(ct => ct.hasSelectedGlobalConsumption);
    }
    return this.editedControlledDeviceType === ASOKA_ELECTRIC_COUNTER;
  }

  @computed
  get getModbusReferenceId(): string | undefined {
    if (this.isModbus && this.modbusReference) {
      return `rs485_${this.modbusReference.substr(0, 3).toLowerCase()}`;
    }
  }

  @computed
  get getDeviceImageType(): string {
    return this.isModbus
      ? this.getModbusReferenceId || ''
      : this.getDeviceType.toLowerCase();
  }

  @computed
  get isForbidden(): boolean {
    return (
      this.isDone && this.isDeviceForbiddenToMergeOnInstallationSinglePhase
    );
  }

  @computed
  get isDeviceForbiddenToMergeOnInstallationSinglePhase(): boolean {
    if (this.getIsModbus || this.deviceType === TPH) {
      return false;
    }

    return (
      inscription.gridType === GridType.SINGLE_PHASE && !this.editedAreCTSplit
    );
  }

  @computed
  public get getDeviceFunctionNamePrefix(): string {
    const devicesWithMeasureOfPrefix = [GMD, GMD_G3, RS485];
    const devicesWithMeasureAndControlOfPrefix = [TPH];

    if (devicesWithMeasureOfPrefix.includes(this.getDeviceType)) {
      return t('Devices:measureOfPrefix');
    }
    if (devicesWithMeasureAndControlOfPrefix.includes(this.getDeviceType)) {
      return t('Devices:measureAndControlOfPrefix');
    }

    return '';
  }
}
