import React, { ReactNode } from 'react';
import { Col, Row } from 'reactstrap';
import './linksContainer.scss';

interface IProps {
  children: ReactNode;
}

const LinksContainer = ({ children }: IProps) => (
  <div className={'links-container'}>
    <Row>
      <Col xs="12">{children}</Col>
    </Row>
  </div>
);

export default LinksContainer;
