import { Col, FormGroup } from 'reactstrap';
import React from 'react';
import { safeMobxInject } from '../../../../../../stores/storeInjectionHelpers';
import { withApollo, WithApolloClient } from 'react-apollo';
import { translate, TranslationFunction } from 'react-i18next';
import { ActivationMySmartBatteryMylight } from '../../../../../../stores/my_smart_battery/activationMySmartBatteryMylight';
import { RouteComponentProps, withRouter } from 'react-router';
import LoadingButton from '../../../../../../ui/Button/LoadingButton/LoadingButton';
import { ActivationMySmartBatteryStrasbourgElectricityAdministrativeProcedure } from '../../../../../../stores/my_smart_battery/activationMySmartBatteryStrasbourgElectricityAdministrativeProcedure';

interface IStoresProps {
  activationMsbMylight: ActivationMySmartBatteryMylight;
  activationMsbStrasbourgElecAdminProcedure: ActivationMySmartBatteryStrasbourgElectricityAdministrativeProcedure;
}

interface IProps extends RouteComponentProps<IMatchParams> {
  t: TranslationFunction;
}

interface IMatchParams {
  id: string;
}

const StrasbourgElectricityAdministrativeProcedureRequestedButton = safeMobxInject<
  IStoresProps,
  IProps
>(
  stores => ({
    activationMsbMylight: stores.activationMySmartBatteryMylight,
    activationMsbStrasbourgElecAdminProcedure:
      stores.activationMySmartBatteryStrasbourgElectricityAdministrativeProcedure
  }),
  ({
    t,
    activationMsbMylight,
    activationMsbStrasbourgElecAdminProcedure,
    client,
    match: {
      params: { id: customerId }
    }
  }: WithApolloClient<IStoresProps & IProps>) => {
    const canRequestAdministrativeProcedure =
      activationMsbMylight.isMsbMylightFormValid &&
      activationMsbStrasbourgElecAdminProcedure.isStrasbourgElectricityAdministrativeProcedureFormValid;

    const requestAdministrativeProcedure = async () => {
      await activationMsbStrasbourgElecAdminProcedure.requestStrasbourgElectricityAdministrativeProcedure(
        client,
        customerId
      );
    };

    return (
      <FormGroup row style={{ paddingLeft: '15px' }}>
        <Col sm={12} md={6} />
        <Col
          sm={12}
          md={6}
          style={{ display: 'flex', flexDirection: 'row-reverse' }}
        >
          <LoadingButton
            loading={
              activationMsbStrasbourgElecAdminProcedure.isLoadingRequestStrasbourgElectricityAdministrativeProcedure
            }
            disabled={!canRequestAdministrativeProcedure}
            onClick={() => requestAdministrativeProcedure()}
          >
            {t('validate')}
          </LoadingButton>
        </Col>
      </FormGroup>
    );
  }
);

export default translate(['MySmartBattery'])(
  withRouter(
    withApollo(StrasbourgElectricityAdministrativeProcedureRequestedButton)
  )
);
