import * as React from 'react';
import { Customer } from '../../../graphql/graphql.schema';
import { translate, TranslationFunction } from 'react-i18next';
import SelectInput, { ISelectValue } from '../../../ui/Select/Select';

const RackingPowerSelector = ({
  t,
  value,
  onChange,
  required,
  error
}: {
  onChange: (a: number) => void;
  t: TranslationFunction;
  value?: ISelectValue | null;
  required?: boolean;
  error?: string;
}) => {
  return (
    <SelectInput
      required
      onChange={v => {
        onChange(Number(v.value));
      }}
      id={`editInstallationForm-rackingPower`}
      name={`${t('rackingPower')} ${required ? '*' : ''}`}
      options={[3, 6, 9, 12, 15, 18, 24, 30, 36].map(e => ({
        label: String(e),
        value: String(e)
      }))}
      value={value}
      unit={'kVa'}
      error={error}
    />
  );
};

export default translate(['Customer', 'Installation'])(RackingPowerSelector);
