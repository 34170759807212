import { action, computed, observable } from 'mobx';
import { ECustomer } from '../../graphql/graphql.schema';
import {
  basicStringValidator,
  dateValidator,
  emailValidator,
  geolocationValidtor,
  phoneNumberValidator,
  postalCodeValidator,
  userNameValidator
} from '../../utils/validators';
import { t } from 'i18next';

export class EditCustomer {
  @observable public id: string = '';
  @observable public email?: string = undefined;
  @observable public lastName?: string = undefined;
  @observable public firstName?: string = undefined;
  @observable public address?: string = undefined;
  @observable public addressSup?: string = undefined;
  @observable public zipCode?: string = undefined;
  @observable public city?: string = undefined;
  @observable public country?: string = undefined;
  @observable public phoneNumber?: string = undefined;
  @observable public mobileNumber?: string = undefined;
  @observable public latitude?: number = undefined;
  @observable public longitude?: number = undefined;
  @observable public birthDate?: Date = undefined;
  @observable public smsAlertsEnabled?: boolean = undefined;
  @observable public emailAlertsEnabled?: boolean = undefined;
  @observable public emailTaken?: boolean = undefined;

  @action.bound
  public setId(id: string) {
    this.id = id;
  }

  @action.bound
  public setEmail(email: string) {
    this.emailTaken = false;
    this.email = email;
  }

  @action.bound
  public setLastName(lastName: string) {
    this.lastName = lastName;
  }

  @action.bound
  public setFirstName(firstName: string) {
    this.firstName = firstName;
  }

  @action.bound
  public setAddress(address: string) {
    this.address = address;
  }

  @action.bound
  public setAddressSup(addressSup: string) {
    this.addressSup = addressSup;
  }

  @action.bound
  public setZipCode(zipCode: string) {
    this.zipCode = zipCode;
  }

  @action.bound
  public setCity(city: string) {
    this.city = city;
  }

  @action.bound
  public setCountry(country: any) {
    this.country = country.value;
  }

  @action.bound
  public setPhoneNumber(phoneNumber: string) {
    this.phoneNumber = phoneNumber;
  }

  @action.bound
  public setMobileNumber(mobileNumber: string) {
    this.mobileNumber = mobileNumber;
  }

  @action.bound
  public setLatitude(latitude: number) {
    this.latitude = latitude;
  }

  @action.bound
  public setLongitude(longitude: number) {
    this.longitude = longitude;
  }

  @action.bound
  public setBirthDate(birthDate: Date) {
    this.birthDate = birthDate;
    this.birthDate.setHours(0, 0, 0, 0);
  }

  @action.bound
  public setSmsAlertsEnabled(smsAlertsEnabled: boolean) {
    this.smsAlertsEnabled = smsAlertsEnabled;
  }

  @action.bound
  public setEmailAlertsEnabled(emailAlertsEnabled: boolean) {
    this.emailAlertsEnabled = emailAlertsEnabled;
  }

  @action.bound
  public init(customer: ECustomer) {
    this.id = customer.id;
    this.email = customer.email;
    this.lastName = customer.lastName;
    this.firstName = customer.firstName;
    this.address = customer.address;
    this.addressSup = customer.addressSup;
    this.zipCode = customer.zipCode;
    this.city = customer.city;
    this.country = customer.country;
    this.phoneNumber = customer.phoneNumber || '';
    this.mobileNumber = customer.mobileNumber || '';
    this.latitude = customer.latitude;
    this.longitude = customer.longitude;
    this.birthDate = new Date(customer.birthDate);
    this.birthDate.setHours(0, 0, 0, 0);
    this.smsAlertsEnabled = customer.smsAlertsEnabled;
    this.emailAlertsEnabled = customer.emailAlertsEnabled;
  }

  @computed
  get toCustomerMutation(): ECustomer {
    return {
      id: this.id,
      email: (this.email || '').toLowerCase(), // FIXME email should not be optional
      lastName: this.lastName,
      firstName: this.firstName,
      address: this.address,
      addressSup: this.addressSup,
      zipCode: this.zipCode,
      city: this.city,
      country: this.country,
      phoneNumber: this.phoneNumber,
      mobileNumber: this.mobileNumber,
      latitude: this.latitude,
      longitude: this.longitude,
      birthDate: this.birthDate && this.birthDate.getTime(),
      smsAlertsEnabled: this.smsAlertsEnabled,
      emailAlertsEnabled: this.emailAlertsEnabled
    };
  }

  @computed
  get firstNameError() {
    return userNameValidator(this.firstName);
  }

  @computed
  get lastNameError() {
    return userNameValidator(this.lastName);
  }

  @computed
  get mobilePhoneError() {
    return phoneNumberValidator(this.mobileNumber);
  }

  @computed
  get phoneNumberError() {
    return phoneNumberValidator(this.phoneNumber);
  }

  @computed
  get postalCodeError() {
    return postalCodeValidator(this.zipCode);
  }

  @computed
  get cityError() {
    return basicStringValidator(this.city);
  }

  @computed
  get addressError() {
    return basicStringValidator(this.address);
  }

  @computed
  get emailAddressError() {
    return emailValidator(this.email);
  }

  @computed
  get emailTakenError() {
    return this.emailTaken;
  }

  @computed
  get geolocationError() {
    return geolocationValidtor(this.latitude, this.longitude);
  }

  @computed
  get birthDateError() {
    return dateValidator(this.birthDate);
  }

  @computed
  get getPhoneError() {
    let phoneError;
    let mobilePhoneError;
    if (this.mobileNumber !== '') {
      mobilePhoneError = phoneNumberValidator(this.mobileNumber);
    }
    if (this.phoneNumber !== '') {
      phoneError = phoneNumberValidator(this.phoneNumber);
    }
    if (this.phoneNumber === '' && this.mobileNumber === '') {
      return (
        phoneNumberValidator(this.phoneNumber) ||
        phoneNumberValidator(this.mobileNumber)
      );
    }
    return phoneError || mobilePhoneError;
  }

  @computed
  get getMissingFields(): string[] | undefined {
    const firstNameIsMissing = this.firstNameError && t('Customer:firstName');
    const lastNameIsMissing = this.lastNameError && t('Customer:name');
    const addressIsMissing = this.addressError && t('Customer:address');
    const postalCodeIsMissing =
      this.postalCodeError && t('Customer:postalCode');
    const cityIsMissing = this.cityError && t('Customer:city');
    const phoneIsMissing = this.getPhoneError && t('Customer:phone');
    const birthDateErrorIsMissing =
      this.birthDateError && t('Customer:birthDate');
    const emailIsMissing =
      (this.emailAddressError || this.emailTakenError) && t('Customer:email');
    const missingFields = [
      firstNameIsMissing,
      lastNameIsMissing,
      addressIsMissing,
      postalCodeIsMissing,
      cityIsMissing,
      phoneIsMissing,
      birthDateErrorIsMissing,
      emailIsMissing
    ];
    if (missingFields.some(field => !!field)) {
      return missingFields;
    }
    return undefined;
  }

  @computed
  get formIsInvalid() {
    return Boolean(
      this.firstNameError ||
        this.lastNameError ||
        this.cityError ||
        this.getPhoneError ||
        this.addressError ||
        this.postalCodeError ||
        this.emailAddressError ||
        this.emailTakenError ||
        this.geolocationError ||
        this.birthDateError
    );
  }

  @action.bound
  public emailIsTaken(isTaken: boolean) {
    this.emailTaken = isTaken;
  }
}

const editCustomerStore: EditCustomer = new EditCustomer();
export default editCustomerStore;
