import React, { useState } from 'react';
import {
  Mutation,
  MutationResult,
  Query,
  QueryResult,
  withApollo,
  WithApolloClient
} from 'react-apollo';

import gql from 'graphql-tag';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IInscriptionNavigationRouterParamProps } from '../InscriptionNavigation';
import { safeMobxInject } from '../../../stores/storeInjectionHelpers';
import UcgPreconfigurationModalView from './UcgPreconfigurationView';
import UcgPreconfigurationSummary from './UcgPreconfigurationSummary';
import i18n from '../../../translations/i18n';
import { EPPDevice, PPDeviceConfigFile } from '../../../graphql/graphql.schema';
import {
  DEVICE_INSCRIPTION_FIELD_QUERY,
  GET_CUSTOMER_AND_DEVICE_CONFIG,
  ICustomerAndDevicesResponse
} from '../DevicesInscription';
import { ApolloError } from 'apollo-client';
import { translate, TranslationFunction } from 'react-i18next';
import { ISnackBarParams } from '../../../stores/userFeedback/snackbarStore';
import omitDeepLodash from '../../../utils/omitDeepLodash';

export interface IModbusDevices {
  reference: string;
  address: string;
}

export interface IProps
  extends RouteComponentProps<IInscriptionNavigationRouterParamProps> {
  closeModal: () => void;
  t: TranslationFunction;
}

interface IStoresProps {
  initErrorsFromGQLError: (e: ApolloError) => void;
  showSnackBar: (params: ISnackBarParams) => void;
  refreshDeviceList: (
    data: ICustomerAndDevicesResponse,
    customerId: string,
    pvInstallationId: string,
    current?: string
  ) => void;
}

interface IResponse {
  saveConfig: PPDeviceConfigFile;
}

interface IParams {
  devices: EPPDevice[];
  pvInstallationId: string;
  lang: string;
}

const APPLY_UCG_PRECONFIGURATION = gql`
    mutation saveConfig($devices: [EPPDevice], $pvInstallationId: String!, $lang: String) {
        updateDeviceConfig(devices: $devices, pvInstallationId: $pvInstallationId, lang: $lang) {
            ${DEVICE_INSCRIPTION_FIELD_QUERY}
        }
    }
`;

const GET_UCG_PRECONFIGURATION = gql`
  query getUCGPreconfiguration($pvInstallationId: String!, $lang: String!) {
    getUCGPreconfiguration(pvInstallationId: $pvInstallationId, lang: $lang) {
      devices {
        id
        deviceType
        masterMac
        deviceTypeOverride
        activationCode
        ethMac
        plcMac
        emulatedFromG3
        preConfig {
          controlledDeviceType
          controlledDeviceName
          roomName
          roomType
          phase
          isDeleted
          compositeId
          greenPlayEnabled
        }
      }
      errors
      addedModbusDevices {
        reference
        address
      }
    }
  }
`;

const UcgPreconfigurationQuery = safeMobxInject<IStoresProps, IProps>(
  stores => ({
    showSnackBar: stores.snackBar.showSnackBar,
    initErrorsFromGQLError: stores.inscriptionError.initErrorsFromApolloError,
    refreshDeviceList: stores.inscription.refreshDeviceList
  }),
  ({
    client,
    t,
    match: {
      params: { pvInstallationId, customerId }
    },
    initErrorsFromGQLError,
    closeModal,
    showSnackBar,
    refreshDeviceList
  }: WithApolloClient<IProps & IStoresProps>) => {
    const [
      ucgPreconfigurationApplied,
      setUcgPreconfigurationApplied
    ] = useState(false);

    const [refreshLoading, setRefreshLoading] = useState<boolean>(false);

    return (
      <Mutation<IResponse, IParams>
        mutation={APPLY_UCG_PRECONFIGURATION}
        onCompleted={() => {
          setUcgPreconfigurationApplied(true);
          showSnackBar({
            text: t('Devices:preconfigurationAppliedWithSuccess'),
            type: 'success'
          });
        }}
        onError={e => initErrorsFromGQLError!(e)}
      >
        {(applyPreconfiguration, { loading: applyLoading }: MutationResult) => {
          return (
            <Query
              query={GET_UCG_PRECONFIGURATION}
              variables={{ pvInstallationId, lang: i18n.language }}
              fetchPolicy={'no-cache'}
            >
              {({ data, loading: fetchLoading }: QueryResult) => {
                const errors: string[] =
                  (data &&
                    data.getUCGPreconfiguration &&
                    data.getUCGPreconfiguration.errors) ||
                  [];

                const devices: EPPDevice[] =
                  (data &&
                    data.getUCGPreconfiguration &&
                    omitDeepLodash(data.getUCGPreconfiguration.devices, [
                      '__typename'
                    ])) ||
                  [];
                const addedModbusDevices: IModbusDevices[] =
                  (data &&
                    data.getUCGPreconfiguration &&
                    data.getUCGPreconfiguration.addedModbusDevices) ||
                  [];

                const closeUcgPreconfiguration = () => {
                  if (addedModbusDevices.length > 0) {
                    setRefreshLoading(true);
                    client
                      .query({
                        fetchPolicy: 'no-cache',
                        query: GET_CUSTOMER_AND_DEVICE_CONFIG,
                        variables: {
                          customerId,
                          pvInstallationId,
                          lang: i18n.language
                        }
                      })
                      .then(
                        ({
                          data: customersAndDevices
                        }: {
                          data: ICustomerAndDevicesResponse;
                        }) => {
                          setRefreshLoading(false);
                          refreshDeviceList(
                            customersAndDevices,
                            customerId,
                            pvInstallationId
                          );
                        }
                      )
                      .catch((e: ApolloError) => {
                        setRefreshLoading(false);
                        initErrorsFromGQLError(e);
                      })
                      .finally(() => {
                        closeModal();
                      });
                  } else {
                    closeModal();
                  }
                };

                return (
                  <>
                    {ucgPreconfigurationApplied ? (
                      <UcgPreconfigurationSummary
                        errors={errors}
                        addedModbusDevices={addedModbusDevices}
                        applyLoading={applyLoading}
                        refreshLoading={refreshLoading}
                        closeUcgPreconfiguration={closeUcgPreconfiguration}
                      />
                    ) : (
                      <UcgPreconfigurationModalView
                        applyPreconfiguration={() => {
                          const devicesToApply = devices.map(device => {
                            delete device.emulatedFromG3;
                            return device;
                          });
                          applyPreconfiguration({
                            variables: {
                              pvInstallationId,
                              devices: devicesToApply,
                              lang: i18n.language
                            }
                          });
                        }}
                        devices={devices}
                        errors={errors}
                        addedModbusDevices={addedModbusDevices}
                        fetchLoading={fetchLoading}
                        applyLoading={applyLoading}
                        refreshLoading={refreshLoading}
                        closeUcgPreconfiguration={closeUcgPreconfiguration}
                      />
                    )}
                  </>
                );
              }}
            </Query>
          );
        }}
      </Mutation>
    );
  }
);

export default withRouter(
  translate(['Devices'])(withApollo(UcgPreconfigurationQuery))
);
