import { default as React, ReactNode } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { inject, observer } from 'mobx-react';
import { getTenantLogo } from '../../utils/tools';
import { IAllStores } from '../../stores/allStores.model';
import { Profile } from '../../stores/profile';
import MainMenuStore from '../../stores/navigation/mainMenu';
import { MainMenu } from './MainMenu/MainMenu';

interface IProps extends RouteComponentProps<IMatchParams> {
  children?: ReactNode;
  mainMenu?: MainMenuStore;
  profile?: Profile;
  hide?: () => void;
  text?: string;
  prosizerUrl?: string;
  visible?: boolean;
}

interface IMatchParams {
  current: string;
}

@inject((allStores: IAllStores) => {
  return {
    mainMenu: allStores.Store.mainMenu,
    profile: allStores.Store.profile,
    prosizerUrl: allStores.environment.prosizerUrl
  };
})
@observer
class MainNavigation extends React.Component<IProps> {
  public componentWillReceiveProps(next: IProps) {
    const { setTitle } = this.props.mainMenu!;
    if (next.location.pathname !== this.props.location.pathname) {
      this.props.mainMenu!.setMenuVisibility(false);
      setTitle(undefined);
    }
  }

  public render() {
    const {
      children,
      match: {
        params: { current }
      },
      mainMenu,
      profile,
      prosizerUrl
    } = this.props;
    const {
      menuIsVisible,
      setMenuVisibility,
      title,
      mainMenuItems
    } = mainMenu!;

    const { tenant } = profile!;
    return (
      <MainMenu
        logo={getTenantLogo(tenant)}
        mainMenuItems={mainMenuItems}
        title={title}
        closeMenu={() => {
          setMenuVisibility(false);
        }}
        openMenu={() => {
          setMenuVisibility(true);
        }}
        menuIsVisible={menuIsVisible}
        currentItem={mainMenuItems.find(m => m.path.split('/')[0] === current)}
        prosizerUrl={prosizerUrl}
      >
        {children}
      </MainMenu>
    );
  }
}

export default withRouter(MainNavigation);
