import * as React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Query, QueryResult } from 'react-apollo';

import { translate } from 'react-i18next';
import gql from 'graphql-tag';
import { RouteComponentProps, withRouter } from 'react-router';
import Spinner from '../../../ui/Spinner/Spinner';
import { TranslationFunction } from 'i18next';
import {
  Field,
  FieldEmail,
  FieldList,
  FieldPhone,
  Title
} from '../../../ui/DataDisplay/FieldList/FieldList';
import { Company, Customer, ProUser } from '../../../graphql/graphql.schema';
export const GET_CUSTOMER = gql`
  query getCustomer($id: String!) {
    customer(id: $id) {
      id
      pvInstallations {
        id
        companies {
          id
          companyName
          address
          zipCode
          city
          country
          companyLocalRegistrationCode
          proUsers {
            email
            firstName
            lastName
            phoneNumber
            mobileNumber
            role {
              name
            }
          }
        }
      }
    }
  }
`;

interface IResponse {
  customer: Customer;
}

interface IMatchParams {
  id: string;
}

export interface IProps extends RouteComponentProps<IMatchParams> {}

const ProUserContactUI = translate(['Customer', 'Common'])(
  ({
    t,
    proUser,
    company
  }: {
    t: TranslationFunction;
    proUser: ProUser;
    company: Company;
  }) => {
    return (
      <Col md={12} lg={6}>
        <FieldList>
          <Title text={t(`Common:${proUser.role && proUser.role.name}`)} />
          <Field text={company && company.companyName} />
          <Field
            name={t('contact')}
            text={
              proUser &&
              `${proUser.firstName ? proUser.firstName.toUpperCase() : ''} ${
                proUser.lastName
              }`
            }
          />
          <Field
            name={t('address')}
            text={
              company &&
              `${company.address} ${company.zipCode || ''} ${company.city}`
            }
          />
          <Field name={t('country')} text={company && company.country} />
          <FieldEmail name={t('email')} email={proUser && proUser.email} />
          <FieldPhone
            name={t('phone')}
            phone={proUser && proUser.phoneNumber}
          />
          <FieldPhone
            name={t('mobile')}
            phone={proUser && proUser.mobileNumber}
          />
        </FieldList>
      </Col>
    );
  }
);

const ProContact = ({
  match: {
    params: { id }
  }
}: IProps) => {
  return (
    <Query query={GET_CUSTOMER} variables={{ id }} fetchPolicy={'no-cache'}>
      {({ loading, data, error }: QueryResult<IResponse>) => {
        return (
          <Spinner loading={loading} error={error ? error.message : undefined}>
            <Container fluid>
              <Col className="mobile-helper">
                <Row>
                  {data &&
                    data.customer &&
                    data.customer.pvInstallations &&
                    data.customer.pvInstallations.map(
                      p =>
                        p.companies &&
                        p.companies.map(
                          c =>
                            c.proUsers &&
                            c.proUsers.map(pp => (
                              <ProUserContactUI
                                key={pp.id}
                                company={c}
                                proUser={pp}
                              />
                            ))
                        )
                    )}
                </Row>
              </Col>
            </Container>
          </Spinner>
        );
      }}
    </Query>
  );
};

export default withRouter(ProContact);
