import { action, computed, observable } from 'mobx';
import {
  basicSelectValidator,
  basicStringValidator,
  dateValidator,
  emailValidator,
  geolocationValidtor,
  phoneNumberValidator,
  postalCodeValidator,
  userNameValidator
} from '../../../utils/validators';
import { ISelectValue } from '../../../ui/Select/Select';
import { FTenant, Maintainer, ProUser } from '../../../graphql/graphql.schema';
import { t } from 'i18next';

export enum TenantName {
  MYHOME = 'myhome',
  DOMUNEO = 'domuneo',
  ENGIE = 'mypower',
  PLANETE_OUI = 'planeteoui'
}

export default class CreateCustomerForm {
  @observable public maintainer?: Maintainer = undefined;
  @observable public tenant?: FTenant = undefined;
  @observable public installer?: ProUser = undefined;
  @observable public tenantId: string = TenantName.MYHOME;
  @observable public firstName: string = '';
  @observable public lastName: string = '';
  @observable public addressSuggestions: ISelectValue[] = [];

  @observable public address: string = '';
  @observable public postalCode: string = '';
  @observable public city: string = '';
  @observable
  public country: ISelectValue = {
    value: 'FR',
    label: 'France'
  };
  @observable public additionalAddress?: string = '';
  @observable public latitude?: number = undefined;
  @observable public longitude?: number = undefined;

  @observable public emailAddress: string = '';
  @observable public emailTaken: boolean = false;
  @observable public birthDate: Date = new Date();
  @observable public phone: string = '';
  @observable public mobilePhone: string = '';
  @observable public gridType: ISelectValue;
  @observable public emailAlertsEnabled: boolean = true;

  @observable public addressValidated: boolean = false;

  @action
  public reset() {
    // FIXME current selectInput don't reset when valuer is undefined
    // this.maintainer = undefined;
    // this.tenant = undefined;
    // this.installer = undefined;
    this.tenantId = TenantName.MYHOME;
    this.firstName = '';
    this.lastName = '';
    this.addressSuggestions = [];

    this.address = '';
    this.postalCode = '';
    this.city = '';
    this.country = {
      value: 'FR',
      label: 'France'
    };
    this.additionalAddress = '';
    this.latitude = undefined;
    this.longitude = undefined;

    this.emailAddress = '';
    this.emailTaken = false;
    this.birthDate = new Date();
    this.phone = '';
    this.mobilePhone = '';
    this.emailAlertsEnabled = true;
    this.addressValidated = false;
  }

  @action.bound
  public updateMaintainer(maintainer?: Maintainer) {
    this.maintainer = maintainer;

    const selectedMaintainerTenants =
      maintainer &&
      maintainer.maintainer.company &&
      maintainer.maintainer.company.tenants;

    if (selectedMaintainerTenants && selectedMaintainerTenants.length > 0) {
      this.tenant = selectedMaintainerTenants[0];
    }
  }

  @action.bound
  public updateInstaller(input?: ProUser) {
    this.installer = input;
  }

  @action.bound
  public updateFirstName(input: string) {
    this.firstName = input;
  }

  @action.bound
  public updateLastName(input: string) {
    this.lastName = input;
  }

  @action.bound
  public updateAddress(input: string) {
    this.address = input;
  }

  @action.bound
  public updatePostalCode(input: string) {
    this.postalCode = input;
  }

  @action.bound
  public updateCity(input: string) {
    this.city = input;
  }

  @action.bound
  public updateCountry(input: ISelectValue) {
    this.country = input;
  }

  @action.bound
  public updateAdditionalAddress(input?: string) {
    this.additionalAddress = input;
  }

  @action.bound
  public updateLatitude(input?: number) {
    this.latitude = input;
  }

  @action.bound
  public updateLongitude(input?: number) {
    this.longitude = input;
  }

  @action.bound
  public updateEmailAddress(input: string) {
    this.emailTaken = false;
    this.emailAddress = input;
  }

  @action.bound
  public updatePhone(phone: string) {
    this.phone = phone;
  }

  @action.bound
  public updateMobilePhone(mobilePhone: string) {
    this.mobilePhone = mobilePhone;
  }

  @action.bound
  public updateBirthDate(input?: Date) {
    this.birthDate = input || new Date();
    this.birthDate.setHours(0, 0, 0, 0);
  }

  @action.bound
  public emailIsTaken(isTaken: boolean) {
    this.emailTaken = isTaken;
  }

  @action.bound
  public updateGridType(input: ISelectValue) {
    this.gridType = input;
  }

  @action.bound
  public setEmailAlertsEnabled(b: boolean) {
    this.emailAlertsEnabled = b;
  }

  @action.bound
  public setTenant(iSelectValue: ISelectValue) {
    this.tenant =
      this.maintainer &&
      this.maintainer.maintainer.company &&
      this.maintainer.maintainer.company.tenants &&
      this.maintainer.maintainer.company.tenants.find(
        ten => ten.id === iSelectValue.value
      );
  }

  @computed
  get getTenants() {
    return (
      (this.maintainer &&
        this.maintainer.maintainer.company &&
        this.maintainer.maintainer.company.tenants &&
        this.maintainer.maintainer.company.tenants) ||
      []
    );
  }

  @computed
  get getSelectTenantOptions(): ISelectValue[] {
    const orderedOptions: string[] = [
      TenantName.MYHOME,
      TenantName.DOMUNEO,
      TenantName.ENGIE,
      TenantName.PLANETE_OUI
    ];
    const tenants = this.getTenants.map(tenant => ({
      value: tenant.id || '',
      label: t(`Tenant:${tenant.name}`),
      name: tenant.name || ''
    }));

    tenants.sort((a, b) => {
      // Sort options in the order given by orderedOptions.
      // If the option doesn't belong to this list, it will be
      // placed at the end.
      const idA = orderedOptions.indexOf(a.name);
      const idB = orderedOptions.indexOf(b.name);

      if (idA === -1) return 1;
      if (idB === -1) return -1;

      return idA - idB;
    });

    return tenants.map(tenant => ({
      value: tenant.value,
      label: tenant.label
    }));
  }

  @computed
  get getSelectMaintainerValue(): ISelectValue | undefined {
    return (
      this.maintainer &&
      this.maintainer.maintainer && {
        value:
          (this.maintainer.maintainer && this.maintainer.maintainer.id) || '',
        label: `${this.maintainer.companyName} - ${this.maintainer.companyCode}`
      }
    );
  }

  @computed
  get getSelectTenantValue(): ISelectValue | undefined {
    return (
      this.tenant && {
        value: this.tenant.id || '',
        label: t(`Tenant:${this.tenant.name}`)
      }
    );
  }

  @computed
  get firstNameError() {
    return userNameValidator(this.firstName);
  }

  @computed
  get maintainerError() {
    return !this.tenant;
  }
  @computed
  get tenantError() {
    return !this.tenant;
  }

  @computed
  get lastNameError() {
    return userNameValidator(this.lastName);
  }

  @computed
  get mobilePhoneError() {
    return phoneNumberValidator(this.mobilePhone);
  }

  @computed
  get phoneNumberError() {
    return phoneNumberValidator(this.phone);
  }

  @computed
  get postalCodeError() {
    return postalCodeValidator(this.postalCode);
  }

  @computed
  get cityError() {
    return basicStringValidator(this.city);
  }

  @computed
  get addressError() {
    return basicStringValidator(this.address);
  }

  @computed
  get emailAddressError() {
    return emailValidator(this.emailAddress);
  }

  @computed
  get emailTakenError() {
    return this.emailTaken;
  }

  @computed
  get geolocationError() {
    return geolocationValidtor(this.latitude, this.longitude);
  }

  @computed
  get birthDateError() {
    return dateValidator(this.birthDate);
  }

  @computed
  get gridTypeError() {
    return basicSelectValidator(this.gridType);
  }

  @computed
  get getPhoneError() {
    let phoneError;
    let mobilePhoneError;
    if (this.mobilePhone !== '') {
      mobilePhoneError = phoneNumberValidator(this.mobilePhone);
    }
    if (this.phone !== '') {
      phoneError = phoneNumberValidator(this.phone);
    }
    if (this.phone === '' && this.mobilePhone === '') {
      return (
        phoneNumberValidator(this.phone) ||
        phoneNumberValidator(this.mobilePhone)
      );
    }
    return phoneError || mobilePhoneError;
  }

  @computed
  get getMissingFieldsInstaller(): string[] | undefined {
    const maintainerIsMissing =
      this.maintainerError && t('Customer:maintainer');
    const tenantIsMissing = this.tenantError && t('Customer:tenant');
    const firstNameIsMissing = this.firstNameError && t('Customer:firstName');
    const lastNameIsMissing = this.lastNameError && t('Customer:name');
    const addressIsMissing = this.addressError && t('Customer:address');
    const postalCodeIsMissing =
      this.postalCodeError && t('Customer:postalCode');
    const cityIsMissing = this.cityError && t('Customer:city');
    const phoneIsMissing = this.getPhoneError && t('Customer:phone');
    const birthDateErrorIsMissing =
      this.birthDateError && t('Customer:birthDate');
    const emailIsMissing =
      (this.emailAddressError || this.emailTakenError) && t('Customer:email');
    const missingFields = [
      maintainerIsMissing,
      tenantIsMissing,
      firstNameIsMissing,
      lastNameIsMissing,
      addressIsMissing,
      postalCodeIsMissing,
      cityIsMissing,
      phoneIsMissing,
      birthDateErrorIsMissing,
      emailIsMissing
    ];
    if (missingFields.some(field => !!field)) {
      return missingFields;
    }
    return undefined;
  }

  @computed
  get installerCreateCustomerFormIsInvalid() {
    return Boolean(
      this.firstNameError ||
        this.lastNameError ||
        this.cityError ||
        this.getPhoneError ||
        this.addressError ||
        this.postalCodeError ||
        this.emailAddressError ||
        this.emailTakenError ||
        this.geolocationError ||
        this.birthDateError ||
        this.tenantError ||
        this.maintainerError
    );
  }

  @computed
  get getMissingFieldsMaintainer(): string[] | undefined {
    const tenantIsMissing = this.tenantError && t('Customer:tenant');
    const firstNameIsMissing = this.firstNameError && t('Customer:firstName');
    const lastNameIsMissing = this.lastNameError && t('Customer:name');
    const addressIsMissing = this.addressError && t('Customer:address');
    const postalCodeIsMissing =
      this.postalCodeError && t('Customer:postalCode');
    const cityIsMissing = this.cityError && t('Customer:city');
    const phoneIsMissing = this.getPhoneError && t('Customer:phone');
    const birthDateErrorIsMissing =
      this.birthDateError && t('Customer:birthDate');
    const emailIsMissing =
      (this.emailAddressError || this.emailTakenError) && t('Customer:email');
    const missingFields = [
      tenantIsMissing,
      firstNameIsMissing,
      lastNameIsMissing,
      addressIsMissing,
      postalCodeIsMissing,
      cityIsMissing,
      phoneIsMissing,
      birthDateErrorIsMissing,
      emailIsMissing
    ];
    if (missingFields.some(field => !!field)) {
      return missingFields;
    }
    return undefined;
  }

  @computed
  get maintainerCreateCustomerFormIsInvalid() {
    return Boolean(
      this.firstNameError ||
        this.lastNameError ||
        this.cityError ||
        this.getPhoneError ||
        this.addressError ||
        this.postalCodeError ||
        this.emailAddressError ||
        this.emailTakenError ||
        this.geolocationError ||
        this.birthDateError ||
        this.tenantError
    );
  }
}
