import React from 'react';

import gql from 'graphql-tag';
import { RouteComponentProps, withRouter } from 'react-router';
import { observer, inject } from 'mobx-react';
import { Query, QueryResult } from 'react-apollo';
import PartnershipsListView from './PartnershipsListView';
import { Device } from '../../../graphql/graphql.schema';
import { IAllStores } from '../../../stores/allStores.model';
import { TadoStore } from '../../../stores/partnerships/tadoStore';
import { EnphaseStore } from '../../../stores/partnerships/enphaseStore';
import { WiserStore } from '../../../stores/partnerships/wiserStore';

export const CHECK_TADO_CONNECTION = gql`
  query checkTadoConnection($customerId: String!) {
    devices(customerId: $customerId) {
      macSerialNumber
      deviceTypeId
      type
    }
    getTadoHomeIdByCustomerId(customerId: $customerId)
    isTadoAccessGranted(customerId: $customerId)
  }
`;

export interface IProps extends RouteComponentProps<IMatchParams> {
  tadoStore?: TadoStore;
  enphaseStore?: EnphaseStore;
  wiserStore?: WiserStore;
}

interface IMatchParams {
  customerId: string;
}

interface IResponse {
  mutationResponse?: any;
  devices: Device[];
  getTadoHomeIdByCustomerId: string;
  isTadoAccessGranted: boolean;
}

const PartnershipsListQuery = inject((allStores: IAllStores) => {
  return {
    tadoStore: allStores.tadoStore,
    enphaseStore: allStores.enphaseStore,
    wiserStore: allStores.wiserStore
  };
})(
  observer(
    ({
      tadoStore,
      enphaseStore,
      match: {
        params: { customerId }
      }
    }: IProps) => {
      return (
        <Query
          query={CHECK_TADO_CONNECTION}
          variables={{ customerId }}
          fetchPolicy={'no-cache'}
          errorPolicy={'all'}
          onCompleted={(data: IResponse) => {
            const isTadoAccessGranted = data && data.isTadoAccessGranted;
            const devices = data && data.devices;
            const tadoHomeId = data && data.getTadoHomeIdByCustomerId;
            tadoStore!.grantTadoAccess(isTadoAccessGranted);
            tadoStore!.setAllDevices(devices || []);
            enphaseStore!.setAllDevices(devices || []);
            tadoStore!.setHomeId(tadoHomeId || undefined);
          }}
        >
          {({ loading, data, error }: QueryResult<IResponse>) => {
            return (
              <PartnershipsListView
                data={data}
                loading={loading}
                error={error}
              />
            );
          }}
        </Query>
      );
    }
  )
);

export default withRouter(PartnershipsListQuery);
