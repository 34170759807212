import { action, computed, observable } from 'mobx';
import { Tenant } from '../graphql/graphql.schema';
import { UserTypes } from '../constants/UserTypes';
import mySmartBatteryListStore from './lists/mySmartBatteryListStore';

export class Profile {
  @observable public userType: UserTypes | null = null;
  @observable public tenant: string = '';
  @observable public tenants: Tenant[] = [];
  @observable public isAdmin: boolean = false;
  @observable public isReadOnly: boolean = false;
  @observable public country: string = '';
  @observable public email: string = '';
  @observable public hasRightPairingToolkit: boolean = false;

  @action.bound
  public setUserType(userType: UserTypes) {
    this.userType = userType;
    this.isAdmin = userType === UserTypes.ADMINISTRATOR;
    this.isReadOnly = userType === UserTypes.MSB_SUPPORT_AGENT;

    mySmartBatteryListStore.resetFilterRule(this.isReadOnly);
  }

  @action.bound
  public setData({
    tenant,
    country,
    tenants,
    email
  }: {
    tenant: string;
    country: string;
    tenants: Tenant[];
    email: string;
  }) {
    this.tenant = tenant;
    this.tenants = tenants;
    this.country = country;
    this.email = email;
  }

  @action.bound
  public setHasRightPairingToolkit(hasRightPairingToolkit: boolean) {
    this.hasRightPairingToolkit = hasRightPairingToolkit;
  }

  @action.bound
  public reset() {
    this.userType = null;
    this.tenant = '';
    this.tenants = [];
    this.isAdmin = false;
    this.country = '';
    this.email = '';
    this.hasRightPairingToolkit = false;
  }

  @computed
  public get useDefaultTenant(): boolean {
    return this.tenants.length === 1;
  }
}
const profile: Profile = new Profile();
export default profile;
