import * as React from 'react';
import { Mutation, MutationResult } from 'react-apollo';
import gql from 'graphql-tag';
import { get } from 'lodash';
import LoadingButton from '../../../../ui/Button/LoadingButton/LoadingButton';
import { RouteComponentProps, withRouter } from 'react-router';
import { ISnackBarParams } from '../../../../stores/userFeedback/snackbarStore';
import { safeMobxInject } from '../../../../stores/storeInjectionHelpers';
import { translate, TranslationFunction } from 'react-i18next';

interface IMatchParams {
  customerId: string;
}

interface IProps extends RouteComponentProps<IMatchParams> {
  emailAddresses: string;
  disabled: boolean;
  onComplete: () => void;
  t: TranslationFunction;
}

interface IStoresProps {
  showSnackBar: (params: ISnackBarParams) => void;
}

interface ISendInscriptionSummaryByEmail {
  success: boolean;
}

interface IResponse {
  sendInscriptionSummaryByEmail: ISendInscriptionSummaryByEmail;
}

const SEND_INSCRIPTION_SUMMARY_BY_EMAIL = gql`
  mutation sendInscriptionSummaryByEmail(
    $emailAddresses: String!
    $customerId: String!
  ) {
    sendInscriptionSummaryByEmail(
      emailAddresses: $emailAddresses
      customerId: $customerId
    ) {
      success
    }
  }
`;

const SendInscriptionSummaryByEmailButton = safeMobxInject<
  IStoresProps,
  IProps
>(
  stores => ({ showSnackBar: stores.snackBar.showSnackBar }),
  ({
    emailAddresses,
    disabled,
    onComplete,
    showSnackBar,
    match: {
      params: { customerId }
    },
    t
  }: IProps & IStoresProps) => {
    return (
      <Mutation
        mutation={SEND_INSCRIPTION_SUMMARY_BY_EMAIL}
        onCompleted={(data: IResponse) => {
          if (get(data, 'sendInscriptionSummaryByEmail.success')) {
            onComplete();
            showSnackBar({
              text: t('Success:sendInscriptionSummary'),
              type: 'success'
            });
          }
        }}
        onError={e => {
          const errorText =
            e.graphQLErrors[0] &&
            e.graphQLErrors[0].message.errorCode === 'emailInvalid'
              ? t('Errors:sendInscriptionSummary')
              : t('Errors:serverError');

          showSnackBar({
            text: errorText,
            type: 'error'
          });
        }}
      >
        {(
          sendInscriptionSummaryByEmail,
          { called, loading, data, error }: MutationResult<IResponse>
        ) => {
          const isError = Boolean(
            (called && !get(data, 'sendInscriptionSummaryByEmail.success')) ||
              (called && error)
          );
          return (
            <LoadingButton
              disabled={disabled}
              loading={loading}
              colorClass="primary"
              fail={isError}
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                event.stopPropagation();
                sendInscriptionSummaryByEmail({
                  variables: { emailAddresses, customerId }
                });
              }}
            >
              {t('Common:send')}
            </LoadingButton>
          );
        }}
      </Mutation>
    );
  }
);
export default withRouter(
  translate(['Common', 'DevicesInscription', 'Success'])(
    SendInscriptionSummaryByEmailButton
  )
);
