import React from 'react';
import logo from '../../assets/images/planeteoui/logo.svg';

const LogoPlaneteOui = () => (
  <div className="logo-wrapper" data-testid={'logo-planeteoui'}>
    <img className="logo" width="100%" src={logo} />
  </div>
);

export default LogoPlaneteOui;
