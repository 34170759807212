import React from 'react';
import { Query, QueryResult } from 'react-apollo';
import { ApolloError } from 'apollo-client';

import gql from 'graphql-tag';
import Spinner from '../../ui/Spinner/Spinner';
import { Redirect } from 'react-router';
import { get } from 'lodash';
import { ProUser } from '../../graphql/graphql.schema';
import { safeMobxInject } from '../../stores/storeInjectionHelpers';
import { UserTypes } from '../../constants/UserTypes';

export const GET_PROFILE = gql`
  query getContext {
    buildVersion
    proUser {
      id
      firstName
      lastName
      email
      hasRightPairingToolkit
      role {
        name
      }
      company {
        id
        country
        tenants {
          id
          name
        }
      }
    }
  }
`;

interface IResponse {
  buildVersion: string;
  proUser: ProUser;
}

export interface IProps {
  children: any;
}

export interface IStoreProps {
  userType: UserTypes | null;
  setUserType: (data: UserTypes) => void;
  setData: (data: any) => void;
  setHasRightPairingToolkit: (hasRightPairingToolkit: boolean) => void;
  setBackVersion: (backVersion: string) => void;
}

export const shouldLogout = (error: ApolloError | undefined) => {
  return (
    error &&
    error.graphQLErrors.some(
      graphQLError => get(graphQLError, 'extensions.exception.status') === 403
    )
  );
};

const ContextQuery = safeMobxInject<IStoreProps, IProps>(
  stores => ({
    userType: stores.Store.profile.userType,
    setUserType: stores.Store.profile.setUserType,
    setData: stores.Store.profile.setData,
    setHasRightPairingToolkit: stores.Store.profile.setHasRightPairingToolkit,
    setBackVersion: stores.Store.mainMenu.setBackVersion
  }),
  ({
    children,
    userType,
    setUserType,
    setData,
    setHasRightPairingToolkit,
    setBackVersion
  }: IProps & IStoreProps) => {
    if (!userType) {
      return (
        <Query
          query={GET_PROFILE}
          fetchPolicy={'cache-and-network'}
          onCompleted={(data: IResponse) => {
            setBackVersion(data.buildVersion);
            setHasRightPairingToolkit(
              get(data, 'proUser.hasRightPairingToolkit', false)
            );
            setUserType(get(data, 'proUser.role.name', UserTypes.INSTALLER));
            setData({
              email: data.proUser.email || '',
              tenant: get(data, 'proUser.company.tenants', [{ name: 'FR' }])[0]
                .name,
              tenants:
                (data &&
                  data.proUser.company &&
                  data.proUser.company.tenants) ||
                [],
              country: get(data, 'proUser.company.country', 'FR')
            });
          }}
        >
          {({ loading, error }: QueryResult<IResponse>) => {
            if (shouldLogout(error)) {
              return <Redirect to="/login" />;
            }
            return (
              <Spinner
                loading={loading}
                error={error ? error.message : undefined}
              >
                <div style={{ width: '100%', height: '100vh' }} />
              </Spinner>
            );
          }}
        </Query>
      );
    }
    return children;
  }
);

export default ContextQuery;
