import React from 'react';
import { IDeviceIcon } from '../DeviceIcons';

const ElectricCar = (props: IDeviceIcon) => (
  <svg
    width="3rem"
    height="3rem"
    viewBox="0 0 20000 20000"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    color="black"
    {...props}
  >
    <g id="prefix__layer">
      <g id="prefix__electric_car">
        <path
          style={{ fill: `${props.color}` }}
          d="M6737 10531c-238 0-141-859-141-1050 4-156-50-298-150-401-102-104-247-162-407-162-326 0-562 234-562 558v2535c0 90-47 136-141 136-33 0-77-6-106-35-26-27-31-67-30-101v-919c0-263-240-558-562-558h-252c-92-325-390-562-743-562h-258v281h-509c-78 0-141 63-141 141s63 141 141 141h509v422h-509c-78 0-141 63-141 141s63 141 141 141h509v281h258c354 0 652-238 744-562h252c81 0 140 103 141 136v914c-4 156 50 298 150 401 102 104 247 162 407 162 326 0 562-234 562-558V9478c0-90 47-136 141-136 33 0 77 6 106 35 26 27 31 67 30 101v919c0 263 240 558 562 558v-422zM16026 9231c291-82 501-349 501-675 0-384-314-699-699-699h-466l-233 122c-433-1298-2367-1308-3526-1308-1143 0-3042-6-3469 1274l-224-87h-466c-384 0-699 314-699 699 0 314 210 582 501 675-303 256-501 640-501 1071v1397c0 198 128 361 349 501v1246c0 198 151 349 349 349h1048c198 0 349-151 349-349v-815c1001 116 2131 116 2794 116 664 0 1793 0 2794-116v815c0 198 151 349 349 349h1048c198 0 349-151 349-349v-1246c221-128 349-291 349-501v-1397c0-431-198-815-501-1071zm-198-1025c198 0 349 151 349 349s-151 349-349 349h-116l-233-699h349zm-6730-833c454-437 4495-524 5053 0 492 188 935 1041 1188 1531h-559c-2096-153-4191-156-6287 0h-559c348-665 438-1174 1164-1531zM7095 8555c0-198 151-349 349-349h349l-233 699h-116c-198 0-349-151-349-349zm1397 4890H7444v-1463c276 107 543 170 1049 251l-1 1213zm7335 0h-1048l-21-1211c396-58 766-136 1055-246l14 1457zm349-1746v23c-141 423-1986 593-2364 617-734 47-1455 58-2177 58s-1444-12-2166-58c-361-23-2251-194-2364-617v-1420c0-582 466-1048 1048-1048h349c2076-138 4173-133 6287 0h349c582 0 1048 466 1048 1048v1397h-12zM8142 9953c-384 0-699 314-699 699 0 384 314 699 699 699 384 0 699-314 699-699 0-384-314-699-699-699zm0 1048c-198 0-349-151-349-349s151-349 349-349 349 151 349 349-151 349-349 349zm6986-1048c-384 0-699 314-699 699 0 384 314 699 699 699 384 0 699-314 699-699 0-384-314-699-699-699zm0 1048c-198 0-349-151-349-349s151-349 349-349 349 151 349 349-151 349-349 349z"
        />
      </g>
    </g>
  </svg>
);

export default ElectricCar;
