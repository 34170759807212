import { Button } from 'reactstrap';
import React, { useState } from 'react';
import { TranslationFunction } from 'i18next';
import { inject, observer } from 'mobx-react';
import { Store } from '../../stores';
import { translate } from 'react-i18next';
import gql from 'graphql-tag';
import { RouteComponentProps, withRouter } from 'react-router';
import { Mutation, QueryResult } from '../../../node_modules/react-apollo';
import tools from '../../utils/tools';
import { IAllStores } from '../../stores/allStores.model';
import { GET_INSTALLATION } from '../devices_inscription/installation/EditInstallationQueryFormInscription';
import Query from 'react-apollo/Query';
import { PVInstallation } from '../../graphql/graphql.schema';
import RegistrationStatus from '../../constants/REGISTRATION_STATUS';
import CustomModal from '../../ui/CustomModal/CustomModal';

interface IResponse {
  pvInstallationById: PVInstallation;
}

interface IProps extends RouteComponentProps {
  t: TranslationFunction;
  Store?: Store;
  customerId: string;
  pvInstallationId: string;
  color?: string;
  textKey?: string;
  mobix?: Store;
  srsRegistrationStatus: string;
}

const CONTINUE_SUBSCRIPTION_MUTATION = gql`
  mutation continueSubscription($customerId: String) {
    continueSubscription(customerId: $customerId) {
      uri
    }
  }
`;

const ContinueSubscriptionButton = inject((allStores: IAllStores) => {
  return {
    mobix: allStores.Store
  };
})(
  observer(
    ({
      history,
      mobix,
      t,
      customerId,
      pvInstallationId,
      color = 'secondary',
      textKey = 'inscription',
      srsRegistrationStatus
    }: IProps) => {
      const { isAdmin } = mobix!.profile;
      const [isOpenConfirm, setIsOpenConfirm] = useState(false);

      let isV1Inscription = false;
      if (srsRegistrationStatus) {
        isV1Inscription =
          srsRegistrationStatus ===
            RegistrationStatus.SETUP_SELECTED_PRODUCTION_DEVICE ||
          srsRegistrationStatus ===
            RegistrationStatus.SETUP_INSTALLATION_DETAILS_SET ||
          srsRegistrationStatus ===
            RegistrationStatus.SETUP_INSTALLATION_DETAILS_SET2 ||
          srsRegistrationStatus ===
            RegistrationStatus.SETUP_DEVICES_REGISTERED ||
          srsRegistrationStatus === RegistrationStatus.SETUP_CONNECT_TADO ||
          srsRegistrationStatus === RegistrationStatus.SETUP_CHECK_WIRING;
      }

      return isAdmin ? (
        <div>-</div>
      ) : (
        <Mutation
          mutation={CONTINUE_SUBSCRIPTION_MUTATION}
          onCompleted={result => {
            tools.redirectToURI(result.continueSubscription.uri);
          }}
        >
          {continueSubscription => {
            let onClick;

            if (isV1Inscription) {
              onClick = (event: React.MouseEvent<HTMLElement>) => {
                event.stopPropagation();
                setIsOpenConfirm(!isOpenConfirm);
              };
            } else {
              onClick = (event: React.MouseEvent<HTMLElement>) => {
                event.stopPropagation();
                history.push(
                  `/devices_inscription/${customerId}/pv_installation/${pvInstallationId}`
                );
              };
            }

            return (
              <>
                <Button
                  className="continue-subscription"
                  color={color}
                  onClick={onClick}
                >
                  {t(textKey)}
                </Button>
                <CustomModal
                  title="Information"
                  isOpened={isOpenConfirm}
                  confirmLabel="ok"
                  size="lg"
                  handleConfirm={() => {
                    setIsOpenConfirm(!isOpenConfirm);
                    continueSubscription({ variables: { customerId } });
                  }}
                >
                  Ce client a été créé sur la version précédente de l'outil
                  d’inscription.
                  <br />
                  Vous allez être redirigé sur cet outil pour finaliser son
                  inscription.
                  <br />
                  <br />
                  L'inscription des clients crées sur cette précédente version
                  devra être terminée sur cette dernière.
                  <br />
                  L’inscription des clients créés sur la nouvelle version (<b>
                    Pro Portal
                  </b>) devra être terminée sur <b>Pro Portal</b>.
                  <br />
                  A partir du 30 novembre 2019, la création de client ne pourra
                  se faire que par <b>Pro Portal</b>.
                  <br />
                  <br />Vos identifiants restent les mêmes sur les deux outils.
                  <br />
                  <br />
                  Besoin de formation ? Contacter dès maintenant votre
                  commercial MyLight.
                </CustomModal>
              </>
            );
          }}
        </Mutation>
      );
    }
  )
);
export default translate('Customer')(withRouter(ContinueSubscriptionButton));
