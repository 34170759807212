import React from 'react';
import { Col, Row } from 'reactstrap';
import { translate, TranslationFunction } from 'react-i18next';

interface IProps {
  t: TranslationFunction;
}
const BadPLCSignalQualityWarning = ({ t }: IProps) => {
  return (
    <Col>
      <Row>{t('Master:warningMessagelowerSignalForAdapterCpl')}</Row>
      <Row>{t('Master:verifCom1ConnectToMultipleSocket')}</Row>
      <Row>{t('Master:verifCom1DistanceOtherCpl')}</Row>
      <Row>{t('Master:verifCom1WifiRepeater')}</Row>
      <Row>{t('Master:verifCom1CellularModem')}</Row>
      <Row>{t('Master:verifCom1QualityElectricityNetwork')}</Row>
      <Row>
        <br />
        {t('Master:warningIntermittentConnexion')}
      </Row>
    </Col>
  );
};

export default translate('Master')(BadPLCSignalQualityWarning);
