import React from 'react';
import { IDeviceIcon } from '../DeviceIcons';

const Iron = (props: IDeviceIcon) => (
  <svg
    width="3rem"
    height="3rem"
    viewBox="0 0 20000 20000"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    color="black"
    {...props}
  >
    <g id="prefix__layer">
      <g id="prefix___1405087129744">
        <path
          style={{ fill: `${props.color}` }}
          d="M5778 12831c-109 0-164-55-218-109-55-55-55-164 0-273 41-109 1160-2880 3371-2880h4272l-382-1883H7935c-164 0-273-109-273-273s109-273 273-273h5104c109 0 218 109 273 218l1051 5159c0 109 0 164-55 218-55 55-109 109-218 109H5779v-14zm7479-2716H8876c-1392 0-2334 1447-2661 2102h7493l-450-2102z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M10268 11125c0 218-177 382-396 382-218 0-382-177-382-382 0-218 177-396 382-396 218 0 396 177 396 396z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M10268 11125c0 218-177 382-396 382-218 0-382-177-382-382 0-218 177-396 382-396 218 0 396 177 396 396z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M10268 11125c0 218-177 382-396 382-218 0-382-177-382-382 0-218 177-396 382-396 218 0 396 177 396 396z"
        />
      </g>
    </g>
  </svg>
);

export default Iron;
