import React from 'react';
import { Button } from 'reactstrap';
import { RouteComponentProps, withRouter } from 'react-router';
import { translate, TranslationFunction } from 'react-i18next';
import { IInscriptionNavigationRouterParamProps } from '../InscriptionNavigation';
import { isMobileDevice } from '../../../utils/tools';

interface IProps
  extends RouteComponentProps<IInscriptionNavigationRouterParamProps> {
  t: TranslationFunction;
  codeType: 'MST' | 'UCG' | 'MSB' | 'PLC';
}

const ScanBarcodeButton = ({ t, history, codeType }: IProps) =>
  isMobileDevice() ? (
    <Button
      className={'scan-button'}
      color={'secondary'}
      onClick={() => {
        history.push(`/barcode/${codeType}`);
      }}
    >
      {t('BarcodeReader:scan')}
    </Button>
  ) : null;

export default withRouter(translate(['BarcodeReader'])(ScanBarcodeButton));
