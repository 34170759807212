import gql from 'graphql-tag';
import { inject, observer } from 'mobx-react';
import { Button } from 'reactstrap';
import { Mutation } from 'react-apollo';
import history from '../../../../history';
import { RouteComponentProps, withRouter } from 'react-router';
import { translate } from 'react-i18next';
import React from 'react';
import { TranslationFunction } from 'i18next';
import { IAllStores } from '../../../../stores/allStores.model';
import { TadoStore } from '../../../../stores/partnerships/tadoStore';
import { ISnackBarParams } from '../../../../stores/userFeedback/snackbarStore';

export const DISCONNECT_TADO = gql`
  mutation disconnectTado($customerId: String!) {
    tadoDisconnect(customerId: $customerId)
  }
`;

export interface IProps extends RouteComponentProps<IMatchParams> {
  tadoStore?: TadoStore;
  t: TranslationFunction;
  showSnackBar?: (params: ISnackBarParams) => void;
}

interface IMatchParams {
  customerId: string;
  pvInstallationId: string;
}

const TadoDisconnectButton = inject(({ tadoStore, snackBar }: IAllStores) => {
  return {
    tadoStore,
    showSnackBar: snackBar.showSnackBar
  };
})(
  observer(
    ({
      match: {
        params: { customerId, pvInstallationId }
      },
      t,
      tadoStore,
      showSnackBar
    }: IProps) => {
      const { tadoAccessGranted, homeId, tadoDevicesPresent } = tadoStore!;
      return (
        <Mutation
          mutation={DISCONNECT_TADO}
          onCompleted={async () => {
            await tadoStore!.resetTadoStore();
            showSnackBar!({
              type: 'success',
              text: t('TadoPartnership:tadoDisconnectingSuccess')
            });
            await history.push(
              `/devices_inscription/${customerId}/partnership/${pvInstallationId}`
            );
          }}
          onError={() =>
            showSnackBar!({
              type: 'error',
              text: t('TadoPartnership:tadoDisconnectingError')
            })
          }
        >
          {disconnectTado => {
            return (
              <Button
                color={
                  homeId || tadoDevicesPresent ? 'danger' : 'primary outline'
                }
                className={homeId || tadoDevicesPresent ? 'disconnect' : ''}
                onClick={() => {
                  disconnectTado({
                    variables: {
                      customerId
                    }
                  });
                }}
                disabled={!tadoAccessGranted}
              >
                {homeId || tadoDevicesPresent
                  ? t('TadoPartnership:disconnectTado')
                  : t('Partnerships:cancel')}
              </Button>
            );
          }}
        </Mutation>
      );
    }
  )
);

export default withRouter(
  translate(['TadoPartnership', 'Partnerships'])(TadoDisconnectButton)
);
