import React from 'react';
import './Modbus.scss';
const ModBusIcon = () => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="48"
    viewBox="0 0 141.7 145"
    className="modbus-icon"
  >
    <g id="FOND" />
    <g id="Calque_1">
      <circle className="st0" cx="70.9" cy="70.9" r="70.9" />
      <g>
        <defs>
          <circle id="SVGID_1_" cx="70.9" cy="70.9" r="70.9" />
        </defs>
        <clipPath id="SVGID_2_">
          <circle cx="70.9" cy="70.9" r="70.9" />
        </clipPath>
        <g className="st17">
          <polyline
            className="st18"
            points="201,52.5 181.3,52.5 177.2,40.2 123.5,40.2 111.4,76.3 101.9,76.3 89.8,40.2 81.4,40.2 69.4,76.3
				38.7,76.3 26.6,40.2 -5.7,40.2 -17.8,76.3 -27.9,76.3 -35.9,52.1 -55.6,52.1 			"
          />
          <polyline
            className="st19"
            points="201,64.5 181.3,64.5 177.2,76.8 123.5,76.8 111.4,40.7 101.9,40.7 89.8,76.8 81.4,76.8 69.4,40.7
				38.7,40.7 26.6,76.8 -5.7,76.8 -17.8,40.7 -27.9,40.7 -35.9,64.9 -55.6,64.9 			"
          />
        </g>
        <g className="st17">
          <path
            className="st5"
            d="M47.4,107.3c0.1,0.2,0.2,0.5,0.2,0.7c0,0.3-0.1,0.6-0.3,0.9c-0.3,0.3-0.7,0.5-1.2,0.5
				c-0.4,0-0.8-0.1-1.2-0.3c-1.3-0.7-1.9-2.2-1.9-4.5c0-0.6-0.2-1.2-0.6-1.5c-0.4-0.4-1-0.6-1.8-0.6h-5.1v5.2c0,0.5-0.1,0.9-0.4,1.2
				c-0.3,0.3-0.6,0.5-1,0.5c-0.5,0-1-0.2-1.4-0.5s-0.6-0.7-0.6-1.2V92.1c0-0.5,0.2-0.9,0.5-1.2c0.3-0.3,0.7-0.5,1.2-0.5h7.8
				c0.9,0,1.8,0.3,2.6,0.8c0.8,0.5,1.5,1.2,2,2.1c0.5,0.9,0.7,1.9,0.7,3c0,0.9-0.2,1.8-0.7,2.6c-0.5,0.9-1.1,1.5-1.9,2
				c1.1,0.8,1.8,1.9,1.9,3.2c0.1,0.3,0.1,0.6,0.1,0.8c0.1,0.6,0.1,1,0.2,1.2s0.2,0.4,0.5,0.6C47.1,106.9,47.3,107.1,47.4,107.3z
				 M42.3,99.3c0.3-0.3,0.6-0.7,0.8-1.2c0.2-0.5,0.3-1.1,0.3-1.7c0-0.5-0.1-1-0.3-1.4c-0.2-0.4-0.5-0.8-0.8-1
				c-0.3-0.3-0.6-0.4-0.9-0.4h-5.9v6.1h5.9C41.7,99.7,42,99.6,42.3,99.3z"
          />
          <path
            className="st5"
            d="M53.2,109.2c-1-0.3-2-0.9-2.8-1.6c-0.5-0.4-0.7-0.9-0.7-1.4c0-0.4,0.2-0.8,0.5-1.1c0.3-0.3,0.7-0.5,1.1-0.5
				c0.3,0,0.6,0.1,0.9,0.3c0.7,0.6,1.4,1,2.1,1.3c0.7,0.3,1.5,0.4,2.4,0.4c1,0,1.9-0.2,2.6-0.7s1.1-1,1.1-1.7c0-0.8-0.4-1.4-1.1-1.9
				s-1.9-0.8-3.4-1c-3.9-0.6-5.9-2.4-5.9-5.5c0-1.1,0.3-2.1,0.9-3s1.4-1.5,2.4-1.9c1-0.4,2.2-0.7,3.4-0.7c1.1,0,2.2,0.2,3.2,0.5
				c1,0.3,1.8,0.8,2.5,1.4c0.5,0.4,0.8,0.9,0.8,1.4c0,0.4-0.2,0.8-0.5,1.1c-0.3,0.3-0.7,0.5-1.1,0.5c-0.3,0-0.5-0.1-0.7-0.2
				c-0.5-0.4-1.1-0.7-2-1s-1.6-0.5-2.3-0.5c-1.1,0-2,0.2-2.6,0.6c-0.6,0.4-0.9,1-0.9,1.7c0,0.8,0.3,1.4,1,1.8c0.6,0.4,1.6,0.7,3,1
				c1.5,0.3,2.8,0.6,3.7,1c0.9,0.4,1.6,1,2.1,1.7s0.7,1.7,0.7,2.9c0,1.1-0.3,2.1-1,3c-0.6,0.9-1.5,1.5-2.6,2s-2.2,0.7-3.4,0.7
				C55.4,109.7,54.3,109.5,53.2,109.2z"
          />
          <path
            className="st5"
            d="M81.3,101.7c0.3,0.3,0.4,0.6,0.4,1.1c0,0.4-0.1,0.8-0.4,1s-0.6,0.4-1.1,0.4h-1.1v3.5c0,0.5-0.2,0.9-0.5,1.2
				s-0.7,0.5-1.2,0.5c-0.5,0-0.8-0.2-1.1-0.5s-0.5-0.7-0.5-1.2v-3.5h-7c-0.4,0-0.8-0.2-1.1-0.5c-0.3-0.3-0.5-0.7-0.5-1.1
				c0-0.4,0.1-0.8,0.4-1.1l8.5-10.5c0.3-0.4,0.8-0.6,1.4-0.6c0.5,0,0.8,0.2,1.1,0.5c0.3,0.3,0.5,0.7,0.5,1.2v9.2h1.1
				C80.6,101.3,81,101.4,81.3,101.7z M71.4,101.3h4.4v-5.4L71.4,101.3z"
          />
          <path
            className="st5"
            d="M97.4,101.4c0.5,0.7,0.7,1.5,0.7,2.3c0,1-0.3,2-0.9,2.8c-0.6,0.9-1.3,1.6-2.3,2.1c-1,0.5-2,0.8-3.2,0.8
				s-2.2-0.3-3.2-0.8c-1-0.5-1.7-1.2-2.3-2.1c-0.6-0.9-0.9-1.8-0.9-2.8c0-1.7,0.9-3.1,2.6-4.2c-0.6-0.4-1.1-0.8-1.6-1.5
				c-0.4-0.6-0.6-1.3-0.6-2.2c0-1,0.3-1.9,0.8-2.7s1.2-1.5,2.1-2c0.9-0.5,1.9-0.7,3-0.7s2.1,0.2,3,0.7c0.9,0.5,1.6,1.1,2.1,2
				s0.8,1.7,0.8,2.7c0,0.9-0.2,1.6-0.6,2.2c-0.4,0.6-1,1-1.6,1.4C96.3,100,96.9,100.7,97.4,101.4z M94,105.6
				c0.6-0.5,0.9-1.2,0.9-1.9c0-0.8-0.3-1.4-0.9-2s-1.3-0.8-2.2-0.8s-1.6,0.3-2.2,0.8s-0.9,1.2-0.9,2c0,0.8,0.3,1.4,0.9,1.9
				s1.3,0.8,2.2,0.8S93.4,106.2,94,105.6z M89.9,94.2c-0.5,0.5-0.7,1.1-0.7,1.8c0,0.7,0.2,1.3,0.7,1.8c0.5,0.5,1.1,0.7,1.9,0.7
				c0.7,0,1.4-0.2,1.9-0.7c0.5-0.5,0.7-1.1,0.7-1.8s-0.2-1.3-0.7-1.8c-0.5-0.5-1.1-0.7-1.9-0.7C91.1,93.4,90.4,93.7,89.9,94.2z"
          />
          <path
            className="st5"
            d="M110.4,97.5c1,0.5,1.7,1.3,2.3,2.3s0.8,2,0.8,3.2c0,1.2-0.3,2.3-1,3.4c-0.7,1-1.6,1.8-2.7,2.4
				c-1.1,0.6-2.4,0.9-3.7,0.9c-0.7,0-1.4-0.1-2.2-0.3c-0.7-0.2-1.4-0.5-1.8-0.9c-0.2-0.2-0.4-0.4-0.5-0.8c-0.1-0.3-0.2-0.6-0.2-0.9
				c0-0.3,0.1-0.6,0.4-0.9c0.3-0.3,0.6-0.4,1.1-0.4c0.3,0,0.7,0.2,1.2,0.5c0.8,0.5,1.4,0.7,2,0.7c0.7,0,1.4-0.2,2.1-0.5
				c0.6-0.3,1.2-0.8,1.6-1.4c0.4-0.6,0.6-1.2,0.6-1.9c0-1-0.3-1.8-1-2.4c-0.7-0.6-1.5-0.9-2.4-0.9c-0.4,0-0.8,0.1-1.1,0.2
				c-0.3,0.1-0.7,0.3-1.2,0.5c-0.3,0.2-0.6,0.3-0.8,0.4c-0.2,0.1-0.4,0.1-0.6,0.1c-0.7,0-1.2-0.2-1.5-0.6c-0.3-0.4-0.4-0.8-0.4-1.4
				c0-0.2,0-0.3,0-0.4l0.8-6.7c0.1-0.4,0.3-0.7,0.6-0.9c0.3-0.2,0.7-0.4,1.1-0.4h7.8c0.4,0,0.8,0.1,1.1,0.4c0.3,0.3,0.4,0.6,0.4,1.1
				c0,0.4-0.1,0.8-0.4,1c-0.3,0.3-0.6,0.4-1.1,0.4h-6.7l-0.5,3.9c0.3-0.2,0.8-0.4,1.3-0.5c0.5-0.1,1-0.2,1.5-0.2
				C108.4,96.7,109.5,97,110.4,97.5z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default ModBusIcon;
