import React from 'react';
import { safeMobxInject } from '../../../stores/storeInjectionHelpers';
import { translate, TranslationFunction } from 'react-i18next';
import {
  Alert,
  Button,
  Col,
  Container,
  ModalBody,
  ModalFooter,
  Row
} from 'reactstrap';
import Spinner from '../../../ui/Spinner/Spinner';
import List from '../../../ui/DataDisplay/List/ListWrapper';
import './ucgPreconfiguration.scss';
import { IModbusDevices } from './UcgPreconfigurationQuery';
import LoadingButton from '../../../ui/Button/LoadingButton/LoadingButton';

// tslint:disable-next-line:no-empty-interface
interface IStoresProps {}

interface IProps {
  t: TranslationFunction;
  errors: string[];
  addedModbusDevices: IModbusDevices[];
  applyLoading: boolean;
  refreshLoading: boolean;
  closeUcgPreconfiguration: () => void;
}

const UcgPreconfigurationView = safeMobxInject<IStoresProps, IProps>(
  stores => ({}),
  ({
    t,
    errors,
    addedModbusDevices,
    applyLoading,
    refreshLoading,
    closeUcgPreconfiguration
  }: IProps & IStoresProps) => {
    const isAddedModbusDevices = addedModbusDevices.length > 0;

    return (
      <>
        <ModalBody
          className={'ucg-preconfiguration-summary'}
          style={{
            paddingTop: '0',
            paddingBottom: '0',
            overflowY: 'auto',
            height: 'auto'
          }}
        >
          <Spinner loading={applyLoading} transparent>
            <Row>
              <List fluid={true}>
                {isAddedModbusDevices && (
                  <Alert color="success">
                    <Col>
                      <Row>{t('Devices:devicesRS485Added')}</Row>
                      <Col>
                        {addedModbusDevices.map(modbusDevice => {
                          return (
                            <Row className={'ml-2'}>
                              {`• ${t('Devices:reference')}: ${
                                modbusDevice.reference
                              }
                               /  ${t('Devices:address')}: ${
                                modbusDevice.address
                              }`}
                            </Row>
                          );
                        })}
                      </Col>
                    </Col>
                  </Alert>
                )}
                {
                  <>
                    {errors.map(error => {
                      return (
                        <Alert color="warning">{t(`Devices:${error}`)}</Alert>
                      );
                    })}
                  </>
                }
                <Alert color="success">
                  {t('Devices:successAffectDevicesFunction')}
                </Alert>
              </List>
            </Row>
          </Spinner>
        </ModalBody>
        <ModalFooter>
          <Container>
            {!applyLoading && (
              <Row>
                <Col className="text-right">
                  <LoadingButton
                    colorClass="primary"
                    loading={refreshLoading}
                    onClick={(event: React.MouseEvent<HTMLElement>) => {
                      event.stopPropagation();
                      closeUcgPreconfiguration();
                    }}
                  >
                    {t('Common:close')}
                  </LoadingButton>
                </Col>
              </Row>
            )}
          </Container>
        </ModalFooter>
      </>
    );
  }
);

export default translate()(UcgPreconfigurationView);
