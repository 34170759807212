import React, { useContext } from 'react';
import { InscriptionQueryContext } from '../DevicesInscription';
import RefreshIcon from '../../../ui/Icons/RefreshIcon';
import { translate, TranslationFunction } from 'react-i18next';

interface IProps {
  t: TranslationFunction;
  label?: string;
}

const RefreshDeviceConfig = ({
  t,
  label = t('Devices:refreshList')
}: IProps) => {
  const context = useContext(InscriptionQueryContext);
  return (
    <div
      className={'refresh-button-container'}
      id={'refresh-button'}
      onClick={() => context.refetchInscriptionQuery({ hideLoading: true })}
    >
      <RefreshIcon loading={context.inscriptionQueryLoading} />
      <span>{label}</span>
    </div>
  );
};

export default translate('Devices')(RefreshDeviceConfig);
