import * as React from 'react';
import { Button, Tooltip } from 'reactstrap';
import './HelpButton.scss';
import { renderToString } from 'react-dom/server';

interface IProps {
  message: any;
  uniqueKey?: string;
}

interface IState {
  tooltipOpen: boolean;
}

export class HelpButton extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      tooltipOpen: false
    };
  }

  public render() {
    let { message, uniqueKey } = this.props;
    
    if (message && typeof message !== 'string') {
      message = renderToString(message);
    }

    return (
      <Button
        id={`${uniqueKey}`}
        className="btn-tooltip"
        onMouseEnter={(event: any) => {
          event.stopPropagation();
          this.setState({ tooltipOpen: true });
        }}
        onClick={(event: any) => {
          event.stopPropagation();
          this.setState({ tooltipOpen: true });
        }}
      >
        ?
        <Tooltip
          autohide={false}
          placement="top"
          isOpen={this.state.tooltipOpen}
          target={`${uniqueKey}`}
          toggle={() => this.setState({ tooltipOpen: false })}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      </Button>
    );
  }
}
