import React, { useEffect } from 'react';
import { translate, TranslationFunction } from 'react-i18next';

import '../battery.scss';
import { RouteComponentProps, withRouter } from 'react-router';
import IsolatedComponent from '../../../../../ui/IsolatedComponent/IsolatedComponent';
import MsbCreation from './MsbCreation';
import { MySmartBattery } from '../../../../../stores/my_smart_battery/mySmartBattery';
import { CreationMySmartBattery } from '../../../../../stores/my_smart_battery/creationMySmartBattery';
import { safeMobxInject } from '../../../../../stores/storeInjectionHelpers';
import { withApollo, WithApolloClient } from 'react-apollo';
import { SnackbarStore } from '../../../../../stores/userFeedback/snackbarStore';
import { getPartnerPath } from '../../../../devices_inscription/routingHelper';
import { InscriptionStepsStore } from '../../../../../stores/inscription/inscriptionStepsStore';

interface IProps extends RouteComponentProps<IMatchParams> {
  t: TranslationFunction;
}

interface IStoresProps {
  mySmartBatteryStore: MySmartBattery;
  creationMySmartBatteryStore: CreationMySmartBattery;
  snackbar: SnackbarStore;
  inscriptionSteps: InscriptionStepsStore;
}

interface IMatchParams {
  customerId: string;
  pvInstallationId?: string;
}

export enum PAGE_COME_FROM {
  DEVICE_INSCRIPTION = 'DEVICE_INSCRIPTION',
  MY_SMART_BATTERY_ACTIVATION = 'MY_SMART_BATTERY_ACTIVATION'
}

const MsbCreationWindow = safeMobxInject<IStoresProps, IProps>(
  stores => ({
    mySmartBatteryStore: stores.mySmartBattery,
    creationMySmartBatteryStore: stores.creationMySmartBattery,
    snackbar: stores.snackBar,
    inscriptionSteps: stores.inscriptionSteps
  }),
  ({
    t,
    client,
    mySmartBatteryStore,
    creationMySmartBatteryStore,
    snackbar,
    inscriptionSteps,
    history,
    match: {
      params: { customerId }
    }
  }: WithApolloClient<IProps & IStoresProps>) => {
    useEffect(
      () => {
        creationMySmartBatteryStore.clearCreationMsbMylight();
        mySmartBatteryStore.loadMsb(client, customerId).then(msb => {
          if (msb) {
            history.goBack();
          }
        });
      },
      [customerId]
    );

    const onCancel = () => {
      history.goBack();
    };

    const onAdd = async () => {
      try {
        await creationMySmartBatteryStore.createMsbMylight(client, customerId);
        if (
          history.location.state.from ===
          PAGE_COME_FROM.MY_SMART_BATTERY_ACTIVATION
        ) {
          history.push(`/customers/${customerId}/installation`);
          return;
        }

        if (history.location.state.from === PAGE_COME_FROM.DEVICE_INSCRIPTION) {
          if (history.location.state.pvInstallationId) {
            inscriptionSteps.setBatteryStepStatus('done');
            await inscriptionSteps.updateMSBRegistrationStatus(
              client,
              history.location.state.pvInstallationId,
              'done'
            );
            history.push(
              getPartnerPath({
                customerId,
                pvInstallationId: history.location.state.pvInstallationId
              })
            );
          }
        }
      } catch (error) {
        snackbar.showErrorSnackBar(t('MySmartBattery:errors.createMsbFailed'));
      }
    };
    return (
      <IsolatedComponent title={t('battery')} sm={9} md={8} lg={7} xl={6}>
        <MsbCreation isolated onCancel={onCancel} onAdd={onAdd} />
      </IsolatedComponent>
    );
  }
);

export default withRouter(
  translate(['MySmartBattery', 'Common'])(withApollo(MsbCreationWindow))
);
