import * as React from 'react';
import { translate, TranslationFunction } from 'react-i18next';
import {
  Button,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from 'reactstrap';
import { RouteComponentProps, withRouter } from 'react-router';

interface IModalProps extends RouteComponentProps {
  t: TranslationFunction;
  showModal: boolean;
  customerId?: string;
  pvInstallationId?: string;
  toggleModal: () => void;
}

const AfterCreateCustomerModal = ({
  t,
  showModal,
  customerId,
  pvInstallationId,
  toggleModal,
  history
}: IModalProps) => (
  <Modal isOpen={showModal} centered>
    <ModalHeader>{t('afterCreateModalTitle')}</ModalHeader>
    <ModalBody>
      <Container>
        <Row>
          <Col className="text-center">
            <Button
              color="primary"
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                event.stopPropagation();
                toggleModal();

                history.push(
                  `devices_inscription/${customerId}/pv_installation/${pvInstallationId}`
                );
              }}
            >
              {t('goToFinishInstallation')}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <Button
              style={{ marginTop: '25px' }}
              color="primary"
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                event.stopPropagation();
                toggleModal();
                history.push(`customers/${customerId}/identity`);
              }}
            >
              {t('goToIdentity')}
            </Button>
          </Col>
        </Row>
      </Container>
    </ModalBody>
  </Modal>
);

export default translate('Common')(withRouter(AfterCreateCustomerModal));
