import React, { useState } from 'react';
import { translate, TranslationFunction } from 'react-i18next';

import './MsbInput.scss';
import {
  ActivationMySmartBatteryEnedisGridInjection
} from '../../../../../stores/my_smart_battery/activationMySmartBatteryEnedisGridInjection';
import { safeMobxInject } from '../../../../../stores/storeInjectionHelpers';
import BatteryDocumentUploadModal from '../documents/BatteryDocumentUploadModal';
import {
  ActivationMySmartBatteryMylight
} from '../../../../../stores/my_smart_battery/activationMySmartBatteryMylight';
import { IconUpload } from '../../../../../ui/Icons/IconUpload';
import { DocumentType } from '../../../../../graphql/graphql.schema';
import { MsbInlineForm } from './MsbInlineForm';
import RowFile, { TypeFile } from '../info/RowFile';

interface IProps {
  t: TranslationFunction;
}

interface IStoresProps {
  activationMsbMylight: ActivationMySmartBatteryMylight;
  activationMsbEnedisGridInjection: ActivationMySmartBatteryEnedisGridInjection;
}

const MsbActivationUploadFileConsuel = safeMobxInject<IStoresProps, IProps>(
  stores => ({
    activationMsbMylight: stores.activationMySmartBatteryMylight,
    activationMsbEnedisGridInjection:
      stores.activationMySmartBatteryEnedisGridInjection
  }),
  ({
    t,
    activationMsbMylight,
    activationMsbEnedisGridInjection
  }: IProps & IStoresProps) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const getConsuelFileName = (): string => {
      return `consuel_${activationMsbMylight.pdlNumberSaved}.pdf`;
    };

    return (
      <>
        {!activationMsbEnedisGridInjection.sendingCONSUELDate ? (
          <MsbInlineForm label={t('consuelUpload')}>
            <button
              onClick={() => setIsModalOpen(true)}
              className="msb-activation-form-group__upload-button"
            >
              {t('uploadFile')} <IconUpload />
            </button>
            <div className="msb-activation-form-group__helper-text">
              <span className="msb-activation-form-group__helper-text--info">
                {t('importFilePdfFormat')}
              </span>
            </div>
          </MsbInlineForm>
        ) : (
          <RowFile typeFile={TypeFile.CONSUEL} fileName={getConsuelFileName()} />
        )}
        <BatteryDocumentUploadModal
          documentType={DocumentType.CONSUEL}
          title={t('consuelUpload')}
          target={'upload_consuel'}
          isModalOpen={isModalOpen}
          toggle={() => setIsModalOpen(!isModalOpen)}
          onComplete={sendingCONSUELDate =>
            (activationMsbEnedisGridInjection.sendingCONSUELDate = sendingCONSUELDate)
          }
        />
      </>
    );
  }
);

export default translate(['MySmartBattery'])(MsbActivationUploadFileConsuel);
