import React from 'react';
import { IDeviceIcon } from '../DeviceIcons';

const Toaster = (props: IDeviceIcon) => (
  <svg
    width="3rem"
    height="3rem"
    viewBox="0 0 20000 20000"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    color="black"
    {...props}
  >
    <g id="prefix__Layer_x0020_1">
      <g id="prefix__toaster">
        <path
          style={{ fill: `${props.color}` }}
          d="M9842 4533c0 80 80 160 80 160v160l-80 80h80c160 0 240-80 240-240s0-240-80-320v-80c0-80 0-80-80-80 0 0 0-80 80-80l80-80h-160c-80 0-80 0-160 80s-80 80-80 160 0 160 80 240zM11242 4533c0 80 80 160 80 160v160l-80 80h80c160 0 240-80 240-240s0-240-80-320v-80c0-80 0-80-80-80 0 0 0-80 80-80l80-80h-160c-80 0-80 0-160 80s-80 80-80 160c-20 80-20 160 80 240zM8222 4533c0 80 80 160 80 160v160l-80 80h80c160 0 240-80 240-240s0-240-80-320v-80c0-80 0-80-80-80 0 0 0-80 80-80l80-80h-160c-80 0-80 0-160 80s-80 80-80 160 80 160 80 240zM14882 14133H5042c-160 0-320-160-320-320V9653c0-1540 1620-1960 2440-1960h5940c820 0 1380 240 1700 640 480 480 400 1220 400 1300v4160c20 180-140 340-320 340zm-9500-640h9200V9653s80-480-240-820c-240-240-660-400-1220-400H7162c-80 0-1800 0-1800 1220v3840h20z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M4882 10553H3502c-160 0-320-160-320-320s160-320 320-320h1380c160 0 320 160 320 320s-160 320-320 320zM16442 10553h-1380c-160 0-320-160-320-320s160-320 320-320h1380c160 0 320 160 320 320s-160 320-320 320zM7902 8433c-160 0-320-160-320-320v-900c-400-320-900-820-740-1300s820-480 1060-480l2044 2c160 0 316 158 316 318s-160 320-320 320H7562c80 160 320 400 660 560 80 80 160 160 160 320v1060c-80 260-240 420-480 420z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M11962 8433c-160 0-320-160-320-320V7053c0-80 80-240 160-320 240-160 467-568 647-668l-2508 7c-160 0-159-78-159-238s3-397 163-397l2395-2c240 0 702 79 862 559s-320 980-740 1300v900c-180 80-340 240-500 240z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M11322 6313c0 40-40 80-80 80s-80-40-80-80 40-80 80-80 80 40 80 80zM11322 6733c0 40-40 80-80 80s-80-40-80-80 40-80 80-80 80 40 80 80zM10902 6313c0 40-40 80-80 80s-80-40-80-80 40-80 80-80 80 40 80 80zM6842 15513c-320 0-480-240-480-480v-980c0-320 240-480 480-480 320 0 480 240 480 480v980c0 240-240 480-480 480zM13102 15513c-320 0-480-240-480-480v-980c0-320 240-480 480-480 320 0 480 240 480 480v980c20 240-240 480-480 480z"
        />
      </g>
    </g>
  </svg>
);

export default Toaster;
