import React, { ReactNode } from 'react';
import { Tooltip } from 'reactstrap';

import './customTooltip.scss';
import { translate } from 'react-i18next';
import { TranslationFunction } from 'i18next';
import classnames from 'classnames';

interface IMsg {
  text?: ReactNode;
  weight?: number;
}

interface IProps {
  t: TranslationFunction;
  errorMsg: IMsg;
  selector: string;
  complementMsg?: ReactNode;
  type?: 'warning' | 'info';
}

interface IState {
  tooltipOpen: boolean;
  tooltipExtended: boolean;
}

class CustomTooltip extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.toggleComplementContent = this.toggleComplementContent.bind(this);
    this.state = {
      tooltipOpen: false,
      tooltipExtended: false
    };
  }

  public toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
      tooltipExtended: false
    });
  }

  public toggleComplementContent() {
    this.setState({
      tooltipExtended: !this.state.tooltipExtended
    });
  }

  public render() {
    const { errorMsg, selector, t, type = 'info' } = this.props;
    const isWarning = type === 'warning';
    return (
      <Tooltip
        autohide={false}
        isOpen={this.state.tooltipOpen}
        target={selector}
        toggle={this.toggle}
        delay={{ show: 100, hide: 400 }}
        className={classnames('custom-tooltip-container', {
          'is-warning': isWarning
        })}
      >
        <span className={'custom-tooltip-container-body'}>
          <span
            style={{
              fontWeight:
                errorMsg.weight && this.state.tooltipExtended
                  ? errorMsg.weight
                  : 400
            }}
          >
            {errorMsg.text}
          </span>

          {this.props.complementMsg &&
            !this.state.tooltipExtended && (
              <span
                onClick={this.toggleComplementContent}
                className={'custom-tooltip-complement-switch'}
              >
                {t('plus')}
              </span>
            )}

          {this.props.complementMsg &&
            this.state.tooltipExtended && (
              <span className={'custom-tooltip-complement-msg'}>
                {this.props.complementMsg}
              </span>
            )}

          {this.props.complementMsg &&
            this.state.tooltipExtended && (
              <span
                onClick={this.toggleComplementContent}
                className={'custom-tooltip-complement-switch'}
              >
                {t('minus')}
              </span>
            )}
        </span>
      </Tooltip>
    );
  }
}

export default translate(['Common'])(CustomTooltip);
