import React from 'react';
import { IDeviceIcon } from '../DeviceIcons';

const Radio = (props: IDeviceIcon) => (
  <svg
    width="3rem"
    height="3rem"
    viewBox="0 0 20000 20000"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    color="black"
    {...props}
  >
    <g id="prefix__Layer_x0020_1">
      <g id="prefix___1440945675776">
        <path
          style={{ fill: `${props.color}` }}
          d="M15271 8661v-800h-1580v780h-500v-780h-1580v780H5511l-800-4780v-20l-520 90 810 4720h-240c-790 0-1400 610-1400 1400v4480c0 770 630 1400 1400 1400h10250c390 0 750-150 1020-420 260-260 390-610 380-980v-4490c0-700-480-1260-1140-1380zm-1160-380h660v360h-660v-360zM3851 10041c0-530 370-900 900-900h10260c530 0 900 370 900 900v4480c0 530-370 900-900 900H4751c-530 0-900-370-900-900v-4480zm8180-1760h660v360h-660v-360z"
        />
        <path
          style={{ fill: `${props.color}` }}
          d="M6451 14621h1620c390 0 750-150 1020-420 260-260 390-610 380-980v-1780c0-790-610-1400-1400-1400H6451c-780 0-1420 630-1420 1400v1780c0 770 640 1400 1420 1400zm2520-1340c0 530-370 900-900 900H6451c-530 0-900-370-900-900v-1780c0-530 370-900 900-900h1620c530 0 900 370 900 900v1780zM11711 14621h1620c690 0 1320-630 1400-1400v-1780c0-790-610-1400-1400-1400h-1620c-770 0-1400 630-1400 1400v1780c0 790 610 1400 1400 1400zm0-4020h1620c530 0 900 370 900 900v1780c0 530-370 900-900 900h-1620c-530 0-900-370-900-900v-1780c0-530 370-900 900-900z"
        />
      </g>
    </g>
  </svg>
);

export default Radio;
