import { EPVInstallation, PVInstallation } from '../../graphql/graphql.schema';
import { observable, action, computed } from 'mobx';
import { t } from 'i18next';

export class EditPVInstallationStore implements Partial<EPVInstallation> {
  @observable public currency?: string = undefined;
  @observable public id: string = '';
  @observable public ucgSerialNumber?: string = undefined;
  @observable public panelCount?: number = undefined;
  @observable public panelManufacturer?: string = undefined;
  @observable public panelWattPeak?: number = undefined;
  @observable public srsLicenceStatus?: string = undefined;
  @observable public srsRegistrationStatus?: string = undefined;
  @observable public edited: boolean = false;
  @observable public hasMultipleProductionCounter?: boolean = undefined;

  @action.bound
  public setEdited(edited: boolean) {
    this.edited = edited;
  }

  @action.bound
  public setPanelCount(panelCount: number) {
    this.edited = true;
    this.panelCount = panelCount;
  }

  @action.bound
  public setPanelManufacturer(panelManufacturer: string) {
    this.edited = true;
    this.panelManufacturer = panelManufacturer;
  }

  @action.bound
  public setPanelWattPeak(panelWattPeak: number) {
    this.edited = true;
    this.panelWattPeak = panelWattPeak;
  }

  @action.bound
  public setPvInstallation(e: PVInstallation) {
    this.edited = false;
    this.currency = e.currency;
    this.id = e.id;
    this.ucgSerialNumber = e.ucgSerialNumber;
    this.panelCount = e.panelCount === 0 ? undefined : e.panelCount;
    this.panelManufacturer = e.panelManufacturer;
    this.panelWattPeak = e.panelWattPeak === 0 ? undefined : e.panelWattPeak;
    this.srsLicenceStatus = e.srsLicenceStatus;
    this.srsRegistrationStatus = e.srsRegistrationStatus;
    this.hasMultipleProductionCounter = e.hasMultipleProductionCounter;
  }

  @computed
  get editedPVInstallation(): EPVInstallation {
    return {
      id: this.id,
      currency: this.currency,
      panelCount: this.panelCount,
      panelWattPeak: this.panelWattPeak,
      panelManufacturer: this.panelManufacturer,
      srsLicenceStatus: this.srsLicenceStatus,
      srsRegistrationStatus: this.srsRegistrationStatus,
      ucgSerialNumber: this.ucgSerialNumber
    };
  }

  @computed
  public get errorPanelManufacturer(): boolean {
    return !this.panelManufacturer;
  }

  @computed
  public get errorPanelCount(): boolean {
    return this.panelCount === 0;
  }

  @computed
  public get errorPanelWattPeak(): boolean {
    return this.panelWattPeak === 0;
  }

  @computed
  get getMissingFieldsPVInstallation(): string[] | undefined {
    const panelManufacturerIsMissing =
      this.errorPanelManufacturer && t('Customer:panelManufacturer');
    const missingFields = [panelManufacturerIsMissing];
    if (missingFields.some(field => !!field)) {
      return missingFields;
    }
    return undefined;
  }

  @computed
  get customerEditFormIsInvalid(): boolean {
    return (
      !this.id ||
      !!this.errorPanelManufacturer ||
      this.errorPanelCount ||
      this.errorPanelWattPeak
    );
  }
  @computed
  get customerInscriptionFormIsInvalid(): boolean {
    return (
      !this.id ||
      !!this.errorPanelManufacturer ||
      !this.panelCount ||
      !this.panelWattPeak ||
      this.errorPanelCount ||
      this.errorPanelWattPeak
    );
  }

  @computed
  get isDone(): boolean {
    return !!(this.panelWattPeak && this.panelCount);
  }
}

const editPVInstallationStore: EditPVInstallationStore = new EditPVInstallationStore();
export default editPVInstallationStore;
