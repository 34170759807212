import React, { ReactNode, useState } from 'react';
import classnames from 'classnames';
import './msbSmoothList.scss';
import {
  Button,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Popover,
  PopoverBody,
  Row
} from 'reactstrap';
import { AutoSizer, List } from 'react-virtualized';

import SortNotificator from './SortNotificator/SortNotificator';
import EmptyCollectionPlaceHolder from '../DataDisplay/List/ListPlaceHolder';
import { safeMobxInject } from '../../stores/storeInjectionHelpers';
import { translate, TranslationFunction } from 'react-i18next';
import { IconFunnel } from '../Icons/IconFunnel';
import { possibleMSBFilterOptions } from '../../stores/lists/mySmartBatteryListStore';
import {
  MSBListFilter,
  MSBListFilterableColumn,
  MSBListFilterRule,
  MSBListSortableColumn,
  MSBListSorting,
  MSBListSortingRule
} from '../../graphql/graphql.schema';

export interface IColumn {
  title: string;
  dataKey?: string;
  render?: (item: any, index?: number) => ReactNode | string;
  columnKey: string;
  align?: string;
  bootstrapOption?: any;
  sortingKey?: MSBListSortableColumn;
  isHidden?: boolean;
  isFilterable?: boolean;
  isSortable?: boolean;
}

interface IProps {
  t: TranslationFunction;
  columns: IColumn[];
  dataSource: any[];
  rowHeight: number;
  placeholder: string;
  onRowClick?: (item: any, index?: number) => void;
  canFetchMore: boolean;
  fetchMore: () => void;
}

interface IStoresProps {
  filterRule: MSBListFilter[];
  toggleFilterRule: (
    column: MSBListFilterableColumn,
    rule: MSBListFilterRule
  ) => void;
  sortingRule: MSBListSorting;
  setSortingRule: (column: MSBListSortableColumn) => void;
}

const titleRowSize = 68 + 38;

const MSBSmoothList = safeMobxInject<IStoresProps, IProps>(
  ({ mySmartBatteryList }) => ({
    filterRule: mySmartBatteryList.filterRule,
    toggleFilterRule: mySmartBatteryList.toggleFilterRule,
    sortingRule: mySmartBatteryList.sortingRule,
    setSortingRule: mySmartBatteryList.setSortingRule
  }),
  ({
    t,
    columns,
    dataSource,
    onRowClick,
    rowHeight,
    placeholder,
    sortingRule,
    setSortingRule,
    filterRule,
    toggleFilterRule,
    canFetchMore,
    fetchMore
  }: IProps & IStoresProps) => {
    const [isOpened, toggleOpened] = useState<
      MSBListFilterableColumn | undefined
    >(undefined);
    const isFilterApplied = (
      columnName: MSBListFilterableColumn,
      rule?: MSBListFilterRule
    ): boolean => {
      const appliedFilter = filterRule.find(
        ({ column }) => column === columnName
      );
      if (rule) {
        return appliedFilter ? appliedFilter.rule === rule : false;
      }
      return Boolean(appliedFilter);
    };
    const isColumnWithFilters = (column: string): boolean => {
      return column in MSBListFilterableColumn;
    };
    const checkIfSortingShouldBeHidden = (column: IColumn): boolean => {
      if (column.isSortable === false) {
        return true;
      }
      return column.columnKey === MSBListFilterableColumn.msbType
        ? isFilterApplied(column.columnKey)
        : false;
    };
    const checkIfFilteringShouldBeHidden = (column: IColumn): boolean => {
      return column.isFilterable === false;
    };
    const visibleColumns = columns.filter(column => !column.isHidden);
    return (
      <div className={'smooth-list msb-list'}>
        <Container fluid style={{ height: '100%', paddingRight: 0 }}>
          <Col className="titles-row">
            {visibleColumns.map(column => (
              <Col
                {...column.bootstrapOption}
                key={column.columnKey}
                style={{ justifyContent: 'center' }}
              >
                <div className={'titles-row-title-container'}>
                  <span className={'titles-row-title'}>{column.title}</span>
                </div>
              </Col>
            ))}
          </Col>
          <Col className="titles-row">
            {visibleColumns.map(column => {
              const isSortingShouldBeHidden = checkIfSortingShouldBeHidden(
                column
              );
              const isFilteringShouldBeHidden = checkIfFilteringShouldBeHidden(
                column
              );
              const isColumnWithFilter =
                isColumnWithFilters(column.columnKey) &&
                !isFilteringShouldBeHidden;
              return (
                <Col
                  {...column.bootstrapOption}
                  key={column.columnKey}
                  style={{ justifyContent: 'center' }}
                  onClick={e => {
                    if (column.sortingKey && !isSortingShouldBeHidden) {
                      return setSortingRule(column.sortingKey);
                    }
                  }}
                >
                  <div className={'titles-row-title-container'}>
                    <div className={'addons'}>
                      {isColumnWithFilter && (
                        <i
                          className={classnames('icon-filter', {
                            filled: isFilterApplied(
                              column.columnKey as MSBListFilterableColumn
                            )
                          })}
                          id={column.columnKey}
                          onClick={e => {
                            if (
                              isColumnWithFilters(
                                column.columnKey as MSBListSortableColumn
                              )
                            ) {
                              e.stopPropagation();
                              const col = column.columnKey as MSBListFilterableColumn;
                              if (isOpened === col) {
                                return toggleOpened(undefined);
                              }
                              return toggleOpened(col);
                            }
                          }}
                        >
                          <IconFunnel
                            filled={isFilterApplied(
                              column.columnKey as MSBListFilterableColumn
                            )}
                          />
                        </i>
                      )}
                      {column.sortingKey &&
                        !isSortingShouldBeHidden && (
                          <div className={'sort-notificator-container'}>
                            <SortNotificator
                              sortingKey={column.sortingKey}
                              sortingRule={{
                                paramName: sortingRule.column || '',
                                reverse:
                                  sortingRule.rule === MSBListSortingRule.DESC
                              }}
                            />
                          </div>
                        )}
                    </div>
                  </div>
                  {isColumnWithFilter && (
                    <Popover
                      placement="bottom"
                      isOpen={isOpened === column.columnKey}
                      target={column.columnKey}
                      className={'filters-popover'}
                      toggle={() => {
                        toggleOpened(undefined);
                      }}
                      trigger={'legacy'}
                    >
                      <PopoverBody>
                        <ListGroup>
                          {possibleMSBFilterOptions.map(filter => {
                            const col = column.columnKey as MSBListFilterableColumn;
                            return (
                              <ListGroupItem
                                key={filter}
                                active={isFilterApplied(col, filter)}
                                onClick={e => {
                                  e.stopPropagation();
                                  toggleFilterRule(col, filter);
                                  toggleOpened(undefined);
                                }}
                                tag="button"
                                action
                              >
                                <div className={'filter-option'}>
                                  <span>
                                    {t(
                                      `MySmartBattery:listFilter_${col}_${filter}`
                                    )}
                                  </span>
                                  {isFilterApplied(col, filter) && (
                                    <i className="icon-ok" />
                                  )}
                                </div>
                              </ListGroupItem>
                            );
                          })}
                        </ListGroup>
                      </PopoverBody>
                    </Popover>
                  )}
                </Col>
              );
            })}
          </Col>
          <AutoSizer className="items">
            {({ height, width }) => (
              <List
                className="list"
                rowCount={dataSource.length + 1}
                rowHeight={rowHeight}
                noRowsRenderer={() => {
                  return <EmptyCollectionPlaceHolder text={placeholder} />;
                }}
                rowRenderer={({ key, index, style }) => {
                  if (index !== dataSource.length) {
                    return (
                      <Row
                        onClick={() => {
                          if (onRowClick) {
                            onRowClick(dataSource[index], index);
                          }
                        }}
                        className="item-row"
                        style={style}
                        key={key}
                      >
                        {visibleColumns.map(column => (
                          <Col
                            style={{ textAlign: column.align }}
                            className="item-col"
                            {...column.bootstrapOption}
                            key={`${column.columnKey}-${key}`}
                          >
                            {column.dataKey
                              ? dataSource[index][column.dataKey]
                              : column.render
                                ? column.render(dataSource[index], index)
                                : ''}
                          </Col>
                        ))}
                      </Row>
                    );
                  }
                  if (index > 1) {
                    return (
                      <Row
                        className="item-row"
                        style={{
                          ...style,
                          justifyContent: 'center',
                          backgroundColor: canFetchMore ? 'aliceblue' : 'white',
                          borderBottom: 'none',
                          cursor: canFetchMore ? 'pointer' : 'unset'
                        }}
                        onClick={canFetchMore ? fetchMore : undefined}
                        key={key}
                      >
                        <div>
                          {canFetchMore
                            ? t('Common:fetchMore')
                            : t('Common:noMoreResults')}
                        </div>
                      </Row>
                    );
                  }
                }}
                height={height - titleRowSize}
                width={width}
              />
            )}
          </AutoSizer>
        </Container>
      </div>
    );
  }
);

export default translate(['Customer', 'Common'])(MSBSmoothList);
