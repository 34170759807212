import React, { useEffect, useState } from 'react';
import { translate, TranslationFunction } from 'react-i18next';

import '../battery.scss';
import { RouteComponentProps, withRouter } from 'react-router';
import List from '../../../../../ui/DataDisplay/List/ListWrapper';
import { Col, Form, FormGroup, Button } from 'reactstrap';
import ListItem from '../../../../../ui/DataDisplay/List/ListItem';
import { ListTitle } from '../../../../../ui/DataDisplay/List/ListHeader';
import { withApollo, WithApolloClient } from 'react-apollo';
import { safeMobxInject } from '../../../../../stores/storeInjectionHelpers';
import { CreationMySmartBattery } from '../../../../../stores/my_smart_battery/creationMySmartBattery';
import ShowAlert from '../../../../../ui/ShowAlert/ShowAlert';
import { MsbInputText } from '../activation/MsbInputText';
import {
  eldPdlValidator,
  msbMylightModemSerialNumberValidator
} from '../../../../../utils/validators';
import LoadingButton from '../../../../../ui/Button/LoadingButton/LoadingButton';
import { MySmartBattery } from '../../../../../stores/my_smart_battery/mySmartBattery';
import MsbDeletionModalOpenButton from '../deletion/MsbDeletionModalOpenButton';

interface IProps extends RouteComponentProps<IMatchParams> {
  t: TranslationFunction;
  isolated?: boolean;
  onCancel?: () => void;
  onAdd?: () => void;
  onUpdate?: () => void;
  onNext?: () => void;
  editMode?: boolean;
}

interface IStoresProps {
  creationMySmartBatteryStore: CreationMySmartBattery;
  mySmartBatteryStore: MySmartBattery;
}

interface IMatchParams {
  customerId: string;
}

const MsbCreation = safeMobxInject<IStoresProps, IProps>(
  stores => ({
    creationMySmartBatteryStore: stores.creationMySmartBattery,
    mySmartBatteryStore: stores.mySmartBattery
  }),
  ({
    t,
    client,
    isolated,
    creationMySmartBatteryStore,
    mySmartBatteryStore,
    onCancel,
    onAdd,
    onNext,
    onUpdate,
    editMode,
    match: {
      params: { customerId }
    }
  }: WithApolloClient<IProps & IStoresProps>) => {
    const [energyDistributorText, setEnergyDistributorText] = useState('');
    const [isSerialNumberLoading, setIsSerialNumberLoading] = useState(false);
    const [serialNumberTextError, setSerialNumberTextError] = useState<
      string | undefined
    >(undefined);

    const [isPdlNumberLoading, setIsPdlNumberLoading] = useState(false);
    const [pdlNumberTextError, setPdlNumberTextError] = useState<
      string | undefined
    >(undefined);

    useEffect(
      () => {
        creationMySmartBatteryStore
          .getPdlNumberByCustomerId(client, customerId)
          .then(pdlNumber => {
            if (pdlNumber) {
              creationMySmartBatteryStore
                .checkPdlNumber(client, pdlNumber, customerId)
                .catch(e => {
                  setPdlNumberTextError(e);
                });
            }
          });
      },
      [customerId]
    );

    useEffect(
      () => {
        setEnergyDistributorText(
          creationMySmartBatteryStore.energyDistributor
            ? t(
                `MySmartBattery:energyDistributors.${
                  creationMySmartBatteryStore.energyDistributor.name
                }`
              )
            : ''
        );
      },
      [creationMySmartBatteryStore.energyDistributor]
    );

    const onChangeSerialNumberField = (value: string) => {
      creationMySmartBatteryStore.setSerialNumber(value);
      creationMySmartBatteryStore.serialNumberInputState = undefined;
    };

    const onBlurSerialNumberField = async () => {
      const { isValid, error } = checkSerialNumberFormat();
      if (isValid) {
        await checkSerialNumber();
      } else {
        creationMySmartBatteryStore.serialNumberInputState = 'error';
        setSerialNumberTextError(error);
      }
    };

    const checkSerialNumber = async () => {
      setIsSerialNumberLoading(true);
      try {
        await creationMySmartBatteryStore.checkSerialNumber(
          client,
          creationMySmartBatteryStore.serialNumber
        );
      } catch (e) {
        setSerialNumberTextError(e);
      }
      setIsSerialNumberLoading(false);
    };

    const checkSerialNumberFormat = () => {
      const error: string | undefined = msbMylightModemSerialNumberValidator(
        creationMySmartBatteryStore.serialNumber
      );
      const isValid = !error;
      return {
        isValid,
        error
      };
    };

    const onChangePdlNumberField = (value: string) => {
      creationMySmartBatteryStore.setPdlNumber(value);
      creationMySmartBatteryStore.pdlNumberInputState = undefined;
    };

    const onBlurPdlNumberField = async () => {
      const { isValid, error } = checkPdlNumberFormat();

      if (isValid) {
        await checkPdlNumber();
      } else {
        creationMySmartBatteryStore.pdlNumberInputState = 'error';
        setPdlNumberTextError(error);
      }
    };

    const checkPdlNumber = async () => {
      setIsPdlNumberLoading(true);
      try {
        await creationMySmartBatteryStore.checkPdlNumber(
          client,
          creationMySmartBatteryStore.pdlNumber,
          customerId
        );
      } catch (e) {
        setPdlNumberTextError(e);
      }
      setIsPdlNumberLoading(false);
    };

    const checkPdlNumberFormat = () => {
      const error: string | undefined = eldPdlValidator(
        creationMySmartBatteryStore.pdlNumber
      );
      const isValid = !error;
      return {
        isValid,
        error
      };
    };

    const msbActivationWorkflowFinished = () => {
      return (
        mySmartBatteryStore.isMSBStrasbourgElectricityAdministrativeProcedureRequested ||
        mySmartBatteryStore.isMSBEnedisGridInjectionRequested ||
        mySmartBatteryStore.isMSBActivated
      );
    };

    const canMsbBeDeleted: boolean = mySmartBatteryStore.isMsbEnedis || mySmartBatteryStore.isMsbStrasbourgElectricity;

    return (
      <Col md={12} lg={!isolated && 6} className={'mobile-helper'}>
        <List fluid>
          {!isolated && <ListTitle text={t('battery')} />}
          <ListItem className={'battery-form'}>
            <Form
              className="form-wrapper"
              onSubmit={e => {
                e.preventDefault();
              }}
            >
              <Col>
                <FormGroup>
                  <MsbInputText
                    id="serialNumber"
                    name="serialNumber"
                    label={t('enterSerialNumber')}
                    value={creationMySmartBatteryStore.serialNumber}
                    inputState={
                      creationMySmartBatteryStore.serialNumberInputState
                    }
                    loading={isSerialNumberLoading}
                    onChange={value => onChangeSerialNumberField(value)}
                    onBlur={value => onBlurSerialNumberField()}
                    disabled={editMode}
                    helperText={{
                      error: serialNumberTextError
                    }}
                  />
                </FormGroup>
                {!editMode && (
                  <ShowAlert
                    type={'info'}
                    padding={0}
                    message={
                      <>
                        <strong>
                          {t('MySmartBattery:whereIsTheMylightSNQuestion')}
                        </strong>
                        <div className={'mt-1'}>
                          {t('MySmartBattery:whereIsTheMylightSNAnswer')}
                        </div>
                      </>
                    }
                  />
                )}
                <FormGroup>
                  <MsbInputText
                    required
                    id="pdlNumber"
                    name="pdlNumber"
                    label={t('pdlNumber')}
                    value={creationMySmartBatteryStore.pdlNumber}
                    inputState={creationMySmartBatteryStore.pdlNumberInputState}
                    loading={isPdlNumberLoading}
                    disabled={msbActivationWorkflowFinished()}
                    onChange={value => onChangePdlNumberField(value)}
                    onBlur={value => onBlurPdlNumberField()}
                    helperText={{
                      error: pdlNumberTextError
                    }}
                  />
                </FormGroup>
                {!editMode && (
                  <ShowAlert
                    type={'info'}
                    padding={0}
                    message={
                      <>
                        <strong>{t('MySmartBattery:whatIsEanFormat')}</strong>
                        <div className={'mt-1'}>
                          {t('MySmartBattery:EnedisFormat')}
                        </div>
                        <div className={'mt-1'}>
                          {t('MySmartBattery:EsFormat')}
                        </div>
                      </>
                    }
                  />
                )}
                {!msbActivationWorkflowFinished() && (
                  <ShowAlert
                    type={'warning'}
                    padding={0}
                    message={
                      <>
                        <strong>{t('MySmartBattery:warning')}</strong>
                        <div className={'mt-1'}>
                          {t('MySmartBattery:EanImportantStep')}
                        </div>
                      </>
                    }
                  />
                )}
                <FormGroup>
                  <MsbInputText
                    disabled
                    id="energyDistributor"
                    name="energyDistributor"
                    label={t('energyDistributor')}
                    value={energyDistributorText}
                  />
                </FormGroup>
              </Col>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}
              >
                {msbActivationWorkflowFinished() ? (
                  <Button
                    color={'primary'}
                    style={{
                      marginRight: '1rem',
                    }}
                    onClick={onNext}
                  >
                    {t('Common:next')}
                  </Button>
                ) : (
                  <>
                    {editMode ? (
                      <>
                        {canMsbBeDeleted && 
                          <MsbDeletionModalOpenButton
                            client={ client }
                            customerId={ customerId }
                            classList={ 'mr-4' } />
                        }

                        <LoadingButton
                          colorClass={'primary'}
                          style={{
                            marginRight: '1rem',
                            paddingLeft: '1.5rem',
                            paddingRight: '1.5rem'
                          }}
                          loading={creationMySmartBatteryStore.isLoadingCreateMsb}
                          onClick={onUpdate}
                        >
                          {t('Common:save')}
                        </LoadingButton>
                      </>
                    ) : (
                      <>
                        <LoadingButton
                          colorClass={'secondary'}
                          style={{
                            marginRight: '1.5rem',
                          }}
                          disabled={creationMySmartBatteryStore.isLoadingCreateMsb}
                          onClick={onCancel}
                        >
                          {t('Common:cancel')}
                        </LoadingButton>
                        <LoadingButton
                          colorClass={'primary'}
                          style={{
                            marginRight: '1rem',
                          }}
                          disabled={
                            !creationMySmartBatteryStore.isMsbMylightFormValid
                          }
                          loading={
                            creationMySmartBatteryStore.isLoadingCreateMsb
                          }
                          onClick={onAdd}
                        >
                          {t('Common:add')}
                        </LoadingButton>
                      </>
                    )}
                  </>
                )}
              </div>
            </Form>
          </ListItem>
        </List>
      </Col>
    );
  }
);

export default withRouter(
  translate(['MySmartBattery', 'Common', 'DevicesInscription'])(
    withApollo(MsbCreation)
  )
);
