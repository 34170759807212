import * as React from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import classnames from 'classnames';
import './customersHeader.scss';
import SearchInput from '../Inputs/SearchInput';
import CreateUserButton from '../../components/redirect/CreateUserButton';
import { safeMobxInject } from '../../stores/storeInjectionHelpers';

interface IProps {
  selected: string;
  searchQuery: string;
  toggleView: (view: string) => void;
  searchInputChange: (view: string) => void;
}

interface IStoresProps {
  searchRule: string;
  setSearchRule: (rule: string) => void;
}

const CustomersHeader = safeMobxInject<IStoresProps, IProps>(
  ({ Store: { customersList } }) => ({
    searchRule: customersList.searchRule,
    setSearchRule: customersList.setSearchRule
  }),
  ({
    selected,
    toggleView,
    searchRule,
    setSearchRule
  }: IProps & IStoresProps) => (
    <div className="customers-header">
      <ButtonGroup>
        <Button
          onClick={() => {
            toggleView('list');
          }}
          className={classnames('toggle', { selected: selected === 'list' })}
        >
          <i className="icon-list" />
        </Button>
        <Button
          onClick={() => {
            toggleView('map');
          }}
          className={classnames('toggle', { selected: selected === 'map' })}
        >
          <i className="icon-map" />
        </Button>
      </ButtonGroup>
      <div className="customers-header-buttons">
        {selected === 'list' && (
          <SearchInput
            searchInputChange={setSearchRule}
            searchQuery={searchRule}
          />
        )}
        <div className="add-customer">
          <CreateUserButton />
        </div>
      </div>
    </div>
  )
);
export default CustomersHeader;
