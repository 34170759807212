import { default as React, useEffect, useState } from 'react';

import { translate } from 'react-i18next';
import { Alert, Col, Form } from 'reactstrap';
import { TranslationFunction } from 'i18next';
import { IUpdateInstallation } from './EditInstallationQueryFormInscription';
import LoadingButton from '../../../ui/Button/LoadingButton/LoadingButton';
import { EditPVInstallationStore } from '../../../stores/installations/editPVInstallationStore';
import { EditElectricalInstallationStore } from '../../../stores/installations/editElectricalInstallationStore';
import { FormInput } from '../../../ui/FormInput/FormInput';
import SelectInput from '../../../ui/Select/Select';
import PanelManufacturerSelector from './PanelManufacturerSelector';
import ListItem from '../../../ui/DataDisplay/List/ListItem';
import { ListTitle } from '../../../ui/DataDisplay/List/ListHeader';
import List from '../../../ui/DataDisplay/List/ListWrapper';
import InternetProviderSelector from './InternetProviderSelector';
import RackingPowerSelector from './RackingPowerSelector';
import { GridType } from '@mylight/data-model';
import ShowAlert from '../../../ui/ShowAlert/ShowAlert';
import { safeMobxInject } from '../../../stores/storeInjectionHelpers';
import { useScrollToInscriptionStep } from '../../../utils/hooks';
import { InscriptionStep } from '../../../stores/inscription/inscriptionStepsStore';
import { CompositeDevice } from '../../../stores/inscription/compositeDevice';
import CustomModal from '../../../ui/CustomModal/CustomModal';
import Spinner from '../../../ui/Spinner/Spinner';

interface IProps {
  t: TranslationFunction;
  loading: boolean;
  done: boolean;
  saveLoading: boolean;
  save: (installation: IUpdateInstallation) => void;
}

interface IStoresProps {
  editElectricalInstallation: EditElectricalInstallationStore;
  editPVInstallation: EditPVInstallationStore;
  isDone: boolean;
  isMSBAdded: boolean;
  customerIsFromCH: boolean;
  compositeDevicesDone: CompositeDevice[];
}

const EditInstallationFormforInscription = safeMobxInject<IStoresProps, IProps>(
  stores => ({
    editElectricalInstallation: stores.editElectricalInstallation,
    editPVInstallation: stores.editPVInstallation,
    isDone: stores.inscriptionSteps.getInstallStepDone,
    isMSBAdded: stores.battery.isMSBAdded,
    customerIsFromCH: stores.inscription.customerIsFromCH,
    compositeDevicesDone: stores.inscription.compositeDevicesDone
  }),
  ({
    loading,
    saveLoading,
    save,
    done,
    isDone,
    t,
    editElectricalInstallation,
    editPVInstallation,
    isMSBAdded,
    customerIsFromCH,
    compositeDevicesDone
  }: IProps & IStoresProps) => {
    useScrollToInscriptionStep(InscriptionStep.INSTALLATION);

    const [gridTypeChanged, setGridTypeChanged] = useState(false);
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);

    useEffect(
      () => {
        const mandatoryPDLForCustomersWithMSB = Boolean(
          !customerIsFromCH && isMSBAdded
        );
        editElectricalInstallation.updatePdlMandatory(
          mandatoryPDLForCustomersWithMSB
        );
      },
      [customerIsFromCH, isMSBAdded]
    );

    const hasCompositeDeviceWithFunctionDefined =
      compositeDevicesDone.length > 0;
    const hasChangedGridTypeToSinglePhase =
      gridTypeChanged &&
      editElectricalInstallation.gridType === GridType.SINGLE_PHASE;

    const { setPanelCount, panelCount } = editPVInstallation;
    const mandatoryPDLForFrenchCustomers = !customerIsFromCH;

    const emptyPDLNumber =
      !editElectricalInstallation.pdlNumber ||
      editElectricalInstallation.pdlNumber === '';

    const formIsInvalid =
      editPVInstallation.customerInscriptionFormIsInvalid ||
      editElectricalInstallation.customerInscriptionFormIsInvalid ||
      (mandatoryPDLForFrenchCustomers && emptyPDLNumber);

    return (
      <Spinner loading={loading}>
        <Col md={12} lg={6} className={'mobile-helper'}>
          <List fluid>
            {!isDone && (
              <ShowAlert
                type={'warning'}
                message={t('StepTodo:installation')}
              />
            )}
            <ListTitle text={t('RegistrationMenu:pv_installation')} />
            <ListItem>
              <Form className={'form-wrapper'}>
                <SelectInput
                  required
                  onChange={value => {
                    setGridTypeChanged(true);
                    editElectricalInstallation.setGridType(value.value);
                  }}
                  id={`editInstallationForm-gridType`}
                  name={t('gridElectricalPhaseType')}
                  options={[
                    {
                      value: GridType.SINGLE_PHASE,
                      label: t(`Installation:${GridType.SINGLE_PHASE}`)
                    },
                    {
                      value: GridType.THREE_PHASE,
                      label: t(`Installation:${GridType.THREE_PHASE}`)
                    }
                  ]}
                  value={{
                    value: editElectricalInstallation.gridType || '',
                    label: editElectricalInstallation.gridType
                      ? t(`Installation:${editElectricalInstallation.gridType}`)
                      : ''
                  }}
                  error={
                    editElectricalInstallation.gridType &&
                    editElectricalInstallation.errorGridType
                  }
                />
                {hasCompositeDeviceWithFunctionDefined &&
                  hasChangedGridTypeToSinglePhase && (
                    <Alert className="email-error" color="warning">
                      {t('Installation:warningResetFunctionCompositeDevice')}
                    </Alert>
                  )}
                {customerIsFromCH ? (
                  <FormInput
                    required
                    onChange={editElectricalInstallation.setRackingPower}
                    id={`editInstallationForm-rackingPower`}
                    type="number"
                    round={1}
                    name={t('Customer:rackingPower')}
                    value={editElectricalInstallation.rackingPower}
                    unit={'kVa'}
                    error={
                      editElectricalInstallation.rackingPower !== null
                        ? editElectricalInstallation.errorRackingPower
                        : undefined
                    }
                  />
                ) : (
                  <RackingPowerSelector
                    onChange={editElectricalInstallation.setRackingPower}
                    value={{
                      label: editElectricalInstallation.rackingPower
                        ? String(editElectricalInstallation.rackingPower)
                        : '',
                      value: editElectricalInstallation.rackingPower
                        ? String(editElectricalInstallation.rackingPower)
                        : ''
                    }}
                    error={
                      editElectricalInstallation.rackingPower
                        ? editElectricalInstallation.errorSelectRackingPower
                        : undefined
                    }
                  />
                )}
                {!customerIsFromCH && (
                  <FormInput
                    required={mandatoryPDLForFrenchCustomers}
                    onChange={editElectricalInstallation.setPdlNumber}
                    id={`editInstallationForm-pdl`}
                    type="text"
                    name={t('Installation:pdl')}
                    value={editElectricalInstallation.pdlNumber || ''}
                    helperText={
                      !editElectricalInstallation.pdlNumber &&
                      isMSBAdded &&
                      t('Installation:pdlWithMsbInfo')
                    }
                    error={
                      Boolean(
                        editElectricalInstallation.pdlNumber &&
                          !editElectricalInstallation.isValidPdlNumber
                      )
                        ? editElectricalInstallation.errorPdlNumber
                        : undefined
                    }
                  />
                )}
                <FormInput<number>
                  required
                  onChange={setPanelCount}
                  id={`editInstallationForm-moduleCount`}
                  type="number"
                  name={t('panelCount')}
                  value={panelCount}
                  min={0}
                  step={1}
                  round={1}
                  error={
                    editPVInstallation.errorPanelCount &&
                    t('Installation:errorPanelCount')
                  }
                />
                <FormInput
                  required
                  onChange={editPVInstallation.setPanelWattPeak}
                  id={`editInstallationForm-panelWattPeak`}
                  type="number"
                  name={t('wattPeakByPanel')}
                  unit="Wc"
                  value={editPVInstallation.panelWattPeak}
                  min={0}
                  step={1}
                  round={1}
                  max={1000}
                  error={
                    editPVInstallation.errorPanelWattPeak &&
                    t('Installation:errorPanelWattPeak')
                  }
                />
                <PanelManufacturerSelector
                  value={editPVInstallation.panelManufacturer || ''}
                  onChange={(pmf: string) => {
                    editPVInstallation.setPanelManufacturer(pmf);
                  }}
                />
                <InternetProviderSelector
                  value={
                    editElectricalInstallation.internetServiceProvider || ''
                  }
                  onChange={(provider: any) => {
                    editElectricalInstallation.setInternetServiceProvider(
                      provider
                    );
                  }}
                />
                <div className="text-right mt-3">
                  <LoadingButton
                    onClick={event => {
                      event.preventDefault();
                      if (
                        hasCompositeDeviceWithFunctionDefined &&
                        hasChangedGridTypeToSinglePhase
                      ) {
                        setIsConfirmOpen(true);
                      } else {
                        save({
                          pVInstallation:
                            editPVInstallation.editedPVInstallation,
                          electricalInstallation: {
                            ...editElectricalInstallation.editedElectricalInstallation
                          }
                        });
                      }
                    }}
                    disabled={formIsInvalid}
                    loading={saveLoading}
                    done={done}
                  >
                    {t('InstallationEdit:saveEditButton')}
                  </LoadingButton>
                </div>
                <CustomModal
                  isOpened={isConfirmOpen}
                  title={t('Common:warning')}
                  handleClose={() => {
                    setIsConfirmOpen(false);
                  }}
                  handleConfirm={() => {
                    save({
                      pVInstallation: editPVInstallation.editedPVInstallation,
                      electricalInstallation:
                        editElectricalInstallation.editedElectricalInstallation
                    });
                    setIsConfirmOpen(false);
                  }}
                  confirmLabel={t('Common:agree')}
                >
                  {t('Installation:areYouSureToChangedGridType')}
                </CustomModal>
              </Form>
            </ListItem>
          </List>
        </Col>
      </Spinner>
    );
  }
);

export default translate([
  'Customer',
  'Installation',
  'Common',
  'PowerContractType'
])(EditInstallationFormforInscription);
