import { action, computed, observable } from 'mobx';
import { WithApolloClient } from 'react-apollo';
import { t } from 'i18next';
import gql from 'graphql-tag';
import { MSB } from '../../graphql/graphql.schema';
import { MsbActivationInputState } from '../../components/customers/installation/battery/activation/MsbInputText';
import snackbarStore from '../userFeedback/snackbarStore';
import mySmartBattery from './mySmartBattery';
import activationMySmartBatteryMylight from './activationMySmartBatteryMylight';

const UPDATE_CAE_NUMBER_BY_CUSTOMER_ID = gql`
  mutation updateCaeNumberByCustomerId(
    $customerId: String!
    $caeNumber: String!
  ) {
    updateCaeNumberByCustomerId(
      customerId: $customerId
      caeNumber: $caeNumber
    ) {
      caeNumber
      caeNumberModifiedAt
    }
  }
`;

const UPDATE_STRASBOURG_INJECTION_PDL_NUMBER_BY_CUSTOMER_ID = gql`
  mutation updateStrasbourgInjectionPdlNumberByCustomerId(
    $customerId: String!
    $strasbourgInjectionPdlNumber: String!
  ) {
    updateStrasbourgInjectionPdlNumberByCustomerId(
      customerId: $customerId
      strasbourgInjectionPdlNumber: $strasbourgInjectionPdlNumber
    ) {
      strasbourgInjectionPdlNumber
      strasbourgInjectionPdlNumberModifiedAt
    }
  }
`;

const REQUEST_STRASBOURG_ELECTRICITY_ADMINISTRATIVE_PROCEDURE = gql`
  mutation requestStrasbourgElectricityAdministrativeProcedure(
    $customerId: String!
  ) {
    requestStrasbourgElectricityAdministrativeProcedure(
      customerId: $customerId
    ) {
      statusModifiedAt
    }
  }
`;

const GET_ARPE_DOWNLOAD_URL = gql`
  query getStrasbourgElectricityARPEDownloadURL {
    getStrasbourgElectricityARPEDownloadURL
  }
`;

export class ActivationMySmartBatteryStrasbourgElectricityAdministrativeProcedure {
  @observable public caeNumber: string = '';
  @observable public caeNumberSaved: string = '';
  @observable public caeNumberModifiedAt: Date | null = null;
  @observable public caeNumberInputState?: MsbActivationInputState = undefined;
  @observable public strasbourgInjectionPdlNumber: string = '';
  @observable public strasbourgInjectionPdlNumberSaved: string = '';
  @observable public strasbourgInjectionPdlNumberModifiedAt: Date | null = null;
  @observable public strasbourgInjectionPdlNumberInputState?: MsbActivationInputState = undefined;
  @observable public sendingARPEDate: Date | null = null;
  @observable
  public isLoadingGetStrasbourgElectricityARPEDownloadURL: boolean = false;
  @observable
  public isLoadingRequestStrasbourgElectricityAdministrativeProcedure: boolean = false;
  @observable
  public isErrorRequestStrasbourgElectricityAdministrativeProcedure: boolean = false;

  @computed
  public get isStrasbourgElectricityAdministrativeProcedureFormValid(): boolean {
    return (
      (this.caeNumberSaved !== '' && this.caeNumberInputState !== 'error') 
      && (this.strasbourgInjectionPdlNumberSaved !== '' && this.strasbourgInjectionPdlNumberInputState !== 'error')
      && this.sendingARPEDate !== null
    );
  }

  @action.bound
  public async updateCaeNumber(
    client: WithApolloClient<any>,
    customerId: string,
    caeNumber: string
  ) {
    try {
      const { data } = await client.mutate({
        mutation: UPDATE_CAE_NUMBER_BY_CUSTOMER_ID,
        variables: {
          customerId,
          caeNumber
        }
      });
      this.caeNumber = data.updateCaeNumberByCustomerId.caeNumber;
      this.caeNumberSaved = data.updateCaeNumberByCustomerId.caeNumber;
      this.caeNumberModifiedAt =
        data.updateCaeNumberByCustomerId.caeNumberModifiedAt;
      this.caeNumberInputState = 'success';
      return Promise.resolve(data.updateCaeNumberByCustomerId);
    } catch (error) {
      this.caeNumberInputState = 'error';
      return Promise.reject(
        t(`MySmartBattery:errors.${error.graphQLErrors[0].message.errorCode}`)
      );
    }
  }

  @action.bound
  public async updateStrasbourgInjectionPdlNumber(
    client: WithApolloClient<any>,
    customerId: string,
    strasbourgInjectionPdlNumber: string
  ) {
    try {
      const { data } = await client.mutate({
        mutation: UPDATE_STRASBOURG_INJECTION_PDL_NUMBER_BY_CUSTOMER_ID,
        variables: {
          customerId,
          strasbourgInjectionPdlNumber
        }
      });
      this.strasbourgInjectionPdlNumber = data.updateStrasbourgInjectionPdlNumberByCustomerId.strasbourgInjectionPdlNumber;
      this.strasbourgInjectionPdlNumberSaved = data.updateStrasbourgInjectionPdlNumberByCustomerId.strasbourgInjectionPdlNumber;
      this.strasbourgInjectionPdlNumberModifiedAt =
        data.updateStrasbourgInjectionPdlNumberByCustomerId.strasbourgInjectionPdlNumberModifiedAt;
      this.strasbourgInjectionPdlNumberInputState = 'success';
      return Promise.resolve(data.updateStrasbourgInjectionPdlNumberByCustomerId);
    } catch (error) {
      this.strasbourgInjectionPdlNumberInputState = 'error';
      return Promise.reject(
        t(`MySmartBattery:errors.${error.graphQLErrors[0].message.errorCode}`)
      );
    }
  }

  @action.bound
  public async getStrasbourgElecARPEDownloadURL(
    client: WithApolloClient<any>
  ): Promise<string> {
    this.isLoadingGetStrasbourgElectricityARPEDownloadURL = true;
    const { data, errors } = await client.query({
      query: GET_ARPE_DOWNLOAD_URL
    });
    this.isLoadingGetStrasbourgElectricityARPEDownloadURL = false;
    return data.getStrasbourgElectricityARPEDownloadURL;
  }

  @action.bound
  public setActivationMsbStrasbourgElectricityAdministrativeProcedure(
    msb: MSB
  ): void {
    this.clearActivationMsbStrasbourgElectricityAdministrativeProcedure();
    if (msb.caeNumber) {
      this.caeNumber = msb.caeNumber || '';
      this.caeNumberSaved = msb.caeNumber || '';
      this.caeNumberModifiedAt = msb.caeNumberModifiedAt || null;
    }

    if (msb.strasbourgInjectionPdlNumber) {
      this.strasbourgInjectionPdlNumber = msb.strasbourgInjectionPdlNumber || '';
      this.strasbourgInjectionPdlNumberSaved = msb.strasbourgInjectionPdlNumber || '';
      this.strasbourgInjectionPdlNumberModifiedAt = msb.strasbourgInjectionPdlNumberModifiedAt || null;
    }

    this.sendingARPEDate = msb.sendingARPEDate || null;
  }

  @action.bound
  public clearActivationMsbStrasbourgElectricityAdministrativeProcedure(): void {
    this.caeNumber = '';
    this.caeNumberSaved = '';
    this.caeNumberModifiedAt = null;
    this.caeNumberInputState = undefined;
    this.strasbourgInjectionPdlNumber = '';
    this.strasbourgInjectionPdlNumberSaved = '';
    this.strasbourgInjectionPdlNumberModifiedAt = null;
    this.strasbourgInjectionPdlNumberInputState = undefined;
    this.sendingARPEDate = null;
  }

  @action.bound
  public async requestStrasbourgElectricityAdministrativeProcedure(
    client: WithApolloClient<any>,
    customerId: string
  ): Promise<void> {
    try {
      this.isLoadingRequestStrasbourgElectricityAdministrativeProcedure = true;
      this.isErrorRequestStrasbourgElectricityAdministrativeProcedure = false;
      const { errors } = await client.mutate({
        mutation: REQUEST_STRASBOURG_ELECTRICITY_ADMINISTRATIVE_PROCEDURE,
        variables: { customerId }
      });
      this.isLoadingRequestStrasbourgElectricityAdministrativeProcedure = false;
      if (errors) {
        this.isErrorRequestStrasbourgElectricityAdministrativeProcedure = true;
        return;
      }
      const msb = await mySmartBattery.loadMsb(client, customerId);
      if (msb) {
        mySmartBattery.setActivationMsb(msb);
        activationMySmartBatteryMylight.setActivationMsbMylight(msb);
        this.setActivationMsbStrasbourgElectricityAdministrativeProcedure(msb);
      }
    } catch (error) {
      this.isLoadingRequestStrasbourgElectricityAdministrativeProcedure = false;
      this.isErrorRequestStrasbourgElectricityAdministrativeProcedure = true;
      snackbarStore.showErrorSnackBar({
        text: t(
          'MySmartBattery:errors.requestStrasbourgElectricityAdministrativeProcedureFailed'
        )
      });
    }
  }
}

const activationMySmartBatteryStrasbourgElectricityAdministrativeProcedure: ActivationMySmartBatteryStrasbourgElectricityAdministrativeProcedure = new ActivationMySmartBatteryStrasbourgElectricityAdministrativeProcedure();
export default activationMySmartBatteryStrasbourgElectricityAdministrativeProcedure;
