import * as React from 'react';

import gql from 'graphql-tag';
import { RouteComponentProps, withRouter } from 'react-router';
import { observer, inject } from 'mobx-react';
import { Query, QueryResult } from 'react-apollo';
import TadoDevicesView from './TadoDevicesView';
import { PPTadoDevice } from '../../../../graphql/graphql.schema';
import { translate, TranslationFunction } from 'react-i18next';
import { sortBy } from 'lodash';
import history from '../../../../history';
import { IAllStores } from '../../../../stores/allStores.model';
import { TadoStore } from '../../../../stores/partnerships/tadoStore';
import { ISnackBarParams } from '../../../../stores/userFeedback/snackbarStore';

export const GET_TADO_DEVICES = gql`
  query getTadoDevices($customerId: String!) {
    getTadoDevices(customerId: $customerId) {
      id
      controlledDeviceType
      deviceName
      srsType
      tadoType
      roomName
      roomType
    }
    srsRoomTypeIds
  }
`;

export interface IProps extends RouteComponentProps<IMatchParams> {
  tadoStore?: TadoStore;
  t: TranslationFunction;
  showSnackBar?: (params: ISnackBarParams) => void;
}

interface IMatchParams {
  customerId: string;
  pvInstallationId: string;
}

interface IResponse {
  queryResponse?: any;
  getTadoDevices?: PPTadoDevice[];
  srsRoomTypeIds: any;
}

const TadoDevicesQuery = inject(({ tadoStore, snackBar }: IAllStores) => {
  return {
    tadoStore,
    showSnackBar: snackBar.showSnackBar
  };
})(
  observer(
    ({
      tadoStore,
      t,
      match: {
        params: { customerId, pvInstallationId }
      },
      showSnackBar
    }: IProps) => {
      return (
        <Query
          query={GET_TADO_DEVICES}
          variables={{ customerId }}
          fetchPolicy={'no-cache'}
          onCompleted={async (data: IResponse) => {
            const {
              storeTadoDevices,
              ignoreTadoDevices,
              clearIgnoredTadoDevices
            } = tadoStore!;
            const getTadoDevices = data && data.getTadoDevices;
            const tadoDevices = sortBy(getTadoDevices, ['id']).map(
              (device: PPTadoDevice) => {
                return {
                  id: device.id,
                  controlledDeviceType: device.controlledDeviceType,
                  deviceName: device.deviceName || '',
                  roomName: device.roomName || '',
                  roomType: device.roomType || 'other_area_type'
                };
              }
            );
            if (tadoDevices.length > 0) {
              await storeTadoDevices(tadoDevices);

              if (tadoStore!.status === 'connected') {
                await ignoreTadoDevices(tadoStore!.unsavedTadoDevices);
              } else {
                await clearIgnoredTadoDevices();
              }
            } else {
              showSnackBar!({
                type: 'error',
                text: t('TadoPartnership:noTadoDevices')
              });
            }
          }}
        >
          {({ loading, data, error }: QueryResult<IResponse>) => {
            // if (error) {
            //   error.graphQLErrors.map(e => {
            //     if (e.message.error === 'Not found') {
            //       addDevicesError(`${t('homeNotFound')}`);
            // } else {
            //   addDevicesError(`${t('getDevices')}`);
            // }
            // });
            // }

            const roomTypes = (data && data.srsRoomTypeIds) || [];
            const { status } = tadoStore!;

            if (status === 'disconnected') {
              history.push(
                `/devices_inscription/${customerId}/partnership/${pvInstallationId}/tado/connecting`
              );
            }
            return <TadoDevicesView loading={loading} roomTypes={roomTypes} />;
          }}
        </Query>
      );
    }
  )
);

export default withRouter(
  translate(['TadoErrors', 'TadoPartnership'])(TadoDevicesQuery)
);
