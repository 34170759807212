import { GridType } from '@mylight/data-model';
import { sortBy } from 'lodash';
import { action, computed, IObservableArray, observable } from 'mobx';
import { DeviceConnectionType } from '../../constants/DeviceConnectionType';
import { ModBusReference } from '../../graphql/graphql.schema';
import inscription from './inscriptionStore';

export interface IModbusDevice {
  reference: string;
  address: string;
}

export class AddDeviceStore {
  @observable public macOrAC: string = '';
  @observable public possibleReferences: ModBusReference[] = [];
  @observable public chosenReference?: ModBusReference = undefined;
  @observable public chosenAddress?: string = undefined;
  @observable public modbusDevicesList: IModbusDevice[] = [];

  @observable private originalPossibleReferences: ModBusReference[] = [];

  @action.bound
  public resetAddDeviceStore(): void {
    this.macOrAC = '';
    this.possibleReferences = [];
    this.originalPossibleReferences = [];
    this.resetChosenReferenceAndAddress();
    this.modbusDevicesList = [];
  }

  @action.bound
  public resetChosenReferenceAndAddress(): void {
    this.chosenReference = undefined;
    this.chosenAddress = undefined;
  }

  @computed
  public get modbusDevicesListItems(): IModbusDevice[] {
    return this.modbusDevicesList.map(device => ({ ...device }));
  }

  @computed
  public get modbusDevicesListLength(): number {
    return this.modbusDevicesList.length;
  }

  @computed
  public get possibleConnectionTypes(): string[] {
    if (inscription.isMstG3) {
      return [
        DeviceConnectionType.PLC,
        DeviceConnectionType.RS485,
        DeviceConnectionType.W_MODBUS
      ];
    }

    return [DeviceConnectionType.PLC];
  }

  /**
   * Get possible references
   * Filter out 3phase in 1phase installation
   * ( 1phase ref are allowed in 3phase installation)
   */
  @computed
  public get availableReferences(): string[] {
    return this.possibleReferences
      ? this.possibleReferences
          .filter(
            r =>
              inscription.gridType === GridType.SINGLE_PHASE
                ? r.phaseCount === 1
                : true
          )
          .map(r => r.reference)
      : [];
  }

  @computed
  public get availableAddresses(): string[] {
    return this.chosenReference ? this.chosenReference.availableAddress : [];
  }

  @computed
  public get canAddRS485Device(): boolean {
    return !!(this.chosenReference && this.chosenAddress);
  }

  @computed
  get canAddPLCDevice(): boolean {
    if (this.macOrAC) {
      if (
        this.macOrAC.match('^([A-Fa-f0-9]{12})$') ||
        (this.macOrAC &&
          this.macOrAC.match('^([A-Za-z0-9]{4}[-]){2}[A-Za-z0-9]{4}$'))
      ) {
        return true;
      }
    }
    return false;
  }

  @action.bound
  public setPossibleReferences(references: ModBusReference[]): void {
    this.originalPossibleReferences = this.possibleReferences = sortBy(
      references,
      ['reference']
    );
  }

  @action.bound
  public setChosenReference(reference: string): void {
    const selectedReference = this.possibleReferences.find(
      r => r.reference === reference
    );
    if (selectedReference) {
      this.chosenReference = selectedReference;
      if (
        selectedReference.defaultAddress &&
        !isNaN(selectedReference.defaultAddress) &&
        selectedReference.availableAddress.includes(
          selectedReference.defaultAddress.toString()
        )
      ) {
        this.setChosenAddress(selectedReference.defaultAddress.toString());
      } else {
        this.setChosenAddress(selectedReference.availableAddress[0]);
      }
    }
  }

  @action.bound
  public setChosenAddress(address: string): void {
    this.chosenAddress = address;
  }

  @action.bound
  public setMacOrAC(macOrAC: string): void {
    this.macOrAC = macOrAC;
  }

  @action.bound
  public addChosenReferenceToModbusDevicesList(): void {
    if (!(this.chosenReference && this.chosenAddress)) return;

    this.modbusDevicesList.push({
      reference: this.chosenReference.reference,
      address: this.chosenAddress
    });

    this.removeAvailableAddress(this.chosenAddress);

    this.resetChosenReferenceAndAddress();
  }

  @action.bound
  public removeReferenceFromModbusDevicesList(
    deviceToRemove: IModbusDevice
  ): void {
    this.modbusDevicesList = this.modbusDevicesList.filter(
      device => device !== deviceToRemove
    );

    this.restoreAvailableAddress(deviceToRemove.address);
  }

  private removeAvailableAddress(addressToRemove: string): void {
    this.possibleReferences = this.possibleReferences
      .map(reference => {
        const availableAddress = reference.availableAddress.filter(
          address => address !== addressToRemove
        );

        const defaultAddress =
          reference.defaultAddress === parseInt(addressToRemove, 10)
            ? availableAddress.length
              ? parseInt(availableAddress[0], 10)
              : reference.defaultAddress
            : reference.defaultAddress;

        return {
          availableAddress,
          defaultAddress,
          reference: reference.reference,
          phaseCount: reference.phaseCount
        };
      })
      .filter(reference => reference.availableAddress.length > 0);
  }

  private restoreAvailableAddress(addressToRestore: string): void {
    this.possibleReferences = this.possibleReferences.map(reference => {
      const originalReference = this.originalPossibleReferences.find(
        ref => ref.reference === reference.reference
      );

      if (
        !originalReference ||
        !originalReference.availableAddress.includes(addressToRestore)
      ) {
        return reference;
      }

      return {
        availableAddress: [
          ...reference.availableAddress,
          addressToRestore
        ].sort(
          (addressA, addressB) =>
            parseInt(addressA, 10) - parseInt(addressB, 10)
        ),
        defaultAddress:
          originalReference.defaultAddress === parseInt(addressToRestore, 10)
            ? parseInt(addressToRestore, 10)
            : reference.defaultAddress,
        reference: reference.reference,
        phaseCount: reference.phaseCount
      };
    });
  }
}

const addDevice: AddDeviceStore = new AddDeviceStore();
export default addDevice;
