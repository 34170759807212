import * as React from 'react';
import { ProUser } from '../../../graphql/graphql.schema';
import { TranslationFunction } from 'i18next';
import Spinner from '../../../ui/Spinner/Spinner';
import { FormGroup } from 'reactstrap';
import SelectInput from '../../../ui/Select/Select';

import { translate } from 'react-i18next';
import gql from 'graphql-tag';
import { Query, QueryResult } from 'react-apollo';
import { inject, observer } from 'mobx-react';
import { Store } from '../../../stores';
import { IAllStores } from '../../../stores/allStores.model';
import { safeMobxInject } from '../../../stores/storeInjectionHelpers';

const GET_COLLEAGUES = gql`
  query colleagues {
    installers {
      id
      firstName
      lastName
      email
      company {
        id
        companyName
        companyCode
      }
    }
  }
`;

interface IStoresProps {
  updateInstaller: (installer?: ProUser) => void;
  installer?: ProUser;
}

interface IProps {
  t: TranslationFunction;
}

interface IResponse {
  installers: ProUser[];
}

const SelectInstaller = safeMobxInject<IStoresProps, IProps>(
  ({ Store: { createCustomer } }) => {
    return {
      updateInstaller: createCustomer.updateInstaller,
      installer: createCustomer.installer
    };
  },
  ({ t, updateInstaller, installer }: IProps & IStoresProps) => {
    return (
      <Query query={GET_COLLEAGUES}>
        {({ loading, data, error }: QueryResult<IResponse>) => {
          const installers = (data && data.installers) || [];
          return (
            <Spinner
              loading={loading}
              error={error && t('installerLoadingError')}
            >
              <FormGroup>
                <SelectInput
                  name={t('installer')}
                  id="assignInstaller"
                  value={{ value: installer ? installer.id : '', label: '' }}
                  onChange={sel => {
                    updateInstaller(installers.find(i => i.id === sel.value));
                  }}
                  disabled={installers.length === 0}
                  options={installers.map(item => ({
                    value: item.id,
                    label: `${item.firstName} ${item.lastName} - ${item.email}`
                  }))}
                />
              </FormGroup>
            </Spinner>
          );
        }}
      </Query>
    );
  }
);

export default translate('Customer')(SelectInstaller);
