import * as React from 'react';
import { Button, Col } from 'reactstrap';
import myProsizer from '../../../assets/images/myprosizer-small.png';
import { translate } from 'react-i18next';
import { TranslationFunction } from 'i18next';
import style from './ProsizerAdvert.module.scss';
import { inject, observer } from 'mobx-react';
import tools from '../../../utils/tools';
import { IAllStores } from '../../../stores/allStores.model';

const ProsizerAdvert = inject(
  ({ environment: { prosizerUrl } }: IAllStores) => ({
    prosizerUrl
  })
)(
  observer(
    ({ t, prosizerUrl }: { t: TranslationFunction; prosizerUrl?: string }) =>
      prosizerUrl ? (
        <Col md={12} lg={12}>
          <div className={style.wrapper}>
            <div className={style['image-box']}>
              <img src={myProsizer} alt="prosizer-logo" />
            </div>
            <div className={style.text}>{t('prosizerCallToAction')}</div>
            <div className={style.link}>
              <Button
                color="primary"
                onClick={() => {
                  tools.openNewTabfForURI(
                    `${prosizerUrl}?token=${localStorage.getItem('Auth-Token')}`
                  );
                }}
              >
                {t('prosizerLinkName')}
              </Button>
            </div>
          </div>
        </Col>
      ) : (
        <span />
      )
  )
);

export default translate('Dashboard')(ProsizerAdvert);
