import React from 'react';
import { safeMobxInject } from '../../../stores/storeInjectionHelpers';
import ShowAlert from '../../../ui/ShowAlert/ShowAlert';
import { Col, Row } from 'reactstrap';

interface IProps {}
interface IStoresProps {
  masterWarnings: [
    { type: 'danger' | 'info' | 'warning'; message: string | JSX.Element }
  ];
}

const MasterWarnings = safeMobxInject<IStoresProps, IProps>(
  stores => ({
    masterWarnings: stores.inscription.masterWarnings
  }),
  ({ masterWarnings }: IStoresProps) => {
    return (
      <Row>
        <Col xs={12} style={{ marginTop: '0.5rem' }}>
          {masterWarnings.length > 0 &&
            masterWarnings.map(({ type, message }, index) => (
              <ShowAlert
                key={index}
                type={type}
                message={message}
                style={{ marginTop: '-0.5rem' }}
              />
            ))}
        </Col>
      </Row>
    );
  }
);

export default MasterWarnings;
