import { action, observable } from 'mobx';

export default class SearchCustomer {
  @observable public searchQuery: string = '';

  @action.bound
  public setCustomerSearchQuery(searchQuery: string) {
    this.searchQuery = searchQuery;
  }
}
