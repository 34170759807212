import * as React from 'react';
import { Col, Row } from 'reactstrap';
import { translate, TranslationFunction } from 'react-i18next';
import {
  MSBListFilter,
  MSBListFilterableColumn,
  MSBListFilterRule,
  MSBListItem,
  MSBListSortableColumn
} from '../../../graphql/graphql.schema';
import MobileSmoothList from '../../../ui/SmoothList/MobileSmoothList';
import { dateFormatter } from '../../../utils/tools';
import { RouteComponentProps, withRouter } from 'react-router';
import IconCompanyMSB from '../../svg/IconCompanyMSB';

interface IProps extends RouteComponentProps {
  t: TranslationFunction;
  mySmartBatteryList: MSBListItem[];
  filters: MSBListFilter[];
  canFetchMore: boolean;
  fetchMore: () => void;
  isReadOnly: boolean;
  loginAsForReadOnly: (customerEmail: string) => void;
}

const MySmartBatteryListMobile = ({
  t,
  mySmartBatteryList,
  history,
  fetchMore,
  canFetchMore,
  isReadOnly,
  loginAsForReadOnly,
  filters
}: IProps) => {
  const onRowClick = (item: MSBListItem) => {
    if (isReadOnly && item.customerEmail) {
      return loginAsForReadOnly(item.customerEmail);
    }
    return history.push(`/customers/${item.customerId}/installation`);
  };

  const isMyLightEnergyMSBFilterEnabled = filters.some(
    filter =>
      filter.column === MSBListFilterableColumn.msbType &&
      filter.rule === MSBListFilterRule.isNotNull
  );
  const isPlaneteOuiMSBFilterEnabled = filters.some(
    filter =>
      filter.column === MSBListFilterableColumn.msbType &&
      filter.rule === MSBListFilterRule.isNull
  );

  return (
    <div className="my-smart-battery-list full-page-list mobile">
      <MobileSmoothList
        rowHeight={isReadOnly ? 320 : 250}
        placeHolderText={t('MySmartBattery:msbEmptyList')}
        onRowClick={onRowClick}
        fetchMore={fetchMore}
        canFetchMore={canFetchMore}
        columns={[
          {
            columnKey: MSBListSortableColumn.customerLastName,
            bootstrapOption: { xs: 6 },
            render: (item: MSBListItem) => (
              <Row>
                <Col xs={12} style={{ textTransform: 'uppercase' }}>
                  {item.customerFirstName}
                </Col>
                <Col xs={12} style={{ textTransform: 'uppercase' }}>
                  {item.customerLastName}
                </Col>
              </Row>
            )
          },
          {
            columnKey: 'icon',
            align: 'right',
            bootstrapOption: { xs: 6 },
            render: (item: MSBListItem) => (
              <Row>
                <Col
                  xs={12}
                  style={{
                    justifyContent: 'flex-end'
                  }}
                >
                  <IconCompanyMSB
                    isMSBPotentiallyStuck={item.msbPotentiallyStuck}
                    isMSBActivated={item.msbOk}
                    msbType={item.msbType}
                  />
                </Col>
              </Row>
            )
          },
          {
            isHidden: !isReadOnly,
            columnKey: MSBListSortableColumn.customerEmail,
            bootstrapOption: { xs: 12 },
            render: (item: MSBListItem) => (
              <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
                <Col xs={8} className="list-item-name">
                  {t('Customer:email')}:
                </Col>
                <Col xs={4} className="list-item-value">
                  {item.customerEmail || '-'}
                </Col>
              </Row>
            )
          },
          {
            columnKey: MSBListSortableColumn.msbVolume,
            bootstrapOption: { xs: 12 },
            render: (item: MSBListItem) => (
              <Row
                style={
                  isReadOnly ? { marginBottom: '10px' } : { marginTop: '10px' }
                }
              >
                <Col xs={8} className="list-item-name">
                  {t('MySmartBattery:volumeShort')}:
                </Col>
                <Col xs={4} className="list-item-value">
                  {item.msbVolume ? `${item.msbVolume}kWh` : '-'}
                </Col>
              </Row>
            )
          },
          {
            isHidden: isMyLightEnergyMSBFilterEnabled,
            columnKey: MSBListSortableColumn.msbCaeNumber,
            bootstrapOption: { xs: 12 },
            render: (item: MSBListItem) => (
              <Row style={isReadOnly ? {} : { marginBottom: '10px' }}>
                <Col xs={8} className="list-item-name">
                  {t('MySmartBattery:caeNumberShort')}:
                </Col>
                <Col xs={4} className="list-item-value">
                  {item.msbCaeNumber || '-'}
                </Col>
              </Row>
            )
          },
          {
            isHidden: !isReadOnly,
            columnKey: MSBListSortableColumn.customerPdlNumber,
            bootstrapOption: { xs: 12 },
            render: (item: MSBListItem) => (
              <Row style={{ marginBottom: '10px' }}>
                <Col xs={8} className="list-item-name">
                  {t('Installation:pdl')}:
                </Col>
                <Col xs={4} className="list-item-value">
                  {item.customerPdlNumber || '-'}
                </Col>
              </Row>
            )
          },
          {
            isHidden: isPlaneteOuiMSBFilterEnabled,
            columnKey: MSBListSortableColumn.msbEnedisConnectNumber,
            bootstrapOption: { xs: 12 },
            render: (item: MSBListItem) => (
              <Row style={isReadOnly ? {} : { marginTop: '10px' }}>
                <Col xs={8} className="list-item-name">
                  {t('MySmartBattery:enedisConnect')}:
                </Col>
                <Col xs={4} className="list-item-value">
                  {item.msbEnedisConnectNumber || '-'}
                </Col>
              </Row>
            )
          },
          {
            isHidden: isPlaneteOuiMSBFilterEnabled,
            columnKey: MSBListSortableColumn.msbConsuelNumber,
            bootstrapOption: { xs: 12 },
            render: (item: MSBListItem) => (
              <Row style={isReadOnly ? {} : { marginBottom: '10px' }}>
                <Col xs={8} className="list-item-name">
                  {t('MySmartBattery:consuelNumber')}:
                </Col>
                <Col xs={4} className="list-item-value">
                  {item.msbConsuelNumber || '-'}
                </Col>
              </Row>
            )
          },
          {
            isHidden: isMyLightEnergyMSBFilterEnabled,
            columnKey: MSBListSortableColumn.msbSendingARPEDate,
            bootstrapOption: { xs: 12 },
            render: (item: MSBListItem) => (
              <Row className={item.msbSendingARPEDate ? 'valid' : 'invalid'}>
                <Col xs={8} className="list-item-name">
                  {t('MySmartBattery:sendingARPEDate')}:
                </Col>
                <Col xs={4} className="list-item-value">
                  {dateFormatter(item.msbSendingARPEDate) || '-'}
                </Col>
              </Row>
            )
          },
          {
            columnKey: MSBListSortableColumn.msbSNFilled,
            bootstrapOption: { xs: 12 },
            render: (item: MSBListItem) => (
              <Row className={item.msbSNFilled ? 'valid' : 'invalid'}>
                <Col xs={8} className="list-item-name">
                  {t(`MySmartBattery:msbSNFilled_${item.msbType}`)}:
                </Col>
                <Col xs={4} className="list-item-value">
                  {dateFormatter(item.msbSNFilled) || '-'}
                </Col>
              </Row>
            )
          },

          {
            isHidden: isMyLightEnergyMSBFilterEnabled,
            columnKey: MSBListSortableColumn.msbACFilled,
            bootstrapOption: { xs: 12 },
            render: (item: MSBListItem) => (
              <Row className={item.msbACFilled ? 'valid' : 'invalid'}>
                <Col xs={8} className="list-item-name">
                  {t('MySmartBattery:msbACFilled')}:
                </Col>
                <Col xs={4} className="list-item-value">
                  {dateFormatter(item.msbACFilled) || '-'}
                </Col>
              </Row>
            )
          },

          {
            isHidden: isMyLightEnergyMSBFilterEnabled,
            columnKey: MSBListSortableColumn.msbEnedisOk,
            bootstrapOption: { xs: 12 },
            render: (item: MSBListItem) => (
              <Row className={item.msbEnedisOk ? 'valid' : 'invalid'}>
                <Col xs={8} className="list-item-name">
                  {t('MySmartBattery:msbEnedisOk')}:
                </Col>
                <Col xs={4} className="list-item-value">
                  {dateFormatter(item.msbEnedisOk) || '-'}
                </Col>
              </Row>
            )
          },
          {
            isHidden: isMyLightEnergyMSBFilterEnabled,
            columnKey: MSBListSortableColumn.msbProviderRegistrationOk,
            bootstrapOption: { xs: 12 },
            render: (item: MSBListItem) => (
              <Row
                className={item.msbProviderRegistrationOk ? 'valid' : 'invalid'}
              >
                <Col xs={8} className="list-item-name">
                  {t('MySmartBattery:msbProviderRegistrationOk')}:
                </Col>
                <Col xs={4} className="list-item-value">
                  {dateFormatter(item.msbProviderRegistrationOk) || '-'}
                </Col>
              </Row>
            )
          },
          {
            columnKey: MSBListSortableColumn.msbOk,
            bootstrapOption: { xs: 12 },
            render: (item: MSBListItem) => (
              <Row className={item.msbOk ? 'valid' : 'invalid'}>
                <Col xs={8} className="list-item-name">
                  {t('MySmartBattery:msbOk')}:
                </Col>
                <Col xs={4} className="list-item-value">
                  {dateFormatter(item.msbOk) || '-'}
                </Col>
              </Row>
            )
          }
        ]}
        dataSource={mySmartBatteryList || []}
      />
    </div>
  );
};

export default withRouter(
  translate(['MySmartBattery', 'Customer', 'Installation'])(
    MySmartBatteryListMobile
  )
);
