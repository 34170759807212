import { RouteComponentProps, withRouter } from 'react-router';
import { translate } from 'react-i18next';
import React from 'react';
import { TranslationFunction } from 'i18next';
import { ISnackBarParams } from '../../../../../stores/userFeedback/snackbarStore';
import LoadingButton from '../../../../../ui/Button/LoadingButton/LoadingButton';
import { IUploadedFile } from './BatteryDocumentUploadModal';
import { safeMobxInject } from '../../../../../stores/storeInjectionHelpers';
import { DocumentType } from '../../../../../graphql/graphql.schema';

interface IProps extends RouteComponentProps<IMatchParams> {
  t: TranslationFunction;
  loading: boolean;
  disabled?: boolean;
  signedDocument: IUploadedFile | undefined;
  documentType: DocumentType;
  uploadDocument: (data: any) => void;
}

interface IStoresProps {
  showSnackBar: (params: ISnackBarParams) => void;
  setSendingDocumentDate: (documentType: DocumentType) => void;
}

interface IMatchParams {
  id: string;
}

const BatteryDocumentUploadButton = safeMobxInject<IStoresProps, IProps>(
  stores => ({
    showSnackBar: stores.snackBar.showSnackBar,
    setSendingDocumentDate: stores.battery.setSendingDocumentDate
  }),
  ({
    match: {
      params: { id: customerId }
    },
    t,
    disabled,
    loading,
    signedDocument,
    documentType,
    uploadDocument
  }: IProps & IStoresProps) => {
    return (
      <LoadingButton
        loading={loading}
        disabled={disabled || false}
        onClick={() => {
          if (signedDocument) {
            uploadDocument({
              variables: {
                customerId,
                type: documentType,
                file: signedDocument
              }
            });
          }
        }}
      >
        {t('Common:send')}
      </LoadingButton>
    );
  }
);

export default withRouter(
  translate(['Common', 'MySmartBattery'])(BatteryDocumentUploadButton)
);
