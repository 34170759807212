import * as React from 'react';
import { Customer } from '../../../graphql/graphql.schema';
import { translate, TranslationFunction } from 'react-i18next';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import Spinner from '../../../ui/Spinner/Spinner';
import AutoComplete from '../../../ui/AutoComplete/AutoComplete';

const GET_INTERNET_BOX_MODELS = gql`
  query internetBoxModels {
    internetBoxModels
  }
`;
const InternetBoxModelSelector = ({
  t,
  value,
  onChange
}: {
  onChange: (a: string) => void;
  t: TranslationFunction;
  value: string;
}) => (
  <Query query={GET_INTERNET_BOX_MODELS}>
    {({ loading, data }) => {
      return (
        <AutoComplete
          onChange={selectValue => {
            onChange(selectValue);
          }}
          id="internetBoxModel"
          name={t('internetBoxModel')}
          value={value}
          options={data.internetBoxModels}
          loading={loading}
        />
      );
    }}
  </Query>
);

export default translate(['Customer', 'Installation'])(
  InternetBoxModelSelector
);
