import React, { useEffect } from 'react';
import { Col, Form, Row } from 'reactstrap';
import { FormInput } from '../../../ui/FormInput/FormInput';
import LoginActionWrapper from '../../../ui/Wrapper/LoginActionWrapper';
import { TranslationFunction } from 'i18next';

import { translate } from 'react-i18next';
import LoadingButton from '../../../ui/Button/LoadingButton/LoadingButton';
import {
  default as SelectInput,
  ISelectValue
} from '../../../ui/Select/Select';
import {
  InstallerFormCustomer,
  MaintainerFormCustomer
} from '../../../graphql/graphql.schema';
import { ApolloError } from 'apollo-client';

import { UserTypes } from '../../../constants/UserTypes';
import createCustomerForm from '../../../stores/forms/createCustomer/createCustomerForm';
import { Profile } from '../../../stores/profile';
import { CustomForm } from '../../../ui/FormInput/CustomForm';
import SelectInstaller from './SelectInstaller';
import BirthDatePicker from '../BirthDatePicker';
import { ListTitle } from '../../../ui/DataDisplay/List/ListHeader';
import { FieldList } from '../../../ui/DataDisplay/FieldList/FieldList';
import AddressForm from '../../../ui/Inputs/AddressForm/AddressForm';
import ShowAlert from '../../../ui/ShowAlert/ShowAlert';
import MissingFieldsAlert from '../../../ui/MissingFormFieldsAlert/MissingFormFieldsAlert';
import { basicSelectValidator } from '../../../utils/validators';
import { safeMobxInject } from '../../../stores/storeInjectionHelpers';

interface IProps {
  t: TranslationFunction;
  success?: boolean;
  saveLoading?: boolean;
  done?: boolean;
  saveError?: ApolloError;
  saveFromMaintainer: (f: MaintainerFormCustomer) => void;
  saveFromInstaller: (f: InstallerFormCustomer) => void;
}

interface IStoresProps {
  createCustomer: createCustomerForm;
  profile: Profile;
}

const CreateCustomerFormMaintainer = safeMobxInject<IStoresProps, IProps>(
  stores => ({
    createCustomer: stores.Store.createCustomer,
    profile: stores.Store.profile
  }),
  ({
    t,
    saveLoading,
    done,
    createCustomer,
    profile,
    saveFromMaintainer
  }: IProps & IStoresProps) => {
    useEffect(
      () => {
        createCustomer.updateMaintainer({
          maintainer: {
            company: { tenants: profile.tenants, id: '' },
            id: ''
          },
          companyCode: ''
        });
      },
      [profile.tenants]
    );

    useEffect(
      () => {
        if (profile.useDefaultTenant) {
          createCustomer.setTenant(createCustomer.getSelectTenantOptions[0]);
        }
      },
      [profile.useDefaultTenant]
    );

    const handleSave = () => {
      const customer: any = {
        email: createCustomer.emailAddress.toLowerCase(),
        firstName: createCustomer.firstName,
        address: createCustomer.address,
        city: createCustomer.city,
        country: createCustomer.country.value,
        latitude: createCustomer.latitude,
        longitude: createCustomer.longitude,
        // emailAlertsEnabled : createCustomer.emailAlertsEnabled,
        lastName: createCustomer.lastName,
        zipCode: Number(createCustomer.postalCode),
        phoneNumber: createCustomer.phone,
        mobileNumber: createCustomer.mobilePhone,
        birthDate: createCustomer.birthDate
      };

      if (createCustomer.tenant) {
        if (profile.userType === UserTypes.MAINTAINER) {
          if (createCustomer.installer) {
            saveFromMaintainer({
              customer,
              tenant: {
                id: createCustomer.tenant.id,
                name: createCustomer.tenant.name
              },
              installerId: createCustomer.installer.id
            });
          } else {
            saveFromMaintainer({
              customer,
              tenant: {
                id: createCustomer.tenant.id,
                name: createCustomer.tenant.name
              }
            });
          }
        }
      }
    };

    return (
      <Form
        className="pt-5 pb-5"
        style={{
          width: '100%'
        }}
        onSubmit={event => {
          event.preventDefault();
        }}
      >
        {!profile.useDefaultTenant && (
          <SelectInput
            id="tenant"
            required
            name={t('tenant')}
            value={createCustomer.getSelectTenantValue}
            options={createCustomer.getSelectTenantOptions}
            error={
              createCustomer.getSelectTenantValue &&
              basicSelectValidator(createCustomer.getSelectTenantValue.value) &&
              t('Common:tenantError')
            }
            onChange={(ten: ISelectValue) => {
              createCustomer.setTenant(ten);
            }}
            loading={!createCustomer.getSelectTenantOptions}
          />
        )}

        <SelectInstaller />
        <FieldList>
          <ListTitle text={t('identity')} />
          <FormInput
            required
            id="customerFirstName"
            type="text"
            name={t('firstName')}
            value={createCustomer.firstName}
            onChange={createCustomer.updateFirstName}
            error={
              createCustomer.firstName &&
              createCustomer.firstNameError &&
              t('Common:firstNameError')
            }
          />
          <FormInput
            required
            id="customerLastName"
            type="text"
            name={t('name')}
            value={createCustomer.lastName}
            onChange={createCustomer.updateLastName}
            error={
              createCustomer.lastName &&
              createCustomer.lastNameError &&
              t('Common:lastNameError')
            }
          />
          <CustomForm required name={t('birthDate')}>
            <BirthDatePicker
              birthDate={createCustomer.birthDate}
              onChange={a => {
                createCustomer.updateBirthDate(a || undefined);
              }}
            />
          </CustomForm>
        </FieldList>
        <FieldList>
          <AddressForm
            initToCurrentPos={true}
            cityError={createCustomer.cityError}
            addressError={createCustomer.addressError}
            zipCodeError={createCustomer.postalCodeError}
            geolocationError={createCustomer.geolocationError}
            zipCode={createCustomer.postalCode}
            address={createCustomer.address}
            city={createCustomer.city}
            country={createCustomer.country}
            additionalAddress={createCustomer.additionalAddress}
            latitude={createCustomer.latitude}
            longitude={createCustomer.longitude}
            onAddressUpdate={createCustomer.updateAddress}
            onZipCodeUpdate={createCustomer.updatePostalCode}
            onCityUpdate={createCustomer.updateCity}
            onCountryUpdate={createCustomer.updateCountry}
            onLatitudeUpdate={createCustomer.updateLatitude}
            onLongitudeUpdate={createCustomer.updateLongitude}
            onAdditionalAddressUpdate={createCustomer.updateAdditionalAddress}
          />
        </FieldList>
        <FieldList>
          <ListTitle text={t('contact')} />
          <CustomForm
            name={t('Common:phoneRequired')}
            labelStyle={{ fontSize: '0.95rem' }}
          >
            <Row>
              <Col xs={12} sm={6}>
                <FormInput
                  id="customerMobilePhone"
                  type="tel"
                  name={t('mobile')}
                  value={createCustomer.mobilePhone}
                  onChange={createCustomer.updateMobilePhone}
                  error={
                    createCustomer.mobilePhone &&
                    createCustomer.mobilePhoneError &&
                    t('Common:phoneError')
                  }
                />
              </Col>
              <Col xs={12} sm={6}>
                <FormInput
                  id="customerPhone"
                  type="tel"
                  name={t('CustomerEdit:phoneNumber')}
                  value={createCustomer.phone}
                  onChange={createCustomer.updatePhone}
                  error={
                    createCustomer.phone &&
                    createCustomer.phoneNumberError &&
                    t('Common:phoneError')
                  }
                />
              </Col>
            </Row>
          </CustomForm>
          <FormInput
            required
            id="customerEmail"
            type="email"
            name={t('email')}
            value={createCustomer.emailAddress}
            onChange={createCustomer.updateEmailAddress}
            error={
              createCustomer.emailAddress
                ? createCustomer.emailTakenError
                  ? t('Common:emailTaken')
                  : createCustomer.emailAddressError
                    ? t('Common:emailAddressError')
                    : undefined
                : undefined
            }
          />
        </FieldList>
        {createCustomer.maintainerCreateCustomerFormIsInvalid && (
          <ShowAlert message={t('StepTodo:installation')} />
        )}
        <MissingFieldsAlert formType={'createCustomerByMaintainer'} />
        <LoginActionWrapper>
          <LoadingButton
            loading={saveLoading}
            done={done}
            onClick={() => {
              handleSave();
            }}
            disabled={createCustomer.maintainerCreateCustomerFormIsInvalid}
          >
            {t('Common:save')}
          </LoadingButton>
        </LoginActionWrapper>
      </Form>
    );
  }
);

export default translate(['Customer', ' Installation', ' Common'])(
  CreateCustomerFormMaintainer
);
