import { action, computed, observable } from 'mobx';
import { IErrorMessageGraphql } from '../../utils/graphQl';
import { PPDevice } from '../../graphql/graphql.schema';
import { Device } from '../inscription/device';

export class SearchPairingStore {
  @observable public masterKey: string = '';
  @observable public lastMasterKeySearch: string = '';
  @observable public isLoading: boolean = false;

  @observable public pairedDevices: Device[] = [];

  @observable public masterNotConnected: boolean = false;
  @observable public invalidAC: boolean = false;
  @observable public invalidMAC: boolean = false;
  @observable public unknownError: boolean = false;

  @computed
  get masterKeyEmpty(): boolean {
    return this.masterKey === '';
  }

  @computed
  get masterKeyInvalid(): boolean {
    if (this.masterKey) {
      if (
        this.masterKey.match('^([A-Fa-f0-9]{12})$') ||
        (this.masterKey &&
          this.masterKey.match('^([A-Za-z0-9]{4}[-]){2}[A-Za-z0-9]{4}$'))
      ) {
        return false;
      }
    }
    return true;
  }

  @action.bound
  public setMasterKey(masterKey: string) {
    this.masterKey = masterKey;
  }

  @action.bound
  public setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @action.bound
  public setPairedDevices(pairedDevices: PPDevice[]) {
    this.pairedDevices = pairedDevices.map((pairedDevice: PPDevice) => {
      return new Device(pairedDevice);
    });
  }

  @action.bound
  public setInvalidAC(invalid: boolean) {
    this.invalidAC = invalid;
  }

  @action.bound
  public setInvalidMAC(invalid: boolean) {
    this.invalidMAC = invalid;
  }

  @action.bound
  public setMasterNotConnectedError(error: boolean) {
    this.masterNotConnected = error;
  }

  @action.bound
  public setUnknownError(error: boolean) {
    this.unknownError = error;
  }

  @action.bound
  public setLastMasterKeySearch(lastMasterKeySearch: string) {
    this.lastMasterKeySearch = lastMasterKeySearch;
  }

  @action.bound
  public resetErrors() {
    this.setMasterNotConnectedError(false);
    this.setInvalidAC(false);
    this.setInvalidMAC(false);
    this.setUnknownError(false);
  }

  @action.bound
  public resetPairedDevices() {
    this.pairedDevices = [];
  }

  @action.bound
  public startSearchPairing() {
    this.setIsLoading(true);
    this.setLastMasterKeySearch(this.masterKey);
    this.resetErrors();
    this.resetPairedDevices();
  }

  @action.bound
  public displaySearchPairingErrors(errors: IErrorMessageGraphql[]) {
    errors.forEach(error => {
      switch (error.errorCode) {
        case 'mstNotConnected':
          this.setMasterNotConnectedError(true);
          break;
        case 'unknownMstError':
          this.setUnknownError(true);
          break;
        case 'activationCodeInvalid':
          this.setInvalidAC(true);
          break;
        case 'invalidMac':
          this.setInvalidMAC(true);
          break;
      }
    });
  }
}

const searchPairingStore: SearchPairingStore = new SearchPairingStore();
export default searchPairingStore;
