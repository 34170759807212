import React from 'react';
import style from './snackbar.module.scss';
import classnames from 'classnames';
import { SnackbarType } from '../../stores/userFeedback/snackbarStore';
import { inject } from 'mobx-react';
import { IAllStores } from '../../stores/allStores.model';

interface IProps {
  hide?: () => void;
  text?: string;
  visible?: boolean;
  type?: SnackbarType;
}

@inject((allStores: IAllStores) => {
  return {
    hide: allStores.snackBar.hide,
    text: allStores.snackBar.text,
    visible: allStores.snackBar.visible,
    type: allStores.snackBar.type
  };
})
export default class SnackBar extends React.Component<IProps> {
  public render() {
    const { visible, text, hide, type } = this.props;

    const classNameType = type ? style[type] : '';

    return (
      <div
        className={classnames(
          style.snackBar,
          { [style.visible]: visible },
          classNameType
        )}
      >
        {text}
        <i
          className={classnames(style.closeButton, 'icon-cross')}
          onClick={() => hide!()}
        />
      </div>
    );
  }
}
